import ExcelJS from 'exceljs';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { forceFileDownload } from '../../utils/downloadData';
import { formateDate } from '@/utils/date.js';

const MIN_COL_WIDTH = 10;
const MAX_COL_WIDTH = 50;

export function useDownloadXlProductionTable(columnsComputed, dataComputed) {
  const store = useStore();
  const filters = computed(() => store && store.state.productionTable.filters);
  const range = computed(() => {
    const deadline = filters.value.deadline;
    if (deadline.currentMonthWithDebt) {
      return `current-month-with-debt-${formateDate(new Date(), 'MM.YYYY')}`;
    }
    const range = deadline.dateRange || deadline.monthRange;
    return `${formateDate(range[0])}-${formateDate(range[1])}`;
  });

  const getColumnByInd = (i) => {
    const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const ind = i - 1;
    const LENGHT = ALPHABET.length;
    if (ind < LENGHT) {
      return ALPHABET[ind];
    }
    const coef = i % LENGHT === 0 ? 1 : 0;
    const firstLet = Math.floor(i / LENGHT) - 1 - coef;
    const secondLet = ind % LENGHT;
    return `${ALPHABET[firstLet]}${ALPHABET[secondLet]}`;
  };

  const downloadXlTable = async () => {
    const columns = columnsComputed.value;
    const data = dataComputed.value;

    const workbook = new ExcelJS.Workbook();

    workbook.creator = 'LG_system';
    workbook.lastModifiedBy = 'LG_system';
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet('Производственная таблица');

    const headerRow = worksheet.getRow(1);

    let OFFSET_COL = 1;
    let OFFSET_ROW = 1;

    for (let i = OFFSET_COL; i < columns.length + OFFSET_COL; i++) {
      const column = columns[i - OFFSET_COL];
      const cell = headerRow.getCell(i);
      cell.value = column.label;
    }
    OFFSET_ROW++;

    for (let i = OFFSET_COL; i < columns.length + OFFSET_COL; i++) {
      const column = columns[i - OFFSET_COL];

      for (let k = OFFSET_ROW; k < data.length + OFFSET_ROW; k++) {
        const order = data[k - OFFSET_ROW];
        const cell = worksheet.getCell(`${getColumnByInd(i)}${k}`);
        cell.value = order[column.key] || '';
      }
    }

    worksheet.columns.forEach((column) => {
      const lengths = column.values.map((v) => v.toString().length);
      const maxLength = Math.min(
        MAX_COL_WIDTH,
        Math.max(...lengths.filter((v) => typeof v === 'number'), MIN_COL_WIDTH)
      );
      column.width = maxLength;
    });

    const buffer = await workbook.xlsx.writeBuffer();
    forceFileDownload(buffer, `Production-table-${range.value}.xlsx`);
  };

  return {
    downloadXlTable,
  };
}
