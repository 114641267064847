<template>
  <div class="header-wrapper">
    <div class="header__search">
      <AInput
        :model-value="search"
        placeholder="Поиск заказов"
        :read-only="globalLoading"
        tab-index="1"
        @input="$emit('update:search', $event)"
      />
    </div>
    <div class="header__actions">
      <ADatePicker
        :model-value="createdAt"
        mode="date"
        format="YYYY-MM-DD"
        label="Дата создания"
        fixed-popover
        auto-apply
        :readonly="globalLoading"
        @change="$emit('update:createdAt', $event)"
      />
      <ADatePicker
        :model-value="deadline"
        mode="date"
        format="YYYY-MM-DD"
        label="Дата отгрузки"
        fixed-popover
        auto-apply
        :readonly="globalLoading"
        @change="$emit('update:deadline', $event)"
      />

      <div class="icon" @click="$emit('openOrdersSettingAside')">
        <Fa icon="filter" style="width: 25px; height: 25px" />
      </div>
    </div>
  </div>
</template>

<script>
import { dayjs } from '@/utils/date.js';

export default {
  name: 'HeaderString',
  props: {
    search: {
      type: String,
      default: null,
    },
    createdAt: {
      type: [String, Date],
      default: null,
    },
    deadline: {
      type: [String, Date],
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:createdAt', 'update:deadline', 'update:search', 'openOrdersSettingAside'],
  computed: {
    globalLoading() {
      return this.loading;
    },
  },
  methods: {
    formatedDate(date) {
      if (!date) return;
      return dayjs(date).format('DD.MM.YYYY HH:mm');
    },
  },
};
</script>
<style lang="scss" scoped>
.header-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: center;

  padding: 0px;
}
.header {
  &__search {
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
    padding: 0px 30px;
  }
  &__actions {
    display: flex;
    align-items: flex-start;
    margin: 0px 40px 0px 20px;
    grid-gap: 10px;

    .icon {
      display: flex;
      align-self: center;
      margin: 10px 30px 0px 30px;
      :hover {
        cursor: pointer;
      }
      :active {
        transform: scale(0.96);
      }
    }
  }
}
</style>
