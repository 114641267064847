import { pluralize } from '../pluralize';

const types = {
  number: 'Обязательное поле', //'Значение должно быть числом',
  string: 'Обязательное поле', //'Значение должно быть строкой',
};

export const validateDictionary = {
  'required': () => 'Обязательное поле',
  'notType': ({ type }) => `${types[type]}`,

  'array_min': ({ min }) => {
    return `Минимум элементов: ${min}`;
  },
  'array_max': ({ max }) => {
    return `максимум элементов: ${max}`;
  },

  'number_min': ({ min }) => {
    return `Минимальное значение: ${min}`;
  },
  'number_max': ({ max }) => {
    return `Максимальное значение: ${max}`;
  },
  'number_moreThan': ({ more }) => {
    return `Значение должно быть больше: ${more}`;
  },
  'must_be_integer': () => `Только целое значение`,

  'string_min': ({ min }) => {
    return `Минимальная длина поля: ${min}`;
  },
  'string_max': ({ max }) => {
    return `Максимальная длина поля: ${max}`;
  },

  'string_length': ({ length }) => {
    return `Допустимая длина поля: ${length} ${pluralize(length, [
      'символ',
      'символа',
      'символов',
    ])}`;
  },
};
