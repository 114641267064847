const ALL_FIELDS = [
  { key: 'guid', name: 'Id' },
  { key: 'name', name: 'Название заказа' },
  {
    key: 'clientName',
    name: 'Имя клиента',
    qtitle: 'С добавлением этого столбца - добавляется поиск по ФИО клиента',
  },
  { key: 'state', name: 'Статус' },
  { key: 'createdAt', name: 'Дата создания' },
  { key: 'deadline', name: 'Дата отгрузки' },
  { key: 'amoId', name: 'amoCRM' },
  { key: 'currency', name: 'Валюта' },
  { key: 'discountFix', name: 'Скидка, ₽' },
  { key: 'discountPercent', name: 'Скидка, %' },
  { key: 'paid', name: 'Оплачено' },
  {
    key: 'teamName',
    name: 'Назв. команды',
    qtitle: 'С добавлением этого столбца - добавляется поиск по названию команды',
  },
  {
    key: 'managerName',
    name: 'Менеджер',
    qtitle: 'С добавлением этого столбца - добавляется поиск по ФИО менеджеру',
  },
];

const PERMANENT_FIELDS = ['guid', 'name'];
const ADMIN_AND_MANAGERS_FIELDS = ['managerName'];

export { ALL_FIELDS, PERMANENT_FIELDS, ADMIN_AND_MANAGERS_FIELDS };
