<template>
  <li>
    <a class="a-pagination-input-button">
      <span v-show="!isInputShown" aria-hidden="true"> ... </span>
    </a>
  </li>
</template>

<script>
export default {
  name: "PaginationInputButton",
  props: {
    isLastPages: {
      type: Boolean,
      required: true,
    },
    isFirstPages: {
      type: Boolean,
      required: true,
    },
    last: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      value: null,
      isInputShown: false,
      isInputInvalid: false,
    };
  },
  computed: {
    paginationInputNumber() {
      return Number(this.value);
    },
  },
  methods: {
    toggleInputAndFocus() {
      this.isInputShown = !this.isInputShown;
      if (this.isInputShown) {
        this.$nextTick(() => {
          this.$refs[this._uid].focus();
        });
      }
    },
    paginationInputHandler() {
      const isPaginationInputNumber = this.paginationInputNumber;
      const isNumberInRange = this.last >= this.paginationInputNumber >= 1;

      if (isPaginationInputNumber && isNumberInRange) {
        this.$emit("pagination-input", this.paginationInputNumber);
        this.toggleInputAndFocus();
      } else {
        this.toggleInvalidClass();
        setTimeout(this.toggleInvalidClass, 500);
      }
    },
    paginationInputResetHandler() {
      this.value = null;
    },
    toggleInvalidClass() {
      this.isInputInvalid = this.isInputInvalid ? !this.isInputInvalid : true;
    },
  },
};
</script>

<style lang="scss" scoped>
$color-red: #ed5565;

li {
  // height: 40px;
}
.a-pagination-input {
  display: block;
  max-width: 40px;
  box-sizing: border-box;
  border: none;
  outline: none;
  text-align: center;

  &:focus {
    border: 1px solid $smoke-blue;
  }

  .invalid & {
    animation: shake 0.5s;
    animation-iteration-count: 1;
    color: $color-red;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  &-button:not([href]):not([tabindex]) {
    display: block;

    box-sizing: border-box;
    height: 40px;
    min-width: 40px;
    margin-right: -1px;

    @include InterSemibold;
    line-height: 40px;
    color: $smoke-blue-50-opacity;

    border: 1px solid $color-white-smoke;
    background-color: $color-white;

    text-align: center;
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
</style>
