<template>
  <div v-if="card && order">
    <div class="defect-template-card">
      <div class="defect-template-card__header">
        <div class="defect-template-card__header-left">
          <div class="block-row">
            <div>
              <div>Название&nbsp;заказа</div>
              <div class="bold" :class="getOrderNameClass">{{ order.name }}</div>
            </div>
            <div>
              <div>Срок&nbsp;исп.</div>
              <div v-if="order.deadline" class="bold">
                {{
                  dayjs(getPackageDate || order.postponedDeadline || order.deadline).format(
                    'DD MMM'
                  )
                }}
              </div>
            </div>
            <div>
              <div>Партия</div>
              <div class="bold">{{ card.index }}/{{ order.cardsQuantity }}</div>
            </div>
            <div>
              <div>Материал</div>
              <div class="bold">
                {{ assortmentDictionary.fabrics[card.product.fabricId]?.name }}
              </div>
            </div>
          </div>
          <div class="defect-template-card__title">
            <div>
              {{ assortmentDictionary.productTypes[card.product.item.productTypeId]?.name }}.
              {{ assortmentDictionary.categories[card.product.item.categoryId]?.name }}.
              {{ card.product.color }}
              ( Артикул
              {{ assortmentDictionary.articles[card.product.item.articleId]?.code }} :
              {{ assortmentDictionary.articles[card.product.item.articleId]?.name }} )
            </div>
            <div class="mr-5">Всего&nbsp;изд.:&nbsp;{{ card.players.length }}</div>
          </div>
        </div>
        <div class="defect-template-card__header-right">
          <div class="defect-template-card__summary">
            <div class="defect-template-card__summary-wrapper">
              <div class="defect-template-card__summary-table">
                <div v-for="(values, sexId) of getSummary" :key="sexId" class="summary-column">
                  <div class="header">{{ getSex(sexId) }}</div>
                  <div v-for="(quantity, size) of values" :key="`${size}_${sexId}`" class="row">
                    <div>{{ size }}</div>
                    <div>{{ quantity }}</div>
                  </div>
                  <div class="wrapper">
                    <div />
                    <div class="sum">{{ getSumInSex(values) }}</div>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
      <div class="body">
        <table>
          <tr>
            <th class="col1">Дата обнаружения</th>
            <th class="col2">Место обнаружения</th>
            <th class="col3">Деталь (Название/ Обозначение)</th>
            <th class="col4">Размер</th>
            <th class="col5">Фамилия / Номер</th>
            <th class="col6">Кол-во</th>
            <th class="col7">Дефект (Согласно каталогу дефектов)</th>
            <th class="col8">Напечатано</th>
            <th class="col9">Перенесено</th>
            <th class="col10">Раскроено</th>
            <th class="col11">Крой проверен</th>
            <th class="col12">Перешито</th>
          </tr>
          <tr v-for="(_, ind) of 100" :key="ind">
            <td v-for="(__, ind2) of 12" :key="`${ind}_${ind2}`"></td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getLink } from '../../../utils/getStorageLink';
import { dayjs } from '../../../utils/date';
import { getClassNameByTextLength } from '@/utils/text.js';
import { ORDER_PARAM_TYPE, ORDER_STATE } from '../../../constants';
import { useOrderParamValue } from '@/use/order/useOrderParamValue';

export default {
  name: 'DefectTemplateCard',
  props: {
    card: {
      type: Object,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { getValueFromOrderParamByType } = useOrderParamValue();

    return {
      getValueFromOrderParamByType,
    };
  },
  computed: {
    ...mapState({
      assortmentDictionary: (s) => s.dictionary.assortmentDictionary,
      productionParamsDictionary: (s) => s.dictionary.productionParams,
    }),
    showQrCode() {
      return [ORDER_STATE.TECH_DESIGN, ORDER_STATE.PRODUCTION, ORDER_STATE.COMPLETED].includes(
        this.order.state
      );
    },
    getSummary() {
      const blocks = {};
      for (const player of this.card.players) {
        if (!blocks[player.sexId]) blocks[player.sexId] = {};
        blocks[player.sexId][player.size] = ++blocks[player.sexId][player.size] || 1;
      }
      return blocks;
    },
    getSumInSex() {
      return (values) => Object.values(values).reduce((s, i) => s + i, 0);
    },
    getSex() {
      return (sexId) => {
        const sex = this.assortmentDictionary.sex[sexId]?.name || '-';
        if (sex?.length > 6) {
          return `${sex.slice(0, 6)}.`;
        }
        return sex;
      };
    },
    getMockup() {
      return this.card.product.design.mockup;
    },
    getOrderNameClass() {
      const text = this.order?.name || '';
      return getClassNameByTextLength(text);
    },
    getDeadlineClasses() {
      const classes = {};
      if (this.getPackageDate && dayjs(this.getPackageDate).isSameOrBefore(new Date(), 'day')) {
        classes['deadline-expired'] = true;
      }
      if (this.order.postponedDeadline) {
        classes['deadline-small'] = true;
        classes['deadline-expired'] = true;
      }
      if (dayjs(this.order.deadline).isSameOrBefore(new Date(), 'day')) {
        classes['deadline-expired'] = true;
      }
      return classes;
    },
    getPackageDate() {
      return this.getValueFromOrderParamByType({
        orderParamsType: ORDER_PARAM_TYPE.PACKING_DATE,
        orderParams: this.order.orderParams,
      });
    },
  },
  async created() {
    this.dayjs = dayjs;
    this.getLink = getLink;
  },
};
</script>
<style scoped lang="scss">
$cardWidth: 1594px;
$cardHeight: 1132px;
$border-color: rgb(158, 158, 158);

.defect-template-card {
  transform: rotate(-90deg);
  transform-origin: center center;
  margin-left: -235px;
  margin-top: 235px;

  overflow: hidden;

  display: flex;
  flex-direction: column;
  grid-gap: 0px;
  border: 1px dashed $border-color;
  width: $cardWidth;
  height: $cardHeight;

  page-break-before: always;
  page-break-after: always;

  &__header {
    display: flex;
    flex-direction: row;
    border: 1px dashed $border-color;
  }

  &__header-left {
    display: flex;
    flex-direction: column;
    width: 100%;

    .block-row {
      display: flex;
      flex-direction: row;

      div {
        display: grid;
        grid-template-rows: 20px 1fr;
        width: fit-content;

        border-right: 1px dashed $border-color;
        border-top: 1px dashed $border-color;
        border-bottom: 1px dashed $border-color;

        flex-grow: 1;
        div {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
        }
        :nth-child(1) {
          width: 100%;
        }
        :nth-child(2) {
          width: 100%;
          font-size: 22px;
          flex-grow: 1;
        }
        .bold {
          font-size: 28px;
          font-weight: 600;
        }
      }
    }
  }
  &__title {
    font-size: 22px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
  &__header-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__summary-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0px 0px 10px 0px;
  }
  &__summary-table {
    display: flex;
    flex-direction: row;
    border: 1px dashed $border-color;
    width: fit-content;
    flex-grow: 3;

    .summary-column {
      display: flex;
      flex-direction: column;

      &:not(:last-child) {
        border-right: 1px solid $border-color;
      }
    }

    .header {
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      border-bottom: 1px dashed $border-color;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 3;

      .sum {
        text-align: center;
        font-weight: 600;
        font-size: 16px;
        border-top: 1px dashed $border-color;
      }
    }
    .row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      div {
        text-align: center;
        padding: 1px 10px;
        &:first-child {
          border-right: 1px solid $border-color;
        }
      }
    }
  }
  .body {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  table {
    border-collapse: collapse;
    overflow: hidden;
    width: 100%;
    height: 100%;

    th {
      background-color: #7eb95a;
    }
    tr {
      height: 50px;
    }
    td {
      border: 1px solid;
    }
    .col1 {
      width: 45px;
    }
    .col5 {
      width: 150px;
    }
  }
}
</style>
