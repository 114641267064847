<template>
  <APageLayout>
    <template #header>
      <div class="header-string">
        <AInput v-model="filters.search" class="filter" placeholder="Поиск" />
        <AToggle
          v-if="isMainManager"
          v-model="filters.showAllOrders"
          label="Заказы всех менеджеров"
          class="toggle"
        />
      </div>
    </template>
    <template #content>
      <div v-if="!globalLoading" class="container design-block">
        <div class="content-wrapper">
          <APageBlock
            v-if="isManager || isMainManager || isAdmin"
            v-model="openQueueBlock"
            class="mb-20"
            model-toggle
          >
            <template #header> Очередь </template>
            <DesignSolutionTable
              :loading="globalLoading"
              :block="DESIGN_SOLUTION_STATUS.QUEUE"
              :filters="filters"
              :row-classes="rowClasses"
              @changeDesignerOrState="changeDesignerOrStateHandler"
            />
          </APageBlock>
          <APageBlock class="mb-20" toggle>
            <template #header> Новые заказы </template>
            <DesignSolutionTable
              :loading="globalLoading"
              :block="DESIGN_SOLUTION_STATUS.NEW"
              :filters="filters"
              @changeDesignerOrState="changeDesignerOrStateHandler"
            />
          </APageBlock>
          <APageBlock class="mb-20" toggle>
            <template #header> В работе </template>
            <DesignSolutionTable
              :loading="globalLoading"
              :block="DESIGN_SOLUTION_STATUS.PROCESS"
              :filters="filters"
              @changeDesignerOrState="changeDesignerOrStateHandler"
            />
          </APageBlock>
          <APageBlock class="mb-20" toggle>
            <template #header> На проверке </template>
            <DesignSolutionTable
              :loading="globalLoading"
              :block="DESIGN_SOLUTION_STATUS.VALIDATE"
              :filters="filters"
              @changeDesignerOrState="changeDesignerOrStateHandler"
            />
          </APageBlock>
        </div>
      </div>
      <div v-else>
        <ALoader centered />
      </div>
    </template>
  </APageLayout>
</template>

<script>
import { mapActions } from 'vuex';
import DesignSolutionTable from './components/DesignSolutionTable.vue';
import { debounce } from '../../utils/lodash.js';
import accessMixin from '@/mixin/accessMixin.js';
import { DESIGN_SOLUTION_STATUS } from '../../constants';

export default {
  name: 'Design',
  components: {
    DesignSolutionTable,
  },
  mixins: [accessMixin],
  data() {
    return {
      designOrdersLoading: true,

      filters: {
        search: null,
        showAllOrders: true,
      },

      openQueueBlock: this.isCanChangeOrder || this.isAdmin,
    };
  },
  computed: {
    accessToChange() {
      // TODO accessToChange
      return true;
    },
    globalLoading() {
      return this.designOrdersLoading;
    },
  },
  async created() {
    this.designOrdersLoading = true;
    this.DESIGN_SOLUTION_STATUS = DESIGN_SOLUTION_STATUS;

    await this.loadInitData()
      .catch(() => {})
      .finally(() => {
        this.designOrdersLoading = false;
      });
  },
  methods: {
    ...mapActions({
      getAllDesigners: 'design/getAllDesigners',
      getDesignSolutions: 'design/getDesignSolutions',
      changeStateAndDesignerInDesignSolution: 'design/changeStateAndDesignerInDesignSolution',
    }),
    async loadInitData() {
      await Promise.all([this.getAllDesigners(), this.getDesignSolutions()]);
    },
    async changeDesignerOrStateHandler({ item, isProduct, newDesignerId, newState }) {
      console.log({ newDesignerId });
      try {
        const data = { designerId: newDesignerId, newState };
        if (isProduct) {
          data.designSolutionId = item.guid;
        } else {
          data.orderId = item.guid;
          data.currentState = item.products[0].state;
        }
        await this.changeStateAndDesignerInDesignSolution(data);
        this.$notify({ title: 'Сохранено' });
      } catch (error) {
        this.$notifyError({ title: 'Ошибка при сохранении' });
      }
    },
    rowClasses(row) {
      if (row.guid === this.selectedDesignSolution?.guid) {
        return 'active';
      }
    },
    debounceSearchOrders: debounce(async function () {
      try {
        this.designOrdersLoading = true;
        await this.getDesignSolutions();
      } catch (err) {
        console.log(err);
      } finally {
        this.designOrdersLoading = false;
      }
    }, 600),
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;

  width: 100%;
  height: 100%;
}

.header-string {
  display: flex;
  flex-direction: row;
  height: 100%;
  // align-content: center;
  // justify-content: center;
  // justify-items: center;
  // justify-self: center;
  // align-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
  // align-self: center;

  .filter {
    width: 100%;
    padding-right: 30px;
  }
  .toggle {
    min-width: max-content;
  }
}
.content-wrapper {
  padding: 0;
  margin: 0;

  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  * > div.block {
    width: calc(50% - 10px);

    @include to(1590px) {
      width: 100%;
    }
  }
}
.page-header {
  @include InterBold;
  font-size: 18px;
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 36px;
}
</style>
<style lang="scss">
.design-block {
  .tr-subrow.active {
    background-color: red;
  }
}
</style>
