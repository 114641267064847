import assortmentApi from '@/api/assortmentApi.js';
import productionApi from '@/api/productionApi.js';
import { getValueByFields } from '@/utils/getValueByFields.js';
import { ITEM_DICTIONARY_FIELDS } from '../../../constants';

export default {
  async getSets({ commit }) {
    const { data } = await assortmentApi.getSets();
    commit('SET_SETS', data);
  },
  async getItems({ commit }) {
    const { data } = await assortmentApi.getItems();
    commit('SET_ITEMS', data);
  },

  async createItem({ commit }, { item }) {
    const { data } = await assortmentApi.createItem({ item });
    commit('ADD_ITEM', data);
    commit(
      'dictionary/SET_BASE_DICTIONARY',
      { key: 'items', data: getValueByFields(ITEM_DICTIONARY_FIELDS, data), oldData: item },
      { root: true }
    );
  },

  async changeItem({ commit }, { item }) {
    const { data } = await assortmentApi.changeItem({ item });
    commit('SET_ITEM', { item: data, oldItem: item });
    commit(
      'dictionary/SET_BASE_DICTIONARY',
      { key: 'items', data: getValueByFields(ITEM_DICTIONARY_FIELDS, data), oldData: item },
      { root: true }
    );
  },

  async getClientDesigns({ commit }, { clientId }) {
    const { data } = await assortmentApi.getClientDesigns({ clientId });
    commit('SET_CLIENT_DESIGNS', { data, clientId });
  },

  async createSet({ commit }, { set }) {
    const { data } = await assortmentApi.createSet({ set });
    commit('ADD_SET', data);
  },

  async changeSet({ commit }, { set }) {
    const { data } = await assortmentApi.changeSet({ set });
    commit('SET_SET', { set: data, oldSet: set });
  },

  async getAllProductionLinesForItem({ commit }, { itemId }) {
    const { data } = await productionApi.getAllProductionLinesForItem({ itemId });
    commit('SET_ITEM_ALL_PRODUCTION_LINES', data);
  },
};
