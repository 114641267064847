// https://jsonformatter.org/scss-to-css

export const cardStyles = `
*{
  font-family: Inter, Arial !important;
}

.card {
  display: grid;
  grid-template-rows: 80px 45px 390px 20px 20px 1fr 305px 20px;
  grid-gap: 0px;
  border: 1px dashed #9e9e9e;
  width: 1132px;
  height: 1594px;
  page-break-before: always;
  page-break-after: always;
}
.card__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.card__header div {
  display: grid;
  grid-template-rows: 20px 1fr;
  width: 100%;
  height: 100%;
  border-right: 1px dashed #9e9e9e;
  border-top: 1px dashed #9e9e9e;
  border-bottom: 1px dashed #9e9e9e;
}
.card__header div div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.card__header div :nth-child(2) {
  font-size: 22px;
}
.card__header div .bold {
  font-size: 28px;
  font-weight: 600;
}
.card__title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  height: 100%;
  font-size: 18px;
  word-wrap: break-all;
}
.card__summary {
  display: grid;
  grid-template-columns: 1.75fr 0.25fr;
}
.card__image {
  display: flex;
  flex-direction: column;
}
.card__image .card__images-cover {
  display: flex;
  flex-direction: row;
}
.card__image img {
  height: 370px;
  max-width: calc(100% - 20px);
}
.card__image .two-image img {
  max-width: calc(50% - 10px);
}
.card__summary-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0px 0px 10px 0px;
}
.card__summary-table {
  display: flex;
  flex-direction: row;
  border: 1px dashed #9e9e9e;
  width: fit-content;
  margin: 10px 0px;
  flex-grow: 3;
}
.card__summary-table .summary-column {
  display: flex;
  flex-direction: column;
}
.card__summary-table .summary-column:not(:last-child) {
  border-right: 1px solid #9e9e9e;
}
.card__summary-table .header {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  border-bottom: 1px dashed #9e9e9e;
  padding: 5px 0px;
}
.card__summary-table .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 3;
}
.card__summary-table .wrapper .sum {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  border-top: 1px dashed #9e9e9e;
}
.card__summary-table .row {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.card__summary-table .row div {
  text-align: center;
  padding: 1px 10px;
}
.card__summary-table .row div:first-child {
  border-right: 1px solid #9e9e9e;
}
.card__players table {
  border-collapse: collapse;
  width: 100%;
}
.card__players tr {
  line-height: 24px;
}
.card__players td {
  font-size: 16px;
  border: 1px solid #9e9e9e;
  text-align: center;
}
.card__players td.index {
  width: 20px;
}
.card__players td.empty {
  width: 30px;
}
.card__players td.size {
  width: 60px;
}
.card__players td.sex {
  width: 70px;
}
.card__players td.lettering {
  min-width: 130px;
}
.card__players td.number {
  width: 90px;
}
.card__card-info {
  display: grid;
  grid-template-columns: 1fr 300px;
}
.card__order {
  display: grid;
}
.card__order div {
  display: grid;
  grid-template-rows: 25px 1fr;
  text-align: center;
}
.card__order div .header {
  border: 1px solid #9e9e9e;
}
.card__order div .body {
  border: 1px solid #9e9e9e;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-weight: 600;
  word-break: break-all;
  hyphens: auto;
}
.card__order div .body.text-xl {
  font-size: 34px;
}
.card__order div .body.text-xxl {
  font-size: 28px;
}
.card__order .name {
  grid-row: 1;
  grid-column: span 2;
}
.card__order .deadline {
  grid-row: row 1;
}
.card__order .deadline .body {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.card__order .deadline .postponedDeadline-date {
  min-height: 50px;
  color: #ff4138;
}
.card__order .deadline .deadline-date.deadline-expired {
  color: #ff4138;
}
.card__order .deadline .deadline-date.deadline-small {
  font-size: 24px;
  text-decoration: line-through;
}
.card__order .deadline .deadline-date:not(.deadline-small) {
  min-height: 50px;
}
.card__order .cards {
  grid-row: row 1;
}
.card .qr-code-test {
  display: flex;
  height: 100%;
  align-items: center;
  text-align: center;
  font-size: 55px;
  font-weight: 500;
}
.card__footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid #9e9e9e;
}














.card-summary {
  display: flex;
  flex-direction: column;
  border: 1px dashed #9e9e9e;
  width: 1132px;
  height: 1594px;
}
.card-summary__info {
  flex-grow: 0.11;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #9e9e9e;
}
.card-summary__info .seconary {
  margin-top: 10px;
  font-size: 12px;
}
.card-summary__info div {
  display: grid;
  grid-template-rows: 20px 1fr;
  font-size: 14px;
  flex-grow: 1;
  border-right: 1px solid #9e9e9e;
  text-align: center;
}
.card-summary__info div .body {
  border-top: 1px dashed #9e9e9e;
  font-size: 20px;
  font-weight: 600;
}
.card-summary__info div .name {
  max-width: 600px;
}
.card-summary__info div .name.text-xl {
  font-size: 16px;
}
.card-summary__info div .name.text-xxl {
  font-size: 16px;
}
.card-summary__summary {
  flex-grow: 3;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.card-summary__summary table .row {
  font-size: 18px;
  font-weight: 600;
}
.card-summary .tables {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
}
.card-summary .tables .summary-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 10px 10px 0px;
  border: 1px solid #9e9e9e;
}
.card-summary .tables .summary-block .header {
  padding-top: 10px;
  font-weight: 600;
}
.card-summary__summary-table {
  display: flex;
  flex-direction: row;
  border-top: 1px dashed #9e9e9e;
  width: fit-content;
  margin: 10px 0px;
  flex-grow: 3;
}
.card-summary__summary-table .summary-column {
  display: flex;
  flex-direction: column;
}
.card-summary__summary-table .summary-column:not(:last-child) {
  border-right: 1px solid #9e9e9e;
}
.card-summary__summary-table .header {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  border-bottom: 1px dashed #9e9e9e;
  padding: 5px 0px;
}
.card-summary__summary-table .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 3;
}
.card-summary__summary-table .wrapper .sum {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  border-top: 1px dashed #9e9e9e;
}
.card-summary__summary-table .row {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.card-summary__summary-table .row div {
  text-align: center;
  padding: 1px 10px;
}
.card-summary__summary-table .row div:first-child {
  border-right: 1px solid #9e9e9e;
}
.card-summary__footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid #9e9e9e;
}





















.defect-template-card {
  transform: rotate(-90deg);
  transform-origin: center center;
  margin-left: -235px;
  margin-top: 235px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  grid-gap: 0px;
  border: 1px dashed #9e9e9e;
  width: 1594px;
  height: 1132px;
  page-break-before: always;
  page-break-after: always;
}
.defect-template-card__header {
  display: flex;
  flex-direction: row;
  border: 1px dashed #9e9e9e;
}
.defect-template-card__header-left {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.defect-template-card__header-left .block-row {
  display: flex;
  flex-direction: row;
}
.defect-template-card__header-left .block-row div {
  display: grid;
  grid-template-rows: 20px 1fr;
  width: fit-content;
  border-right: 1px dashed #9e9e9e;
  border-top: 1px dashed #9e9e9e;
  border-bottom: 1px dashed #9e9e9e;
  flex-grow: 1;
}
.defect-template-card__header-left .block-row div div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.defect-template-card__header-left .block-row div :nth-child(1) {
  width: 100%;
}
.defect-template-card__header-left .block-row div :nth-child(2) {
  width: 100%;
  font-size: 22px;
  flex-grow: 1;
}
.defect-template-card__header-left .block-row div .bold {
  font-size: 28px;
  font-weight: 600;
}
.defect-template-card__title {
  font-size: 22px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.defect-template-card__header-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.defect-template-card__summary-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0px 0px 10px 0px;
}
.defect-template-card__summary-table {
  display: flex;
  flex-direction: row;
  border: 1px dashed #9e9e9e;
  width: fit-content;
  flex-grow: 3;
}
.defect-template-card__summary-table .summary-column {
  display: flex;
  flex-direction: column;
}
.defect-template-card__summary-table .summary-column:not(:last-child) {
  border-right: 1px solid #9e9e9e;
}
.defect-template-card__summary-table .header {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  border-bottom: 1px dashed #9e9e9e;
}
.defect-template-card__summary-table .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 3;
}
.defect-template-card__summary-table .wrapper .sum {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  border-top: 1px dashed #9e9e9e;
}
.defect-template-card__summary-table .row {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.defect-template-card__summary-table .row div {
  text-align: center;
  padding: 1px 10px;
}
.defect-template-card__summary-table .row div:first-child {
  border-right: 1px solid #9e9e9e;
}
.defect-template-card .body {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.defect-template-card table {
  border-collapse: collapse;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.defect-template-card table th {
  background-color: #7eb95a;
}
.defect-template-card table tr {
  height: 50px;
}
.defect-template-card table td {
  border: 1px solid;
}
.defect-template-card table .col1 {
  width: 45px;
}
.defect-template-card table .col5 {
  width: 150px;
}


@import url('https://fonts.googleapis.com/css2?Family=Inter:wght@400;500;600;700;800 & display=swap');
`;
