export default () => ({
  sets: [],
  items: [],

  clientDesigns: {
    clientId: null,
    designs: [],
    setsIds: [],
  },

  allProdLinesItem: [],
});
