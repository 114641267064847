<template>
  <div v-if="pageImportErrors.length" class="mt-40">
    <table>
      <tr class="a-bold">
        <td># Игрок</td>
        <td># Изделие</td>
        <td>Ошибка</td>
        <td>Неверное значение</td>
      </tr>
      <tr v-for="(err, ind) of pageImportErrors" :key="ind">
        <td>{{ err.playerInd }}</td>
        <td>{{ err.productInd }}</td>
        <td>{{ err.text }}</td>
        <td>{{ err.wrongValue }}</td>
      </tr>
    </table>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'OrderXlImportErrors',
  props: {
    isSelectedOrder: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      newOrder: (s) => s.order.newOrder,
      selectedOrder: (s) => s.order.selectedOrder,
      selectedOrderInfo: (s) => s.order.selectedOrderInfo,
      newOrderInfo: (s) => s.order.newOrderInfo,
      newOrderXlImportErrors: (s) => s.order.newOrderXlImportErrors,
      selectedOrderXlImportErrors: (s) => s.order.selectedOrderXlImportErrors,
    }),
    pageOrder() {
      return this.isSelectedOrder ? this.selectedOrder : this.newOrder;
    },
    orderInfo() {
      return this.isSelectedOrder ? this.selectedOrderInfo : this.newOrderInfo;
    },
    pageImportErrors() {
      return this.isSelectedOrder ? this.selectedOrderXlImportErrors : this.newOrderXlImportErrors;
    },
  },
};
</script>
<style lang="scss" scoped>
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td {
  padding: 2px 10px;
  border: 1px solid $color-white-smoke;
}
</style>
