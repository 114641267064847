import './scss/main.scss';
import 'gitart-vue-dialog/dist/style.css';
import '@vuepic/vue-datepicker/dist/main.css';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
// import 'font-awesome/scss/font-awesome.scss'
// import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
import '@fortawesome/fontawesome-free/css/solid.min.css';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
// import '@fortawesome/fontawesome-free/webfonts/fa-solid-900.woff2';
// import '@fortawesome/fontawesome-free/css/all.css';
// import '@fortawesome/fontawesome-free/scss/solid.scss';
// import VueVirtualScroller from 'vue-virtual-scroller';
import { RecycleScroller } from 'vue-virtual-scroller';
import injectDependencies from '@/injector';
import injectLgUILib from '@@/main.js';
import { createApp } from 'vue';
import App from './App.vue';

import router from './router';
import store from './store';

const app = createApp(App);
app.component('RecycleScroller', RecycleScroller);
app.use(store);
app.use(router);
injectDependencies(app);
injectLgUILib(app);
app.mount('#app');
