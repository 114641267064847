<template>
  <label
    class="a-toggle"
    :class="{
      active: checked === 'no-checked-prop' ? active : checked,
      disabled,
    }"
  >
    <div class="a-toggle__inputs-wrapper">
      <input v-model="data" type="checkbox" class="a-toggle__input" />
      <i class="a-toggle__btn" type="button" />
    </div>
    <span class="a-toggle__label">
      <span>{{ label }}</span>
    </span>
  </label>
</template>

<script>
export default {
  name: "AToggle",
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    checked: {
      type: [String, Boolean],
      default: () => "no-checked-prop",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["input", "change", "update:modelValue"],
  data() {
    return {
      data: this.modelValue,
    };
  },
  computed: {
    active() {
      return this.data;
    },
  },
  watch: {
    modelValue() {
      this.updateFromProp();
    },
    data() {
      this.$emit("input", this.data);
      this.$emit("change", this.data);
      this.$emit("update:modelValue", this.data);
    },
  },
  created() {
    this.updateFromProp();
  },
  methods: {
    updateFromProp() {
      this.data = this.modelValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.a-toggle {
  @include InterSemibold;
  display: flex;
  align-items: flex-start;
  cursor: pointer;

  width: fit-content;

  margin-left: -7px;
  margin-right: -7px;
  margin-bottom: 0;
  padding-left: 7px;
  padding-right: 7px;

  &.disabled {
    pointer-events: none;
  }

  &.reversed {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

.a-toggle__inputs-wrapper {
  width: 38px;
  height: 20px;

  flex-shrink: 0;

  border-radius: 10px;
  background-color: $color-gray;
  position: relative;

  transition: all 0.15s ease-in-out;
  will-change: background-color;

  .a-toggle.active &,
  .a-toggle.active & {
    background-color: $cornflower-blue;
  }

  .a-toggle.active.disabled & {
    background-color: $cornflower-blue-dirty;

    // opacity: 0.8;
  }
}

.a-toggle__input {
  display: none;
}

.a-toggle__label {
  @include InterMedium;
  font-size: 14px;
  color: $color-black;

  padding-top: 1px;
  margin-top: -2px;

  .a-toggle.active &,
  .a-toggle.active & {
    @include InterSemibold;
  }

  .a-toggle.disabled & {
    color: $color-dark-gray;
  }
}

.a-toggle__label,
.a-toggle__inputs-wrapper {
  box-sizing: border-box;
  padding-left: 7px;
  padding-right: 7px;
}

.a-toggle__btn {
  position: absolute;
  top: 50%;
  left: 0;

  box-sizing: border-box;
  width: 20px;
  height: 20px;

  border: 2px solid $color-gray;
  border-radius: 50%;
  background-color: $color-white;
  outline: none;
  -webkit-appearance: none;

  transform: translate(0, -50%);

  transition: all 0.15s ease-in-out;
  will-change: left, right, background-color;

  .a-toggle.active &,
  .a-toggle.active & {
    left: 18px;
    border-color: $cornflower-blue;
  }

  .a-toggle.active.disabled & {
    border-color: $cornflower-blue-dirty;
    opacity: 0.7;
  }
}
</style>
