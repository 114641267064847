<template>
  <div>
    <APageAside
      :title="
        productionLineId
          ? 'Редактирование производственной линии'
          : 'Создание производственной линии'
      "
      wide
      @close="closeHandler"
    >
      <div v-if="productionLineId || creatingProductionLine || copyProductionLineId">
        <ProductionLineBlock
          :production-line-id="productionLineId"
          :creating-production-line="creatingProductionLine"
          :copy-production-line-id="copyProductionLineId"
          @close="closeHandler"
          @update:user="$emit('update:productionLine', $event)"
        />
      </div>
    </APageAside>
  </div>
</template>

<script>
import ProductionLineBlock from './ProductionLineBlock.vue';

export default {
  name: 'CreateProductionAsideBlock',
  components: {
    ProductionLineBlock,
  },
  props: {
    productionLineId: {
      type: Number,
      default: null,
    },
    copyProductionLineId: {
      type: Number,
      default: null,
    },
    creatingProductionLine: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'update:productionLine'],
  methods: {
    closeHandler() {
      this.$emit('close');
    },
  },
};
</script>
