<template>
  <Login />
</template>

<script>
import Login from '@/components/login';
export default {
  name: 'LoginPage',
  components: { Login },
};
</script>
<style lang="scss"></style>
