import { DESIGN_SOLUTION_STATUS } from '../../../constants';
import { cloneDeep } from '../../../utils/lodash';

export const NEW_DESIGN_SOLUTIONS = {
  [DESIGN_SOLUTION_STATUS.QUEUE]: [],
  [DESIGN_SOLUTION_STATUS.NEW]: [],
  [DESIGN_SOLUTION_STATUS.PROCESS]: [],
  [DESIGN_SOLUTION_STATUS.VALIDATE]: [],
};

export default () => ({
  allDesigners: [],

  designSolutions: cloneDeep(NEW_DESIGN_SOLUTIONS),

  selectedDesignSolution: null,
  selectedDesignSolutionCache: null,

  designMessages: [],
});
