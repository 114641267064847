<template>
  <div class="block-wrapper">
    <div v-if="localGrowType">
      <div v-if="localGrowType.deleted" class="entity-alert-deleted">Тип размера удален !</div>
      <div v-if="localGrowType" class="entity-title-block">
        <div class="entity-title-block__title">
          Тип размера:&nbsp;
          <span v-if="localGrowType.id" class="a-bold"> {{ localGrowType.id }}. </span>
          &emsp;{{ localGrowType.name }}
        </div>
      </div>
      <div v-else class="entity-title-block">
        <div class="entity-title-block__title">Новый тип размера</div>
      </div>
      <div class="entity-details my-20">
        <div class="entity-details_block">
          <AInput
            v-model="localGrowType.name"
            :read-only="!accessToChange"
            class="entity-details__desc"
            label="Название типа размера"
            :error="getErrMess('name')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import accessMixin from '@/mixin/accessMixin.js';
import validateMixin from '@/mixin/validateChild.js';

export default {
  name: 'GrowTypeBlock',
  mixins: [accessMixin, validateMixin],
  props: {
    selectedEntity: {
      type: Object,
      default: null,
    },
    creatingEntity: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:selectedEntity'],
  data() {
    return {};
  },
  computed: {
    localGrowType: {
      get() {
        return this.selectedEntity;
      },
      set(val) {
        this.$emit('update:selectedEntity', val);
      },
    },
    accessToChange() {
      return this.isCanChangeAssortment && !this.selectedEntity?.deleted && !this.loading;
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@include assortment-base-entity;
</style>
