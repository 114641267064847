import { PRODUCT_TYPE_COMPLEXITY } from '@/constants';

const PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS = {
  [PRODUCT_TYPE_COMPLEXITY.NO_COMPLEXITY]: `${PRODUCT_TYPE_COMPLEXITY.NO_COMPLEXITY}Quantity`,
  [PRODUCT_TYPE_COMPLEXITY.EASY]: `${PRODUCT_TYPE_COMPLEXITY.EASY}Quantity`,
  [PRODUCT_TYPE_COMPLEXITY.MEDIUM]: `${PRODUCT_TYPE_COMPLEXITY.MEDIUM}Quantity`,
  [PRODUCT_TYPE_COMPLEXITY.HARD]: `${PRODUCT_TYPE_COMPLEXITY.HARD}Quantity`,
  [PRODUCT_TYPE_COMPLEXITY.VERY_HARD]: `${PRODUCT_TYPE_COMPLEXITY.VERY_HARD}Quantity`,
};

const PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS = {
  [PRODUCT_TYPE_COMPLEXITY.NO_COMPLEXITY]: `${PRODUCT_TYPE_COMPLEXITY.NO_COMPLEXITY}QuantityPlan`,
  [PRODUCT_TYPE_COMPLEXITY.EASY]: `${PRODUCT_TYPE_COMPLEXITY.EASY}QuantityPlan`,
  [PRODUCT_TYPE_COMPLEXITY.MEDIUM]: `${PRODUCT_TYPE_COMPLEXITY.MEDIUM}QuantityPlan`,
  [PRODUCT_TYPE_COMPLEXITY.HARD]: `${PRODUCT_TYPE_COMPLEXITY.HARD}QuantityPlan`,
  [PRODUCT_TYPE_COMPLEXITY.VERY_HARD]: `${PRODUCT_TYPE_COMPLEXITY.VERY_HARD}QuantityPlan`,
};
const PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS = {
  [PRODUCT_TYPE_COMPLEXITY.NO_COMPLEXITY]: `${PRODUCT_TYPE_COMPLEXITY.NO_COMPLEXITY}QuantityDebt`,
  [PRODUCT_TYPE_COMPLEXITY.EASY]: `${PRODUCT_TYPE_COMPLEXITY.EASY}QuantityDebt`,
  [PRODUCT_TYPE_COMPLEXITY.MEDIUM]: `${PRODUCT_TYPE_COMPLEXITY.MEDIUM}QuantityDebt`,
  [PRODUCT_TYPE_COMPLEXITY.HARD]: `${PRODUCT_TYPE_COMPLEXITY.HARD}QuantityDebt`,
  [PRODUCT_TYPE_COMPLEXITY.VERY_HARD]: `${PRODUCT_TYPE_COMPLEXITY.VERY_HARD}QuantityDebt`,
};

const INIT_FILTERS = {
  text: null,
  timeToProduction: null,
  currentManagerIds: [],
  techDesignerIds: [],
  deadline: {
    dateRange: null,
    monthRange: null,
    currentMonthWithDebt: false,
  },
  createdCardDate: {
    dateRange: null,
    monthRange: null,
  },
  packingDate: {
    dateRange: null,
    monthRange: null,
  },
  techDesignDoneDate: {
    dateRange: null,
    monthRange: null,
  },
  tag: {
    include: [],
    exclude: [],
  },
  category: {
    include: [],
    exclude: [],
  },
  orderState: {
    include: [],
    exclude: [],
  },
};

const COLUMNS = [
  {
    label: 'T',
    key: 'tagId',
    class: 'tagId',
    sortable: true,
  },
  {
    label: 'Категория',
    key: 'category',
    class: 'category',
    sortable: true,
  },
  {
    label: 'id',
    key: 'guid',
    class: 'guid',
  },
  {
    label: 'Название заказа',
    key: 'name',
    class: 'name',
    sortable: true,
  },
  {
    label: 'amo',
    key: 'amoId',
    class: 'amoId',
  },
  {
    label: 'К-во парт.',
    key: 'cardsQuantity',
    class: 'cardsQuantity',
    sortable: true,
  },
  {
    label: 'К-во',
    key: 'allQuantity',
    class: 'allQuantity',
    sortable: true,
  },
  {
    label: 'Без пош.',
    key: PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.NO_COMPLEXITY],
    class: PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.NO_COMPLEXITY],
    linkedTo: 'allQuantity',
    sortable: true,
  },
  {
    label: 'Лег.',
    key: PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.EASY],
    class: PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.EASY],
    linkedTo: 'allQuantity',
    sortable: true,
  },
  {
    label: 'Ср.',
    key: PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.MEDIUM],
    class: PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.MEDIUM],
    linkedTo: 'allQuantity',
    sortable: true,
  },
  {
    label: 'Сл.',
    key: PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.HARD],
    class: PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.HARD],
    linkedTo: 'allQuantity',
    sortable: true,
  },
  {
    label: 'Оч. сл.',
    key: PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.VERY_HARD],
    class: PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.VERY_HARD],
    linkedTo: 'allQuantity',
    sortable: true,
  },
  {
    label: 'Менеджер',
    key: 'managerName',
    class: 'managerName',
    sortable: true,
  },
  {
    label: 'Время на пр.',
    key: 'timeToProduction',
    class: 'timeToProduction',
    sortable: true,
  },
  {
    label: 'Отгрузка',
    key: 'deadline',
    class: 'deadline',
    type: 'date',
    sortable: true,
  },
  {
    label: 'Упаковка',
    key: 'packageDate',
    class: 'packageDate',
    type: 'date',
    sortable: true,
  },
  {
    label: 'Статус',
    key: 'status',
    class: 'status',
    sortable: true,
  },
  {
    label: 'Дизайнер',
    key: 'designerName',
    class: 'designerName',
    sortable: true,
  },
  {
    label: 'Карты',
    key: 'createdCardDate',
    class: 'createdCardDate',
    type: 'date',
    sortable: true,
  },
  {
    label: 'Тиффы',
    key: 'techDesignDoneDate',
    class: 'techDesignDoneDate',
    type: 'date',
    sortable: true,
  },
];

const SUMMARY_COLUMNS = [
  {
    label: 'Отгрузка',
    key: 'deadline',
    class: 'deadline',
    sortable: true,
  },
  {
    label: 'План',
    key: 'allQuantityPlan',
    class: 'allQuantityPlan',
    sortable: true,
  },
  {
    label: 'Без пош.',
    key: PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[PRODUCT_TYPE_COMPLEXITY.NO_COMPLEXITY],
    class:
      PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[PRODUCT_TYPE_COMPLEXITY.NO_COMPLEXITY],
    sortable: true,
    linkedTo: 'allQuantity',
  },
  {
    label: 'Лег.',
    key: PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[PRODUCT_TYPE_COMPLEXITY.EASY],
    class: PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[PRODUCT_TYPE_COMPLEXITY.EASY],
    sortable: true,
    linkedTo: 'allQuantity',
  },
  {
    label: 'Ср.',
    key: PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[PRODUCT_TYPE_COMPLEXITY.MEDIUM],
    class: PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[PRODUCT_TYPE_COMPLEXITY.MEDIUM],
    sortable: true,
    linkedTo: 'allQuantity',
  },
  {
    label: 'Сл.',
    key: PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[PRODUCT_TYPE_COMPLEXITY.HARD],
    class: PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[PRODUCT_TYPE_COMPLEXITY.HARD],
    sortable: true,
    linkedTo: 'allQuantity',
  },
  {
    label: 'Оч. сл.',
    key: PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[PRODUCT_TYPE_COMPLEXITY.VERY_HARD],
    class: PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[PRODUCT_TYPE_COMPLEXITY.VERY_HARD],
    sortable: true,
    linkedTo: 'allQuantity',
  },
  {
    label: 'Долг',
    key: 'allQuantityDebt',
    class: 'allQuantityDebt',
    sortable: true,
  },
  {
    label: 'Без пош.',
    key: PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[PRODUCT_TYPE_COMPLEXITY.NO_COMPLEXITY],
    class:
      PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[PRODUCT_TYPE_COMPLEXITY.NO_COMPLEXITY],
    sortable: true,
    linkedTo: 'allQuantity',
  },
  {
    label: 'Лег.',
    key: PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[PRODUCT_TYPE_COMPLEXITY.EASY],
    class: PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[PRODUCT_TYPE_COMPLEXITY.EASY],
    sortable: true,
    linkedTo: 'allQuantity',
  },
  {
    label: 'Ср.',
    key: PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[PRODUCT_TYPE_COMPLEXITY.MEDIUM],
    class: PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[PRODUCT_TYPE_COMPLEXITY.MEDIUM],
    sortable: true,
    linkedTo: 'allQuantity',
  },
  {
    label: 'Сл.',
    key: PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[PRODUCT_TYPE_COMPLEXITY.HARD],
    class: PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[PRODUCT_TYPE_COMPLEXITY.HARD],
    sortable: true,
    linkedTo: 'allQuantity',
  },
  {
    label: 'Оч. сл.',
    key: PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[PRODUCT_TYPE_COMPLEXITY.VERY_HARD],
    class: PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[PRODUCT_TYPE_COMPLEXITY.VERY_HARD],
    sortable: true,
    linkedTo: 'allQuantity',
  },
  {
    label: 'Готово',
    key: 'allQuantityCompleted',
    class: 'allQuantityCompleted',
    sortable: true,
  },
];

export {
  COLUMNS,
  INIT_FILTERS,
  SUMMARY_COLUMNS,
  PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS,
  PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS,
  PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS,
};
