<template>
  <APageLayout>
    <template #header>
      <div class="page-header">
        <div class="page-header__container">
          <div class="row">
            <div class="mr-40">Аналитика</div>
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <div class="content-wrapper">
        <MonitoringDepartmentReport :loading="loading" />
        <MonitoringPlanFactPackagingBlock :loading="loading" />
      </div>
    </template>
  </APageLayout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import MonitoringDepartmentReport from './components/monitoringDepartmentReport.vue';
import MonitoringPlanFactPackagingBlock from './components/monitoringPlanFactPackagingBlock.vue';

export default {
  name: 'Analytics',
  components: {
    MonitoringPlanFactPackagingBlock,
    MonitoringDepartmentReport,
  },
  data() {
    return {
      loading: false,
      startDate: null,
      endDate: null,
    };
  },
  computed: {
    ...mapState({
      lastAppVersion: (s) => s.mobile.lastAppVersion,
    }),
  },
  async created() {
    // await this.bootstrap();
  },
  methods: {
    ...mapActions({
      getMonitorngDepartmentReport: 'analytics/getMonitorngDepartmentReport',
    }),
    async getMonitorngDepartmentReportHandler() {
      this.loading = true;
      try {
        await this.getMonitorngDepartmentReport({
          startDate: this.startDate,
          endDate: this.endDate,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async bootstrap() {
      try {
        await this.getLastAppVersion();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page-header {
  @include InterBold;
  font-size: 18px;
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 36px;
  position: relative;

  &__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.content-wrapper {
  display: flex;
  flex-direction: column;
}
</style>
