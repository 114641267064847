import cardApi from '../../../api/cardApi';
import orderApi from '../../../api/orderApi';

export default {
  async getAllMonitoringOrders({ state, commit }, { force = false } = {}) {
    if (state.orders.length && !force) {
      return;
    }
    const { data } = await orderApi.getAllMonitoringOrders();
    commit('SET_ALL_ORDERS', data);
  },

  async getAllMonitoringCardDetails({ commit }, { card }) {
    const { data } = await cardApi.getAllMonitoringCardDetails({ cardId: card.guid });
    commit('SET_MONITORING_CARD_DETAILS', { data, card });
  },

  async patchCard({ commit }, { card }) {
    const data = await cardApi.patchCard({ card });
    commit('PATCH_MONITORING_CARD', data);
  },
};
