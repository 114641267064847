import { reactive, computed, watch } from 'vue';
import {
  COLUMNS,
  SUMMARY_COLUMNS,
} from '../../components/productionTable/productionTableDictionary';
import { cloneDeep } from '../../utils/lodash';
import { storage, STORAGE_KEY, STORAGE_PATH } from '../../utils/localStorage';

export function useProductionTableColumnController() {
  const columnsDefault = cloneDeep(COLUMNS);
  const summaryColumnsDefault = cloneDeep(SUMMARY_COLUMNS);

  const quantityDetails = {
    label: 'Детализация кол-ва',
    key: 'quantityDetails',
  };
  const selectedColumnsDictionary = [
    {
      ...cloneDeep(COLUMNS.find((c) => c.key === 'deadline')),
      deleted: true,
    },
    quantityDetails,
    ...cloneDeep(COLUMNS.filter((c) => c.linkedTo !== 'allQuantity' && c.key !== 'deadline')),
  ];

  const displayCustomization = reactive({
    columnSetting: reactive(
      storage({
        key: STORAGE_KEY.PRODUCTION_TABLE,
        path: STORAGE_PATH.PRODUCTION_TABLE_SETTING_EXCLUDED_COLUMN,
      }) || []
    ),
  });

  const isDetailsQuantityExclude = computed(() =>
    displayCustomization.columnSetting.includes('quantityDetails')
  );
  const columns = computed(() =>
    columnsDefault.filter((c) =>
      c.linkedTo === 'allQuantity'
        ? !isDetailsQuantityExclude.value
        : !displayCustomization.columnSetting.includes(c.key)
    )
  );

  const summaryColumns = computed(() =>
    summaryColumnsDefault.filter((c) =>
      isDetailsQuantityExclude.value ? c.linkedTo !== 'allQuantity' : true
    )
  );

  watch(
    () => displayCustomization,
    (value) => {
      storage(
        {
          key: STORAGE_KEY.PRODUCTION_TABLE,
          path: STORAGE_PATH.PRODUCTION_TABLE_SETTING_EXCLUDED_COLUMN,
        },
        value.columnSetting
      );
    },
    { deep: true }
  );

  const showDisplayCustomizationBudge = computed(() => displayCustomization.columnSetting.length);

  return {
    columns,
    summaryColumns,

    showDisplayCustomizationBudge,

    displayCustomization,
    displayCustomizationDictionary: { columnSetting: cloneDeep(selectedColumnsDictionary) },
  };
}
