<template>
  <DesignSolution />
</template>

<script>
import DesignSolution from '@/components/designSolution';
export default {
  name: 'DesignSolutionPage',
  components: { DesignSolution },
};
</script>
