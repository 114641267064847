<template>
  <div
    v-click-away="close"
    class="a-simple-table-select"
    :class="{
      open: isOpen,
      disabled,
      readOnly,
      errorBorder,
    }"
    :key="`a-simple-table-select--${uuid}`"
  >
    <div class="a-simple-table-select__wrapper">
      <label :for="`a-simple-table-select-${uuid}`">
        <div class="a-simple-table-select__el-wrapper">
          <input
            v-show="showPlaceholder"
            :value="selectedLabel"
            :tabindex="disabled ? -1 : 0"
            autocomplete="off"
            type="text"
            v-bind="$attrs"
            class="a-simple-table-select__el placeholder"
            :placeholder="$attrs.placeholder"
            @focus="toggleOpened"
            :readonly="readOnly"
          />
          <input
            v-show="isOpen"
            :id="`a-simple-table-select-${uuid}`"
            v-model="filter"
            :tabindex="disabled ? -1 : 0"
            autocomplete="off"
            type="text"
            class="a-simple-table-select__el filter"
            placeholder=""
            :readonly="readOnly"
          />
        </div>
      </label>
    </div>
    <div class="a-simple-table-select__options-wrapper">
      <ul class="a-simple-table-select__options-list">
        <template v-if="filteredOptions.length">
          <li
            v-for="(item, idx) in filteredOptions"
            :key="idx"
            class="a-simple-table-select__options-tab"
            :class="{
              'a-simple-table-select__options-tab--active': isActiveItem(item),
            }"
            @click="selectItem(item)"
          >
            {{ item }}
          </li>
        </template>
        <li
          v-else-if="isOpen"
          key="no-options"
          class="a-simple-table-select__options-tab"
        >
          <p class="no-options">—</p>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import uuid from "../../mixin/uuid.js";

export default {
  name: "ASimpleTableSelect",
  mixins: [uuid],
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: [Array, Object, String, Number],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    errorBorder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: null,
      filter: null,
      isOpen: false,
    };
  },
  computed: {
    filteredOptions() {
      let result = null;
      if (this.filter) {
        result = this.options.filter(
          (item) =>
            String(item)
              .toLowerCase()
              .indexOf(String(this.filter).toLowerCase()) > -1
        );
      } else {
        result = this.options;
      }
      return result;
    },
    selectedLabel() {
      return this.data;
    },
    showPlaceholder() {
      return !this.isOpen;
    },
  },
  watch: {
    modelValue: function () {
      this.updateFromProp();
    },
    data: function () {
      this.$emit("update:modelValue", this.data);
      this.$emit("change", this.data);
    },
  },
  beforeMount() {
    this.updateFromProp();
  },
  methods: {
    close() {
      this.isOpen = false;
    },
    toggleOpened() {
      if (this.readOnly) return;
      this.isOpen = !this.isOpen;
    },
    updateFromProp() {
      if (this.modelValue) {
        this.data = this.modelValue;
      } else {
        this.data = null;
      }
    },
    isActiveItem(item) {
      return String(item) === String(this.data);
    },
    selectItem(item) {
      String(item) === String(this.data)
        ? (this.data = null)
        : (this.data = item);
      this.close();
    },
  },
};
</script>
<style lang="scss" scoped>
.a-simple-table-select {
  display: flex;
  flex-direction: column;
  position: relative;

  max-width: 100%;
  width: 100%;

  margin-bottom: 0;

  @include InterSemibold;

  font-size: 14px;

  &:not(.open):not(.readOnly) {
    .a-simple-table-select {
      &__el-wrapper {
        &::after {
          position: absolute;
          top: 37%;
          right: 10px;

          height: 2px;
          width: 2px;

          content: "";
          border: solid $color-gray;
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 2px;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
        }
      }
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.6;
    .a-simple-table-select__label {
      color: $color-gray;
    }
  }
  &__wrapper {
    position: relative;
  }

  &__el {
    position: relative;

    display: inline-block;

    color: $color-black;

    box-sizing: border-box;
    height: 40px;
    max-width: 100%;
    width: 100%;

    overflow: hidden;

    line-height: 40px;
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;

    border: none;
    border-color: transparent;
    outline: none;

    padding: 13px 15px 13px 15px;

    transition: all 0.2s;

    -webkit-appearance: none;

    &.filter::placeholder {
      color: $color-gray;
    }
  }

  &__options-wrapper {
    position: relative;
    z-index: 10;
  }

  &__options-list {
    z-index: 10;
    @include boxShadow;

    font-size: 14px;

    margin: 0;
    padding: 0;
    list-style: none;

    @include scroll;

    background-color: $color-white;

    overflow-y: auto;
    overflow-x: hidden;
    max-height: 0px;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transition: max-height 0.2s;

    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &__options-tab {
    position: relative;
    padding: 0;
    margin: 0;
    padding-left: 10px;
    width: 100%;
    box-sizing: border-box;
    height: 40px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    cursor: pointer;
    transition: background-color 0.2s;
    &:hover {
      background-color: $color-bg-light-blue;
    }

    &--active {
      background-color: $color-bg-light-blue-active;
      &:hover {
        color: $color-red;
      }
    }
    .no-options {
      width: 100%;
      @include noSelect;
      cursor: auto;
    }
  }
  // &.readOnly {
  //   .a-simple-table-select {
  //     &__el {
  //       border: dashed 1px $color-light-gray;
  //       background-color: $color-white;
  //     }
  //   }
  // }

  &.errorBorder {
    // .a-select__wrapper {
    outline: 1px solid $color-red;
    // }
  }
  &__options-icon {
    width: 13px;
  }
  &__options-tab:not(:last-child) {
    border-bottom: 1px solid $color-bg-smoke-active;
  }
  &.open {
    &:not(.errorBorder) {
      outline: 1px solid $color-bg-smoke-active;
    }
    .a-simple-table-select {
      &__options-list {
        max-height: 200px;
        margin-bottom: 100px;
      }
      &__el {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}
</style>
