<template>
  <div>
    <div
      :ref="`a-tooltip-${uuid}-item`"
      class="button"
      aria-describedby="tooltip"
    >
      <Fa icon="info-circle" class="qicon" style="width: 17px; height: 17px" />
    </div>
    <div :ref="`a-tooltip-${uuid}-content`" class="tooltip" role="tooltip">
      <div class="tooltip__content">
        {{ qtitle }}
        <slot />
      </div>
      <div class="arrow" data-popper-arrow></div>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import uuid from "../../mixin/uuid.js";

export default {
  name: "Tooltip",
  mixins: [uuid],
  props: {
    qtitle: {
      type: String,
      default: null,
    },
    position: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      styleToltip: {
        position: null,
      },
    };
  },
  mounted() {
    this.initPopover();
  },
  methods: {
    initPopover() {
      const button = this.$refs[`a-tooltip-${this.uuid}-item`];
      const tooltip = this.$refs[`a-tooltip-${this.uuid}-content`];
      const options = {
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 10],
            },
          },
        ],
      };
      if (this.position) options.placement = this.position;
      const popperInstance = createPopper(button, tooltip, options);

      function show() {
        // Make the tooltip visible
        tooltip.setAttribute("data-show", "");

        // Enable the event listeners
        popperInstance.setOptions((options) => ({
          ...options,
          modifiers: [
            ...options.modifiers,
            { name: "eventListeners", enabled: true },
          ],
        }));

        // Update its position
        popperInstance.update();
      }

      function hide() {
        // Hide the tooltip
        tooltip.removeAttribute("data-show");

        // Disable the event listeners
        popperInstance.setOptions((options) => ({
          ...options,
          modifiers: [
            ...options.modifiers,
            { name: "eventListeners", enabled: false },
          ],
        }));
      }

      const showEvents = ["mouseenter", "focus"];
      const hideEvents = ["mouseleave", "blur"];

      showEvents.forEach((event) => {
        button.addEventListener(event, show);
      });

      hideEvents.forEach((event) => {
        button.addEventListener(event, hide);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  position: relative;
  display: block;
  color: $color-light-gray;
}
.tooltip {
  background: $color-bg-smoke;
  color: $smoke-blue;
  font-weight: bold;

  font-size: 13px;
  border-radius: 4px;
  padding: 0;
  margin: 0;
  display: block;
  visibility: hidden;
  opacity: 0;

  // max-width: min-content;
  &__content {
    padding: 4px 8px;
  }
  transition: opacity 0.15s ease-out;

  z-index: 100;
}

.tooltip[data-show] {
  display: block;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s ease-out;
}

.arrow:after {
  content: " ";
  position: absolute;
  top: 50%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
}

.tooltip[data-popper-placement^="top"] > .arrow {
  bottom: -4px;
  &::after {
    top: 100%;
    border-color: $color-bg-smoke transparent transparent transparent;
  }
}

.tooltip[data-popper-placement^="bottom"] > .arrow {
  top: -4px;
  &::after {
    bottom: 100%;
    border-color: transparent transparent $color-bg-smoke transparent;
  }
}

.tooltip[data-popper-placement^="left"] > .arrow {
  right: 0px;
  &::after {
    left: 100%;
    border-color: transparent transparent transparent $color-bg-smoke;
  }
}

.tooltip[data-popper-placement^="right"] > .arrow {
  left: 0px;
  &::after {
    right: 100%;
    border-color: transparent $color-bg-smoke transparent transparent;
  }
}
</style>
