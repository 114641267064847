import BaseService from './services/BaseService.js';

class designerApi extends BaseService {
  async getDesignSolutions() {
    const data = await this.api.get('/design/design-solution');
    return data;
  }
  async getDesignSolution({ designSolutionId }) {
    const data = await this.api.get(`/design/design-solution/${designSolutionId}`);
    return data;
  }
  async sendDesignSolutionMessage({ message, designSolutionId }) {
    const data = await this.api.post(`/design/design-solution-messages/${designSolutionId}`, {
      message,
    });
    return data;
  }
  async updateDesignSolution({ designSolution }) {
    const data = await this.api.post(`/design/design-solution/${designSolution.guid}`, {
      designSolution,
    });
    return data;
  }
  async deleteDesignSolution({ designSolutionId, originalDesignSolutionId }) {
    const data = await this.api.delete(`/design/design-solution/${designSolutionId}`, {
      originalDesignSolutionId,
    });
    return data;
  }
  async changeStateAndDesignerInDesignSolution({
    designSolutionId,
    orderId,
    currentState,
    designerId,
    newState,
  }) {
    await this.api.patch(`/design/design-solution`, {
      designSolutionId,
      orderId,
      currentState,
      designerId,
      newState,
    });
  }

  async changeDesinSolutionMockupStatus({ messageId, newMockupStatus, designSolutionId }) {
    const data = await this.api.patch(`/design/design-solution-messages/${designSolutionId}`, {
      messageId,
      newMockupStatus,
    });
    return data;
  }

  async getDesignMessages({ designSolutionId }) {
    const data = await this.api.get('/design/design-solution-messages', {
      params: { designSolutionId },
    });
    return data;
  }
}
export default new designerApi();
