<template>
  <div v-if="isActive || !lazy" v-show="isActive">
    <slot></slot>
  </div>
</template>

<script>
import { onBeforeMount, inject, ref, watch } from "vue";

export default {
  name: "ATab",
  setup() {
    const index = ref(0);
    const isActive = ref(false);

    const tabs = inject("ATabsProvider");

    watch(
      () => tabs.selectedIndex,
      () => {
        isActive.value = index.value === tabs.selectedIndex;
      }
    );

    onBeforeMount(() => {
      index.value = tabs.count;
      tabs.count++;
      isActive.value = index.value === tabs.selectedIndex;
    });
    return { index, isActive };
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dotted: {
      type: Boolean,
      default: false,
    },
    tabValue: {
      type: String,
      default: null,
    },
  },
};
</script>
