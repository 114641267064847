<template>
  <div class="block-wrapper">
    <div v-if="localUser && localUser" class="user">
      <div v-if="localUser.deleted" class="user-alert-deleted">Пользователь удален !</div>
      <div v-if="!creatingUser" class="user-mockups">
        <img
          v-if="localUser.avatarId"
          :src="getLink(user.avatarId)"
          alt="аватар пользователя"
          class="user-mockups__front"
        />
        <div v-else class="no-image">
          <Fa
            name="icon"
            icon="image"
            class="icon faicon empty-product"
            style="width: 60px; height: 60px"
          />
        </div>
      </div>
      <div class="user-details">
        <div class="user-details_block mb-20">
          <AInput
            v-model="localUser.contacts.phone"
            v-maska="'+7 (###) ###-##-##'"
            :read-only="!accessToChange"
            label="Телефон"
            :error="getErrMess('contacts.phone')"
            class="mr-15"
          />
          <AInput
            v-model="localUser.contacts.email"
            :read-only="!accessToChange"
            label="Почта"
            :error="getErrMess('contacts.email')"
          />
        </div>
        <div class="user-details_block mb-20">
          <AInput
            v-model="localUser.surname"
            :read-only="!accessToChange"
            label="Фамилия"
            :error="getErrMess('surname')"
            class="mr-15"
          />
          <AInput
            v-model="localUser.name"
            :read-only="!accessToChange"
            label="Имя"
            :error="getErrMess('name')"
            class="mr-15"
          />
          <AInput
            v-model="localUser.patronymic"
            :read-only="!accessToChange"
            label="Отчество"
            :error="getErrMess('patronymic')"
          />
        </div>

        <div class="user-details_block currency mb-20">
          <div class="mr-20">
            <ASelect
              v-model="localUser.type"
              :options="NEW_USER_TYPES"
              label="Тип пользователя"
              track-key="value"
              select-label="name"
              :read-only="!accessToChange || localUser.type === USER_TYPES.MAIN_EMPLOYEE"
              :error="getErrMess('type')"
              disable-deleted
            />
          </div>
          <div v-if="needCurrency">
            <ASelect
              v-model="localUser.currency"
              :options="CURRENCIES"
              label="Валюта"
              track-key="value"
              select-label="name"
              :read-only="!accessToChange"
              :error="getErrMess('currency')"
            />
          </div>
        </div>

        <div v-if="needDepartments" class="user-details_block departments">
          <div class="mr-20">
            <ASelect
              v-model="localUser.departmentIds"
              :options="departments"
              label="Ответв. за отделы"
              select-label="name"
              :error="getErrMess(`departmentIds`)"
              multiple
            />
            <!-- generate-select-tag-from-options-key="name" -->
          </div>
        </div>
        <div class="user-details_block mt-20">
          <AButton v-if="accessToChange" success :disabled="!islocalUserModified" @click="saveUser">
            Сохранить
          </AButton>
          <AButton
            v-if="
              (isAdmin || isMainManager || isMainEmployee) &&
              !creatingUser &&
              !userLoading &&
              (isEmployee || isMainEmployeeUser)
            "
            :disabled="islocalUserModified"
            :danger="!localUser.deleted"
            :success="localUser.deleted"
            class="ml-20"
            @click="deleteUserHandler(!localUser.deleted)"
          >
            {{ localUser.deleted ? 'Восстановить' : 'Удалить' }}
          </AButton>
        </div>
        <ALoader v-if="userLoading" centered green />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { cloneDeep, isEqual } from '@/utils/lodash.js';
import { CURRENCY, CURRENCIES, USER_TYPES_DICTIONARY } from '@/constants/index.js';
import { getLink } from '@/utils/getStorageLink.js';
import accessMixin from '@/mixin/accessMixin.js';
import { userBlockSchema, employeeBlockSchema } from '@/utils/validate/index.js';
import validateMixin from '@/mixin/validate.js';
import ConfirmDialog from '@@/components/Confirm/index.vue';
import { USER_TYPES } from '../../constants';

const contacts = {
  phone: null,
  email: null,
};

const NEW_EMPTY_USER = {
  guid: null,
  name: null,
  patronymic: null,
  surname: null,
  contacts: cloneDeep(contacts),
  currentManagerId: null,
  type: null,
  currency: CURRENCY.RUR,
  deleted: false,
  departmentIds: [],
  isNewItem: true,
};

export default {
  name: 'UserBlock',
  mixins: [accessMixin, validateMixin],
  props: {
    userId: {
      type: String,
      default: null,
    },
    creatingUser: {
      type: Boolean,
      default: false,
    },
    defaultEmail: {
      type: String,
      default: null,
    },
    defaultPhone: {
      type: String,
      default: null,
    },
    creatingUserTypes: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['close', 'update:user'],
  data() {
    return {
      userLoading: false,
      user: null,

      localUser: null,
      errors: null,
      api_user: null,
    };
  },
  computed: {
    ...mapState({
      departments: (s) => s.production.departments,
    }),
    accessToChange() {
      return (
        (this.isAdmin || this.isManager || this.isMainManager || this.isMainEmployee) &&
        !this.localUser?.deleted &&
        !this.userLoading
      );
    },
    islocalUserModified() {
      return !isEqual(this.api_user, {
        ...this.localUser,
        contacts: {
          ...this.localUser.contacts,
          phone: String(
            +String(this.localUser.contacts.phone)
              .replace(/[^0-9.]/gi, '')
              .slice(1)
          ),
        },
      });
    },
    isMainEmployeeUser() {
      return this.api_user?.type === USER_TYPES.MAIN_EMPLOYEE;
    },
    isEmployee() {
      return this.api_user?.type === USER_TYPES.EMPLOYEE;
    },
    needDepartments() {
      return [USER_TYPES.EMPLOYEE, USER_TYPES.MAIN_EMPLOYEE].includes(this.localUser?.type);
    },
    needCurrency() {
      return this.localUser?.type === USER_TYPES.CUSTOMER;
    },
  },
  watch: {
    // userId: function (userId) {
    //   if (!userId) return;
    //   this.getUserHandler(userId);
    // },
    // user: function (user) {
    //   this.updateFromProp(user);
    // },
  },
  created() {
    this.CURRENCIES = CURRENCIES;
    this.USER_TYPES = USER_TYPES;
    this.NEW_USER_TYPES = this.creatingUserTypes.map((t) => ({
      name: USER_TYPES_DICTIONARY[t],
      value: t,
      deleted: t === USER_TYPES.MAIN_EMPLOYEE,
    }));
    this.USER_COMPONENT_TYPE = this.NEW_USER_TYPES[0].value;
    this.getLink = getLink;
    this.updateFromProp();
  },
  methods: {
    ...mapActions({
      getUser: 'user/getUserToComponent',
      getEmployee: 'user/getAllEmployeeToComponent',
      changeEmployee: 'user/changeEmployee', // TODO: changeEmployeeToComponent?
      createUser: 'user/createUserToComponent',
      changeUser: 'user/changeUserToComponent',
      deleteEmployee: 'user/createUserToComponent',
    }),
    handlerController(action = 'get') {
      const handlers = {
        get: {
          default: this.getUser,
          [USER_TYPES.CUSTOMER]: this.getUser,
          [USER_TYPES.EMPLOYEE]: this.getEmployee,
        },
        create: {
          default: this.createUser,
          [USER_TYPES.CUSTOMER]: this.createUser,
          [USER_TYPES.EMPLOYEE]: this.changeEmployee,
        },
        change: {
          default: this.changeUser,
          [USER_TYPES.CUSTOMER]: this.changeUser,
          [USER_TYPES.EMPLOYEE]: this.changeEmployee,
        },
        delete: {
          // default: this.changeUser,
          // [USER_TYPES.CUSTOMER]: this.changeUser,
          [USER_TYPES.EMPLOYEE]: this.deleteEmployee,
        },
      };

      if (action === 'get') {
        return handlers.get[this.USER_COMPONENT_TYPE] || handlers.get.default;
      }
      if (action === 'create') {
        return handlers.create[this.USER_COMPONENT_TYPE] || handlers.create.default;
      }
      if (action === 'change') {
        return handlers.change[this.USER_COMPONENT_TYPE] || handlers.change.default;
      }
    },
    async getUserHandler(userId) {
      try {
        this.userLoading = true;
        const handler = this.handlerController('get');
        const user = await handler({ userId });
        this.user = user;
        this.api_user = user;
      } catch (error) {
        console.log(error);
      } finally {
        this.userLoading = false;
      }
    },
    async saveUser() {
      const schema =
        this.USER_COMPONENT_TYPE == USER_TYPES.EMPLOYEE ? employeeBlockSchema : userBlockSchema;
      const formValidation = this.$form(this.localUser, schema);
      if (!formValidation.isValid) {
        this.$notifyError({ title: `Ошибка валидации формы` });
        return;
      }
      const cachedPhone = this.localUser.contacts.phone;
      this.localUser.contacts.phone = this.localUser.contacts.phone
        ? String(
            +String(this.localUser.contacts.phone)
              .replace(/[^0-9.]/gi, '')
              .slice(1)
          )
        : null;
      this.userLoading = true;
      try {
        const handler = this.handlerController(this.creatingUser ? 'create' : 'change');
        const data = await handler({ user: this.localUser });
        this.$emit('update:user', data);
        this.closePage();
        this.$notify({ title: 'Сохранено' });
      } catch (err) {
        console.log(err);
        this.localUser.contacts.phone = cachedPhone;
        // if (deleteOrRestore) {
        //   this.localUser.deleted = !this.localUser.deleted;
        // }
      } finally {
        this.userLoading = false;
      }
    },
    deleteUserHandler(needDeleted) {
      if (this.islocalUserModified) {
        this.$notifyError({
          title: `Сбросте (или сохраните) все изменения в пользователе, а затем удалите его`,
        });
        return;
      }
      this.$dialog.addDialog({
        component: ConfirmDialog,
        props: {
          title: needDeleted ? 'Удаление пользователя' : 'Восстановление пользователя',
          text: `Вы действительно хотите ${needDeleted ? 'удалить' : 'восстановить'} пользователя?`,
          onConfirm: (confirm) => {
            confirm.showModal = false;
            this.localUser.deleted = needDeleted;
            this.saveUser(true);
          },
        },
      });
    },
    closePage() {
      this.$emit('close');
    },
    // saveUserHandler() {
    //   this.$dialog.addDialog({
    //     component: ConfirmDialog,
    //     props: {
    //       title: 'Сохранить изменения',
    //       text: 'Применить выбранные изменения?',
    //       onConfirm: (confirm) => {
    //         confirm.showModal = false;
    //         this.saveUser();
    //       },
    //     },
    //   });
    // },
    async updateFromProp(user) {
      if (!user && this.userId) {
        await this.getUserHandler(this.userId);
      }
      const newUser = {
        ...NEW_EMPTY_USER,
        ...(NEW_EMPTY_USER.type ? {} : { type: this.NEW_USER_TYPES[0].value }),
      };
      const value = this.creatingUser ? newUser : user || this.user;
      if (this.creatingUser && !value.phone && this.defaultPhone) {
        value.contacts.phone = this.defaultPhone;
      }
      if (this.creatingUser && !value.phone && this.defaultEmail) {
        value.contacts.email = this.defaultEmail;
      }

      this.localUser = cloneDeep(value);
    },
    getMockupLink(url) {
      if (!url) return null;
      return getLink(url);
    },
  },
};
</script>
<style lang="scss" scoped>
.user {
  &-alert-deleted {
    background-color: $color-red;
    padding: 8px 0px;
    border-radius: 8px;
    text-align: center;
    margin-bottom: 10px;

    @include InterSemibold;
  }
  &-mockups {
    display: flex;
    flex-direction: row;
    padding: 20px 0px;
    img {
      height: 250px;
    }
  }
  &-details {
    display: flex;
    flex-direction: column;

    .user-details_block {
      display: flex;
      flex-direction: row;
    }

    &__desc {
      height: fit-content;
    }
  }
}
.validation-error-items {
  color: $color-red;
  margin-left: 15px;
}
.no-image {
  display: flex;
  justify-content: center;
  align-self: center;
  min-width: 170px;

  color: $color-bg-smoke-active;
}
</style>
