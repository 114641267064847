<template>
  <Monitoring />
</template>

<script>
import Monitoring from '@/components/monitoring';
export default {
  name: 'MonitoringPage',
  components: { Monitoring },
};
</script>
