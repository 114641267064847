<template>
  <div class="production-summary-table-block-wrapper" :style="cssVars">
    <div v-if="globalLoading">
      <ALoader centered />
    </div>
    <div v-else>
      <table
        v-if="pTableOrders"
        class="production-summary-table-orders-table"
        :class="{ ['highlight-main-col']: displayCustomization.showQuantityDetails }"
        @mousedown="mousedownHandler"
        @mouseover="mouseoverHandler"
      >
        <tr class="headers">
          <th
            v-for="column of columns"
            :key="column.key"
            :class="{
              [column.class]: column.class,
              ['main-col']: !column.linkedTo,
              sortable: column.sortable,
              active: sort.sortBy === column.key,
              reverse: sort.reverse,
              pointer: column.sortable,
            }"
            @click="column.sortable ? sortByHandler(column.key) : null"
          >
            <div
              class="title-text-wrapper"
              :class="{
                [column.class]: column.class,
              }"
            >
              <span>
                {{ column.label }}
              </span>
              <span
                v-if="column.sortable && sort.sortBy !== column.key"
                class="title-icon-group-wrapper"
              >
                <i class="fa fa-angle-up title-icon" style="font-size: 9px" title="сортировать"></i>
                <i
                  class="fa fa-angle-down title-icon"
                  style="font-size: 9px"
                  title="сортировать"
                ></i>
              </span>
              <span v-else-if="column.sortable" class="title-icon-wrapper">
                <i class="fa fa-angle-up title-icon" style="font-size: 9px" title="сортировать"></i>
              </span>
            </div>
          </th>
        </tr>

        <tr
          v-for="(item, ind) of pTableOrders"
          :key="item.deadline"
          :class="{ weekend: isWeekend(item.deadline) }"
        >
          <td
            :data-table-value="`deadline__${ind}`"
            :class="{
              selectCell: isSelectCell('deadline', ind),
              'deadline-expired': item.isDeadlineExpired,
            }"
            class="deadline"
          >
            <div>{{ item.deadline }}</div>
          </td>

          <td
            :data-table-value="`allQuantityPlan__${ind}`"
            :class="{ selectCell: isSelectCell('allQuantityPlan', ind) }"
            class="allQuantityPlan main-col"
          >
            <div>
              {{ item.allQuantityPlan }}
            </div>
          </td>
          <td
            v-if="showQuantityDetails"
            :data-table-value="`${
              PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[
                PRODUCT_TYPE_COMPLEXITY.NO_COMPLEXITY
              ]
            }__${ind}`"
            :class="{
              [PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[
                PRODUCT_TYPE_COMPLEXITY.NO_COMPLEXITY
              ]]: true,
              selectCell: isSelectCell(
                PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[
                  PRODUCT_TYPE_COMPLEXITY.NO_COMPLEXITY
                ],
                ind
              ),
            }"
          >
            <div>
              {{ item.noComplexityQuantityPlan }}
            </div>
          </td>
          <td
            v-if="showQuantityDetails"
            :data-table-value="`${
              PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[PRODUCT_TYPE_COMPLEXITY.EASY]
            }__${ind}`"
            :class="{
              [PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[
                PRODUCT_TYPE_COMPLEXITY.EASY
              ]]: true,
              selectCell: isSelectCell(
                PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[PRODUCT_TYPE_COMPLEXITY.EASY],
                ind
              ),
            }"
          >
            <div>
              {{ item.easyQuantityPlan }}
            </div>
          </td>
          <td
            v-if="showQuantityDetails"
            :data-table-value="`${
              PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[PRODUCT_TYPE_COMPLEXITY.MEDIUM]
            }__${ind}`"
            :class="{
              [PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[
                PRODUCT_TYPE_COMPLEXITY.MEDIUM
              ]]: true,
              selectCell: isSelectCell(
                PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[PRODUCT_TYPE_COMPLEXITY.MEDIUM],
                ind
              ),
            }"
          >
            <div>
              {{ item.mediumQuantityPlan }}
            </div>
          </td>
          <td
            v-if="showQuantityDetails"
            :data-table-value="`${
              PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[PRODUCT_TYPE_COMPLEXITY.HARD]
            }__${ind}`"
            :class="{
              [PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[
                PRODUCT_TYPE_COMPLEXITY.HARD
              ]]: true,
              selectCell: isSelectCell(
                PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[PRODUCT_TYPE_COMPLEXITY.HARD],
                ind
              ),
            }"
          >
            <div>
              {{ item.hardQuantityPlan }}
            </div>
          </td>
          <td
            v-if="showQuantityDetails"
            :data-table-value="`${
              PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[PRODUCT_TYPE_COMPLEXITY.VERY_HARD]
            }__${ind}`"
            :class="{
              [PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[
                PRODUCT_TYPE_COMPLEXITY.VERY_HARD
              ]]: true,
              selectCell: isSelectCell(
                PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[
                  PRODUCT_TYPE_COMPLEXITY.VERY_HARD
                ],
                ind
              ),
            }"
          >
            <div>
              {{ item.veryHardQuantityPlan }}
            </div>
          </td>

          <!-- Debt -->
          <td
            :data-table-value="`allQuantityDebt__${ind}`"
            :class="{ selectCell: isSelectCell('allQuantityDebt', ind) }"
            class="allQuantityDebt main-col"
          >
            <div>
              {{ item.allQuantityDebt }}
            </div>
          </td>
          <td
            v-if="showQuantityDetails"
            :data-table-value="`${
              PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[
                PRODUCT_TYPE_COMPLEXITY.NO_COMPLEXITY
              ]
            }__${ind}`"
            :class="{
              [PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[
                PRODUCT_TYPE_COMPLEXITY.NO_COMPLEXITY
              ]]: true,
              selectCell: isSelectCell(
                PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[
                  PRODUCT_TYPE_COMPLEXITY.NO_COMPLEXITY
                ],
                ind
              ),
            }"
          >
            <div>
              {{ item.noComplexityQuantityDebt }}
            </div>
          </td>
          <td
            v-if="showQuantityDetails"
            :data-table-value="`${
              PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[PRODUCT_TYPE_COMPLEXITY.EASY]
            }__${ind}`"
            :class="{
              [PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[
                PRODUCT_TYPE_COMPLEXITY.EASY
              ]]: true,
              selectCell: isSelectCell(
                PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[PRODUCT_TYPE_COMPLEXITY.EASY],
                ind
              ),
            }"
          >
            <div>
              {{ item.easyQuantityDebt }}
            </div>
          </td>
          <td
            v-if="showQuantityDetails"
            :data-table-value="`${
              PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[PRODUCT_TYPE_COMPLEXITY.MEDIUM]
            }__${ind}`"
            :class="{
              [PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[
                PRODUCT_TYPE_COMPLEXITY.MEDIUM
              ]]: true,
              selectCell: isSelectCell(
                PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[PRODUCT_TYPE_COMPLEXITY.MEDIUM],
                ind
              ),
            }"
          >
            <div>
              {{ item.mediumQuantityDebt }}
            </div>
          </td>
          <td
            v-if="showQuantityDetails"
            :data-table-value="`${
              PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[PRODUCT_TYPE_COMPLEXITY.HARD]
            }__${ind}`"
            :class="{
              [PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[
                PRODUCT_TYPE_COMPLEXITY.HARD
              ]]: true,
              selectCell: isSelectCell(
                PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[PRODUCT_TYPE_COMPLEXITY.HARD],
                ind
              ),
            }"
          >
            <div>
              {{ item.hardQuantityDebt }}
            </div>
          </td>
          <td
            v-if="showQuantityDetails"
            :data-table-value="`${
              PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[PRODUCT_TYPE_COMPLEXITY.VERY_HARD]
            }__${ind}`"
            :class="{
              [PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[
                PRODUCT_TYPE_COMPLEXITY.VERY_HARD
              ]]: true,
              selectCell: isSelectCell(
                PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[
                  PRODUCT_TYPE_COMPLEXITY.VERY_HARD
                ],
                ind
              ),
            }"
          >
            <div>
              {{ item.veryHardQuantityDebt }}
            </div>
          </td>

          <td
            :data-table-value="`allQuantityCompleted__${ind}`"
            class="allQuantityCompleted main-col"
            :class="{
              selectCell: isSelectCell('allQuantityCompleted', ind),
            }"
          >
            <div>
              {{ item.allQuantityCompleted }}
            </div>
          </td>

          <!-- SummaryRow -->
        </tr>

        <tr class="summary-row">
          <th class="deadline main-col">
            <div>{{ summaryRow.count }} д.</div>
          </th>
          <th class="main-col">
            <div>{{ summaryRow.allQuantityPlan }}</div>
          </th>

          <th
            v-if="showQuantityDetails"
            :class="
              PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[
                PRODUCT_TYPE_COMPLEXITY.NO_COMPLEXITY
              ]
            "
          >
            <div>{{ summaryRow.allQuantityPlan }}</div>
          </th>
          <th
            v-if="showQuantityDetails"
            :class="
              PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[PRODUCT_TYPE_COMPLEXITY.EASY]
            "
          >
            <div>
              {{
                summaryRow[
                  PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[PRODUCT_TYPE_COMPLEXITY.EASY]
                ]
              }}
            </div>
          </th>
          <th
            v-if="showQuantityDetails"
            :class="
              PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[PRODUCT_TYPE_COMPLEXITY.MEDIUM]
            "
          >
            <div>
              {{
                summaryRow[
                  PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[PRODUCT_TYPE_COMPLEXITY.MEDIUM]
                ]
              }}
            </div>
          </th>
          <th
            v-if="showQuantityDetails"
            :class="
              PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[PRODUCT_TYPE_COMPLEXITY.HARD]
            "
          >
            <div>
              {{
                summaryRow[
                  PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[PRODUCT_TYPE_COMPLEXITY.HARD]
                ]
              }}
            </div>
          </th>
          <th
            v-if="showQuantityDetails"
            :class="
              PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[PRODUCT_TYPE_COMPLEXITY.VERY_HARD]
            "
          >
            <div>
              {{
                summaryRow[
                  PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[
                    PRODUCT_TYPE_COMPLEXITY.VERY_HARD
                  ]
                ]
              }}
            </div>
          </th>

          <!-- DEBT -->
          <th class="main-col">
            <div>{{ summaryRow.allQuantityDebt }}</div>
          </th>
          <th
            v-if="showQuantityDetails"
            :class="
              PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[
                PRODUCT_TYPE_COMPLEXITY.NO_COMPLEXITY
              ]
            "
          >
            <div>
              {{
                summaryRow[
                  PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[
                    PRODUCT_TYPE_COMPLEXITY.NO_COMPLEXITY
                  ]
                ]
              }}
            </div>
          </th>
          <th
            v-if="showQuantityDetails"
            :class="
              PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[PRODUCT_TYPE_COMPLEXITY.EASY]
            "
          >
            <div>
              {{
                summaryRow[
                  PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[PRODUCT_TYPE_COMPLEXITY.EASY]
                ]
              }}
            </div>
          </th>
          <th
            v-if="showQuantityDetails"
            :class="
              PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[PRODUCT_TYPE_COMPLEXITY.MEDIUM]
            "
          >
            <div>
              {{
                summaryRow[
                  PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[PRODUCT_TYPE_COMPLEXITY.MEDIUM]
                ]
              }}
            </div>
          </th>
          <th
            v-if="showQuantityDetails"
            :class="
              PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[PRODUCT_TYPE_COMPLEXITY.HARD]
            "
          >
            <div>
              {{
                summaryRow[
                  PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[PRODUCT_TYPE_COMPLEXITY.HARD]
                ]
              }}
            </div>
          </th>
          <th v-if="showQuantityDetails">
            <div>
              {{
                summaryRow[
                  PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[
                    PRODUCT_TYPE_COMPLEXITY.VERY_HARD
                  ]
                ]
              }}
            </div>
          </th>

          <th class="main-col">
            <div>
              <div>{{ summaryRow.allQuantityCompleted }}</div>
            </div>
          </th>
        </tr>
      </table>
      <AggregateRowBlock :aggregated-data="aggregatedSummaryTableData" is-summary-table />
    </div>
  </div>
</template>

<script>
import { computed, reactive, toRefs, watch } from 'vue';
import accessMixin from '@/mixin/accessMixin.js';
import { copyToClipboard } from '@/utils/clipboard.js';
import { PRODUCT_TYPE_COMPLEXITY } from '../../../constants';
import { useProductTableAggregation } from '@/use/productionTable/useProductTableAggregation.js';
import {
  PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS,
  PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS,
} from '../productionTableDictionary';
import AggregateRowBlock from './AggregatedRow.vue';
import { orderBy } from '@/utils/lodash.js';

const TABLE_HEIGHT_OFFSET = 110;
export default {
  name: 'ProductionSummaryTableBlock',
  components: { AggregateRowBlock },
  mixins: [accessMixin],
  props: {
    productionTableOrders: {
      type: Array,
      required: true,
    },
    summaryRow: {
      type: Object,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    displayCustomization: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { columns, filters, productionTableOrders, summaryRow } = toRefs(props);
    const globalLoading = computed(() => props.loading);

    const showQuantityDetails = computed(
      () => !!columns.value.find((c) => c.linkedTo === 'allQuantity')
    );

    watch(
      () => filters,
      () => {
        resetSelectHandler();
      },
      { deep: true }
    );

    const sort = reactive({
      sortBy: null,
      reverse: null,
    });
    const sortByHandler = (key) => {
      resetSelectHandler();
      if (sort.sortBy === key) {
        sort.reverse = !sort.reverse;
      } else {
        sort.sortBy = key;
      }
    };
    const sortedProductTableOrders = computed(() =>
      orderBy(productionTableOrders.value, [sort.sortBy], [sort.reverse ? 'desc' : 'asc'])
    );
    const isWeekend = (date) => {
      if (date === '__') {
        return false;
      }
      return [0, 6].includes(new Date(date).getDay());
    };

    const {
      mouseupHandler,
      mousedownHandler,
      mouseoverHandler,
      copyTableHandler,
      resetSelectHandler,

      selectedRange,
      isSelectCell,
      isSelectRange,
      startedSelectCell,

      aggregatedSummaryTableData,
    } = useProductTableAggregation(columns, sortedProductTableOrders, true);

    return {
      sortByHandler,
      showQuantityDetails,

      pTableOrders: sortedProductTableOrders,
      pTableSummary: summaryRow,

      mouseupHandler,
      mousedownHandler,
      mouseoverHandler,
      copyTableHandler,

      aggregatedSummaryTableData,

      sort,

      isWeekend,

      isSelectCell,
      selectedRange,
      isSelectRange,
      startedSelectCell,

      globalLoading,
    };
  },
  data() {
    return {
      tableHeight: window.innerHeight - TABLE_HEIGHT_OFFSET,
    };
  },
  computed: {
    cssVars() {
      return {
        '--production-summary-table-height': this.tableHeight + 'px',
      };
    },
  },
  created() {
    this.PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS =
      PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS;

    this.PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS =
      PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS;

    this.PRODUCT_TYPE_COMPLEXITY = PRODUCT_TYPE_COMPLEXITY;
  },
  mounted() {
    window.addEventListener('resize', this.changeCssWatch);
    document.addEventListener('keydown', this.copyTableHandler);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.changeCssWatch);
    document.removeEventListener('keydown', this.copyTableHandler);
  },
  methods: {
    changeCssWatch() {
      this.tableHeight = window.innerHeight - TABLE_HEIGHT_OFFSET;
    },
    openWindow(url) {
      window.open(url, '_blank');
    },
    copyText(text) {
      copyToClipboard(text);
      this.$notify({ title: 'Скопировано' });
    },
  },
};
</script>
<style lang="scss" scoped>
.production-summary-table-block-wrapper {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  @include scroll-wider;

  .action-icon {
    cursor: pointer;
    &:active {
      transform: scale(0.9);
      box-shadow: inset 0px -1px 2px $color-white-smoke;
    }
  }
}

@mixin tabble-cell {
  font-size: 13px;
  color: $smoke-blue;
  vertical-align: middle;
  text-align: start;

  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 7px;
  padding-right: 7px;

  border-bottom: 1px solid $color-light-silver;

  &:first-child {
    padding-left: 15px;
  }

  &:last-child {
    padding-left: 15px;
  }
}

.production-summary-table-block-wrapper {
  max-height: var(--production-summary-table-height);
  width: 100%;
  overflow: auto;
  @include scroll-wide;
  display: flex;
  flex-direction: row;
  position: relative;
}

.production-summary-table-orders-table {
  @include noSelect;

  border-collapse: collapse;

  overflow: auto;
  @include scroll-wider;
  height: 100%;

  border-collapse: collapse;
  width: 100%;

  .headers {
    position: sticky;
    top: 0;

    background-color: $color-white;
    z-index: 1;

    vertical-align: middle;
    text-align: center;
    th {
      font-size: 12px;
      border-bottom: 1px solid transparent;
    }
    box-shadow: 0px 2px 3px 0px rgba(191, 191, 191, 0.26);
  }
}

.name-wrapper {
  display: flex;
}

td,
th {
  @include tabble-cell;
}

td div {
  min-height: 20px;
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  tr:hover & {
    max-width: 100%;
    // white-space: normal;

    // word-break: break-all;
  }
}

.deadline-expired {
  color: $color-red;
}

.title-icon-group-wrapper,
.title-icon-wrapper {
  display: flex;
  flex-direction: column;
  opacity: 1;
  opacity: 0.5;
}

th.active .title-icon {
  opacity: 1;
}
th.active.reverse .title-icon {
  transform: rotate(180deg);
}

.title-icon {
  margin-top: 2px;
  margin-left: 5px;
  opacity: 0.5;
}

.title-text-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

$color-select: rgba(255, 200, 200, 0.15);

tr.weekend {
  background-color: $color-select;
}

td {
  transition: background-color 0.2s;
}

th:not(.deadline) div {
  width: 55px;
}
.deadline {
  width: 140px;
}

tr th:not(.main-col) {
  @include Inter;
}
.highlight-main-col tr td.main-col {
  background-color: $color-select;
}
.selectCell {
  background-color: rgba(85, 175, 193, 0.236) !important;
}
</style>
