import BaseService from './services/BaseService.js';

class cardApi extends BaseService {
  async createCards({ orderId }) {
    const data = await this.api.post(`/card/${orderId}`);
    return data;
  }
  async getCards({ orderId }) {
    const data = await this.api.get(`/card/${orderId}`);
    return data;
  }
  async getAllMonitoringCardDetails({ cardId }) {
    const data = await this.api.get(`/card/monitoring/get-all-monitoring-card-details/${cardId}`);
    return data;
  }
  async patchCard({ card }) {
    const data = await this.api.patch(`/card/`, {
      card,
    });
    return data;
  }
}

export default new cardApi();
