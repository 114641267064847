import { getPropData } from './getPropData.js';

function getKey(key) {
  return KEYS[key] || null;
}

export const STORAGE_KEY = {
  PAGINATION: 'PAGINATION',
  SETTINGS: 'SETTINGS',
  MONITORING: 'MONITORING',
  PRODUCTION_TABLE: 'PRODUCTION_TABLE',
};

export const STORAGE_PATH = {
  ORDERS_TABLE_SETTING: 'ORDERS_TABLE_SETTING',
  ORDERS_TABLE: 'ORDERS_TABLE',
  MONITORING_SETTING_SELECTED_DEP: 'MONITORING_SETTING_SELECTED_DEP',
  PRODUCTION_TABLE_SETTING_EXCLUDED_COLUMN: 'PRODUCTION_TABLE_SETTING_EXCLUDED_COLUMN',
};

/**
 *
 * @param {string} key key
 * @param {string} path пока только 1 свойство объекта(без вложенностей)
 * @param {JSON} value payload (не передавать undefined)
 *
 * @returns
 */
export function storage({ key, path }, value) {
  const savedKey = getKey(key);
  if (value !== undefined) {
    if (savedKey) {
      const oldData = JSON.parse(localStorage.getItem(savedKey) || '{}');
      localStorage.setItem(savedKey, JSON.stringify({ ...oldData, [path]: value }));
    }
    return;
  }

  if (savedKey) {
    const data = JSON.parse(localStorage.getItem(savedKey) || '{}');
    return getPropData(data, path);
  }
}

const KEYS = {
  [STORAGE_KEY.PAGINATION]: 'LG_MANAGER/PAGINATION',
  [STORAGE_KEY.SETTINGS]: 'LG_MANAGER/SETTINGS',
  [STORAGE_KEY.MONITORING]: 'LG_MANAGER/MONITORING',
  [STORAGE_KEY.PRODUCTION_TABLE]: 'LG_MANAGER/PRODUCTION_TABLE',
};
