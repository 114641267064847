import io from 'socket.io-client';
import { TOKEN } from '../../../constants/auth';
let socket;

export default {
  connect({ dispatch, commit }) {
    // const { host, userId, token } = params || {};
    const { host, token } = {
      host: process.env.VUE_APP_API_TARGET,
      token: localStorage.getItem(TOKEN.ACCESS_TOKEN),
    };
    if (!token) {
      console.log('Socket connection error', host, token);
      return;
    }

    try {
      socket.disconnect();
    } catch {
      // console.log(err);
    }
    const options = {
      path: '/sockets',
      auth: { token },
      // query: { ns: userId },
      transports: ['websocket', 'polling'],
    };
    socket = io(host, options);

    socket.open();

    // design
    socket.on('getDesignMessage', (data) => {
      console.log('getDesignMessage ws', data);
      dispatch('design/SOCKET_getDesignMessage', data, { root: true });
    });

    socket.on('updateDesignMessage', (data) => {
      console.log('updateDesignMessage ws', data);
      dispatch('design/SOCKET_updateDesignMessage', data, { root: true });
    });

    socket.on('updateDesignSolutions', (data) => {
      console.log('updateDesignSolutions ws', data);
      dispatch('design/SOCKET_updateDesignSolutions', data, { root: true });
    });

    //order
    socket.on('patchOrder', (data) => {
      console.log('patchOrder ws', data);
      commit('order/PATCH_ORDER', data, { root: true });
      commit('card/PATCH_CARD_ORDER', data, { root: true });
      commit('monitoring/PATCH_MONITORING_ORDER', data, { root: true });
    });
    socket.on('addOrReplaceMonitoringOrder', (data) => {
      console.log('addOrReplaceMonitoringOrder ws', data);
      commit('monitoring/ADD_OR_REPLACE_MONITORING_ORDER', data, { root: true });
    });
    socket.on('deleteOrder', (data) => {
      console.log('deleteOrder ws', data);
      commit('order/DELETE_ORDER', data, { root: true });
      commit('monitoring/DELETE_MONITORING_ORDER', data, { root: true });
    });
    socket.on('updateOrderParams', (data) => {
      console.log('updateOrderParams ws', data);
      commit('order/PATCH_ORDER_PARAMS', data, { root: true });
      commit('card/PATCH_CARD_ORDER_PARAMS', data, { root: true });
      commit('monitoring/PATCH_MONITORING_ORDER_PARAMS', data, { root: true });
    });

    //users
    socket.on('updateEmployee', (data) => {
      console.log('updateEmployee ws', data);
      commit('user/SET_EMPLOYEE', { employee: data }, { root: true });
    });

    //department
    socket.on('updateDepartment', (data) => {
      console.log('updateDepartment ws', data);
      commit('production/SET_DEPARTMENT', { department: data }, { root: true });
    });

    //monitoring
    //card
    socket.on('updateMonitoringCard', (data) => {
      console.log('updateMonitoringCard ws', data);
      commit('monitoring/PATCH_MONITORING_CARD', data, { root: true });
    });
  },
  connetctToRoom(_, { id, type }) {
    socket.emit('connetctToRoom', { id, type });
  },
  disconnectFromRoom(_, { id, type }) {
    socket.emit('disconnectFromRoom', { id, type });
  },
};
