import analyticsApi from '@/api/analyticsApi';

export default {
  async getMonitorngDepartmentReport(_, { startDate, endDate }) {
    const data = await analyticsApi.getMonitorngDepartmentReport({ startDate, endDate });
    return data;
  },

  async getMonitoringPlanFactPackagingData(_, { startDate, endDate }) {
    const data = await analyticsApi.getMonitoringPlanFactPackagingData({ startDate, endDate });
    return data.data;
  },
};
