<template>
  <label
    class="a-checkbox"
    :class="{
      active,
      reversed,
      disabled,
    }"
  >
    <div class="a-checkbox__inputs-wrapper">
      <input
        v-model="data"
        type="checkbox"
        class="a-checkbox__input"
        v-bind="$attrs"
      />
    </div>
    <span class="a-checkbox__label"> {{ label }}&nbsp; </span>
  </label>
</template>

<script>
export default {
  name: "ACheckbox",
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    reversed: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["input", "change", "update:modelValue"],
  data() {
    return {
      data: null,
    };
  },
  computed: {
    active() {
      return !!this.data;
    },
  },
  watch: {
    modelValue() {
      this.updateFromProp();
    },
    data() {
      this.$emit("input", this.data);
      this.$emit("change", this.data);
      this.$emit("update:modelValue", this.data);
    },
  },
  mounted() {
    this.updateFromProp();
  },
  methods: {
    updateFromProp() {
      this.data = this.modelValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.a-checkbox {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  min-width: fit-content;
  margin-left: -7px;
  margin-right: -7px;
  margin-bottom: 0;
  padding-left: 7px;
  padding-right: 7px;

  max-width: 100%;

  &.disabled {
    .a-checkbox__inputs-wrapper {
      background-color: $color-light-gray;
    }
    pointer-events: none;
  }

  &.reversed {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

.a-checkbox__inputs-wrapper {
  width: 16px;
  height: 16px;

  flex-shrink: 0;
  position: relative;

  border-radius: 4px;
  background-color: $color-bg-light-blue;
  background-image: none;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid $color-bg-light-blue-active;

  transition: all 0.15s ease-in-out;
  will-change: background-color;

  .a-checkbox.active &,
  .a-checkbox.active & {
    background-color: $cornflower-blue;
    border-color: $color-blue;
    background-image: url(./assets/correct.svg);
    background-size: 8px 7px;
  }

  .a-checkbox.active.disabled & {
    background-color: $color-light-gray;
    background-image: url(./assets/correct-dark.svg);
    overflow: 0.5;
    border-color: $color-light-gray;
  }
}

.a-checkbox__input {
  display: none;
}

.a-checkbox__label {
  @include InterSemibold;
  font-size: 14px;
  color: $smoke-blue;

  padding-top: 1px;
  margin-top: -3px;

  display: flex;
  flex-direction: row;

  // .a-checkbox.active &,
  // .a-checkbox.active & {
  // }

  .a-checkbox.disabled & {
    color: $smoke-blue;
  }
}

.a-checkbox__label,
.a-checkbox__inputs-wrapper {
  box-sizing: border-box;
  padding-left: 7px;
  padding-right: 7px;
}
</style>
