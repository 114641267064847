<template>
  <div class="a-spacer" :class="{ bold, textDefault }">
    <div v-if="text" class="a-spacer__text-block">
      <div class="a-spacer__text-block-text">
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ASpacer",
  props: {
    text: {
      type: String,
      default: null,
    },
    bold: {
      type: Boolean,
      default: true,
    },
    textDefault: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.a-spacer {
  width: 100%;
  max-width: 100%;
  min-width: 100%;

  &.bold {
    @include InterSemibold;
    font-size: 1.1rem;
  }

  &.textDefault {
    font-size: 14px;
  }

  &__text-block {
    &-text {
      width: fit-content;
      padding-right: 4%;
      padding-left: 4px;
      border-bottom: 1px solid $color-bg-smoke-active;
    }
  }
}
</style>
