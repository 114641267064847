<template>
  <Analytics />
</template>

<script>
import Analytics from '@/components/analytics';
export default {
  name: 'AnalyticsPage',
  components: { Analytics },
};
</script>
