<template>
  <div class="cover">
    <div class="container">
      <div ref="messagesContainer" class="messages-container">
        <div ref="messagesBlock" class="messages-block">
          <template v-for="message of messages" :key="message.guid">
            <DesignSolutionMessageBlock
              :message="message"
              class="message-block"
              :loading="loading"
            />
          </template>
        </div>
      </div>
      <div class="input-block">
        <InputMessageBlock />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import accessMixin from '@/mixin/accessMixin.js';
import { getLink } from '@/utils/getStorageLink.js';
import DesignSolutionMessageBlock from './DesignSolutionMessageBlock.vue';
import InputMessageBlock from './InputMessageBlock.vue';
import { nextTick } from 'vue';

export default {
  name: 'ChatComponent',
  components: {
    DesignSolutionMessageBlock,
    InputMessageBlock,
  },
  mixins: [accessMixin],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: [],
  data() {
    return {};
  },
  computed: {
    ...mapState({
      messages: (s) => s.design.designMessages,
    }),
    messagesCount() {
      return this.messages.length;
    },
  },
  watch: {
    messagesCount: {
      handler: async function () {
        await nextTick();
        this.$refs.messagesBlock.scrollIntoView(false);
      },
    },
  },
  created() {
    this.getLink = getLink;
  },
  mounted() {
    setTimeout(() => this.$refs.messagesBlock.scrollIntoView(false), 100);
  },
  methods: {
    ...mapActions({
      uploadImage: 'upload/uploadImage',
    }),
  },
};
</script>
<style lang="scss" scoped>
.cover {
  max-width: 1000px;
  height: 100vh;
  max-height: 100vh;
}
.container {
  display: flex;
  flex-direction: column;

  // justify-content: space-between;

  margin: 0px;

  width: calc(100% - 20px);
  height: calc(100% - 50px);
  margin: 10px;
  overflow-y: auto;
  overflow-x: hidden;

  // max-height: calc(100vh - 30px);
  // @include scroll-wider;
  // overflow-y: auto;

  .messages-container {
    // height: 100px;
    overflow-y: auto;
    @include scroll-wider;

    .messages-block {
      max-width: 1000px;
    }
    flex: 2 0.5 100%;
  }
  .input-block {
    flex: 2 2 100%;
  }
  .messages-container {
    // max-height: calc(100vh - 200px);
    // @include scroll-wider;
    // overflow-y: auto;
  }
}
</style>
