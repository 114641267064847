<template>
  <APageBlock class="mb-20" toggle>
    <template #header> Подневный отчет готовых изделий</template>
    <div class="content">
      <div class="interval-picker mr-20">
        <ADatePicker
          mode="date"
          range
          format="YYYY-MM-DD"
          label="Интервал"
          fixed-popover
          :readonly="globalLoading"
          @change="setReportRange"
        />
      </div>
      <div class="mb-10 mt-20">
        <AButton
          v-if="!globalLoading"
          :disabled="!startDate || !endDate"
          @click="getMonitorngDepartmentReportHandler"
        >
          Скачать отчет
        </AButton>
        <div v-else>
          <ALoader centered />
        </div>
      </div>
    </div>
  </APageBlock>
</template>

<script>
import { mapActions } from 'vuex';
import { forceFileDownload } from '@/utils/downloadData.js';

export default {
  name: 'MonitoringDepartmentReport',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localLoading: false,
      startDate: null,
      endDate: null,
    };
  },
  computed: {
    globalLoading() {
      return this.loading || this.localLoading;
    },
  },
  methods: {
    ...mapActions({
      getMonitorngDepartmentReport: 'analytics/getMonitorngDepartmentReport',
    }),
    setReportRange(data) {
      [this.startDate, this.endDate] = data || [null, null];
    },

    async getMonitorngDepartmentReportHandler() {
      this.localLoading = true;
      try {
        const data = await this.getMonitorngDepartmentReport({
          startDate: this.startDate,
          endDate: this.endDate,
        });
        forceFileDownload(
          data,
          `monitoringDepartmentReport_${this.startDate}_${this.endDate}.xlsx`
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.localLoading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.interval-picker {
  max-width: 250px;
}
</style>
