import { Form } from '../utils/validate/index.js';

export default {
  data() {
    return { formErrors: {} };
  },
  methods: {
    getErrMess(path) {
      return this.formErrors[path];
    },
    $form(initialValues, validationSchema) {
      const formValidation = new Form(initialValues, validationSchema).validate();
      this.formErrors = formValidation.errors;
      return formValidation;
    },
  },
};
