import { TOKEN } from '../../../constants/auth';

export default {
  SET_AUTH_TOKENS(state, { token, refreshToken }) {
    state.token = token;
    state.refreshToken = refreshToken;
    localStorage.setItem(TOKEN.ACCESS_TOKEN, token);
    localStorage.setItem(TOKEN.REFRESH_TOKEN, refreshToken);
  },

  DELETE_AUTH_TOKENS(state) {
    state.token = null;
    state.refreshToken = null;
    localStorage.removeItem(TOKEN.ACCESS_TOKEN);
    localStorage.removeItem(TOKEN.REFRESH_TOKEN);
  },
};
