<template>
  <th colspan="6">
    <div class="order-info-wrapper">
      <div class="order-info">
        <ATabs
          routing
          class="tabs"
          :selected-tab="0"
          :tab-props="tabProps"
          @changeTab="({ tabValue }) => $emit('changeTab', tabValue)"
        >
          <ATab name="Клиент" :tab-value="ORDER_TAB.CLIENT">
            <OrderInfoClientBlock :is-selected-order="isSelectedOrder" :loading="loading" />
          </ATab>
          <ATab name="Команда" :tab-value="ORDER_TAB.TEAM">
            <OrderInfoTeamBlock :is-selected-order="isSelectedOrder" :loading="loading" />
          </ATab>
          <ATab name="Заказ" :tab-value="ORDER_TAB.ORDER">
            <OrderInfoOrderBlock :is-selected-order="isSelectedOrder" :loading="loading" />
          </ATab>
          <ATab name="Производство" :tab-value="ORDER_TAB.PRODUCTION">
            <OrderInfoProductionBlock :is-selected-order="isSelectedOrder" :loading="loading" />
          </ATab>
          <ATab name="Дизайн" :tab-value="ORDER_TAB.DESIGN">
            <OrderDesignBlock :is-selected-order="isSelectedOrder" :loading="loading" />
          </ATab>
          <ATab name="Импорт" :tab-value="ORDER_TAB.IMPORT">
            <OrderImportBlock :is-selected-order="isSelectedOrder" :loading="loading" />
          </ATab>
        </ATabs>
      </div>
    </div>
  </th>
</template>
<script>
import { mapState } from 'vuex';
import OrderInfoProductionBlock from './OrderInfoProductionBlock.vue';
import OrderInfoClientBlock from './OrderInfoClientBlock.vue';
import OrderInfoOrderBlock from './OrderInfoOrderBlock.vue';
import OrderInfoTeamBlock from './OrderInfoTeamBlock.vue';
import OrderDesignBlock from './OrderDesignBlock.vue';
import OrderImportBlock from './OrderImportBlock.vue';
import { ORDER_TAB } from '../../../../constants';

export default {
  name: 'OrderInfoBlock',
  components: {
    OrderInfoProductionBlock,
    OrderInfoClientBlock,
    OrderInfoOrderBlock,
    OrderInfoTeamBlock,
    OrderDesignBlock,
    OrderImportBlock,
  },
  props: {
    isSelectedOrder: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['changeTab'],
  data() {
    return {};
  },
  computed: {
    ...mapState({
      newOrder: (s) => s.order.newOrder,
      selectedOrder: (s) => s.order.selectedOrder,
      newOrderInfo: (s) => s.order.newOrderInfo.orderErrors,
      selectedOrderInfo: (s) => s.order.selectedOrderInfo.orderErrors,
    }),
    disableTeamTab() {
      return !this.isSelectedOrder && !this.newOrder?.clientId;
    },
    tabProps() {
      const result = {
        client: { redDotted: false },
        team: { redDotted: false },
        order: { redDotted: false },
        production: { redDotted: false },
      };
      const orderValidateErrors = this.isSelectedOrder ? this.selectedOrderInfo : this.newOrderInfo;
      if (!orderValidateErrors || !Object.values(orderValidateErrors).length) return result;
      const errorKeys = Object.keys(orderValidateErrors);
      if (errorKeys.includes('clientId')) {
        result.client.redDotted = true;
      }
      if (errorKeys.some((el) => ['teamName', 'teamId'].includes(el))) {
        result.team.redDotted = true;
      }
      if (
        errorKeys.some((el) =>
          ['name', 'deadline', 'paid', 'discountFix', 'discountPercent', 'currency'].includes(el)
        )
      ) {
        result.order.redDotted = true;
      }
      return result;
    },
  },
  created() {
    this.ORDER_TAB = ORDER_TAB;
  },
};
</script>
<style lang="scss" scoped>
th {
  height: 340px !important;
  width: 680px !important;
  background-color: rgb(206, 206, 206);
  overflow: hidden;
}
.order-info-wrapper {
  height: 340px !important;
  width: 680px !important;
}
.order-info-wrapper,
.order-info {
  display: flex;
  flex-direction: row;

  min-width: 100%;
  min-height: 100%;
}
// .client-tab {
//   display: flex;

//   height: 100%;
//   width: 100%;
// }
</style>
