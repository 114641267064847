<template>
  <div>
    <APageAside
      title="Добавление изделий"
      wider
      :loading="clientDesignsLoading"
      @close="closeHandler"
    >
      <div v-if="addProduct" class="cover">
        <div class="content">
          <div class="content__search">
            <AInput
              v-model="filter"
              placeholder="Поиск по id, названию или описанию изделия/комплекта"
            />
          </div>
          <div class="content__assortment mb-20">
            <ARadioButton
              v-model="assortmentType"
              :options="ASSORTMENT_TYPE"
              inline
              class="mt-20"
            />
          </div>
          <div class="content__block-wrapper">
            <div class="content__block-category">
              <RadioButtonsGroup
                v-model="selectedCategory"
                :options="categories"
                prop-to-bind="id"
                borderless
                nowraptext
                class="mt-20"
              />
            </div>
            <div v-if="!addProduct.clientId && assortmentType === 'client'" class="client-required">
              Необходимо выбрать клиента ( вкладка "Клиент")
            </div>
            <div v-else class="content__block-assortment-wrapper">
              <ARadioButton
                v-model="productType"
                :options="PODUCT_TYPE"
                inline
                class="ml-20 mb-10"
              />

              <div v-if="productType == 'products'" class="content__block-assortment">
                <div
                  v-for="item of preparedItemsItems"
                  :key="`${item.id}_${item.designId}`"
                  class="content__block-product-item"
                >
                  <div class="header" :title="item.name">
                    <span class="name">
                      {{ getElementName(item.name) }}
                    </span>
                    <span class="caption"> Изделие: {{ item.id }} </span>
                  </div>

                  <div class="maket">
                    <div v-if="assortmentType === 'client'" class="image">
                      <img
                        :src="
                          getLink(
                            item.design.mockup.front ||
                              item.design.mockup.back ||
                              item.design.mockup.plain
                          )
                        "
                        alt="mockup"
                      />
                    </div>
                    <div v-else-if="itemImages[item.id]" class="image">
                      <img :src="getLink(itemImages[item.id])" alt="mockup" />
                    </div>
                    <div v-else class="empty-image-wrapper">
                      <Fa
                        icon="image"
                        class="icon faicon empty-product"
                        style="width: 60px; height: 60px"
                      />
                    </div>
                  </div>
                  <div class="fabrics">
                    <!-- <ABadgeList :items="getItemFabrics(item.itemFabricPrices)" /> -->
                  </div>
                  <span class="caption">
                    {{ productTypes[item.productTypeId]?.name }}
                  </span>
                  <div class="button">
                    <AButton small @click="showAddProductModal(item)"> Выбрать материал </AButton>
                  </div>
                </div>
              </div>
              <div v-else class="content__block-sets">
                <div
                  v-for="(set, setInd) of preparedSetsItems"
                  :key="`${set.id}_${setInd}`"
                  class="content__block-set-item"
                >
                  <div class="header">
                    <span class="name">
                      {{ set.name }}
                    </span>
                    <span class="caption"> Комплект: {{ set.id }} </span>
                  </div>
                  <div class="item-wrapper">
                    <div
                      v-for="itemSet of set.itemSets"
                      :key="`${itemSet.index}_${set.id}_${setInd}`"
                      class="item"
                    >
                      <div v-if="itemImages[itemSet.itemId]" class="image">
                        <img :src="getLink(itemImages[itemSet.itemId])" alt="mockup" />
                      </div>
                      <div v-else class="empty-image-wrapper">
                        <Fa
                          name="icon"
                          icon="image"
                          class="icon faicon empty-product"
                          style="width: 60px; height: 60px"
                        />
                      </div>
                      <div>
                        <span class="caption">
                          {{ productTypes[itemsDictionary[itemSet.itemId].productTypeId]?.name }}
                          {{ fabrics[itemSet.fabricId].name }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="price">
                    {{ set.prices[addProduct.currency] }}
                    {{ CURRENCIE_SIGN[addProduct.currency] }}
                  </div>
                  <div class="button">
                    <AButton
                      small
                      :disabled="!set.prices[addProduct.currency]"
                      @click="
                        assortmentType == 'client'
                          ? showAddProductModal(set, false)
                          : addSetHandler(set)
                      "
                    >
                      {{ assortmentType == 'client' ? 'Выбрать дизайн' : 'Добавить' }}
                    </AButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </APageAside>
  </div>
</template>

<script>
import RadioButtonsGroup from '@@/components/Radio-buttons-group/index.vue';
import { mapState, mapMutations, mapActions } from 'vuex';
import { parseDictionary } from '@/utils/dictionaryUtils.js';
import { getLink } from '@/utils/getStorageLink';
import { filterArrayByFields } from '@/utils/filters.js';
import { cloneDeep } from '@/utils/lodash.js';
import AsideAddProductModal from './AsideAddProductModal.vue';
import { CURRENCIES } from '@/constants/index';

const ASSORTMENT_TYPE = [
  {
    value: 'client',
    name: 'Каталог клиента',
  },
  {
    value: 'lg',
    name: 'Наш ассортимент',
  },
];

const PODUCT_TYPE = [
  {
    value: 'products',
    name: 'Изделия',
  },
  {
    value: 'sets',
    name: 'Комплекты',
  },
];

export default {
  name: 'AsideAddProductPage',
  components: {
    RadioButtonsGroup,
  },
  props: {
    addProduct: {
      type: Object,
      default: null,
    },
  },
  emits: ['close'],
  data() {
    return {
      filter: null,

      assortmentType: 'lg',
      productType: 'sets',

      selectedCategory: 1,

      clientDesignsLoading: false,
    };
  },
  computed: {
    ...mapState({
      allSets: (s) => s.assortment.sets,
      allItems: (s) => s.assortment.items,
      itemsDictionary: (s) => s.dictionary.assortmentDictionary.items,
      categories: (s) => parseDictionary(s.dictionary.assortmentDictionary.categories),
      productTypes: (s) => s.dictionary.assortmentDictionary.productTypes,
      fabrics: (s) => s.dictionary.assortmentDictionary.fabrics,
      clientDesigns: (s) => s.assortment.clientDesigns,
    }),
    itemImages() {
      return this.allItems.reduce((acc, item) => {
        acc[item.id] = item.article.mockup.front || item.article.mockup.back;
        return acc;
      }, {});
    },
    getItemForSelectedCategory() {
      return parseDictionary(this.itemsDictionary).reduce((acc, curr) => {
        curr.categoryId == this.selectedCategory && acc.push(curr.id);
        return acc;
      }, []);
    },
    filtredItem() {
      const items = this.allItems.filter((item) => {
        if (item.deleted) return false;
        return this.selectedCategory ? item.categoryId == this.selectedCategory : true;
      });
      if (!this.filter) return items;
      return filterArrayByFields(items, this.filter, ['id', 'name', 'desc']);
    },
    filtredClientItems() {
      return this.clientDesigns.designs.reduce((acc, design) => {
        const item = this.filtredItem.find((item) => item.id == design.itemId);
        if (item) {
          acc.push({
            ...cloneDeep(item),
            designId: design.guid,
            design: { mockup: { ...design.mockup } },
            lastDesignProduct: { ...design.lastDesignProduct },
          });
        }
        return acc;
      }, []);
    },
    preparedItemsItems() {
      return this.assortmentType == 'client' ? this.filtredClientItems : this.filtredItem;
    },
    filtredSets() {
      let sets = !this.selectedCategory
        ? this.allSets
        : this.allSets.filter((set) =>
            set.itemSets.some((item) => this.getItemForSelectedCategory.includes(item.itemId))
          );
      sets = sets.filter((_) => !_.deleted);
      if (!this.filter) return sets;
      return filterArrayByFields(sets, this.filter, ['id', 'name']);
    },
    filtredClientSets() {
      const possibleClientSetIds = this.clientDesigns.setsIds || [];
      return this.filtredSets.filter((set) => possibleClientSetIds.includes(set.id));
    },
    preparedSetsItems() {
      return this.assortmentType == 'client' ? this.filtredClientSets : this.filtredSets;
    },
  },
  watch: {
    assortmentType() {
      this.getClientDesignsHandler();
    },
    'addProduct.clientId': function () {
      this.getClientDesignsHandler();
    },
  },
  created() {
    this.getLink = getLink;
    this.PODUCT_TYPE = PODUCT_TYPE;
    this.ASSORTMENT_TYPE = ASSORTMENT_TYPE;
    this.CURRENCIE_SIGN = CURRENCIES.reduce((acc, curr) => {
      acc[curr.value] = curr.sign;
      return acc;
    }, {});
  },
  beforeUnmount() {
    this.resetClientDesigns();
  },
  methods: {
    ...mapActions({
      getClientDesigns: 'assortment/getClientDesigns',
    }),
    ...mapMutations({
      resetClientDesigns: 'assortment/RESET_CLIENT_DESIGNS',
      addItemToOrder: 'order/ADD_ITEM',
      addSetToOrder: 'order/ADD_SET',
    }),
    async getClientDesignsHandler() {
      if (
        !(
          this.addProduct?.clientId &&
          this.assortmentType === 'client' &&
          this.clientDesigns.clientId != this.addProduct.clientId
        )
      ) {
        return;
      }
      this.clientDesignsLoading = true;
      try {
        await this.getClientDesigns({ clientId: this.addProduct.clientId });
      } catch (err) {
        console.log(err);
      } finally {
        this.clientDesignsLoading = false;
      }
    },
    closeHandler() {
      this.$emit('close');
    },
    getItemFabrics(itemFabricPrices) {
      return itemFabricPrices
        .map((itemFabric) => {
          if (itemFabric.deleted) return;
          return this.fabrics[itemFabric.fabricId].name;
        })
        .filter((_) => _);
    },
    showAddProductModal(product, isItem = true) {
      this.$dialog.addDialog({
        component: AsideAddProductModal,
        props: {
          title: `Добавление ${isItem ? 'изделия' : 'комплекта'}`,
          isItem,
          persistent: false,
          currency: this.addProduct.currency,
          product,
          clientItemDesigns: !isItem ? this.clientDesigns.designs : null,
          fabricsDictionary: isItem ? this.fabrics : null,
          onConfirm: (confirm, product, itemFabricOrDesigns) => {
            confirm.showModal = false;
            if (isItem) return this.addItemHandler(product, itemFabricOrDesigns);
            this.addSetHandler(product, itemFabricOrDesigns);
          },
        },
      });
    },
    addItemHandler(product, itemFabric) {
      const lastDesignProduct = product.lastDesignProduct || {};
      const design = product.design || { mockup: {} };
      this.addItemToOrder({
        selectedOrder: this.addProduct?.isSelectedOrder,

        defPrice: itemFabric.prices[this.addProduct.currency],
        fabricId: itemFabric.fabricId,
        itemId: product.id,
        ...lastDesignProduct,
        designId: product.designId,
        design,
      });
      this.$notify({ title: 'Изделие добавлен' });
    },
    addSetHandler(set, designs = {}) {
      const addingSet = {
        defPrice: set.prices[this.addProduct.currency],
        setId: set.id,
      };

      const items = set.itemSets.reduce((acc, itemSet) => {
        let item = {
          fabricId: itemSet.fabricId,
          itemId: itemSet.itemId,
        };

        const designInfo = designs[itemSet.index];
        if (designInfo) {
          item = {
            ...item,
            ...designInfo.lastDesignProduct,
            designId: designInfo.guid,
            design: { mockup: designInfo.mockup },
          };
        }
        acc.push(item);
        return acc;
      }, []);
      this.addSetToOrder({
        selectedOrder: this.addProduct?.isSelectedOrder,
        set: addingSet,
        items,
      });
      this.$notify({ title: 'Комплект добавлен' });
    },
    getElementName(name = '') {
      if (name.length > 50) {
        return `${name.slice(0, 50)}...`;
      }
      return name;
    },
  },
};
</script>
<style lang="scss" scoped>
.client-required {
  text-align: center;
  color: $color-red;
}
.content-wrapper,
.cover {
  width: 100%;
  min-height: 100%;
}

.content {
  height: 100%;

  &__block {
    width: 100%;
    height: 100%;

    &-wrapper {
      display: grid;
      grid-template-columns: 150px 1fr;
      grid-gap: 0px;
      height: 100%;
      width: 100%;
    }

    &-category {
      display: flex;
      flex-direction: row;
    }
    &-assortment,
    &-sets {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      // justify-content: space-around;
      align-content: flex-start;

      overflow-y: scroll;
      @include scroll-wide;

      height: 680px;

      padding: 20px;
      grid-gap: 10px;
    }
  }
}
.content__block-product-item {
  // background-color: red;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 180px;
  height: 240px;
  border-radius: 4px;

  border: 1px solid $color-bg-smoke;

  .caption {
    text-align: center;
    font-size: 12px;
    color: $color-gray;
  }

  .header {
    padding: 4px 5px;
    .name {
      @include InterSemibold;
      text-align: center;
    }
    .caption {
      display: flex;
      flex-direction: row;
      font-size: 12px;

      color: $color-gray;
    }
  }
  .maket {
    display: flex;
    justify-content: center;
    align-content: center;

    .image {
      img {
        max-height: 100px;
        max-width: 100px;
        border-radius: 4px;
      }
    }
    .empty-image-wrapper {
      height: 80px;

      display: flex;
      justify-content: center;
      align-items: center;

      .empty-product {
        color: $color-bg-smoke-active;
      }
    }
  }
  .fabrics {
    padding: 0px 5px;
  }
  .button {
    display: flex;
    justify-content: center;
    margin: 10px;
  }
}

.content__block-set-item {
  border: 1px solid $color-bg-smoke;
  border-radius: 4px;
  padding: 5px;

  height: fit-content;
  .header {
    padding: 4px 5px;
    .name {
      @include InterSemibold;
      text-align: center;
    }
    .caption {
      display: flex;
      flex-direction: row;
      font-size: 12px;

      color: $color-gray;
    }
  }
  .item-wrapper {
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    flex-wrap: nowrap;

    justify-content: space-around;
  }
  .item {
    .image {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 100px;
        max-width: 100px;
        border-radius: 4px;
      }
    }
    .empty-image-wrapper {
      height: 100px;

      display: flex;
      justify-content: center;
      align-items: center;

      .empty-product {
        color: $color-bg-smoke-active;
      }
    }
  }
  .price {
    text-align: center;
    margin: 5px 0px;
    @include InterSemibold;
  }
  .button {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
  }
}
</style>
