<template>
  <div class="page-aside__group-separator">
    <div class="page-aside__group-label">
      <span v-if="label" class="page-aside__group-label-content">
        {{ label }}&nbsp;
        <tooltip v-if="qtitle" :qtitle="qtitle" />
      </span>
      <slot name="after-title" />
    </div>
    <slot />
  </div>
</template>

<script>
import Tooltip from "../../Tooltip/index.vue";

export default {
  name: "PageAsideCell",
  components: {
    Tooltip,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    qtitle: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.page-aside__group-separator {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-bottom: 20px;

  width: 100%;
}

.page-aside__group-label {
  position: relative;

  display: flex;
  align-items: baseline;
  width: 100%;
  margin-bottom: 10px;

  &-content {
    // display: block;
    @include items-row__el;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
  }

  &::after {
    content: "";

    position: absolute;
    bottom: 0;
    left: 5px;
    right: 5px;

    height: 1px;

    background-color: $color-bg-smoke-active;
  }
}
</style>
