<template>
  <div class="wrapper" :style="cssVars">
    <div class="cards-wrapper">
      <div
        class="cover"
        :class="{ selected: selectedCard === 'summary' }"
        @click="selectedCard = 'summary'"
      >
        <SummaryCard class="card" :summary="summary" :cards="cards" :order="order" />
      </div>
      <div
        v-for="card of cards"
        :key="card.guid"
        class="cover"
        :class="{ selected: selectedCard?.guid == card.guid }"
        @click="selectedCard = card"
      >
        <CardBlock :card="card" class="card" :order="order" />
      </div>
    </div>
    <div class="selected-card">
      <div class="cover">
        <SummaryCard
          v-if="selectedCard === 'summary'"
          class="card"
          :summary="summary"
          :cards="cards"
          :order="order"
        />
        <CardBlock v-else :card="selectedCard" :order="order" class="card" />
      </div>
    </div>
  </div>
</template>

<script>
import CardBlock from '../components/cardBlock.vue';
import SummaryCard from '../components/summaryCard.vue';

export default {
  name: 'PreviewCards',
  components: {
    CardBlock,
    SummaryCard,
  },
  props: {
    cards: {
      type: Array,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
    summary: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedCard: 'summary',
      scale: 0.2,
    };
  },
  computed: {
    cssVars() {
      return {
        '--scalec': this.scale,
      };
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
$cardWidth: 1132px;
$cardHeight: 1594px;
$scaleCard: var(--scalec);

$scaleSelectedCard: 0.55;
.wrapper {
  display: flex;
  flex-direction: row;
  position: relative;

  .selected-card {
    width: fit-content;
    height: fit-content;
    margin-left: 10px;
    .card {
      transform: scale($scaleSelectedCard);
      margin-bottom: calc(($cardHeight * ($scaleSelectedCard - 1)));
      margin-right: calc(($cardWidth * ($scaleSelectedCard - 1)));
      transform-origin: top left;
    }
  }
  .cards-wrapper {
    padding-bottom: 100px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    max-height: calc(100vh - 150px);
    overflow-y: auto;
    @include scroll-wider;

    .cover {
      margin: 10px;
      padding: 3px;

      height: fit-content;

      cursor: pointer;
      transition: all 0.3s;

      &.selected {
        box-shadow: 0px 0px 5px 3px rgba(80, 151, 179, 0.541);
        background-color: rgba(127, 187, 255, 0.014);
      }

      .card {
        transform: scale($scaleCard);
        margin-bottom: calc(($cardHeight * ($scaleCard - 1)));
        margin-right: calc(($cardWidth * ($scaleCard - 1)));
        transform-origin: top left;
      }
    }
  }
}
</style>
