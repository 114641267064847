<template>
  <div>
    <div v-if="itemFabric" class="item-item-fabrics__block">
      <div>
        <ASelect
          v-model="itemFabric.fabricId"
          :options="
            itemFabric.fabricId
              ? parseDictionary(assortmentDictionary.fabrics)
              : allAvailableFabrics
          "
          disable-deleted
          select-label="name"
          label="Материал"
          :read-only="!!itemFabric.fabricId"
          :disabled="itemFabric.deleted"
          :error="getErrMess(`fabricId`)"
        />
      </div>
      <div class="item-item-fabrics__prices">
        <AInput
          v-model="itemFabric.prices.RUR"
          type="number"
          label="RUR"
          :disabled="itemFabric.deleted"
          :read-only="!accessToChange"
          :error="getErrMess(`prices.RUR`)"
          :min="0"
        />
        <AInput
          v-model="itemFabric.prices.EUR"
          type="number"
          label="EUR"
          :disabled="itemFabric.deleted"
          :read-only="!accessToChange"
          :error="getErrMess(`prices.EUR`)"
          :min="0"
        />
        <AButton
          v-if="accessToChange"
          :danger="!itemFabric.deleted"
          class="ml-10"
          @click="
            delResItemFabrickHandler({
              fabricId: itemFabric.fabricId,
              deleteFabric: !itemFabric.deleted,
            })
          "
        >
          {{ itemFabric.deleted ? 'Восстановить' : 'Удалить' }}</AButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { parseDictionary } from '@/utils/dictionaryUtils.js';
import accessMixin from '@/mixin/accessMixin.js';
import validateMixin from '@/mixin/validateChild.js';

export default {
  name: 'ItemPriceItem',
  mixins: [accessMixin, validateMixin],
  props: {
    modelValue: {
      type: Object,
      default: null,
    },
    allAvailableFabrics: {
      type: Array,
      default: () => [],
    },
    itemDeleted: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'delResItemFabric'],
  computed: {
    ...mapState('dictionary', ['assortmentDictionary']),
    accessToChange() {
      return this.isCanChangeAssortment && !this.itemDeleted && !this.loading;
    },
    itemFabric: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  created() {
    this.parseDictionary = parseDictionary;
  },
  methods: {
    delResItemFabrickHandler({ fabricId, deleteFabric = false }) {
      this.$emit('delResItemFabric', { fabricId, deleteFabric });
    },
  },
};
</script>
<style lang="scss" scoped>
.item {
  &-item-fabrics {
    &__block {
      display: grid;
      grid-template-columns: 230px 1fr;
      margin-top: 8px;
      align-items: flex-end;
    }

    &__prices {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-end;
      .a-input {
        width: 110px;
      }
      label {
        width: 170px;
        margin-left: 10px;
      }
    }
  }
}
</style>
