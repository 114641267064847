import { cloneDeep } from '../../../utils/lodash';
import { NEW_DESIGN_SOLUTIONS } from './state';

export default {
  SET_ALL_DESIGNERS(state, data) {
    state.allDesigners = data;
  },
  SET_DESIGN_SOLUTIONS(state, data) {
    state.designSolutions = mapDesignSolutionOnState(data);
  },
  SET_DESIGN_SOLUTION(state, data) {
    state.selectedDesignSolution = data;
    state.selectedDesignSolutionCache = cloneDeep(data);
  },
  SET_DESIGN_SOLUTIONS_MESSAGES(state, data) {
    state.designMessages = data;
  },
  ADD_DESIGN_MESSAGE(state, data) {
    if (state.selectedDesignSolution.guid != data.chatId) return;
    state.designMessages.push(data);
  },
  UPDATE_DESIGN_SOLUTIONS(state, data) {
    updateDesignSolutions(state, data);
  },
  UPDATE_DESIGN_MESSAGE(state, data) {
    if (state.selectedDesignSolution.guid != data.chatId) return;
    const index = state.designMessages.findIndex((m) => m.guid === data.guid);
    if (index === -1) {
      state.designMessages.push(data);
    } else {
      state.designMessages.splice(index, 1, data);
    }
  },
  RESET_DESIGN_SOLUTION_PAGE(state) {
    state.designSolutions = cloneDeep(NEW_DESIGN_SOLUTIONS);
    state.designMessages = [];
    state.selectedDesignSolution = null;
    state.selectedDesignSolutionCache = null;
  },
};

function mapDesignSolutionOnState(designSolutions = []) {
  return designSolutions.reduce((acc, desSol) => {
    const order = acc[desSol.state].find((o) => o.guid === desSol.orderId);
    if (order) {
      order.products.push({ ...desSol });
      return acc;
    }
    acc[desSol.state].push({
      guid: desSol.orderId,
      ...desSol.order,
      products: [{ ...desSol }],
    });
    return acc;
  }, cloneDeep(NEW_DESIGN_SOLUTIONS));
}

function updateDesignSolutions(state, newItems) {
  const allValidStatuses = Object.keys(NEW_DESIGN_SOLUTIONS);
  const updatedBlocks = allValidStatuses.reduce((acc, i) => {
    acc[i] = [];
    return acc;
  }, {});

  for (const newDesignSolutions of newItems) {
    // TODO: мб переделать схему рассылки этих обновлений?(указывать поля которые изменились)
    if (state.selectedDesignSolution?.guid === newDesignSolutions.guid) {
      ['designerId', 'state'].forEach((field) => {
        state.selectedDesignSolution[field] = newDesignSolutions[field];
        state.selectedDesignSolutionCache[field] = newDesignSolutions[field];
      });
      if (newDesignSolutions.deleted == true) {
        state.selectedDesignSolution = null;
        state.selectedDesignSolutionCache = null;
      }
    }

    // для оптимизации сортировки
    if (
      allValidStatuses.includes(newDesignSolutions.state) &&
      !updatedBlocks[newDesignSolutions.state].includes(newDesignSolutions.orderId)
    ) {
      updatedBlocks[newDesignSolutions.state].push(newDesignSolutions.orderId);
    }

    for (const status of allValidStatuses) {
      // сначала удаляем элемент
      state.designSolutions[status] = state.designSolutions[status].reduce(
        (allOrdersInBlock, order) => {
          const productIndex = order.products.findIndex((el) => el.guid == newDesignSolutions.guid);
          if (productIndex != -1 && order.products.length === 1) {
            return allOrdersInBlock;
          }
          if (productIndex != -1) {
            order.products.splice(productIndex, 1);
          }
          allOrdersInBlock.push(order);
          return allOrdersInBlock;
        },
        []
      );
    }

    if (!state.designSolutions[newDesignSolutions.state] || newDesignSolutions.deleted == true) {
      continue;
    }
    const orderIndex = state.designSolutions[newDesignSolutions.state].findIndex(
      (o) => o.guid == newDesignSolutions.orderId
    );
    // если такого заказа нет в блоке - добавляем новый заказ и сразу же добавляем в него новое решение
    if (orderIndex == -1) {
      state.designSolutions[newDesignSolutions.state].push({
        guid: newDesignSolutions.orderId,
        ...newDesignSolutions.order,
        products: [{ ...newDesignSolutions }],
      });
    } else {
      // если заказ уже есть - то добавляем в него обновленный продукт
      state.designSolutions[newDesignSolutions.state][orderIndex].products.push(newDesignSolutions);
    }
  }

  // сортировка
  for (const block in updatedBlocks) {
    if (!updatedBlocks[block].length) continue;
    state.designSolutions[block].forEach((o) => {
      if (updatedBlocks[block].includes(o.guid)) {
        o.products.sort((a, b) =>
          a.productIndex == b.productIndex
            ? a.productLocalIndex - b.productLocalIndex
            : a.productIndex - b.productIndex
        );
      }
    });
  }
}
