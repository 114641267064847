<template>
  <div>
    <APageAside
      :title="orderCategory ? 'Редактирование категории заказа ' : 'Создание категории'"
      wide
      @close="closeHandler"
    >
      <div v-if="orderCategory || creatingOrderCategory">
        <OrderCategoryBlock
          :order-category="orderCategory"
          :creating-order-category="creatingOrderCategory"
          @close="closeHandler"
          @update:user="$emit('update:orderCategory', $event)"
        />
      </div>
    </APageAside>
  </div>
</template>

<script>
import OrderCategoryBlock from './OrderCategoryBlock.vue';

export default {
  name: 'CreateOrderCategorysAsideBlock',
  components: {
    OrderCategoryBlock,
  },
  props: {
    orderCategory: {
      type: Object,
      default: null,
    },
    creatingOrderCategory: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'update:orderCategory'],
  methods: {
    closeHandler() {
      this.$emit('close');
    },
  },
};
</script>
