export default () => ({
  newOrder: null,
  newOrderInfo: {
    user: null,
    teams: [],
    orderErrors: {},
  },
  newOrderXlImportErrors: [],

  selectedOrder: null,
  selectedOrderInfo: {
    user: null,
    teams: [],
    orderErrors: {},
  },
  selectedOrderXlImportErrors: [],
  selectedOrderLoading: false,
  newOrderLoading: false,

  allOrders: [],
  orderTags: [],
  orderCategory: [],
});
