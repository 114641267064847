export function stringMatch(valueToFind, target) {
  return String(target).match(new RegExp(valueToFind, 'i'));
}

/**
 * @param {Array} items Исходный массив с объектами
 * @param {String} searchString Строка для фильтрации
 * @param {Array} fields Массив с полями для фильтрации
 * fields[n] - строка(ключ объекта) или массив, где первый элемент-строка(ключ объекта),
 * а второй-функция которая применится к значению перед фильтрация(ф-я должна вернуть строку)
 * @returns {Array}
 */
export function filterArrayByFields(items, searchString, fields) {
  if (!searchString && searchString !== 0) return items;
  const filter = String(searchString).toLowerCase();
  return items.filter((item) => {
    for (const field of fields) {
      const value = typeof field === 'string' ? String(item[field]) : field[1](item[field[0]]);
      if (value.toLowerCase().includes(filter)) return true;
    }
    return false;
  });
}

export function filterArrayByOneField(items, filter, field = 'name') {
  return items.filter((item) => String(item[field]).toLowerCase().indexOf(filter) > -1);
}

/**
 * @param {Array} items Исходный массив с объектами
 * @param {Array} fields Массив с Fn
 * @returns {Array}
 */
export function filterArrayByFn(items, fnArray) {
  return items.filter((item) => {
    for (const [field, value, fn, IsAnd = true] of fnArray) {
      if (!value && !fn) {
        if (IsAnd) {
          continue;
        } else {
          return true;
        }
      }
      if (fn) {
        const res = fn(field ? item[field] : item) && IsAnd;
        if (res && IsAnd) {
          continue;
        } else {
          return res;
        }
      }
      if (value) {
        const res = item[field]?.toLowerCase()?.indexOf(value) > -1;
        if (res && IsAnd) {
          continue;
        } else {
          return res;
        }
      }
    }
    return true;
  });
}
