<template>
  <Cards />
</template>

<script>
import Cards from '@/components/cards';
export default {
  name: 'CardsPage',
  components: { Cards },
};
</script>
