<template>
  <div class="client-tab"></div>
</template>
<script>
import { mapState } from 'vuex';
import accessMixin from '@/mixin/accessMixin.js';
import { ORDER_STATE } from '../../../../constants';

export default {
  name: 'OrderDesignBlock',
  mixins: [accessMixin],
  props: {
    isSelectedOrder: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      newOrder: (s) => s.order.newOrder,
      selectedOrder: (s) => s.order.selectedOrder,
      selectedOrderInfo: (s) => s.order.selectedOrderInfo,
      newOrderInfo: (s) => s.order.newOrderInfo,
    }),
    accessToChange() {
      return (
        this.isCanChangeOrder &&
        this.pageOrder.clientId &&
        ![ORDER_STATE.COMPLETED, ORDER_STATE.PRODUCTION, ORDER_STATE.TECH_DESIGN].includes(
          this.pageOrder._old.state
        )
      );
    },
    pageOrder() {
      return this.isSelectedOrder ? this.selectedOrder : this.newOrder;
    },
    orderInfo() {
      return this.isSelectedOrder ? this.selectedOrderInfo : this.newOrderInfo;
    },
  },
};
</script>
<style lang="scss" scoped>
.client-tab {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 275px;

  overflow: hidden;
  @include scroll;

  .need-client {
    display: flex;
    justify-content: center;
    margin: 10px 0px;

    color: $color-red;
  }
}
</style>
