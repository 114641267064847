<template>
  <div class="a-radio-button" :class="{ disabled, error, inline }">
    <div class="a-radio-button-items-wrapper">
      <div
        v-for="(radio, ind) in options"
        :key="`${ind}_${radio[propToBind]}`"
        class="a-radio-button-item"
      >
        <input
          type="radio"
          :checked="radio[propToBind] == data"
          v-bind="$attrs"
        />
        <span @click="clickHandler(radio)">{{ radio[selectLabel] }}</span>
      </div>
    </div>

    <span class="a-radio-button__error">
      <div class="a-radio-button__error-icon" />
      {{ error }}
    </span>
  </div>
</template>

<script>
export default {
  name: "ARadioButton",
  props: {
    modelValue: {
      type: [String, Number],
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    selectLabel: {
      type: String,
      default: "name",
    },
    propToBind: {
      type: String,
      default: "value",
    },
    inline: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: null,
    },
  },
  emits: ["input", "change", "update:modelValue"],
  data() {
    return {
      data: null,
    };
  },
  watch: {
    modelValue() {
      this.updateFromProp();
    },
    data: {
      handler: function () {
        this.$emit("input", this.data);
        this.$emit("change", this.data);
        this.$emit("update:modelValue", this.data);
      },
    },
  },
  beforeMount() {
    this.updateFromProp();
  },
  methods: {
    updateFromProp() {
      this.data = this.modelValue;
    },
    clickHandler(radio) {
      if (this.disabled) return;
      this.data = radio[this.propToBind];
    },
  },
};
</script>

<style lang="scss" scoped>
$radioSize: 20px;

.a-radio-button {
  margin-top: 5px;

  &.inline {
    .a-radio-button-items-wrapper {
      display: flex;
      .a-radio-button-item {
        margin-right: 10px;
      }
    }
  }
  &.disabled {
    pointer-events: none;
    .a-radio-button-item {
      input + span {
        &:before {
          background: $color-bg-smoke-active;
        }
      }
    }
  }
}

.a-radio-button-item {
  display: block;
  margin-top: 5px;
  width: fit-content;
  cursor: pointer;

  input {
    display: none;
    & + span {
      line-height: $radioSize;
      height: $radioSize;
      padding-left: $radioSize;
      display: block;
      position: relative;
      &:not(:empty) {
        padding-left: $radioSize + 8;
      }
      &:before,
      &:after {
        content: "";
        width: $radioSize;
        height: $radioSize;
        display: block;
        border-radius: 50%;
        left: 0;
        top: 0;
        position: absolute;
      }
      &:before {
        background: $color-bg-light-blue-active;
        transition: background 0.2s ease,
          transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 2);
      }
      &:after {
        background: #fff;
        transform: scale(0.78);
        transition: transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.4);
      }
    }
    &:checked + span {
      &:before {
        transform: scale(1.04);
        background: $cornflower-blue;
      }
      &:after {
        transform: scale(0.4);
        transition: transform 0.3s ease;
      }
    }
  }
  &:hover {
    input {
      & + span {
        &:before {
          transform: scale(0.92);
        }
        &:after {
          transform: scale(0.74);
        }
      }
      &:checked + span {
        &:after {
          transform: scale(0.4);
        }
      }
    }
  }
}

.a-radio-button__error {
  @include input-error(".a-radio-button");
}
</style>
