<template>
  <div
    class="a-table-wrapper"
    :class="{
      borderless,
      small,
      wrap,
      'headers-sticked': headersSticked,
      'first-column-sticked': firstColumnSticked,
      'first-column-sortable': isFirstFieldSortable,
    }"
  >
    <table class="a-table" :style="propToCssVar">
      <tbody>
        <tr class="tr-headers">
          <th
            v-for="(field, idx) in fields"
            :key="`tr-headers-th${idx}`"
            :class="{
              [field.class]: field.class,
              sortable: field.sortable,
              active: sortBy === field.key,
              reverse: sortBy === field.key && reverse,
            }"
            @click="field.sortable ? sortByClickHandler(field.key) : null"
          >
            <div class="a-table__title-wrapper">
              <span class="a-table__title-text">
                {{ field.label }}
              </span>
              <template v-if="field.sortable && sortBy != field.key">
                <div class="a-table__title-icon-group-wrapper">
                  <Fa
                    class="a-table__title-icon-group"
                    icon="angle-up"
                    style="width: 8px; height: 8px"
                    title="сортировать"
                  />
                  <Fa
                    class="a-table__title-icon-group"
                    icon="angle-down"
                    style="width: 8px; height: 8px"
                    title="сортировать"
                  />
                </div>
              </template>
              <template v-else-if="field.sortable">
                <Fa
                  class="a-table__title-icon"
                  icon="angle-up"
                  style="width: 12px; height: 12px"
                  title="сортировать"
                />
              </template>
            </div>
          </th>
        </tr>
        <template v-if="loading">
          <tr>
            <td :colspan="fields.length">
              <Loader centered />
            </td>
          </tr>
        </template>
        <template
          v-for="(item, idx) in sortedItems"
          :key="itemKey ? `body_key_${item[itemKey]}` : idx"
          v-else
        >
          <tr v-on="getPropHandlers(item)" :class="{ ...bindRowClasses(item) }">
            <td
              v-for="(field, index) in fields"
              :key="`data-${index}`"
              :class="{ sortable: field.sortable }"
            >
              <slot
                :name="field.key"
                v-bind="{
                  field,
                  item,
                  idx,
                  data: getDataByKey({
                    item,
                    key: field.key,
                    type: field.type,
                    mask: field.mask,
                  }),
                }"
              >
                {{
                  getDataByKey({
                    item,
                    key: field.key,
                    type: field.type,
                    mask: field.mask,
                  })
                }}
              </slot>
            </td>
          </tr>
        </template>
        <tr v-if="!items.length && !loading">
          <td :colspan="fields.length">
            <slot name="no-items-message"> Нет данных </slot>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { format, formatRoubleCurrency } from "@@/utils";
import { Loader } from "../index.js";

function getPropData(obj, key) {
  let data = obj;
  const keyArray = key.split(".");

  if (keyArray.length > 1) {
    for (let i = 0; i < keyArray.length; i++) {
      const propKey = keyArray[i];

      data = data[propKey];
    }
  } else {
    data = obj[key];
  }

  return data;
}

function is(Ctor, val) {
  return (val != null && val.constructor === Ctor) || val instanceof Ctor;
}

function isNumber(value) {
  return (
    is(Number, value) && is(Number, Number(value)) && !isNaN(Number(value))
  );
}

function isPercentString(value) {
  return /\d{1,}(\.{1})?(\d{1,})?%/.test(value);
}

function parseDate(value) {
  let separations = [".", "/", "-"];
  let separation;
  separations.forEach((el) => {
    if (separations.indexOf(el)) {
      separation = el;
      return;
    }
  });
  if (`${value}`.split(separation).length < 3) {
    return new Date(`01${separation}${value}`);
  } else {
    return new Date(`${value}`);
  }
}

function sortArray({ array, sortBy, reverse, isDate = false, getValueFn }) {
  return array.sort((a, b) => {
    let aData = getValueFn ? getValueFn(a) : getPropData(a, sortBy);
    let bData = getValueFn ? getValueFn(b) : getPropData(b, sortBy);

    const areStrings = is(String, aData) && is(String, bData);
    const areBool = is(Boolean, aData) && is(Boolean, bData);
    if (areBool) {
      if (!reverse) return aData === bData ? 0 : aData ? -1 : 1;
      return aData === bData ? 0 : aData ? 1 : -1;
    }
    const arePercentStrings = areStrings
      ? isPercentString(aData) && isPercentString(bData)
      : false;
    const areNumbers = isNumber(aData) && isNumber(bData);

    if (isDate) {
      return reverse
        ? parseDate(aData) - parseDate(bData)
        : parseDate(bData) - parseDate(aData);
    }

    if (areStrings) {
      if (arePercentStrings) {
        aData = Number(aData.replace("%", ""));
        bData = Number(bData.replace("%", ""));

        return reverse ? aData - bData : bData - aData;
      }

      aData = aData.toLowerCase();
      bData = bData.toLowerCase();

      return reverse ? aData.localeCompare(bData) : bData.localeCompare(aData);
    }

    let compare;

    if (areNumbers) {
      aData = Number(aData);
      bData = Number(bData);
      compare = reverse ? aData - bData : bData - aData;
    } else {
      compare =
        ["", null, undefined].includes(bData) && aData
          ? reverse
            ? -1
            : 1
          : reverse
          ? aData < bData
          : aData > bData;
      // compare = reverse ? aData < bData : aData > bData;
    }

    if (compare > 0) {
      return 1;
    } else if (compare < 0) {
      return -1;
    } else {
      return 0;
    }
  });
}

function getSortedItemsWithFixedBottom({ items, condition }) {
  const isConditionFunc = typeof condition === "function";
  const fixedItemIdx = items.findIndex(
    isConditionFunc
      ? condition
      : (item) => JSON.stringify(item) === JSON.stringify(condition)
  );

  if (fixedItemIdx > -1) {
    const lastIdx = items.length - 1;
    const nextLastIdx = fixedItemIdx + 1;

    let leftPartOfResult = [];
    let rightPartOfResult = [];

    if (fixedItemIdx !== 0) {
      leftPartOfResult = items.slice(0, fixedItemIdx);
    }

    if (lastIdx !== fixedItemIdx) {
      rightPartOfResult = items.slice(nextLastIdx);
    }

    return leftPartOfResult
      .concat(rightPartOfResult)
      .concat(items[fixedItemIdx]);
  } else {
    console.group("A-Table");
    console.warn("No item was finded by fixed condition");
    console.groupEnd();
    return items;
  }
}

export default {
  name: "ATable",
  components: {
    Loader,
    // Tooltip,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    rowHandlers: {
      type: Object,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
    wrap: {
      type: Boolean,
      default: false,
    },
    extraRow: {
      type: [Boolean, String],
      default: false,
    },
    lastRow: {
      type: Boolean,
      default: false,
    },
    borderless: {
      type: Boolean,
      default: true,
    },
    fixedItem: {
      type: [Object, Function],
      default: null,
    },
    firstColumnSticked: {
      type: Boolean,
      default: false,
    },
    headersSticked: {
      type: Boolean,
      default: false,
    },
    cssVar: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    startSort: {
      type: Object,
      default: null,
    },
    externalSort: {
      type: Boolean,
      default: false,
    },
    rowClasses: {
      type: Function,
      default: () => {},
    },
    rowClick: {
      type: Function,
      default: null,
    },
    itemKey: {
      type: String,
      default: null,
    },
    useVirtualScroller: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["sortBy"],
  data() {
    return {
      sortBy: this.startSort ? this.startSort.field : null,
      reverse: this.startSort ? this.startSort.reverse : null,
      updateVar: true,
    };
  },
  computed: {
    bindRowClasses() {
      return (item) => {
        const result = this.rowClasses(item);
        if (!result) return "";
        return Array.isArray(result)
          ? result.reduce((a, b) => {
              a[b] = b;
              return a;
            }, {})
          : { [result]: result };
      };
    },
    sortedItems() {
      if (this.externalSort) {
        return this.items;
      }
      if (this.sortBy) {
        let sortedItems = sortArray({
          array: this.items,
          reverse: this.reverse,
          sortBy: this.sortBy,
          isDate: this.isDate(this.sortBy),
          getValueFn: this.fields.find((f) => f.key === this.sortBy).getValueFn,
        });

        if (this.fixedItem) {
          return getSortedItemsWithFixedBottom({
            items: sortedItems,
            condition: this.fixedItem,
          });
        }

        return sortedItems;
      }

      return this.items;
    },
    isFirstFieldSortable() {
      return this.fields.length ? this.fields[0].sortable : false;
    },
    propToCssVar() {
      let style = {};
      if (this.cssVar?.widthContent != null) {
        style["--widthContent"] = this.cssVar.widthContent;
      }
      return style;
    },
  },
  methods: {
    isDate(sortBy) {
      return (
        this.fields.findIndex(
          (field) => field.key === sortBy && field.isDate == true
        ) > -1
      );
    },
    sortByClickHandler(key) {
      if (this.sortBy === key) {
        this.reverse = !this.reverse;
      } else {
        this.sortBy = key;
      }
      if (this.externalSort) {
        this.$emit("sortBy", { key, reverse: this.reverse });
      }
    },
    getPropHandlers(row) {
      let allHandlers = {};
      if (this.rowHandlers) {
        const isMultipleHandlers = Array.isArray(this.rowHandlers);

        if (isMultipleHandlers) {
          allHandlers = this.rowHandlers.reduce((acc, item) => {
            acc[item.event] = () => item.handler(row);

            return acc;
          }, {});
        } else {
          allHandlers = {
            [this.rowHandlers.event]: () => this.rowHandlers.handler(row),
          };
        }
      }
      if (this.rowClick) allHandlers.click = () => this.rowClick(row);
      return allHandlers;
    },
    getDataByKey({ item, key, type, mask }) {
      let data = item[key];

      if (key) {
        data = key.split(".").reduce((acc, keyEl) => {
          acc = acc[keyEl];

          return acc;
        }, item);
      }

      if (type === "currency" || type === "decimal") {
        data = formatRoubleCurrency(data, type);
      }

      if (type === "date" && data) {
        data = this.formatDate(data, mask);
      }

      return data;
    },
    formatDate(date, mask) {
      return format(
        new Date(date),
        mask || "DD.MM.YYYY"

        // { locale: dateFnsRuLocale }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.a-table {
  background-color: $color-white;
  border-collapse: collapse;

  table-layout: fixed;

  max-width: 100%;
  width: 100%;

  &-wrapper {
    width: 100%;
    max-width: 100%;
    border: 1px solid $color-light-silver;
    border-radius: 4px;
    overflow: hidden;

    &.borderless {
      border: none;
    }
  }
}

th,
td {
  @include td-th-default;
}

.a-table-wrapper {
  overflow: auto;
  @include scroll-wide;
}

.a-table-wrapper.borderless td,
.a-table-wrapper.borderless th {
  padding-left: 15px;
  padding-right: 15px;
}

.a-table-wrapper.small td,
.a-table-wrapper.small th {
  @include td-th-small;
}

.a-table-wrapper.small th.sortable,
.a-table-wrapper.small td.sortable {
  padding-left: 20px;
}

.a-table-wrapper.small.first-column-sortable td:first-child {
  padding-left: 20px;
}

.a-table-wrapper.first-column-sticked {
  td:first-child,
  th:first-child {
    position: sticky;
    left: 0;

    background-color: $color-white;
    z-index: 1;

    &::before {
      content: "";

      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;

      width: 1px;

      background-color: $color-iron;
    }
  }
}

.a-table-wrapper.headers-sticked {
  .tr-headers th {
    position: sticky;
    top: 0;

    background-color: $color-white;
    z-index: 1;
  }
}

.before-extra-tr th,
.before-extra-tr td {
  border-color: $color-light-silver;
}

th {
  @include Inter;
  text-align: left;
  color: $color-gray;
  font-size: 11px;

  &.sortable {
    cursor: pointer;
    transition: all 0.3s linear;
    transition: font 0s linear;
    will-change: color;
  }

  &.sortable.active {
    @include InterSemibold;
  }
}

td {
  @include Inter;

  min-height: 50px;
  max-width: 100%;
  width: auto;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @include scroll-wide;

  tr:hover & {
    max-width: 100%;
    white-space: normal;

    word-break: break-all;
  }

  .wrap & {
    max-width: 100%;
    overflow: auto;
    white-space: normal;
  }

  .wrap tr:hover & {
    word-break: normal;
  }
}

.a-table__title-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
}
.a-table__title-text {
  display: inline-block;
  .qtooltip {
    float: right;
  }
}

.a-table__title-icon-group-wrapper {
  display: flex;
  flex-direction: column;
  opacity: 1;
  left: -15px;
  opacity: 0.5;

  margin-left: 5px;
}
.a-table__title-icon {
  margin-top: 2px;
  margin-left: 5px;
  opacity: 0.5;

  .a-table-wrapper.small & {
    top: 2px;
    left: -14px;
    width: 10px;
  }

  th.sortable.active > .a-table__title-wrapper & {
    transform: scaleY(1);
    opacity: 1;
  }

  th.sortable.active.reverse > .a-table__title-wrapper & {
    transform: scaleY(1);
  }

  th.sortable.active > .a-table__title-wrapper & {
    transform: scaleY(-1);
  }
}
.a-table {
  min-width: var(--widthContent);

  overflow-x: scroll;
  @include scroll;
}
// .a-table__title-icon {
//   color: red;
//   background-color: red;
//   fill: red;
// }
</style>
