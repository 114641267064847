import { patchOrderParams, pathOrder } from '../../../utils/order';

export default {
  SET_ALL_CARDS(state, data) {
    state.cards = data;
  },

  SET_CARD_ORDER(state, data) {
    state.cardOrder = data;
  },

  PATCH_CARD_ORDER(state, data) {
    if (state.cardOrder?.guid !== data.guid) {
      return;
    }
    pathOrder(state.cardOrder, data);
  },

  PATCH_CARD_ORDER_PARAMS(state, data) {
    if (state.cardOrder?.guid !== data.orderId) {
      return;
    }
    patchOrderParams(state.cardOrder.orderParams, data.changedOrderParams);
  },
};
