import {
  FIELD_TO_PATCH_ORDER,
  ORDER_PARAMS_CHANGED_EVENT,
  ORDER_PARAM_VALUE_TYPE,
  ORDER_STATE,
} from '../constants';
import { getPropData, nestedPropertyExists } from './getPropData';
import { set } from './lodash';

// синхронизировать с бэком
const orderStateSequence = {
  [ORDER_STATE.NEW]: 1,
  [ORDER_STATE.DESIGN]: 2,
  [ORDER_STATE.TECH_DESIGN]: 3,
  [ORDER_STATE.PRODUCTION]: 4,
  [ORDER_STATE.COMPLETED]: 5,
};

export function isAvailableState(currentState, state, param = true) {
  const current = orderStateSequence[currentState];
  const potential = orderStateSequence[state];
  return current && potential && param
    ? potential - current <= 10 && potential - current >= 0
    : current < potential;
}

// синхронизировать с бэком
export function getOrderSum(order, soloPlayerInd = null, withoutOrderDiscount = false) {
  const RETURNED_ERROR_VALUE = 0;

  let orderSum = 0;
  if (!order.playerProducts.length || !order.products.length) {
    return RETURNED_ERROR_VALUE;
  }

  if (soloPlayerInd != null) {
    return getSumForPlayer(soloPlayerInd);
  } else {
    for (let playerInd = 0; playerInd < order.players.length; playerInd++) {
      orderSum += getSumForPlayer(playerInd);
    }
  }
  const discount = withoutOrderDiscount
    ? 0
    : order.discountFix || (orderSum / 100) * order.discountPercent;
  const resultSum = +(orderSum + discount).toFixed(2);
  return resultSum < 0 ? 0 : resultSum;

  function getSumForPlayer(playerInd) {
    let sumForPlayer = 0;
    const playerProducts = order.playerProducts[playerInd];
    for (let productInd = 0; productInd < playerProducts.length; productInd++) {
      const playerProduct = playerProducts[productInd];
      if (!playerProduct.quantity) {
        continue;
      }
      const product = order.products[productInd];
      if (!product.kitId) {
        sumForPlayer += playerProduct.quantity * getProductOrKitPrice(product);
      } else if (product.kitId && product.localIndex == 1) {
        const kit = order.kits.find((k) => k.guid === product.kitId);
        if (!kit) {
          console.log('getOrderSum', { product, playerInd });
          throw new Error('Not found kit');
        }
        sumForPlayer += playerProduct.quantity * getProductOrKitPrice(kit);
      }
    }

    return sumForPlayer;
  }
}

// синхронизировать с бэком
function getProductOrKitPrice(productOrKit) {
  if (productOrKit.isFree || productOrKit.discountPercent == -100) {
    return 0;
  }
  const discount =
    productOrKit.discountFix || (productOrKit.defPrice / 100) * productOrKit.discountPercent;
  const price = +(productOrKit.defPrice + discount).toFixed(2);
  return price < 0 ? 0 : price;
}

export function pathOrder(order, data) {
  for (const field of FIELD_TO_PATCH_ORDER) {
    if (
      nestedPropertyExists(data, field.split('.')) &&
      getPropData(order, field) != getPropData(data, field)
    ) {
      set(order, field, getPropData(data, field));
    }
  }
}

export function patchOrderParams(orderParams, data) {
  const updatedParams = data[ORDER_PARAMS_CHANGED_EVENT.UPDATED];
  if (updatedParams) {
    for (const orderParamsType in updatedParams) {
      const orderParam = updatedParams[orderParamsType];
      if (
        ORDER_PARAM_VALUE_TYPE.SIMPLE_VALUE.includes(orderParamsType) ||
        ORDER_PARAM_VALUE_TYPE.SINGLE_RELATION.includes(orderParamsType)
      ) {
        orderParams[orderParamsType] = [orderParam];
      }
      if (ORDER_PARAM_VALUE_TYPE.MULTIPLE_RELATIONS.includes(orderParamsType)) {
        if (orderParams[orderParamsType]) {
          orderParams[orderParamsType].push(...orderParam);
        } else {
          orderParams[orderParamsType] = [...orderParam];
        }
      }
    }
  }
  const deletedParams = data[ORDER_PARAMS_CHANGED_EVENT.DELETED];
  if (deletedParams) {
    for (const orderParamsType in deletedParams) {
      if (ORDER_PARAM_VALUE_TYPE.SINGLE_RELATION.includes(orderParamsType)) {
        delete orderParams[orderParamsType];
      }
      if (ORDER_PARAM_VALUE_TYPE.MULTIPLE_RELATIONS.includes(orderParamsType)) {
        for (const orderParam of deletedParams[orderParamsType]) {
          const ind = orderParams[orderParamsType].findIndex(
            (p) => String(p.entityId) === String(orderParam.entityId)
          );
          orderParams[orderParamsType].splice(ind, 1);
        }
      }
    }
  }
}
