import { USER_TYPES } from '../constants';
export const LINKS = [
  {
    name: 'Новый заказ',
    routeName: 'orderPage',
    path: '/orders/new',
    icon: 'plus',
    types: [USER_TYPES.ADMIN, USER_TYPES.MAIN_MANAGER, USER_TYPES.MANAGER],
    children: [
      {
        name: 'Все заказы',
        routeName: 'ordersPage',
        path: '/orders',
        icon: 'cart-plus',
        regExpPathActive: /orders$(?!\/new$)/i,
        types: [USER_TYPES.ADMIN, USER_TYPES.MAIN_MANAGER, USER_TYPES.MANAGER],
      },
      {
        name: 'Настройка',
        routeName: 'orderSettingPage',
        path: '/orders/setting',
        icon: 'tags',
        types: [USER_TYPES.ADMIN, USER_TYPES.MAIN_MANAGER, USER_TYPES.MANAGER],
      },
    ],
  },
  // {
  //   name: 'Заказы',
  //   routeName: 'ordersPage',
  //   path: '/orders',
  //   icon: 'cart-plus',
  //   regExpPathActive: /orders$(?!\/new$)/i,
  //   types: [USER_TYPES.ADMIN, USER_TYPES.MAIN_MANAGER, USER_TYPES.MANAGER],
  //   children: [
  //     {
  //       name: 'Настройка',
  //       routeName: 'orderSettingPage',
  //       path: '/orders/setting',
  //       icon: 'tags',
  //       types: [USER_TYPES.ADMIN, USER_TYPES.MAIN_MANAGER, USER_TYPES.MANAGER],
  //     },
  //   ],
  // },
  // {
  //   name: 'Аналитика',
  //   path: '/analytics',
  //   icon: 'chart-bar',
  //   children: [
  //     {
  //       name: 'Продажи',
  //       path: '/sales',
  //       icon: 'dollar-sign',
  //     },
  //     {
  //       name: 'Размеры',
  //       path: '/sizes',
  //       icon: 'ruler-combined',
  //     },
  //   ],
  // },
  {
    name: 'Ассортимент',
    routeName: 'assortmentPage',
    path: '/assortment',
    icon: 'book-open',
    types: [
      USER_TYPES.ADMIN,
      USER_TYPES.MAIN_MANAGER,
      USER_TYPES.MANAGER,
      USER_TYPES.MAIN_DESIGNER,
      USER_TYPES.MAIN_EMPLOYEE,
      USER_TYPES.DESIGN,
    ],
  },
  {
    name: 'Производство',
    routeName: 'productionPage',
    path: '/production',
    icon: 'cogs',
    types: [USER_TYPES.MAIN_MANAGER, USER_TYPES.MAIN_EMPLOYEE, USER_TYPES.ADMIN],
  },
  {
    name: 'Пользователи',
    routeName: 'usersPage',
    path: '/users',
    icon: 'users',
    types: [USER_TYPES.MAIN_EMPLOYEE, USER_TYPES.ADMIN],
  },
  // {
  //   name: 'Товары',
  //   path: '/products',
  //   icon: 'box-open',
  // },
  // {
  //   name: 'Дизайн',
  //   path: '/design',
  //   icon: 'palette',
  //   types: [
  //     USER_TYPES.ADMIN,
  //     USER_TYPES.MAIN_MANAGER,
  //     USER_TYPES.MANAGER,
  //     USER_TYPES.MAIN_DESIGNER,
  //     USER_TYPES.DESIGN,
  //   ],
  // },
  {
    name: 'Мониторинг',
    routeName: 'monitoringPage',
    path: '/monitoring',
    icon: 'project-diagram',
    types: [
      USER_TYPES.ADMIN,
      USER_TYPES.MAIN_EMPLOYEE,
      USER_TYPES.MAIN_MANAGER,
      USER_TYPES.MANAGER,
      USER_TYPES.MAIN_DESIGNER,
      USER_TYPES.DESIGN,
      USER_TYPES.EMPLOYEE,
    ],
  },
  {
    name: 'Приложение',
    routeName: 'applicationPage',
    path: '/application',
    icon: 'mobile',
    types: [
      USER_TYPES.ADMIN,
      USER_TYPES.MAIN_MANAGER,
      USER_TYPES.MANAGER,
      USER_TYPES.MAIN_DESIGNER,
      USER_TYPES.MAIN_EMPLOYEE,
      USER_TYPES.DESIGN,
      USER_TYPES.EMPLOYEE,
    ],
  },
  {
    name: 'Аналитика',
    routeName: 'analyticsPage',
    path: '/analytics',
    icon: 'chart-bar',
    types: [
      USER_TYPES.ADMIN,
      USER_TYPES.MAIN_MANAGER,
      USER_TYPES.MANAGER,
      USER_TYPES.MAIN_DESIGNER,
      USER_TYPES.MAIN_EMPLOYEE,
      USER_TYPES.DESIGN,
    ],
  },
  {
    name: 'Произв. таблица',
    routeName: 'productionTablePage',
    path: '/productionTable',
    icon: 'table',
    types: [
      USER_TYPES.ADMIN,
      USER_TYPES.MAIN_MANAGER,
      USER_TYPES.MANAGER,
      USER_TYPES.MAIN_DESIGNER,
      USER_TYPES.MAIN_EMPLOYEE,
      USER_TYPES.DESIGN,
    ],
  },
  // {
  //   name: 'Настройки',
  //   path: '/settings',
  //   icon: 'sliders-h',
  // },
];
