<template>
  <APageLayout>
    <template #header>
      <OrderHeader :loading="globalLoading" :is-selected-order="!!orderId" />
    </template>
    <template #content>
      <!-- <div v-show="!globalLoading" class="content-wrapper">
        <div v-if="!!orderId && !selectedOrder" class="new-order">Заказ не найден</div>
        <div v-else-if="!!orderId">
          <OrderTable :is-selected-order="true" />
        </div>
        <div v-else>
          <OrderTable :is-selected-order="false" />
        </div>
      </div> -->
      <div v-if="!globalLoading" class="content-wrapper">
        <div v-if="!!orderId && !selectedOrder" class="new-order">Заказ не найден</div>
        <div v-else-if="!!orderId">
          <OrderTable :is-selected-order="true" />
        </div>
        <div v-else>
          <OrderTable :is-selected-order="false" />
        </div>
      </div>
      <div v-else>
        <ALoader centered />
      </div>
    </template>
  </APageLayout>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import OrderHeader from './order-header/index.vue';
import OrderTable from './order-table/index.vue';
import accessMixin from '@/mixin/accessMixin.js';

export default {
  name: 'Order',
  components: {
    OrderHeader,
    OrderTable,
  },
  mixins: [accessMixin],
  data() {
    return {
      orderLoading: true,
    };
  },
  computed: {
    ...mapState({
      selectedOrder: (s) => s.order.selectedOrder,
      selectedOrderLoading: (s) => s.order.selectedOrderLoading,
      newOrderLoading: (s) => s.order.newOrderLoading,
    }),
    accessToChange() {
      return this.isCanChangeOrder;
    },
    globalLoading() {
      return this.orderLoading || this.selectedOrderLoading || this.newOrderLoading;
    },
    orderId() {
      const orderId = this.$route.params.orderId;
      return orderId === 'new' ? null : orderId;
    },
  },
  watch: {
    '$route.params.orderId': async function (newValue, oldValue) {
      if (!oldValue || !newValue) return;
      const resetSelectedOrder = newValue == 'new';
      this.orderLoading = true;
      if (resetSelectedOrder) {
        this.resetOrder(false);
        setTimeout(() => (this.orderLoading = false));
      } else {
        this.resetOrder(true);
        await this.getOrder({ orderId: newValue });
        this.orderLoading = false;
      }
    },
  },
  async created() {
    await this.loadInitData();
  },
  beforeUnmount() {
    this.resetOrder(false);
    this.resetOrder(true);
  },
  methods: {
    ...mapActions({
      getSets: 'assortment/getSets',
      getItems: 'assortment/getItems',
      getOrder: 'order/getOrder',
      getOrderTags: 'order/getOrderTags',
      getOrderCategory: 'order/getOrderCategory',
      getAllManagers: 'user/getAllManagers',
      getAvailableAssortment: 'dictionary/getAvailableAssortment',
      getAssortmentDictionary: 'dictionary/getAssortmentDictionary',
      getProductionParams: 'dictionary/getProductionParams',
    }),
    ...mapMutations({
      resetOrder: 'order/resetOrder',
      prepareNewOrder: 'order/prepareNewOrder',
    }),
    async loadInitData() {
      this.orderLoading = true;
      try {
        const promises = [
          this.getAssortmentDictionary(),
          this.getProductionParams(),
          this.getSets(),
          this.getItems(),
          this.getAllManagers(),
          this.getOrderTags(),
          this.getOrderCategory(),
        ];
        if (this.orderId) promises.push(this.getOrder({ orderId: this.orderId }));
        if (this.accessToChange) {
          promises.push(
            this.getAvailableAssortment({ key: 'itemSex' }),
            this.getAvailableAssortment({ key: 'itemFabrics' })
          );
        }
        await Promise.all(promises);
        if (!this.orderId) {
          this.prepareNewOrder();
        }
      } catch (error) {
        console.log(error);
      } finally {
        await this.$nextTick();
        this.orderLoading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.content-wrapper {
  padding: 0;
  margin: 0;

  height: 100%;
  width: 100%;
}
.new-order {
  // display: flex;
  width: 100%;
  height: 100%;

  .select {
    padding: 80px;
    background-color: rgba(124, 124, 124, 0.397);
  }
}
.page-header {
  @include InterBold;
  font-size: 18px;
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 36px;
}

.order-info {
  height: 340px;
  width: 540px;
  background-color: rgb(206, 206, 206);
}
.item-block {
  // border: 1px solid red;
}
table,
tr,
td,
th {
  padding: 0px;
  margin: 0;
  border: 1px solid red;
}

table {
  position: relative;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
.fixed-row {
  position: sticky;
  left: -1px;
  // top: 0px;
  z-index: 100;
  background-color: white;
  // background-color: red;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 5;
  .shadow {
    // z-index: 5;
    box-shadow: 2px 0px 3px 2.5px rgba(0, 0, 0, 0.397);
    // box-shadow: 5px 0px 0px 3px rgba(0, 0, 0, 1);
  }
  border: none;
}

.item-block {
  position: sticky;
  top: -300px;
  z-index: 15;
  // background-color: red;
}
</style>
