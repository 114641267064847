<template>
  <div class="card-cover">
    <div class="card" :class="{ showAdditionalInfo }">
      <div class="first-row">
        <div class="row-centred">
          <div class="important-flag mr-5">
            <OrderUrgencyTypeSelector
              :order-value="order.urgencyType"
              :card-value="getMaxCardUrgencyType"
              :draggabled="loading || !accessToChangeSubDepartment"
              mode="order"
              @change="$emit('changeOrderUrgencyType', { order, urgencyType: $event })"
            />
          </div>
          <div class="title mr-5 ml-5" :title="order.name">{{ order.name }}</div>
        </div>
        <div class="row-centred">
          <div class="date">
            {{ getOrderDedline }}
          </div>
          <div class="actions">
            <IconCover active @click="showAdditionalInfoHandler">
              <i
                class="fa fa-chevron-down icon"
                style="font-size: 14px"
                :class="{ showAdditionalInfo }"
              ></i>
            </IconCover>
          </div>
        </div>
        <div
          v-if="getCompleteInProductionClasses"
          :class="getCompleteInProductionClasses"
          class="status-category"
        ></div>
      </div>
      <div class="second-row">
        <div class="order-id pointer ml-20 mb-5" @click="copyText(order.guid)">
          ID {{ order.guid.slice(-4).toUpperCase() }}
        </div>
        <div class="cards-block">
          <div v-for="card of order.cards" :key="card.guid" class="card-part">
            <div
              class="part-caption"
              :class="{ done: card.generalState === CARD_GENERAL_STATE.DONE }"
            >
              <div class="icon mr-5">
                <i class="fa fa-check icon" style="font-size: 14px"></i>
              </div>
              <div class="caption">
                Партия {{ card.index }}/{{ order.cardsQuantity }}
                {{ `(${card.playersLength} шт.)` }}
              </div>
            </div>
            <div>
              {{ getCardDepartment(card) }}
            </div>
          </div>
        </div>
        <div class="link mt-10">
          <ABadge fontbase small @click="openOrder">
            <div class="order-link">
              <i class="fa fa-link icon" style="font-size: 14px"></i>
              {{ getOrderLink }}
            </div>
          </ABadge>
          <ABadge v-if="order.amoId" fontbase small class="mt-10" @click="openAmo">
            <div class="order-link">
              <i class="fa fa-link icon" style="font-size: 14px"></i>
              {{ order.amoId }}
            </div>
          </ABadge>
        </div>
        <div
          v-if="showCardController"
          class="cards-controller mt-10"
          @click="$emit('orderProductionLineController', order)"
        >
          <AButton small :disabled="loading">Управление произв. линией </AButton>
        </div>
        <div class="a-row">
          <div
            v-if="showHideDoneOrderBtn"
            class="cards-controller mt-10 mr-20"
            @click="$emit('doneOrderSendToClient', order)"
          >
            <AButton small success :disabled="loading"> Заказ отправлен клиенту </AButton>
          </div>
          <div
            v-if="showOrderPaidBtn"
            class="cards-controller mt-10"
            @click="$emit('orderSetPaid', order)"
          >
            <AButton small success :disabled="loading"> Заказ оплачен </AButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import accessMixin from '@/mixin/accessMixin.js';
import { dayjs } from '@/utils/date.js';
import IconCover from '@/components/base/IconCover.vue';
import OrderUrgencyTypeSelector from '@/components/base/OrderUrgencyTypeSelector';
import { copyToClipboard } from '@/utils/clipboard.js';
import {
  CARD_GENERAL_STATE,
  ORDER_PARAM_TYPE,
  ORDER_STATE,
  URGENCY_TYPE,
} from '../../../constants';
import { useOrderParamValue } from '../../../use/order/useOrderParamValue';

export default {
  name: 'MonitoringOrderCard',
  components: {
    IconCover,
    OrderUrgencyTypeSelector,
  },
  mixins: [accessMixin],
  props: {
    order: {
      type: Object,
      required: true,
    },
    additionalInfoShowOrderCardId: {
      type: String,
      default: null,
    },
    departmentsDictionary: {
      type: Object,
      required: true,
    },
    accessToChangeSubDepartment: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'showOrderCardAdditionalInfo',
    'orderProductionLineController',
    'doneOrderSendToClient',
    'changeOrderUrgencyType',
    'orderSetPaid',
  ],
  setup() {
    const { getValueFromOrderParamByType } = useOrderParamValue();

    return {
      getValueFromOrderParamByType,
    };
  },
  data() {
    return {
      showAdditionalInfo: false,
    };
  },
  computed: {
    getCompleteInProductionClasses() {
      const paidDate = this.getValueFromOrderParamByType({
        orderParamsType: ORDER_PARAM_TYPE.PAID_DATE,
        orderParams: this.order.orderParams,
      });
      if (this.order.state !== ORDER_STATE.COMPLETED || (this.order.closeDate && paidDate)) {
        return;
      }
      if (!this.order.closeDate && !paidDate) {
        return { notCloseAndNotPaid: true };
      }
      if (!this.order.closeDate) {
        return { notClose: true };
      }
      return { notPaid: true };
    },
    showCardController() {
      return this.order.state != ORDER_STATE.COMPLETED && (this.isAdmin || this.isMainEmployee);
    },
    showHideDoneOrderBtn() {
      return (
        this.order.state === ORDER_STATE.COMPLETED &&
        !this.order.closeDate &&
        (this.isCanChangeOrder || this.isCanChangeProduction)
      );
    },
    showOrderPaidBtn() {
      const paidDate = this.getValueFromOrderParamByType({
        orderParamsType: ORDER_PARAM_TYPE.PAID_DATE,
        orderParams: this.order.orderParams,
      });

      return this.order.state === ORDER_STATE.COMPLETED && !paidDate && this.isAdmin;
    },
    getOrderDedline() {
      const deadline = this.getPackageDate || this.order.postponedDeadline || this.order.deadline;
      if (!deadline) {
        return '   -   ';
      }
      const date = dayjs(deadline).format('D[\xA0]MMM');
      const day = dayjs(deadline).format('dd').toUpperCase();
      return `${date},\xA0${day}`;
    },
    getPackageDate() {
      return this.getValueFromOrderParamByType({
        orderParamsType: ORDER_PARAM_TYPE.PACKING_DATE,
        orderParams: this.order.orderParams,
      });
    },
    getCardDepartment() {
      return (card) => {
        if (card.generalState === CARD_GENERAL_STATE.DONE) {
          return 'Готово!';
        }
        return this.departmentsDictionary[card.currentDepartmentId]?.name;
      };
    },
    getOrderLink() {
      if (process.env.NODE_ENV === 'production') {
        return `http://lk.luckygrass.ru/orders/${this.order.guid}`;
      }
      return `http://localhost:8080/orders/${this.order.guid}`;
    },
    getMaxCardUrgencyType() {
      let value;
      for (const card of this.order.cards) {
        if (
          this.URGENCY_TYPE_SORT_ARRAY.indexOf(card.ugencyType) >
          this.URGENCY_TYPE_SORT_ARRAY.indexOf(value)
        ) {
          value = card.ugencyType;
        }
      }
      if (
        this.URGENCY_TYPE_SORT_ARRAY.indexOf(value) >
        this.URGENCY_TYPE_SORT_ARRAY.indexOf(this.order.ugencyType)
      ) {
        return value;
      }
      return null;
    },
  },
  watch: {
    additionalInfoShowOrderCardId(orderId) {
      if (orderId && orderId != this.order.guid) {
        this.showAdditionalInfo = false;
      }
    },
  },
  created() {
    this.CARD_GENERAL_STATE = CARD_GENERAL_STATE;
    this.URGENCY_TYPE_SORT_ARRAY = [...Object.values(URGENCY_TYPE)];
  },
  methods: {
    showAdditionalInfoHandler() {
      this.showAdditionalInfo = !this.showAdditionalInfo;
      if (this.showAdditionalInfo) {
        console.log('emit ', this.order.guid);
        this.$emit('showOrderCardAdditionalInfo', this.order.guid);
      }
    },
    openOrder() {
      this.$router.push({ path: `/orders/${this.order.guid}`, replace: false });
    },
    openAmo() {
      window.open(this.order.amoId, '_blank');
    },
    copyText(text) {
      copyToClipboard(text);
      this.$notify({ title: 'Скопировано' });
    },
  },
};
</script>
<style lang="scss" scoped>
$padding-block: 16px;

.card {
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: 16px;

  &-cover {
    background-color: $color-white;
    width: 400px;
    border-radius: 4px;
  }

  &.showAdditionalInfo {
    .first-row {
      // ::before {
      //   display: block;
      //   content: '';
      //   position: absolute;
      //   left: -$padding-block;
      //   right: -$padding-block;
      //   bottom: -12px;
      //   height: 1px;
      //   background-color: $color-bg-smoke;
      // }
    }
    .second-row {
      margin-top: 24px;
      height: fit-content;
    }
  }

  .first-row {
    display: grid;
    grid-template-columns: 253px 110px 6px;
    align-items: center;
    position: relative;
    height: 20px;

    .important-flag {
      color: $color-light-gray;
      .important {
        color: $color-important;
      }
    }
    .title {
      font-size: 18px;
      line-height: 20px;
      @include InterBold;
      max-width: 210px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .date {
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-size: 12px;
      @include InterBold;
      color: $color-gray;
      margin: 0px 5px;
    }
    .actions {
      display: flex;
      flex-direction: row;
    }
    .status-category {
      height: 100%;
      width: 6px;
      border-radius: 4px;
      &.notCloseAndNotPaid {
        background: linear-gradient(0deg, $process-card-state 50%, $color-red 50%);
      }
      &.notPaid {
        background-color: $color-red;
      }
      &.notClose {
        background-color: $process-card-state;
      }
    }
  }

  .second-row {
    height: 0px;
    overflow: hidden;
    position: relative;
    .link {
      .order-link {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 360px;
        text-align: left;
      }
      cursor: pointer;
    }
    .order-id {
      @include InterBold;
      color: $color-gray;
    }
  }

  .cards-block {
    display: flex;
    flex-direction: column;
    gap: 6px;

    .card-part {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .part-caption {
        display: flex;
        flex-direction: row;
        .icon {
          color: $color-gray;
        }
        &.done {
          .icon {
            color: $order-done-block-color-solid;
          }
          .caption {
            color: $color-gray;
            text-decoration: line-through;
          }
        }
      }
    }
  }
}

.row-centred {
  display: flex;
  flex-direction: row;
  // justify-content: center;
  // align-items: center;
  justify-content: flex-start;
  align-items: center;
}
.icon.showAdditionalInfo {
  transform: rotate(-180deg);
}
.icon {
  transition: transform 0.15s;
}
</style>
