//return a promise that resolves with a File instance
export async function dataUrlToFile(url, filename) {
  const mimeType = (url.match(/^data:([^;]+);/) || '')[1];
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
}
