<template>
  <Bar :id="uuid" :data="chartData" :options="options" :plugins="plugins" />
</template>

<script>
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  LineController,
  PointElement,
  // BarController,
  // Filler,
  // TimeScale,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import uuid from "../../../mixin/uuid.js";
import { totalizer, paddingBelowLegends } from "../../../utils/charts";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ChartDataLabels,
  totalizer,
  paddingBelowLegends,
  //
  LineElement,
  LineController,
  PointElement
  // BarController,
  // Filler,
  // TimeScale
);

export default {
  name: "BarChart",
  components: { Bar },
  mixins: [uuid],
  props: {
    title: {
      type: String,
      default: null,
    },
    labels: {
      type: Array,
      required: true,
    },
    datasets: {
      type: Array,
      required: true,
    },
    lineDataset: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      options: {
        maintainAspectRatio: false,

        responsive: true,
        scales: {
          x: {
            stacked: true,
            // ticks: {
            //   beginAtZero: true,
            //   suggestedMax: 100,
            // },
          },
          y: {
            stacked: true,
          },
        },
        plugins: {
          title: {
            display: !!this.title,
            text: this.title,
            position: "top",
            // padding: {
            //   top: 10,
            //   bottom: 30,
            // },
          },
          legend: {
            display: true,
            position: "right",

            // display: true,
            // align: "center",
            labels: {
              padding: 20,
            },
          },
        },
        // plugins: {
        //   legend: {
        //     // display: true,
        //     // position: "bottom",

        //     // display: true,
        //     // align: "center",
        //     labels: {
        //       // lineDashOffset: 40,
        //       padding: 20,
        //     },
        //   },
        // },
      },
      plugins: [totalizer, paddingBelowLegends],
    };
  },
  computed: {
    chartData() {
      return {
        labels: this.labels,
        datasets: [
          ...this.datasets.map((d) => ({
            order: 1,
            ...d,
          })),
          this.lineDataset
            ? {
                ...this.lineDataset,
                type: "line",
                borderColor: "#3abff8",
                order: 0,
                datalabels: {
                  align: "end",
                  // font: {
                  //   weight: "bold",
                  // },
                  // color: "red",
                  // eslint-disable-next-line
                  // backgroundColor: function (context) {
                  //   return "white";
                  // },
                },
              }
            : {},
          {
            label: "Итого",
            data: new Array(this.labels.length).fill("").map(() => 0),
            backgroundColor: "rgba(24,91,62,0)",
            order: 1,
            datalabels: {
              // eslint-disable-next-line
              backgroundColor: function (context) {
                return "white";
              },
              formatter: (value, ctx) => {
                const total = ctx.chart.$totalizer.totals[ctx.dataIndex];
                return total;
              },
              align: "end",
              anchor: "end",
              display: function (ctx) {
                return ctx.datasetIndex === ctx.chart.$totalizer.utmost;
              },
            },
          },
        ],
      };
    },
  },
};
</script>
