import { cloneDeep } from '@/utils/lodash';

export default {
  SET_ORDERS(state, data) {
    state.orders = data;
  },
  SET_SUMMARY_ORDERS(state, data) {
    state.summaryTable = data;
  },
  SET_SUMMARY_CURRENCT_MONTH(state, data) {
    state.currenctMonthStat = data;
  },
  SET_FILTERS(state, filters) {
    state.filters = cloneDeep(filters);
  },
};
