export default {
  SET_SETS(state, sets) {
    state.sets = sets;
  },
  ADD_SET(state, set) {
    state.sets = [set, ...state.sets];
  },
  SET_SET(state, { set, oldSet }) {
    if (!set) {
      const index = state.sets.findIndex((set) => set.id === oldSet.id);
      state.sets.splice(index, 1);
      return;
    }

    const index = state.sets.findIndex((s) => s.id === set.id);
    if (index === -1) {
      state.sets = [set, ...state.sets];
    } else {
      state.sets.splice(index, 1, set);
    }
  },

  SET_ITEMS(state, items) {
    state.items = items;
  },
  ADD_ITEM(state, item) {
    state.items = [item, ...state.items];
  },
  SET_ITEM(state, { item, oldItem }) {
    if (!item) {
      const index = state.items.findIndex((item) => item.id === oldItem.id);
      state.items.splice(index, 1);
      return;
    }
    const index = state.items.findIndex((i) => i.id === item.id);
    if (index === -1) {
      state.items = [item, ...state.items];
    } else {
      state.items.splice(index, 1, item);
    }
  },

  SET_ARTICLE_IN_ITEMS(state, article) {
    state.items.forEach((item) => {
      if (item.article.id === article.id) {
        item.article = article;
      }
    });
  },

  PATH_ITEM_FIELD_BY_FIELD(state, { key, data, oldData }) {
    for (const item of state.items) {
      if (item[key] === oldData) {
        item[key] = data;
      }
    }
  },

  SET_CLIENT_DESIGNS(state, { data, clientId }) {
    const itemsWithDesigns = Array.from(new Set(data.map((el) => el.itemId)));
    state.clientDesigns = {
      clientId,
      designs: data,
      setsIds: setAvailableKitsForClient(state.sets, itemsWithDesigns),
    };
  },
  RESET_CLIENT_DESIGNS(state) {
    state.clientDesigns = {
      clientId: null,
      designs: [],
      setsIds: [],
    };
  },

  SET_ITEM_ALL_PRODUCTION_LINES(state, data) {
    state.allProdLinesItem = data;
  },
};

function setAvailableKitsForClient(sets, itemsWithDesigns) {
  const result = [];
  for (const set of sets) {
    if (set.itemSets.some((itemSet) => itemsWithDesigns.includes(itemSet.itemId))) {
      result.push(set.id);
    }
  }
  return result;
}
