<template>
  <component :is="layout" />
</template>

<script>
import auth from './layouts/auth.vue';
import common from './layouts/common.vue';

export default {
  components: { common, auth },
  computed: {
    layout() {
      return ['/', '/login'].includes(this.$route.path) ? 'auth' : 'common';
    },
  },
};
</script>
