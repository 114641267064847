<template>
  <div>
    <APageAside :title="getTitle" wide @close="closeHandler">
      <div v-if="selectedEntity || creatingEntity">
        <AssortmentBaseElementBlock
          :entity="selectedEntity"
          :creating-entity="creatingEntity"
          :type="type"
          @close="closeHandler"
        />
      </div>
    </APageAside>
  </div>
</template>

<script>
import AssortmentBaseElementBlock from '@/components/assortment/assortmentDictionaryAsideBlock/components/AssortmentBaseElementBlock.vue';
import { BASE_FIELD_DICTIONARY } from '../assortmentDictionary.js';

export default {
  name: 'AssortmentDictionaryAsideBlock',
  components: {
    AssortmentBaseElementBlock,
  },
  props: {
    selectedEntity: {
      type: Object,
      default: null,
    },
    creatingEntity: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: null,
    },
  },
  emits: ['close'],
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    getTitle() {
      if (!this.type) return;
      const entity = BASE_FIELD_DICTIONARY.find((i) => i.value === this.type);
      return `${this.creatingEntity ? 'Создание' : 'Редактирование'} ${entity?.title || this.type}`;
    },
  },
  methods: {
    closeHandler() {
      this.$emit('close');
      return;
    },
  },
};
</script>
<style lang="scss" scoped>
.confirm-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
</style>
