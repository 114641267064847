<template>
  <div class="block-wrapper">
    <div v-if="localEntity" class="entity">
      <Component
        :is="`${getTypeEntity.component}Block`"
        v-model:selectedEntity="localEntity"
        :creating-entity="creatingEntity"
        :loading="loading"
        :form-errors="formErrors"
      />
      <div class="entity-details my-20">
        <div class="entity-details_block">
          <AButton
            v-if="accessToChange"
            success
            :disabled="!islocalEntityModified"
            @click="saveEntityHandler"
          >
            Сохранить
          </AButton>
          <AButton
            v-if="accessToChange"
            :disabled="!islocalEntityModified"
            @click="updateFromProp()"
          >
            Сбросить
          </AButton>
          <AButton
            v-if="isCanChangeAssortment && !creatingEntity && !loading"
            :disabled="islocalEntityModified"
            :danger="!localEntity.deleted"
            :success="localEntity.deleted"
            @click="deleteEntityHandler(!localEntity.deleted)"
          >
            {{ localEntity.deleted ? 'Восстановить' : 'Удалить' }}
          </AButton>
        </div>
        <ALoader v-if="loading" centered green />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { parseDictionary } from '../../../../utils/dictionaryUtils.js';
import { cloneDeep, isEqual } from '../../../../utils/lodash.js';
import accessMixin from '../../../../mixin/accessMixin.js';
import {
  baseEntityAssortmentSchema,
  productTypeAssortmentSchema,
  articleAssortmentSchema,
} from '../../../../utils/validate/index.js';
import validateMixin from '../../../../mixin/validate.js';
import ConfirmDialog from '@@/components/Confirm/index.vue';
import { BASE_FIELD_DICTIONARY } from '../../assortmentDictionary.js';

import ProductTypeBlock from './ProductTypeBlock.vue';
import CategoryBlock from './CategoryBlock.vue';
import GrowTypeBlock from './GrowTypeBlock.vue';
import ArticleBlock from './ArticleBlock.vue';
import FabricBlock from './FabricBlock.vue';
import SexBlock from './SexBlock.vue';

const NEW_ENTITIES = {
  newCategoryItem: {
    id: null,
    name: null,
    deleted: false,
    isNewItem: true,
  },
  newFabricItem: {
    id: null,
    name: null,
    deleted: false,
    isNewItem: true,
  },
  newProductTypeItem: {
    id: null,
    name: null,
    deleted: false,
    isNewItem: true,
  },
  newGrowTypeItem: {
    id: null,
    name: null,
    deleted: false,
    isNewItem: true,
  },
  newSexItem: {
    id: null,
    name: null,
    deleted: false,
    isNewItem: true,
  },
  newArticleItem: {
    id: null,
    name: null,
    code: null,
    desc: null,
    file: null,
    mockup: {
      front: null,
      back: null,
      plain: null,
    },
    fileToUpload: {
      front: null,
      back: null,
      plain: null,
    },
    deleted: false,
    isNewItem: true,
  },
};

export default {
  name: 'AssortmentBaseElementBlock',
  components: {
    ProductTypeBlock,
    CategoryBlock,
    GrowTypeBlock,
    ArticleBlock,
    FabricBlock,
    SexBlock,
  },
  mixins: [accessMixin, validateMixin],
  props: {
    entity: {
      type: Object,
      default: null,
    },
    creatingEntity: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: null,
    },
  },
  emits: ['close'],
  data() {
    return {
      loading: false,

      localEntity: null,
      errors: null,
    };
  },
  computed: {
    ...mapState('dictionary', ['assortmentDictionary']),
    accessToChange() {
      return this.isCanChangeAssortment && !this.localEntity.deleted && !this.loading; // TODO !this.item.deleted;
    },
    islocalEntityModified() {
      return !isEqual(this.entity, this.localEntity);
    },
    getTypeEntity() {
      return BASE_FIELD_DICTIONARY.find((i) => i.value === this.type);
    },
  },
  watch: {
    entity: function (entity) {
      this.updateFromProp(entity);
    },
  },
  created() {
    this.updateFromProp();
    this.parseDictionary = parseDictionary;
  },
  methods: {
    ...mapActions({
      changeCategory: 'dictionary/changeCategory',
      changeFabric: 'dictionary/changeFabric',
      changeArticle: 'dictionary/changeArticle',
      changeGrowType: 'dictionary/changeGrowType',
      changeProductType: 'dictionary/changeProductType',
      changeSex: 'dictionary/changeSex',
      uploadImage: 'upload/uploadImage',
      uploadArticle: 'upload/uploadArticle',
    }),
    async saveEntity(deleteOrRestore = false) {
      const isValidMockup = this.checkArticleMockup();
      if (!isValidMockup) return;

      let schema;
      if (this.getTypeEntity.value === 'article') {
        schema = articleAssortmentSchema;
      } else if (this.getTypeEntity.value === 'productType') {
        schema = productTypeAssortmentSchema;
      } else {
        schema = baseEntityAssortmentSchema;
      }
      const formValidation = this.$form(this.localEntity, schema);
      if (!formValidation.isValid) {
        this.$notifyError({ title: `Ошибка валидации формы` });
        return;
      }
      this.loading = true;
      if (this.getTypeEntity.value === 'article') {
        if (this.localEntity.fileToUpload?.front) {
          const { sha2 } = await this.uploadImage({
            file: this.localEntity.fileToUpload.front,
            entityId: this.localEntity.id,
            key: 'article-mockup-front',
          });
          this.localEntity.mockup.front = sha2;
        }
        if (this.localEntity.fileToUpload?.back) {
          const { sha2 } = await this.uploadImage({
            file: this.localEntity.fileToUpload.back,
            entityId: this.localEntity.id,
            key: 'article-mockup-back',
          });
          this.localEntity.mockup.back = sha2;
        }
        if (this.localEntity.fileToUpload?.plain) {
          const { sha2 } = await this.uploadImage({
            file: this.localEntity.fileToUpload.plain,
            entityId: this.localEntity.id,
            key: 'article-mockup-plain',
          });
          this.localEntity.mockup.plain = sha2;
        }
        if (this.localEntity.fileToUpload?.articleFile) {
          const { sha2 } = await this.uploadArticle({
            file: this.localEntity.fileToUpload.articleFile,
            entityId: this.localEntity.id,
          });
          this.localEntity.file = sha2;
        }
        delete this.localEntity.fileToUpload;
      }
      try {
        await this[`change${this.getTypeEntity.component}`]({
          [this.getTypeEntity.value]: this.localEntity,
        });
        this.$emit('close');
        this.$notify({ title: 'Сохранено' });
      } catch (err) {
        if (deleteOrRestore) {
          this.localEntity.deleted = !this.localEntity.deleted;
        }
        console.log('error saveEntity', err);
      } finally {
        this.loading = false;
      }
    },
    deleteEntityHandler(needDeleted) {
      if (this.islocalEntityModified) {
        this.$notifyError({
          title: `Сбросте (или сохраните) все изменения, а затем удалите`,
        });
        return;
      }
      this.$dialog.addDialog({
        component: ConfirmDialog,
        props: {
          title: `${needDeleted ? 'Удаление' : 'Восстановление'} ${this.getTypeEntity.title}`,
          // text: `Вы действительно хотите ${needDeleted ? 'удалить' : 'восстановить'} ${
          //   this.localEntity.name || this.getTypeEntity.name
          // }?`,
          text: `Это приведет к ${needDeleted ? 'удалению' : 'восстановлению'} ${
            this.getTypeEntity.title
          } "${this.localEntity.name}"`,
          onConfirm: (confirm) => {
            confirm.showModal = false;
            this.localEntity.deleted = needDeleted;
            this.saveEntity(true);
          },
        },
      });
    },
    saveEntityHandler() {
      this.$dialog.addDialog({
        component: ConfirmDialog,
        props: {
          title: 'Сохранить изменения',
          text: 'Применить выбранные изменения?',
          onConfirm: (confirm) => {
            confirm.showModal = false;
            this.saveEntity();
          },
        },
      });
    },
    updateFromProp(entity) {
      let value;
      if (this.creatingEntity) {
        value = NEW_ENTITIES[`new${this.getTypeEntity.component}Item`];
      } else {
        value = entity || this.entity;
      }
      this.localEntity = cloneDeep(value);
    },
    checkArticleMockup() {
      if (this.getTypeEntity?.value !== 'article') return true;
      let isValid = true;
      if (!this.localEntity.fileToUpload?.front && !this.localEntity.mockup.front) {
        this.formErrors[`mockup.front`] = 'Необходимо загрузить мокап (перед)';
        isValid = false;
      } else if (this.localEntity.fileToUpload?.front) {
        delete this.formErrors[`mockup.front`];
      }
      if (!this.localEntity.fileToUpload?.back && !this.localEntity.mockup.back) {
        this.formErrors[`mockup.back`] = 'Необходимо загрузить мокап (спинку)';
        isValid = false;
      } else if (this.localEntity.fileToUpload?.back) {
        delete this.formErrors[`mockup.back`];
      }
      if (!this.localEntity.fileToUpload?.plain && !this.localEntity.mockup.plain) {
        this.formErrors[`mockup.plain`] = 'Необходимо загрузить раскладку';
        isValid = false;
      } else if (this.localEntity.fileToUpload?.plain) {
        delete this.formErrors[`mockup.plain`];
      }
      return isValid;
    },
  },
};
</script>
<style lang="scss" scoped>
@include assortment-base-entity;
</style>
