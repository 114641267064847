<template>
  <div class="client-tab">
    <div v-if="!pageOrder.clientId" class="a-row need-client">Необходимо выбрать клиента</div>
    <div class="a-row mb-10">
      <AInput
        v-model="teamName"
        label="Создать новую команду"
        class="mr-20"
        :disabled="!!teamId || !accessToChange"
      />
      <ASelect
        v-model="teamId"
        :options="allTeams"
        label="Команда клиента"
        track-key="guid"
        select-label="name"
        :disabled="!!teamName || !accessToChange"
      />
    </div>
    <div v-if="pageOrder.clientId && allTeams.length" class="a-row mt-10">
      <span> У клиента есть {{ allTeams.length }} {{ getPluralizedTeams(allTeams.length) }} </span>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex';
import accessMixin from '@/mixin/accessMixin.js';
import { ORDER_STATE } from '../../../../constants';
import { pluralizedTeams } from '../../../../constants/pluralizedWords';

export default {
  name: 'OrderInfoTeamBlock',
  mixins: [accessMixin],
  props: {
    isSelectedOrder: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      newOrder: (s) => s.order.newOrder,
      selectedOrder: (s) => s.order.selectedOrder,
      selectedOrderInfo: (s) => s.order.selectedOrderInfo,
      newOrderInfo: (s) => s.order.newOrderInfo,
    }),
    accessToChange() {
      return (
        this.isCanChangeOrder &&
        this.pageOrder.clientId &&
        ![ORDER_STATE.COMPLETED, ORDER_STATE.PRODUCTION, ORDER_STATE.TECH_DESIGN].includes(
          this.pageOrder._old.state
        )
      );
    },
    pageOrder() {
      return this.isSelectedOrder ? this.selectedOrder : this.newOrder;
    },
    orderInfo() {
      return this.isSelectedOrder ? this.selectedOrderInfo : this.newOrderInfo;
    },
    allTeams() {
      return this.isSelectedOrder ? this.selectedOrder.teams || [] : this.orderInfo?.teams || [];
    },
    teamName: {
      get() {
        return this.pageOrder.teamName;
      },
      set(value) {
        this.setOrderField({ selectedOrder: this.isSelectedOrder, key: 'teamName', data: value });
      },
    },
    teamId: {
      get() {
        return this.pageOrder.teamId;
      },
      set(value) {
        this.setOrderField({ selectedOrder: this.isSelectedOrder, key: 'teamId', data: value });
      },
    },
  },
  beforeMount() {
    this.teamId =
      this.isSelectedOrder && this.selectedOrder?.teamId ? this.selectedOrder?.teamId : null;
  },
  methods: {
    ...mapMutations({
      setOrderField: 'order/setOrderField',
    }),
    getPluralizedTeams(num) {
      return pluralizedTeams(num);
    },
  },
};
</script>
<style lang="scss" scoped>
.client-tab {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 275px;

  overflow: hidden;
  @include scroll;

  .need-client {
    display: flex;
    justify-content: center;
    margin: 10px 0px;

    color: $color-red;
  }
}
</style>
