const orderTagsFields = [
  { key: 'id', label: 'Id', sortable: true, class: 'id' },
  { key: 'name', label: 'Название', sortable: true },
  { key: 'color', label: 'Цвет' },
];

const orderCategoryFields = [
  { key: 'id', label: 'Id', sortable: true, class: 'id' },
  { key: 'name', label: 'Название', sortable: true },
];

export { orderTagsFields, orderCategoryFields };
