export const totalizer = {
  id: 'totalizer',
  beforeUpdate: (chart) => {
    let totals = {};
    let utmost = 0;

    chart.data.datasets.forEach((dataset, datasetIndex) => {
      if (chart.isDatasetVisible(datasetIndex) && dataset.order === 1) {
        utmost = datasetIndex;
        dataset.data.forEach((value, index) => {
          totals[index] = (totals[index] || 0) + value;
        });
      }
    });
    chart.$totalizer = {
      totals: totals,
      utmost: utmost
    };
  }
};

// TODO: протестировать на роботоспособность
export const paddingBelowLegends = {
  id: 'paddingBelowLegends',
  beforeInit: function (chart) {
    chart.legend.afterFit  = function () {
      this.height = this.height + 50;
    };
  }
};
