import BaseService from './services/BaseService.js';

class UploadApi extends BaseService {
  async uploadImage({ file, key, entityId }) {
    const formData = new FormData();
    await formData.append('file', file);
    await formData.append('key', key);
    await formData.append('entityId', entityId);
    const data = await this.api.post('/upload', formData);
    return data;
  }
  async uploadArticle({ file, entityId }) {
    const formData = new FormData();
    await formData.append('file', file);
    await formData.append('key', 'article');
    await formData.append('entityId', entityId);
    const data = await this.api.post('/upload', formData);
    return data;
  }
  async uploadFile({ file, key, entityId }) {
    const formData = new FormData();
    await formData.append('file', file);
    await formData.append('key', key);
    await formData.append('entityId', entityId);
    const data = await this.api.post('/upload', formData);
    return data;
  }
}
export default new UploadApi();
