import productionTableApi from '@/api/productionTableApi';

export default {
  async getProductionTableOrdersData({ commit }, { filters } = {}) {
    const { data } = await productionTableApi.getProductionTableOrdersData({ filters });
    commit('SET_ORDERS', data);
    commit('SET_FILTERS', filters);
  },
  async getProductionTableSummaryOrdersData({ commit }, { filters } = {}) {
    const { data } = await productionTableApi.getProductionTableSummaryOrdersData({ filters });
    commit('SET_SUMMARY_ORDERS', data);
  },
  async getProductionTableSummaryMonthStat({ commit }) {
    const { data } = await productionTableApi.getProductionTableSummaryMonthStat();
    commit('SET_SUMMARY_CURRENCT_MONTH', data);
  },
};
