<template>
  <div class="example-full" :class="{ [position]: position }">
    <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
      <h3>Загрузить изображения</h3>
    </div>
    <div class="upload" v-show="!isOption">
      <div v-if="!filesModel.length">
        <div class="text-center p-5">
          <div class="dpordownarea" :for="name" v-if="placeholder">
            <label :for="name" class="btn btn-lg btn-primary">
              Перетащите файлы для загрузки
            </label>
          </div>
          <!-- <label :for="name" class="btn btn-lg btn-primary">
            Загрузить файл
          </label> -->
        </div>
      </div>
      <div v-if="filesModel.length" class="image--list">
        <div
          v-for="file in filesModel"
          :key="file.id"
          v-show="!hidePreviewOnUpload || !file.success"
          class="image--wrapper"
        >
          <div class="image--preview">
            <img
              class="image--preview-img"
              v-if="file.thumb"
              :src="file.thumb"
            />
            <span v-else>-</span>
          </div>
          <div>
            <!-- <div class="filename">
              {{ file.name }}
            </div> -->
            <!-- <div
              class="progress"
              v-if="file.active || file.progress !== '0.00'"
            >
              <div
                :class="{
                  'progress-bar': true,
                  'progress-bar-striped': true,
                  'bg-danger': file.error,
                  'progress-bar-animated': file.active,
                }"
                role="progressbar"
                :style="{ width: file.progress + '%' }"
              >
                {{ file.progress }}%
              </div>
            </div> -->
          </div>
          <div v-if="file.error" class="image--error">
            <fa
              class="icon"
              style="width: 25px; height: 25px"
              icon="plus-circle"
            />
          </div>
          <div v-else-if="file.success" class="image--success">
            <fa
              class="icon"
              style="width: 25px; height: 25px"
              icon="check-circle"
            />
          </div>
          <div
            v-if="!file.success"
            class="image--remove"
            @click.prevent="$refs.upload.remove(file)"
          >
            <fa
              class="icon"
              style="width: 25px; height: 25px"
              icon="plus-circle"
            />
          </div>
        </div>
      </div>

      <div class="example-foorer">
        <div class="btn-group">
          <FileUpload
            class="btn btn-primary dropdown-toggle"
            :custom-action="uploadFiles"
            :extensions="extensions"
            :accept="accept"
            :multiple="multiple"
            :directory="directory"
            :create-directory="createDirectory"
            :size="size || 0.1"
            :thread="thread < 1 ? 1 : thread > 5 ? 5 : thread"
            :headers="headers"
            :data="data"
            :drop="drop"
            :drop-directory="dropDirectory"
            :add-index="addIndex"
            v-model="filesModel"
            @input-filter="inputFilter"
            @input-file="inputFile"
            ref="upload"
          >
            <!-- <i class="fa fa-plus"></i>
            Select -->
          </FileUpload>
          <!-- <div class="dropdown-menu">
            <label class="dropdown-item" :for="name">Add files</label>
          </div> -->
        </div>
        <!-- <button
          type="button"
          class="btn btn-success"
          v-if="!$refs.upload || !$refs.upload.active"
          @click.prevent="$refs.upload.active = true"
        >
          <i class="fa fa-arrow-up" aria-hidden="true"></i>
          Start Upload
        </button>
        <button
          type="button"
          class="btn btn-danger"
          v-else
          @click.prevent="$refs.upload.active = false"
        >
          <i class="fa fa-stop" aria-hidden="true"></i>
          Stop Upload
        </button> -->
      </div>
    </div>
  </div>
</template>

<script>
import uuid from "../../mixin/uuid.js";
import { formatSize } from "../../utils/formatSize.js";
export default {
  name: "AFileUpload",
  mixins: [uuid],
  props: {
    uploadFiles: {
      type: Function,
      required: true,
    },
    startUpload: {
      type: Boolean,
      default: false,
    },
    hidePreviewOnUpload: {
      type: Boolean,
      default: true,
    },
    position: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    files: {
      type: Array,
      default: null,
    },
  },
  emits: ["update:uploadInfo", "update:files"],
  data() {
    return {
      localFiles: [],
      accept: "image/png,image/jpeg",
      // extensions: "gif,jpg,jpeg,png,webp",
      extensions: ["jpg", "jpeg", "png"],
      // extensions: /\.(gif|jpe?g|png|webp)$/i,
      minSize: 1024,
      size: 1024 * 1024 * 10,
      multiple: true,
      directory: false,
      drop: true,
      dropDirectory: true,
      createDirectory: true,
      addIndex: false,
      thread: 3,
      name: `file-upload-${this.uuid}`,
      // postAction: "/upload/post",
      // putAction: "/upload/put",
      headers: {
        "X-Csrf-Token": "xxxx",
      },
      data: {
        _csrf_token: "xxxxxx",
      },
      autoCompress: 1024 * 1024,
      uploadAuto: false,
      isOption: false,
      addData: {
        show: false,
        name: "",
        type: "",
        content: "",
      },
      editFile: {
        show: false,
        name: "",
      },
    };
  },
  computed: {
    filesModel: {
      get() {
        return this.files || this.localFiles;
      },
      set(val) {
        if (!val) return;
        if (!this.files) {
          this.localFiles = val;
        } else {
          this.$emit("update:files", val);
        }
      },
    },
  },
  watch: {
    "editFile.show"(newValue, oldValue) {
      //  error
      if (!newValue && oldValue) {
        this.$refs.upload.update(this.editFile.id, {
          error: this.editFile.error || "",
        });
      }
      if (newValue) {
        this.$nextTick(() => {
          if (!this.$refs.editImage) {
            return;
          }
          // let cropper = new Cropper(this.$refs.editImage, {
          //   autoCrop: false,
          // });
          // this.editFile = {
          //   ...this.editFile,
          //   cropper,
          // };
        });
      }
    },
    "addData.show"(show) {
      if (show) {
        this.addData.name = "";
        this.addData.type = "";
        this.addData.content = "";
      }
    },
    startUpload(statUpload) {
      this.$refs.upload.active = !!statUpload;
    },
    // filesModel: {
    //   handler: function (value) {
    //     this.$emit("updete:files", value);
    //   },
    //   deep: true,
    // },
  },
  methods: {
    $formatSize(v) {
      return formatSize(v);
    },
    // 3th params- prevent
    inputFilter(newFile, oldFile, prevent) {
      if (
        newFile &&
        newFile.error === "" &&
        newFile.file &&
        (!oldFile || newFile.file !== oldFile.file)
      ) {
        // Create a blob field
        newFile.blob = "";
        let URL = window.URL || window.webkitURL;
        if (URL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }
        // Thumbnails
        newFile.thumb = "";
        if (newFile.blob && newFile.type.substr(0, 6) === "image/") {
          newFile.thumb = newFile.blob;
        } else {
          this.alert(
            `Недопустимый формат ${newFile.name ? `файла ${newFile.name}` : ""}`
          );
          prevent();
        }
      }
      // image size
      if (
        newFile &&
        newFile.error === "" &&
        newFile.type.substr(0, 6) === "image/" &&
        newFile.blob &&
        (!oldFile || newFile.blob !== oldFile.blob)
      ) {
        newFile.error = "image parsing";
        let img = new Image();
        img.onload = () => {
          this.$refs.upload.update(newFile, {
            error: "",
            height: img.height,
            width: img.width,
          });
        };
        img.οnerrοr = (e) => {
          this.$refs.upload.update(newFile, { error: "parsing image size" });
          console.log(e);
        };
        img.src = newFile.blob;
      }
    },
    // add, update, remove File Event
    inputFile(newFile, oldFile) {
      if (newFile && oldFile) {
        // update
        if (newFile.active && !oldFile.active) {
          // beforeSend
          // min size
          if (
            newFile.size >= 0 &&
            this.minSize > 0 &&
            newFile.size < this.minSize
          ) {
            this.$refs.upload.update(newFile, { error: "size" });
          }
        }
        if (newFile.progress !== oldFile.progress) {
          // progress
        }
        if (newFile.error && !oldFile.error) {
          // error
        }
        if (newFile.success && !oldFile.success) {
          // success
        }
      }
      if (!newFile && oldFile) {
        // remove
        if (oldFile.success && oldFile.response.id) {
          // $.ajax({
          //   type: 'DELETE',
          //   url: '/upload/delete?id=' + oldFile.response.id,
          // })
        }
      }
      // Automatically activate upload
      if (
        Boolean(newFile) !== Boolean(oldFile) ||
        oldFile.error !== newFile.error
      ) {
        if (this.uploadAuto && !this.$refs.upload.active) {
          this.$refs.upload.active = true;
        }
      }
    },
    alert(message) {
      this.$notifyError({ title: message });
    },
    onEditFileShow(file) {
      this.editFile = { ...file, show: true };
      this.$refs.upload.update(file, { error: "edit" });
    },
    onEditorFile() {
      if (!this.$refs.upload.features.html5) {
        this.alert("Your browser does not support");
        this.editFile.show = false;
        return;
      }
      let data = {
        name: this.editFile.name,
        error: "",
      };
      if (this.editFile.cropper) {
        let binStr = atob(
          this.editFile.cropper
            .getCroppedCanvas()
            .toDataURL(this.editFile.type)
            .split(",")[1]
        );
        let arr = new Uint8Array(binStr.length);
        for (let i = 0; i < binStr.length; i++) {
          arr[i] = binStr.charCodeAt(i);
        }
        data.file = new File([arr], data.name, { type: this.editFile.type });
        data.size = data.file.size;
      }
      this.$refs.upload.update(this.editFile.id, data);
      this.editFile.error = "";
      this.editFile.show = false;
    },
    // add folder
    onAddFolder() {
      if (!this.$refs.upload.features.directory) {
        this.alert("Your browser does not support");
        return;
      }
      let input = document.createElement("input");
      input.style =
        "background: rgba(255, 255, 255, 0);overflow: hidden;position: fixed;width: 1px;height: 1px;z-index: -1;opacity: 0;";
      input.type = "file";
      input.setAttribute("allowdirs", true);
      input.setAttribute("directory", true);
      input.setAttribute("webkitdirectory", true);
      input.multiple = true;
      document.querySelector("body").appendChild(input);
      input.click();
      input.onchange = (e) => {
        this.$refs.upload.addInputFile(input).then(function () {
          document.querySelector("body").removeChild(input);
        });
        console.log(e);
      };
    },
    onAddData() {
      this.addData.show = false;
      if (!this.$refs.upload.features.html5) {
        this.alert("Your browser does not support");
        return;
      }
      let file = new window.File([this.addData.content], this.addData.name, {
        type: this.addData.type,
      });
      this.$refs.upload.add(file);
    },
  },
};
</script>

<style scoped lang="scss">
.image {
  &--list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-self: center;
    justify-self: center;
    padding: 5px;
    border: 1px solid $color-bg-smoke;
    border-radius: 8px;
    max-width: 100%;

    background: #eee;
    background-image: linear-gradient(
        45deg,
        rgba(0, 0, 0, 0.09) 25%,
        transparent 0,
        transparent 75%,
        rgba(0, 0, 0, 0.09) 0
      ),
      linear-gradient(
        45deg,
        rgba(0, 0, 0, 0.09) 25%,
        transparent 0,
        transparent 75%,
        rgba(0, 0, 0, 0.09) 0
      );
    background-position: 0 0, 15px 15px, 15px 15px, 30px 30px;
    background-size: 30px 30px;
  }
  &--wrapper {
    display: flex;
    position: relative;
    width: 100px;
    height: 140px;

    align-content: center;

    &:hover {
      .image--remove {
        opacity: 1;
        z-index: 10;
      }
    }
  }
  &--preview {
    display: flex;

    align-content: center;
    align-items: center;
    align-self: center;
    justify-items: center;
    justify-self: center;
    justify-content: center;
  }
  &--preview-img {
    max-width: 100px;
    max-height: 120px;
  }
  &--success {
    position: absolute;
    top: calc(50% - 20px);
    right: calc(50% - 12px);
    color: $color-apple;
  }
  &--error {
    position: absolute;
    top: calc(50% - 20px);
    right: calc(50% - 12px);
    color: $color-red;
    transform: rotate(45deg);
  }
  &--remove {
    opacity: 0;
    position: absolute;
    top: calc(50% - 20px);
    right: calc(50% - 12px);
    color: $color-red;
    transform: rotate(45deg);
    cursor: pointer;
  }
}

.dpordownarea {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  border-radius: 8px;
  background-color: $color-bg-light-blue;
  @include InterBold;
}
.example-full {
  // position
  .drop-active {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: fixed;
    z-index: 9999;
    opacity: 0.6;
    text-align: center;
    background: #000;
  }
  &.left .drop-active {
    right: 52%;
  }
  &.right .drop-active {
    left: 50%;
  }
}
.example-full .drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
</style>
