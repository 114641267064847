import BaseService from './services/BaseService.js';

class userApi extends BaseService {
  async getCurrentUser() {
    const data = await this.api.get(`/user`);
    return data;
  }
  async getUserById({ userId }) {
    const data = await this.api.get(`/user/${userId}`);
    return data;
  }
  async createUser({ user }) {
    const data = await this.api.post(`/user`, { user });
    return data;
  }
  async changeUser({ user }) {
    const data = await this.api.patch(`/user`, { user });
    return data;
  }
  async getAllDesigners() {
    const data = await this.api.get(`/user/get-designers`);
    return data;
  }
  async getEmployee({ userId }) {
    const data = await this.api.get(`/user/get-employee/${userId}`);
    return data;
  }
  async getAllManagers() {
    const data = await this.api.get(`/user/get-managers`);
    return data;
  }
  async getAllEmployees() {
    const data = await this.api.get(`/user/get-employee`);
    return data;
  }
  async changeEmployee({ user }) {
    const data = await this.api.patch(`/user/change-employee`, { user });
    return data;
  }
}
export default new userApi();
