<template>
  <div class="block" :class="{ close: !isOpen }" v-bind="$attrs">
    <div
      class="block__content"
      :class="{ withoutPadding, isOpen, close: !isOpen }"
    >
      <div class="block__header">
        <div class="block__header-text"><slot name="header" /></div>
        <div class="block__header-btn noSelect">
          <fa
            v-if="toggle || modelToggle"
            @click="toggleHandler"
            class="toggle-icon"
            icon="chevron-up"
            style="width: 25px; height: 20px"
            :class="{ isOpen }"
          />
        </div>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "PageBlock",
  props: {
    toggle: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Boolean,
      default: true,
    },
    modelToggle: {
      type: Boolean,
      default: false,
    },
    withoutPadding: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      isOpen: this.modelToggle ? this.modelValue : true,
    };
  },
  watch: {
    modelValue(value) {
      if (this.modelToggle) {
        this.isOpen = value;
      }
    },
  },
  methods: {
    toggleHandler() {
      if (this.modelToggle) {
        this.$emit("update:modelValue", !this.isOpen);
        return;
      }
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
.block {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  width: 100%;
  // height: 100%;

  margin: 0px;
  padding: 0px;

  &.close {
    height: fit-content;
  }

  &__content {
    display: grid;
    grid-template-rows: 35px 1fr;

    width: 100%;
    height: 100%;

    border-radius: 5px;
    box-shadow: $box-shadow-block;

    padding: 10px 14px;

    transition: height 0.3s;

    &.withoutPadding {
      padding: 0px 0px;
    }
    &.close > :last-child {
      height: 0px;

      transition: height 0.3s;
      visibility: hidden;
      overflow: hidden;
    }

    .block__header {
      display: grid;
      grid-template-columns: 1fr 45px;
      margin-bottom: -1px;
      align-self: center;

      &-text {
        padding: 4px 7px;

        @include InterSemibold;
        font-size: 16px;
      }
      &-btn {
        align-self: center;
        .toggle-icon {
          color: $color-light-gray;
          cursor: pointer;
          transition: transform 0.3s;
          &.isOpen {
            transform: rotate(-180deg);
          }
        }
      }
    }
  }
}
</style>
