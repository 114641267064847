<template>
  <div class="loader-line" />
</template>
<script>
export default {
  name: "LinePageLoader",
};
</script>
<style lang="scss" scoped>
.loader-line {
  display: block;
  position: absolute;
  top: 0px;
  left: -10%;
  right: 0px;
  bottom: 0px;
  height: 6px;
}
.loader-line:before {
  content: "";
  position: absolute;
  height: 5px;
  width: 40%;
  background-color: $process-card-state;
  -webkit-animation: lineAnim 1s linear infinite;
  -moz-animation: lineAnim 1s linear infinite;
  animation: lineAnim 1s linear infinite;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

@keyframes lineAnim {
  0% {
    left: -36px;
    width: 0px;
  }
  50% {
    right: 0px;
    width: 100%;
  }
  100% {
    right: 0px;
    width: 0%;
  }
}
</style>
