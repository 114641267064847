<template>
  <div class="block-wrapper">
    <div v-if="localProductionLine" class="production-line">
      <div class="production-line-item-actions">
        <div v-if="creatingProdLine">
          <ASelect
            v-model="newCreatingProdLineId"
            :options="filtredProductionLines"
            disable-deleted
            block-unselect
            select-label="name"
            label="Производственная линия"
            :read-only="!accessToChange || productionLineLoading"
            :error="getErrMess(`productionLineId`)"
          />
        </div>
        <div v-else>
          <ASelect
            :model-value="activeProdLineId"
            :options="filtredProductionLines"
            disable-deleted
            block-unselect
            select-label="name"
            label="Производственная линия"
            read-only
            :error="getErrMess(`productionLineId`)"
          />
        </div>

        <div />
        <div class="btns">
          <AInput
            v-if="accessToChange"
            v-model="itemIdForImportSettings"
            type="number"
            label="Исх. изд., id"
            :disabled="productionLineLoading"
            :min="1"
            integer
            class="element mr-10"
          />
          <AInput
            v-if="accessToChange"
            v-model="itemProdLineIdForImportSettings"
            type="number"
            label="Пр. лин, id"
            :disabled="productionLineLoading"
            :min="1"
            integer
            class="element mr-10"
          />
          <AButton v-if="accessToChange" @click="importProductionSettingFromItem">
            Заполнить
          </AButton>
        </div>
      </div>
      <div v-if="!productionLineLoading" class="production-line-details">
        <ASpacer text="Проиводственные параметры изделия" class="mb-15 mt-15" bold />
        <div v-if="localProductionLine.departmentSettings.length" class="production-line-setting">
          <div
            v-for="(departmentSetting, ind) of localProductionLine.departmentSettings"
            :key="`${ind}_${departmentSetting.id}`"
            class="production-line-setting-blocks"
          >
            <div class="production-line-setting-blocks-wrapper">
              <div class="production-line-setting-blocks-content">
                <transition-group tag="div" name="list-complete">
                  <div
                    v-for="(depSet, ink) of departmentSetting"
                    :key="`${ind}_${ink}_${depSet.id}`"
                    class="production-line-setting-block list-complete-item"
                  >
                    <div class="production-line-setting-block-content-wrapper">
                      <div class="production-line-setting-block-content">
                        <ASelect
                          v-model="depSet.departmentId"
                          :options="departments"
                          disable-deleted
                          select-label="name"
                          label="Отдел"
                          :disabled="productionLineLoading"
                          read-only
                          :error="getErrMess(`departmentSettings[${ind}][${ink}].departmentId`)"
                          class="element"
                        />
                        <AInput
                          v-model="depSet.employeeIncomePerUnit"
                          type="number"
                          label="Доход работника за 1шт. изделия, Руб"
                          :disabled="depSet.deleted || productionLineLoading"
                          :read-only="!accessToChange"
                          :error="
                            getErrMess(`departmentSettings[${ind}][${ink}].employeeIncomePerUnit`)
                          "
                          :min="0"
                          class="element"
                        />
                        <AInput
                          v-model="depSet.commissioning"
                          type="number"
                          label="Пусконаладка, сек"
                          :disabled="depSet.deleted || productionLineLoading"
                          :read-only="!accessToChange"
                          :error="getErrMess(`departmentSettings[${ind}][${ink}].commissioning`)"
                          :min="0"
                          integer
                          class="element"
                        />
                        <AInput
                          v-model="depSet.timePerUnit"
                          type="number"
                          label="Время затраченое на одно изд. сек"
                          :disabled="depSet.deleted || productionLineLoading"
                          :read-only="!accessToChange"
                          :error="getErrMess(`departmentSettings[${ind}][${ink}].timePerUnit`)"
                          :min="0"
                          integer
                          class="element"
                        />
                      </div>
                    </div>
                  </div>
                </transition-group>
              </div>
            </div>
          </div>
        </div>
        <div class="production-line-details_block mt-15">
          <AButton
            v-if="accessToChange"
            success
            :disabled="!islocalProductionLineModified && !!item.productionLineId"
            @click="saveProductionLine"
          >
            Сохранить
          </AButton>
          <AButton
            v-if="accessToChange"
            :disabled="!islocalProductionLineModified"
            @click="updateFromProp"
          >
            Сбросить
          </AButton>
          <AButton
            v-if="item && accessToChange && !creatingProdLine"
            :disabled="islocalProductionLineModified"
            danger
            @click="deleteHandlerConfirm"
          >
            Удалить произв. парам. для изделия
          </AButton>
        </div>
        <ALoader v-if="productionLineLoading" centered green />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { cloneDeep, isEqual } from '../../../utils/lodash.js';
import accessMixin from '../../../mixin/accessMixin.js';
import { itemProductionLineBlockSchema } from '../../../utils/validate/index.js';
import validateMixin from '../../../mixin/validate.js';
// import ConfirmDialog from '@@/components/Confirm/index.vue';
import AsideDeleteProductionLineModal from './AsideDeleteProductionLineModal.vue';
import { getDepartmentIndsFromProductionLine } from '@/utils/productionLine.js';
import { DEFAULT_PRODUCTION_LINE_ID } from '../../../constants/index.js';

export default {
  name: 'ItemProductionLineBlock',
  mixins: [accessMixin, validateMixin],
  props: {
    item: {
      type: Object,
      default: null,
    },
    activeProdLineId: {
      type: Number,
      default: null,
    },
    creatingProdLine: {
      type: Boolean,
      default: false,
    },
    itemProdLinesIds: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['close', 'createProdLine', 'deleteProdLine'],
  data() {
    return {
      productionLineLoading: false,

      localProductionLine: null,
      productionLineSource: null, // не мутировать

      itemIdForImportSettings: null,
      itemProdLineIdForImportSettings: null,

      newCreatingProdLineId: null,
      resolveChangedDepartments: null,
    };
  },
  computed: {
    ...mapState({
      departments: (s) => s.production.departments,
      productionLines: (s) => s.production.productionLines,
    }),
    accessToChange() {
      return (
        !this.item.deleted &&
        this.isCanChangeProduction &&
        !this.localProductionLine.deleted &&
        !this.productionLineLoading
      );
    },
    filtredProductionLines() {
      return this.productionLines.filter((p) => {
        if (p.deleted) {
          return false;
        }
        const alreadyExist = !this.itemProdLinesIds.includes(p.id);
        if (this.creatingProdLine) {
          return alreadyExist;
        }
        return alreadyExist || p.id === this.activeProdLineId;
      });
    },
    islocalProductionLineModified() {
      return !isEqual(this.productionLineSource, this.localProductionLine);
    },
  },
  watch: {
    'activeProdLineId': async function (newValue) {
      await this.updateFromProp(newValue);
    },
    'newCreatingProdLineId': async function (newValue) {
      await this.updateFromProp(null, newValue);
    },
  },
  async created() {
    this.DEFAULT_PRODUCTION_LINE_ID = DEFAULT_PRODUCTION_LINE_ID;
    await this.updateFromProp(this.activeProdLineId);
  },
  methods: {
    ...mapActions({
      changeItemProductionLine: 'production/changeItemProductionLine',
      getProductionLine: 'production/getProductionLineToComponent',
    }),
    async saveProductionLine() {
      const formValidation = this.$form(this.localProductionLine, itemProductionLineBlockSchema);
      if (!formValidation.isValid) {
        this.$notifyError({ title: `Ошибка валидации формы` });
        return;
      }
      this.productionLineLoading = true;
      try {
        const updatedItemDepartmentLine = await this.changeItemProductionLine({
          productionLine: this.localProductionLine,
          itemId: this.item.id,
        });

        this.setLocalEntity(updatedItemDepartmentLine);
        this.$notify({ title: 'Сохранено' });
        if (this.creatingProdLine) {
          this.$emit('createProdLine', updatedItemDepartmentLine);
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.productionLineLoading = false;
      }
    },
    // sync ItemProductionControllerBlock
    async deleteHandlerConfirm() {
      if (this.islocalProductionLineModified) {
        this.$notifyError({
          title: `Сбросте (или сохраните) все изменения в производственной линиии, а затем удалите ее`,
        });
        return;
      }
      const initialDepartmentsIds = getDepartmentIndsFromProductionLine(this.productionLineSource);
      let toProdLineId;
      if (this.itemProdLinesIds.length > 1) {
        toProdLineId =
          this.itemProdLinesIds[0] === this.productionLineSource.id
            ? this.itemProdLinesIds[1]
            : this.itemProdLinesIds[0];
      } else {
        toProdLineId = DEFAULT_PRODUCTION_LINE_ID;
      }
      const toProdLine = await this.getProductionLineHandler({ id: toProdLineId, itemId: null });
      const existingDepartmentsIds = getDepartmentIndsFromProductionLine(toProdLine);
      const deletedDepartmentIds = initialDepartmentsIds.filter(
        (oldDepId) => oldDepId && !existingDepartmentsIds.includes(oldDepId)
      );
      if (!deletedDepartmentIds.length) {
        return this.deleteHandler();
      }
      this.$dialog.addDialog({
        component: AsideDeleteProductionLineModal,
        props: {
          deletedDepartmentIds,
          existingDepartmentsIds,
          persistent: false,
          onConfirm: (confirmResolveDel, resolveChangedDepartments) => {
            confirmResolveDel.showModal = false;
            this.resolveChangedDepartments = resolveChangedDepartments;
            this.deleteHandler();
          },
        },
      });
    },
    // sync ItemProductionControllerBlock
    async deleteHandler() {
      this.localProductionLine.deleted = true;
      this.productionLineLoading = true;
      try {
        await this.changeItemProductionLine({
          productionLine: this.localProductionLine,
          itemId: this.item.id,
          resolveChangedDepartments: this.resolveChangedDepartments || {},
        });

        this.$notify({ title: 'Сохранено' });
        this.$emit('deleteProdLine');
      } catch (err) {
        console.log(err);
      } finally {
        this.productionLineLoading = false;
      }
    },
    async updateFromProp(newProdLineId, creatingProdLineId) {
      let productionLineForCopy;
      if (!newProdLineId) {
        this.newCreatingProdLineId = creatingProdLineId || this.filtredProductionLines[0]?.id;
        productionLineForCopy = await this.getProductionLineHandler({
          id: this.newCreatingProdLineId,
          itemId: null,
        });
      } else {
        productionLineForCopy = await this.getProductionLineHandler({
          id: newProdLineId,
          itemId: this.item.id,
        });
      }
      productionLineForCopy.itemId = this.item.id;
      if (this.creatingProdLine) {
        productionLineForCopy.departmentSettings =
          this.clearProdLine(productionLineForCopy).departmentSettings;
      }
      this.localProductionLine = cloneDeep(productionLineForCopy);
      this.productionLineSource = cloneDeep(productionLineForCopy);
    },
    setLocalEntity(prodLine, afterDelete) {
      // this.localItem = cloneDeep(item);
      // this.sourceItem = cloneDeep(item);
      // if (!this.localItem.productionLineId) {
      //   this.localItem.productionLineId = DEFAULT_PRODUCTION_LINE_ID;
      // }
      if (afterDelete) {
        const clearedProdLine = {
          ...this.clearProdLine(prodLine),
          itemId: null,
        };
        this.localProductionLine = cloneDeep(clearedProdLine);
        this.productionLineSource = cloneDeep(clearedProdLine);
      } else {
        this.localProductionLine = cloneDeep(prodLine);
        this.productionLineSource = cloneDeep(prodLine);
      }
    },
    clearProdLine(prodLine) {
      return {
        ...prodLine,
        departmentSettings: prodLine.departmentSettings.map((deps) => {
          return deps.map((dep) => {
            return {
              ...dep,
              itemId: this.item.id,
              isNewItem: true,
              id: null,
            };
          });
        }),
      };
    },
    async getProductionLineHandler({ id, itemId = null }) {
      try {
        this.productionLineLoading = true;
        const productionLine = await this.getProductionLine({ id, itemId });
        return productionLine;
      } catch (error) {
        console.log(error);
      } finally {
        this.productionLineLoading = false;
      }
    },
    async importProductionSettingFromItem() {
      if (!Number.isInteger(this.itemIdForImportSettings)) {
        this.$notifyError({ title: 'Неверно введено id изделия' });
      }
      if (!Number.isInteger(this.itemProdLineIdForImportSettings)) {
        this.$notifyError({ title: 'Неверно введено id производственной линии' });
      }
      this.productionLineLoading = true;
      try {
        const productionLine = await this.getProductionLine({
          itemId: this.itemIdForImportSettings,
          id: this.itemProdLineIdForImportSettings,
        });

        if (productionLine.id !== this.localProductionLine.id) {
          this.$notify({
            title: `У изделия для импорта отличается производственная линия.(id=${productionLine.id} - ${productionLine.name})
            Но данные были импортированы в совпадающие отделы`,
            type: 'warning',
            duration: 10000,
          });
        }

        const importingDeps = {};
        for (const deps of productionLine.departmentSettings) {
          for (const dep of deps) {
            importingDeps[dep.departmentId] = dep;
          }
        }
        for (const deps of this.localProductionLine.departmentSettings) {
          for (const dep of deps) {
            const impDep = importingDeps[dep.departmentId];
            if (!impDep) {
              continue;
            }
            dep.commissioning = impDep.commissioning;
            dep.employeeIncomePerUnit = impDep.employeeIncomePerUnit;
            dep.timePerUnit = impDep.timePerUnit;
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.productionLineLoading = false;
      }
    },
    closeAsideBlock() {
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
.production-line {
  &-item-actions {
    display: grid;
    grid-template-columns: 200px 1fr 300px;

    .btns {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      .element {
        width: 100px;
      }
    }
  }
  &-alert-deleted {
    background-color: $color-red;
    padding: 8px 0px;
    border-radius: 8px;
    text-align: center;
    margin-bottom: 10px;

    @include InterSemibold;
  }

  &-details {
    display: flex;
    flex-direction: column;
    .production-line-item-production-line {
      display: grid;
      grid-template-columns: 1fr 1fr 100px;
      grid-gap: 20px;
      margin-bottom: 10px;
      align-items: flex-end;
    }

    .production-line-details_block {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 36px;
      margin-bottom: 10px;
      align-items: flex-end;
    }
  }
  .production-line-setting {
    &-header {
      display: grid;
      grid-template-columns: 1fr 450px;

      .btn {
        display: flex;
      }
    }
    &-blocks {
      margin-bottom: 5px;
      &-wrapper {
        display: flex;
        flex-direction: column;
      }
      &-content {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }
    &-block {
      display: flex;
      &-content-wrapper {
        width: 100%;

        display: grid;
        grid-template-columns: 1fr 30px;
      }
      &-content {
        display: block;
        padding: 15px;
        margin-bottom: 20px;
        border: 1px dashed $color-light-gray;
        border-radius: 4px;
        width: 80%;
        .element {
          margin-top: 20px;
        }
      }
      &-btn {
        display: flex;
        justify-content: center;
        justify-self: right;
        align-items: center;
        padding-left: 40px;
      }
    }
  }
  .add-stage-btn-group {
    display: flex;
    flex-direction: row;
    * {
      width: 200px;
      margin: 20px 10px 20px 10px;
    }
  }
}
.validation-error-items {
  color: $color-red;
  margin-left: 15px;
}

.list-complete-item {
  transition: all 0.3s;
}
.list-complete-enter,
.list-complete-leave-to {
  opacity: 0;
}
</style>
