<template>
  <notifications class="notification" :key="uuid">
    <template #body="{ item, close }">
      <div :class="`a-notification ${item.type || 'success'}`">
        <p class="a-notification__content">
          <span class="title">
            {{ item.title }}
          </span>
          <span v-if="item.text"> {{ item.text }} </span>
        </p>
        <button class="a-notification__btn" title="закрыть" @click="close" />
      </div>
    </template>
  </notifications>
</template>

<script>
import uuid from "../../mixin/uuid";

export default {
  name: "ANotification",
  mixins: [uuid],
};
</script>

<style lang="scss" scoped>
$color-07-white: rgba(255, 255, 255, 0.7);

.notification {
  right: 3rem !important;
  top: 1rem !important;
  width: auto !important;
  transition: all 0.6s ease-in-out;
  background-color: transparent;
}

.a-notification {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;

  box-sizing: border-box;
  min-height: 46px;
  width: 100%;
  min-width: 320px;
  max-width: 400px;

  @include Inter;
  color: $color-white;
  font-size: 14px;

  background-color: $color-midnight-blue;
  border-radius: 3px;

  padding: 10px 10px 10px 10px;
  margin-bottom: 10px;

  &.success {
    background-color: $color-apple;
  }

  &.warning {
    background-color: $color-wanring;
  }

  &.error {
    background-color: $color-red;
  }

  span {
    display: block;
  }
}

.a-notification__icon {
  position: relative;
  display: flex;

  flex-shrink: 0;

  width: 16px;
  height: 16px;

  color: $color-midnight-blue;

  background-color: $color-07-white;
  border-radius: 50%;

  margin-right: 12px;

  .success & {
    color: $color-apple;
  }

  .warning & {
    color: $color-wanring;
  }

  .error & {
    color: $color-red;
  }

  &::before,
  &::after {
    display: none;

    .error & {
      display: block;

      content: "";

      position: absolute;
      left: 50%;
      top: 50%;

      width: 7px;
      height: 2px;

      background-color: $color-red;
    }
  }

  &::before {
    display: inline-block;
    width: 100%;
    text-align: center;

    font-family: "Times New Roman";
    line-height: 0;

    margin: auto;

    content: "i";

    .error & {
      content: "";
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  &::after {
    .error & {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

.a-notification__content {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-self: center;

  flex-grow: 1;

  margin: 0;
  padding: 0;

  .title {
    @include InterMedium;
  }
}

.a-notification__btn {
  justify-self: flex-end;
  flex-shrink: 0;

  -webkit-appearance: none;

  position: relative;

  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  width: 36px;
  height: 36px;

  margin: -9px -10px -7px 0;
  padding: 0;

  &::before,
  &::after {
    display: block;

    content: "";

    position: absolute;
    left: 50%;
    top: 50%;

    width: 10px;
    height: 2px;

    background-color: $color-07-white;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}
</style>
