<template>
  <GDialog
    v-model="showModal"
    :persistent="persistent"
    content-class="a-modal-wrapper aside-change-production-line-modal"
    max-width="1000"
  >
    <div class="modal__content">
      <!-- <pre>
       {{ cardEmployeeDetails[0] }}
      </pre> -->
      <div class="modal-title">
        {{ title }}
      </div>
      <div class="table">
        <!-- v-for="(departmentGroup, departmentId, index) in groupDetailsByDepartments" -->

        <div
          v-for="(rows, index) of groupDetailsByDepartments"
          :key="rows.departmentId"
          class="block"
        >
          <div class="title-row">
            {{ index + 1 }}.&nbsp;
            <div class="title">{{ departmentsDictionary[rows.departmentId].name }}</div>
          </div>
          <div class="details-body">
            <div v-for="(row, ind) of rows.items" :key="row.id" class="row">
              <div class="row-item">{{ CARD_STATE_DICTIONARY[row.state] }}</div>
              <div class="row-item">
                {{ getEmployeesName(row.userId) }}
              </div>
              <div class="row-item">{{ formatTimeStamp(row.createdAt) }}</div>
              <div class="row-item">
                <div v-if="rows[ind - 1]">
                  + {{ getDiffTime(rows[ind - 1].createdAt, row.createdAt) }}
                </div>
              </div>
              <div v-if="row.state == CARD_STATE.DONE && row.resultTime" class="row-item rusult">
                <div class="a-row">
                  {{ getStageDuration(row.resultTime) }}
                  <ATooltip
                    qtitle="'Чистое' время в работе, без учета времени на паузе"
                    position="left"
                  />
                </div>
                <div>В работе у сотрудника: &nbsp;</div>
              </div>
              <div
                v-if="
                  rows.items[rows.items.length - 1].state != CARD_STATE.DONE &&
                  rows.items[rows.items.length - 1].state === row.state
                "
                class="row-item rusult"
              >
                {{ getDurationInLastDep(row.createdAt) }}

                <div>В этом состоянии: &nbsp;</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </GDialog>
</template>
<script>
import { mapState } from 'vuex';
import { GDialog } from 'gitart-vue-dialog';
import { dayjs } from '@/utils/date.js';
import { CARD_STATE_DICTIONARY, CARD_STATE } from '../../../constants';

const DAY_IN_MS = 84400000;

export default {
  name: 'MonitoringUserCardsDetailsModal',
  components: { GDialog },
  props: {
    modelValue: {
      type: Boolean,
      default: null,
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: true,
    },
    onConfirm: {
      type: Function,
      default: null,
    },
    cardEmployeeDetails: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    departmentsDictionary: {
      type: Object,
      required: true,
    },
    employeesDictionary: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    ...mapState({
      employees: (s) => s.user.employees,
    }),
    groupDetailsByDepartments() {
      const isLastStateInDepPause = (depId) => {
        return (
          this.cardEmployeeDetails.findLast((d) => d.departmentId === depId)?.state ===
          CARD_STATE.PAUSE
        );
      };

      const data = this.cardEmployeeDetails.reduce((acc, curr) => {
        let row = acc.find((el) => el.departmentId === curr.departmentId);
        if (!row) {
          acc.push({
            departmentId: curr.departmentId,
            items: [],
          });
          row = acc[acc.length - 1];
        }
        if (
          !row.items.find((c) => c.state === curr.state) &&
          (curr.state != CARD_STATE.PAUSE || isLastStateInDepPause(curr.departmentId))
        ) {
          row.items.push(curr);
        }
        return acc;
      }, []);
      return data;
    },
    showModal: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
        if (!value && this.onClose) {
          return this.onClose(this);
        }
      },
    },
  },
  created() {
    this.CARD_STATE = CARD_STATE;
    this.CARD_STATE_DICTIONARY = CARD_STATE_DICTIONARY;
  },
  methods: {
    getEmployeesName(employeeId) {
      const employee = this.employeesDictionary[employeeId];
      if (!employee) {
        return ' - ';
      }
      if (!employee.surname) {
        return employee.name;
      }
      return `${employee.name} ${employee.surname.slice(0, 1)}.`;
    },
    formatTimeStamp(date) {
      return dayjs(date).format(`DD.MM  HH:mm`);
    },
    getDiffTime(dateOld, date = new Date()) {
      return dayjs(dateOld).from(dayjs(date), true);
    },
    getStageDuration(ms) {
      if (ms > DAY_IN_MS * 31) {
        return dayjs.duration(ms).format('MM [мес.] D [д.] H [ч.] mm [мин.]');
      }
      if (ms > DAY_IN_MS) {
        return dayjs.duration(ms).format('D [д.] H [ч.] mm [мин.]');
      }
      if (ms < 1000 * 60) {
        return dayjs.duration(ms).format('s [сек.]');
      }
      return dayjs.duration(ms).format('H [ч.] mm [мин.]');
    },
    getDurationInLastDep(date) {
      return this.getStageDuration(Date.now() - new Date(date).getTime());
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-title {
  display: flex;
  flex-direction: row;
  @include InterSemibold;
  font-size: 18px;
  margin: 20px 0px 20px 0px;
  align-items: center;
  justify-content: center;
}
.modal__content {
  padding: 20px;
}
.block {
  position: relative;
  border: 1px solid $color-light-gray;
  border-radius: 6px;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 20px 20px;

  .title-row {
    position: absolute;
    top: -10px;
    background-color: white;
    padding: 0px 10px;
    display: flex;
    flex-direction: row;
    font-size: 16px;
    @include InterSemibold;

    .title {
      text-transform: capitalize;
    }
  }

  .details-body {
    // margin-bottom: 20px;
    .row {
      // display: flex;
      // flex-direction: row;
      margin: 5px 0px;
      border-bottom: 1px dashed $color-light-gray;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .row-item {
      margin: 2px 7px;
      // background-color: blue;
    }
    .rusult {
      padding: 5px 50px 5px 0px;
      display: flex;
      flex-direction: row-reverse;
      // background-color: red;
      grid-column: span 4;
      // margin-right: 50px;
      border-top: 1px solid $color-gray;
      // margin-top: 5px;
      // margin-bottom: 5px;
      border-bottom: none;
    }
  }
}
// .title-row {
//   display: grid;
//   grid-template-columns: 1fr 1fr 1fr 1fr;
// }
</style>
