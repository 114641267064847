<template>
  <button
    class="a-btn"
    :class="{
      light,
      disabled,
      danger,
      small,
      top,
      loading,
      success,
    }"
    v-bind="$attrs"
    :disabled="disabled"
  >
    <loader v-if="loading" class="a-btn__loader" :yellow="!danger" />
    <template v-else>
      <div class="a-btn__icon">
        <slot name="icon" />
      </div>
      <span class="a-btn__content">
        <slot />
      </span>
    </template>
  </button>
</template>

<script>
import Loader from "../Loader/index.vue";

export default {
  name: "AButton",
  components: {
    Loader,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    danger: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.a-btn {
  position: relative;

  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 36px;

  @include InterSemibold;
  font-size: 14px;
  line-height: 22px;
  color: $color-white;

  background-color: $cornflower-blue; //$color-action;
  &.light {
    background-color: $color-gray-bg; //$color-action-secondary;
    color: $cornflower-blue;
  }
  &.danger {
    background-color: $color-red;
  }
  &.success {
    background-color: $color-apple;
  }
  border: none;
  outline: none;
  cursor: pointer;

  box-sizing: border-box;
  min-height: 40px;

  border-radius: 6px;
  transition: background-color 0.3s;

  &__icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  &.disabled {
    @include noSelect;
    color: $color-white;

    pointer-events: none;
    opacity: 0.7;
  }
  &.loading {
    @include noSelect;
    pointer-events: none;
    opacity: 1;
  }
  &.top {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  &:hover,
  &:focus,
  &:active {
    outline: none;
  }
  transition: box-shadow 0.2s, background-color 0.2s;

  &:hover {
    background-color: $cornflower-blue-90-opacity; //$color-action-hover;
    &.light {
      background-color: $color-bg-smoke-active; //$color-action-secondary-hover;
    }
    &.danger {
      background-color: $color-red-90-opacity; //$color-action-secondary-hover;
    }
    &.success {
      background-color: $color-apple-90-opacity;
    }
  }
  &:active {
    @include boxShadowActive;
    padding-top: 3px;
    background-color: $cornflower-blue;
    &.light {
      background-color: $color-bg-smoke-active;
    }
    &.danger {
      background-color: $color-red; //$color-action-secondary-hover;
    }
  }
  &.small {
    min-width: 20px;

    // line-height: 20px;
    height: 30px;
    min-height: 30px;

    .a-btn__content {
      padding: 8px 2px 8px 2px;
    }
  }
}
</style>
