<template>
  <div class="a-tabs" :class="{ borderless, partition }">
    <ul class="a-tabs__labels-list">
      <li
        v-for="(tab, idx) in tabs"
        :key="tab.props.name"
        class="a-tabs__label"
        :class="{
          'a-tabs__label--active': idx === selectedIndex,
        }"
      >
        <a
          :class="{
            active: idx === selectedIndex,
            disabled: tab.props.disabled,
            'dotted-red': tabPropsValues[tab.props[`tab-value`]]?.redDotted,
          }"
          @click="!tab.props.disabled && selectTab(idx)"
        >
          {{ tab.props.name }}
        </a>
        <div class="a-tabs__label--dash" />
      </li>
    </ul>
    <div class="a-tabs__details">
      <slot />
    </div>
  </div>
</template>

<script>
import {
  reactive,
  provide,
  onMounted,
  onBeforeMount,
  toRefs,
  watch,
} from "vue";

export default {
  name: "ATabs",
  setup(props, { slots, emit }) {
    const state = reactive({
      selectedIndex: 0,
      count: 0,
      tabs: [],
    });

    provide("ATabsProvider", state);

    const selectTab = (i) => {
      state.selectedIndex = i;
      emit("changeTab", {
        index: i,
        tabValue: state.tabs[i].props[`tab-value`],
      });
    };

    onBeforeMount(() => {
      if (slots.default) {
        state.tabs = slots
          .default()
          .filter((child) => child.type.name === "ATab");
      }
    });

    onMounted(() => {
      selectTab(props.selectedTab);
    });

    let tabPropsValues = reactive({ ...props.tabProps });
    watch(
      () => props.tabProps,
      (newValue) => {
        // похоже на костыль, разобраться с watch + reactive + переопределение объекта
        for (const tabVal in tabPropsValues) {
          const tabProp = tabPropsValues[tabVal];
          tabProp.redDotted = newValue[tabVal].redDotted;
        }
      },
      { deep: true, immediate: true }
    );

    return { ...toRefs(state), selectTab, tabPropsValues };
  },
  props: {
    borderless: {
      type: Boolean,
      default: false,
    },
    partition: {
      type: Boolean,
      default: false,
    },
    selectedTab: {
      type: Number,
      default: 0,
    },
    tabProps: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
$box-shadow-color: rgba(22, 118, 243, 0.4);

@mixin dott {
  content: "•";
  position: absolute;
  bottom: 15px;
  right: 5px;
  font-size: 18px;
  color: $cornflower-blue;
}
.a-tabs {
  border: 1px solid $color-bg-smoke; //$cornflower-blue; //$color-athens-gray;
  background-color: $color-white;

  width: 100%;
  height: 100%;

  overflow-x: hidden;
  overflow-y: auto;

  &.borderless {
    // border-radius: 0;
    border: none;
    overflow: unset;
  }
}

.a-tabs__labels-list {
  display: flex;
  flex-wrap: wrap;

  list-style-type: none;
  border-bottom: 1px solid $color-bg-smoke;

  margin: 0;
  padding: 0;

  overflow-x: auto;

  .a-tabs.borderless > & {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.a-tabs__label {
  position: relative;

  cursor: pointer;
  .a-tabs.partition &:not(:last-of-type) {
    border-right: 1px solid $color-bg-smoke;
  }

  a {
    position: relative;
    display: inline-block;
    box-sizing: border-box;

    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px;
    padding-bottom: 12px;

    @include InterBold;
    font-size: 14px;

    &.active {
      color: $cornflower-blue;
    }
    &.disabled {
      color: $color-gray;
      cursor: default;
    }
    &.dotted {
      ::after {
        @include dott;
      }
    }
    &.dotted-red::after {
      @include dott;
      color: $color-red;
    }
  }

  &--dash {
    display: none;
    position: absolute;
    bottom: 0;
    left: calc(50% - 16px);

    width: 32px;
    height: 2px;
    background-color: $color-blue;
    animation: selectTab 0.4s;
  }
  &--active .a-tabs__label--dash {
    display: block;
  }
}

.a-tabs__details {
  padding-top: 10px;
  // padding-bottom: 25px;
  padding-left: 15px;
  padding-right: 15px;
  height: max-content;
  width: 100%;

  .a-tabs.borderless > & {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@keyframes selectTab {
  5% {
    box-shadow: 0px 0px 6px 4px $box-shadow-color;
  }
  25% {
    box-shadow: 0px 0px 5px 3px $box-shadow-color;
  }
}
</style>
