<template>
  <APageLayout>
    <template #header>
      <div class="page-header">
        <div class="page-header__container">
          <div>Пользователи</div>
          <div class="mr-20">
            <AToggle
              v-model="showDeletedEntity"
              label="Показать удаленные сущности"
              class="toggle"
            />
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <div v-if="!loading" class="content-wrapper users-page-content-wrapper">
        <APageBlock class="mb-20" toggle>
          <template #header> Сотрудники производства </template>
          <div class="content">
            <div class="table-filter">
              <AInput v-model.trim="tableFilters.employees" placeholder="Фильтр" />
            </div>
            <ATable
              :items="employeesPagination.chunkedItems"
              :fields="employeeFields"
              class="employee-table mb-20"
              :loading="loading"
              :row-classes="employeeTableRowClasses"
              :row-click="(row) => (selectedEmployee = row)"
            >
              <template #departmentIds="{ item }">
                <ABadgeList :items="geEmployeeDepartments(item.departmentIds)" />
              </template>
            </ATable>
            <APagination
              v-model:currentPage="employeesPagination.currentPage"
              v-model:currentOffset="employeesPagination.offset"
              :items="filtredEmployees"
              :all="filtredEmployees.length"
              :loading="loading"
              :offsets="[10, 20, 50, 0]"
              mode="local"
              @changeOffset="employeesPagination.offset = $event"
              @update:chunkedItems="employeesPagination.chunkedItems = $event"
            />
            <div>
              <AButton class="mb-10 mt-10" @click="createEmployee = true"
                >Добавить сотрудника
              </AButton>
            </div>
          </div>
        </APageBlock>
        <CreateEmployeeAsideBlock
          :creating-user="createEmployee"
          :user-id="selectedEmployee?.guid"
          @close="closeAsidePageHandler"
        />
      </div>
      <div v-else>
        <ALoader centered />
      </div>
    </template>
  </APageLayout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { reactive } from 'vue';
import { employeeFields } from './usersDictionary';
import { prepareDictionary } from '../../utils/dictionaryUtils.js';
import { filterArrayByFields } from '../../utils/filters.js';
import CreateEmployeeAsideBlock from './components/CreateEmployeeAsideBlock.vue';

export default {
  name: 'Users',
  components: {
    CreateEmployeeAsideBlock,
  },
  setup() {
    return {
      employeesPagination: reactive({
        current: 1,
        offset: 10,
        chunkedItems: reactive([]),
      }),
    };
  },
  data() {
    return {
      loading: true,

      selectedEmployee: null,
      createEmployee: false,

      tableFilters: {
        employees: null,
        fieldsForFilter: ['guid', 'name', 'surname', 'login'],
      },

      showDeletedEntity: true,
    };
  },
  computed: {
    ...mapState({
      items: (s) => s.assortment.items,
      employees: (s) => s.user.employees,
      departments: (s) => s.production.departments,
    }),
    filtredEmployees() {
      const filtredEmployees = !this.showDeletedEntity
        ? this.employees.filter((i) => !i.deleted)
        : this.employees;
      return filterArrayByFields(
        filtredEmployees,
        this.tableFilters.employees,
        this.tableFilters.fieldsForFilter
      );
    },
    geEmployeeDepartments() {
      return (departmentIds) =>
        departmentIds.reduce((acc, curr) => {
          const dep = this.prepareDepartmentDictionary[curr]?.name || `departmentId=${curr}`;
          acc.push(dep);
          return acc;
        }, []);
    },
    prepareDepartmentDictionary() {
      return prepareDictionary(this.departments);
    },
  },
  async created() {
    this.employeeFields = employeeFields;
    await this.bootstrap();
  },
  methods: {
    ...mapActions({
      getSets: 'assortment/getSets',
      getAllEmployees: 'user/getAllEmployees',
      getAllDepartments: 'production/getAllDepartments',
    }),
    async bootstrap() {
      this.loading = true;
      const promises = [this.getAllEmployees(), this.getAllDepartments()];
      try {
        await Promise.all(promises);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    closeAsidePageHandler() {
      this.createEmployee = false;
      this.selectedEmployee = null;
    },
    employeeTableRowClasses(row) {
      if (row.guid === this.selectedEmployee?.guid) return 'active-table-row';
      if (row.deleted) return 'row-deleted';
    },
  },
};
</script>
<style lang="scss" scoped>
.page-header {
  @include InterBold;
  font-size: 18px;
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 36px;

  &__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
.content-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}
.table-filter {
  max-width: 350px;
  margin: 5px 0px 0px 0px;
}
</style>
<style lang="scss">
.users-page-content-wrapper {
  .active-table-row {
    background-color: $color-active-item;
  }
  .row-deleted {
    background-color: $color-light-gray;
  }
}
</style>
