<template>
  <GDialog
    v-model="showModal"
    :persistent="persistent"
    content-class="a-modal-wrapper"
    max-width="1000"
  >
    <div class="modal__content">
      <div v-if="title" class="modal__title">
        {{ title }}
      </div>
      <div class="modal__block">
        <div v-if="isItem" class="modal__block-items">
          <div v-for="item of getItemData(product)" :key="item.id">
            <div class="fabric">{{ item.fabric }}</div>
            <div v-if="currency === 'EUR'">{{ item.EUR || '—' }} €</div>
            <div v-if="currency === 'RUR'">{{ item.RUR || '—' }} ₽</div>
            <div>
              <AButton
                small
                :disabled="!item[currency]"
                @click="confirmItemHandler(product, item.itemFabric)"
                >Добавить
              </AButton>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="modal__block-set-items">
            <div v-for="itemSet of product.itemSets" :key="itemSet.index" class="item-wrapper">
              <div
                class="item__header"
                :style="`grid-column: 1 / ${itemDesigns(itemSet.itemId).length + 1}`"
              >
                Изделие: {{ itemSet.itemId }}
              </div>
              <div
                v-for="(design, ind) of itemDesigns(itemSet.itemId)"
                :key="ind"
                class="design-wrapper"
                :class="{ selected: selectedSetDesigns[itemSet.index] === design?.guid }"
              >
                <div class="design_mockup">
                  <div v-if="design" class="image">
                    <img :src="getLink(design.mockup.front || design.mockup.back)" alt="mockup" />
                  </div>
                  <div v-else class="empty-image-wrapper">
                    <Fa
                      name="icon"
                      icon="image"
                      class="icon faicon empty-product"
                      style="width: 60px; height: 60px"
                    />
                  </div>
                  <AButton
                    v-if="design"
                    small
                    :danger="selectedSetDesigns[itemSet.index] == design.guid"
                    @click="selectDesignHandler(itemSet.index, design.guid)"
                  >
                    {{ selectedSetDesigns[itemSet.index] == design.guid ? 'Отменить' : 'Выбрать' }}
                  </AButton>
                </div>
              </div>
            </div>
          </div>

          <div class="set-confirm-button">
            <AButton @click="confirmSetHandler"> добавить в заказ</AButton>
          </div>
        </div>
      </div>
    </div>
  </GDialog>
</template>
<script>
import { GDialog } from 'gitart-vue-dialog';
import { getLink } from '@/utils/getStorageLink';
import { cloneDeep } from '@/utils/lodash.js';

export default {
  name: 'AsideAddProductModal',
  components: { GDialog },
  props: {
    modelValue: {
      type: Boolean,
      default: null,
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: true,
    },
    isItem: {
      type: Boolean,
      default: true,
    },
    onConfirm: {
      type: Function,
      default: null,
    },
    onClose: {
      type: Function,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    product: {
      type: Object,
      required: true,
    },
    fabricsDictionary: {
      type: Object,
      default: null,
    },
    currency: {
      type: String,
      required: true,
    },
    clientItemDesigns: {
      type: Array,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      selectedSetDesigns: null,
    };
  },
  computed: {
    showModal: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
        if (!value && this.onClose) {
          return this.onClose(this);
        }
      },
    },
  },
  created() {
    this.getLink = getLink;
    if (!this.isItem) {
      this.selectedSetDesigns = this.product.itemSets.reduce((acc, itemSet) => {
        acc[itemSet.index] = null;
        return acc;
      }, {});
    }
  },
  methods: {
    confirmItemHandler(product, itemFabric) {
      return this.onConfirm(this, cloneDeep(product), cloneDeep(itemFabric));
    },
    confirmSetHandler() {
      if (!Object.values(this.selectedSetDesigns).filter((_) => _).length) {
        this.$notifyError({
          title: `Необходимо выбрать хотя бы один дизайн`,
        });
        return;
      }
      const designs = {};
      for (const index in this.selectedSetDesigns) {
        const guid = this.selectedSetDesigns[index];
        designs[index] = cloneDeep(this.clientItemDesigns.find((el) => el.guid == guid));
      }
      return this.onConfirm(this, cloneDeep(this.product), designs);
    },
    getItemData(item) {
      return item.itemFabricPrices
        .filter((el) => !el.deleted)
        .map((el) => ({
          fabric: this.fabricsDictionary[el.fabricId]?.name,
          RUR: el.prices.RUR,
          EUR: el.prices.EUR,
          fabricId: el.fabricId,
          itemFabricId: el.id,
          itemFabric: el,
        }));
    },
    selectDesignHandler(index, guid) {
      this.selectedSetDesigns[index] == guid
        ? (this.selectedSetDesigns[index] = null)
        : (this.selectedSetDesigns[index] = guid);
    },
    itemDesigns(itemId) {
      const designs = this.clientItemDesigns.filter((design) => design.itemId == itemId);
      return designs.length ? designs : [null];
    },
  },
};
</script>
<style lang="scss" scoped>
.modal {
  &__content {
    display: flex;
    flex-direction: column;
    margin: 15px 30px;
  }
  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    @include InterSemibold;
    font-size: 16px;
    padding: 5px 15px 20px;
  }
  &__text {
    margin-top: 5px;
  }
}

.a-modal-wrapper {
}

// изделия
.modal__block-items {
  width: 100%;
  height: 190px;
  display: flex;
  overflow-x: scroll;
  align-items: center;
  flex-grow: 1;

  @include scroll-wider;

  grid-gap: 15px;
  div {
    text-align: center;
    margin-top: 5px;
    justify-self: center;
  }
  .fabric {
    @include InterSemibold;
  }
}
// комплекты
.modal__block-set-items {
  display: flex;
  flex-direction: row;

  .item-wrapper {
    display: grid;
    grid-auto-flow: column;
    border-right: 2px solid $color-info;

    grid-template-rows: 30px 1fr;
    .item__header {
      text-align: center;
    }

    .design-wrapper {
      display: flex;
      margin: 0px 5px;
      padding: 5px;

      &.selected {
        background-color: $color-bg-light-blue-active;
        border-radius: 4px;
        transition: background-color 0.15s;
      }

      .design_mockup {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}

.set-confirm-button {
  display: flex;
  margin-top: 10px;
  justify-content: center;
}
.image {
  img {
    max-height: 100px;
    max-width: 100px;
    border-radius: 4px;
  }
}
.empty-image-wrapper {
  margin: 0px 8px;
  height: 80px;

  display: flex;
  justify-content: center;
  justify-self: center;
  align-content: center;
  align-self: center;

  .empty-product {
    color: $color-bg-smoke-active;
  }
}
</style>
