<template>
  <Design />
</template>

<script>
import Design from '@/components/design';
export default {
  name: 'DesignPage',
  components: { Design },
};
</script>
