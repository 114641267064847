<template>
  <!-- <div style="width: 320px; height: 70px"></div> -->
  <div class="card-cover">
    <div class="card" :class="{ [card.state]: card.state, showAdditionalInfo, isForeignCard }">
      <div class="status"></div>
      <div class="body">
        <div class="first-row">
          <div class="important-flag mr-5">
            <OrderUrgencyTypeSelector
              :disabled="loading || !accessToChangeSubDepartment"
              :card-value="card.urgencyType"
              :order-value="order.urgencyType"
              mode="card"
              @change="$emit('changeCardUrgencyType', { card, urgencyType: $event })"
            />
          </div>
          <div class="title mr-5" :title="order.name">{{ order.name }}</div>
          <div class="actions">
            <IconCover :active="!loading" @click="showAdditionalInfoHandler">
              <i
                class="fa fa-chevron-down icon"
                style="font-size: 14px"
                :class="{ showAdditionalInfo }"
              ></i>
            </IconCover>
          </div>
        </div>
        <div class="second-row">
          <div class="guid-quantity a-row">
            <div class="pointer mr-10" @click="copyText(order.guid)">
              ID {{ order.guid.slice(-4).toUpperCase() }}
            </div>
            <div class="quantity a-row">
              <i class="fa fa-box icon mr-5" style="font-size: 15px"></i>
              <div class="a-row">
                {{ card.index }}/{{ order.cardsQuantity }} | {{ card.playersLength }} шт.
              </div>
            </div>
          </div>

          <div class="date">
            <ABadge
              fontbase
              small
              :gray="!deadlineExpired && card.state === CARD_STATE.QUEUE"
              :red="deadlineExpired"
            >
              {{ getOrderDedline }}
            </ABadge>
          </div>
        </div>

        <div class="additionalInfo a-row" :class="{ showAdditionalInfo }">
          <AButton
            small
            class="mr-10"
            title="Производственная карта"
            :disabled="loading"
            @click="$emit('showCardDetails', { card, action: MONITORING_CARD_ACTIONS.FULL_CARD })"
          >
            <i class="fa fa-th-list icon faicon" style="font-size: 15px"></i>
          </AButton>
          <AButton
            small
            class="mr-10"
            title="Сотрудники работавшие над партией"
            :disabled="loading"
            @click="
              $emit('showCardDetails', { card, action: MONITORING_CARD_ACTIONS.USET_CARD_DETAILS })
            "
          >
            <i class="fa fa-people-arrows icon faicon" style="font-size: 15px"></i>
          </AButton>
          <ABadge fontbase class="ml-5">
            {{ getEmployeesName }}
          </ABadge>
        </div>
      </div>
      <div class="foreign-status"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import IconCover from '@/components/base/IconCover.vue';
import OrderUrgencyTypeSelector from '@/components/base/OrderUrgencyTypeSelector';
import { copyToClipboard } from '@/utils/clipboard.js';
import { dayjs } from '@/utils/date.js';
import { getUserName } from '@/utils/string.js';
import { CARD_STATE, MONITORING_CARD_ACTIONS, ORDER_PARAM_TYPE } from '../../../constants';
import { useOrderParamValue } from '@/use/order/useOrderParamValue';
export default {
  name: 'MonitoringCard',
  components: {
    IconCover,
    OrderUrgencyTypeSelector,
  },
  props: {
    card: {
      type: Object,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
    employeesDictionary: {
      type: Object,
      required: true,
    },
    accessToChangeSubDepartment: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    additionalInfoShowCardId: {
      type: String,
      default: null,
    },
    isForeignCard: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['showCardAdditionalInfo', 'showCardDetails', 'changeCardUrgencyType'],
  setup() {
    const { getValueFromOrderParamByType } = useOrderParamValue();

    return {
      getValueFromOrderParamByType,
    };
  },
  data() {
    return {
      showAdditionalInfo: false,
    };
  },
  computed: {
    ...mapState({
      departments: (s) => s.production.departments,
    }),
    cssVar() {
      return {
        '--department-count': this.departments.length,
      };
    },
    getOrderDedline() {
      const deadline = this.getPackageDate || this.order.postponedDeadline || this.order.deadline;
      if (!deadline) {
        return '   -   ';
      }
      const date = dayjs(deadline).format('D[\xA0]MMM');
      const day = dayjs(deadline).format('dd').toUpperCase();
      return `${date},\xA0${day}`;
    },
    getEmployeesName() {
      const employee = this.employeesDictionary[this.card.currentEmployeeId];
      return getUserName(employee);
    },
    deadlineExpired() {
      return (
        (this.getPackageDate && dayjs(this.getPackageDate).isSameOrBefore(new Date(), 'day')) ||
        !!this.order.postponedDeadline ||
        dayjs(this.order.deadline).isSameOrBefore(new Date(), 'day')
      );
    },
    getPackageDate() {
      return this.getValueFromOrderParamByType({
        orderParamsType: ORDER_PARAM_TYPE.PACKING_DATE,
        orderParams: this.order.orderParams,
      });
    },
  },
  watch: {
    additionalInfoShowCardId(cardId) {
      if (cardId && cardId != this.card.guid) {
        this.showAdditionalInfo = false;
      }
    },
  },
  created() {
    this.CARD_STATE = CARD_STATE;
    this.MONITORING_CARD_ACTIONS = MONITORING_CARD_ACTIONS;
  },
  methods: {
    copyText(text) {
      copyToClipboard(text);
      this.$notify({ title: 'Скопировано' });
    },
    showAdditionalInfoHandler() {
      if (this.loading) {
        return;
      }
      this.showAdditionalInfo = !this.showAdditionalInfo;
      if (this.showAdditionalInfo) {
        console.log('emit ', this.card.guid);
        this.$emit('showCardAdditionalInfo', this.card.guid);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$card-width: 320px;
$additionalInfo-transition: 0.2s;
.card {
  &.showAdditionalInfo {
    height: fit-content;

    .body .additionalInfo {
      margin-top: 12px;
      transition: height $additionalInfo-transition, margin $additionalInfo-transition;
      height: 30px;
    }
  }

  &.isForeignCard {
    .foreign-status {
      background-color: $color-red;
    }
  }
  &-cover {
    // display: flex;
    // justify-content: center;
    background-color: $color-white;
    width: $card-width;
    // height: $card-height;
    border-radius: 4px;
  }

  display: grid;
  grid-template-columns: 8px 1fr 8px;
  grid-template-rows: 1fr;
  height: 100%;
  width: 100%;

  border-radius: 4px;

  .status {
    transition: background-color $additionalInfo-transition;
    height: 100%;
    border-radius: 4px 0px 0px 4px;
  }
  .foreign-status {
    height: 100%;
    border-radius: 0px 4px 4px 0px;
  }
  .body {
    padding: 12px 16px 12px 16px;
    // display: grid;
    // grid-template-rows: 1fr 1fr;
    display: flex;
    flex-direction: column;
    // gap: 12px;
    // grid-template-rows: 1fr 1fr 1fr;
    // grid-gap: 12px;

    .first-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .important-flag {
        color: $color-light-gray;
        .important {
          color: $color-important;
        }
      }
      .title {
        font-size: 18px;
        @include InterBold;
        // width: 195px;
        width: 210px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .actions {
        display: flex;
        flex-direction: row;
      }
    }

    .second-row {
      margin-top: 12px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @include InterBold;
      color: $color-gray;
      font-size: 12px;
    }

    .additionalInfo {
      transition: height $additionalInfo-transition, margin $additionalInfo-transition;
      height: 0px;
      overflow: hidden;
    }
  }

  &.queue {
    .status {
      background-color: $queue-card-state;
    }
  }
  &.process {
    .status {
      background-color: $process-card-state;
    }
  }
  &.pause {
    .status {
      background-color: $pause-card-state;
    }
  }
}
.icon.showAdditionalInfo {
  transform: rotate(-180deg);
}
.icon {
  transition: transform 0.15s;
}
</style>
