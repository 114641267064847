import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faProjectDiagram,
  faRulerCombined,
  faChevronDown,
  faCheckCircle,
  faPlusCircle,
  faSignOutAlt,
  faUserCircle,
  faDollarSign,
  faInfoCircle,
  faAngleDown,
  faChevronUp,
  // faArrowDown,
  faCartPlus,
  faChartBar,
  faSlidersH,
  faBookOpen,
  faAngleUp,
  // faArrowUp,
  faBoxOpen,
  faPalette,
  faCheck,
  faCogs,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

library.add(faProjectDiagram);
library.add(faRulerCombined);
library.add(faChevronDown);
library.add(faCheckCircle);
library.add(faPlusCircle);
library.add(faSignOutAlt);
library.add(faUserCircle);
library.add(faDollarSign);
library.add(faInfoCircle);
library.add(faChevronUp);

library.add(faAngleUp);
library.add(faAngleDown);

// library.add(faArrowDown);
library.add(faCartPlus);
library.add(faChartBar);
library.add(faSlidersH);
library.add(faBookOpen);
// library.add(faArrowUp);
library.add(faBoxOpen);
library.add(faPalette);
library.add(faCheck);
library.add(faCogs);
library.add(faPlus);

export default library;
