<template>
  <div>
    <div class="production-line-controller mb-10">
      <div class="production-line-controller__title mb-10">
        Изделие:&nbsp;
        <span class="a-bold"> {{ item.id }}. </span> &emsp;{{ item.name }}
      </div>
      <div v-if="!itemProdLinesIds.length" class="production-line-title-block__warn">
        У изделия не заполнены производственные параметры
      </div>
      <div v-if="isCanChangeProduction" class="actions mb-10">
        <AButton
          v-if="accessToChange"
          small
          class="mb-10 mt-10 ml-5"
          :disabled="orderLineModified || loading"
          @click="changeOrderItemProductionLineHandlerConfirm"
        >
          Сохранить порядок пр. лин.
        </AButton>
        <AButton
          v-if="accessToChange"
          small
          class="mb-10 mt-10 ml-5"
          :disabled="!canAddProdLineForItem"
          @click="creatingProdLineHandler"
        >
          Добавить пр. линию
        </AButton>
      </div>
      <Draggable
        v-if="itemProdLinesIds?.length"
        v-model="itemProdLinesIds"
        item-key="id"
        class="draggable__prodLine"
        ghost-class="ghost"
        :scroll-sensitivity="20"
        :disabled="loading"
        @start="drag = true"
        @end="drag = false"
      >
        <template #item="{ element, index }">
          <div
            :class="{
              base: index === 0,
              activeProdLineId: element === activeProdLineId && !creatingProdLine,
            }"
            class="draggable__item"
            @click="selectProdLineHandler(element)"
          >
            <div>[{{ element }}] {{ productionLinesDictionary[element].name }}</div>
            <div v-if="index === 0" class="ml-5">
              <Fa name="icon" icon="crown" class="icon" style="width: 15px; height: 15px" />
            </div>
          </div>
        </template>
      </Draggable>
    </div>
    <div v-if="accessToChange && creatingProdLine" class="creating-header">
      Создание производственной линии для изделия
    </div>
    <hr class="mt-15 mb-15" />
    <div>
      <ItemProductionLineBlock
        v-if="showProdLineBlock"
        :item="item"
        :loading="loading"
        :active-prod-line-id="activeProdLineId"
        :creating-prod-line="creatingProdLine"
        :item-prod-lines-ids="itemProdLinesIds"
        @close="closeHandler"
        @createProdLine="createProdLineHandler(true)"
        @deleteProdLine="createProdLineHandler"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { getDepartmentIndsFromProductionLine } from '@/utils/productionLine.js';
import ItemProductionLineBlock from '@/components/production/components/ItemProductionLineBlock.vue';
import AsideDeleteProductionLineModal from '@/components/production/components/AsideDeleteProductionLineModal.vue';
import accessMixin from '@/mixin/accessMixin.js';
import { prepareDictionary } from '@/utils/dictionaryUtils';
import { isEqual } from '@/utils/lodash.js';

export default {
  name: 'ItemProductionControllerBlock',
  components: {
    ItemProductionLineBlock,
  },
  mixins: [accessMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
    productionSetting: {
      type: Object,
      default: null,
    },
  },
  emits: ['close'],
  data() {
    return {
      loading: true,
      showModal: false,

      activeProdLineId: null,
      creatingProdLine: false,

      drag: false,
      itemProdLinesIdsValues: null,
      resolveChangedDepartments: {},
    };
  },
  computed: {
    ...mapState({
      productionLines: (s) => s.production.productionLines,
      allProdLinesItem: (s) => s.assortment.allProdLinesItem,
    }),
    accessToChange() {
      return !this.item.deleted && this.isCanChangeProduction && !this.loading;
    },
    orderLineModified() {
      return isEqual(this.itemProdLinesIds, this.initialAllProdLineItemsIds);
    },
    initialAllProdLineItemsIds() {
      return this.allProdLinesItem.map((p) => p.id);
    },
    showProdLineBlock() {
      if (this.loading) {
        return false;
      }
      if (this.creatingProdLine) {
        return this.canAddProdLineForItem;
      }
      if (this.activeProdLineId) {
        return true;
      }
      return false;
    },
    itemProdLinesIds: {
      get() {
        if (!this.itemProdLinesIdsValues) {
          return this.initialAllProdLineItemsIds || [];
        }
        return this.itemProdLinesIdsValues;
      },
      set(val) {
        this.itemProdLinesIdsValues = val;
      },
    },
    productionLinesDictionary() {
      return prepareDictionary(this.productionLines);
    },
    canAddProdLineForItem() {
      return this.productionLines.length > this.allProdLinesItem.length;
    },
  },
  async created() {
    await this.bootstrap();
  },
  methods: {
    ...mapActions({
      getAllProductionLinesForItem: 'assortment/getAllProductionLinesForItem',
      changeOrderItemProductionLine: 'production/changeOrderItemProductionLine',
    }),
    async bootstrap(createProdLine) {
      this.loading = true;
      try {
        const promises = [this.getAllProductionLinesForItem({ itemId: this.item.id })];
        await Promise.all(promises);

        this.activeProdLineId =
          this.allProdLinesItem[createProdLine ? this.allProdLinesItem.length - 1 : 0]?.id || null;
        this.creatingProdLine = this.allProdLinesItem[0]?.id ? false : true;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async changeOrderItemProductionLineHandler() {
      this.loading = true;
      try {
        await this.changeOrderItemProductionLine({
          productionLineIds: this.itemProdLinesIds,
          itemId: this.item.id,
          resolveChangedDepartments: this.resolveChangedDepartments,
        });
        await this.bootstrap();
        this.$notify({ title: 'Сохранено' });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async createProdLineHandler(createProdLine) {
      await this.bootstrap(createProdLine);
    },
    creatingProdLineHandler() {
      this.loading = true;

      this.activeProdLineId = null;
      this.creatingProdLine = true;

      this.loading = false;
    },
    selectProdLineHandler(element) {
      this.loading = true;

      this.creatingProdLine = false;
      this.activeProdLineId = element;

      this.loading = false;
    },
    //sync ItemProductionLineBlock
    async changeOrderItemProductionLineHandlerConfirm() {
      if (this.allProdLinesItem[0].id === this.itemProdLinesIds[0]) {
        this.changeOrderItemProductionLineHandler();
        return;
      }
      const inittialProdLine = this.allProdLinesItem[0];
      const initialDepartmentsIds = getDepartmentIndsFromProductionLine(inittialProdLine);
      const toProdLine = this.allProdLinesItem.find((p) => p.id === this.itemProdLinesIds[0]);
      const existingDepartmentsIds = getDepartmentIndsFromProductionLine(toProdLine);
      const deletedDepartmentIds = initialDepartmentsIds.filter(
        (oldDepId) => oldDepId && !existingDepartmentsIds.includes(oldDepId)
      );
      if (!deletedDepartmentIds.length) {
        return this.changeOrderItemProductionLineHandler();
      }
      this.$dialog.addDialog({
        component: AsideDeleteProductionLineModal,
        props: {
          deletedDepartmentIds,
          existingDepartmentsIds,
          title: 'Распределение карт с отсутствующих отделов в новой главной пр. линии',
          persistent: false,
          onConfirm: (confirmResolveDel, resolveChangedDepartments) => {
            confirmResolveDel.showModal = false;
            this.resolveChangedDepartments = resolveChangedDepartments;
            this.changeOrderItemProductionLineHandler();
          },
        },
      });
    },

    closeHandler() {
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
.production-line-controller {
  .actions {
    display: flex;
    flex-direction: row-reverse;
    margin-right: 5px;
  }

  &-title-block {
    display: flex;
    flex-direction: column;

    &__title {
      @include InterSemibold;
      font-size: 16px;
    }

    &__warn {
      font-size: 14px;
      @include InterSemibold;
      background-color: rgba(255, 196, 0, 0.371);
      padding: 5px 10px 5px 10px;

      display: flex;
      align-self: center;
      border-radius: 4px;
    }
  }
}
.creating-header {
  margin: 5px 0px;
  font-size: 16px;
  color: $color-dirty-apple;
  @include InterBold;

  display: flex;
  align-items: center;
  align-self: center;
  align-content: center;
  justify-content: center;
  justify-self: center;
  background-color: $color-success;
  padding: 3px 0px;
  border-radius: 4px;
}

.draggable {
  &__prodLine {
    display: flex;
    flex-direction: row;
    gap: 10px;

    background-color: $color-bg-acctive-blue;

    border-radius: 10px;
    max-width: 100%;
    overflow-x: auto;

    padding: 5px;
    @include scroll-wide;

    justify-content: space-between;
  }
  &__item {
    border-radius: 10px;

    flex-grow: 1;
    padding: 5px 10px;
    background-color: $color-bg-smoke-active;
    word-wrap: break-all;
    overflow-wrap: break-word;

    border: 2px solid transparent;

    cursor: pointer;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: fit-content;

    &.activeProdLineId {
      border-color: $color-red-90-opacity;
    }
    &.base:not(.ghost) {
      background-color: $cornflower-blue-60-opacity;
    }
  }
}
</style>
