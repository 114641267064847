import orderApi from '@/api/orderApi';
import router from '../../../router/index.js';
import { notify } from '@kyvg/vue3-notification';

export default {
  async getOrder({ commit }, { orderId }) {
    commit('SET_ORDER_LOADING', { key: 'selectedOrderLoading', data: true });
    try {
      const { data } = await orderApi.getOrder({ orderId });
      commit('SET_SELECTED_ORDER', data);
    } catch (error) {
      console.log(error);
    } finally {
      commit('SET_ORDER_LOADING', { key: 'selectedOrderLoading', data: false });
    }
  },

  async getAllOrders({ commit }, { filters, selectedFields, settings, limit, offset }) {
    const { data } = await orderApi.getAllOrders({
      filters,
      selectedFields,
      settings,
      limit,
      offset,
    });
    commit('SET_ALL_ORDERS', { offset, data });
    return { length: data.length };
  },

  async getOrderTags({ state, commit }, { force = false } = {}) {
    if (state.orderTags.length && !force) {
      return;
    }
    const { data } = await orderApi.getOrderTags();
    commit('SET_ALL_ORDER_TAGS', data);
  },
  async saveOrderTag({ commit }, { orderTag } = {}) {
    const { data } = await orderApi.saveOrderTag({ orderTag });
    commit('SAVE_ORDER_TAG', { orderTag: data, oldOlderTag: orderTag });
  },

  async getOrderCategory({ state, commit }, { force = false } = {}) {
    if (state.orderCategory.length && !force) {
      return;
    }
    const { data } = await orderApi.getOrderCategory();
    commit('SET_ALL_ORDER_CATEGORY', data);
  },
  async saveOrderCategory({ commit }, { orderCategory } = {}) {
    const { data } = await orderApi.saveOrderCategory({ orderCategory });
    commit('SAVE_ORDER_CATEGORY', { orderCategory: data, oldOlderCategory: orderCategory });
  },

  async getClientUserAndTeamsInfo({ commit }, { phone, email, selectedOrder }) {
    const { data } = await orderApi.getClientUserAndTeamsInfo({ phone, email });
    commit('setOrderInfo', { selectedOrder, data });
  },

  async xlImport({ commit }, { tableSha, products, selectedOrder }) {
    const { data } = await orderApi.xlImport({ tableSha, products });
    commit('setXlImportData', { selectedOrder, data });
  },

  async xlDownloadTemplate() {
    const data = await orderApi.xlDownloadTemplate();
    return data;
  },

  async saveOrder({ commit }, { order }) {
    commit('SET_ORDER_LOADING', { key: 'selectedOrderLoading', data: true });
    try {
      const { data, frontNotify } = await orderApi.saveOrder({ order });
      if (frontNotify) {
        for (const message of frontNotify) {
          notify({ text: message.title, type: 'warning', duration: -1 });
        }
      }
      commit('SET_SELECTED_ORDER', data);
      router.push('/orders/' + data.guid);
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      commit('SET_ORDER_LOADING', { key: 'selectedOrderLoading', data: false });
    }
  },

  async resetOrDeleteOrder(_, { orderId, needDelete }) {
    await orderApi.resetOrDeleteOrder({ orderId, needDelete });
  },
  async patchOrder(_, { order }) {
    await orderApi.patchOrder({ order });
  },

  async patchOrderParams(_, { orderId, orderParams }) {
    await orderApi.patchOrderParams({ orderId, orderParams });
  },

  async patchOrderSendToClient(_, { orderId }) {
    await orderApi.patchOrderSendToClient({ orderId });
  },

  async patchOrderSetPaid(_, { orderId }) {
    await orderApi.patchOrderSetPaid({ orderId });
  },
};
