const config = {
  production: {
    yandexBucket: 'luckygrass',
    yandexFolder: 'storage',
  },
  development: {
    yandexBucket: 'test-t-r1',
    yandexFolder: 'lg_images',
  },
};

export default config[process.env.NODE_ENV === 'production' ? 'production' : 'development'];
