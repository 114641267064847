const departmentFields = [
  { key: 'id', label: 'Id', sortable: true, class: 'id' },
  { key: 'name', label: 'Название', sortable: true },
  { key: 'tableIndex', label: '№п/п в табл. мониторинга', sortable: true },
  { key: 'shiftDuration', label: 'Доступно в день', sortable: true },
  // { key: 'isActive', label: 'Статус' },
];

const productionLineFields = [
  { key: 'id', label: 'Id', sortable: true, class: 'id' },
  { key: 'name', label: 'Название', sortable: true, class: 'name' },
  { key: 'actions', label: '', class: 'actions' },
];

export { departmentFields, productionLineFields };
