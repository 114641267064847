import {
  AsideGroupSeparation,
  SimpleTableSelect,
  AsideCell,
  StackedBarChart,
  BlockSeparator,
  LinePageLoader,
  RadioButton,
  ButtonGroup,
  FileUpload,
  PageLayout,
  DatePicker,
  Pagination,
  LineLoader,
  RingLoader,
  PageBlock,
  PageAside,
  ItemGroup,
  InputFile,
  BadgeList,
  Dropdown,
  Checkbox,
  Confirm,
  Tooltip,
  Notify,
  Select,
  Button,
  Toggle,
  Spacer,
  Loader,
  Input,
  Label,
  Table,
  Badge,
  Tabs,
  Tab,
} from '../../../../lg_ui/src/components/index.js';

import VueDatePicker from '@vuepic/vue-datepicker';

/* es */
export default (app) => {
  app.component('VueDatePicker', VueDatePicker);

  app.component('AAsideGroupSeparation', AsideGroupSeparation);
  app.component('ASimpleTableSelect', SimpleTableSelect);
  app.component('AAsideCell', AsideCell);
  app.component('AStackedBarChart', StackedBarChart);
  app.component('ABlockSeparator', BlockSeparator);
  app.component('ALinePageLoader', LinePageLoader);
  app.component('ARadioButton', RadioButton);
  app.component('AButtonGroup', ButtonGroup);
  app.component('AFileUpload', FileUpload);
  app.component('APageLayout', PageLayout);
  app.component('ADatePicker', DatePicker);
  app.component('APagination', Pagination);
  app.component('ALineLoader', LineLoader);
  app.component('ARingLoader', RingLoader);
  app.component('APageBlock', PageBlock);
  app.component('APageAside', PageAside);
  app.component('AItemGroup', ItemGroup);
  app.component('AInputFile', InputFile);
  app.component('ABadgeList', BadgeList);
  app.component('ADropdown', Dropdown);
  app.component('ACheckbox', Checkbox);
  app.component('AConfirm', Confirm);
  app.component('ATooltip', Tooltip);
  app.component('ANotify', Notify);
  app.component('ASelect', Select);
  app.component('AButton', Button);
  app.component('AToggle', Toggle);
  app.component('ASpacer', Spacer);
  app.component('ALoader', Loader);
  app.component('AInput', Input);
  app.component('ALabel', Label);
  app.component('ATable', Table);
  app.component('ABadge', Badge);
  app.component('ATabs', Tabs);
  app.component('ATab', Tab);
};
