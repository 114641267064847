import { h } from "vue";
import "./a-button-group.scss";

export default function aButtonGroup({ borderless }, { slots }) {
  let btnEls = [];
  const $slots = slots.default();
  if ($slots) {
    btnEls = btnEls.concat(
      $slots.reduce((acc, item) => {
        acc.push(h("li", { class: "a-button-group__tab" }, [item]));

        return acc;
      }, [])
    );
  }
  const listEl = h(
    "ul",
    {
      class: {
        "a-button-group": true,
        borderless: borderless,
      },
    },
    btnEls
  );

  return listEl;
}
