<template>
  <Order />
</template>

<script>
import Order from '@/components/order';
export default {
  name: 'OrderPage',
  components: { Order },
};
</script>
