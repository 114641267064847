<template>
  <div class="wrapper">
    <div class="showMore"></div>
    <div class="pagination-wrapper">
      <ul class="a-pagination" :class="{ disabled: loading }">
        <li v-if="!isFirstPages && pageArr.length">
          <APaginationLink
            title="Предыдущая страница"
            aria-label="Previous"
            laquo
            @click="navigationHandler('previous')"
          />
        </li>
        <li v-if="!isFirstPages && !isShort">
          <APaginationLink
            :active="1 == current"
            @click="navigationHandler('current', 1)"
          >
            1
          </APaginationLink>
        </li>
        <APaginationInputButton
          v-if="!isShort && !isFirstPages"
          :is-last-pages="isLastPages"
          :is-first-pages="isFirstPages"
          :last="last"
          @pagination-input="navigationHandler('current', $event)"
        />
        <li
          v-for="item of pageArr"
          :key="item"
          :class="{ active: item == current || last == 1 }"
        >
          <APaginationLink
            :active="item == current"
            @click="navigationHandler('current', item)"
          >
            {{ item }}
          </APaginationLink>
        </li>
        <APaginationInputButton
          v-if="!isShort && !isLastPages"
          :is-last-pages="isLastPages"
          :is-first-pages="isFirstPages"
          :last="last"
          @pagination-input="navigationHandler('current', $event)"
        />
        <li v-if="last && !isLastPages" :class="{ active: last == current }">
          <APaginationLink
            :active="last == current"
            @click="navigationHandler('current', last)"
          >
            {{ last }}
          </APaginationLink>
        </li>
        <li
          v-if="!isLastPages && pageArr.length > 3"
          :class="{ disabled: current == pageArr.length }"
        >
          <APaginationLink
            aria-label="Next"
            title="Следующая страница"
            raquo
            @click="navigationHandler('next')"
          />
        </li>
      </ul>
      <div class="offset-setting">
        <ASelect
          :options="offsetArray"
          :modelValue="offsetArray.find((e) => e.id == offset)"
          select-label="label"
          greyish
          @change="changeOffsetHandler"
          :model-only-key="false"
          block-unselect
        />
      </div>
    </div>
  </div>
</template>

<script>
import APaginationLink from "./components/APaginationLink.vue";
import APaginationInputButton from "./components/APaginationInputButton.vue";
import ASelect from "../Select/index.vue";

export default {
  name: "Pagination",
  components: {
    APaginationInputButton,
    APaginationLink,
    ASelect,
  },
  props: {
    all: {
      type: Number,
      default: 1,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    offsets: {
      type: Array,
      default: null,
    },
    currentOffset: {
      type: Number,
      default: 10,
    },
    items: {
      type: Array,
      default: null,
    },
    mode: {
      type: String,
      default: null,
    },
  },
  data() {
    return {};
  },
  emits: [
    "current",
    "next",
    "previous",
    "update:chunkedItems",
    "changeOffset",
    "update:currentPage",
    "update:currentOffset",
  ],
  computed: {
    current: {
      get() {
        return this.currentPage;
      },
      set(val) {
        this.$emit("update:currentPage", val);
      },
    },
    offset: {
      get() {
        return this.currentOffset;
      },
      set(val) {
        this.$emit("update:currentOffset", val);
      },
    },
    isLocalMode() {
      return this.mode === "local";
    },
    pageArr() {
      if (this.offset === 0) return [1];
      const currentPage = this.current || 1;
      const allPages = Math.ceil(
        (this.all || this.items?.length) / this.offset
      );
      const pagesArray = [];
      const amplitude = 2;

      const maxPagesQuantity = amplitude * 2 + 1; // максимальное количество страниц

      const isInOptimalRange = currentPage > 5 && allPages >= maxPagesQuantity; // текущая посередине по центру
      const isCloseToTheEnd = allPages - currentPage < maxPagesQuantity / 2; // текущая ближе к концу
      let firstNumber = 1;

      if (isInOptimalRange) firstNumber = currentPage - amplitude;

      if (isCloseToTheEnd && allPages > maxPagesQuantity) {
        firstNumber = allPages - maxPagesQuantity + 1;
      }

      let idx = 0;
      let currentNumber = firstNumber;
      let allPagesQuantity =
        allPages < maxPagesQuantity ? allPages : firstNumber + maxPagesQuantity;

      for (let i = firstNumber; i <= allPagesQuantity; i++) {
        pagesArray[idx] = currentNumber++;
        idx = ++idx;
      }
      return pagesArray;
    },
    offsetArray() {
      if (!this.offsets) return null;
      const isAllPageVariant = this.offsets.includes(0);
      const result = this.offsets
        .filter((e) => e)
        .map((el) => {
          return {
            id: el,
            label: `Показывать по ${el}`,
          };
        });
      if (isAllPageVariant) result.push({ id: 0, label: "Показать все" });
      return result;
    },
    last() {
      if (this.offset === 0) return 1;
      return Math.ceil((this.all || this.items?.length) / this.offset);
    },
    isLastPages() {
      return (
        this.pageArr.includes(this.last) || this.pageArr.includes(this.last - 1)
      );
    },
    isFirstPages() {
      return this.pageArr.includes(1) && this.current < 6;
    },
    isShort() {
      return !this.pageArr.length || this.last < 6;
    },
    pageInfo() {
      if (!this.isLocalMode) return;
      const all = this.items.length;
      const last = Math.ceil(all / parseInt(this.offset));

      return {
        last,
        page: this.current || 1,
      };
    },
    slicedItems() {
      if (!this.isLocalMode) return;
      if (!this.offset) return this.items;
      const result = {};

      for (let i = 1; i <= this.pageInfo.last; i++) {
        const idxFrom = (i - 1) * this.offset;
        const idxTo = idxFrom + this.offset;

        result[i] = this.items.slice(idxFrom, idxTo);
      }

      return result[this.pageInfo.page];
    },
  },
  watch: {
    slicedItems() {
      this.updateChunkedItems();
    },
  },
  created() {
    this.updateChunkedItems();
  },
  methods: {
    navigationHandler(action, current) {
      if (action === "current") {
        this.$emit("current", current);
        this.current = current;
      } else if (action === "next") {
        this.current++;
        this.$emit("next");
      } else if (action === "previous") {
        this.current--;
        this.$emit("previous");
      }
    },
    changeOffsetHandler(offsetObj) {
      this.offset = offsetObj.id;
    },
    updateChunkedItems() {
      this.$emit("update:chunkedItems", this.slicedItems || []);
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin hidden {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}
@mixin revealed {
  border: inherit;
  clip: unset;
  clip-path: unset;
  height: inherit;
  margin: inherit;
  overflow: unset;
  padding: inherit;
  position: inherit;
  width: inherit;
  white-space: unset;
}
.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  .pagination-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
}
.a-pagination {
  display: flex;
  list-style-type: none;

  margin: 0;
  padding: 0;

  &.disabled {
    pointer-events: none;
  }
}
</style>
