<template>
  <div
    class="page-aside__wrapper"
    :class="{
      loading,
      error: asideErrors.length,
      active,
      wide,
      wider,
      widers,
      'aside-modal': asideModal,
    }"
    :style="propToCssVar"
  >
    <div class="page-aside__cover" @click="$emit('close')" />
    <div class="page-aside">
      <div v-if="title" class="page-aside__title-wrapper">
        <button
          class="page-aside__close"
          title="закрыть"
          @click="$emit('close')"
        />
        <h2 class="a-h1">
          {{ title }}
        </h2>
      </div>
      <div class="page-aside__container">
        <slot />
        <!-- <div v-if="asideErrors.length" class="page-aside__errors">
          <div class="page-aside__errors-icon" />
          <a class="page-aside__errors-label"> Есть ошибки </a>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "APageAside",
  props: {
    asideErrors: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    wide: {
      type: Boolean,
      default: false,
    },
    wider: {
      type: Boolean,
      default: false,
    },
    widers: {
      type: Boolean,
      default: false,
    },
    asideModal: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close"],
  data() {
    return {
      active: false,
    };
  },
  computed: {
    propToCssVar() {
      if (!this.asideErrors) return {};
      let style = {};
      if (this.wide) {
        style["--widthContent"] = "700px";
      } else if (this.wider) {
        style["--widthContent"] = "1000px";
      } else if (this.widers) {
        style["--widthContent"] = "1200px";
      } else {
        style["--widthContent"] = "500px";
      }
      return style;
    },
    // smallDisplay() {
    //   return this.isMobile || window.innerWidth < 460;
    // },
  },
  // inject: ["isMobile"],
  watch: {
    active() {
      if (this.active) {
        document.body.style.height = "100vh";
        document.body.style.overflowY = "hidden";
        document.body.addEventListener("keyup", this.pressKeyHandler);
      } else {
        document.body.style.height = "100%";
        document.body.style.overflowY = "scroll";
        document.body.removeEventListener("keyup", this.pressKeyHandler);
      }
    },
  },
  created() {
    this.$ESC_KEYCODE = 27;
  },
  updated() {
    this.checkSlots();
  },
  mounted() {
    this.checkSlots();
  },
  methods: {
    checkSlots() {
      const slots = this.$slots?.default();
      const isDefaultSlot = Array.isArray(slots[0].children);
      this.active = isDefaultSlot;

      return this.active;
    },
    pressKeyHandler(event) {
      if (event.keyCode === this.$ESC_KEYCODE) {
        this.$emit("close");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-aside__title-wrapper {
  position: relative;

  width: calc(100% + 60px);
  margin: -25px -30px 0 -30px;
  // padding-top: 25px;
  padding-right: 40px;
  padding-left: 30px;
  margin-bottom: 30px;

  box-sizing: border-box;

  background-color: $color-white-smoke;
  box-shadow: 0px 1px 0 0 $color-white-smoke;

  .page-aside__wrapper.aside-modal & {
    // padding-top: 15px;
    padding-right: 35px;
    margin-bottom: 20px;
    opacity: 0;
  }
  transition: opacity 0.2s;
  .page-aside__wrapper.aside-modal.active & {
    opacity: 1;
  }
}

.a-h1 {
  width: 100%;
}

.page-aside {
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 0;
  // right: 0;
  // bottom: 0;

  // height: 100vh;
  width: 500px;
  max-width: 100%;
  .page-aside__wrapper:not(.aside-modal) & {
    top: 0;
    right: 0;
    bottom: 0;

    height: 100vh;
    // width: 500px;
    // max-width: 100%;
  }
  @include InterSemibold;
  font-size: 14px;

  background-color: $color-white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  padding: 25px 30px 20px 30px;
  z-index: 28;

  overflow-y: auto;
  @include scroll-wide;

  transform: translate(100%, 0);
  transition: transform 0.15s ease-in-out;

  .page-aside__wrapper.wide & {
    width: 700px;
    transition: transform 0.3s ease-in-out;
  }

  .page-aside__wrapper.wider & {
    width: 1000px;
    transition: transform 0.4s ease-in-out;
  }

  .page-aside__wrapper.widers & {
    width: 1200px;
    transition: transform 0.4s ease-in-out;
  }
  .page-aside__wrapper.active & {
    transform: translate(0, 0);
  }
  .page-aside__wrapper.aside-modal & {
    display: none;
    border-radius: 8px;
    max-height: calc(100% - 25%);
    padding-bottom: 40px;
  }

  .page-aside__wrapper.aside-modal.active & {
    display: flex;
    left: calc(calc(100% - var(--widthContent)) / 2);
    top: 13%;
  }

  .loading & {
    pointer-events: none;

    &::before,
    &::after {
      position: absolute;
      content: "";
    }

    &::before {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      width: 100%;
      height: 1000vh;

      background-color: rgba(206, 208, 218, 0.5);
      z-index: 25;
    }
  }
}

.page-aside__container {
  margin-left: -5px;
  margin-right: -5px;

  // min-height: 100%;
  // min-width: 100%;
  .page-aside__wrapper.aside-modal & {
    overflow-y: scroll;
    @include scroll-wide;
  }
}

.page-aside__wrapper {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;

  width: 100vw;
  height: 100vh;

  background-color: transparent;

  z-index: -1;
  transition: all 0.15s linear;
  will-change: opacity, background-color;

  &.wide {
    transition: all 0.3s linear;
  }
  &.wider {
    transition: all 0.4s linear;
  }
  &.widers {
    transition: all 0.4s linear;
  }

  &.active {
    z-index: 200;
    transition: all 0.4s linear;
    background-color: rgba(0, 0, 0, 0.35);
  }

  &.loading.active::after {
    display: block;

    position: relative;
    content: "";

    left: 50%;
    top: 50%;

    width: 150px;
    height: 150px;
    padding: 50px;

    background-image: url(./assets/loading.svg);
    background-size: 80%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    border-radius: 50%;

    z-index: 30;

    transform: translate(-50%, -50%);
    animation: circle-pulsing-waves 1.5s linear infinite;
  }
}

.page-aside__cover {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;

  width: 100vw;
  height: 100vh;

  transform: translate(100%, 0);

  transition: none;
  backdrop-filter: blur(1px);

  .active & {
    transform: translate(0, 0);
  }

  .loading & {
    pointer-events: none;
  }

  .widers,
  .wider,
  .wide & {
    backdrop-filter: none;
  }
}

.page-aside__close {
  position: absolute;
  top: 15px;
  right: 30px;

  border: none;
  outline: none;
  background: none;

  box-sizing: border-box;
  width: 40px;
  height: 40px;
  padding: 10px;
  cursor: pointer;

  margin-right: -10px;

  transition: 0.3s transform ease-in-out;
  will-change: transform;

  z-index: 1;
  .page-aside__wrapper.aside-modal & {
    height: 30px;
  }
  &:hover {
    transform: rotate(90deg);
  }

  &::before,
  &::after {
    content: "";

    position: absolute;
    left: 50%;
    top: 50%;

    width: 14px;
    height: 2px;

    background-color: $color-red;
    border-radius: 2px;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.page-aside__errors {
  cursor: pointer;
  @include items-row__el;
  @include input-error(".page-aside__wrapper");
  flex-direction: row;
  border-top: 1px solid $color-red;
  width: 100%;

  &-label {
    @include link;
    font-size: 12px;
  }
}
</style>
