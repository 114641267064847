import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// import { FontAwesomeIcon } from '@fortawesome/free-solid-svg-icons';
import library from '@@/icons/ui-liblary.js';

import {
  faEye,
  faBox,
  faCog,
  faTags,
  faRedo,
  faLink,
  faStar,
  faCopy,
  faSave,
  faPlus,
  faGift,
  faTable,
  faCrown,
  faImage,
  faTimes,
  faCheck,
  faUsers,
  faPenNib,
  faFilter,
  faThList,
  faMobile,
  faFilePdf,
  faChartBar,
  faSlidersH,
  faEyeSlash,
  faTrashAlt,
  faFileImage,
  faEllipsisH,
  faFileExcel,
  faEllipsisV,
  faArrowsAltV,
  faCheckDouble,
  faCalendarDay,
  faChevronDown,
  faPeopleArrows,
  faFileDownload,
  faLongArrowAltRight,
  faExternalLinkSquareAlt,
} from '@fortawesome/free-solid-svg-icons';
library.add(faEye);
library.add(faBox);
library.add(faCog);
library.add(faTags);
library.add(faRedo);
library.add(faLink);
library.add(faStar);
library.add(faCopy);
library.add(faSave);
library.add(faPlus);
library.add(faGift);
library.add(faTable);
library.add(faCrown);
library.add(faImage);
library.add(faTimes);
library.add(faCheck);
library.add(faUsers);
library.add(faPenNib);
library.add(faFilter);
library.add(faThList);
library.add(faMobile);
library.add(faFilePdf);
library.add(faChartBar);
library.add(faSlidersH);
library.add(faEyeSlash);
library.add(faTrashAlt);
library.add(faFileImage);
library.add(faEllipsisH);
library.add(faFileExcel);
library.add(faEllipsisV);
library.add(faArrowsAltV);
library.add(faCalendarDay);
library.add(faCheckDouble);
library.add(faChevronDown);
library.add(faPeopleArrows);
library.add(faFileDownload);
library.add(faLongArrowAltRight);
library.add(faExternalLinkSquareAlt);

export default (vue) => {
  vue.component('Fa', FontAwesomeIcon);
};
