<template>
  <div class="block-wrapper">
    <div v-if="localProductionLine" class="production-line">
      <div v-if="productionLineId" class="production-line-title-block mb-5">
        <div class="production-line-title-block__title">
          Производственная линия:&nbsp;
          <span class="a-bold"> {{ localProductionLine.id }}. </span> &emsp;{{
            localProductionLine.name
          }}
        </div>
      </div>
      <div v-else class="production-line-title-block">
        <div class="production-line-title-block__title">Новая производственная линия</div>
      </div>

      <div class="production-line-details">
        <div class="production-line-details_block">
          <AInput
            v-model="localProductionLine.name"
            :read-only="!accessToChange"
            :disabled="productionLineLoading.deleted || productionLineLoading"
            label="Название"
            :error="getErrMess('name')"
          />
        </div>
        <ASpacer text="Состав производственной линии" class="mb-15 mt-15" bold />
        <div v-if="localProductionLine.departmentSettings.length" class="production-line-setting">
          <div
            v-for="(departmentSetting, ind) of localProductionLine.departmentSettings"
            :key="`${ind}_${departmentSetting.id}`"
            class="production-line-setting-blocks"
          >
            <div class="production-line-setting-header mt-40 mb-10">
              <ASpacer :text="`Этап #${ind + 1}`" class="mb-15 mt-15" bold />
              <div class="btn">
                <AButton
                  v-if="accessToChange"
                  :disabled="!canAddDepartmentOrStage"
                  @click="addDepSet(ind)"
                >
                  Добавить отдел в этап
                </AButton>
                <AButton
                  v-if="accessToChange"
                  :disabled="!canDelDepartmentOrStage"
                  danger
                  class="ml-20"
                  @click="delStage(ind)"
                >
                  Удалить этап
                </AButton>
              </div>
            </div>
            <div class="production-line-setting-blocks-wrapper">
              <div class="production-line-setting-blocks-content">
                <AToggle
                  v-model="localProductionLine.departmentSettings[ind][0].required"
                  :label="`${
                    departmentSetting.length === 1
                      ? 'Обязательный этап'
                      : 'Один из отделов обязательный'
                  }`"
                  :disabled="!accessToChange"
                  class="ml-20 mb-10"
                />
                <transition-group tag="div" name="list-complete">
                  <div
                    v-for="(depSet, ink) of departmentSetting"
                    :key="`${ind}_${ink}_${depSet.id}`"
                    class="production-line-setting-block list-complete-item"
                  >
                    <div class="production-line-setting-block-content-wrapper">
                      <div class="production-line-setting-block-content">
                        <ASelect
                          v-model="depSet.departmentId"
                          :options="getDepartmentOptions(ind, ink)"
                          disable-deleted
                          select-label="name"
                          label="Отдел"
                          :disabled="productionLineLoading"
                          :read-only="!accessToChange"
                          :error="getErrMess(`departmentSettings[${ind}][${ink}].departmentId`)"
                          class="element"
                        />
                        <AInput
                          v-model="depSet.employeeIncomePerUnit"
                          type="number"
                          label="Доход работника за 1шт. изделия, Руб"
                          :disabled="depSet.deleted || productionLineLoading"
                          :read-only="!accessToChange"
                          :error="
                            getErrMess(`departmentSettings[${ind}][${ink}].employeeIncomePerUnit`)
                          "
                          :min="0"
                          class="element"
                        />
                        <AInput
                          v-model="depSet.commissioning"
                          type="number"
                          label="Пусконаладка, сек"
                          :disabled="depSet.deleted || productionLineLoading"
                          :read-only="!accessToChange"
                          :error="getErrMess(`departmentSettings[${ind}][${ink}].commissioning`)"
                          :min="0"
                          integer
                          class="element"
                        />
                        <AInput
                          v-model="depSet.timePerUnit"
                          type="number"
                          label="Время затраченое на одно изд. сек"
                          :disabled="depSet.deleted || productionLineLoading"
                          :read-only="!accessToChange"
                          :error="getErrMess(`departmentSettings[${ind}][${ink}].timePerUnit`)"
                          :min="0"
                          integer
                          class="element"
                        />
                      </div>
                      <div
                        v-if="accessToChange && departmentSetting.length > 1"
                        class="production-line-setting-block-btn"
                      >
                        <AButton
                          danger
                          :disabled="!canDelDepartmentOrStage"
                          class="mr-15"
                          @click="delDepSet(ind, ink)"
                        >
                          Удалить&nbsp;отд.
                        </AButton>
                      </div>
                    </div>
                  </div>
                </transition-group>
              </div>
            </div>
          </div>
          <div v-if="accessToChange" class="add-stage-btn-group">
            <AButton
              :disabled="productionLineLoading || !canAddDepartmentOrStage"
              class="mt-20 mt-20"
              @click="addStage"
            >
              Добавить этап
            </AButton>
            <AInput
              v-if="accessToChange"
              v-model="newStageIndex"
              type="number"
              label="№п/п нового этапа"
              :disabled="productionLineLoading"
              :min="1"
              :max="localProductionLine?.departmentSetting?.length + 1"
              integer
              class="element"
            />
          </div>
        </div>
        <div class="production-line-details_block mt-15">
          <AButton
            v-if="accessToChange"
            success
            :disabled="!islocalProductionLineModified"
            @click="saveProductionLine"
          >
            Сохранить
          </AButton>
          <AButton
            v-if="accessToChange"
            :disabled="!islocalProductionLineModified"
            @click="updateFromProp()"
          >
            Сбросить
          </AButton>
          <AButton
            v-if="
              accessToChange &&
              !creatingProductionLine &&
              !copyProductionLineId &&
              !productionLineLoading &&
              productionLineId != DEFAULT_PRODUCTION_LINE_ID &&
              !localProductionLine.deleted
            "
            :disabled="islocalProductionLineModified"
            :danger="!localProductionLine.deleted"
            :success="!!localProductionLine.deleted"
            @click="deleteHandlerConfirm"
          >
            Удалить
          </AButton>
        </div>
        <ALoader v-if="productionLineLoading" centered green />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { cloneDeep, isEqual } from '../../../utils/lodash.js';
import accessMixin from '../../../mixin/accessMixin.js';
import { productionLineBlockSchema } from '../../../utils/validate/index.js';
import { getDepartmentIndsFromProductionLine } from '@/utils/productionLine.js';
import validateMixin from '../../../mixin/validate.js';
import ConfirmDialog from '@@/components/Confirm/index.vue';
import { DEFAULT_PRODUCTION_LINE_ID } from '../../../constants/index.js';
import AsideDeleteProductionLineModal from './AsideDeleteProductionLineModal.vue';
import AsideChangeProductionLineModalPreview from './AsideChangeProductionLineModalPreview.vue';
import DeleteProductionLineConflictModal from './DeleteProductionLineConflictModal.vue';

const newDepartmentSettingItem = {
  id: null,
  productionLineId: null,
  departmentId: null,
  itemId: null,
  index: null,
  commissioning: null,
  employeeIncomePerUnit: null,
  timePerUnit: null,
  required: true,
  isNewItem: true,
};
const newProductionLineItem = {
  id: null,
  name: null,
  departmentSettings: [[cloneDeep(newDepartmentSettingItem)]],
  deleted: false,
  isNewItem: true,
};

export default {
  name: 'ProductionLineBlock',
  mixins: [accessMixin, validateMixin],
  props: {
    productionLineId: {
      type: Number,
      default: null,
    },
    creatingProductionLine: {
      type: Boolean,
      default: false,
    },
    copyProductionLineId: {
      type: Number,
      default: null,
    },
  },
  emits: ['close'],
  data() {
    return {
      productionLineLoading: false,

      localProductionLine: null,
      productionLineSource: null, // не мутировать

      deletedDepOrStage: false,
      addedDepOrStage: false,

      resolveChangedDepartments: null,
      fillAddedItemDepartments: false,
      newStageIndex: null,
    };
  },
  computed: {
    ...mapState({
      departments: (s) => s.production.departments,
      productionLines: (s) => s.production.productionLines,
    }),
    accessToChange() {
      return (
        this.isCanChangeProduction &&
        !this.localProductionLine.deleted &&
        !this.productionLineLoading
      );
    },
    islocalProductionLineModified() {
      return !isEqual(this.productionLineSource, this.localProductionLine);
    },
    getDepartmentOptions() {
      const existingDepartmentsIds = getDepartmentIndsFromProductionLine(this.localProductionLine);
      return (ind, ink) => {
        return this.departments.filter(
          (e) =>
            !existingDepartmentsIds.includes(e.id) ||
            e.id == this.localProductionLine.departmentSettings[ind][ink].departmentId
        );
      };
    },
    canAddDepartmentOrStage() {
      if (this.creatingProductionLine || !this.productionLineId) {
        return true;
      }
      if (this.deletedDepOrStage) {
        return false;
      }
      return true;
    },
    canDelDepartmentOrStage() {
      if (this.creatingProductionLine || !this.productionLineId) {
        return true;
      }
      if (this.addedDepOrStage) {
        return false;
      }
      return true;
    },
  },
  watch: {
    productionLineId: function () {
      this.updateFromProp();
    },
  },
  created() {
    this.DEFAULT_PRODUCTION_LINE_ID = DEFAULT_PRODUCTION_LINE_ID;
    this.updateFromProp();
  },
  methods: {
    ...mapActions({
      changeProductionLine: 'production/changeProductionLine',
      getProductionLine: 'production/getProductionLineToComponent',
      getProductionLineCardsAndItems: 'production/getProductionLineCardsAndItemsToComponent',
    }),
    addDepSet(ind) {
      this.addedDepOrStage = true;
      this.localProductionLine.departmentSettings[ind].push(cloneDeep(newDepartmentSettingItem));
    },
    delDepSet(ind, ink) {
      this.deletedDepOrStage = true;
      this.localProductionLine.departmentSettings[ind].splice(ink, 1);
    },
    delStage(ind) {
      this.deletedDepOrStage = true;
      this.localProductionLine.departmentSettings.splice(ind, 1);
      this.newStageIndex--;
    },
    addStage() {
      this.addedDepOrStage = true;
      const index = this.newStageIndex - 1;
      if (
        !Number.isInteger(index) ||
        index < 0 ||
        index > this.localProductionLine.departmentSettings.length
      ) {
        this.$notifyError({ title: 'Неверный номер нового этапа' });
        return;
      }
      if (index === this.localProductionLine.departmentSettings.length) {
        this.localProductionLine.departmentSettings.push([cloneDeep(newDepartmentSettingItem)]);
        this.newStageIndex++;
        return;
      }
      this.localProductionLine.departmentSettings.splice(index, 0, [
        cloneDeep(newDepartmentSettingItem),
      ]);
      this.newStageIndex++;
    },
    isDepartmentsModif() {
      const initial = this.productionLineSource.departmentSettings;
      const current = this.localProductionLine.departmentSettings;
      if (initial.length !== current.length) {
        return true;
      }
      for (let i = 0; i < initial.length; i++) {
        const initialDeps = initial[i];
        const currDeps = current[i];
        if (initialDeps.length !== currDeps.length) {
          return true;
        }
        for (let k = 0; k < initialDeps.length; k++) {
          const iDep = initialDeps[k];
          const cDep = currDeps[k];
          if (iDep.departmentId !== cDep.departmentId) {
            return true;
          }
        }
      }
    },
    async saveProductionLine() {
      const formValidation = this.$form(this.localProductionLine, productionLineBlockSchema);
      if (!formValidation.isValid) {
        this.$notifyError({ title: `Ошибка валидации формы` });
        return;
      }
      const departmentChanged = this.isDepartmentsModif();
      if (departmentChanged) {
        this.$dialog.addDialog({
          component: AsideChangeProductionLineModalPreview,
          props: {
            productionLine: cloneDeep(this.localProductionLine),
            persistent: false,
            onConfirm: (confirmResolveDel) => {
              confirmResolveDel.showModal = false;
              this.saveProductionLineStep3();
            },
          },
        });
        return;
      }
      this.saveProductionLineStep3();
    },
    async saveProductionLineStep3(forDelete) {
      const addedDepIds = this.getAddedDepInds();
      if (addedDepIds.length && this.productionLineSource.id) {
        this.$dialog.addDialog({
          component: ConfirmDialog,
          props: {
            title: 'Добавились новые этапы в производственную линию',
            text: 'Заполнить этими параметрами - парамтры во всех других изделий, которые используют эту производственную линию?',
            buttons: [
              {
                text: 'Не заполнять',
                handler: async (confirm) => {
                  confirm.showModal = false;
                  this.fillAddedItemDepartments = false;
                  await this.saveProductionLineStep4(forDelete);
                },
              },
              {
                text: 'Заполнить',
                handler: async (confirm) => {
                  confirm.showModal = false;
                  this.fillAddedItemDepartments = true;
                  await this.saveProductionLineStep4(forDelete);
                },
              },
            ],
          },
        });
      } else {
        await this.saveProductionLineStep4(forDelete);
      }
    },
    async saveProductionLineStep4(forDelete = false) {
      try {
        this.productionLineLoading = true;
        const updatedProdLine = await this.changeProductionLine({
          productionLine: this.localProductionLine,
          resolveChangedDepartments: this.resolveChangedDepartments,
          fillAddedItemDepartments: this.fillAddedItemDepartments,
        });
        if (forDelete) {
          this.closeAsideBlock();
        } else {
          await this.updateFromProp(updatedProdLine);
        }
        this.$notify({ title: 'Сохранено' });
      } catch (err) {
        console.log(err);
      } finally {
        this.productionLineLoading = false;
      }
    },
    async deleteHandlerConfirm() {
      if (this.islocalProductionLineModified) {
        this.$notifyError({
          title: `Сбросте (или сохраните) все изменения в производственной линиии, а затем удалите ее`,
        });
        return;
      }
      const { cards, items } = await this.getProductionLineCardsAndItems({
        productionLineId: this.productionLineId,
      });
      if (!cards.length && !items.length) {
        await this.deleteHandler();
      } else {
        this.$dialog.addDialog({
          component: DeleteProductionLineConflictModal,
          props: {
            cards,
            items,
            onConfirm: async (confirm) => {
              confirm.showModal = false;
            },
          },
        });
      }
    },
    async deleteHandler() {
      const deletedProductsIds = await this.getDeletedDepIndsForDeletingProductionLine();
      const defaultProdLine = await this.getProductionLineHandler({
        id: DEFAULT_PRODUCTION_LINE_ID,
      });
      const existingDepartmentsIds = getDepartmentIndsFromProductionLine(defaultProdLine);
      if (deletedProductsIds.length) {
        this.$dialog.addDialog({
          component: AsideDeleteProductionLineModal,
          props: {
            deletedDepartmentIds: deletedProductsIds,
            existingDepartmentsIds,
            persistent: false,
            onConfirm: (confirmResolveDel, resolveChangedDepartments) => {
              confirmResolveDel.showModal = false;
              this.resolveChangedDepartments = resolveChangedDepartments;
              this.localProductionLine.deleted = true;
              this.saveProductionLineStep3(true);
            },
          },
        });
      } else {
        this.localProductionLine.deleted = true;
        this.saveProductionLineStep3(true);
      }
    },
    getDeletedDepInds() {
      const initialDepartmentsIds = getDepartmentIndsFromProductionLine(this.productionLineSource);
      const existingDepartmentsIds = getDepartmentIndsFromProductionLine(this.localProductionLine);
      return initialDepartmentsIds.filter(
        (oldDepId) => oldDepId && !existingDepartmentsIds.includes(oldDepId)
      );
    },
    getAddedDepInds() {
      const initialDepartmentsIds = getDepartmentIndsFromProductionLine(this.productionLineSource);
      const existingDepartmentsIds = getDepartmentIndsFromProductionLine(this.localProductionLine);
      return existingDepartmentsIds.filter(
        (oldDepId) => oldDepId && !initialDepartmentsIds.includes(oldDepId)
      );
    },
    async getDeletedDepIndsForDeletingProductionLine() {
      const initialDepartmentsIds = getDepartmentIndsFromProductionLine(this.productionLineSource);
      const defaultProductionLine = await this.getProductionLineHandler({
        id: DEFAULT_PRODUCTION_LINE_ID,
      });
      const existingDepartmentsIds = getDepartmentIndsFromProductionLine(defaultProductionLine);
      return initialDepartmentsIds.filter((oldDepId) => !existingDepartmentsIds.includes(oldDepId));
    },
    async updateFromProp(prodLine) {
      if (this.creatingProductionLine && !prodLine) {
        this.localProductionLine = cloneDeep(newProductionLineItem);
        this.productionLineSource = cloneDeep(newProductionLineItem);
      } else if (this.copyProductionLineId && !prodLine) {
        const productionLineForCopy = await this.getProductionLineHandler({
          id: this.copyProductionLineId,
        });
        const productionLine = {
          id: null,
          name: `Копия ${productionLineForCopy.name} - ${productionLineForCopy.id}`,
          departmentSettings: productionLineForCopy.departmentSettings.map((deps) => {
            return deps.map((dep) => {
              return {
                ...dep,
                isNewItem: true,
                id: null,
              };
            });
          }),
        };
        this.localProductionLine = cloneDeep(productionLine);
        this.productionLineSource = cloneDeep(productionLine);
      } else {
        const productionLine =
          prodLine || (await this.getProductionLineHandler({ id: this.productionLineId }));
        this.localProductionLine = cloneDeep(productionLine);
        this.productionLineSource = cloneDeep(productionLine);
      }
      this.newStageIndex = this.localProductionLine.departmentSettings.length + 1;
    },
    async getProductionLineHandler({ id }) {
      try {
        this.productionLineLoading = true;
        const productionLine = await this.getProductionLine({ id });
        return productionLine;
      } catch (error) {
        console.log(error);
      } finally {
        this.productionLineLoading = false;
      }
    },
    closeAsideBlock() {
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
.production-line {
  &-alert-deleted {
    background-color: $color-red;
    padding: 8px 0px;
    border-radius: 8px;
    text-align: center;
    margin-bottom: 10px;

    @include InterSemibold;
  }

  &-title-block {
    display: flex;
    flex-direction: column;

    &__title {
      @include InterSemibold;
      font-size: 16px;
    }
  }
  &-details {
    display: flex;
    flex-direction: column;
    .production-line-item-production-line {
      display: grid;
      grid-template-columns: 1fr 1fr 100px;
      grid-gap: 20px;
      margin-bottom: 10px;
      align-items: flex-end;
    }

    .production-line-details_block {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 36px;
      margin-bottom: 10px;
      align-items: flex-end;
    }
  }
  .production-line-setting {
    &-header {
      display: grid;
      grid-template-columns: 1fr 450px;

      .btn {
        display: flex;
      }
    }
    &-blocks {
      margin-bottom: 5px;

      // border: 1px solid $color-gray;
      // border-radius: 6px;
      // padding-left: 7px;
      // margin-top: 20px;

      &-wrapper {
        display: flex;
        flex-direction: column;
      }
      &-content {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }
    &-block {
      display: flex;
      &-content-wrapper {
        width: 100%;

        display: grid;
        grid-template-columns: 1fr 30px;
        // padding: 20px 0px 20px 0px;
      }
      &-content {
        display: block;
        padding: 15px;
        margin-bottom: 20px;
        border: 1px dashed $color-light-gray;
        border-radius: 4px;
        width: 80%;
        .element {
          margin-top: 20px;
        }
      }
      &-btn {
        display: flex;
        justify-content: center;
        justify-self: right;
        align-items: center;
        padding-left: 40px;
      }
    }
  }
  .add-stage-btn-group {
    display: flex;
    flex-direction: row;
    * {
      width: 200px;
      margin: 20px 10px 20px 10px;
    }
  }
}
.validation-error-items {
  color: $color-red;
  margin-left: 15px;
}

.list-complete-item {
  transition: all 0.3s;
}
.list-complete-enter,
.list-complete-leave-to {
  opacity: 0;
}
</style>
