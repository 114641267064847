<template>
  <div>
    <div v-if="itemSexItem" class="item-item-sex__block">
      <div>
        <ASelect
          v-model="itemSexItem.sexId"
          :options="
            itemSexItem.sexId ? parseDictionary(assortmentDictionary.sex) : allAvailableSexes
          "
          disable-deleted
          select-label="name"
          label="Пол"
          :read-only="!!itemSexItem.sexId"
          :disabled="itemSexItem.deleted"
          :error="getErrMess(`sexId`)"
        />
      </div>
      <div class="item-item-sex__sizeChart ml-5">
        <ASelect
          v-model="itemSexItem.sizeChart"
          :options="assortmentDictionary.sizes"
          label="Размерная сетка"
          :read-only="!accessToChange"
          :disabled="itemSexItem.deleted"
          :error="getErrMess(`sizeChart`)"
          multiple
        />
      </div>
      <AButton
        v-if="accessToChange"
        :danger="!itemSexItem.deleted"
        class="ml-10"
        @click="
          delResItemSexHandler({
            sexId: itemSexItem.sexId,
            deleteSex: !itemSexItem.deleted,
          })
        "
      >
        {{ itemSexItem.deleted ? 'Восстановить' : 'Удалить' }}</AButton
      >
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { parseDictionary } from '@/utils/dictionaryUtils.js';
import accessMixin from '@/mixin/accessMixin.js';
import validateMixin from '@/mixin/validateChild.js';

export default {
  name: 'ItemSexItem',
  mixins: [accessMixin, validateMixin],
  props: {
    modelValue: {
      type: Object,
      default: null,
    },
    allAvailableSexes: {
      type: Array,
      default: () => [],
    },
    itemDeleted: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'delResItemSex'],
  computed: {
    ...mapState('dictionary', ['assortmentDictionary']),
    accessToChange() {
      return this.isCanChangeAssortment && !this.itemDeleted && !this.loading;
    },
    itemSexItem: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  created() {
    this.parseDictionary = parseDictionary;
  },
  methods: {
    delResItemSexHandler({ sexId, deleteSex = false }) {
      this.$emit('delResItemSex', { sexId, deleteSex });
    },
  },
};
</script>
<style lang="scss" scoped>
.item {
  &-item-sex {
    &__block {
      display: grid;
      grid-template-columns: 80px 1fr 115px;
      margin-top: 8px;
      align-items: flex-end;
      min-width: 100%;
      button {
        width: fit-content;
      }
    }
    &__sizeChart {
      width: 100%;
      min-width: 100%;
    }
  }
}
</style>
