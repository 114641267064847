<template>
  <div
    class="message-cover"
    :class="{
      isIncoming,
      isOutgoing,
    }"
  >
    <div class="header">{{ message.user.name }}</div>
    <div class="body-wrapper">
      <div class="body-message">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-if="message.type == 1" class="body" v-html="messageText(message.text)" />
        <div v-if="message.type == 2" class="image">
          <img :src="getLink(message.content)" alt="message content" download />
        </div>
        <div
          v-if="message.type == 10"
          class="solution"
          :class="{
            'new': message.mockupStatus == 1,
            'confirmed': message.mockupStatus == 10,
            'cancelled': message.mockupStatus == 51,
          }"
        >
          <img :src="getLink(message.mockup.front)" alt="message content" download />
          <img
            v-if="message.mockup.back"
            :src="getLink(message.mockup.back)"
            alt="message content"
            download
          />
          <img
            v-if="message.mockup.plain"
            :src="getLink(message.mockup.plain)"
            alt="message content"
            download
          />
        </div>
        <!-- {{ message.text }}
        </div> -->
        <div class="footer">
          <div v-if="isOutgoing" class="status">
            <Fa
              v-if="message.status == 1"
              icon="check"
              class="icon send"
              style="width: 12px; height: 12px"
            />
            <Fa
              v-else-if="message.status == 3"
              icon="check-double"
              class="icon check"
              style="width: 12px; height: 12px"
            />
          </div>
          <div>{{ formatDate(message.createdAt) }}</div>
        </div>
      </div>
      <div class="body-quote">
        <!-- <AButton small @click="saveImage(message)">save </AButton> -->
        <AButton
          v-if="(isManager || isMainManager) && message.mockupStatus == 1"
          small
          green
          class="mx-5 my-5"
          :disabled="globalLoading"
          @click="confirmSolutionWrapper(message, 10)"
          >Утвердить
        </AButton>
        <AButton
          v-if="(isDesigner || isMainDesigner) && message.mockupStatus == 1"
          small
          danger
          class="mx-5"
          :disabled="globalLoading"
          @click="confirmSolutionWrapper(message, 51)"
          >Заблок.
        </AButton>
        <AButton
          v-if="(isDesigner || isMainDesigner) && message.mockupStatus == 51"
          small
          danger
          class="mx-5"
          :disabled="globalLoading"
          @click="confirmSolutionWrapper(message, 1)"
          >Разблок.
        </AButton>
      </div>
    </div>
  </div>
</template>

<script>
import accessMixin from '@/mixin/accessMixin.js';
import { dayjs } from '../../../utils/date.js';
import { getLink } from '../../../utils/getStorageLink.js';
import { mapActions } from 'vuex';
import ConfirmDialog from '@@/components/Confirm/index.vue';

export default {
  name: 'DesignSolutionMessageBlock',
  mixins: [accessMixin],
  props: {
    message: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: [],
  data() {
    return {
      changeStatusLoading: false,
    };
  },
  computed: {
    globalLoading() {
      return this.loading || this.changeStatusLoading;
    },
    isIncoming() {
      return this.accessUserId != this.message.userId;
      // return true;
      // return false;
    },
    isOutgoing() {
      return !this.isIncoming;
    },
  },
  created() {
    this.getLink = getLink;
  },
  methods: {
    ...mapActions({
      changeDesinSolutionMockupStatus: 'design/changeDesinSolutionMockupStatus',
    }),
    formatDate(date) {
      if (!date) return '-';
      return dayjs(date).format('HH:mm');
    },
    messageText(text = '') {
      const validateUrl = (url) => {
        const article =
          /^(http|https|ftp):\/\/([A-Z0-9][A-Z0-9_-]*(?:.[A-Z0-9][A-Z0-9_-]*)+):?(d+)?\/?/i;
        return !!article.test(url);
      };
      const paragraphs = text.split(/\r?\n/g).reduce((accParagraphs, paragraph) => {
        const words = paragraph.split(' ').reduce((accWords, word) => {
          word = word.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
          if (validateUrl(word)) {
            accWords.push(
              `<a href="${word}" target="_blank" rel="noopener noreferrer">${word}</a>`
            );
          } else {
            accWords.push(word);
          }
          return accWords;
        }, []);
        accParagraphs.push(words.join(' '));
        return accParagraphs;
      }, []);
      return paragraphs.join('<br />');
    },
    async confirmSolutionWrapper(message, newMockupStatus) {
      if (newMockupStatus === 10) {
        this.$dialog.addDialog({
          component: ConfirmDialog,
          props: {
            title: 'Подтверждение дизайна',
            text: 'После подтверждения дизайна, невозможно будет вернутся к обсуждению и редактированию дизайнерского решения',
            onConfirm: (confirm) => {
              confirm.showModal = false;
              this.confirmSolution(message, newMockupStatus);
            },
          },
        });
        return;
      }
      await this.confirmSolution(message, newMockupStatus);
    },
    async confirmSolution({ guid, chatId }, newMockupStatus) {
      this.changeStatusLoading = true;
      try {
        await this.changeDesinSolutionMockupStatus({
          designSolutionId: chatId,
          messageId: guid,
          newMockupStatus,
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.changeStatusLoading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.message-cover {
  @include Inter;
  display: flex;
  flex-direction: column;

  width: 100%;
  height: min-content;
  max-height: fit-content;

  margin-bottom: 10px;
  padding-right: 10px;

  .header {
    display: flex;
    height: 15px;
    margin-bottom: 2px;

    font-size: 12px;
    // background-color: rgb(244, 244, 245);
  }
  // background-color: rgb(214, 216, 218);

  .body-wrapper {
    display: flex;

    // background-color: aliceblue;
    max-width: 65%;
    height: fit-content;
    max-height: fit-content;
  }
  .body-message {
    border-radius: 6px;
    .body {
      min-width: 50px;
      margin: 8px;

      max-width: 100%;
      word-break: break-word;
    }
    .image {
      img {
        max-width: 100%;
      }
    }
    .solution {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      border: 1px solid $color-info;
      border-radius: 8px;

      &.new {
        border-color: $color-info;
      }
      &.confirmed {
        border-color: $color-apple;
      }
      &.cancelled {
        border-color: $color-red;
      }

      img {
        max-width: 48%;
      }
    }
    .footer {
      display: flex;
      flex-direction: row-reverse;
      padding: 4px;
      grid-gap: 10px;

      font-size: 13px;

      .status {
        display: flex;
        justify-self: center;
        align-self: center;

        margin-right: 10px;
        .send {
          color: $color-gray;
        }
        .check {
          color: $color-apple;
        }
      }
    }
  }

  &.isOutgoing {
    .header {
      flex-direction: row-reverse;
    }
    .body-wrapper {
      flex-direction: row-reverse;
      align-self: flex-end;
    }
    .body-message {
      background-color: $color-own;
      word-break: break-all;
    }
  }

  &.isIncoming {
    .body-message {
      background-color: $color-white-smoke;
    }
  }
}
</style>
