<template>
  <div class="products-wrapper">
    <div class="products__header">
      <div class="products__header-name">
        <div class="name" :title="getFullTitle">
          {{ isKit ? 'Комплект ' + getTitle : getTitle }}
        </div>
        <div class="code">
          {{ isKit ? 'Комплект ' + getKit.setId : 'Изделие ' + getProduct.itemId }}
          {{ getAllItem }}
        </div>
      </div>
      <div class="products__header-price">{{ getPrice }}&nbsp;{{ getCurrency.sign }}</div>
      <div v-if="getFullItem.isFree" class="products__header-free">
        <Fa
          name="icon"
          icon="gift"
          class="icon faicon free-product"
          style="width: 30px; height: 30px"
          title="Это изделие не будет отображаться у клиента"
        />
      </div>
      <div v-if="accessToChange" class="products__header-delete-btn">
        <div class="btn-wrapper" @click="deleteProductHandler">
          <Fa
            name="icon"
            icon="times"
            class="icon faicon"
            style="width: 35px; height: 35px"
            :title="`Удалить ${isKit ? 'комплект' : 'изделие'}`"
          />
        </div>
      </div>
    </div>
    <div class="products__content">
      <div
        v-if="selectedInfoTab == ORDER_TAB.ORDER"
        class="product-content__product-order"
        :class="{ 'visible': selectedInfoTab == ORDER_TAB.ORDER }"
      >
        <div class="a-col product-discounts">
          <AInput
            v-model="discountFix"
            :label="`Скидка/Наценка, ${getCurrency.sign}`"
            :disabled="!accessToChange || !!discountPercent || isFree"
            :read-only="false"
            type="number"
          />
          <AInput
            v-model="discountPercent"
            label="Скидка/Наценка, %"
            :disabled="!accessToChange || !!discountFix || isFree"
            :read-only="false"
            :min="-100"
            type="number"
          />
          <div v-if="!isKit" class="a-row free-product">
            <ACheckbox
              :model-value="getProduct.isFree"
              label="Подарок"
              class="mt-10"
              :disabled="!accessToChange"
              @change="setProductFree"
            />
            <ATooltip
              qtitle="Это изделие не будет отображаться у клиента. (Возможно только в случае, когда стоимость = 0)"
              position="left"
            />
          </div>
        </div>
      </div>
      <div class="product-cover">
        <div
          v-for="(product, prodInd) of getProducts"
          :key="`product_${product.index}_${product.localIndex}`"
          class="product-wrapper"
        >
          <div
            v-if="![ORDER_TAB.PRODUCTION, ORDER_TAB.DESIGN].includes(selectedInfoTab)"
            class="product-content"
          >
            <div
              class="product-content__image"
              :class="{ 'mini': selectedInfoTab == ORDER_TAB.ORDER }"
            >
              <div
                v-if="getProductImage(product, 'front') || getProductImage(product, 'back')"
                class="wrapper-img"
              >
                <div class="front-wrapper">
                  <img :src="getProductImage(product, 'front')" alt="front" />
                  <img class="back" :src="getProductImage(product, 'back')" alt="back" />
                </div>
              </div>
              <Fa
                v-else
                name="icon"
                icon="image"
                class="icon faicon empty-product"
                style="width: 60px; height: 60px"
              />
            </div>
            <div
              class="product-content__footer"
              :class="{ last: prodInd === getProducts.length - 1 }"
            >
              {{ getProductTypeName(product.itemId) }}
            </div>
          </div>
          <div
            v-if="selectedInfoTab === ORDER_TAB.PRODUCTION"
            class="product-params-content mousewheel-abort"
          >
            <div class="a-col mt-5">
              <ASelect
                :model-value="product.paramsCloverId"
                :options="parseDictionary(productionParamsDictionary.paramsClover)"
                placeholder="Клевер"
                select-label="name"
                disable-deleted
                :disabled="!accessToChangeProduction"
                @change="
                  setProductionParams({
                    selectedOrder: isSelectedOrder,
                    index: product.index,
                    localIndex: product.localIndex,
                    key: 'paramsCloverId',
                    value: $event,
                  })
                "
              />
              <ASelect
                :model-value="product.paramsPackagingId"
                :options="parseDictionary(productionParamsDictionary.paramsPackaging)"
                placeholder="Упаковка изделий"
                select-label="name"
                disable-deleted
                :disabled="!accessToChangeProduction"
                @change="
                  setProductionParams({
                    selectedOrder: isSelectedOrder,
                    index: product.index,
                    localIndex: product.localIndex,
                    key: 'paramsPackagingId',
                    value: $event,
                  })
                "
              />
              <ASelect
                :model-value="product.paramsOrderPackagingId"
                :options="parseDictionary(productionParamsDictionary.paramsOrderPackaging)"
                placeholder="Упаковка заказа"
                select-label="name"
                disable-deleted
                :disabled="!accessToChangeProduction"
                @change="
                  setProductionParams({
                    selectedOrder: isSelectedOrder,
                    index: product.index,
                    localIndex: product.localIndex,
                    key: 'paramsOrderPackagingId',
                    value: $event,
                  })
                "
              />
              <ASelect
                :model-value="product.paramsPressSizeId"
                :options="parseDictionary(productionParamsDictionary.paramsPressSize)"
                placeholder="Разм. пресс"
                select-label="name"
                disable-deleted
                :disabled="!accessToChangeProduction"
                @change="
                  setProductionParams({
                    selectedOrder: isSelectedOrder,
                    index: product.index,
                    localIndex: product.localIndex,
                    key: 'paramsPressSizeId',
                    value: $event,
                  })
                "
              />
              <ASelect
                :model-value="product.paramsProfileId"
                :options="parseDictionary(productionParamsDictionary.paramsProfile)"
                placeholder="Профиль"
                select-label="name"
                disable-deleted
                :disabled="!accessToChangeProduction"
                @change="
                  setProductionParams({
                    selectedOrder: isSelectedOrder,
                    index: product.index,
                    localIndex: product.localIndex,
                    key: 'paramsProfileId',
                    value: $event,
                  })
                "
              />
              <ASelect
                :model-value="product.paramsSizeBoxId"
                :options="parseDictionary(productionParamsDictionary.paramsSizeBox)"
                placeholder="Размерник"
                class="paramsSizeBox"
                select-label="name"
                disable-deleted
                :disabled="!accessToChangeProduction"
                @change="
                  setProductionParams({
                    selectedOrder: isSelectedOrder,
                    index: product.index,
                    localIndex: product.localIndex,
                    key: 'paramsSizeBoxId',
                    value: $event,
                  })
                "
              />
              <AInput
                :model-value="product.filePath"
                placeholder="Путь к файлам"
                :disabled="!accessToChangeProduction"
                @input="
                  setProductionParams({
                    selectedOrder: isSelectedOrder,
                    index: product.index,
                    localIndex: product.localIndex,
                    key: 'filePath',
                    value: $event,
                  })
                "
              />
              <AInput
                :model-value="product.color"
                placeholder="Цвет"
                :disabled="!accessToChangeProduction"
                @input="
                  setProductionParams({
                    selectedOrder: isSelectedOrder,
                    index: product.index,
                    localIndex: product.localIndex,
                    key: 'color',
                    value: $event,
                  })
                "
              />
              <AInput
                :model-value="product.comment"
                placeholder="Комментарий"
                :disabled="!accessToChangeProduction"
                @input="
                  setProductionParams({
                    selectedOrder: isSelectedOrder,
                    index: product.index,
                    localIndex: product.localIndex,
                    key: 'comment',
                    value: $event,
                  })
                "
              />
            </div>
            <div class="product-params-content-footer">
              <span class="pointer"> {{ getProductTypeName(product.itemId) }}</span>
              <div class="image-preview">
                <img :src="getProductImage(product, 'front')" alt="front" />
              </div>
            </div>
          </div>
          <div v-if="selectedInfoTab === ORDER_TAB.DESIGN">
            <div class="product-content__product-design">
              <AInputFile
                max="5"
                greyish
                :formats="mockupFormats"
                :disabled="fileToUpload[product.localIndex] || !accessToChangeProduction"
                @update:modelValue="
                  uploadManagerImage($event, product.guid, product.index, product.localIndex)
                "
              >
                <div>
                  {{
                    (product.managerDesign && product.managerDesign.plain) ||
                    product.design.mockup.plain
                      ? 'Изменить'
                      : 'Изобр. для карт'
                  }}
                </div>
                <template #icon>
                  <Fa
                    name="icon"
                    icon="file-image"
                    class="icon faicon"
                    style="width: 30px; height: 30px"
                  />
                </template>
              </AInputFile>
              <ALoader v-if="fileToUpload[product.localIndex]" centered />
              <img
                v-if="product.managerDesign && product.managerDesign.plain"
                class="image"
                width="90px"
                height="110px"
                :src="getLink(product.managerDesign.plain)"
                alt="мокап"
              />
              <img
                v-else-if="product.design.mockup && product.design.mockup.plain"
                class="image"
                width="90px"
                height="110px"
                :src="getLink(product.design.mockup.plain)"
                alt="мокап"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import ConfirmDialog from '@@/components/Confirm/index.vue';
import { getLink } from '@/utils/getStorageLink';
import { CURRENCIES } from '@/constants/index.js';
import accessMixin from '@/mixin/accessMixin.js';
import { parseDictionary } from '@/utils/dictionaryUtils.js';
import { MOCKUP_FORMATS, ORDER_STATE, ORDER_TAB } from '../../../../constants';

export default {
  name: 'ProductBlock',
  mixins: [accessMixin],
  props: {
    // ind: {
    //   type: Number,
    //   required: true,
    // },
    index: {
      type: Number,
      required: true,
    },
    localIndex: {
      type: Number,
      default: null,
    },
    selectedInfoTab: {
      type: String,
      default: 'client',
    },
    shadowRow: {
      type: Boolean,
      default: false,
    },
    isSelectedOrder: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      mockupFormats: [...MOCKUP_FORMATS],
      fileToUpload: null,
    };
  },
  computed: {
    ...mapState({
      newOrder: (s) => s.order.newOrder,
      selectedOrder: (s) => s.order.selectedOrder,
      assortmentDictionary: (s) => s.dictionary.assortmentDictionary,
      productionParamsDictionary: (s) => s.dictionary.productionParams,
      orderValidateErrors: (s) =>
        s.order[`${this?.isSelectedOrder ? 'selectedOrder' : 'newOrder'}Info`].orderErrors,
    }),
    accessToChange() {
      return (
        this.isCanChangeOrder &&
        ![ORDER_STATE.COMPLETED, ORDER_STATE.PRODUCTION, ORDER_STATE.TECH_DESIGN].includes(
          this.getPageOrder._old.state
        )
      );
    },
    accessToChangeProduction() {
      return (
        this.isCanChangeOrder &&
        ![ORDER_STATE.COMPLETED, ORDER_STATE.PRODUCTION, ORDER_STATE.TECH_DESIGN].includes(
          this.getPageOrder._old.state
        )
      );
    },
    getFullItem() {
      return this.isKit ? this.getKit : this.getProduct;
    },
    getCurrency() {
      return CURRENCIES.find((currency) => currency.value == this.getPageOrder.currency);
    },
    isKit() {
      return this.localIndex != null;
    },
    getProduct() {
      return this.getPageOrder.products.find(
        (el) => el.index === this.index && el.localIndex === this.localIndex
      );
    },
    getKit() {
      if (!this.getProduct) return null; // пока в процессе удаления
      return this.isKit
        ? this.getPageOrder.kits.find((kit) => kit.guid === this.getProduct.kitId)
        : null;
    },
    getProductName() {
      const item = this.assortmentDictionary.items[this.getProduct.itemId];
      return (
        this.assortmentDictionary.productTypes[item.productTypeId].name +
        ' ' +
        this.assortmentDictionary.fabrics[this.getProduct.fabricId].name
      );
    },
    getProductTypeName() {
      return (itemId) => {
        const item = this.assortmentDictionary.items[itemId];
        return this.assortmentDictionary.productTypes[item.productTypeId].name;
      };
    },
    getSetName() {
      if (this.isKit) {
        return this.assortmentDictionary.sets[this.getKit.setId].name;
      }
      return '';
    },
    getTitle() {
      if (this.isKit) {
        const setName = this.getSetName;
        if (setName.length > 70) {
          return `${setName.slice(0, 70)}...`;
        }
        return setName;
      }
      return this.getProductName;
    },
    getFullTitle() {
      if (this.isKit) {
        return this.getSetName;
      }
      return this.getProductName;
    },
    getPrice() {
      const item = this.isKit ? this.getKit : this.getProduct;
      if (!item) {
        return 'deleteProcess';
      }
      const discount = item.discountFix || (item.defPrice / 100) * item.discountPercent;
      return +(item.defPrice + discount).toFixed(2);
    },
    getAllItem() {
      if (!this.isKit) return '';
      return ` ( Изделия: ${this.getProducts.map((el) => el.itemId).join(', ')} )`;
    },
    getPageOrder() {
      return this.isSelectedOrder ? this.selectedOrder : this.newOrder;
    },
    getProducts() {
      return !this.getProduct.localIndex
        ? [this.getProduct]
        : this.getPageOrder.products.filter((_) => _.index === this.getProduct.index);
    },
    discountFix: {
      get() {
        return this.getFullItem.discountFix;
      },
      set(value) {
        this.setDiscounts({
          selectedOrder: this.isSelectedOrder,
          isKit: this.isKit,
          index: this.getFullItem.index,
          localIndex: this.isKit ? null : this.getProduct.localIndex,
          key: 'discountFix',
          data: value,
        });
      },
    },
    discountPercent: {
      get() {
        return this.getFullItem.discountPercent;
      },
      set(value) {
        this.setDiscounts({
          selectedOrder: this.isSelectedOrder,
          isKit: this.isKit,
          index: this.getFullItem.index,
          localIndex: this.isKit ? null : this.getProduct.localIndex,
          key: 'discountPercent',
          data: value,
        });
      },
    },
    isFree() {
      return !this.isKit && this.getProduct.isFree;
    },
  },
  watch: {
    getPrice(price) {
      if (price != 'deleteProcess' && !this.isKit && this.isFree && price > 0) {
        this.isFree = false;
      }
    },
  },
  created() {
    this.getLink = getLink;
    this.parseDictionary = parseDictionary;
    this.ORDER_TAB = ORDER_TAB;
    this.fileToUpload = new Array(this.getProducts.length);
  },
  methods: {
    ...mapActions({
      uploadImage: 'upload/uploadImage',
    }),
    ...mapMutations({
      setDiscounts: 'order/setDiscounts',
      setProductionParams: 'order/setProductionParams',
      deleteProduct: 'order/DELETE_PRODUCT',
    }),
    getProductImage(product, side) {
      if (product.design?.mockup?.[side]) {
        return getLink(product.design.mockup[side]);
      }
      if (product.productImage) {
        return getLink(product.productImage);
      }
      if (product.design?.mockup?.plain) {
        return getLink(product.design.mockup?.plain);
      }
      const item = this.assortmentDictionary.items[product.itemId];
      const articleMockup = this.assortmentDictionary.articles[item.articleId]?.mockup;
      if (articleMockup?.[side]) {
        return getLink(articleMockup[side]);
      }
      return null;
    },
    getOrderErrMess(path) {
      return this.orderValidateErrors[path];
    },
    setProductFree(e) {
      if (this.isKit) return;
      const free = !!e;
      this.setProductionParams({
        selectedOrder: this.isSelectedOrder,
        index: this.getProduct.index,
        localIndex: this.getProduct.localIndex,
        key: 'isFree',
        value: free,
      });
      if (!e) {
        return;
      }

      this.setDiscounts({
        selectedOrder: this.isSelectedOrder,
        isKit: false,
        index: this.getProduct.index,
        localIndex: this.getProduct.localIndex,
        key: 'discountFix',
        data: 0,
      });
      this.setDiscounts({
        selectedOrder: this.isSelectedOrder,
        isKit: false,
        index: this.getProduct.index,
        localIndex: this.getProduct.localIndex,
        key: 'discountPercent',
        data: free ? -100 : 0,
      });
    },
    deleteProductHandler() {
      this.$dialog.addDialog({
        component: ConfirmDialog,
        props: {
          title: `Удалить ${this.isKit ? 'комплект' : 'изделие'}?`,
          closeText: 'Отмена',
          onConfirm: (confirm) => {
            confirm.showModal = false;
            this.deleteProduct({
              selectedOrder: this.isSelectedOrder,
              isKit: this.isKit,
              index: this.getFullItem.index,
            });
          },
        },
      });
    },
    async uploadManagerImage(file, productId, index, localIndex) {
      if (!file) {
        return;
      }
      this.fileToUpload[localIndex] = true;
      let sha2;
      try {
        ({ sha2 } = await this.uploadImage({
          file,
          entityId: productId,
          key: 'product-manager-mockup-plain',
        }));
      } catch (error) {
        console.log(error);
      } finally {
        this.fileToUpload[localIndex] = false;
      }

      this.setProductionParams({
        selectedOrder: this.isSelectedOrder,
        index,
        localIndex,
        key: 'managerDesign',
        value: {
          back: null,
          front: null,
          plain: sha2,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.products-wrapper {
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 50px 1fr;
  height: 340px !important;

  border: none;
}

.products {
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;

    &:hover {
      .products__header-delete-btn {
        visibility: visible;
        top: 30px;
        opacity: 1;
        transition: opacity 0.15s, top 0.15s;
      }
    }
    margin: 10px 15px 10px 10px;

    &-name {
      max-width: fit-content;
      .name {
        @include InterSemibold;
        font-size: 14px;
        text-align: left;
      }
      .code {
        @include Inter;

        display: flex;
        align-self: flex-start;

        font-size: 10px;

        color: $color-gray;
      }
    }
    &-price {
      @include InterBold;
      font-size: 20px;
      align-self: center;
    }
    &-free {
      color: $color-red;
    }

    &-delete-btn {
      visibility: hidden;

      position: absolute;
      left: calc(50% - 16px);
      top: 0px;
      z-index: 10;
      color: $color-red;
      cursor: pointer;

      opacity: 0;
      :active {
        transform: scale(0.96);
      }
    }
  }
  &__content {
    // display: grid;
    // grid-auto-flow: row;
    // grid-template-columns: 1fr 1fr;
    display: flex;
    flex-direction: column;
    max-height: 290px;
  }
}

.product-cover {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  transition: height 0.15s;
}
.product-wrapper {
  display: grid;
  grid-auto-flow: column;

  width: 250px;
  height: 100%;
}

.product-content {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: 1fr 30px;

  &__product-order {
    display: flex;
    justify-content: center;
    height: 0%;
    width: 100%;

    &.visible {
      height: 100%;
      transition: height 0.15s ease-in;
    }
    .a-col {
      height: 100%;

      justify-content: center;
      align-items: center;
      overflow: hidden;
    }
  }

  &__image {
    display: flex;
    flex-direction: row;

    align-self: center;
    align-content: center;
    justify-content: center;

    .wrapper-img {
      display: inline-block;
      position: relative;

      .front-wrapper {
        position: relative;
      }
      .back {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        transition: opacity 0.15s;
        will-change: opacity;
      }
      .front-wrapper:hover .back {
        opacity: 1;
        transition: opacity 0.15s;
        will-change: opacity;
      }
    }
    img {
      max-width: 170px;
      max-height: 220px;
      transition: all 0.15s;
    }

    &.mini {
      img {
        max-height: 90px !important;
        transition: all 0.25s;
      }
    }
  }

  &__product-design {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    align-self: center;
    align-content: center;
    justify-content: space-between;

    .image {
      width: 170px;
      height: 220px;

      display: flex;
      flex-direction: row;

      align-self: center;
      align-content: center;
      justify-content: center;
    }
  }

  &__footer {
    justify-content: center;
    align-self: center;
  }
  &__footer:not(.last) {
    border-right: 1px solid $color-bg-smoke-active;
  }
}

.product-params-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &-footer {
    position: relative;
    align-self: center;
    padding: 5px;

    .image-preview {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      padding: 10px;
      position: absolute;
      margin: auto;
      top: 120px;
      left: 50%;
      transform: translate(-50%);
      img {
        max-height: 100px !important;
        border-radius: 10px;
      }
    }
    span:hover ~ .image-preview {
      visibility: visible;
      transition: all 0.2s;
      opacity: 1;
      top: 20px;
    }
  }

  .a-col > * {
    margin-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.empty-product {
  color: $color-bg-smoke-active;
}
.product-discounts {
  .free-product {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
}
</style>
<style lang="scss">
.product-params-content,
.product-discounts {
  .a-select__el {
    height: 20px;
  }
  .a-input__el {
    height: 20px;
  }
  .a-input__label {
    bottom: 15%;
  }
}
</style>
