import userApi from '@/api/userApi';
import designerApi from '@/api/designerApi';

export default {
  async getAllDesigners({ commit }) {
    const { data } = await userApi.getAllDesigners();
    commit('SET_ALL_DESIGNERS', data);
  },

  async getDesignSolutions({ commit }) {
    const { data } = await designerApi.getDesignSolutions();
    commit('SET_DESIGN_SOLUTIONS', data);
  },

  async getDesignSolution({ commit }, { designSolutionId }) {
    const { data, designMessages } = await designerApi.getDesignSolution({ designSolutionId });
    commit('SET_DESIGN_SOLUTION', data);
    commit('SET_DESIGN_SOLUTIONS_MESSAGES', designMessages);
  },

  async sendDesignSolutionMessage(_, { message, designSolutionId }) {
    await designerApi.sendDesignSolutionMessage({ message, designSolutionId });
  },

  async updateDesignSolution({ commit }, { designSolution }) {
    const { data } = await designerApi.updateDesignSolution({ designSolution });
    commit('SET_DESIGN_SOLUTION', data);
  },
  async changeStateAndDesignerInDesignSolution(
    _,
    { designSolutionId, orderId, currentState, designerId, newState }
  ) {
    await designerApi.changeStateAndDesignerInDesignSolution({
      designSolutionId,
      orderId,
      currentState,
      designerId,
      newState,
    });
  },

  async getDesignMessages({ commit }, { designSolutionId }) {
    const { data } = await designerApi.getDesignMessages({ designSolutionId });
    commit('SET_DESIGN_SOLUTIONS_MESSAGES', data);
  },

  async changeDesinSolutionMockupStatus(_, { messageId, newMockupStatus, designSolutionId }) {
    await designerApi.changeDesinSolutionMockupStatus({
      messageId,
      newMockupStatus,
      designSolutionId,
    });
  },

  async deleteDesignSolution(_, { designSolutionId, originalDesignSolutionId }) {
    await designerApi.deleteDesignSolution({ designSolutionId, originalDesignSolutionId });
  },

  async SOCKET_getDesignMessage({ commit }, data) {
    commit('ADD_DESIGN_MESSAGE', data);
  },
  async SOCKET_updateDesignMessage({ commit }, data) {
    commit('UPDATE_DESIGN_MESSAGE', data);
  },
  async SOCKET_updateDesignSolutions({ commit }, data) {
    commit('UPDATE_DESIGN_SOLUTIONS', data);
  },
  // async SOCKET_disconnect(_, data) {
  //   console.log('SOCKET SOCKET_disconnect', data);
  // },
};
