<template>
  <APageLayout max-height>
    <template #header>
      <HeaderString
        v-model:search="filters.search"
        v-model:createdAt="filters.createdAt"
        v-model:deadline="filters.deadline"
        :loading="globalLoading"
        @openOrdersSettingAside="openTableSettingAside = true"
      />
    </template>
    <template #content>
      <div class="content-wrapper">
        <div v-if="globalLoading" class="loader-wrapper">
          <ALoader centered />
        </div>
        <OrdersTable class="mt-20" :selected-fields="selectedFields" :loading="globalLoading" />
        <div v-if="!allOrdersLoaded" class="pagination mt-20 pb-20">
          <div class="btn">
            <AButton white :disabled="globalLoading" @click="showMore"> Показать ещё </AButton>
          </div>
          <div>
            <ASelect
              v-model="pagination.pageSize"
              :options="PAGINATION_SIZES"
              class="orders-pagination-size"
              :class="{ 'top-open': ordersCount }"
              block-unselect
            />
          </div>
        </div>
      </div>

      <OrdersTableSettingAsideBlock
        :open-table-setting-aside="openTableSettingAside"
        @close="openTableSettingAside = false"
        @updateOrdersTableSetting="updateOrdersTableSettingHandler"
      />
    </template>
  </APageLayout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import HeaderString from './headerString/index.vue';
import OrdersTable from './components/OrdersTable.vue';
import OrdersTableSettingAsideBlock from './components/OrdersTableSettingAsideBlock.vue';
import { debounce } from '../../utils/lodash.js';
import { STORAGE_KEY, STORAGE_PATH, storage } from '../../utils/localStorage';
import { cloneDeep } from 'lodash';

const PAGINATION_SIZES = [50, 100, 200, 300];

export default {
  name: 'Orders',
  components: {
    OrdersTableSettingAsideBlock,
    HeaderString,
    OrdersTable,
  },
  data() {
    return {
      ordersLoading: true,

      openTableSettingAside: false,
      allOrdersLoaded: false,

      selectedFields: [],
      settings: {},

      filters: {
        search: null,
        createdAt: null,
        deadline: null,
      },
      pagination: {
        currentPage: 1,
        totalPages: null,
        pageSize: storage({ key: STORAGE_KEY.PAGINATION, path: STORAGE_PATH.ORDERS_TABLE }) || 100,
      },
    };
  },
  computed: {
    ...mapState({
      ordersCount: (s) => s.order.allOrders?.length,
    }),
    globalLoading() {
      return this.ordersLoading;
    },
  },
  watch: {
    filters: {
      handler: function () {
        this.debounceSearchOrders();
      },
      deep: true,
    },
    'pagination.pageSize': function (pageSize) {
      storage({ key: STORAGE_KEY.PAGINATION, path: STORAGE_PATH.ORDERS_TABLE }, pageSize || 100);
    },
  },

  created() {
    this.PAGINATION_SIZES = PAGINATION_SIZES;
  },
  methods: {
    ...mapActions({
      getAllOrders: 'order/getAllOrders',
    }),
    updateOrdersTableSettingHandler(
      allParam = { selectedFields: [], settings: { showOrdersAllManager: false } }
    ) {
      this.selectedFields = cloneDeep(allParam.selectedFields);
      this.settings = cloneDeep(allParam.settings);
      this.debounceSearchOrders(0);
    },
    showMore() {
      this.debounceSearchOrders(this.ordersCount);
    },
    debounceSearchOrders: debounce(async function (offset = 0) {
      try {
        this.ordersLoading = true;
        const { length: fetchCount } = await this.getAllOrders({
          filters: this.filters,
          selectedFields: this.selectedFields,
          settings: this.settings,
          limit: this.pagination.pageSize,
          offset,
        });
        if (fetchCount < this.pagination.pageSize) {
          this.allOrdersLoaded = true;
        } else {
          this.allOrdersLoaded = false;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.ordersLoading = false;
      }
    }, 600),
  },
};
</script>
<style lang="scss" scoped>
.page-header {
  @include InterBold;
  font-size: 18px;
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 36px;
}
.loader-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;

  justify-content: center;
}
.content-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 200px);
  // min-height: calc(100vh - 200px);
  width: 100%;

  justify-items: space-between;

  .pagination {
    display: grid;
    grid-template-columns: 1fr 70px;
    width: 100%;
    .btn {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      justify-content: center;
    }
  }
}
</style>
<style lang="scss">
.pagination .orders-pagination-size {
  width: 70px;

  &.top-open {
    .a-select__options-list {
      top: -200px;
      border-radius: 8px;
      transition: all 0s;
    }
  }
}
</style>
