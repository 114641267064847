<template>
  <div class="block-wrapper">
    <div v-if="localItem && localItem.article" class="item">
      <div v-if="localItem.deleted" class="item-alert-deleted">Изделие удалено !</div>
      <div v-if="item" class="item-title-block">
        <div class="item-title-block__title">
          <div>
            Изделие:&nbsp;
            <span class="a-bold"> {{ localItem.id }}. </span> &emsp;{{ localItem.name }}
          </div>
          <div>
            <AButton
              v-if="
                isCanChangeAssortment &&
                !creatingItem &&
                !itemLoading &&
                !localItem.deleted &&
                localItem.id &&
                item.id
              "
              :disabled="isLocalItemModified"
              @click="$emit('itemProductionSetting', item)"
            >
              Настр. Произв
            </AButton>
          </div>
        </div>
        <div class="item-title-block__subtitle">
          <div class="item-title-block__article">Артикул:&ensp;</div>
          <div>{{ localItem.article.code }}</div>
        </div>
      </div>
      <div v-else class="item-title-block">
        <div class="item-title-block__title">Новый изделие</div>
      </div>
      <div class="item-mockups">
        <img
          v-if="localItem.article.mockup.front"
          :src="getMockupLink(localItem.article.mockup.front, 'front')"
          alt="артикул-перед"
          class="item-mockups__front"
        />
        <div v-else class="no-image">
          <Fa
            name="icon"
            icon="image"
            class="icon faicon empty-product"
            style="width: 60px; height: 60px"
          />
        </div>

        <img
          v-if="localItem.article.mockup.back"
          :src="getMockupLink(localItem.article.mockup.back, 'back')"
          alt="артикул-спинка"
          class="item-mockups__back"
        />
        <div v-else class="no-image">
          <Fa
            name="icon"
            icon="image"
            class="icon faicon empty-product"
            style="width: 60px; height: 60px"
          />
        </div>
      </div>
      <div class="item-details">
        <div class="item-details_block">
          <AInput
            v-model="localItem.name"
            :read-only="!accessToChange"
            label="Название"
            :error="getErrMess('name')"
          />
          <ASelect
            v-model="localItem.growTypeId"
            :options="parseDictionary(assortmentDictionary.growTypes)"
            disable-deleted
            select-label="name"
            label="Тип размера"
            :read-only="!accessToChange"
            :error="getErrMess('growTypeId')"
          />
        </div>
        <div class="item-details_block">
          <ASelect
            v-model="localItem.categoryId"
            :options="parseDictionary(assortmentDictionary.categories)"
            disable-deleted
            select-label="name"
            label="Категория"
            :read-only="!accessToChange"
            :error="getErrMess('categoryId')"
          />
          <ASelect
            v-model="localItem.productTypeId"
            :options="parseDictionary(assortmentDictionary.productTypes)"
            disable-deleted
            select-label="name"
            label="Тип"
            :read-only="!accessToChange"
            :error="getErrMess('productTypeId')"
          />
        </div>
        <AInput
          v-model="localItem.desc"
          textarea
          :read-only="!accessToChange"
          name="localItem.desc"
          class="item-details__desc"
          label="Общее описание изделия"
          :error="getErrMess('desc')"
        />

        <ASpacer text="Артикул" class="mb-15 mt-15" bold />
        <div class="item-details_block">
          <ASelect
            v-model="localItem.article.id"
            :options="parseDictionary(assortmentDictionary.articles)"
            disable-deleted
            select-label="name"
            label="Название артикула"
            :read-only="!(accessToChange && creatingItem)"
            :error="getErrMess('article.id')"
          />
          <ASelect
            :model-value="localItem.article.id"
            :options="parseDictionary(assortmentDictionary.articles)"
            disable-deleted
            select-label="code"
            label="Код артикула"
            read-only
          />
        </div>
        <div class="item-details_block">
          <AButton v-if="localItem.article.file" light @click="openWindow(localItem.article.file)">
            Скачать артикул
          </AButton>
          <ALabel v-else value="Файл артикула не загружен в систему" />
        </div>
        <AInput
          v-if="localItem.article.id"
          :model-value="assortmentDictionary.articles[localItem.article.id].desc"
          textarea
          read-only
          class="item-details__desc"
          label="Описание артикула"
          :error="getErrMess('article.desc')"
        />
        <ASpacer text="Цены и материал" class="mb-15 mt-15" bold />
        <div class="item-item-fabrics">
          <div v-for="(itemFabric, ind) in localItem.itemFabricPrices" :key="`${ind}_`">
            <ItemPriceItem
              v-model="localItem.itemFabricPrices[ind]"
              :error-prop-path="`itemFabricPrices[${ind}]`"
              :form-errors="formErrors"
              :loading="itemLoading"
              :all-available-fabrics="allAvailableFabrics"
              :item-deleted="localItem.deleted"
              @delResItemFabric="delResItemFabricHandler({ ...$event, ind })"
            />
          </div>
          <div v-if="!localItem.itemFabricPrices.length" class="validation-error-items">
            Необходим хотя бы один материал
          </div>
          <AButton v-if="accessToChange && !localItem.deleted" class="my-10" @click="addItemPrice">
            {{ 'Добавить' }}</AButton
          >
        </div>
        <ASpacer text="Размеры" class="mb-15 mt-15" bold />
        <div class="item-item-fabrics">
          <div v-for="(sexSize, ind) in localItem.itemSexes" :key="`${ind}_${sexSize.sexId}`">
            <ItemSexItem
              v-model="localItem.itemSexes[ind]"
              :error-prop-path="`itemSexes[${ind}]`"
              :form-errors="formErrors"
              :all-available-sexes="allAvailableSexes"
              :item-deleted="localItem.deleted"
              :loading="itemLoading"
              @delResItemSex="delResItemSexHandler({ ...$event, ind })"
            />
          </div>
          <div v-if="!localItem.itemSexes.length" class="validation-error-items">
            Необходим хотя бы один пол
          </div>
          <AButton v-if="accessToChange && !localItem.deleted" class="my-10" @click="addItemSex">
            Добавить
          </AButton>
        </div>
        <div class="item-details_block">
          <AButton
            v-if="accessToChange"
            success
            :disabled="!isLocalItemModified"
            @click="saveItemHandler"
          >
            Сохранить
          </AButton>
          <AButton v-if="accessToChange" :disabled="!isLocalItemModified" @click="updateFromProp()">
            Сбросить
          </AButton>
          <AButton
            v-if="isCanChangeAssortment && !creatingItem && !itemLoading"
            :disabled="isLocalItemModified"
            :danger="!localItem.deleted"
            :success="localItem.deleted"
            @click="deleteItemHandler(!localItem.deleted)"
          >
            {{ localItem.deleted ? 'Восстановить' : 'Удалить' }}
          </AButton>
        </div>
        <ALoader v-if="itemLoading" centered green />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { parseDictionary } from '../../../../utils/dictionaryUtils.js';
import { cloneDeep, isEqual } from '../../../../utils/lodash.js';
import accessMixin from '../../../../mixin/accessMixin.js';
import { itemBlockSchema } from '../../../../utils/validate/index.js';
import { getLink } from '../../../../utils/getStorageLink';
import validateMixin from '../../../../mixin/validate.js';
import ItemPriceItem from './ItemPriceItem.vue';
import ItemSexItem from './ItemSexItem.vue';
import ConfirmDialog from '@@/components/Confirm/index.vue';

const itemArticleItem = {
  id: null,
  code: null,
  desc: null,
  file: null,
  name: null,
  mockup: {},
  createdAt: null,
  isNewItem: true,
};

const itemFabricPricesItem = {
  id: null,
  prices: {
    EUR: null,
    RUR: null,
  },
  deleted: false,
  fabricId: null,
  isNewItem: true,
};

const itemSexesItem = {
  id: null,
  sizeChart: [],
  deleted: false,
  sexId: null,
  isNewItem: true,
};

const newItem = {
  id: null,
  name: null,
  desc: null,
  productTypeId: null,
  categoryId: null,
  growTypeId: null,
  deleted: null,
  article: cloneDeep(itemArticleItem),
  itemFabricPrices: [cloneDeep(itemFabricPricesItem)],
  itemSexes: [cloneDeep(itemSexesItem)],
};

export default {
  name: 'ItemBlock',
  components: { ItemPriceItem, ItemSexItem },
  mixins: [accessMixin, validateMixin],
  props: {
    item: {
      type: Object,
      default: null,
    },
    creatingItem: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'itemProductionSetting'],
  data() {
    return {
      itemLoading: false,

      localItem: null,
      errors: null,
    };
  },
  computed: {
    ...mapState('dictionary', ['assortmentDictionary']),
    accessToChange() {
      return this.isCanChangeAssortment && !this.localItem.deleted && !this.itemLoading; // TODO !this.item.deleted;
    },
    isLocalItemModified() {
      return !isEqual(this.item, this.localItem);
    },
    allAvailableFabrics() {
      const fabricsIds = this.localItem.itemFabricPrices.map((item) => item.fabricId);
      return this.parseDictionary(this.assortmentDictionary.fabrics).filter(
        (fabric) => !fabricsIds.includes(fabric.id)
      );
    },
    allAvailableSexes() {
      const sexesIds = this.localItem.itemSexes.map((sex) => sex.sexId);
      return this.parseDictionary(this.assortmentDictionary.sex).filter(
        (sex) => !sexesIds.includes(sex.id)
      );
    },
  },
  watch: {
    item: function (item) {
      this.updateFromProp(item);
    },
  },
  created() {
    this.updateFromProp();
    this.parseDictionary = parseDictionary;
  },
  methods: {
    ...mapActions({
      changeItem: 'assortment/changeItem',
      createItem: 'assortment/createItem',
    }),

    async saveItem(deleteOrRestore) {
      const formValidation = this.$form(this.localItem, itemBlockSchema);
      if (!formValidation.isValid) {
        this.$notifyError({ title: `Ошибка валидации формы` });
        return;
      }
      this.itemLoading = true;
      try {
        const handler = this.creatingItem ? this.createItem : this.changeItem;
        await handler({ item: this.localItem });
        this.$emit('close');
        this.$notify({ title: 'Сохранено' });
      } catch (err) {
        console.log(err);
        if (deleteOrRestore) {
          this.localItem.deleted = !this.localItem.deleted;
        }
      } finally {
        this.itemLoading = false;
      }
    },
    deleteItemHandler(needDeleted) {
      if (this.isLocalItemModified) {
        this.$notifyError({
          title: `Сбросте (или сохраните) все изменения в изделие, а затем удалите его`,
        });
        return;
      }
      this.$dialog.addDialog({
        component: ConfirmDialog,
        props: {
          title: needDeleted ? 'Удаление изделия' : 'Восстановление изделия',
          text: `Вы действительно хотите ${needDeleted ? 'удалить' : 'восстановить'} изделие?`,
          onConfirm: (confirm) => {
            confirm.showModal = false;
            this.localItem.deleted = needDeleted;
            this.saveItem(true);
          },
        },
      });
    },
    saveItemHandler() {
      this.$dialog.addDialog({
        component: ConfirmDialog,
        props: {
          title: 'Сохранить изменения',
          text: 'Применить выбранные изменения?',
          onConfirm: (confirm) => {
            confirm.showModal = false;
            this.saveItem();
          },
        },
      });
    },
    addItemPrice() {
      this.localItem.itemFabricPrices.push(cloneDeep(itemFabricPricesItem));
    },
    addItemSex() {
      this.localItem.itemSexes.push(cloneDeep(itemSexesItem));
    },
    delResItemSexHandler({ sexId, deleteSex = false, ind }) {
      const index = sexId ? this.localItem.itemSexes.findIndex((i) => i.sexId === sexId) : ind;
      if ([-1, null].includes(index)) {
        this.$notifyError({
          title: `Ошибка. Попробуйте ${
            deleteSex ? 'удалить' : 'восстановить'
          } пол отдельным действием`,
        });
      }
      const itemSexes = this.localItem.itemSexes[index];
      if (itemSexes?.isNewItem) {
        this.localItem.itemSexes.splice(index, 1);
      } else {
        itemSexes.deleted = deleteSex;
      }
    },
    delResItemFabricHandler({ fabricId, deleteFabric = false, ind }) {
      const index = fabricId
        ? this.localItem.itemFabricPrices.findIndex((i) => i.fabricId === fabricId)
        : ind;
      if ([-1, null].includes(index)) {
        this.$notifyError({
          title: `Ошибка. Попробуйте ${
            deleteFabric ? 'удалить' : 'восстановить'
          } материал отдельным действием`,
        });
      }
      const itemFabricPrice = this.localItem.itemFabricPrices[index];
      if (itemFabricPrice.isNewItem) {
        this.localItem.itemFabricPrices.splice(index, 1);
      } else {
        itemFabricPrice.deleted = deleteFabric;
      }
    },
    updateFromProp(item) {
      const value = this.creatingItem ? newItem : item || this.item;
      this.localItem = cloneDeep(value);
    },
    openWindow(url) {
      if (!url) {
        this.$notifyError({ title: 'файл артикула не загружен' });
        return;
      }
      window.open(getLink(url, true), '_blank');
    },
    getMockupLink(url) {
      if (!url) return null;
      return getLink(url);
    },
  },
};
</script>
<style lang="scss" scoped>
.item {
  &-alert-deleted {
    background-color: $color-red;
    padding: 8px 0px;
    border-radius: 8px;
    text-align: center;
    margin-bottom: 10px;

    @include InterSemibold;
  }

  &-title-block {
    display: flex;
    flex-direction: column;

    &__title {
      @include InterSemibold;
      font-size: 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    &__article {
      @include InterSemibold;
    }
    &__subtitle {
      display: flex;
      flex-direction: row;
      color: $color-gray;
      font-size: 12px;
    }
  }
  &-mockups {
    display: flex;
    flex-direction: row;
    padding: 20px 0px;
    img {
      height: 250px;
    }
  }
  &-details {
    display: flex;
    flex-direction: column;

    .item-details_block {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 36px;
      margin-bottom: 10px;
      align-items: flex-end;
    }
    &__desc {
      height: fit-content;
    }
  }
  .item-details__desc {
    max-width: 100%;
  }
  &-item-fabrics {
    &__block {
      display: grid;
      grid-template-columns: 230px 1fr;
      margin-top: 8px;
      align-items: flex-end;
    }

    &__prices {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-end;
      label {
        width: 170px;
        margin-left: 10px;
        // width: fit-content;
      }
    }
  }
}
.validation-error-items {
  color: $color-red;
  margin-left: 15px;
}
.no-image {
  display: flex;
  justify-content: center;
  align-self: center;
  min-width: 170px;

  color: $color-bg-smoke-active;
}
</style>
