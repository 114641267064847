<template>
  <div class="block-wrapper">
    <div v-if="localOrderCategory" class="orderCategory">
      <div v-if="localOrderCategory.deleted" class="order-categoryalert-deleted">
        <p>
          Категория не доступен для выбора в существующих заказах, но все еще используется в уже
          созданных заказах
        </p>
      </div>
      <div v-if="orderCategory" class="order-categorytitle-block mb-5">
        <div class="order-categorytitle-block__title">
          Категория:&nbsp;
          <span class="a-bold"> {{ localOrderCategory.id }}. </span> &emsp;{{
            localOrderCategory.name
          }}
        </div>
      </div>
      <div v-else class="order-categorytitle-block">
        <div class="order-categorytitle-block__title">Новая категория заказа</div>
      </div>
      <div class="order-category-details">
        <div class="order-category-details_block">
          <AInput
            v-model="localOrderCategory.name"
            :read-only="!accessToChange"
            label="Название"
            :error="getErrMess('name')"
          />
        </div>
        <div class="order-category-details_block mt-15">
          <AButton
            v-if="accessToChange"
            success
            :disabled="!islocalOrderCategoryModified"
            @click="saveOrderCategory"
          >
            Сохранить
          </AButton>
          <AButton
            v-if="accessToChange"
            :disabled="!islocalOrderCategoryModified"
            @click="updateFromProp()"
          >
            Сбросить
          </AButton>
          <AButton
            v-if="
              (isAdmin || isMainEmployee || isMainManager) &&
              !creatingOrderCategory &&
              !orderCategoryLoading
            "
            :disabled="islocalOrderCategoryModified"
            :danger="!localOrderCategory.deleted"
            :success="localOrderCategory.deleted"
            @click="deleteItemHandler(!localOrderCategory.deleted)"
          >
            {{ localOrderCategory.deleted ? 'Восстановить' : 'Удалить' }}
          </AButton>
        </div>
        <ALoader v-if="orderCategoryLoading" centered green />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { cloneDeep, isEqual } from '../../../utils/lodash.js';
import accessMixin from '../../../mixin/accessMixin.js';
import { orderCategoryBlockSchema } from '../../../utils/validate/index.js';
import validateMixin from '../../../mixin/validate.js';
import ConfirmDialog from '@@/components/Confirm/index.vue';

const newOrderCategoryItem = {
  id: null,
  name: null,
  deleted: false,
};

export default {
  name: 'OrderCategoryBlock',
  mixins: [accessMixin, validateMixin],
  props: {
    orderCategory: {
      type: Object,
      default: null,
    },
    creatingOrderCategory: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  data() {
    return {
      orderCategoryLoading: false,

      localOrderCategory: null,
    };
  },
  computed: {
    accessToChange() {
      return (
        (this.isCanChangeProduction || this.isCanChangeOrder) &&
        !this.localOrderCategory.deleted &&
        !this.orderCategoryLoading
      );
    },
    islocalOrderCategoryModified() {
      return !isEqual(this.orderCategory, this.localOrderCategory);
    },
  },
  watch: {
    orderCategory: function (orderCategory) {
      this.updateFromProp(orderCategory);
    },
  },
  created() {
    this.updateFromProp();
  },
  methods: {
    ...mapActions({
      changeOrderCategory: 'order/saveOrderCategory',
    }),

    async saveOrderCategory() {
      const formValidation = this.$form(this.localOrderCategory, orderCategoryBlockSchema);
      if (!formValidation.isValid) {
        this.$notifyError({ title: `Ошибка валидации формы` });
        return;
      }
      this.orderCategoryLoading = true;
      try {
        await this.changeOrderCategory({ orderCategory: this.localOrderCategory });
        this.$emit('close');
        this.$notify({ title: 'Сохранено' });
      } catch (err) {
        console.log(err);
      } finally {
        this.orderCategoryLoading = false;
      }
    },
    deleteItemHandler(needDeleted) {
      if (this.islocalOrderCategoryModified) {
        this.$notifyError({
          title: `Сбросте (или сохраните) изменения, а затем удалите его`,
        });
        return;
      }
      this.$dialog.addDialog({
        component: ConfirmDialog,
        props: {
          title: needDeleted ? 'Удаление категории' : 'Восстановление категории',
          text: `Вы действительно хотите ${needDeleted ? 'удалить' : 'восстановить'} категорию?`,
          onConfirm: (confirm) => {
            confirm.showModal = false;
            this.localOrderCategory.deleted = needDeleted;
            this.saveOrderCategory(true);
          },
        },
      });
    },
    updateFromProp(orderCategory) {
      const value = this.creatingOrderCategory
        ? newOrderCategoryItem
        : orderCategory || this.orderCategory;
      this.localOrderCategory = cloneDeep(value);
    },
  },
};
</script>
<style lang="scss" scoped>
.order-category {
  &-alert-deleted {
    background-color: $color-red;
    padding: 8px 0px;
    border-radius: 8px;
    text-align: center;
    margin-bottom: 10px;

    @include InterSemibold;
  }

  &-title-block {
    display: flex;
    flex-direction: column;

    &__title {
      @include InterSemibold;
      font-size: 16px;
    }
  }
  &-details {
    display: flex;
    flex-direction: column;
    .order-categoryitem-orderCategory {
      display: grid;
      grid-template-columns: 1fr 1fr 100px;
      grid-gap: 20px;
      margin-bottom: 10px;
      align-items: flex-end;
    }

    .order-category-details_block {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 36px;
      margin-bottom: 10px;
      align-items: flex-end;
    }
  }
}
.validation-error-items {
  color: $color-red;
  margin-left: 15px;
}
</style>
