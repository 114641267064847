import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import minMax from 'dayjs/plugin/minMax';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.locale('ru');
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(minMax);
dayjs.extend(customParseFormat);

const formateDate = (date, format = 'DD.MM.YYYY', nulleble = null) => {
  return date ? dayjs(date).format(format) : nulleble;
};

const getDateOffset = (date = new Date(), offsetDays = 0, format = 'DD.MM.YYYY') => {
  return dayjs(new Date(date)).add(offsetDays, 'day').format(format);
};

export { dayjs, getDateOffset, formateDate };
