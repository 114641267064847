import { ORDER_CATEGORY_GROUP } from '../../constants';
import { formateDate } from '../../utils/date';

export function useMonitoringPlanFactPackaging() {
  const koefValues = {
    noComplexityQuantity: 0.1,
    easyQuantity: 0.8,
    mediumQuantity: 1,
    hardQuantity: 2,
    veryHardQuantity: 6,
  };

  const getQuantityWithCoef = (item) => {
    const complexities = Object.keys(koefValues);
    let total = 0;
    for (const key of complexities) {
      const koef = koefValues[key];
      total += koef * item[key];
    }
    return parseFloat(total.toFixed(2));
  };

  const replaceLastInArray = (arr, newLastValue) => {
    arr.splice(arr.length - 1, 1, newLastValue);
  };

  function formatDataForCharts(data) {
    const xAxis = [];
    const barProjects = [];
    const barProjectsMoney = [];
    const barProjectsClient = [];
    const line = [];

    const barProjectsKoef = [];
    const barProjectsMoneyKoef = [];
    const barProjectsClientKoef = [];
    const lineWithKoef = [];

    for (const item of data) {
      const date = formateDate(item.packingDate, 'DD.MM');
      if (!xAxis.includes(date)) {
        barProjects.push(0);
        barProjectsMoney.push(0);
        barProjectsClient.push(0);
        line.push(0);

        barProjectsKoef.push(0);
        barProjectsMoneyKoef.push(0);
        barProjectsClientKoef.push(0);
        lineWithKoef.push(0);

        xAxis.push(date);
      }
      if (!item.category) {
        continue;
      }
      switch (item.category) {
        case ORDER_CATEGORY_GROUP.PROJECT:
          {
            replaceLastInArray(barProjects, item.allQuantity);
            replaceLastInArray(barProjectsKoef, getQuantityWithCoef(item));
          }
          break;
        case ORDER_CATEGORY_GROUP.PROJECT_MONEY:
          {
            replaceLastInArray(barProjectsMoney, item.allQuantity);
            replaceLastInArray(barProjectsMoneyKoef, getQuantityWithCoef(item));
          }
          break;
        case ORDER_CATEGORY_GROUP.CLIENTS:
          {
            replaceLastInArray(barProjectsClient, item.allQuantity);
            replaceLastInArray(barProjectsClientKoef, getQuantityWithCoef(item));
          }
          break;
        case ORDER_CATEGORY_GROUP.ALL_PACKAGED:
          {
            replaceLastInArray(line, item.allQuantity);
            replaceLastInArray(lineWithKoef, getQuantityWithCoef(item));
          }
          break;
      }
    }

    const accBarProjects = barProjects.reduce((acc, curr, index) => {
      acc.push((acc[index - 1] || 0) + curr);
      return acc;
    }, []);

    const accBarProjectsMoney = barProjectsMoney.reduce((acc, curr, index) => {
      acc.push((acc[index - 1] || 0) + curr);
      return acc;
    }, []);

    const accBarProjectsClient = barProjectsClient.reduce((acc, curr, index) => {
      acc.push((acc[index - 1] || 0) + curr);
      return acc;
    }, []);

    const accLine = line.reduce((acc, curr, index) => {
      acc.push((acc[index - 1] || 0) + curr);
      return acc;
    }, []);

    return {
      xAxis,
      planAbs: {
        datasets: [
          {
            label: 'Клиентские',
            data: barProjectsClient,
            backgroundColor: 'rgb(207,226,243)',
          },
          {
            label: 'Проектные денежные',
            data: barProjectsMoney,
            backgroundColor: 'rgb(182,215,168)',
          },
          {
            label: 'Проектные',
            data: barProjects,
            backgroundColor: 'rgb(255,229,153)',
          },
        ],
        lineDataset: {
          label: 'Упаковано по мониторингу',
          data: line,
          backgroundColor: 'rgb(74,134,232)',
        },
      },
      planAbsWithKoef: {
        datasets: [
          {
            label: 'Клиентские',
            data: barProjectsClientKoef,
            backgroundColor: 'rgb(207,226,243)',
          },
          {
            label: 'Проектные денежные',
            data: barProjectsMoneyKoef,
            backgroundColor: 'rgb(182,215,168)',
          },

          {
            label: 'Проектные',
            data: barProjectsKoef,
            backgroundColor: 'rgb(255,229,153)',
          },
        ],
        lineDataset: {
          label: 'Упаковано по мониторингу',
          data: lineWithKoef,
          backgroundColor: 'rgb(74,134,232)',
        },
      },
      accPlan: {
        datasets: [
          {
            label: 'Клиентские',
            data: accBarProjectsClient,
            backgroundColor: 'rgb(207,226,243)',
          },
          {
            label: 'Проектные денежные',
            data: accBarProjectsMoney,
            backgroundColor: 'rgb(182,215,168)',
          },
          {
            label: 'Проектные',
            data: accBarProjects,
            backgroundColor: 'rgb(255,229,153)',
          },
        ],
        lineDataset: {
          label: 'Упаковано по мониторингу',
          data: accLine,
          backgroundColor: 'rgb(74,134,232)',
        },
      },
    };
  }

  return { formatDataForCharts };
}
