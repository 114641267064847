<template>
  <div
    class="a-datepicker"
    :class="{
      disabled,
      error,
      labeled: label,
      greyish: !white,
      readonly,
      selected: date || placeholder,
      withoutIcon,
    }"
    :key="`a-datepicker-${uuid}`"
  >
    <!-- <v-date-picker
      v-model="date"
      :mode="mode"
      :is24hr="mode == 'dateTime'"
      :masks="masks"
      :popover="{ visibility: 'focus' }"
    >
      <template v-slot="{ inputValue, inputEvents }">
        <div class="a-datepicker__el">
          <input
            :id="`a-datepicker-${uuid}`"
            :value="inputValue"
            class="a-input-el"
            v-on="inputEvents"
            :readonly="readonly"
            :disabled="disabled"
            :placeholder="$attrs.placeholder || ''"
          />
          <label :for="`a-datepicker-${uuid}`" class="a-datepicker__label">
            {{ label }}
          </label>
        </div>
      </template>
    </v-date-picker> -->
    <!-- <VueDatePicker v-model="date" :popover="{ visibility: 'focus' }">a
    </VueDatePicker> -->

    <div class="a-datepicker__el">
      <!-- <input
        :type="mode === 'dateTime' ? 'datetime-local' : 'date'"
        v-model="date"
        placeholder=""
      /> -->
      <VueDatePicker
        v-model="getModuleDate"
        locale="ru"
        select-text="Выбрать"
        cancel-text="Отмена"
        now-button-label="Сейчас"
        :placeholder="getPlaceHolder"
        :range="range"
        :disabled="disabled"
        :format="formatLabel"
        :uid="`a-datepicker-${uuid}`"
        :teleport="true"
        :auto-apply="autoApply"
        :month-picker="monthPicker"
        :enable-time-picker="enableTimePicker"
      >
        <template v-if="withoutIcon" #input-icon />
      </VueDatePicker>
      <label :for="`dp-input-a-datepicker-${uuid}`" class="a-datepicker__label">
        {{ label }}
      </label>
    </div>

    <div
      class="a-datepicker__error"
      :class="{ 'a-datepicker__error--active': error }"
    >
      <div class="a-datepicker__error-icon" />
      {{ error }}
    </div>
  </div>
</template>

<script>
import uuid from "../../mixin/uuid.js";
import { dayjs } from "@/utils/date.js";
import { isEqual } from "../../utils/lodash.js";
const mask = "YYYY.MM.DD";

export default {
  name: "ADatePicker",
  mixins: [uuid],
  emits: ["input", "change", "update:modelValue"],
  props: {
    label: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [Array, String, Date, Object],
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    white: {
      type: Boolean,
      default: false,
    },
    fixedPopover: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "dateTime",
    },
    format: {
      type: String,
      default: mask,
    },
    withoutIcon: {
      type: Boolean,
      default: false,
    },
    range: {
      type: Boolean,
      default: false,
    },
    autoApply: {
      type: Boolean,
      default: false,
    },
    monthPicker: {
      type: Boolean,
      default: false,
    },
    enableTimePicker: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      date: this.getDate(this.modelValue),
      masks: {
        input: "YYYY-MM-DD",
        inputDateTime: mask,
        inputDateTime24hr: "YYYY-MM-DD HH:mm",
        inputTime: mask,
        inputTime24hr: mask,
        data: mask,
        iso: mask,
      },
    };
  },
  computed: {
    getPlaceHolder() {
      return this.placeholder
        ? dayjs(this.placeholder).isValid()
          ? `${dayjs(this.placeholder).format(this.format)}`
          : this.placeholder
        : null;
    },
    getModuleDate: {
      get() {
        const date = this.date;
        if (!date || (this.range && !date?.length)) {
          return null;
        }
        if (this.monthPicker) {
          if (this.range) {
            return [
              {
                month: dayjs(date[0]).get("month"),
                year: dayjs(date[0]).get("year"),
              },
              {
                month: dayjs(date[1]).get("month"),
                year: dayjs(date[1]).get("year"),
              },
            ];
          }
          return {
            month: dayjs(date).get("month"),
            year: dayjs(date).get("year"),
          };
        }
        if (this.range) {
          return [
            dayjs(date[0]).format(this.format),
            dayjs(date[1] ? date[1] : date[0]).format(this.format),
          ];
        }
        return dayjs(date).format(this.format);
      },
      set(val) {
        this.date = this.getDate(val);
      },
    },
  },
  methods: {
    getDate(date) {
      if (!date || (this.range && !date?.length)) {
        return null;
      }
      if (this.monthPicker) {
        if (this.range) {
          return [
            this.getBaseDateFromMonthObj(date[0]),
            this.getBaseDateFromMonthObj(date[1] || date[0], true),
          ];
        }
        return this.getBaseDateFromMonthObj(date);
      }
      if (this.range) {
        return [
          dayjs(date[0]).format(this.format),
          dayjs(date[1] ? date[1] : date[0]).format(this.format),
        ];
      }
      return dayjs(date).format(this.format);
    },
    getBaseDateFromMonthObj(date, setLastDate = false) {
      return typeof date === "object"
        ? setLastDate
          ? dayjs(`${date.year}/${date.month + 1}`)
              .endOf("month")
              .format(this.format)
          : dayjs(`${date.year}/${date.month + 1}`).format(this.format)
        : date;
    },

    formatLabel(date) {
      if (this.monthPicker) {
        if (this.range) {
          const format = "MM.YYYY";
          return `${dayjs(date[0]).format(format)} ${
            date[1] ? ` ÷ ${dayjs(date[1]).format(format)}` : ""
          }
       `;
        }
        return dayjs(date).format("MM.YYYY");
      }
      if (this.range) {
        const needSkipYear =
          this.format.toLowerCase().includes("yy") &&
          date[1] &&
          dayjs(date[0]).isSame(date[1], "year");
        const format = needSkipYear
          ? this.format.replace(/-?(YY|YYYY)?-/, "")
          : this.format;
        return `${dayjs(date[0]).format(format)} ${
          date[1] ? ` ÷ ${dayjs(date[1]).format(format)}` : ""
        }
       `;
      }
      return dayjs(date).format(this.format);
    },
  },
  watch: {
    date(updDate) {
      const value = this.getDate(updDate);
      this.$emit("input", value);
      this.$emit("change", value);
      this.$emit("update:modelValue", value);
    },
    modelValue(updDate) {
      if (isEqual(this.date, updDate)) {
        return;
      }
      this.date = this.getDate(updDate);
    },
  },
};
</script>

<style lang="scss" scoped>
// input[type="datetime-local"]:in-range::-webkit-datetime-edit-year-field,
// input[type="datetime-local"]:in-range::-webkit-datetime-edit-month-field,
// input[type="datetime-local"]:in-range::-webkit-datetime-edit-day-field,
// input[type="datetime-local"]:in-range::-webkit-datetime-edit-text {
//   color: transparent;
// }
// input[type="date"]:in-range::-webkit-datetime-edit-year-field,
// input[type="date"]:in-range::-webkit-datetime-edit-month-field,
// input[type="date"]:in-range::-webkit-datetime-edit-day-field,
// input[type="date"]:in-range::-webkit-datetime-edit-text {
//   color: transparent;
// }
.a-datepicker {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: left;
  position: relative;
  justify-content: flex-end;

  &.labeled {
    padding-top: 20px;
    // margin-top: 20px;
  }
  &.disabled,
  &.readonly {
    pointer-events: none;
  }
  &:not(.withoutIcon) {
    .a-datepicker__label {
      padding-left: 25px;
    }
  }

  &__label {
    @include InterSemibold;
    position: absolute;
    bottom: 30%;
    left: 10px;
    transition: bottom 0.2s, font-size 0.2s;
    color: $color-dark-gray;
    font-size: 14px;
    z-index: 1;
  }
  &:not(.readonly):not(.disabled) {
    .a-datepicker__label:focus {
      bottom: 110%;
      font-size: 12px;
    }
    .a-datepicker__label:hover {
      cursor: pointer;
    }
  }
  &.selected {
    .a-datepicker__label {
      bottom: 110%;
      font-size: 12px;
    }
  }
  &__el {
    position: relative;

    display: inline-block;
    width: 100%;
  }

  // input.a-input-el,
  // input {
  //   position: relative;

  //   display: inline-block;

  //   color: $color-black-gray;
  //   @include InterSemibold;
  //   font-size: 14px;

  //   box-sizing: border-box;
  //   line-height: 40px;
  //   height: 40px;
  //   max-width: 100%;
  //   width: 100%;

  //   overflow: hidden;

  //   background-color: white;
  //   border: none;
  //   border-radius: 6px;
  //   outline: 1px solid transparent;

  //   padding: 13px 15px 13px 15px;

  //   transition: all 0.15s ease-in-out;
  //   -webkit-appearance: none;
  //   &::placeholder,
  //   .disabled & {
  //     color: $color-gray;
  //   }
  // }
  &.greyish {
    input {
      border: none;
      box-shadow: none;
      background-color: $color-gray-bg;
    }
  }
  &.readonly {
    input {
      border: dashed 1px $color-light-gray;
      background-color: $color-white;
    }

    &.greyish &__el &:hover {
      background-color: $color-white;
    }
  }
  &.greyish {
    .a-datepicker {
      &__el:hover {
        background-color: $color-white;
      }
    }
  }

  &.disabled {
    input.a-input-el,
    input {
      background-color: $color-gray-bg;
      opacity: 0.6;
    }
  }
  &.error {
    input.a-input-el,
    input[readonly].a-input-el,
    input {
      color: $color-red;
      border-color: $color-red;
      padding-right: 36px;
    }
  }

  &__error {
    @include input-error(".a-datepicker");
  }
}

// .a-datepicker__label {
//   @include input-label(".a-datepicker");
// }
</style>
<style lang="scss">
input.dp__input {
  height: 40px;
}
.a-datepicker.withoutIcon input.dp__input_icon_pad {
  padding-left: 10px;
}
// .a-datepicker.fixedPopover {
//   .vc-popover-content-wrapper {
//     position: fixed !important;
//   }
// }
</style>
