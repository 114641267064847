export function getClassNameByTextLength(str = '') {
  const length = str?.length || 0;
  if (length < 50) {
    return {};
  }
  if (length < 150) {
    return { 'text-xl': true };
  }
  return { 'text-xxl': true };
}
