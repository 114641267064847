<template>
  <Application />
</template>

<script>
import Application from '@/components/application';
export default {
  name: 'ApplicationPage',
  components: { Application },
};
</script>
