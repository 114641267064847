<template>
  <div>
    <APageAside title="Фильтры" wide @close="closeHandler">
      <div v-if="showAsideFilters">
        <div>
          <div class="mb-40">
            <div class="filter-block">
              <ASelect
                v-model="localFilters.currentManagerIds"
                :options="managers"
                :select-label-fn="getUserName"
                track-key="guid"
                label="Менеджер"
                :readonly="loading"
                disable-deleted
                multiple
              />
              <ASelect
                v-model="localFilters.techDesignerIds"
                :options="designers"
                :select-label-fn="getUserName"
                track-key="guid"
                label="Дизайнер"
                :readonly="loading"
                disable-deleted
                multiple
              />
            </div>
            <ASpacer text="Дата отгрузки" text-default />
            <div class="filter-block">
              <ADatePicker
                :model-value="filters.deadline.dateRange"
                mode="date"
                without-icon
                range
                :readonly="loading"
                label="Дата отгрузки. Интервал - День"
                @change="updFilters($event, 'deadline', 'dateRange')"
              />
              <ADatePicker
                :model-value="filters.deadline.monthRange"
                mode="date"
                without-icon
                range
                month-picker
                :readonly="loading"
                label="Дата отгрузки. Интервал - Месяц"
                @change="updFilters($event, 'deadline', 'monthRange')"
              />
            </div>
            <div class="filter-block">
              <AToggle
                :model-value="filters.deadline.currentMonthWithDebt"
                mode="date"
                without-icon
                :readonly="loading"
                label="Текущий мес. + незавершенные"
                @change="updFilters($event, 'deadline', 'currentMonthWithDebt')"
              />
            </div>
            <ASpacer
              text="Дата производства | постановки задачи | создание карт"
              text-default
              class="mt-15 mb-15"
            />
            <div class="filter-block">
              <ADatePicker
                :model-value="filters.createdCardDate.dateRange"
                mode="date"
                without-icon
                range
                :readonly="loading"
                label="Дата создания карт. Интервал - День"
                @change="updFilters($event, 'createdCardDate', 'dateRange')"
              />
              <ADatePicker
                :model-value="filters.createdCardDate.monthRange"
                mode="date"
                without-icon
                range
                month-picker
                :readonly="loading"
                label="Дата создания карт. Интервал - Месяц"
                @change="updFilters($event, 'createdCardDate', 'monthRange')"
              />
            </div>
            <ASpacer text="Дата упаковки" text-default class="mt-15 mb-15" />
            <div class="filter-block">
              <ADatePicker
                :model-value="filters.packingDate.dateRange"
                mode="date"
                without-icon
                range
                :readonly="loading"
                label="Дата упаковки. Интервал - День"
                @change="updFilters($event, 'packingDate', 'dateRange')"
              />
              <ADatePicker
                :model-value="filters.packingDate.monthRange"
                mode="date"
                without-icon
                range
                month-picker
                :readonly="loading"
                label="Дата упаковки. Интервал - Месяц"
                @change="updFilters($event, 'packingDate', 'monthRange')"
              />
            </div>
            <ASpacer text="Тиффы" text-default class="mt-15 mb-15" />
            <div class="filter-block">
              <ADatePicker
                :model-value="filters.techDesignDoneDate.dateRange"
                mode="date"
                without-icon
                range
                :readonly="loading"
                label="Тиффы. Интервал - День"
                @change="updFilters($event, 'techDesignDoneDate', 'dateRange')"
              />
              <ADatePicker
                :model-value="filters.techDesignDoneDate.monthRange"
                mode="date"
                without-icon
                range
                month-picker
                :readonly="loading"
                label="Тиффы. Интервал - Месяц"
                @change="updFilters($event, 'techDesignDoneDate', 'monthRange')"
              />
            </div>

            <ASpacer text="Теги" text-default class="mt-15 mb-15" />
            <div class="filter-block">
              <ASelect
                :model-value="filters.tag.include"
                :options="orderTags"
                select-label="name"
                label="Только выбранные теги"
                :disabled="loading"
                multiple
                @change="updFilters($event, 'tag', 'include')"
              />
              <ASelect
                :model-value="filters.tag.exclude"
                :options="orderTags"
                select-label="name"
                label="Исключить теги"
                :disabled="loading"
                multiple
                @change="updFilters($event, 'tag', 'exclude')"
              />
            </div>
            <ASpacer text="Категории" text-default class="mt-15 mb-15" />
            <div class="filter-block">
              <ASelect
                :model-value="filters.category.include"
                :options="orderCategory"
                select-label="name"
                label="Только выбранные категории"
                :disabled="loading"
                multiple
                @change="updFilters($event, 'category', 'include')"
              />
              <ASelect
                :model-value="filters.category.exclude"
                :options="orderCategory"
                select-label="name"
                label="Исключить категории"
                :disabled="loading"
                multiple
                @change="updFilters($event, 'category', 'exclude')"
              />
            </div>
            <ASpacer text="Статус заказа" text-default class="mt-15 mb-15" />

            <div class="filter-block">
              <ASelect
                :model-value="filters.orderState.include"
                :options="preparedOrderStateList"
                select-label="name"
                label="Только выбранные статусы"
                track-key="id"
                :disabled="loading"
                multiple
                @change="updFilters($event, 'orderState', 'include')"
              />

              <ASelect
                :model-value="filters.orderState.exclude"
                :options="preparedOrderStateList"
                select-label="name"
                label="Исключить статус"
                track-key="id"
                :disabled="loading"
                multiple
                @change="updFilters($event, 'orderState', 'exclude')"
              />
            </div>
            <ASpacer text=" " text-default class="mt-15 mb-15" />
            <div class="filter-block">
              <AInput
                v-model="localFilters.timeToProduction"
                type="number"
                :min="1"
                :readonly="loading"
                label="Время на производство, дн. <="
              />
            </div>

            <div class="mt-40">
              <div class="filter-block">
                <AButton :disabled="loading" @click="resetFilters"> Сбросить фильтры </AButton>
                <AButton
                  v-if="!canUseAlreadyDownloadedOrders"
                  success
                  :disabled="loading || !isSelectedDate || !isFilterDirty"
                  @click="searchHandler"
                >
                  поиск
                </AButton>
              </div>
            </div>
          </div>
          <ASpacer text="Настройка отображения" class="mb-40" />
          <div>
            <div class="filter-block">
              <ASelect
                v-model="localColumnSetting"
                :options="displayCustomizationDictionary.columnSetting"
                track-key="key"
                select-label="label"
                label="Исключить столбцы"
                :readonly="loading"
                disable-deleted
                multiple
              />
            </div>
          </div>
        </div>
      </div>
    </APageAside>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { ORDER_STATE, ORDER_STATE_DICTIONARY } from '@/constants';
import { cloneDeep } from '@/utils/lodash';
import { getUserName } from '@/utils/string';
import { notifyError } from '@/utils/notify';
import { INIT_FILTERS } from '../productionTableDictionary';
import { isEqual } from '../../../utils/lodash';

export default {
  name: 'ProductionTableAsideFilter',
  props: {
    showAsideFilters: {
      type: Boolean,
      default: false,
    },
    orderTags: {
      type: Array,
      default: () => [],
    },
    orderCategory: {
      type: Array,
      default: () => [],
    },
    displayCustomization: {
      type: Object,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    canUseAlreadyDownloadedOrders: {
      type: Boolean,
      default: false,
    },
    displayCustomizationDictionary: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'close',
    'update:filters',
    'update:deadline',
    'update:createdCardDate',
    'update:techDesignDoneDate',
    'update:tag',
    'update:category',
    'update:orderState',
    'update:displayCustomization',
    'searchOrders',
    'resetFilters',
  ],
  setup(props, { emit }) {
    const store = useStore();

    const managers = store.state.user.managers;
    const designers = store.state.user.employees.filter((u) => u.departmentIds.includes(1));
    const appliedFilters = computed(() => store.state.productionTable.filters);

    const preparedOrderStateList = Object.values(ORDER_STATE).map((s) => ({
      id: s,
      name: ORDER_STATE_DICTIONARY[s],
    }));

    const initFilters = cloneDeep(INIT_FILTERS);
    const localFilters = computed(() => props.filters);

    const localColumnSetting = computed({
      get() {
        return props.displayCustomization.columnSetting;
      },
      set(newValue) {
        emit(
          'update:displayCustomization',
          Object.assign(props.displayCustomization, { columnSetting: newValue })
        );
      },
    });

    const isNotEmptyFilter = (v) => (Array.isArray(v) ? !!v.length : !!v);
    const updFilters = (value, field, key = null) => {
      const nestObj = {
        ...(isNotEmptyFilter(value)
          ? cloneDeep(initFilters[field])
          : cloneDeep(props.filters[field])),
        [key]: value,
      };
      emit(`update:${field}`, nestObj);
    };

    const isSelectedDate = computed(
      () => Object.values(props.filters.deadline).filter((_) => _).length
    );
    const isFilterDirty = computed(() => !isEqual(props.filters, appliedFilters.value));

    const getUserNameMod = (v) => getUserName(v, null);
    const searchHandler = () => emit('searchOrders');
    const closeHandler = () => {
      if (!isSelectedDate.value) {
        return notifyError({ title: 'Необходимо выбрать дату' });
      }
      emit('close');
    };
    const resetFilters = () => emit('resetFilters');

    return {
      managers,
      designers,
      preparedOrderStateList,

      localColumnSetting,

      localFilters,
      isSelectedDate,
      isFilterDirty,

      searchHandler,

      updFilters,

      getUserName: getUserNameMod,

      resetFilters,
      closeHandler,
    };
  },
};
</script>
<style lang="scss" scoped>
.filter-block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 36px;
  margin-bottom: 20px;
  align-items: flex-end;
}
</style>
