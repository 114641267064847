<template>
  <div>
    <APageAside
      :title="orderTag ? 'Редактирование тега заказа ' : 'Создание тега'"
      wide
      @close="closeHandler"
    >
      <div v-if="orderTag || creatingOrderTag">
        <OrderTagBlock
          :order-tag="orderTag"
          :creating-order-tag="creatingOrderTag"
          @close="closeHandler"
          @update:user="$emit('update:orderTag', $event)"
        />
      </div>
    </APageAside>
  </div>
</template>

<script>
import OrderTagBlock from './OrderTagBlock.vue';

export default {
  name: 'CreateOrderTagsAsideBlock',
  components: {
    OrderTagBlock,
  },
  props: {
    orderTag: {
      type: Object,
      default: null,
    },
    creatingOrderTag: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'update:orderTag'],
  methods: {
    closeHandler() {
      this.$emit('close');
    },
  },
};
</script>
