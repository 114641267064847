<template>
  <transition-group
    name="list"
    tag="ul"
    class="a-tags-list"
    :class="{ readOnly }"
  >
    <li
      v-for="(item, idx) in tags"
      :key="`tag-${idx}`"
      class="a-tags-list__tab"
    >
      <div class="a-tags-list__container">
        <span v-if="labelPrefix" class="a-tags-list__label-prefix">
          {{ item[labelPrefix] }}
        </span>
        <span :key="`idx`" class="a-tags-list__label">
          <!-- {{ labelPrefix }} {{ selectLabel ? item[selectLabel] : item }} -->
          {{ getLabel(item) }}
        </span>
        <button
          v-if="!readOnly"
          title="удалить"
          class="a-tags-list__btn"
          @click.stop="$emit('remove', item)"
        />
      </div>
    </li>
  </transition-group>
</template>

<script>
export default {
  name: "ASelectTagsList",
  props: {
    tags: {
      type: Array,
      required: true,
    },
    selectLabel: {
      type: String,
      default: null,
    },
    selectLabelFn: {
      type: Function,
      default: null,
    },
    labelPrefix: {
      type: String,
      default: null,
    },
    trackKey: {
      type: String,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: null,
    },
    modelOnlyKey: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    // getLabel() {
    //   return (item) => {
    //     let label = this.labelPrefix ? `${this.labelPrefix}` : "";

    //     if (this.selectLabel) {
    //       if (this.modelOnlyKey) {
    //         label += this.options.find((i) => i[this.trackKey] == item)?.[
    //           this.selectLabel
    //         ];
    //       } else {
    //         label += item[this.selectLabel];
    //       }
    //     } else {
    //       label += item;
    //     }
    //     return label;
    //   };
    // },
    getLabel() {
      return (item) => {
        let label = this.labelPrefix ? `${this.labelPrefix}` : "";

        if (this.selectLabelFn) {
          if (this.modelOnlyKey) {
            // TODO проверить
            label += this.selectLabelFn(
              this.options.find((i) => i[this.trackKey] == item)
            );
          } else {
            label += this.selectLabelFn(item);
          }
        } else if (this.selectLabel) {
          if (this.modelOnlyKey) {
            label += this.options.find((i) => i[this.trackKey] == item)?.[
              this.selectLabel
            ];
          } else {
            label += item[this.selectLabel];
          }
        } else {
          label += item;
        }
        return label;
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.a-tags-list {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  width: 100%;
  box-sizing: border-box;

  border: 1px solid $color-geyser;
  border-radius: 4px;

  list-style-type: none;
  margin-top: 0;
  margin-bottom: 0;
  padding: 1px;

  &__tab {
    display: flex;
    align-items: flex-start;
    flex-grow: 0;

    border: 1px solid $color-geyser;
    border-radius: 4px;
    background-color: $color-catskill-white;

    box-sizing: border-box;
    // padding: 9px 12px 8px 12px;
    margin: 1px;
  }

  &__container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    padding: 1px 12px 0px 12px;
    margin: 0;
  }

  &__label {
    display: inline-block;

    @include InterSemibold;
    line-height: 0.7;
    padding: 9px 0px;

    &-prefix {
      display: flex;
      flex-direction: row;
      align-self: center;

      box-sizing: border-box;
      height: 16px;
      background-color: $color-gray;
      border-radius: 4px;

      padding-left: 5px;
      padding-right: 5px;

      margin-right: 5px;

      font-size: 12px;
      line-height: 16px;
    }
  }

  &__btn {
    display: flex;
    flex-direction: row;
    align-self: center;

    position: relative;

    background: none;
    border: none;

    width: 28px;
    height: 30px;

    padding: 9px 0px;
    margin-top: -9px;
    margin-bottom: -10px;
    margin-right: -13px;

    cursor: pointer;

    &::before,
    &::after {
      content: "";

      position: absolute;
      top: 50%;
      left: 50%;

      display: inline-block;
      width: 10px;
      height: 2px;

      background-color: $color-botticelly;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
  &.readOnly {
    .a-tags-list {
      &__container {
        background-color: $color-white;
        border: 1px dashed $color-light-gray;
      }
    }
  }
}
</style>

<style lang="scss">
.a-select.opened .a-tags-list {
  border-bottom: none;
  border-color: $color-dodger-blue;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.a-tags-list {
  .disabled & {
    pointer-events: none;
    background-color: $color-gray;
  }

  .loading & {
    pointer-events: none;
    background-color: $color-gray;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    padding-right: 36px;
  }
}
</style>
