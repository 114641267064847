<template>
  <div class="icon-cover" :class="{ active, [shadow]: shadow }">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'IconCover',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: String,
      default: null,
    },
  },
};
</script>
<style lang="scss" scoped>
$height: 22px;
.icon-cover {
  width: $height;
  height: $height;
  border: 1.2px solid $color-light-gray;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  user-select: none;
  &.active {
    &:hover {
      cursor: pointer;
    }
    &:active {
      transform: scale(0.96);
      box-shadow: inset 0px -1px 2px $color-white-smoke;
    }
  }

  &.l1 {
    box-shadow: 0px 0px 4px 2px $color-red;
  }
  &.l2 {
    box-shadow: 0px 0px 5px 2px $color-wanring;
  }
  &.l3 {
    box-shadow: 0px 0px 4px 2px $color-dodger-blue;
  }
}
</style>
