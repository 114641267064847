import { v4 as uuidv4 } from 'uuid';
import { cloneDeep } from '@/utils/lodash.js';
import { CURRENCY, ORDER_PARAM_VALUE_TYPE, ORDER_STATE } from '../../../constants/index';
import { patchOrderParams, pathOrder } from '../../../utils/order';

const SELECTED_ORDER = 'selectedOrder';
const NEW_ORDER = 'newOrder';

const newPlayer = {
  fio: null,
  sexId: null,
  guid: null,
  index: null,
  number: null,
  comment: null,
  orderId: null,
  createdAt: null,
  lettering: null,
};

const newPlayerProduct = {
  id: null,
  size: null,
  comment: null,
  playerId: null,
  quantity: null,
  productId: null,
};

const newProduct = {
  details: {},
  guid: null,
  color: null,
  index: null,
  kitId: null,
  price: null,
  design: { mockup: {} },
  isFree: false,
  comment: null,
  deleted: false,
  orderId: null,
  clientId: null,
  defPrice: null,
  designId: null,
  fabricId: null,
  filePath: null,
  itemId: null,
  managerId: null,
  localIndex: null,
  discountFix: 0,
  productImage: null,
  paramsCloverId: null,
  techDesignerId: null,
  discountPercent: null,
  paramsProfileId: null,
  paramsSizeBoxId: null,
  paramsPackagingId: null,
  paramsPressSizeId: null,
  paramsOrderPackagingId: null,
  managerDesign: {
    back: null,
    front: null,
    plain: null,
  },
};

const newKit = {
  guid: null,
  index: null,
  price: null,
  setId: null,
  isFree: false,
  deleted: false,
  orderId: null,
  clientId: null,
  defPrice: null,
  discountFix: null,
  discountPercent: null,
};

const newOrder = {
  guid: null,
  name: null,
  currentManagerId: null,
  clientId: null,
  deadline: null,
  state: ORDER_STATE.NEW,
  currency: CURRENCY.RUR,
  discountFix: null,
  discountPercent: null,
  paid: null,
  teamId: null,
  teamName: null,
  amoId: null,
  important: false,
  products: [],
  players: [],
  playerProducts: [],
  kits: [],
  createdAt: null,
  orderParams: {},

  _old: {
    state: ORDER_STATE.NEW,
  },
};

export default {
  SET_SELECTED_ORDER(state, data) {
    const mappedPlayerProducts = selectOrderPlayerProductsMapper(data);
    data.playerProducts = mappedPlayerProducts;
    data._old = {
      state: data.state,
    };
    state.selectedOrder = data;
  },

  SET_ALL_ORDERS(state, { offset, data }) {
    if (offset) {
      state.allOrders.push(...data);
      return;
    }
    state.allOrders = data;
  },

  SET_ORDER_LOADING(state, { key, data }) {
    state[key] = data;
  },
  setOrderField(state, { selectedOrder = false, key, data }) {
    const field = selectedOrder ? 'selectedOrder' : 'newOrder';
    state[field][key] = data;
  },
  setDiscounts(state, { selectedOrder = false, isKit, index, localIndex, key, data }) {
    const field = selectedOrder ? 'selectedOrder' : 'newOrder';
    const type = isKit ? 'kits' : 'products';
    state[field][type].forEach((el) => {
      if (isKit ? el.index == index : el.index == index && el.localIndex == localIndex) {
        el[key] = data;
      }
    });
  },
  setAllProductionParams(state, { selectedOrder, data }) {
    const field = selectedOrder ? 'selectedOrder' : 'newOrder';
    state[field].products.forEach((el) => {
      for (const key in data) {
        const value = data[key];
        el[key] = value;
      }
    });
  },
  setProductionParams(state, { selectedOrder, index, localIndex, key, value }) {
    const field = selectedOrder ? 'selectedOrder' : 'newOrder';
    state[field].products.forEach((product) => {
      if (product.index === index && product.localIndex === localIndex) {
        product[key] = value;
      }
    });
  },

  prepareNewOrder(state) {
    state.newOrder = cloneDeep({ ...newOrder });
  },

  setOrderInfo(state, { selectedOrder, data = {} }) {
    const key = selectedOrder ? 'selectedOrderInfo' : 'newOrderInfo';
    state[key].user = data.user || null;
    state[key].teams = data.teams || [];
    if (!selectedOrder) {
      state.newOrder[`clientId`] = data?.user?.guid || null;
    }
  },

  setClientBlockUser(state, { user, isSelectedOrder }) {
    if (isSelectedOrder) {
      state.selectedOrder.user = cloneDeep(user);
      return;
    }
    state.newOrderInfo.user = cloneDeep(user);
  },

  setXlImportData(
    state,
    { selectedOrder, data = { xlImportErrors: [], players: [], playerProducts: [] } }
  ) {
    const key = selectedOrder ? 'selectedOrder' : 'newOrder';
    state[`${key}XlImportErrors`] = [...data.xlImportErrors];
    state[key] = { ...state[key], players: data.players, playerProducts: data.playerProducts };
  },

  resetOrder(state, selectedOrder = true) {
    const key = selectedOrder ? 'selectedOrder' : 'newOrder';
    let order;
    const orderInfo = cloneDeep({
      user: null,
      teams: [],
      orderErrors: {},
    });
    if (selectedOrder) {
      order = null;
      state.selectedOrderXlImportErrors = [];
    } else {
      order = cloneDeep({ ...newOrder });
      state.newOrderXlImportErrors = [];
    }
    state[key] = order;
    state[`${key}Info`] = orderInfo;
  },

  addPlayer(state, { selectedOrder }) {
    const order = state[selectedOrder ? SELECTED_ORDER : NEW_ORDER];
    const index = getNewIndex(order.players);
    order.players.push({
      ...newPlayer,
      index,
      orderId: order.guid || null,
    });
    order.playerProducts.push(
      order.products.map((product) => ({
        ...newPlayerProduct,
        productId: product.guid,
      }))
    );
  },

  deletePlayer(state, { selectedOrder, playerInd }) {
    const order = state[selectedOrder ? SELECTED_ORDER : NEW_ORDER];
    order.players.splice(playerInd, 1);
    order.playerProducts.splice(playerInd, 1);
  },

  updatePlayer(state, { playerInd, selectedOrder, data, key }) {
    const order = state[selectedOrder ? SELECTED_ORDER : NEW_ORDER];
    order.players[playerInd][key] = data;
    if (key === 'sexId') {
      order.playerProducts[playerInd].forEach((pp) => {
        pp.quantity = 0;
        pp.size = null;
      });
    }
  },

  ADD_ITEM(state, { selectedOrder, ...itemInfo }) {
    const order = state[selectedOrder ? SELECTED_ORDER : NEW_ORDER];
    const index = getNewIndex(order.products);

    const addingProduct = cloneDeep({
      ...newProduct,
      ...itemInfo,
      orderId: order.guid,
      clientId: order.clientId,
      managerId: order.managerId,
      index,
    });
    order.products.push(addingProduct);
    order.playerProducts.forEach((productPlayerArr) => {
      return productPlayerArr.push({
        ...newPlayerProduct,
        playerId: productPlayerArr[0]?.playerId,
      });
    });
  },

  ADD_SET(state, { selectedOrder, set, items }) {
    const order = state[selectedOrder ? SELECTED_ORDER : NEW_ORDER];
    const setIndex = getNewIndex(order.kits);
    const itemIndex = getNewIndex(order.products);
    const kitId = uuidv4();

    const addingKit = cloneDeep({
      ...newKit,
      ...set,
      guid: kitId,
      newItem: true,
      orderId: order.guid,
      clientId: order.clientId,
      index: setIndex,
    });
    const addingItems = items.map((item, localIndex) =>
      cloneDeep({
        ...newProduct,
        ...item,
        kitId,
        orderId: order.guid,
        clientId: order.clientId,
        managerId: order.managerId,
        index: itemIndex,
        localIndex: localIndex + 1,
      })
    );
    order.kits.push(addingKit);
    order.products.push(...addingItems);
    order.playerProducts.forEach((productPlayerArr) =>
      productPlayerArr.push(
        // TODO: chack map((_)=>)
        ...new Array(addingItems.length).fill(null).map(() => ({
          ...newPlayerProduct,
          playerId: productPlayerArr[0]?.playerId,
        }))
      )
    );
  },

  updatePlayerProducts(state, { productInd, playerInd, selectedOrder, data, key }) {
    const order = state[selectedOrder ? SELECTED_ORDER : NEW_ORDER];
    if (key === 'quantity' && order.products[productInd].localIndex) {
      order.products.forEach((product, ind) => {
        if (product.index === order.products[productInd].index) {
          order.playerProducts[playerInd][ind][key] = data;
        }
      });
      return;
    }
    order.playerProducts[playerInd][productInd][key] = data;
  },

  DELETE_PRODUCT(state, { selectedOrder, isKit, index }) {
    const order = state[selectedOrder ? SELECTED_ORDER : NEW_ORDER];
    let startIndex, delConunt;
    if (isKit) {
      const kitId = order.kits.find((k) => k.index === index).guid;
      delConunt = order.products.filter((p) => p.kitId === kitId).length;
      startIndex = order.products.findIndex((p) => p.kitId === kitId);
    } else {
      startIndex = order.products.findIndex((p) => p.index === index);
      delConunt = 1;
    }
    order.products.splice(startIndex, delConunt);
    order.playerProducts.forEach((plairArr) => plairArr.splice(startIndex, delConunt));
    if (isKit) order.kits = order.kits.filter((k) => k.index != index);
  },

  SET_VALIDATE_ERRORS(state, { selectedOrder, ...data }) {
    const orderInfo = state[`${selectedOrder ? SELECTED_ORDER : NEW_ORDER}Info`];
    orderInfo.orderErrors = data;
  },
  PATCH_ORDER_PARAMS(state, data) {
    if (state.selectedOrder?.guid === data.orderId) {
      patchOrderParams(state.selectedOrder.orderParams, data.changedOrderParams);
    }
  },
  PATCH_ORDER(state, data) {
    if (state.selectedOrder?.guid === data.guid) {
      pathOrder(state.selectedOrder, data);
      pathOrder(state.selectedOrder._old, data);
    }

    for (const order of state.allOrders) {
      if (order.guid === data.guid) {
        pathOrder(order, data);
        return;
      }
    }
  },
  DELETE_ORDER(state, data) {
    if (state.selectedOrder?.guid === data.guid) {
      console.log('DELETE_ORDER', this);
      this.commit('order/resetOrder', true);
    }

    for (let i = 0; i < state.allOrders.length; i++) {
      const order = state.allOrders[i];
      if (order.guid === data.guid) {
        state.allOrders.splice(i, 1);
        return;
      }
    }
  },

  SET_ALL_ORDER_TAGS(state, data) {
    state.orderTags = data;
  },

  SAVE_ORDER_TAG(state, { orderTag, oldOlderTag }) {
    if (!orderTag) {
      const index = state.orderTags.findIndex((ordTag) => ordTag.id === oldOlderTag.id);
      state.orderTags.splice(index, 1);
      return;
    }
    const index = state.orderTags.findIndex((i) => i.id === orderTag.id);
    if (index === -1) {
      state.orderTags = [orderTag, ...state.orderTags];
    } else {
      state.orderTags.splice(index, 1, orderTag);
    }
  },

  SET_ORDER_PARAMS(state, { selectedOrder = false, value, orderParamsType }) {
    const field = selectedOrder ? 'selectedOrder' : 'newOrder';
    const orderParamField = state[field].orderParams[orderParamsType];
    if (ORDER_PARAM_VALUE_TYPE.SIMPLE_VALUE.includes(orderParamsType)) {
      if (orderParamField) {
        orderParamField[0].data.value = value;
      } else {
        state[field].orderParams[orderParamsType] = [{ data: { value } }];
      }
    }
    if (ORDER_PARAM_VALUE_TYPE.SINGLE_RELATION.includes(orderParamsType)) {
      if (orderParamField) {
        orderParamField[0].entityId = value;
      } else {
        state[field].orderParams[orderParamsType] = [{ entityId: value, data: { value: null } }];
      }
    }
    if (ORDER_PARAM_VALUE_TYPE.MULTIPLE_RELATIONS.includes(orderParamsType)) {
      if (orderParamField) {
        if (!value.length) {
          state[field].orderParams[orderParamsType] = [];
          return;
        }

        for (const entityId of value) {
          const ind = orderParamField.findIndex((p) => String(p.entityId) === String(entityId));
          if (ind > -1) {
            continue;
          }
          orderParamField.push({ entityId, data: { value: null } });
        }
        const existsValues = orderParamField.map((e) => e.entityId);
        for (const entityId of existsValues) {
          const ind = value.findIndex(
            (valueEntityId) => String(valueEntityId) === String(entityId)
          );
          if (ind === -1) {
            orderParamField.splice(ind, 1);
          }
        }
      } else {
        state[field].orderParams[orderParamsType] = [];
        for (const newEntityId of value) {
          state[field].orderParams[orderParamsType].push({
            entityId: newEntityId,
            data: { value: null },
          });
        }
      }
    }
  },

  SET_ALL_ORDER_CATEGORY(state, data) {
    state.orderCategory = data;
  },

  SAVE_ORDER_CATEGORY(state, { orderCategory, oldOlderCategory }) {
    if (!orderCategory) {
      const index = state.orderCategory.findIndex((ordTag) => ordTag.id === oldOlderCategory.id);
      state.orderCategory.splice(index, 1);
      return;
    }
    const index = state.orderCategory.findIndex((i) => i.id === orderCategory.id);
    if (index === -1) {
      state.orderCategory = [orderCategory, ...state.orderCategory];
    } else {
      state.orderCategory.splice(index, 1, orderCategory);
    }
  },
};

// синхронизировать с функцией на бэке
function selectOrderPlayerProductsMapper(order) {
  const orderedProducts = order.products.map((_) => _.guid);
  const orderedPlayers = order.players.map((_) => _.guid);
  const resultArr = new Array(orderedPlayers.length)
    .fill('')
    // TODO: check map((_)=>)
    .map(() => new Array(orderedProducts.length).fill(undefined));
  for (let i = 0; i < order.playerProducts.length; i++) {
    const playerProduct = order.playerProducts[i];
    const x = orderedProducts.indexOf(playerProduct.productId);
    const y = orderedPlayers.indexOf(playerProduct.playerId);
    resultArr[y][x] = playerProduct;
  }
  return resultArr.map((_, y) =>
    _.map(
      (__, x) =>
        __ || {
          id: null,
          size: null,
          comment: null,
          playerId: orderedPlayers[y],
          quantity: null,
          productId: orderedProducts[x],
        }
    )
  );
}

/**
 * Подготавливает новый индекс для изделий/комплектов
 * @param {Array|null} arr
 */
function getNewIndex(arr) {
  return arr?.length ? Math.max(...arr.map((_) => _.index)) + 1 : 1;
}
