<template>
  <div class="block-separator" :class="{ column }">
    <slot />
  </div>
</template>

<script>
export default {
  name: "BlockSeparator",
  props: {
    column: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
// .block-separator {
//   display: grid;
//   grid-template-columns: 1fr;
//   grid-gap: $block-separation-margin;

//   width: 100%;
//   height: 100%;

//   &.column {
//     grid-template-columns: 1fr 1fr;
//   }
// }

// .block-separator > .block-separator {
//   grid-gap: $small-block-separation-margin;
// }
.block-separator {
  display: flex;
  flex-direction: column;
  grid-gap: $block-separation-margin;

  width: 100%;
  max-height: 100%;

  &.column {
    flex-direction: row;
  }
}

.block-separator > .block-separator {
  grid-gap: $small-block-separation-margin;
}
</style>
