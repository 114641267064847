<template>
  <div v-click-away="hide">
    <div
      :ref="`a-tooltip-${uuid}-item`"
      class="button"
      aria-describedby="tooltip"
    >
      <slot name="activator" />
    </div>
    <div :ref="`a-tooltip-${uuid}-content`" class="tooltip" role="tooltip">
      <slot />
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import uuid from "../../mixin/uuid.js";

export default {
  name: "Dropdown",
  mixins: [uuid],
  emits: ["update:show"],
  props: {
    qtitle: {
      type: String,
      default: null,
    },
    position: {
      type: String,
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      styleToltip: {
        position: null,
      },
      button: null,
      tooltip: null,
      popperInstance: null,

      showDropdown: false,
    };
  },
  mounted() {
    this.button = this.$refs[`a-tooltip-${this.uuid}-item`];
    this.tooltip = this.$refs[`a-tooltip-${this.uuid}-content`];
    this.initPopover();
  },
  watch: {
    show(value) {
      if (!value) {
        this.hide();
      }
    },
  },
  methods: {
    initPopover() {
      const options = {
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 0],
            },
          },
        ],
      };
      if (this.position) options.placement = this.position;
      this.popperInstance = createPopper(this.button, this.tooltip, options);

      const show = () => {
        // Make the tooltip visible
        this.tooltip.setAttribute("data-show", "");

        // Enable the event listeners
        this.popperInstance.setOptions((options) => ({
          ...options,
          modifiers: [
            ...options.modifiers,
            { name: "eventListeners", enabled: true },
          ],
        }));

        // Update its position
        this.popperInstance.update();
        this.$emit("update:show", true);
      };

      const showEvents = ["click", "focus"];
      // const hideEvents = ["blur"];

      showEvents.forEach((event) => {
        this.button.addEventListener(event, show);
      });

      // hideEvents.forEach((event) => {
      //   button.addEventListener(event, hide);
      // });
    },
    hide() {
      // Hide the tooltip
      this.tooltip.removeAttribute("data-show");

      // Disable the event listeners
      this.popperInstance.setOptions((options) => ({
        ...options,
        modifiers: [
          ...options.modifiers,
          { name: "eventListeners", enabled: false },
        ],
      }));
      this.showDropdown = false;
      this.$emit("update:show", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  position: relative;
  display: block;
  padding: 0;
  margin: 0;
  // color: $color-light-gray;
}
.tooltip {
  // background: $color-bg-smoke;
  // color: $smoke-blue;
  // font-weight: bold;

  // font-size: 13px;
  // border-radius: 4px;
  padding: 0;
  margin: 0;
  display: block;
  visibility: hidden;
  opacity: 0;

  // max-width: min-content;
  // &__content {
  //   padding: 4px 8px;
  // }
  transition: opacity 0.15s ease-out;

  z-index: 100;
}

.tooltip[data-show] {
  display: block;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s ease-out;
}
</style>
