<template>
  <label
    :for="uuid"
    class="a-input"
    :class="{
      error,
      disabled,
      textarea,
      greyish: !white,
      labeled: label,
      readOnly,
    }"
  >
    <div class="a-input__el-wrapper">
      <textarea
        v-if="textarea"
        ref="input"
        v-model="data"
        :type="$attrs.type || 'text'"
        autocomplete="off"
        v-bind="$attrs"
        :placeholder="!label ? $attrs.placeholder : ''"
        :tabindex="disabled ? -1 : 0"
        class="a-input__el"
        :disabled="disabled"
        :readonly="disabled || readOnly"
        v-on="inputListeners"
        @blur="$emit('change', data)"
        :id="`a-input-${uuid}`"
      />
      <input
        v-else
        v-model="data"
        :type="$attrs.type || 'text'"
        autocomplete="off"
        v-bind="$attrs"
        :placeholder="!label ? $attrs.placeholder : ''"
        :tabindex="disabled ? -1 : 0"
        class="a-input__el"
        :disabled="disabled"
        :readonly="disabled || readOnly"
        v-on="inputListeners"
        @blur="$emit('change', data)"
        :id="`a-input-${uuid}`"
        :pattern="integer ? '\d*' : null"
      />
      <div v-if="$attrs.type == 'number'" class="a-input__arrows">
        <span
          v-show="max === null || data < max"
          class="a-input__up"
          @click="changeData('up')"
        />
        <span
          v-show="min === null || data > min"
          class="a-input__down"
          @click="changeData('down')"
        />
      </div>
      <label
        v-if="label"
        :for="`a-input-${uuid}`"
        class="a-input__label"
        :style="inputOntop"
      >
        {{ label }}
      </label>
    </div>
    <span class="a-input__error">
      <div class="a-input__error-icon" />
      {{ error }}
    </span>
  </label>
</template>

<script>
import uuid from "../../mixin/uuid.js";

export default {
  name: "AInput",
  mixins: [uuid],
  emits: ["update:modelValue", "input", "change", "blur", "keyup"],
  props: {
    modelValue: {
      type: [String, Number],
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    textarea: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    white: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    integer: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      data: null,
    };
  },
  computed: {
    inputListeners: function () {
      return Object.assign({}, this.$attrs, {
        input: (event) => {
          this.updateFromProp(event.target.value);
          this.$emit("input", this.getValue(this.data));
        },
        change: (event) => {
          this.updateFromProp(event.target.value);
          this.$emit("change", this.getValue(this.data));
        },
        // TODO протестить
        keyup: (event) => {
          if (!this.textarea) return;
          this.updateFromProp(event.target.value);
          this.$emit("keyup", event);
        },
        // TODO починить, чтобы после блюра значение при type==number != ''
        // blur: (event) => {
        //   this.updateFromProp(event.target.value);
        //   this.$emit("blur", this.getValue(this.data));
        // },
        // keyup: (event) => {
        //   this.updateFromProp(event.target.value);
        //   if (event.keyCode === this.$ENTER_KEYCODE) {
        //     this.$emit("keyup", this.getValue(this.data));
        //   }
        // },
      });
    },
    inputOntop() {
      if (![null, ""].includes(this.data) && this.label) {
        return {
          bottom: "110" + "%",
          "font-size": "12px",
        };
      }
      return {};
    },
  },
  watch: {
    modelValue(newValue) {
      this.updateFromProp(newValue);
    },
    data() {
      this.$emit("update:modelValue", this.getValue(this.data));
    },
  },
  created() {
    this.$ENTER_KEYCODE = 13;
  },
  mounted() {
    this.updateFromProp(this.modelValue);
  },
  methods: {
    getValue(value) {
      if (this.$attrs.type === "number") {
        if (!Number.isNaN(parseFloat(value))) {
          return parseFloat(value);
        }
        if (!value) {
          return null;
        }
      }
      return value;
    },
    updateFromProp(value) {
      if (this.min !== null && typeof value === "number" && value < this.min) {
        this.data = this.min;
      } else if (this.max !== null && value > this.max) {
        this.data = +this.max;
      } else {
        this.data = value;
      }
    },
    changeData(action) {
      if (action === "up") {
        this.max === null || this.data < this.max ? this.data++ : null;
      } else {
        this.min === null || this.data > this.min ? this.data-- : null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.a-input {
  display: flex;
  flex-direction: column;
  position: relative;

  max-width: 100%;
  width: 100%;

  margin-bottom: 0;
  &.labeled {
    margin-top: 20px;
  }

  @include InterSemibold;
  font-size: 14px;

  &:not(.readOnly):not(.disabled) {
    input:focus ~ .a-input__label {
      bottom: 110%;
      font-size: 12px;
    }
  }
  &__label {
    position: absolute;
    bottom: 30%;
    z-index: 1;
    left: 10px;
    transition: bottom 0.2s, font-size 0.2s;
    color: $color-dark-gray;
    font-size: 14px;
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.6;
    .a-input__label {
      color: $color-gray;
    }
  }
  &.error {
    .a-input__label {
      bottom: 110%;
    }
  }
  &__el {
    position: relative;

    display: inline-block;

    color: $color-black;

    box-sizing: border-box;
    height: 40px;
    max-width: 100%;
    width: 100%;

    overflow: hidden;

    line-height: 40px;
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;

    border: none;
    border-color: transparent;
    border-radius: 6px;
    outline: none;

    padding: 13px 15px 13px 15px;

    transition: all 0.15s ease-in-out;
    will-change: border-color, color, padding;

    -webkit-appearance: none;
    -moz-appearance: textfield;

    .a-input.textarea & {
      line-height: 1.35;
      height: 120px;
      max-width: 100%;
      overflow-y: scroll;

      @include scroll-wide;
      resize: none;
    }

    .a-input.greyish & {
      background-color: $color-gray-bg;
    }

    &::placeholder,
    .disabled & {
      color: $color-gray;
    }
    .a-input__arrows {
      display: none;
    }
  }
  &[type="number"]:active {
    .a-input__up:active,
    .a-input__down:active {
      background-color: $color-gray;
    }
  }
  &[type="number"]:hover {
    position: relative;

    @include noSelect;

    .a-input {
      &__arrows {
        display: flex;
        position: relative;
      }
      &__up,
      &__down {
        position: absolute;
        cursor: pointer;

        width: 8px;
        height: 8px;

        right: 8px;

        background-image: url(./assets/input-arrow.svg);
        background-repeat: no-repeat;
        background-size: contain;
      }
      &__up {
        bottom: 25px;

        transform: rotate(270deg);
        -webkit-transform: rotate(270deg);
      }
      &__down {
        bottom: 8px;

        transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
      }
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &.readOnly {
    .a-input {
      &__el {
        border: dashed 1px $color-light-gray;
        background-color: $color-white;
      }
    }
  }
}
.a-input__error {
  @include input-error(".a-input");
}

.a-input__caption {
  @include input-caption(".a-input");
}
</style>
