<template>
  <div>
    <APageAside
      :title="selectedSet ? 'Просмотр комплекта' : 'Создание комплекта'"
      wide
      @close="closeHandler"
    >
      <div v-if="selectedSet || creatingSet">
        <SetBlock :set="selectedSet" :creating-set="creatingSet" @close="closeHandler" />
      </div>
    </APageAside>
  </div>
</template>

<script>
import SetBlock from '@/components/assortment/setAsideBlock/compontns/setBlock.vue';

export default {
  name: 'SetAsideBlock',
  components: {
    SetBlock,
  },
  props: {
    selectedSet: {
      type: Object,
      default: null,
    },
    creatingSet: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    closeHandler() {
      this.$emit('close');
      return;
    },
  },
};
</script>
<style lang="scss" scoped>
.confirm-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
</style>
