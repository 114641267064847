<template>
  <Users />
</template>

<script>
import Users from '@/components/users';
export default {
  name: 'UsersPage',
  components: { Users },
};
</script>
