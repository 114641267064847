<template>
  <div v-if="card && order">
    <div class="card">
      <div class="card__header">
        <!-- SYNC MOBILE APP -->
        <div>
          <div>Партия</div>
          <div class="bold">{{ card.index }}/{{ order.cardsQuantity }}</div>
        </div>
        <div>
          <div>Клевер</div>
          <div>
            {{ productionParamsDictionary.paramsClover[card.product.paramsCloverId]?.name }}
          </div>
        </div>
        <div>
          <div>Вшив. разм.</div>
          <div>
            {{ productionParamsDictionary.paramsSizeBox[card.product.paramsSizeBoxId]?.name }}
          </div>
        </div>
        <div>
          <div>Разм.пресс</div>
          <div>
            {{ productionParamsDictionary.paramsPressSize[card.product.paramsPressSizeId]?.name }}
          </div>
        </div>
        <div>
          <div>Упаковка изделий</div>
          <div>
            {{ productionParamsDictionary.paramsPackaging[card.product.paramsPackagingId]?.name }}
          </div>
        </div>
        <div>
          <div>Упаковка заказа</div>
          <div>
            {{
              productionParamsDictionary.paramsOrderPackaging[card.product.paramsOrderPackagingId]
                ?.name
            }}
          </div>
        </div>
        <div>
          <div>Материал</div>
          <div class="bold">
            {{ assortmentDictionary.fabrics[card.product.fabricId]?.name }}
          </div>
        </div>
      </div>
      <div class="card__title">
        {{ assortmentDictionary.productTypes[card.product.item.productTypeId]?.name }}.
        {{ assortmentDictionary.categories[card.product.item.categoryId]?.name }}.
        {{ card.product.color }}
        ( Артикул
        {{ assortmentDictionary.articles[card.product.item.articleId]?.code }} :
        {{ assortmentDictionary.articles[card.product.item.articleId]?.name }} )
      </div>
      <div class="card__summary">
        <div class="card__image">
          <div class="card__images-cover">
            <div
              v-if="getMockup.front || getMockup.back"
              class="pair"
              :class="{ 'two-image': !!getMockup.back }"
            >
              <img v-if="getMockup.front" :src="getLink(getMockup.front)" alt="перед" />
              <img v-if="getMockup.back" :src="getLink(getMockup.back)" alt="спинка" />
            </div>
            <div v-else class="plain">
              <img :src="getLink(getMockup.plain)" alt="раскладка" />
            </div>
            <div v-if="articleMockup.plain" class="plain">
              <img :src="getLink(articleMockup.plain)" alt="раскладка" />
            </div>
          </div>

          <div>
            Профиль :
            {{ productionParamsDictionary.paramsProfile[card.product.paramsProfileId]?.name }}
          </div>
        </div>
        <div class="card__summary-wrapper">
          <div class="card__summary-table">
            <div v-for="(values, sexId) of getSummary" :key="sexId" class="summary-column">
              <div class="header">{{ getSex(sexId) }}</div>
              <div v-for="(quantity, size) of values" :key="`${size}_${sexId}`" class="row">
                <div>{{ size }}</div>
                <div>{{ quantity }}</div>
              </div>
              <div class="wrapper">
                <div />
                <div class="sum">{{ getSumInSex(values) }}</div>
              </div>
            </div>
          </div>
          <div></div>

          <div>Общее кол-во: {{ card.players.length }}</div>
        </div>
      </div>
      <div class="card__path">Макет: {{ card.product.filePath }}</div>
      <div>amoCRM: {{ order.amoId }}</div>
      <div class="card__players">
        <table>
          <tbody>
            <tr>
              <td class="index">№</td>
              <td class="size">Разм.</td>
              <td class="sex">Пол</td>
              <td class="lettering">Надпись</td>
              <td class="number">Номер</td>
              <td class="empty">Печать</td>
              <td class="empty">Перен.</td>
              <td class="empty">Крой</td>
              <td class="empty">Пошив</td>
              <td class="empty">ВТО</td>
              <td>Комментарии</td>
            </tr>
            <tr
              v-for="(player, index) of card.players"
              :key="`${player.playerId}_${index}_${player.playerProductId}`"
            >
              <td class="index">{{ index + 1 }}</td>
              <td class="size">{{ player.size }}</td>
              <td class="sex">{{ getSex(player.sexId) }}</td>
              <td class="lettering">{{ player.lettering }}</td>
              <td class="number">{{ player.number }}</td>
              <td class="empty"></td>
              <td class="empty"></td>
              <td class="empty"></td>
              <td class="empty"></td>
              <td class="empty"></td>
              <td>{{ player.comment }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card__card-info">
        <div class="card__order">
          <div class="name">
            <div class="header">Название заказа</div>
            <div class="body" :class="getOrderNameClass">{{ order.name }}</div>
          </div>
          <div class="deadline">
            <div class="header">Срок исполнения</div>
            <div v-if="order.deadline" class="body">
              <div
                v-if="order.postponedDeadline"
                class="postponedDeadline-date"
                :class="getDeadlineClasses"
              >
                {{ dayjs(order.postponedDeadline).format('DD MMM') }}
              </div>
              <div class="deadline-date" :class="getDeadlineClasses">
                {{ dayjs(order.deadline).format('DD MMM') }}
              </div>
            </div>
          </div>
          <div class="cards">
            <div class="header">Партия</div>
            <div class="body">{{ card.index }}/{{ order.cardsQuantity }}</div>
          </div>
        </div>
        <div class="qr-code">
          <img v-if="showQrCode" :src="imgUrl" width="290" height="290" />
          <div v-else class="qr-code-test">Тестовая карта</div>
        </div>
      </div>
      <div class="card__footer">
        <div>Менеджер: {{ getManagerName }}</div>
        <div>Тел: {{ order.manager.contacts.phone }}</div>
        <div>
          Карта составлена: {{ dayjs().format('DD.MM.YYYY HH:mm:ss') }} (V.
          {{ dayjs(card.createdAt).format('DD.MM HH:mm') }})
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcode';
import { mapState } from 'vuex';
import { getLink } from '../../../utils/getStorageLink';
import { dayjs } from '../../../utils/date';
import { getClassNameByTextLength } from '@/utils/text.js';
import { getUserName } from '@/utils/string.js';
import { ORDER_STATE } from '../../../constants';

export default {
  name: 'CardBlock',
  props: {
    card: {
      type: Object,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      imgUrl: null,
    };
  },
  computed: {
    ...mapState({
      assortmentDictionary: (s) => s.dictionary.assortmentDictionary,
      productionParamsDictionary: (s) => s.dictionary.productionParams,
    }),
    showQrCode() {
      return [ORDER_STATE.TECH_DESIGN, ORDER_STATE.PRODUCTION, ORDER_STATE.COMPLETED].includes(
        this.order.state
      );
    },
    getSummary() {
      const blocks = {};
      for (const player of this.card.players) {
        if (!blocks[player.sexId]) blocks[player.sexId] = {};
        blocks[player.sexId][player.size] = ++blocks[player.sexId][player.size] || 1;
      }
      return blocks;
    },
    getSumInSex() {
      return (values) => Object.values(values).reduce((s, i) => s + i, 0);
    },
    getSex() {
      return (sexId) => {
        const sex = this.assortmentDictionary.sex[sexId]?.name || '-';
        if (sex?.length > 6) {
          return `${sex.slice(0, 6)}.`;
        }
        return sex;
      };
    },
    getMockup() {
      return this.card.product.design.mockup;
    },
    articleMockup() {
      return this.card.product.article.mockup;
    },
    getOrderNameClass() {
      const text = this.order?.name || '';
      return getClassNameByTextLength(text);
    },
    getDeadlineClasses() {
      const classes = {};
      if (this.order.postponedDeadline) {
        classes['deadline-small'] = true;
        classes['deadline-expired'] = true;
      }
      if (dayjs(this.order.deadline).isSameOrBefore(new Date(), 'day')) {
        classes['deadline-expired'] = true;
      }
      return classes;
    },
    getManagerName() {
      return getUserName(this.order.manager);
    },
  },
  watch: {
    'card.guid': async function (newValue, oldValue) {
      if (!oldValue || !newValue || newValue === oldValue) return;
      this.getQrCodeData();
    },
  },
  async created() {
    this.dayjs = dayjs;
    this.getLink = getLink;
    this.getQrCodeData();
  },
  methods: {
    async getQrCodeData() {
      this.imgUrl = await QRCode.toDataURL(`${this.card.orderId}_${this.card.guid}`, {
        errorCorrectionLevel: 'H',
      });
    },
  },
};
</script>
<style scoped lang="scss">
$cardWidth: 1132px;
$cardHeight: 1594px;
$scaleCard: var(--scalec);
$border-color: rgb(158, 158, 158);

.card {
  display: grid;
  grid-template-rows: 80px 45px 390px 20px 20px 1fr 305px 20px;
  grid-gap: 0px;
  border: 1px dashed $border-color;
  width: $cardWidth;
  height: $cardHeight;

  page-break-before: always;
  page-break-after: always;

  // transform: scale($scaleCard) translateX(calc(calc($cardWidth * $scaleCard) * (-1)))
  //   translateY(calc(calc($cardHeight * $scaleCard) * (-1)));

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    div {
      display: grid;
      grid-template-rows: 20px 1fr;

      width: 100%;
      height: 100%;

      border-right: 1px dashed $border-color;
      border-top: 1px dashed $border-color;
      border-bottom: 1px dashed $border-color;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
      :nth-child(2) {
        font-size: 22px;
      }
      .bold {
        font-size: 28px;
        font-weight: 600;
      }
    }
  }
  &__title {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    height: 100%;
    font-size: 18px;
    word-wrap: break-all;
  }

  &__summary {
    display: grid;
    grid-template-columns: 1.75fr 0.25fr;
  }
  &__image {
    display: flex;
    flex-direction: column;

    .card__images-cover {
      display: flex;
      flex-direction: row;
    }
    img {
      height: 370px;
      max-width: calc(100% - 20px);
    }
    .two-image {
      img {
        max-width: calc(50% - 10px);
      }
    }
  }
  &__summary-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0px 0px 10px 0px;
  }
  &__summary-table {
    display: flex;
    flex-direction: row;
    border: 1px dashed $border-color;
    width: fit-content;
    margin: 10px 0px;
    flex-grow: 3;

    .summary-column {
      display: flex;
      flex-direction: column;

      &:not(:last-child) {
        border-right: 1px solid $border-color;
      }
    }

    .header {
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      border-bottom: 1px dashed $border-color;
      padding: 5px 0px;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 3;

      .sum {
        text-align: center;
        font-weight: 600;
        font-size: 16px;
        border-top: 1px dashed $border-color;
      }
    }
    .row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      div {
        text-align: center;
        padding: 1px 10px;
        &:first-child {
          border-right: 1px solid $border-color;
        }
      }
    }
  }
  &__players {
    table {
      border-collapse: collapse;
      width: 100%;
    }
    tr {
      line-height: 24px;
    }
    td {
      font-size: 16px;
      border: 1px solid $border-color;
      text-align: center;

      &.index {
        width: 20px;
      }
      &.empty {
        width: 30px;
      }
      &.size {
        width: 60px;
      }
      &.sex {
        width: 70px;
      }
      &.lettering {
        min-width: 130px;
      }
      &.number {
        width: 90px;
      }
    }
  }

  &__card-info {
    display: grid;
    // grid-template-columns: 1fr 300px;
    grid-template-columns: 1fr 300px;
    // align-items: flex-end;
    // justify-content: flex-start;
    // background-color: red;
  }
  &__order {
    display: grid;
    div {
      display: grid;
      grid-template-rows: 25px 1fr;
      text-align: center;
      .header {
        border: 1px solid $border-color;
      }
      .body {
        border: 1px solid $border-color;
        display: flex;
        justify-content: center;
        align-items: center;

        font-size: 40px;
        font-weight: 600;
        word-break: break-all;
        hyphens: auto;
        &.text-xl {
          font-size: 34px;
        }
        &.text-xxl {
          font-size: 28px;
        }
      }
    }
    .name {
      grid-row: 1;
      grid-column: span 2;
    }
    .deadline {
      grid-row: row 1;
      .body {
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      .postponedDeadline-date {
        min-height: 50px;
        color: $color-red;
      }
      .deadline-date {
        &.deadline-expired {
          color: $color-red;
        }
        &.deadline-small {
          font-size: 24px;
          text-decoration: line-through;
        }
        &:not(.deadline-small) {
          min-height: 50px;
        }
      }
    }
    .cards {
      grid-row: row 1;
    }
  }

  .qr-code-test {
    display: flex;
    height: 100%;
    align-items: center;
    text-align: center;
    font-size: 55px;
    font-weight: 500;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid $border-color;
  }
}
</style>
