<template>
  <APageLayout>
    <template #header>
      <div class="page-header">
        <div class="page-header__container">
          <div>Производство</div>
          <div class="mr-20">
            <AToggle
              v-model="showDeletedEntity"
              label="Показать удаленные сущности"
              class="toggle"
            />
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <div v-if="!loading" class="content-wrapper production-page-content-wrapper">
        <APageBlock class="mb-20" toggle>
          <template #header> Отделы производства </template>
          <div class="content">
            <div class="table-filter">
              <AInput v-model.trim="tableFilters.departments" placeholder="Фильтр" />
            </div>
            <ATable
              :items="departmentsPagination.chunkedItems"
              :fields="departmentFields"
              class="department-table mb-20"
              :loading="loading"
              :row-classes="(row) => departmentTableRowClasses(row)"
              :row-click="(row) => (selecteDepartment = row)"
            >
              <template #isActive="{ item }">
                <ABadge :red="!item.isActive">
                  {{ item.isActive ? 'Активен' : 'Неактивен' }}
                </ABadge>
              </template>
            </ATable>
            <APagination
              v-model:currentPage="departmentsPagination.currentPage"
              v-model:currentOffset="departmentsPagination.offset"
              :items="filtredDepartments"
              :all="filtredDepartments.length"
              :loading="loading"
              :offsets="[10, 20, 50, 0]"
              mode="local"
              @changeOffset="departmentsPagination.offset = $event"
              @update:chunkedItems="departmentsPagination.chunkedItems = $event"
            />
            <div>
              <AButton
                v-if="isCanChangeProduction"
                class="mb-10 mt-10"
                @click="createDepartment = true"
                >Создать отдел
              </AButton>
            </div>
          </div>
        </APageBlock>
        <APageBlock class="mb-20" toggle>
          <template #header> Производственные линии </template>
          <div class="content">
            <div class="table-filter">
              <AInput v-model.trim="tableFilters.productionLines" placeholder="Фильтр" />
            </div>
            <ATable
              :items="productionLinePagination.chunkedItems"
              :fields="productionLineFields"
              class="production-line-table mb-20"
              :loading="loading"
              :row-classes="(row) => productionLineTableRowClasses(row)"
              :row-click="(row) => (selecteProductionLineId = row.id)"
            >
              <template #actions="{ item }">
                <AButton
                  v-if="isCanChangeProduction"
                  small
                  @click.stop="copyProductionLineId = item.id"
                >
                  <Fa
                    name="icon"
                    icon="copy"
                    class="icon faicon"
                    style="width: 15px; height: 15px"
                  />
                </AButton>
              </template>
            </ATable>
            <APagination
              v-model:currentPage="productionLinePagination.currentPage"
              v-model:currentOffset="productionLinePagination.offset"
              :items="filtredProductionLines"
              :all="filtredProductionLines.length"
              :loading="loading"
              :offsets="[10, 20, 50, 0]"
              mode="local"
              @changeOffset="productionLinePagination.offset = $event"
              @update:chunkedItems="productionLinePagination.chunkedItems = $event"
            />
            <div>
              <AButton
                v-if="isCanChangeProduction"
                class="mb-10 mt-10"
                @click="createProductionLine = true"
                >Создать производственную линию
              </AButton>
            </div>
          </div>
        </APageBlock>
        <CreateDepartmentAsideBlock
          :creating-department="createDepartment"
          :department="selecteDepartment"
          @close="closeAsideDepartmentPageHandler"
        />
        <CreateProductionLineAsideBlock
          :creating-production-line="createProductionLine"
          :copy-production-line-id="copyProductionLineId"
          :production-line-id="selecteProductionLineId"
          @close="closeAsideProductionLinePageHandler"
        />
      </div>
      <div v-else>
        <ALoader centered />
      </div>
    </template>
  </APageLayout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { reactive } from 'vue';
import accessMixin from '@/mixin/accessMixin.js';
import { departmentFields, productionLineFields } from './productionDictionary';
import { filterArrayByFields } from '@/utils/filters.js';
import CreateDepartmentAsideBlock from './components/CreateDepartmentAsideBlock.vue';
import CreateProductionLineAsideBlock from './components/CreateProductionLineAsideBlock.vue';
import { DEFAULT_PRODUCTION_LINE_ID } from '../../constants';
export default {
  name: 'Production',
  components: {
    CreateDepartmentAsideBlock,
    CreateProductionLineAsideBlock,
  },
  mixins: [accessMixin],
  setup() {
    return {
      departmentsPagination: reactive({
        current: 1,
        offset: 10,
        chunkedItems: reactive([]),
      }),
      productionLinePagination: reactive({
        current: 1,
        offset: 10,
        chunkedItems: reactive([]),
      }),
    };
  },
  data() {
    return {
      loading: true,

      selecteDepartment: null,
      createDepartment: false,

      selecteProductionLineId: null,
      createProductionLine: false,
      copyProductionLineId: null,

      tableFilters: {
        departments: null,
        productionLines: null,
        fieldsForFilter: ['id', 'name'],
      },

      showDeletedEntity: true,
    };
  },
  computed: {
    ...mapState({
      items: (s) => s.assortment.items,
      departments: (s) => s.production.departments,
      productionLines: (s) => s.production.productionLines,
    }),
    filtredDepartments() {
      const filtredDepartments = !this.showDeletedEntity
        ? this.departments.filter((i) => !i.deleted)
        : this.departments;
      return filterArrayByFields(
        filtredDepartments,
        this.tableFilters.departments,
        this.tableFilters.fieldsForFilter
      );
    },
    filtredProductionLines() {
      const filtredProductionLines = !this.showDeletedEntity
        ? this.productionLines.filter((i) => !i.deleted)
        : this.productionLines;
      return filterArrayByFields(
        filtredProductionLines,
        this.tableFilters.productionLines,
        this.tableFilters.fieldsForFilter
      );
    },
  },
  async created() {
    this.departmentFields = departmentFields;
    this.productionLineFields = productionLineFields;
    await this.bootstrap();
  },
  methods: {
    ...mapActions({
      getAllEmployees: 'user/getAllEmployees',
      getAllDepartments: 'production/getAllDepartments',
      getAllProductionLines: 'production/getAllProductionLines',
    }),
    async bootstrap() {
      this.loading = true;
      const promises = [
        this.getAllDepartments(),
        this.getAllProductionLines(),
        this.getAllEmployees(),
      ];
      try {
        await Promise.all(promises);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    closeAsideDepartmentPageHandler() {
      this.createDepartment = false;
      this.selecteDepartment = null;
    },
    closeAsideProductionLinePageHandler() {
      this.selecteProductionLineId = null;
      this.createProductionLine = false;
      this.copyProductionLineId = null;
    },
    departmentTableRowClasses(row) {
      if (row.id === this.selecteDepartment?.id) return 'active-table-row';
      if (row.deleted) return 'row-deleted';
    },
    productionLineTableRowClasses(row) {
      if (row.id === this.selecteProductionLineId) return 'active-table-row';
      if (row.id === DEFAULT_PRODUCTION_LINE_ID) return 'default-productionline-table-row';
      if (row.deleted) return 'row-deleted';
    },
  },
};
</script>
<style lang="scss" scoped>
.page-header {
  @include InterBold;
  font-size: 18px;
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 36px;

  &__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
.content-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}
.table-filter {
  max-width: 350px;
  margin: 5px 0px 0px 0px;
}
</style>
<style lang="scss">
.production-page-content-wrapper {
  .default-productionline-table-row {
    $def-prod-line-col: #444df70f;
    $white-def-prod-line-col: #e5e5f700;
    background-image: linear-gradient(135deg, $def-prod-line-col 25%, transparent 25%),
      linear-gradient(225deg, $def-prod-line-col 25%, transparent 25%),
      linear-gradient(45deg, $def-prod-line-col 25%, transparent 25%),
      linear-gradient(315deg, $def-prod-line-col 25%, $white-def-prod-line-col 25%);
    background-position: 10px 0, 10px 0, 0 0, 0 0;
    background-size: 10px 10px;
    background-repeat: repeat;
  }
  .active-table-row {
    background-color: $color-active-item;
  }
  .row-deleted {
    background-color: $color-light-gray;
  }
  .department-table {
    .id {
      width: 60px;
    }
  }
  .production-line-table {
    .id {
      width: 60px;
    }
    .actions {
      width: 200px;
    }
    td {
      margin: 0px;
      padding: 7px;
    }
  }
}
</style>
