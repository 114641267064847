import { cloneDeep } from './lodash';
/**
 * @param {Array} fields
 * @param {Object} object
 * @param {Boolean} [copy]
 */
export function getValueByFields(fields, object, copy = true) {
  if (!object) return;
  return fields.reduce((acc, curr) => {
    acc[curr] = copy ? cloneDeep(object[curr]) : object[curr];
    return acc;
  }, {});
}
