import { ORDER_PARAM_TYPE } from '../constants/index.js';
import BaseService from './services/BaseService.js';

class OrdertApi extends BaseService {
  async getOrder({ orderId }) {
    const data = await this.api.get(`/order/${orderId}`);
    return data;
  }

  async getAllOrders({ filters, selectedFields, settings, limit, offset }) {
    const data = await this.api.get(`/order`, {
      params: {
        filters,
        selectedFields,
        settings,
        limit,
        offset,
      },
    });
    return data;
  }
  async getOrderTags() {
    const data = await this.api.get(`/order/order-tags`);
    return data;
  }
  async saveOrderTag({ orderTag }) {
    const data = await this.api.post(`/order/order-tag`, {
      orderTag,
    });
    return data;
  }

  async getOrderCategory() {
    const data = await this.api.get(`/order/order-params/${ORDER_PARAM_TYPE.CATEGORY}`);
    return data;
  }
  async saveOrderCategory({ orderCategory }) {
    const data = await this.api.post(`/order/order-params/${ORDER_PARAM_TYPE.CATEGORY}`, {
      entity: orderCategory,
    });
    return data;
  }

  async xlImport({ tableSha, products }) {
    const data = await this.api.post(`/order/xl-import`, {
      order: {
        tableSha,
        products,
      },
    });
    return data;
  }

  async xlDownloadTemplate() {
    const data = await this.api.get(`/order/xl-empty-template`, {
      headers: { 'content-type': 'multipart/form-data' },
      responseType: 'blob',
    });
    return data;
  }

  async saveOrder({ order }) {
    const data = await this.api.post(`/order`, { order });
    return data;
  }

  async getClientUserAndTeamsInfo({ phone, email }) {
    const data = await this.api.get(`/user/find-user-for-order`, {
      params: {
        phone,
        email,
      },
    });
    return data;
  }

  async getOrderForCards({ orderId }) {
    const data = await this.api.get(`/order/card-order/${orderId}`);
    return data;
  }

  async resetOrDeleteOrder({ orderId, needDelete }) {
    const data = await this.api.patch(`/order/reset-or-delete`, {
      orderId,
      needDelete,
    });
    return data;
  }

  async patchOrder({ order }) {
    const data = await this.api.patch(`/order`, { order });
    return data;
  }

  async patchOrderSendToClient({ orderId }) {
    const data = await this.api.patch(`/order/patch-order-send-to-client/${orderId}`);
    return data;
  }

  async patchOrderSetPaid({ orderId }) {
    const data = await this.api.patch(`/order/patch-order-set-paid/${orderId}`);
    return data;
  }
  async patchOrderParams({ orderId, orderParams }) {
    const data = await this.api.patch(`/order/patch-order-params/${orderId}`, { orderParams });
    return data;
  }

  async getAllMonitoringOrders() {
    const data = await this.api.get(`/order/monitoring`);
    return data;
  }
}
export default new OrdertApi();
