<template>
  <div class="client-tab production">
    <div class="a-col">
      <ASelect
        v-model="paramsCloverId"
        :options="parseDictionary(productionParamsDictionary.paramsClover)"
        label="Клевер"
        select-label="name"
        disable-deleted
        :disabled="!accessToChange"
      />
      <ASelect
        v-model="paramsPackagingId"
        :options="parseDictionary(productionParamsDictionary.paramsPackaging)"
        label="Упаковка изделий"
        select-label="name"
        disable-deleted
        :disabled="!accessToChange"
      />
      <ASelect
        v-model="paramsOrderPackagingId"
        :options="parseDictionary(productionParamsDictionary.paramsOrderPackaging)"
        label="Упаковка заказа"
        select-label="name"
        disable-deleted
        :disabled="!accessToChange"
      />
      <ASelect
        v-model="paramsPressSizeId"
        :options="parseDictionary(productionParamsDictionary.paramsPressSize)"
        label="Разм. пресс"
        select-label="name"
        disable-deleted
        :disabled="!accessToChange"
      />
      <ASelect
        v-model="paramsProfileId"
        :options="parseDictionary(productionParamsDictionary.paramsProfile)"
        label="Профиль"
        class="paramsProfile"
        select-label="name"
        disable-deleted
        :disabled="!accessToChange"
      />
      <ASelect
        v-model="paramsSizeBoxId"
        :options="parseDictionary(productionParamsDictionary.paramsSizeBox)"
        label="Размерник"
        class="paramsSizeBox"
        select-label="name"
        disable-deleted
        :disabled="!accessToChange"
      />
      <AInput v-model="filePath" label="Путь к файлам" :disabled="!accessToChange" />
    </div>
    <div class="a-col ml-20">
      <AButton class="mt-20" :disabled="!accessToChange" @click="setAllProductionParamsHandler">
        Установить для всех изд.
      </AButton>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex';
import accessMixin from '@/mixin/accessMixin.js';
import { parseDictionary } from '@/utils/dictionaryUtils.js';
import { ORDER_STATE } from '../../../../constants';

export default {
  name: 'OrderInfoProductionBlock',
  mixins: [accessMixin],
  props: {
    isSelectedOrder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      paramsCloverId: null,
      paramsPackagingId: null,
      paramsOrderPackagingId: null,
      paramsProfileId: null,
      paramsPressSizeId: null,
      paramsSizeBoxId: null,
      filePath: null,
    };
  },
  computed: {
    ...mapState({
      newOrder: (s) => s.order.newOrder,
      selectedOrder: (s) => s.order.selectedOrder,
      productionParamsDictionary: (s) => s.dictionary.productionParams,
    }),
    accessToChange() {
      return (
        this.isCanChangeOrder &&
        this.pageOrder.clientId &&
        ![ORDER_STATE.COMPLETED, ORDER_STATE.PRODUCTION, ORDER_STATE.TECH_DESIGN].includes(
          this.pageOrder._old.state
        )
      );
    },
    pageOrder() {
      return this.isSelectedOrder ? this.selectedOrder : this.newOrder;
    },
  },
  created() {
    this.parseDictionary = parseDictionary;
  },
  methods: {
    ...mapMutations({
      setAllProductionParams: 'order/setAllProductionParams',
    }),
    setAllProductionParamsHandler() {
      this.setAllProductionParams({
        selectedOrder: this.isSelectedOrder,
        data: {
          paramsCloverId: this.paramsCloverId,
          paramsPackagingId: this.paramsPackagingId,
          paramsOrderPackagingId: this.paramsOrderPackagingId,
          paramsPressSizeId: this.paramsPressSizeId,
          paramsProfileId: this.paramsProfileId,
          paramsSizeBoxId: this.paramsSizeBoxId,
          filePath: this.filePath,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.client-tab.production {
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
  @include scroll;
  height: 275px;

  .a-col {
    width: 200px;
  }
}
</style>

<style lang="scss">
.client-tab.production {
  .a-select {
    .a-select__options-list {
      top: -40px;
      border-radius: 8px;
    }
    &.paramsSizeBox {
      .a-select__options-list {
        top: -200px;
      }
    }
    &.paramsProfile {
      .a-select__options-list {
        top: -160px;
      }
    }
  }
}
</style>
