<template>
  <div v-if="!loading" class="content-wrapper">
    <Navigation
      :links="links || []"
      :name="currentUser?.name"
      :type="USER_TYPES_DICTIONARY[currentUser?.type]"
      :avatar-id="avatarId"
      @logout="logoutHandler"
    />
    <ANotify />
    <router-view />
    <GDialogRoot />
  </div>
  <div v-else class="center">
    <ALoader centred />
  </div>
</template>
<script>
import Navigation from 'ui/Navigation/index.vue';
import { LINKS } from '../utils/app.dictionary';
import { GDialogRoot } from 'gitart-vue-dialog';
import { mapActions, mapState } from 'vuex';
import { USER_TYPES_DICTIONARY } from '../constants/index.js';
import { TOKEN } from '../constants/auth';

export default {
  components: {
    Navigation,
    GDialogRoot,
  },
  data() {
    return {
      avatarId: null,
      loading: true,
    };
  },
  computed: {
    ...mapState({
      currentUser: (s) => s.user.currentUser,
      refreshToken: (s) => s.auth.refreshToken,
    }),
  },
  created() {
    this.USER_TYPES_DICTIONARY = USER_TYPES_DICTIONARY;
    this.bootstrap();
  },
  // beforeUnmount() {
  //   this.disconnectFromRoom({
  //     id: this.currentUser.guid,
  //   });
  // },
  methods: {
    ...mapActions({
      getCurrentUser: 'user/getCurrentUser',
      connectSoket: 'socket/connect',
      logout: 'auth/logout',
      connetctToRoom: 'socket/connetctToRoom',
      disconnectFromRoom: 'socket/disconnectFromRoom',
    }),
    async bootstrap() {
      this.loading = true;
      try {
        await Promise.all([this.getCurrentUser()]);
        this.links = LINKS.filter((l) => l.types.includes(this.currentUser.type));
        await this.connectSoket({
          userId: this.currentUser.guid,
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    async logoutHandler() {
      try {
        const refreshToken = this.refreshToken || localStorage.getItem(TOKEN.REFRESH_TOKEN);
        if (!refreshToken) {
          throw new Error('refreshToken not found');
        }
        await this.logout({ refreshToken });
      } catch (err) {
        console.log(err);
      } finally {
        window.location.href = '/login';
      }
    },
  },
};
</script>
<style lang="scss">
.content-wrapper {
  @include Inter;
  background-color: $color-white;
  display: flex;
  align-items: flex-start;
  height: 100%;
  width: 100%;

  min-height: 100%;
}
.center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}
</style>
