<template>
  <div class="a-table-design-all-wrapper">
    <ALoader v-show="loading" centered />
    <div v-if="!loading && items" class="a-table-design-wrapper">
      <table class="a-table-design">
        <tbody>
          <tr>
            <th
              v-for="(field, idx) in fields"
              :key="`week-th-${idx}`"
              :class="{ [field.class]: field.class }"
            >
              <span class="a-table__title-text">
                {{ field.label }}
              </span>
            </th>
            <th />
          </tr>
          <template v-if="loading">
            <tr>
              <td :colspan="fields.length + 1">
                <ALoader centered />
              </td>
            </tr>
          </template>
          <template v-for="(item, itemInd) in items" :key="`item-tr-${item.guid}`">
            <!--  v-on="getPropHandlers(item)" -->
            <tr>
              <td
                v-for="(field, indCell) in fields"
                :key="`body-td-data-${itemInd}-${indCell}-${field.key}`"
                :class="{ [field.bodyClass]: field.bodyClass }"
                class="td-body-cell"
              >
                <slot :name="field.key" v-bind="{ item, key: field.key }">
                  <span>
                    {{
                      getDataByKey({
                        item,
                        key: field.key,
                        type: field.type,
                      })
                    }}
                  </span>
                </slot>
              </td>
              <td class="toggle">
                <div @click.stop="item.showDetails = !item.showDetails">
                  <fa
                    class="toggle-icon"
                    icon="chevron-up"
                    style="width: 25px; height: 20px"
                    :class="{ showDetails: item.showDetails }"
                  />
                </div>
              </td>
            </tr>
            <tr
              v-for="subItem in item.products"
              v-show="item.showDetails"
              :key="`subitem-tr-${subItem.guid}`"
              class="tr-subrow"
            >
              <!-- :class="{ ...bindRowClasses(item) }" -->
              <!-- v-on="getPropHandlers(subItem)" -->
              <td
                v-for="(field, indSubCell) in fields"
                :key="`body-td-data-${itemInd}-${indSubCell}-${field.key}-${subItem.guid}`"
                :class="{ [field.bodyClass]: field.bodyClass }"
                class="td-body-subcell"
              >
                <slot :name="`sub${field.key}`" v-bind="{ item: subItem, key: field.key }">
                  <span>
                    {{
                      getDataByKey({
                        item: subItem,
                        key: field.key,
                        type: field.type,
                      })
                    }}
                  </span>
                </slot>
              </td>
              <td />
            </tr>
          </template>
          <tr v-if="!items.length && !loading" class="no-items">
            <td :colspan="fields.length + 1">Нет заказов</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { dayjs } from '../../../utils/date.js';
export default {
  name: 'ATableDesign',
  props: {
    items: {
      type: [Array, null],
      default: () => [],
    },
    fields: {
      type: [Array, null],
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    // rowClasses: {
    //   type: Function,
    //   default: null,
    // },
    // rowClick: {
    //   type: Function,
    //   default: null,
    // },
  },
  computed: {
    // bindRowClasses() {
    //   return (item) => {
    //     const result = this.rowClasses(item);
    //     if (!result) return '';
    //     return Array.isArray(result)
    //       ? result.reduce((a, b) => {
    //           a[b] = b;
    //           return a;
    //         }, {})
    //       : { [result]: result };
    //   };
    // },
  },
  methods: {
    getDataByKey({ item, key, type }) {
      let data = item[key];
      if (type === 'date' && data) {
        data = dayjs(data).format('DD.MM.YYYY');
      }
      return data;
    },
    // getPropHandlers(row) {
    //   const allHandlers = {};
    //   if (this.rowClick) allHandlers.click = () => this.rowClick(row);
    //   return allHandlers;
    // },
  },
};
</script>

<style lang="scss" scoped>
.no-items {
  text-align: center;
}
.toggle {
  text-align: center;
  vertical-align: middle;
}

.a-table-design-wrapper {
  // position: relative;
  overflow-y: visible;
  @include scroll-wider;

  // padding-top: 50px;
}

.a-table-design {
  background-color: $color-white;
  border-collapse: collapse;

  table-layout: fixed;

  // max-width: 100%;
  // width: 100%;
  @include InterSemibold;
}

th,
td {
  @include td-th-default;
  padding: 10px;
  // @include td-th-small;
  // min-width: 50px;
}

th {
  @include InterSemibold;
  font-size: 12px;
  color: $color-gray;
}

td {
  @include InterSemibold;
  max-width: 100%;
  width: auto;
}
.td-body-cell,
.td-body-subcell {
  // text-align: center;
  vertical-align: middle;
}

th:not(:first-child),
td:not(:first-child) {
  text-align: start;
}

.toggle-icon {
  width: 30px;
  color: $color-light-gray;
  cursor: pointer;
  transition: transform 0.3s;
  &.showDetails {
    transform: rotate(-180deg);
  }
}
</style>
