import BaseService from './services/BaseService.js';

const BASE_PATH = 'productionTable';

class productionTableApi extends BaseService {
  async getProductionTableOrdersData({ filters }) {
    const data = await this.api.get(`${BASE_PATH}`, { params: { filters } });
    return data;
  }

  async getProductionTableSummaryOrdersData({ filters }) {
    const data = await this.api.get(`${BASE_PATH}/summary`, { params: { filters } });
    return data;
  }

  async getProductionTableSummaryMonthStat() {
    const data = await this.api.get(`${BASE_PATH}/summary-currenct-month`);
    return data;
  }
}
export default new productionTableApi();
