import { notify } from '@kyvg/vue3-notification';
import { reactive, computed } from 'vue';
import { throttle } from '../../utils/lodash';
import { copyToClipboard } from '@/utils/clipboard.js';
import {
  PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS,
  PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS,
  PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS,
} from '../../components/productionTable/productionTableDictionary';
import { PRODUCT_TYPE_COMPLEXITY } from '@/constants';
import { dayjs, formateDate } from '@/utils/date.js';

export function useProductTableAggregation(columns, orders, isSummaryTable = false) {
  const selectedRange = reactive({
    key: [],
    index: [],
  });
  const startedSelectCell = reactive([]);

  const isSelectOneCell = computed(() => startedSelectCell.length);
  const isSelectRange = computed(
    () => !!(selectedRange.key.length > 1 || selectedRange.index.length > 1)
  );

  const isSelectCell = (key, ind) =>
    selectedRange.index.includes(ind) && selectedRange.key.includes(key);

  const setKey = (key) => {
    const activeCell = startedSelectCell[0];
    const newArr = [];
    if (key === activeCell) {
      return (selectedRange.key = [key]);
    }

    const lefterCol = columns.value.find((c) => c.key === key || c.key === activeCell).key;
    const rightCol = lefterCol === key ? activeCell : key;
    let startFillKeys = false;
    for (const column of columns.value) {
      if (lefterCol === column.key) {
        startFillKeys = true;
      }
      if (!startFillKeys) {
        continue;
      }
      newArr.push(column.key);
      if (column.key === rightCol) {
        return (selectedRange.key = newArr);
      }
    }
  };
  const setIndex = (index) => {
    const start = Math.min(startedSelectCell[1], index);
    const end = Math.max(startedSelectCell[1], index);
    selectedRange.index = new Array(end - start + 1).fill('').map((_, i) => i + start);
  };

  const activeSelectHandler = (e) => {
    if (e.which !== 1) {
      return;
    }
    const coords = formatCoord(e.target);

    if (!coords) {
      return;
    }
    const [key, index] = coords;
    if (!isSelectOneCell.value) {
      return;
    }

    setKey(key);
    setIndex(index);
  };

  const getCoordValue = (target) => {
    return [target.tagName, target.nodeName].includes('TABLE')
      ? []
      : target.tagName === 'TD'
      ? target.dataset.tableValue.split('__')
      : getCoordValue(target.parentElement || target.parentNode);
  };

  const formatCoord = (target) => {
    const result = getCoordValue(target);
    return result?.length ? [result[0], +result[1]] : null;
  };

  const mousedownHandler = (e) => {
    if (e.which !== 1) {
      return;
    }
    const coords = formatCoord(e.target);

    if (!coords) {
      return;
    }
    const [key, index] = coords;

    if (startedSelectCell[0] === key && startedSelectCell[1] === index) {
      startedSelectCell.splice(-2);
      selectedRange.key = [];
      selectedRange.index = [];
      return;
    }

    startedSelectCell[0] = key;
    startedSelectCell[1] = index;
    selectedRange.key = [key];
    selectedRange.index = [index];
  };

  const resetSelectHandler = () => {
    startedSelectCell.splice(-2);
    selectedRange.key = [];
    selectedRange.index = [];
  };
  const mouseupHandler = () => {
    if (selectedRange.key.length === 1 && selectedRange.index.length === 1) {
      resetSelectHandler();
    }
  };

  const mouseoverHandler = throttle(
    (e) => {
      return activeSelectHandler(e);
    },
    55,
    { leading: true, trailing: true }
  );

  const copyTableHandler = (e) => {
    if (!(e.ctrlKey && ['c', 'с'].includes(e.key.toLowerCase())) || !isSelectOneCell.value) {
      return;
    }
    const data = orders.value.slice(
      selectedRange.index[0],
      selectedRange.index[selectedRange.index.length - 1] + 1
    );

    const rows = [];
    for (const order of data) {
      let str = '';
      for (const key of selectedRange.key) {
        str += `${str ? '\t' : ''}${order[key] === undefined ? '\t' : `${order[key] ?? ''} `}`;
      }
      rows.push(str);
    }
    copyToClipboard(rows.join('\n'));
    notify({ text: 'Скопировано', type: 'success' });
  };

  const aggregatedData = computed(() => {
    if (isSummaryTable) return;
    const maxIndex = selectedRange.index[selectedRange.index.length - 1];
    if (!isSelectOneCell.value || maxIndex > orders.value.length) {
      return;
    }
    const result = {
      tagName: [],
      categoryNames: [],
      ordersQuantity: 0,
      cardsQuantity: 0,
      allQuantity: 0,
      [PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.NO_COMPLEXITY]]: 0,
      [PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.EASY]]: 0,
      [PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.MEDIUM]]: 0,
      [PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.HARD]]: 0,
      [PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.VERY_HARD]]: 0,
      managerName: [],
      designerName: [],
      timeToProduction: [],
      deadline: [],
      packageDate: [],
      status: [],
      createdCardDate: [],
      techDesignDoneDate: [],
    };
    let needShow = false;
    for (let i = selectedRange.index[0]; i <= maxIndex; i++) {
      const order = orders.value[i];
      result.ordersQuantity++;

      if (selectedRange.key.includes('tagId') && order.tagName) {
        result.tagName.push(order.tagName);
        needShow = true;
      }
      if (selectedRange.key.includes('category') && order.categoryNames.length) {
        result.categoryNames.push(...order.categoryNames);
        needShow = true;
      }

      if (selectedRange.key.includes('cardsQuantity')) {
        result.cardsQuantity += order.cardsQuantity;
        needShow = true;
      }

      if (selectedRange.key.includes('allQuantity')) {
        result.allQuantity += order.allQuantity;
        needShow = true;
      }

      for (const k of Object.values(PRODUCT_TYPE_COMPLEXITY)) {
        if (selectedRange.key.includes(PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[k])) {
          result[PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[k]] +=
            order[PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[k]];
          needShow = true;
        }
      }

      if (selectedRange.key.includes('managerName')) {
        result.managerName.push(order.managerName);
        needShow = true;
      }

      if (selectedRange.key.includes('designerName') && order.designerName) {
        result.designerName.push(order.designerName);
        needShow = true;
      }

      if (selectedRange.key.includes('timeToProduction') && order.deadline) {
        result.timeToProduction.push(order.timeToProduction);
        needShow = true;
      }

      if (selectedRange.key.includes('deadline') && order.deadline) {
        result.deadline.push(order.deadline);
        needShow = true;
      }
      if (selectedRange.key.includes('packageDate') && order.packageDate) {
        result.packageDate.push(order.packageDate);
        needShow = true;
      }

      if (selectedRange.key.includes('status')) {
        result.status.push(order.status);
        needShow = true;
      }

      if (selectedRange.key.includes('createdCardDate') && order.createdCardDate) {
        result.createdCardDate.push(order.createdCardDate);
        needShow = true;
      }
      if (selectedRange.key.includes('techDesignDoneDate') && order.techDesignDoneDate) {
        result.techDesignDoneDate.push(order.techDesignDoneDate);
        needShow = true;
      }
    }

    const keysWithMinMax = ['timeToProduction'];
    for (const k of keysWithMinMax) {
      const values = result[k].filter((_) => _);
      const min = Math.min(...values);
      const max = Math.max(...values);
      result[k] = {
        min: min === Number.POSITIVE_INFINITY ? null : min,
        max: max === Number.NEGATIVE_INFINITY ? null : max,
      };
    }

    const keysWithMinMaxDate = ['deadline', 'createdCardDate', 'techDesignDoneDate', 'packageDate'];
    for (const k of keysWithMinMaxDate) {
      const values = result[k].map((d) => dayjs(d, 'DD.MM.YYYY'));

      result[k] = {
        min: formateDate(dayjs.min(values)),
        max: formateDate(dayjs.max(values)),
      };
    }

    const keysDistincted = ['tagName', 'managerName', 'designerName', 'status', 'category'];
    for (const k of keysDistincted) {
      result[k] = [...new Set(result[k])];
    }

    return needShow ? result : null;
  });

  const aggregatedSummaryTableData = computed(() => {
    if (!isSummaryTable) return;
    const maxIndex = selectedRange.index[selectedRange.index.length - 1];
    if (!isSelectOneCell.value || maxIndex > orders.value.length) {
      return;
    }

    const result = {
      ordersQuantity: 0,
      deadline: [],
      allQuantityPlan: 0,
      [PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[
        PRODUCT_TYPE_COMPLEXITY.NO_COMPLEXITY
      ]]: 0,
      [PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[PRODUCT_TYPE_COMPLEXITY.EASY]]: 0,
      [PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[PRODUCT_TYPE_COMPLEXITY.MEDIUM]]: 0,
      [PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[PRODUCT_TYPE_COMPLEXITY.HARD]]: 0,
      [PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[PRODUCT_TYPE_COMPLEXITY.VERY_HARD]]: 0,
      allQuantityDebt: 0,
      [PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[
        PRODUCT_TYPE_COMPLEXITY.NO_COMPLEXITY
      ]]: 0,
      [PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[PRODUCT_TYPE_COMPLEXITY.EASY]]: 0,
      [PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[PRODUCT_TYPE_COMPLEXITY.MEDIUM]]: 0,
      [PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[PRODUCT_TYPE_COMPLEXITY.HARD]]: 0,
      [PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[PRODUCT_TYPE_COMPLEXITY.VERY_HARD]]: 0,
      allQuantityCompleted: 0,
    };

    let needShow = false;
    for (let i = selectedRange.index[0]; i <= maxIndex; i++) {
      const order = orders.value[i];
      result.ordersQuantity++;

      if (selectedRange.key.includes('deadline') && order.deadline) {
        result.deadline.push(order.deadline);
        needShow = true;
      }

      if (selectedRange.key.includes('allQuantityPlan')) {
        result.allQuantityPlan += order.allQuantityPlan;
        needShow = true;
      }
      if (selectedRange.key.includes('allQuantityDebt')) {
        result.allQuantityDebt += order.allQuantityDebt;
        needShow = true;
      }
      if (selectedRange.key.includes('allQuantityCompleted')) {
        result.allQuantityCompleted += order.allQuantityCompleted;
        needShow = true;
      }

      for (const k of Object.values(PRODUCT_TYPE_COMPLEXITY)) {
        if (selectedRange.key.includes(PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[k])) {
          result[PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[k]] +=
            order[PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_PLAN_KEYS[k]];
          needShow = true;
        }
      }
      for (const k of Object.values(PRODUCT_TYPE_COMPLEXITY)) {
        if (selectedRange.key.includes(PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[k])) {
          result[PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[k]] +=
            order[PRODUCT_TYPE_COMPLEXITY_QUANTITY_SUMMARY_DEBT_KEYS[k]];
          needShow = true;
        }
      }
    }

    const keysWithMinMaxDate = ['deadline', 'packageDate'];
    for (const k of keysWithMinMaxDate) {
      const values = result[k].map((d) => dayjs(d, 'DD.MM.YYYY'));

      result[k] = {
        min: formateDate(dayjs.min(values)),
        max: formateDate(dayjs.max(values)),
      };
    }

    return needShow ? result : null;
  });

  return {
    mousedownHandler,
    mouseoverHandler,
    mouseupHandler,
    resetSelectHandler,

    copyTableHandler,

    isSelectCell,
    selectedRange,
    isSelectRange,
    startedSelectCell,
    aggregatedData,
    aggregatedSummaryTableData,
  };
}
