import authApi from '../../../api/authApi';

export default {
  async refresh({ commit }, { refreshToken }) {
    const { data } = await authApi.refresh({ refreshToken });
    commit('SET_AUTH_TOKENS', data);
  },
  async logout({ commit }, { refreshToken }) {
    authApi.logout({ refreshToken });
    commit('DELETE_AUTH_TOKENS');
  },
};
