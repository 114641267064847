<template>
  <GDialog
    v-model="showModal"
    :persistent="persistent"
    content-class="a-modal-wrapper"
    max-width="1000"
  >
    <div class="modal__content">
      <div v-if="title" class="modal__title">
        {{ title }}
      </div>
      <div v-if="text" class="modal__text">
        {{ text }}
      </div>
      <div class="modal__actions">
        <template v-if="buttons">
          <div class="btns">
            <div v-for="(btn, id) of buttons" :key="id">
              <AButton @click="btn.handler(this)" :success="btn.success">
                {{ btn.text }}
              </AButton>
            </div>
          </div>
        </template>
        <template v-else>
          <AButton v-if="!withoutClose" @click="showModal = false">
            {{ closeText || "Закрыть" }}
          </AButton>
          <AButton v-if="onConfirm" success @click="confirmHandler">
            Подтвердить
          </AButton>
        </template>
      </div>
    </div>
  </GDialog>
</template>
<script>
import { GDialog } from "gitart-vue-dialog";

export default {
  name: "Confirm",
  components: { GDialog },
  props: {
    modelValue: {
      type: Boolean,
      default: null,
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: true,
    },
    onConfirm: {
      type: Function,
      default: null,
    },
    onClose: {
      type: Function,
      default: null,
    },
    withoutClose: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    closeText: {
      type: String,
      default: null,
    },
    buttons: {
      type: Array,
      default: null,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    showModal: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
        if (!value && this.onClose) {
          return this.onClose(this);
        }
      },
    },
  },
  methods: {
    confirmHandler() {
      return this.onConfirm(this);
    },
  },
};
</script>
<style lang="scss" scoped>
.modal {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px 30px;
  }
  &__title {
    @include InterSemibold;
    font-size: 16px;
    padding: 5px 15px 20px;
  }
  &__text {
    margin-top: 5px;
  }
  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    width: 100%;
    margin-top: 15px;

    .btns {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 100%;
    }
    // background-color: red;
  }
}
</style>
<style lang="scss">
.a-modal-wrapper {
  width: fit-content;
}
</style>
