<template>
  <OrderSetting />
</template>

<script>
import OrderSetting from '@/components/orderSetting';
export default {
  name: 'OrdersSettingPage',
  components: { OrderSetting },
};
</script>
