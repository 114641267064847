<template>
  <div class="loader" :class="{ centered }"></div>
</template>
<script>
export default {
  name: "RingLoader",
  props: {
    centered: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style lang="scss" scoped>
$color: $color-apple;
$width: 20px;

.loader,
.loader:after {
  border-radius: 50%;
  width: $width;
  height: $width;
}
$border: 1px;
.loader {
  position: relative;
  border-top: $border solid rgba(255, 255, 255, 0.2);
  border-right: $border solid rgba(255, 255, 255, 0.2);
  border-bottom: $border solid rgba(255, 255, 255, 0.2);
  border-left: 3px solid $color;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1s infinite linear;
  animation: load8 1s infinite linear;

  &.centered {
    min-width: 100%;
  }
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
