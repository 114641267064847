import BaseService from './services/BaseService.js';

class dictionaryService extends BaseService {
  async getAssortmentDictionary() {
    const data = await this.api.get('/item/itemDictionary');
    return data;
  }

  async getProductionParams() {
    const data = await this.api.get(`/item/production-params`);
    return data;
  }
  async getAvailableAssortment({ key }) {
    const data = await this.api.get(`/item/available-assortment/${key}`);
    return data;
  }

  async changeArticle({ article }) {
    const data = await this.api.post('/item/article', { article });
    return data;
  }

  async changeCategory({ category }) {
    const data = await this.api.post('/item/category', { category });
    return data;
  }

  async changeFabric({ fabric }) {
    const data = await this.api.post('/item/fabric', { fabric });
    return data;
  }

  async changeGrowType({ growType }) {
    const data = await this.api.post('/item/grow-type', { growType });
    return data;
  }

  async changeProductType({ productType }) {
    const data = await this.api.post('/item/product-type', { productType });
    return data;
  }

  async changeSex({ sex }) {
    const data = await this.api.post('/item/sex', { sex });
    return data;
  }
}
export default new dictionaryService();
