<template>
  <Transition>
    <div v-if="showData" class="aggregate-row-wrapper">
      <div class="a-row">
        <div class="aggregate-row-item">
          <div class="col-centred">
            <div>
              {{ aggregatedData.ordersQuantity }}
            </div>
            <span class="secondary bold">{{ isSummaryTable ? orderDateName : orderPlName }}</span>
          </div>
        </div>

        <div v-if="tagName" class="aggregate-row-item">
          <div class="col-centred">
            <div class="words-col">
              {{ tagName }}
            </div>
            <span class="secondary bold">Тег</span>
          </div>
        </div>
        <div v-if="categoryNames" class="aggregate-row-item">
          <div class="col-centred">
            <div class="words-col">
              {{ categoryNames }}
            </div>
            <span class="secondary bold">Категория</span>
          </div>
        </div>

        <div v-if="aggregatedData.cardsQuantity" class="aggregate-row-item">
          <div class="col-centred">
            <div class="words-col">
              {{ aggregatedData.cardsQuantity }}
            </div>
            <span class="secondary bold">К&#8288;-&#8288;во&nbsp;парт.</span>
          </div>
        </div>

        <!-- Quantity -->
        <!-- BaseTableQuantity -->
        <div v-if="aggregatedData.allQuantity" class="aggregate-row-item">
          <div class="col-centred">
            <div>
              {{ aggregatedData.allQuantity }}
            </div>
            <span class="secondary bold">К&#8288;-&#8288;во</span>
          </div>
        </div>
        <div v-if="aggregatedData.noComplexityQuantity" class="aggregate-row-item">
          <div class="col-centred">
            <div>
              {{ aggregatedData.noComplexityQuantity }}
            </div>
            <span class="secondary bold">Без пош.</span>
          </div>
        </div>
        <div v-if="aggregatedData.easyQuantity" class="aggregate-row-item">
          <div class="col-centred">
            <div>
              {{ aggregatedData.easyQuantity }}
            </div>
            <span class="secondary bold">Лег.</span>
          </div>
        </div>
        <div v-if="aggregatedData.mediumQuantity" class="aggregate-row-item">
          <div class="col-centred">
            <div>
              {{ aggregatedData.mediumQuantity }}
            </div>
            <span class="secondary bold">Ср.</span>
          </div>
        </div>
        <div v-if="aggregatedData.hardQuantity" class="aggregate-row-item">
          <div class="col-centred">
            <div>
              {{ aggregatedData.hardQuantity }}
            </div>
            <span class="secondary bold">Сл.</span>
          </div>
        </div>
        <div v-if="aggregatedData.veryHardQuantity" class="aggregate-row-item">
          <div class="col-centred">
            <div>
              {{ aggregatedData.veryHardQuantity }}
            </div>
            <span class="secondary bold">Оч. сл.</span>
          </div>
        </div>
        <!-- SummaryTableQuantity -->
        <div v-if="aggregatedData.allQuantityPlan" class="aggregate-row-item">
          <div class="col-centred main-cell">
            <div>
              {{ aggregatedData.allQuantityPlan }}
            </div>
            <span class="secondary bold">План</span>
          </div>
        </div>
        <div v-if="aggregatedData.noComplexityQuantityPlan" class="aggregate-row-item">
          <div class="col-centred">
            <div>
              {{ aggregatedData.noComplexityQuantityPlan }}
            </div>
            <span class="secondary bold">Без&nbsp;пош.</span>
          </div>
        </div>
        <div v-if="aggregatedData.easyQuantityPlan" class="aggregate-row-item">
          <div class="col-centred">
            <div>
              {{ aggregatedData.easyQuantityPlan }}
            </div>
            <span class="secondary bold">Лег.</span>
          </div>
        </div>
        <div v-if="aggregatedData.mediumQuantityPlan" class="aggregate-row-item">
          <div class="col-centred">
            <div>
              {{ aggregatedData.mediumQuantityPlan }}
            </div>
            <span class="secondary bold">Ср.</span>
          </div>
        </div>
        <div v-if="aggregatedData.hardQuantityPlan" class="aggregate-row-item">
          <div class="col-centred">
            <div>
              {{ aggregatedData.hardQuantityPlan }}
            </div>
            <span class="secondary bold">Сл.</span>
          </div>
        </div>
        <div v-if="aggregatedData.veryHardQuantityPlan" class="aggregate-row-item">
          <div class="col-centred">
            <div>
              {{ aggregatedData.veryHardQuantityPlan }}
            </div>
            <span class="secondary bold">Оч. сл.</span>
          </div>
        </div>
        <!--  -->
        <div v-if="aggregatedData.allQuantityDebt" class="aggregate-row-item">
          <div class="col-centred main-cell">
            <div>
              {{ aggregatedData.allQuantityDebt }}
            </div>
            <span class="secondary bold">Долг</span>
          </div>
        </div>
        <div v-if="aggregatedData.noComplexityQuantityDebt" class="aggregate-row-item">
          <div class="col-centred">
            <div>
              {{ aggregatedData.noComplexityQuantityDebt }}
            </div>
            <span class="secondary bold">Без пош.</span>
          </div>
        </div>
        <div v-if="aggregatedData.easyQuantityDebt" class="aggregate-row-item">
          <div class="col-centred">
            <div>
              {{ aggregatedData.easyQuantityDebt }}
            </div>
            <span class="secondary bold">Лег.</span>
          </div>
        </div>
        <div v-if="aggregatedData.mediumQuantityDebt" class="aggregate-row-item">
          <div class="col-centred">
            <div>
              {{ aggregatedData.mediumQuantityDebt }}
            </div>
            <span class="secondary bold">Ср.</span>
          </div>
        </div>
        <div v-if="aggregatedData.hardQuantityDebt" class="aggregate-row-item">
          <div class="col-centred">
            <div>
              {{ aggregatedData.hardQuantityDebt }}
            </div>
            <span class="secondary bold">Сл.</span>
          </div>
        </div>
        <div v-if="aggregatedData.veryHardQuantityDebt" class="aggregate-row-item">
          <div class="col-centred">
            <div>
              {{ aggregatedData.veryHardQuantityDebt }}
            </div>
            <span class="secondary bold">Оч. сл.</span>
          </div>
        </div>
        <!--  -->
        <div v-if="aggregatedData.allQuantityCompleted" class="aggregate-row-item">
          <div class="col-centred main-cell">
            <div>
              {{ aggregatedData.allQuantityCompleted }}
            </div>
            <span class="secondary bold">Готово</span>
          </div>
        </div>
        <!-- Quantity End -->

        <div v-if="managerName" class="aggregate-row-item">
          <div class="col-centred">
            <div class="words-col">
              {{ managerName }}
            </div>
            <span class="secondary bold">Менеджер</span>
          </div>
        </div>

        <div v-if="aggregatedData.timeToProduction?.max" class="aggregate-row-item">
          <div class="col-centred">
            <div>
              <div>
                <span class="secondary bold">min:&nbsp;</span>
                <span> {{ aggregatedData.timeToProduction.min }} </span>
              </div>
              <div>
                <span class="secondary bold">max:&nbsp;</span>
                <span> {{ aggregatedData.timeToProduction.max }} </span>
              </div>
            </div>
            <span class="secondary bold">Время&nbsp;на&nbsp;пр.</span>
          </div>
        </div>

        <div v-if="aggregatedData.deadline?.max" class="aggregate-row-item">
          <div class="col-centred">
            <div>
              <div>
                <span class="secondary bold">min:&nbsp;</span>
                <span> {{ aggregatedData.deadline.min }} </span>
              </div>
              <div>
                <span class="secondary bold">max:&nbsp;</span>
                <span> {{ aggregatedData.deadline.max }} </span>
              </div>
            </div>
            <span class="secondary bold">Отгрузка</span>
          </div>
        </div>
        <div v-if="aggregatedData.packageDate?.max" class="aggregate-row-item">
          <div class="col-centred">
            <div>
              <div>
                <span class="secondary bold">min:&nbsp;</span>
                <span> {{ aggregatedData.packageDate.min }} </span>
              </div>
              <div>
                <span class="secondary bold">max:&nbsp;</span>
                <span> {{ aggregatedData.packageDate.max }} </span>
              </div>
            </div>
            <span class="secondary bold">Упаковка</span>
          </div>
        </div>

        <div v-if="status" class="aggregate-row-item">
          <div class="col-centred">
            <div class="words-col">
              {{ status }}
            </div>
            <span class="secondary bold">Статус</span>
          </div>
        </div>

        <div v-if="designerName" class="aggregate-row-item">
          <div class="col-centred">
            <div class="words-col">
              {{ designerName }}
            </div>
            <span class="secondary bold">Дизайнер</span>
          </div>
        </div>

        <div v-if="aggregatedData.createdCardDate?.max" class="aggregate-row-item">
          <div class="col-centred">
            <div>
              <div>
                <span class="secondary bold">min:&nbsp;</span>
                <span> {{ aggregatedData.createdCardDate.min }} </span>
              </div>
              <div>
                <span class="secondary bold">max:&nbsp;</span>
                <span> {{ aggregatedData.createdCardDate.max }} </span>
              </div>
            </div>
            <span class="secondary bold">Карты</span>
          </div>
        </div>

        <div v-if="aggregatedData.techDesignDoneDate?.max" class="aggregate-row-item">
          <div class="col-centred">
            <div>
              <div>
                <span class="secondary bold">min:&nbsp;</span>
                <span> {{ aggregatedData.techDesignDoneDate.min }} </span>
              </div>
              <div>
                <span class="secondary bold">max:&nbsp;</span>
                <span> {{ aggregatedData.techDesignDoneDate.max }} </span>
              </div>
            </div>
            <span class="secondary bold">Тиффы</span>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { computed } from 'vue';
import { pluralizedDay, pluralizedOrder } from '../../../constants/pluralizedWords';

export default {
  name: 'AggregateRow',
  props: {
    aggregatedData: {
      type: Object,
      default: null,
    },
    isSummaryTable: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const getList = (list = []) => {
      if (!list.length) {
        return;
      }
      return list.length > 3
        ? `${list
            .slice(0, 3)
            .map((e) => String(e).replace(/\s/, '\u00A0'))
            .join(' ')} + ${list.length - 3}`
        : list.length && list.map((e) => String(e).replace(/\s/, '\u00A0')).join(' ');
    };

    const tagName = computed(() => getList(props.aggregatedData?.tagName));
    const categoryNames = computed(() => getList(props.aggregatedData?.categoryNames));
    const managerName = computed(() => getList(props.aggregatedData?.managerName));
    const designerName = computed(() => getList(props.aggregatedData?.designerName));
    const status = computed(() => getList(props.aggregatedData?.status));

    const orderPlName = computed(() => {
      return (
        props.aggregatedData?.ordersQuantity && pluralizedOrder(props.aggregatedData.ordersQuantity)
      );
    });
    const orderDateName = computed(() => {
      return (
        props.aggregatedData?.ordersQuantity && pluralizedDay(props.aggregatedData.ordersQuantity)
      );
    });

    const showData = computed(() =>
      !props.isSummaryTable
        ? props.aggregatedData &&
          (tagName.value ||
            categoryNames.value ||
            managerName.value ||
            status.value ||
            designerName.value ||
            props.aggregatedData?.cardsQuantity ||
            props.aggregatedData?.allQuantity ||
            props.aggregatedData?.noComplexityQuantity ||
            props.aggregatedData?.easyQuantity ||
            props.aggregatedData?.mediumQuantity ||
            props.aggregatedData?.hardQuantity ||
            props.aggregatedData?.veryHardQuantity ||
            props.aggregatedData?.timeToProduction?.max ||
            props.aggregatedData?.deadline?.max ||
            props.aggregatedData?.packageDate?.max ||
            props.aggregatedData?.createdCardDate?.max ||
            props.aggregatedData?.techDesignDoneDate?.max)
        : props.aggregatedData &&
          (props.aggregatedData?.deadline?.max ||
            props.aggregatedData?.packageDate?.max ||
            props.aggregatedData?.allQuantityPlan ||
            props.aggregatedData?.noComplexityQuantityPlan ||
            props.aggregatedData?.easyQuantityPlan ||
            props.aggregatedData?.mediumQuantityPlan ||
            props.aggregatedData?.hardQuantityPlan ||
            props.aggregatedData?.veryHardQuantityPlan ||
            props.aggregatedData?.allQuantityDebt ||
            props.aggregatedData?.noComplexityQuantityDebt ||
            props.aggregatedData?.easyQuantityDebt ||
            props.aggregatedData?.mediumQuantityDebt ||
            props.aggregatedData?.hardQuantityDebt ||
            props.aggregatedData?.veryHardQuantityDebt ||
            props.aggregatedData?.allQuantityCompleted)
    );

    return {
      showData,

      tagName,
      categoryNames,
      managerName,
      designerName,
      status,

      orderDateName,
      orderPlName,
    };
  },
};
</script>
<style lang="scss" scoped>
.bold {
  @include InterSemibold;
}
.secondary {
  font-size: 11px;
  color: $color-gray;
}
.aggregate-row {
  &-wrapper {
    position: fixed;
    bottom: 0px;

    left: 50%;
    transform: translate(-50%, -50%);

    min-height: 50px;

    background-color: white;
    box-shadow: 2px 0px 10px 10px rgba(137, 137, 137, 0.41); //$color-white-smoke;

    padding: 10px;
    border-radius: 4px;
  }
}
.aggregate-row-item {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  padding: 0px 10px;
  &:not(:last-of-type) {
    border-right: 1px solid $color-bg-smoke;
  }
}
.words-col {
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  width: min-content;
}
.col-centred {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.main-cell {
  @include InterBold;
}
.v-enter-active,
.v-leave-active {
  transition: all 0.3s ease-in-out;
}

.v-enter-from,
.v-leave-to {
  transform: translateX(-50px);
  opacity: 0;
}
</style>
