<template>
  <div
    class="a-label"
    :class="{
      postfix,
      greyish: !white,
      labeled: label,
      labelUnderline,
      borderless,
      small,
    }"
  >
    <div v-if="label" class="a-label__label">
      {{ label }}
    </div>
    <div class="a-label__value">
      <div class="a-label__value-value">
        {{ value }}
      </div>
      <div v-if="postfix" class="postfix">
        {{ postfix }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ALabel",
  props: {
    white: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    label: {
      type: [String, Number],
      default: null,
    },
    borderless: {
      type: Boolean,
      default: false,
    },
    labelUnderline: {
      type: Boolean,
      default: false,
    },
    postfix: {
      type: String,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.a-label {
  position: relative;

  display: flex;
  flex-direction: column;
  width: 100%;

  @include InterSemibold;

  height: 40px;

  &__label {
    font-size: 12px;
    position: absolute;
    color: $color-dark-gray;
    bottom: 108%;

    left: 10px;
    padding-bottom: 2px;
  }
  &__value {
    max-width: 100%;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    overflow: hidden;

    border-radius: 6px;
    text-align: center;
    .a-label:not(.borderless) & {
      border: 1px solid $color-gray-bg-dark;
    }

    padding: 4px 10px 4px 10px;
    box-sizing: border-box;

    .postfix {
      justify-self: flex-end;
      padding: 0;
      padding-left: 5px;
      margin: 0;

      border-left: 2px solid $color-gray-bg-dark;
    }
  }
  &.postfix {
    .a-label {
      &__value {
        &-value {
          padding-right: 5px;
        }
      }
    }
  }
  &.labelUnderline {
    .a-label {
      &__label {
        border-bottom: 1px solid $color-gray;
      }
    }
  }
  &.greyish {
    .a-label {
      &__value {
        border: none;
        box-shadow: none;
        background-color: $color-gray-bg-dark;
      }
    }
  }
  &.labeled:not(.small) {
    margin-top: 30px;
  }
  &.small {
    margin-top: 15px;
    height: 30px;

    .a-label__label {
      bottom: 100%;
    }
    .a-label__value {
      border-radius: 4px;
    }
  }
}
</style>
