<template>
  <div class="block-wrapper">
    <div v-if="localProductType">
      <div v-if="localProductType.deleted" class="entity-alert-deleted">Тип изделия удален !</div>
      <div v-if="localProductType" class="entity-title-block">
        <div class="entity-title-block__title">
          Тип изделия:&nbsp;
          <span v-if="localProductType.id" class="a-bold"> {{ localProductType.id }}. </span>
          &emsp;{{ localProductType.name }}
        </div>
      </div>
      <div v-else class="entity-title-block">
        <div class="entity-title-block__title">Новый тип изделия</div>
      </div>
      <div class="entity-details my-20">
        <div class="entity-details_block">
          <AInput
            v-model="localProductType.name"
            :read-only="!accessToChange"
            class="entity-details__desc"
            label="Название типа изделия"
            :error="getErrMess('name')"
          />
        </div>
      </div>
      <div class="entity-details my-20">
        <div class="entity-details_block">
          <ASelect
            v-model="localProductType.complexity"
            :options="PRODUCT_TYPE_COMPLEXITY_DICTIONARY"
            disable-deleted
            select-label="label"
            track-key="value"
            label="Сложность пошива"
            :read-only="!accessToChange"
            :error="getErrMess('complexity')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import accessMixin from '@/mixin/accessMixin.js';
import validateMixin from '@/mixin/validateChild.js';
import { PRODUCT_TYPE_COMPLEXITY_DICTIONARY } from '../../../../constants';

export default {
  name: 'ProductTypeBlock',
  mixins: [accessMixin, validateMixin],
  props: {
    selectedEntity: {
      type: Object,
      default: null,
    },
    creatingEntity: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:selectedEntity'],
  data() {
    return {};
  },
  computed: {
    localProductType: {
      get() {
        return this.selectedEntity;
      },
      set(val) {
        this.$emit('update:selectedEntity', val);
      },
    },
    accessToChange() {
      return this.isCanChangeAssortment && !this.selectedEntity?.deleted && !this.loading;
    },
  },
  created() {
    this.PRODUCT_TYPE_COMPLEXITY_DICTIONARY = PRODUCT_TYPE_COMPLEXITY_DICTIONARY;
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@include assortment-base-entity;
</style>
