<template>
  <div class="production-table-block-wrapper" :style="cssVars">
    <div v-if="globalLoading">
      <ALoader centered />
    </div>
    <div v-else>
      <table
        v-if="pTableOrders && columns"
        class="production-table-orders-table"
        @mousedown="mousedownHandler"
        @mouseover="mouseoverHandler"
      >
        <tr class="headers">
          <th
            v-for="column of columns"
            :key="column.key"
            :class="{
              [column.class]: column.class,
              sortable: column.sortable,
              active: sort.sortBy === column.key,
              reverse: sort.reverse,
              pointer: column.sortable,
            }"
            @click="column.sortable ? sortByHandler(column.key) : null"
          >
            <div
              class="title-text-wrapper"
              :class="{
                [column.class]: column.class,
              }"
            >
              <span>
                {{ column.label }}
              </span>
              <span
                v-if="column.sortable && sort.sortBy !== column.key"
                class="title-icon-group-wrapper"
              >
                <i class="fa fa-angle-up title-icon" style="font-size: 9px" title="сортировать"></i>
                <i
                  class="fa fa-angle-down title-icon"
                  style="font-size: 9px"
                  title="сортировать"
                ></i>
              </span>
              <span v-else-if="column.sortable" class="title-icon-wrapper">
                <i class="fa fa-angle-up title-icon" style="font-size: 9px" title="сортировать"></i>
              </span>
            </div>
          </th>
        </tr>

        <tr v-for="(item, ind) of pTableOrders" :key="item.guid">
          <td
            v-if="isDisplayedColumn('tagId')"
            :data-table-value="`tagId__${ind}`"
            :class="{ selectCell: isSelectCell('tagId', ind) }"
            class="tagId"
          >
            <div
              class="order-tag-color"
              :style="{ 'background-color': item.colorTag }"
              :title="item.tagName"
            />
          </td>
          <td
            v-if="isDisplayedColumn('category')"
            :data-table-value="`category__${ind}`"
            :class="{ selectCell: isSelectCell('category', ind) }"
            class="category"
          >
            <div class="name-wrapper">
              <div class="ml-5">{{ item.categoryNames.join(', ') }}</div>
            </div>
          </td>
          <td
            v-if="isDisplayedColumn('guid')"
            :data-table-value="`guid__${ind}`"
            :class="{ selectCell: isSelectCell('guid', ind) }"
            class="guid"
          >
            <div class="pointer" @click="copyText(item.guid)">{{ item.guid.slice(-4) }}</div>
          </td>

          <td
            v-if="isDisplayedColumn('name')"
            :data-table-value="`name__${ind}`"
            :class="{ selectCell: isSelectCell('name', ind) }"
            class="name"
          >
            <div class="name-wrapper">
              <i
                class="fa fa-external-link-square-alt action-icon"
                style="font-size: 16px"
                @click="openWindow(item.orderUrl)"
              ></i>
              <div class="ml-5">{{ item.name }}</div>
            </div>
          </td>

          <td
            v-if="isDisplayedColumn('amoId')"
            :data-table-value="`amoId__${ind}`"
            :class="{ selectCell: isSelectCell('amoId', ind) }"
            class="amoId"
          >
            <div v-if="item.amoId" class="a-row">
              <i
                class="fa fa-copy action-icon mr-10"
                style="font-size: 16px"
                @click="copyText(item.amoId)"
              ></i>

              <i
                class="fas fa-external-link-square-alt action-icon"
                style="font-size: 16px"
                @click="openWindow(item.amoId)"
              ></i>
            </div>
            <div v-else />
          </td>

          <td
            v-if="isDisplayedColumn('cardsQuantity')"
            :data-table-value="`cardsQuantity__${ind}`"
            :class="{ selectCell: isSelectCell('cardsQuantity', ind) }"
            class="cardsQuantity"
          >
            <div>
              {{ item.cardsQuantity }}
            </div>
          </td>

          <td
            v-if="isDisplayedColumn('allQuantity')"
            :data-table-value="`allQuantity__${ind}`"
            :class="{ selectCell: isSelectCell('allQuantity', ind) }"
            class="allQuantity"
          >
            <div>
              {{ item.allQuantity }}
            </div>
          </td>

          <td
            v-if="isDisplayedColumn('noComplexityQuantity')"
            :data-table-value="`${
              PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.NO_COMPLEXITY]
            }__${ind}`"
            :class="{
              [PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.NO_COMPLEXITY]]: true,
              selectCell: isSelectCell(
                PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.NO_COMPLEXITY],
                ind
              ),
            }"
          >
            <div>
              {{ item.noComplexityQuantity }}
            </div>
          </td>
          <td
            v-if="isDisplayedColumn('easyQuantity')"
            :data-table-value="`${
              PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.EASY]
            }__${ind}`"
            :class="{
              [PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.EASY]]: true,
              selectCell: isSelectCell(
                PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.EASY],
                ind
              ),
            }"
          >
            <div>
              {{ item.easyQuantity }}
            </div>
          </td>
          <td
            v-if="isDisplayedColumn('mediumQuantity')"
            :data-table-value="`${
              PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.MEDIUM]
            }__${ind}`"
            :class="{
              [PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.MEDIUM]]: true,
              selectCell: isSelectCell(
                PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.MEDIUM],
                ind
              ),
            }"
          >
            <div>
              {{ item.mediumQuantity }}
            </div>
          </td>
          <td
            v-if="isDisplayedColumn('hardQuantity')"
            :data-table-value="`${
              PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.HARD]
            }__${ind}`"
            :class="{
              [PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.HARD]]: true,
              selectCell: isSelectCell(
                PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.HARD],
                ind
              ),
            }"
          >
            <div>
              {{ item.hardQuantity }}
            </div>
          </td>
          <td
            v-if="isDisplayedColumn('veryHardQuantity')"
            :data-table-value="`${
              PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.VERY_HARD]
            }__${ind}`"
            :class="{
              [PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.VERY_HARD]]: true,
              selectCell: isSelectCell(
                PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.VERY_HARD],
                ind
              ),
            }"
          >
            <div>
              {{ item.veryHardQuantity }}
            </div>
          </td>

          <td
            v-if="isDisplayedColumn('managerName')"
            :data-table-value="`managerName__${ind}`"
            :class="{ selectCell: isSelectCell('managerName', ind) }"
            class="managerName"
          >
            <div>
              {{ item.managerName }}
            </div>
          </td>

          <td
            v-if="isDisplayedColumn('timeToProduction')"
            :data-table-value="`timeToProduction__${ind}`"
            :class="{ selectCell: isSelectCell('timeToProduction', ind) }"
            class="timeToProduction"
          >
            <div>
              {{ item.timeToProduction }}
            </div>
          </td>

          <td
            v-if="isDisplayedColumn('deadline')"
            :data-table-value="`deadline__${ind}`"
            :class="{
              selectCell: isSelectCell('deadline', ind),
              'deadline-expired': item.isDeadlineExpired,
            }"
            class="deadline"
          >
            <div>
              {{ item.deadline }}
            </div>
          </td>
          <td
            v-if="isDisplayedColumn('packageDate')"
            :data-table-value="`packageDate__${ind}`"
            :class="{
              selectCell: isSelectCell('packageDate', ind),
              'deadline-expired': item.isPackageDateExpired,
            }"
            class="packageDate"
          >
            <div>
              {{ item.packageDate }}
            </div>
          </td>

          <td
            v-if="isDisplayedColumn('status')"
            :data-table-value="`status__${ind}`"
            :class="{ selectCell: isSelectCell('status', ind) }"
            class="status"
          >
            <div>
              {{ item.status }}
            </div>
          </td>

          <td
            v-if="isDisplayedColumn('designerName')"
            :data-table-value="`designerName__${ind}`"
            :class="{ selectCell: isSelectCell('designerName', ind) }"
            class="designerName"
          >
            <div>
              {{ item.designerName }}
            </div>
          </td>

          <td
            v-if="isDisplayedColumn('createdCardDate')"
            :data-table-value="`createdCardDate__${ind}`"
            :class="{ selectCell: isSelectCell('createdCardDate', ind) }"
            class="createdCardDate"
          >
            <div>
              {{ item.createdCardDate }}
            </div>
          </td>

          <td
            v-if="isDisplayedColumn('techDesignDoneDate')"
            :data-table-value="`techDesignDoneDate__${ind}`"
            :class="{ selectCell: isSelectCell('techDesignDoneDate', ind) }"
            class="techDesignDoneDate"
          >
            <div>
              {{ item.techDesignDoneDate }}
            </div>
          </td>
        </tr>

        <!-- SummaryRow -->
        <tr>
          <th v-if="isDisplayedColumn('tagId')" class="tagId">
            <div></div>
          </th>
          <th v-if="isDisplayedColumn('category')" class="category">
            <div></div>
          </th>
          <th v-if="isDisplayedColumn('guid')" class="guid">
            <div>{{ summaryRow.count }} шт.</div>
          </th>
          <th v-if="isDisplayedColumn('name')" class="name">
            <div></div>
          </th>
          <th v-if="isDisplayedColumn('amoId')" class="amoId">
            <div></div>
          </th>
          <th v-if="isDisplayedColumn('cardsQuantity')" class="cardsQuantity">
            <div></div>
          </th>
          <th v-if="isDisplayedColumn('allQuantity')" class="allQuantity">
            <div>{{ summaryRow.allQuantity }}</div>
          </th>
          <th
            v-if="isDisplayedColumn('noComplexityQuantity')"
            :class="PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.NO_COMPLEXITY]"
          >
            <div>
              {{
                summaryRow[
                  PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.NO_COMPLEXITY]
                ]
              }}
            </div>
          </th>
          <th
            v-if="isDisplayedColumn('easyQuantity')"
            :class="PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.EASY]"
          >
            <div>
              {{ summaryRow[PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.EASY]] }}
            </div>
          </th>
          <th
            v-if="isDisplayedColumn('mediumQuantity')"
            :class="PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.MEDIUM]"
          >
            <div>
              {{
                summaryRow[PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.MEDIUM]]
              }}
            </div>
          </th>
          <th
            v-if="isDisplayedColumn('hardQuantity')"
            :class="PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.HARD]"
          >
            <div>
              {{ summaryRow[PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.HARD]] }}
            </div>
          </th>
          <th
            v-if="isDisplayedColumn('veryHardQuantity')"
            :class="PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.VERY_HARD]"
          >
            <div>
              {{
                summaryRow[PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS[PRODUCT_TYPE_COMPLEXITY.VERY_HARD]]
              }}
            </div>
          </th>
          <th class="managerName">
            <div></div>
          </th>
          <th class="timeToProduction">
            <div></div>
          </th>
          <th class="deadline">
            <div></div>
          </th>
          <th class="status">
            <div></div>
          </th>
          <th class="designerName">
            <div></div>
          </th>
          <th class="createdCardDate">
            <div></div>
          </th>
          <th class="techDesignDoneDate">
            <div></div>
          </th>
        </tr>
      </table>
      <AggregateRowBlock :aggregated-data="aggregatedData" />
    </div>
  </div>
</template>

<script>
import { computed, reactive, toRefs, watch } from 'vue';
import accessMixin from '@/mixin/accessMixin.js';
import { copyToClipboard } from '@/utils/clipboard.js';
import { PRODUCT_TYPE_COMPLEXITY } from '../../../constants';
import { useProductTableAggregation } from '@/use/productionTable/useProductTableAggregation.js';
import { PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS } from '../productionTableDictionary';
import AggregateRowBlock from './AggregatedRow.vue';
import { orderBy } from '@/utils/lodash.js';

const TABLE_HEIGHT_OFFSET = 110;
export default {
  name: 'ProductionTableBlock',
  components: { AggregateRowBlock },
  mixins: [accessMixin],
  props: {
    productionTableOrders: {
      type: Array,
      required: true,
    },
    summaryRow: {
      type: Object,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    displayCustomization: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { columns, filters, productionTableOrders, summaryRow } = toRefs(props);
    const globalLoading = computed(() => props.loading);

    // const getColumns = computed(() => {
    //   console.log(columns);
    //   return columns.value;
    // });

    watch(
      () => filters,
      () => {
        resetSelectHandler();
      },
      { deep: true }
    );

    const sort = reactive({
      sortBy: null,
      reverse: null,
    });
    const sortByHandler = (key) => {
      resetSelectHandler();
      if (sort.sortBy === key) {
        sort.reverse = !sort.reverse;
      } else {
        sort.sortBy = key;
      }
    };
    const sortedProductTableOrders = computed(() =>
      orderBy(productionTableOrders.value, [sort.sortBy], [sort.reverse ? 'desc' : 'asc'])
    );

    const {
      mouseupHandler,
      mousedownHandler,
      mouseoverHandler,
      copyTableHandler,
      resetSelectHandler,

      selectedRange,
      isSelectCell,
      isSelectRange,
      startedSelectCell,

      aggregatedData,
    } = useProductTableAggregation(columns, sortedProductTableOrders);

    const isDisplayedColumn = (key) => props.columns.find((c) => c.key === key);

    return {
      sortByHandler,

      pTableOrders: sortedProductTableOrders,
      pTableSummary: summaryRow,

      mouseupHandler,
      mousedownHandler,
      mouseoverHandler,
      copyTableHandler,

      aggregatedData,

      sort,

      isSelectCell,
      selectedRange,
      isSelectRange,
      startedSelectCell,

      globalLoading,

      isDisplayedColumn,
    };
  },
  data() {
    return {
      tableHeight: window.innerHeight - TABLE_HEIGHT_OFFSET,
    };
  },
  computed: {
    cssVars() {
      return {
        '--production-table-height': this.tableHeight + 'px',
      };
    },
  },
  created() {
    this.PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS = PRODUCT_TYPE_COMPLEXITY_QUANTITY_KEYS;
    this.PRODUCT_TYPE_COMPLEXITY = PRODUCT_TYPE_COMPLEXITY;
  },
  mounted() {
    window.addEventListener('resize', this.changeCssWatch);
    document.addEventListener('keydown', this.copyTableHandler);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.changeCssWatch);
    document.removeEventListener('keydown', this.copyTableHandler);
  },
  methods: {
    changeCssWatch() {
      this.tableHeight = window.innerHeight - TABLE_HEIGHT_OFFSET;
    },
    openWindow(url) {
      window.open(url, '_blank');
    },
    copyText(text) {
      copyToClipboard(text);
      this.$notify({ title: 'Скопировано' });
    },
  },
};
</script>
<style lang="scss" scoped>
.production-table-block-wrapper {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  @include scroll-wider;

  .action-icon {
    cursor: pointer;
    &:active {
      transform: scale(0.9);
      box-shadow: inset 0px -1px 2px $color-white-smoke;
    }
  }
  .order-tag-color {
    width: 10px;
    height: 15px;
    margin-right: 3px;
    border-radius: 4px;
  }
}

@mixin tabble-cell {
  font-size: 13px;
  color: $smoke-blue;
  vertical-align: top;

  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 7px;
  padding-right: 7px;

  border-bottom: 1px solid $color-light-silver;

  &:first-child {
    padding-left: 15px;
  }

  &:last-child {
    padding-left: 15px;
  }
}

.production-table-block-wrapper {
  height: var(--production-table-height);
  width: 100%;
  overflow: auto;
  @include scroll-wide;
  display: flex;
  flex-direction: row;
  position: relative;
}

.production-table-orders-table {
  @include noSelect;
  // &.noselecttable {
  //   @include noSelect;
  // }

  border-collapse: collapse;

  overflow: auto;
  @include scroll-wider;
  height: 100%;

  border-collapse: collapse;
  width: 100%;

  display: flex;
  flex-flow: column;

  .headers {
    position: sticky;
    top: 0;

    background-color: $color-white;
    z-index: 1;

    vertical-align: middle;
    text-align: center;
    th {
      font-size: 12px;
      border-bottom: 1px solid transparent;
    }
    box-shadow: 0px 2px 3px 0px rgba(191, 191, 191, 0.26);
  }

  .guid:hover {
    text-decoration: underline;
  }

  .tagId div {
    width: 10px;
  }
  .guid div {
    width: 70px;
  }
  .category {
    div {
      width: 120px;
    }
  }
  .name {
    div {
      width: 280px;
    }
  }
  .amoId div {
    width: 60px;
  }

  .cardsQuantity,
  .allQuantity,
  .noComplexityQuantity,
  .easyQuantity,
  .mediumQuantity,
  .hardQuantity,
  .veryHardQuantity {
    div {
      width: 45px;
    }
  }

  .status div {
    width: 140px;
  }

  .managerName,
  .designerName {
    div {
      width: 120px;
    }
  }

  .timeToProduction div {
    width: 45px;
  }

  .deadline,
  .packageDate,
  .createdCardDate,
  .techDesignDoneDate {
    div {
      width: 75px;
    }
  }

  .last-row {
    td {
      @include td-th-default;
    }
  }
}

.name-wrapper {
  display: flex;
}

td,
th {
  @include tabble-cell;
}

td div {
  min-height: 20px;
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  tr:hover & {
    max-width: 100%;
    white-space: normal;

    word-break: break-all;
  }
}
td {
  transition: background-color 0.2s;
}
.selectCell {
  background-color: rgba(85, 175, 193, 0.236);
}
.deadline-expired {
  color: $color-red;
}

.title-icon-group-wrapper,
.title-icon-wrapper {
  display: flex;
  flex-direction: column;
  opacity: 1;
  opacity: 0.5;
}

th.active .title-icon {
  opacity: 1;
}
th.active.reverse .title-icon {
  transform: rotate(180deg);
}

.title-icon {
  margin-top: 2px;
  margin-left: 5px;
  opacity: 0.5;
}

.title-text-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
</style>
