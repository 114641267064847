import uploadApi from '@/api/uploadApi';

export default {
  async uploadImage(_, { file, key, entityId }) {
    const { data } = await uploadApi.uploadImage({ file, key, entityId });
    return data;
  },

  async uploadArticle(_, { file, entityId }) {
    const { data } = await uploadApi.uploadArticle({ file, entityId });
    return data;
  },

  async uploadFile(_, { file, key, entityId }) {
    const { data } = await uploadApi.uploadImage({ file, key, entityId });
    return data;
  },
};
