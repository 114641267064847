<template>
  <GDialog
    v-model="showModal"
    :persistent="persistent"
    content-class="a-modal-wrapper aside-change-production-line-modal-preview"
    max-width="2000"
  >
    <div class="modal__content">
      <div class="modal__title">{{ productionLine.name }} произв. линия:</div>
      <div class="modal__block">
        <div class="modal__block-items">
          <div
            v-for="(depsSet, ind) of productionLine.departmentSettings"
            :key="ind"
            class="modal__block-item-deps-wrapper"
          >
            <div class="modal__block-item-deps">
              <div
                v-for="(dep, ink) of depsSet"
                :key="`${ind}_${ink}_${dep.id}`"
                class="modal__block-item"
              >
                <ALabel :value="prepareDepartmentDictionary[dep.departmentId].name" />
              </div>
            </div>

            <div class="arrow">
              <Fa
                name="icon"
                icon="long-arrow-alt-right"
                class="icon faicon"
                style="width: 30px; height: 30px"
              />
            </div>
          </div>
          <div class="done">
            <ABadge big> Готовое изделие </ABadge>
          </div>
        </div>
        <div class="modal__save-btn">
          <AButton small @click="confirmItemHandler"> Сохранить </AButton>
        </div>
      </div>
    </div>
  </GDialog>
</template>
<script>
import { GDialog } from 'gitart-vue-dialog';
import { mapState } from 'vuex';
import { prepareDictionary } from '../../../utils/dictionaryUtils';

export default {
  name: 'AsideChangeProductionLineModalPreview',
  components: { GDialog },
  props: {
    modelValue: {
      type: Boolean,
      default: null,
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: true,
    },
    onConfirm: {
      type: Function,
      default: null,
    },
    onClose: {
      type: Function,
      default: null,
    },
    productionLine: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    ...mapState({
      departments: (s) => s.production.departments,
    }),
    prepareDepartmentDictionary() {
      return prepareDictionary(this.departments);
    },
    showModal: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
        if (!value && this.onClose) {
          return this.onClose(this);
        }
      },
    },
  },
  methods: {
    confirmItemHandler() {
      return this.onConfirm(this);
    },
  },
};
</script>
<style lang="scss" scoped>
.modal {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px 30px;
  }
  &__title {
    @include InterSemibold;
    font-size: 16px;
    padding: 5px 15px 20px;
  }
  &__text {
    margin-top: 5px;
  }
  &__save-btn {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }
}

.a-modal-wrapper {
  width: fit-content;
}

// изделия
.modal__block {
  &-items {
    display: flex;
    flex-direction: row;
    justify-content: center;
    // background-color: red;
  }
  &-item-deps-wrapper {
    display: flex;
    flex-direction: row;
  }
  &-item-deps {
    display: flex;
    flex-direction: column;
  }
  &-item {
    display: flex;
    margin: 10px;

    width: 100%;
    height: 100%;

    align-self: center;
    justify-content: center;
    align-items: center;
  }
}
.arrow,
.done {
  display: flex;
  margin: 0px 5px;

  align-self: center;
  justify-content: center;
  align-items: center;
}
</style>
