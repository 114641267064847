<template>
  <ul class="sections__tabs">
    <li
      v-for="(section, ind) of links"
      :key="`${section.path}_${ind}`"
      class="sections__tab"
      :class="{ active: isActiveTab(section) }"
    >
      <router-link :to="{ path: section.path }" class="section__link">
        <div class="sections__icon-cover">
          <fa
            class="icon"
            style="width: 25px; height: 25px"
            :icon="section.icon"
            :class="isActiveTab(section) && 'active-icon'"
          />
        </div>
        <div class="sections__name">
          {{ section.name }}
        </div>
      </router-link>
      <NavigationSection
        class="children"
        :links="section.children"
        :isNavOpen="isNavOpen"
        v-if="section.children?.length"
      >
      </NavigationSection>
    </li>
  </ul>
</template>

<script>
import NavigationSection from "./navigation-section.vue";

export default {
  name: "NavigationSection",
  components: {
    NavigationSection,
  },
  props: {
    links: {
      type: Array,
      required: true,
    },
    isNavOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentPath: this.$route.path || "/",
    };
  },
  computed: {
    isActiveTab() {
      return (tab) =>
        tab.regExpPathActive
          ? tab.regExpPathActive.test(this.currentPath)
          : tab.path === this.currentPath;
    },
  },
  watch: {
    $route: {
      handler() {
        this.currentPath = this.$route.path;
      },
      deep: true,
    },
  },
  methods: {
    linkHandler(path) {
      if (this.$route.path != path) this.$router.push({ path });
    },
  },
};
</script>

<style lang="scss" scoped>
$padding-left: 20px;

.icon {
  color: $color-gray;

  &.active-icon {
    color: $cornflower-blue;
  }
  transition: color 0.3s;
}

.nav.open {
  .header {
    .header__logo {
      width: 100%;
      opacity: 1;
    }
  }
  .profile-block {
    margin-left: 22px;

    &__icon-cover {
      padding: 0px 20px;
    }

    &__manager {
      width: fit-content;
      transition: opacity 0.3s;
      opacity: 1;
      visibility: visible;
    }
  }
  .sections {
    &__tabs {
      transition: margin-left 0.3s;
    }
    &__tabs.children {
      .sections__icon-cover {
        opacity: 0;
        padding-left: $padding-left;
      }
    }
    &__icon-cover {
      padding-left: $padding-left;
    }
  }
  .bottom-block {
    .exit {
      opacity: 1;
      transition: opacity 0.2s;
      width: fit-content;
      transition: width 0.7s;
    }
    .exit-icon {
      .fa-exit-icon {
        transform: rotateZ(0deg);
      }
    }
  }
}

.open .sections__tabs .children {
  .sections__name {
    margin-left: 20px;
    transition: margin-left 0.3s;
  }
}
.sections__tabs .children {
  .sections__name {
    transition: margin-left 0.3s !important;
  }
}

.sections {
  &__tabs {
    margin: 0;
    padding: 0;
    list-style: none;
    transition: margin-left 0.3s;
    overflow: hidden;

    .navigation-group-list {
      padding-left: 32px;
      margin-left: 0;

      overflow: hidden;
      transition: all 0.3s ease-in-out;
    }
  }

  &__tab {
    display: flex;
    flex-direction: column;

    @include noSelect;
    a {
      display: flex;
      flex-direction: row;
      text-decoration: none;
      align-items: center;
      cursor: pointer;
      width: 100%;
      padding: 16px 0px;
    }
  }
  &__icon-cover {
    display: flex;
    align-items: center;
    justify-content: center;

    padding-right: 20px;
    padding-left: 20px;
    transition: padding-left 0.3s;
    transition: opacity 0.2s;
  }

  &__tab.active > .section__link {
    background-color: $smoke-blue;
    transition: background-color 0.3s;
    .sections__name {
      color: $cornflower-blue;
      transition: color 0.3s;
    }
  }
  .sections__tab:hover > a:hover > .sections__name {
    color: $cornflower-blue;
    transition: color 0.3s;
  }

  &__name {
    color: $color-gray;
    white-space: nowrap;
  }
}
</style>
