<template>
  <GDialog
    v-model="showModal"
    :persistent="persistent"
    content-class="a-modal-wrapper aside-change-production-line-modal"
    max-width="700"
  >
    <div class="modal__content">
      <div class="modal__title">{{ title || 'Распределение карт с удаленных отделов' }}</div>
      <div class="modal__title">
        <p>
          На удаленных участках могут находится карты, необходимо выбрать, в какой отдел попадут эти
          карты.
        </p>
        <p>Карты будут находится в состоянии "Очередь" в выбранном отделе.</p>
      </div>
      <div class="modal__block">
        <div class="modal__block-items">
          <div
            v-for="depForDel of deletedDepartments"
            :key="depForDel.id"
            class="modal__block-item"
          >
            <div class="deleted"><ALabel :value="depForDel.name" /></div>
            <div class="arrow">
              <Fa
                name="icon"
                icon="long-arrow-alt-right"
                class="icon faicon"
                style="width: 30px; height: 30px"
              />
            </div>
            <div class="new-dep">
              <ASelect
                v-model="resolveChangedDepartments[depForDel.id]"
                :options="existingDepartments"
                disable-deleted
                select-label="name"
                block-unselect
              />
            </div>
          </div>
        </div>
        <div class="modal__save-btn">
          <AButton small :disabled="disableSave" @click="confirmItemHandler"> Сохранить </AButton>
        </div>
      </div>
    </div>
  </GDialog>
</template>
<script>
import { GDialog } from 'gitart-vue-dialog';
import { cloneDeep } from '@/utils/lodash.js';
import { mapState } from 'vuex';

export default {
  name: 'AsideDeleteProductionLineModal',
  components: { GDialog },
  props: {
    modelValue: {
      type: Boolean,
      default: null,
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: true,
    },
    onConfirm: {
      type: Function,
      default: null,
    },
    onClose: {
      type: Function,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    deletedDepartmentIds: {
      type: Array,
      required: true,
    },
    existingDepartmentsIds: {
      type: Array,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      resolveChangedDepartments: {},
    };
  },
  computed: {
    ...mapState({
      departments: (s) => s.production.departments,
    }),
    deletedDepartments() {
      return this.departments.filter((d) => this.deletedDepartmentIds.includes(d.id));
    },
    existingDepartments() {
      return this.departments
        .filter((d) => this.existingDepartmentsIds.includes(d.id))
        .concat([{ id: 'endCard', name: 'Завершить' }]);
    },
    disableSave() {
      return (
        Object.values(this.resolveChangedDepartments).filter((_) => _).length !==
        this.deletedDepartmentIds.length
      );
    },
    showModal: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
        if (!value && this.onClose) {
          return this.onClose(this);
        }
      },
    },
  },
  created() {},
  methods: {
    confirmItemHandler() {
      return this.onConfirm(this, cloneDeep(this.resolveChangedDepartments));
    },
  },
};
</script>
<style lang="scss" scoped>
.modal {
  margin-bottom: 999px;
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px 30px;
  }
  &__title {
    @include InterSemibold;
    font-size: 16px;
    padding: 5px 15px 20px;
  }
  &__text {
    margin-top: 5px;
  }
  &__save-btn {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }
}

.a-modal-wrapper {
  width: fit-content;
}

// изделия
.modal__block {
  &-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &-item {
    // display: flex;
    // width: 100%;
    // justify-content: space-between;
    width: 450px;
    display: grid;
    grid-template-columns: 1fr 45px 1fr;
    margin-bottom: 10px;
    // align-self: center;
    // background-color: rgba(255, 0, 0, 0.155);
    div {
      width: 100%;
      // background-color: red;
    }
    .arrow {
      display: flex;
      align-self: center;
      justify-content: center;
    }
    .deleted {
      display: flex;
      align-self: center;
      // background-color: red;
    }
    .new-dep {
      // background-color: red;
    }
  }
}
</style>
<style lang="scss">
.aside-change-production-line-modal {
  padding-bottom: 300px;
}
</style>
