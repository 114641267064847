<template>
  <a class="a-pagination-link" :class="{ laquo, raquo, active }">
    <slot />
  </a>
</template>

<script>
export default {
  name: "APaginationLink",
  props: {
    laquo: {
      type: Boolean,
      default: false,
    },
    raquo: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.a-pagination-link:not([href]):not([tabindex]) {
  position: relative;

  display: block;

  box-sizing: border-box;
  height: 40px;
  min-width: 40px;

  @include InterSemibold;
  line-height: 40px;
  color: $smoke-blue-50-opacity;

  padding-left: 13px;
  padding-right: 13px;

  border: 1px solid $color-white-smoke;
  background-color: $color-white;

  cursor: pointer;

  transition: color 0.15s ease-in-out;
  will-change: color;

  li:not(:last-child) & {
    border-right: none;
  }

  &.active {
    color: $smoke-blue;
  }

  &:hover,
  &.active {
    color: $smoke-blue;
  }

  &.laquo,
  &.raquo {
    &::after {
      content: "";
      position: absolute;

      left: 50%;
      top: 50%;

      width: 0;
      height: 0;

      border-top: 4px solid $color-white-smoke;
      border-right: 4px solid $color-white-smoke;
      border-bottom: 4px solid transparent;
      border-left: 4px solid transparent;

      border-radius: 2px;
    }
  }

  &.laquo::after {
    transform: translate(-20%, -50%) rotate(45deg) scale(-1);
  }

  &.raquo::after {
    transform: translate(-80%, -50%) rotate(45deg);
  }
}

li:first-child .a-pagination-link {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

li:last-child .a-pagination-link {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
</style>
