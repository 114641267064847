<template>
  <APageLayout>
    <template #header>
      <div class="page-header">
        <div class="page-header__container">
          <div class="mr-40">Настройки заказа</div>
          <div class="mr-20">
            <AToggle
              v-model="showDeletedEntity"
              label="Показать удаленные сущности"
              class="toggle"
            />
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <div class="content-wrapper order-setting-page-content-wrapper">
        <APageBlock class="mb-20" toggle>
          <template #header> Теги заказа </template>
          <div class="content">
            <div class="table-filter">
              <AInput v-model.trim="tableFilters.orderTags" placeholder="Фильтр" />
            </div>
            <ATable
              :items="orderTagsPagination.chunkedItems"
              :fields="orderTagsFields"
              class="order-tags-table mb-20"
              :loading="loading"
              :row-classes="(row) => orderTagsTableRowClasses(row)"
              :row-click="(row) => (selecteOrderTag = row)"
            >
              <template #color="{ item }">
                <div
                  :style="{ 'background-color': `#${item.color}` }"
                  class="order-tags-color"
                ></div>
              </template>
            </ATable>
            <APagination
              v-model:currentPage="orderTagsPagination.currentPage"
              v-model:currentOffset="orderTagsPagination.offset"
              :items="filtredOrderTags"
              :all="filtredOrderTags.length"
              :loading="loading"
              :offsets="[10, 20, 50, 0]"
              mode="local"
              @changeOffset="orderTagsPagination.offset = $event"
              @update:chunkedItems="orderTagsPagination.chunkedItems = $event"
            />
            <div>
              <AButton
                v-if="isCanChangeProduction || isCanChangeOrder"
                class="mb-10 mt-10"
                @click="createOrderTag = true"
                >Создать тег
              </AButton>
            </div>
          </div>
        </APageBlock>

        <APageBlock class="mb-20" toggle>
          <template #header> Категории заказа </template>
          <div class="content">
            <div class="table-filter">
              <AInput v-model.trim="tableFilters.orderCategory" placeholder="Фильтр" />
            </div>
            <ATable
              :items="orderCategoryPagination.chunkedItems"
              :fields="orderCategoryFields"
              class="order-category-table mb-20"
              :loading="loading"
              :row-classes="(row) => orderCategoryTableRowClasses(row)"
              :row-click="(row) => (selecteOrderCategory = row)"
            >
              <template #color="{ item }">
                <div
                  :style="{ 'background-color': `#${item.color}` }"
                  class="order-tags-color"
                ></div>
              </template>
            </ATable>
            <APagination
              v-model:currentPage="orderCategoryPagination.currentPage"
              v-model:currentOffset="orderCategoryPagination.offset"
              :items="filtredOrderCategory"
              :all="filtredOrderCategory.length"
              :loading="loading"
              :offsets="[10, 20, 50, 0]"
              mode="local"
              @changeOffset="orderCategoryPagination.offset = $event"
              @update:chunkedItems="orderCategoryPagination.chunkedItems = $event"
            />
            <div>
              <AButton
                v-if="isCanChangeProduction || isCanChangeOrder"
                class="mb-10 mt-10"
                @click="createOrderCategory = true"
                >Создать категорию
              </AButton>
            </div>
          </div>
        </APageBlock>

        <CreateOrderTagAsideBlock
          :creating-order-tag="createOrderTag"
          :order-tag="selecteOrderTag"
          @close="closeAsideOrderTagsPageHandler"
        />
        <CreateOrderCategoryAsideBlock
          :creating-order-category="createOrderCategory"
          :order-category="selecteOrderCategory"
          @close="closeAsideOrderCategoryPageHandler"
        />
      </div>
    </template>
  </APageLayout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { reactive } from 'vue';
import accessMixin from '@/mixin/accessMixin.js';
import CreateOrderTagAsideBlock from './components/CreateOrderTagAsideBlock.vue';
import CreateOrderCategoryAsideBlock from './components/CreateOrderCategoryAsideBlock.vue';
import { orderTagsFields, orderCategoryFields } from './orderSettingDictionary';
import { filterArrayByFields } from '@/utils/filters.js';

export default {
  name: 'OrderSetting',
  components: {
    CreateOrderTagAsideBlock,
    CreateOrderCategoryAsideBlock,
  },
  mixins: [accessMixin],
  setup() {
    return {
      orderTagsPagination: reactive({
        current: 1,
        offset: 10,
        chunkedItems: reactive([]),
      }),
      orderCategoryPagination: reactive({
        current: 1,
        offset: 10,
        chunkedItems: reactive([]),
      }),
    };
  },
  data() {
    return {
      loading: true,

      selecteOrderTag: null,
      createOrderTag: false,

      selecteOrderCategory: null,
      createOrderCategory: false,

      showDeletedEntity: true,

      tableFilters: {
        orderTags: null,
        orderCategory: null,
        fieldsForFilter: ['id', 'name'],
      },
    };
  },
  computed: {
    ...mapState({
      orderTags: (s) => s.order.orderTags,
      orderCategory: (s) => s.order.orderCategory,
    }),
    filtredOrderTags() {
      const filtredOrderTags = !this.showDeletedEntity
        ? this.orderTags.filter((i) => !i.deleted)
        : this.orderTags;
      return filterArrayByFields(
        filtredOrderTags,
        this.tableFilters.orderTags,
        this.tableFilters.fieldsForFilter
      );
    },
    filtredOrderCategory() {
      const filtredOrderCategory = !this.showDeletedEntity
        ? this.orderCategory.filter((i) => !i.deleted)
        : this.orderCategory;
      return filterArrayByFields(
        filtredOrderCategory,
        this.tableFilters.orderCategory,
        this.tableFilters.fieldsForFilter
      );
    },
  },
  async created() {
    this.orderTagsFields = orderTagsFields;
    this.orderCategoryFields = orderCategoryFields;
    await this.bootstrap();
  },
  methods: {
    ...mapActions({
      getOrderTags: 'order/getOrderTags',
      getOrderCategory: 'order/getOrderCategory',
    }),
    async bootstrap() {
      this.loading = true;
      const promises = [this.getOrderTags(), this.getOrderCategory()];
      try {
        await Promise.all(promises);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    closeAsideOrderTagsPageHandler() {
      this.createOrderTag = false;
      this.selecteOrderTag = null;
    },
    closeAsideOrderCategoryPageHandler() {
      this.createOrderCategory = false;
      this.selecteOrderCategory = null;
    },
    orderTagsTableRowClasses(row) {
      if (row.id === this.selecteOrderTag?.id) return 'active-table-row';
      if (row.deleted) return 'row-deleted';
    },
    orderCategoryTableRowClasses(row) {
      if (row.id === this.selecteOrderCategory?.id) return 'active-table-row';
      if (row.deleted) return 'row-deleted';
    },
  },
};
</script>
<style lang="scss" scoped>
.page-header {
  @include InterBold;
  font-size: 18px;
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 36px;

  &__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
.content-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}
</style>

<style lang="scss">
.order-tags-table,
.order-category-table {
  .id {
    width: 60px;
  }
  .active-table-row {
    background-color: $color-active-item;
  }
  .row-deleted {
    background-color: $color-light-gray;
  }
}

.order-setting-page-content-wrapper {
  .order-tags-table {
    .id {
      width: 60px;
    }
    .order-tags-color {
      width: 50px;
      height: 15px;
    }
  }
}
</style>
