import BaseService from './services/BaseService.js';

class AssortmentApi extends BaseService {
  async getSets() {
    const data = await this.api.get('/item/sets');
    return data;
  }

  async getItems() {
    const data = await this.api.get('/item');
    return data;
  }

  async getClientDesigns({ clientId }) {
    const data = await this.api.get(`/item/client-designs/${clientId}`);
    return data;
  }

  async createItem({ item }) {
    const data = await this.api.post('/item', { item });
    return data;
  }
  async changeItem({ item }) {
    const data = await this.api.patch('/item', { item });
    return data;
  }

  async createSet({ set }) {
    const data = await this.api.post('/item/set', { set });
    return data;
  }

  async changeSet({ set }) {
    const data = await this.api.patch('/item/set', { set });
    return data;
  }
}
export default new AssortmentApi();
