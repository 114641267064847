<template>
  <div
    class="a-input-file"
    :class="{ disabled, greyish }"
    @click="clickHandler"
  >
    <div class="a-input-file__icon">
      <slot name="icon" />
    </div>
    <div class="a-input-file__content">
      <slot />
    </div>

    <input
      :id="`a-input-file-${uuid}`"
      :ref="`a-input-file-${uuid}`"
      type="file"
      name="file"
      class="input-file"
      :multiple="multiple"
      :disabled="disabled"
      @input="fileUploadHandler"
      @click="inputClickHandler"
    />
  </div>
</template>
<script>
import { isEqual } from "../../utils/lodash.js";
import uuid from "../../mixin/uuid.js";

export default {
  name: "AInputFile",
  mixins: [uuid],
  props: {
    modelValue: {
      type: [String, Array, File],
      default: null,
    },
    accept: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    formats: {
      type: Array,
      default: () => ["jpeg", "png"],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    max: {
      type: [Number, String],
      default: null,
    },
    greyish: {
      type: Boolean,
      default: false,
    },
    forceEmit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      files: [],
    };
  },
  computed: {
    data() {
      return this.files.filter((f) => f);
    },
  },
  watch: {
    files: {
      handler: function () {
        if (!this.multiple) {
          this.$emit("update:modelValue", this.data[0]);
          return;
        }
        this.$emit("update:modelValue", this.data);
      },
      deep: true,
    },
    modelValue: {
      handler: function () {
        this.updateFromProp();
      },
      deep: true,
    },
  },
  created() {
    this.updateFromProp();
  },
  methods: {
    clickHandler() {
      this.$refs[`a-input-file-${this.uuid}`].click();
    },
    fileUploadHandler() {
      const files = this.$refs[`a-input-file-${this.uuid}`].files;
      this.files = Array.from(files).reduce((acc, item) => {
        try {
          if (item) {
            const err = new Error();
            if (
              this.formats &&
              !this.formats.includes(item.type.split("/")[1])
            ) {
              err.message = `Недопустимый формат файла ${item.name ?? ""}.`;
              throw err;
            }
            if (this.max && Number(this.max) * 1024 * 1042 < item.size) {
              err.message = `Размер файла ${item.name ?? ""} сшишком большой`;
              throw err;
            }
            item.url = "";
            let URL = window.URL || window.webkitURL;
            if (URL && URL.createObjectURL) {
              item.url = URL.createObjectURL(item);
            }

            acc = acc.concat(item);
          }
        } catch (err) {
          this.$notifyError({ title: err.message });
        }
        return acc;
      }, []);
    },
    async fileFromString(data) {
      let file = null;

      if (typeof data === "string") {
        try {
          const blob = await fetch(data).then((res) => res.blob());
          file = new File([blob], data);
        } catch (err) {
          console.log(err);
        }
      }

      return file || data;
    },
    async updateFromProp() {
      let files = [];

      const isArray = Array.isArray(this.modelValue);

      if (!isEqual(this.modelValue, this.files)) {
        if (this.modelValue) {
          if (isArray) {
            if (this.modelValue.length) {
              files = await Promise.all(
                this.modelValue.map(
                  async (item) => await this.fileFromString(item)
                )
              );
            } else {
              this.files = [];
            }
          } else {
            files = [await this.fileFromString(this.modelValue)];
          }
          this.fileUploadHandler(files);
        }
      }
    },
    inputClickHandler(e) {
      if (this.forceEmit) {
        e.target.value = null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.a-input-file {
  display: flex;
  &__icon {
    cursor: pointer;
    width: 30px;
    height: 30px;
  }
  &__content {
    width: 100%;
    height: 100%;
  }
  &.greyish {
    background-color: $color-gray-bg;
    border-radius: 6px;
    padding: 4px;
  }
  &.disabled {
    background-color: $color-geyser;
    cursor: default;
  }
  cursor: pointer;
}

.input-file {
  display: none;
}
</style>
