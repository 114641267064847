<template>
  <div class="client-tab order">
    <div class="a-row mb-10">
      <AInput
        v-model="name"
        label="Название заказа"
        class="mr-20"
        :disabled="!accessToChange"
        :error="getOrderErrMess('name')"
      />
      <ADatePicker
        v-if="showPostponedDeadline"
        v-model="postponedDeadline"
        label="Новая дата отгрузки"
        enable-time-picker
        format="YYYY.MM.DD HH:mm"
        class="mr-20"
        :disabled="!accessToChangeModifiedFields"
        :read-only="isOrderComplete"
        :placeholder="deadline"
        :error="getOrderErrMess('postponedDeadline')"
      />
      <ADatePicker
        v-else
        v-model="deadline"
        label="Дата отгрузки"
        enable-time-picker
        format="YYYY.MM.DD HH:mm"
        :disabled="!accessToChange"
        :read-only="isOrderComplete"
        class="mr-20"
        :error="getOrderErrMess('deadline')"
      />
      <ADatePicker
        v-model="orderPramModelPackingDate"
        label="Дата упаковки"
        format="YYYY.MM.DD"
        :disabled="!accessToChangeModifiedFields"
        :read-only="isOrderComplete"
        class="mr-20"
      />
    </div>
    <div class="a-row mb-10">
      <AInput
        v-model="paid"
        :label="`Оплачено, ${getCurrencySign}`"
        class="mr-20"
        :disabled="!accessToChange"
        :read-only="isOrderComplete"
        :min="0"
        type="number"
      />
      <AInput
        v-model="discountFix"
        :label="`Скидка/Наценка, ${getCurrencySign}`"
        :disabled="!accessToChange || !!discountPercent"
        :read-only="isOrderComplete"
        type="number"
        class="mr-20"
      />
      <AInput
        v-model="discountPercent"
        label="Скидка/Наценка, %"
        :disabled="!accessToChange || !!discountFix"
        :read-only="isOrderComplete"
        :min="-100"
        :max="100"
        type="number"
        class="mr-20"
        :error="getOrderErrMess('discountPercent')"
      />
    </div>
    <div class="a-row mb-10">
      <ASelect
        v-model="state"
        label="Статус заказа"
        class="mr-20"
        track-key="value"
        select-label="name"
        block-unselect
        disable-deleted
        :options="getOrderStates"
        :disabled="!accessToChange"
        :read-only="!isSelectedOrder"
      />
      <AInput
        v-model="amoId"
        label="amoId"
        class="mr-20"
        :disabled="!accessToChange"
        :read-only="isOrderComplete"
      />
      <ASelect
        v-model="currentManagerId"
        :options="managers"
        label="Менеджер заказа"
        track-key="guid"
        select-label="surname"
        class="mr-20 important-order"
        block-unselect
        :disabled="!accessToChange"
        :error="getOrderErrMess('currentManagerId')"
      />
    </div>
    <div class="a-row">
      <ASelect
        v-model="orderPramModelOrderCategory"
        :options="orderCategory"
        label="Категория заказа"
        class="mr-20 important-order"
        select-label="name"
        track-key="id"
        disable-deleted
        multiple
        :disabled="!accessToChange"
      />
      <ASelect
        v-model="tagId"
        :options="orderTags"
        label="Тег заказа"
        track-key="id"
        disable-deleted
        select-label="name"
        class="mr-20 important-order"
        :disabled="!accessToChange"
        :error="getOrderErrMess('currentManagerId')"
      />
      <div class="urgencyType">
        <OrderUrgencyTypeSelector
          v-model:order-value="urgencyType"
          row
          mode="order"
          class="mt-40 ml-15"
          :disabled="!accessToChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex';
import accessMixin from '@/mixin/accessMixin.js';
import OrderUrgencyTypeSelector from '@/components/base/OrderUrgencyTypeSelector';
import validateOrder from '@/mixin/validateOrder.js';
import { isAvailableState } from '@/utils/order.js';
import { ORDER_STATE, CURRENCIES, ORDER_PARAM_TYPE } from '@/constants/index.js';
import ConfirmDialog from '@@/components/Confirm/index.vue';
import { useOrderParamValue } from '@/use/order/useOrderParamValue';

export default {
  name: 'OrderInfoOrderBlock',
  components: {
    OrderUrgencyTypeSelector,
  },
  mixins: [accessMixin, validateOrder],
  props: {
    isSelectedOrder: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { orderPramModelFn } = useOrderParamValue(props.isSelectedOrder);
    const orderPramModelPackingDate = orderPramModelFn({
      orderParamsType: ORDER_PARAM_TYPE.PACKING_DATE,
    });
    const orderPramModelOrderCategory = orderPramModelFn({
      orderParamsType: ORDER_PARAM_TYPE.CATEGORY,
    });
    return {
      orderPramModelPackingDate,
      orderPramModelOrderCategory,
    };
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      managers: (s) => s.user.managers,
      newOrder: (s) => s.order.newOrder,
      orderTags: (s) => s.order.orderTags,
      selectedOrder: (s) => s.order.selectedOrder,
      selectedOrderInfo: (s) => s.order.selectedOrderInfo,
      newOrderInfo: (s) => s.order.newOrderInfo,
      orderCategory: (s) => s.order.orderCategory,
    }),

    accessToChange() {
      return (
        this.isCanChangeOrder &&
        !this.loading &&
        this.pageOrder.clientId &&
        ![ORDER_STATE.COMPLETED, ORDER_STATE.PRODUCTION, ORDER_STATE.TECH_DESIGN].includes(
          this.pageOrder._old.state
        )
      );
    },
    accessToChangeModifiedFields() {
      return (
        this.isCanChangeOrder &&
        !this.loading &&
        this.pageOrder.clientId &&
        this.pageOrder._old.state !== ORDER_STATE.COMPLETED
      );
    },
    pageOrder() {
      return this.isSelectedOrder ? this.selectedOrder : this.newOrder;
    },
    isOrderComplete() {
      return this.pageOrder.state === ORDER_STATE.COMPLETED;
    },
    showPostponedDeadline() {
      return [ORDER_STATE.COMPLETED, ORDER_STATE.PRODUCTION, ORDER_STATE.TECH_DESIGN].includes(
        this.pageOrder._old.state
      );
    },
    getCurrencySign() {
      return CURRENCIES.find((currency) => currency.value == this.pageOrder.currency)?.sign || '';
    },
    getOrderStates() {
      const result = [
        {
          value: ORDER_STATE.NEW,
          name: 'Новый заказ',
          deleted:
            this.isSelectedOrder && !isAvailableState(this.pageOrder?._old.state, ORDER_STATE.NEW),
        },
        {
          value: ORDER_STATE.DESIGN,
          name: 'Разработка дизайна',
          deleted:
            this.isSelectedOrder &&
            !isAvailableState(this.pageOrder?._old.state, ORDER_STATE.DESIGN),
        },
        {
          value: ORDER_STATE.TECH_DESIGN,
          name: 'Технический дизайн',
          deleted:
            this.isSelectedOrder &&
            !isAvailableState(this.pageOrder?._old.state, ORDER_STATE.TECH_DESIGN),
        },
        // {
        //   value: ORDER_STATE.PRODUCTION,
        //   name: 'Производство',
        //   deleted:
        //     this.isSelectedOrder &&
        //     !isAvailableState(this.pageOrder?._old.state, ORDER_STATE.PRODUCTION),
        // },
        // {
        //   value: ORDER_STATE.COMPLETED,
        //   name: 'Готов',
        // },
      ].filter(
        (s) => this.isSelectedOrder && isAvailableState(this.pageOrder?._old.state, s.value)
      );

      return result;
    },
    name: {
      get() {
        return this.pageOrder.name;
      },
      set(value) {
        this.setOrderField({ selectedOrder: this.isSelectedOrder, key: 'name', data: value });
      },
    },
    deadline: {
      get() {
        return this.pageOrder.deadline;
      },
      set(value) {
        this.setOrderField({ selectedOrder: this.isSelectedOrder, key: 'deadline', data: value });
      },
    },
    postponedDeadline: {
      get() {
        return this.pageOrder.postponedDeadline;
      },
      set(value) {
        this.setOrderField({
          selectedOrder: this.isSelectedOrder,
          key: 'postponedDeadline',
          data: value,
        });
      },
    },
    amoId: {
      get() {
        return this.pageOrder.amoId;
      },
      set(value) {
        this.setOrderField({ selectedOrder: this.isSelectedOrder, key: 'amoId', data: value });
      },
    },
    paid: {
      get() {
        return this.pageOrder.paid;
      },
      set(value) {
        this.setOrderField({ selectedOrder: this.isSelectedOrder, key: 'paid', data: value });
      },
    },
    state: {
      get() {
        return this.pageOrder.state;
      },
      set(value) {
        this.setOrderField({ selectedOrder: this.isSelectedOrder, key: 'state', data: value });
      },
    },
    discountFix: {
      get() {
        return this.pageOrder.discountFix;
      },
      set(value) {
        this.setOrderField({
          selectedOrder: this.isSelectedOrder,
          key: 'discountFix',
          data: value,
        });
      },
    },
    discountPercent: {
      get() {
        return this.pageOrder.discountPercent;
      },
      set(value) {
        this.setOrderField({
          selectedOrder: this.isSelectedOrder,
          key: 'discountPercent',
          data: value,
        });
      },
    },
    currency: {
      get() {
        return this.pageOrder.currency;
      },
      set(value) {
        this.setOrderField({ selectedOrder: this.isSelectedOrder, key: 'currency', data: value });
      },
    },
    currentManagerId: {
      get() {
        return this.pageOrder.currentManagerId;
      },
      set(value) {
        this.setOrderField({
          selectedOrder: this.isSelectedOrder,
          key: 'currentManagerId',
          data: value,
        });
      },
    },
    tagId: {
      get() {
        return this.pageOrder.tagId;
      },
      set(value) {
        this.setOrderField({
          selectedOrder: this.isSelectedOrder,
          key: 'tagId',
          data: value,
        });
      },
    },
    urgencyType: {
      get() {
        return this.pageOrder.urgencyType;
      },
      set(value) {
        this.setOrderField({
          selectedOrder: this.isSelectedOrder,
          key: 'urgencyType',
          data: value,
        });
      },
    },
  },
  watch: {
    state(newState) {
      if (newState === ORDER_STATE.COMPLETED) {
        this.$dialog.addDialog({
          component: ConfirmDialog,
          props: {
            persistent: true,
            withoutClose: true,
            title: 'Понятно',
            text: 'При изменение статуса заказа на "Завершен" - заказ закроется и его нельзя будет редактировать',
            onConfirm: (confirm) => {
              confirm.showModal = false;
            },
          },
        });
      }
    },
  },
  created() {
    this.CURRENCIES = CURRENCIES;
    this.ORDER_STATE = ORDER_STATE;
  },
  methods: {
    ...mapMutations({
      setOrderField: 'order/setOrderField',
    }),
  },
};
</script>
<style lang="scss" scoped>
.client-tab {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 275px;

  .state {
    width: 335px;
  }
  div {
    overflow: visible;
  }
  overflow: auto;
  @include scroll;

  .important-order {
    width: 200px;
    min-width: 200px;
  }

  .urgencyType {
    width: 100px;
  }
  // .dropdown-btn {
  //   background-color: red;
  //   width: 10px;
  // }
  // .dropdown {
  //   background-color: red;
  // }
}
</style>
<style lang="scss">
.client-tab.order {
  .a-select.currency {
    .a-select__options-list {
      top: -40px;
      border-radius: 8px;
    }
  }
}
</style>
