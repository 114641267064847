import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import _debounce from 'lodash/debounce';
import _xor from 'lodash/xor';
import _throttle from 'lodash/throttle';
import _sortBy from 'lodash/sortBy';
import _set from 'lodash/set';
import _orderBy from 'lodash/orderBy';

export const cloneDeep = _cloneDeep;
export const isEqual = _isEqual;
export const debounce = _debounce;
export const xor = _xor;
export const throttle = _throttle;
export const sortBy = _sortBy;
export const set = _set;
export const orderBy = _orderBy;
