<template>
  <div class="page" :class="{ maxHeight, withoutMargins }">
    <div v-if="hasHeaderSlot" class="page__header">
      <slot name="header" />
    </div>
    <div class="page__content">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  name: "PageLayout",
  props: {
    maxHeight: {
      type: Boolean,
      default: false,
    },
    withoutMargins: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasHeaderSlot() {
      return !!this.$slots.header;
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  max-height: 100%;
  height: 100%;
  width: 100%;

  overflow: auto;
  @include scroll-wider;
  background-color: $color-white;

  &__header {
    height: 70px;
    min-height: 70px;

    box-shadow: $box-shadow-header;
    background-color: $color-white;

    z-index: 2;
    position: sticky;
    top: 0;
  }

  &__content {
    &:not(.withoutMargins) {
      margin: $page-margin-offset;
    }

    position: relative;

    display: flex;
    flex-direction: column;
    // flex-grow: 1;
  }
  &.withoutMargins {
    .page__content {
      margin: 0px 0px 0px 0px;
    }
  }
  &.maxHeight {
    .page__content {
      height: 100vh;
    }
  }
  &.withoutMargins.maxHeight {
    .page__content {
      height: calc(100vh - 70px);
    }
  }
}
</style>
