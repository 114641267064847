import productionApi from '@/api/productionApi';

export default {
  async getAllDepartments({ commit, state }, { force = false } = {}) {
    if (state.departments.length && !force) {
      return;
    }
    const { data } = await productionApi.getAllDepartments();
    commit('SET_ALL_DEPARTMENTS', data);
    return data;
  },

  async changeDepartment({ commit }, { department }) {
    const { data } = await productionApi.changeDepartment({ department });
    commit('SET_DEPARTMENT', { department: data, oldDepartment: department });
    return data;
  },

  async getProductionLineToComponent(_, { id, itemId } = {}) {
    const { data } = await productionApi.getProductionLine({ id, itemId });
    return data;
  },

  async getProductionLineCardsAndItemsToComponent(_, { productionLineId } = {}) {
    const { data } = await productionApi.getProductionLineCardsAndItems({ productionLineId });
    return data;
  },

  async changeProductionLine(
    { commit },
    { itemId, productionLine, resolveChangedDepartments, fillAddedItemDepartments }
  ) {
    const { data } = await productionApi.changeProductionLine({
      productionLine,
      resolveChangedDepartments,
      fillAddedItemDepartments,
    });
    if (!itemId) {
      commit('SET_PRODUCTION_LINE', { productionLine: data, oldProductionLine: productionLine });
      if (!data) {
        commit(
          'assortment/PATH_ITEM_FIELD_BY_FIELD',
          { key: 'productionLineId', oldData: productionLine.id, data: null },
          { root: true }
        );
      }
    }
    return data;
  },

  async changeItemProductionLine(_, { productionLine, itemId, resolveChangedDepartments }) {
    const { data } = await productionApi.changeItemProductionLine({
      productionLine,
      itemId,
      resolveChangedDepartments,
    });
    return data;
  },

  async changeOrderItemProductionLine(_, { productionLineIds, itemId, resolveChangedDepartments }) {
    const { data } = await productionApi.changeOrderItemProductionLine({
      productionLineIds,
      itemId,
      resolveChangedDepartments,
    });
    return data;
  },

  async saveOrderCardsProductionLineIds(_, { orderId, cards }) {
    const { data } = await productionApi.saveOrderCardsProductionLineIds({
      orderId,
      cards,
    });
    return data;
  },

  async getAllOrderItemProductionLinesIdsByOrderId(_, { orderId }) {
    const { data } = await productionApi.getAllOrderItemProductionLinesIdsByOrderId({ orderId });
    return data;
  },

  async getAllProductionLines({ commit, state }, { force = false } = {}) {
    if (state.productionLinesDownloaded.length && !force) {
      return;
    }
    const { data } = await productionApi.getAllProductionLines();
    commit('SET_ALL_PRODUCTION__LINES', data);
    return data;
  },
};
