import * as yup from 'yup';
import { validateDictionary } from './dictionary';

yup.setLocale({
  mixed: {
    required: () => ({ key: 'required' }),
    notType: ({ type }) => ({ key: 'notType', values: { type } }),
  },
  array: {
    min: ({ min }) => ({ key: 'array_min', values: { min } }),
    max: ({ max }) => ({ key: 'array_max', values: { max } }),
  },
  number: {
    min: ({ min }) => ({ key: 'number_min', values: { min } }),
    max: ({ max }) => ({ key: 'number_max', values: { max } }),
    moreThan: ({ more }) => ({ key: 'number_moreThan', values: { more } }),
    integer: () => ({ key: 'must_be_integer' }),
  },
  string: {
    min: ({ min }) => ({ key: 'string_min', values: { min } }),
    max: ({ max }) => ({ key: 'string_max', values: { max } }),
    length: ({ length }) => ({ key: 'string_length', values: { length } }),
  },
});

class Form {
  constructor(initialValues, validationSchema) {
    this.values = initialValues;
    this.validationSchema = validationSchema;
    this.errors = {};
    this.isSubmitting = false;
    this.isValid = null;
  }

  validate() {
    try {
      this.validationSchema.validateSync(this.values, { abortEarly: false });
    } catch (err) {
      err.inner.forEach((error) => {
        let text;
        console.log('validate unils', error.message, error?.params);
        if (error.message.key) {
          const dictionaryValue = validateDictionary[error.message.key];
          text = dictionaryValue ? dictionaryValue(error.message.values) : 'Ошибка валидации';
        } else {
          text = error.message;
        }
        this.errors = {
          ...this.errors,
          [error.path]: text || 'Ошибка валидации',
        };
      });
    }
    this.isValid = Object.keys(this.errors).length === 0;

    return this;
  }

  reset() {
    this.values = {};
    this.errors = {};
    this.isValid = null;
  }
  getErrors() {
    return this.errors;
  }
}

export { yup, Form };
