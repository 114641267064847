<template>
  <div class="order-wrapper">
    <table class="order-table">
      <thead class="order__header">
        <tr>
          <td v-for="field of allFiltredHeaders" :key="field.key" :class="{ [field.key]: true }">
            {{ field.name }}
          </td>
        </tr>
        <tr class="highlight">
          <td :colspan="allFiltredHeaders.length">
            <div />
          </td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="order of allOrders" :key="order.guid" class="order__row">
          <td class="guid pointer hover-select" @click="copyText(order.guid)">
            {{ order.guid.slice(-4) }}
          </td>
          <td class="name">
            <div class="text-overflow">
              {{ order.name }}
            </div>
          </td>
          <td v-if="selectedFields.includes('clientName')" class="clientName">
            <div class="text-overflow">
              {{ order.clientName }}
            </div>
          </td>
          <td v-if="selectedFields.includes('state')" class="state">
            {{ ORDER_STATE_DICTIONARY[order.state] }}
          </td>
          <td v-if="selectedFields.includes('createdAt')" class="createdAt">
            <span class="date">
              {{ formatDate(order.createdAt) }}
            </span>
          </td>
          <td v-if="selectedFields.includes('deadline')" class="deadline">
            <span class="date">
              {{ formatDate(order.deadline, { long: true }) }}
            </span>
          </td>
          <td v-if="selectedFields.includes('amoId')" class="amoId">
            <div
              v-if="order.amoId"
              :title="order.amoId"
              class="link"
              @click="openLink(order.amoId)"
            >
              Открыть
            </div>
          </td>
          <td v-if="selectedFields.includes('currency')" class="currency">
            {{ order.currency }}
          </td>
          <td v-if="selectedFields.includes('discountFix')" class="discountFix">
            {{ order.discountFix }}
          </td>
          <td v-if="selectedFields.includes('discountPercent')" class="discountPercent">
            {{ order.discountPercent }}
          </td>
          <td v-if="selectedFields.includes('paid')" class="paid">
            {{ order.paid }}
          </td>
          <td v-if="selectedFields.includes('teamName')" class="teamName">
            <div class="text-overflow">
              {{ order.teamName }}
            </div>
          </td>
          <td v-if="selectedFields.includes('oldOrderId')" class="oldOrderId">
            {{ order.details.oldOrderId }}
          </td>
          <td v-if="selectedFields.includes('managerName')" class="managerName">
            <div class="text-overflow">
              {{ order.managerName }}
            </div>
          </td>
          <td class="actions a-row">
            <router-link :to="{ path: `/orders/${order.guid}` }">
              <div class="icon mr-10">
                <Fa icon="external-link-square-alt" style="width: 25px; height: 25px" />
              </div>
            </router-link>

            <!-- <div v-if="isMainManager || isAdmin" class="icon">
              <Fa icon="sliders-h" style="width: 25px; height: 25px" />
            </div> -->
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ALL_FIELDS, PERMANENT_FIELDS } from '../ordersDictionary.js';
import { ORDER_STATE_DICTIONARY } from '../../../constants/index.js';
import { dayjs } from '@/utils/date.js';
import accessMixin from '@/mixin/accessMixin.js';
import { copyToClipboard } from '@/utils/clipboard.js';

export default {
  name: 'OrdersTable',
  mixins: [accessMixin],
  props: {
    selectedFields: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      allOrders: (s) => s.order.allOrders,
    }),
    globalLoading() {
      return this.loading;
    },
    allFiltredHeaders() {
      return ALL_FIELDS.filter(
        (f) => this.selectedFields.includes(f.key) || PERMANENT_FIELDS.includes(f.key)
      ).concat([{ key: 'actions', name: 'Настройки' }]);
    },
  },
  created() {
    this.ORDER_STATE_DICTIONARY = ORDER_STATE_DICTIONARY;
  },
  methods: {
    openOrder(guid) {
      this.$router.push({ name: 'orderPage', params: { orderId: guid } });
    },
    formatDate(date, options = { long: false }) {
      if (!date) return '—';
      return dayjs(date).format(`DD.MM.YYYY ${options.long ? 'HH:mm' : ''}`);
    },
    openLink(url) {
      window.open(url, '_blank');
    },
    copyText(text) {
      copyToClipboard(text);
      this.$notify({ title: 'Скопировано' });
    },
  },
};
</script>
<style lang="scss" scoped>
.date {
  white-space: nowrap;
}

.text-overflow {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow-y: hidden;
  overflow-x: hidden;

  &:hover {
    overflow-y: visible;
    -webkit-box-orient: horizontal;
  }
  word-break: break-all;
  hyphens: auto;
}

.order-wrapper {
  display: flex;
  align-self: center;

  overflow: auto;
  width: 100%;
  @include scroll-wider;
}

.order {
  &-table {
    border-collapse: collapse;

    // width: 100%;
    // min-width: 70%;
    // table-layout: fixed;
    // overflow-wrap: break-word;
  }
  &__header {
    // position: sticky;
    // top: 70px;
    // background-color: white;
    // box-shadow: 0px 5px 3px -2px rgba(0, 0, 0, 0.1);

    td {
      @include Inter;
      font-size: 14px;
      color: $color-gray;

      padding: 15px 10px 0px 10px;
      text-align: start;

      &.state {
        width: 100px;
      }
      &.name {
        width: 320px;
        // max-width: 320px;
      }
      &.clientName {
        width: 250px;
        max-width: 250px;
      }
      &.discountFix {
        width: 100px;
      }
      &.discountPercent {
        width: 100px;
      }
    }
  }

  &__row {
    font-weight: 400;
    font-size: 16px;

    td {
      padding: 18px 10px;
      border-top: 1px solid $color-white-smoke;
      border-bottom: 1px solid $color-white-smoke;
      // max-width: 350px;

      &.guid {
        width: 65px;
      }
      &.state {
        width: 100px;
      }
      &.name {
        width: 320px;
        max-width: 320px;
      }
      &.clientName {
        width: 240px;
        max-width: 240px;
        word-break: break-all;
      }
      &.discountFix {
        width: 100px;
      }
      &.discountPercent {
        width: 100px;
      }
    }
    .link {
      cursor: pointer;
      text-decoration: underline;
    }
    .hover-select:hover {
      @include InterBold;
    }
    .actions {
      .icon {
        cursor: pointer;
        &:active {
          transform: scale(0.95);
        }
      }
    }
  }
}
</style>
