<template>
  <div>
    <OrderDesignBaseTable
      :key="block"
      :fields="HEADERS || []"
      :items="filtredItems || []"
      :loading="loading"
    >
      <template #subname> &nbsp;</template>
      <template #subdeadline="{ item }">
        <div class="a-row">
          <span class="t-secondary"> Изд. </span> {{ item.product.itemId }}
          <div v-if="item.product.comment" class="ml-10">
            <ATooltip :qtitle="item.product.comment" position="top" />
          </div>
        </div>
      </template>
      <template #submanager="{ item }">
        <router-link :to="{ path: `/design/${item.guid}` }">
          <div class="btn-wrapper">
            <!-- @click="openDesignSolution(item.guid)" -->
            <!-- <div class="btn-wrapper" @click="$router.push('/design/' + item.guid)"> -->
            <AButton small light> Открыть </AButton>
          </div>
        </router-link>
      </template>
      <template #actions="{ item }">
        <div v-if="isMainDesigner">
          <div v-if="allProductInBlockHaveNoOneDesigner(item)">
            <ASelect
              :model-value="item.designerId"
              :options="allDesigners"
              select-label="name"
              track-key="guid"
              placeholder="Назначить"
              :disabled="changeDesignerLoading"
              @change="changeAllProductHandler({ item, isProduct: false, newDesignerId: $event })"
            />
          </div>
          <div v-else>...</div>
        </div>
        <div v-else>
          <div v-if="allProductInBlockHaveOneDesigner(item)">
            <ALabel :value="getDesignerName(item.products[0].designerId)" />
          </div>
          <div v-else-if="item.products[0].designerId">...</div>
        </div>
      </template>
      <template #subactions="{ item }">
        <div v-if="isMainDesigner">
          <ASelect
            :model-value="item.designerId"
            :options="allDesigners"
            select-label="name"
            track-key="guid"
            placeholder="Назначить"
            :disabled="changeDesignerLoading"
            @change="
              $emit('changeDesignerOrState', { item, isProduct: true, newDesignerId: $event })
            "
          />
        </div>
        <div v-else-if="item.designerId && item.designerId != accessUserId">
          <ALabel :value="getDesignerName(item.designerId)" />
        </div>
        <div
          v-if="isMainDesigner || (isDesigner && item.designerId === accessUserId)"
          :class="{ 'mt-10': isMainDesigner }"
        >
          <ASelect
            :model-value="item.state"
            :options="filtredDesignProductStatuses"
            select-label="name"
            track-key="value"
            placeholder="Начать"
            :disabled="changeDesignerLoading"
            block-unselect
            @change="$emit('changeDesignerOrState', { item, isProduct: true, newState: $event })"
          />
        </div>
      </template>
    </OrderDesignBaseTable>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import OrderDesignBaseTable from '../../base/OrderDesignBaseTable/index.vue';
import ConfirmDialog from '@@/components/Confirm/index.vue';
import accessMixin from '@/mixin/accessMixin.js';
import { DESIGN_PRODUCT_STATUSES, DESIGN_SOLUTION_STATUS } from '../../../constants/index.js';
import { filterArrayByFields } from '../../../utils/filters';

const HEADERS = [
  {
    key: 'name',
    label: 'Заказ',
    class: 'name ',
    bodyClass: 'name text-overflow',
  },
  // {
  //   key: 'specification',
  //   label: 'Техническое задание',
  //   class: '',
  //   bodyClass: 'text-overflow',
  // },
  {
    key: 'deadline',
    label: 'Дата отгрузки',
    class: 'date',
    bodyClass: 'date',
    type: 'date',
  },
  {
    key: 'manager',
    label: 'Менеджер',
  },
  {
    key: 'actions',
    class: 'actions',
    bodyClass: 'actions',
    label: 'Дизайнер',
  },
];

export default {
  name: 'DesignSolutionTable',
  components: {
    OrderDesignBaseTable,
  },
  mixins: [accessMixin],
  props: {
    block: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['changeDesignerOrState', 'openDesignSolution'],
  data() {
    return {
      changeDesignerLoading: false,
    };
  },
  computed: {
    ...mapState({
      allDesigners: (s) => s.design.allDesigners,
      designSolutions: (s) => s.design.designSolutions,
    }),
    getDesignerName() {
      return (guid) => this.allDesigners.find((d) => d.guid == guid)?.name || '-';
    },
    filtredItems() {
      const allItems = this.designSolutions[this.block] || [];
      return allItems.reduce((acc, i) => {
        // сделать для остальных ролей, если нужно
        if (!this.filters.showAllOrders && i.products[0].managerId != this.accessUserId) {
          return acc;
        }
        if (!this.filters.search) {
          acc.push(i);
          return acc;
        }
        if (
          i.manager.toLowerCase().includes(this.filters.search) ||
          i.name.toLowerCase().includes(this.filters.search)
        ) {
          acc.push(i);
          return acc;
        }

        const filtredProducts = filterArrayByFields(i.products, this.filters.search, [
          ['specification', (specification) => JSON.stringify(specification)],
          ['product', (p) => String([p.color, p.comment, p.article.code, p.article.name])],
        ]);
        if (!filtredProducts.length) return acc;
        i.products = filtredProducts;
        acc.push(i);
        return acc;
      }, []);
    },
    filtredDesignProductStatuses() {
      const notAllovewdState = [DESIGN_SOLUTION_STATUS.COMPLETE, DESIGN_SOLUTION_STATUS.QUEUE];
      return DESIGN_PRODUCT_STATUSES.filter((s) => {
        return !notAllovewdState.includes(s.value);
      });
    },
  },
  created() {
    this.HEADERS = HEADERS;
  },
  methods: {
    openDesignSolution(guid) {
      this.$router.push({ name: 'designSolutionPage', params: { designSolutionId: guid } });
    },
    changeAllProductHandler(data) {
      this.$dialog.addDialog({
        component: ConfirmDialog,
        props: {
          title: 'Смена дизайнера',
          text: `Поменять дизайнера для всех партий в этом блок? (${data.item.products.length} шт.)`,
          onConfirm: (confirm) => {
            confirm.showModal = false;
            this.$emit('changeDesignerOrState', data);
          },
        },
      });
    },
    allProductInBlockHaveNoOneDesigner(item) {
      return item.products.every((p) => !p.designerId);
    },
    allProductInBlockHaveOneDesigner(item) {
      const designerId = item.products[0].designerId;
      return designerId && item.products.every((p) => p.designerId == designerId);
    },
  },
};
</script>
<style lang="scss" scoped>
a {
  text-decoration: none;
  cursor: pointer;
}
.btn-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}
</style>
<style lang="scss">
.actions {
  min-width: 170px;
}
.text-overflow {
  span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow-y: hidden;

    &:hover {
      overflow-y: visible;
      -webkit-box-orient: horizontal;
    }
  }
}
</style>
