<template>
  <ProductionTable />
</template>

<script>
import ProductionTable from '@/components/productionTable';
export default {
  name: 'ProductionTablePage',
  components: { ProductionTable },
};
</script>
