<template>
  <div class="client-tab">
    <div class="btn-container mt-10">
      <AInputFile
        v-model="xlTableFile"
        greyish
        :formats="['vnd.openxmlformats-officedocument.spreadsheetml.sheet']"
        :disabled="!accessToChange"
        :force-emit="true"
      >
        <div class="entity-file-block__mockup-content ml-10 pt-5">Импорт таблицы</div>
        <template #icon>
          <Fa name="icon" icon="file-excel" class="icon faicon" style="width: 30px; height: 30px" />
        </template>
      </AInputFile>
      <AButton @click="downloadXlTemplate()">
        <Fa
          name="icon"
          icon="file-download"
          class="icon faicon"
          style="width: 30px; height: 30px"
        />
        Скачать шаблон
      </AButton>
    </div>
    <div>
      <div class="errors">
        <p v-if="!pageOrder.products.length">Необходимо добавить изделия</p>
        <p v-if="!pageOrder.clientId">Необходимо выбрать клиента</p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import accessMixin from '@/mixin/accessMixin.js';
import { ORDER_STATE } from '../../../../constants';
import { pluralizedError } from '../../../../constants/pluralizedWords.js';
import { forceFileDownload } from '@/utils/downloadData.js';

export default {
  name: 'OrderImportBlock',
  mixins: [accessMixin],
  props: {
    isSelectedOrder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      xlTableFile: null,
    };
  },
  computed: {
    ...mapState({
      newOrder: (s) => s.order.newOrder,
      selectedOrder: (s) => s.order.selectedOrder,
      selectedOrderInfo: (s) => s.order.selectedOrderInfo,
      newOrderInfo: (s) => s.order.newOrderInfo,
      newOrderXlImportErrors: (s) => s.order.newOrderXlImportErrors,
      selectedOrderXlImportErrors: (s) => s.order.selectedOrderXlImportErrors,
    }),
    accessToChange() {
      return (
        this.isCanChangeOrder &&
        this.pageOrder.clientId &&
        ![ORDER_STATE.COMPLETED, ORDER_STATE.PRODUCTION, ORDER_STATE.TECH_DESIGN].includes(
          this.pageOrder._old.state
        ) &&
        this.pageOrder.products.length
      );
    },
    pageOrder() {
      return this.isSelectedOrder ? this.selectedOrder : this.newOrder;
    },
    orderInfo() {
      return this.isSelectedOrder ? this.selectedOrderInfo : this.newOrderInfo;
    },
    pageImportErrors() {
      return this.isSelectedOrder ? this.selectedOrderXlImportErrors : this.newOrderXlImportErrors;
    },
  },
  watch: {
    xlTableFile: {
      handler: function (file) {
        if (file) {
          this.importXlHandler(file);
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      uploadFile: 'upload/uploadFile',
      xlImport: 'order/xlImport',
      xlDownloadTemplate: 'order/xlDownloadTemplate',
    }),
    ...mapMutations({
      setGlobalLoading: 'order/SET_ORDER_LOADING',
    }),
    async importXlHandler(file) {
      if (!file) {
        return;
      }
      this.setGlobalLoading({
        key: this.isSelectedOrder ? 'selectedOrderLoading' : 'newOrderLoading',
        data: true,
      });
      try {
        const { sha2 } = await this.uploadFile({
          entityId: null,
          file: file,
          key: 'import-order-table',
        });
        await this.xlImport({
          tableSha: sha2,
          products: this.pageOrder.products,
          selectedOrder: this.isSelectedOrder,
        });
        if (this.pageImportErrors.length) {
          const errorWord = pluralizedError(this.pageImportErrors.length);
          this.$notifyError({
            title: `Обнаружено ${this.pageImportErrors.length} ${errorWord} при импорте`,
          });
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.setGlobalLoading({
          key: this.isSelectedOrder ? 'selectedOrderLoading' : 'newOrderLoading',
          data: false,
        });
      }
    },
    async downloadXlTemplate() {
      const data = await this.xlDownloadTemplate();
      forceFileDownload(data, 'emptyTemplate.xlsx');
    },
  },
};
</script>
<style lang="scss" scoped>
.client-tab {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 275px;

  overflow: hidden;
  @include scroll;

  .btn-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: fit-content;
  }

  .errors {
    p {
      color: $color-red;
    }
  }
}
</style>
