<template>
  <div class="not-found">
    <div class="title">Страница не найдена</div>
  </div>
</template>

<script>
export default {
  name: 'NotFoundPage',
};
</script>
<style scoped lang="scss">
.not-found {
  display: flex;
  flex-direction: column;
  width: 100%;

  align-items: center;

  .title {
    @include InterBold;
    font-size: 20px;
    margin-top: 50px;
  }
}
</style>
