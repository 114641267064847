import { createRouter, createWebHistory } from 'vue-router';
import store from '../store/index.js';
import { LINKS } from '../utils/app.dictionary.js';

function dynImport(pagePath) {
  return () => import('@/pages/' + pagePath + '/index.vue');
}

// const designSolutionPage = dynImport('designSolution');
const productionTablePage = dynImport('productionTable');
const orderSettingPage = dynImport('orderSetting');
const applicationPage = dynImport('application');
const monitoringPage = dynImport('monitoring');
const productionPage = dynImport('production');
const assortmentPage = dynImport('assortment');
const analyticsPage = dynImport('analytics');
const notFoundPage = dynImport('notFound');
const ordersPage = dynImport('orders');
// const designPage = dynImport('design');
const orderPage = dynImport('order');
const loginPage = dynImport('login');
const cardsPage = dynImport('cards');
const usersPage = dynImport('users');

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: { name: 'Login' },
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: loginPage,
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/assortment',
    name: 'assortmentPage',
    component: assortmentPage,
  },
  {
    path: '/orders',
    name: 'ordersPage',
    component: ordersPage,
  },
  {
    path: '/orders/:orderId',
    name: 'orderPage',
    component: orderPage,
  },
  {
    path: '/orders/setting',
    name: 'orderSettingPage',
    component: orderSettingPage,
  },
  {
    path: '/production',
    name: 'productionPage',
    component: productionPage,
  },
  {
    path: '/productionTable',
    name: 'productionTablePage',
    component: productionTablePage,
  },
  {
    path: '/users',
    name: 'usersPage',
    component: usersPage,
  },
  {
    path: '/monitoring',
    name: 'monitoringPage',
    component: monitoringPage,
  },
  {
    path: '/application',
    name: 'applicationPage',
    component: applicationPage,
  },
  {
    path: '/analytics',
    name: 'analyticsPage',
    component: analyticsPage,
  },
  // {
  //   path: '/design',
  //   name: 'designPage',
  //   component: designPage,
  // },
  // {
  //   path: '/design/:designSolutionId',
  //   name: 'designSolutionPage',
  //   component: designSolutionPage,
  // },
  {
    path: '/cards/:orderId',
    name: 'cardsPage',
    component: cardsPage,
  },
  {
    path: '/404',
    name: 'notFoundPage',
    component: notFoundPage,
  },
  {
    path: '/:path(.*)',
    redirect: '/404',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const skipPages = ['cardsPage'];

const getRoute = (to) => {
  for (const link of LINKS) {
    if (link.routeName === to.name) {
      return link;
    }
    for (const ch of link.children || []) {
      if (ch.routeName === to.name) {
        return ch;
      }
    }
  }
};

router.beforeEach((to, from, next) => {
  const userType = store.state.user.currentUser?.type || localStorage.getItem('currentUserType');
  if (['Login', 'notFoundPage'].includes(to.name)) {
    next();
  } else {
    if (!userType) {
      next({ name: 'Login' });
      return;
    }
    if (skipPages.includes(to.name)) {
      next();
      return;
    }
    const r = getRoute(to);
    if (!r) {
      next({ name: 'notFoundPage' });
      return;
    }
    if (!r.types.includes(userType)) {
      next({ name: 'notFoundPage' });
    } else {
      next();
    }
  }
});

export default router;
