export default {
  SET_ITEMS_DICTIONARY(state, assortmentDictionary) {
    state.assortmentDictionary = assortmentDictionary;
    state.assortmentDictionaryDownload = true;
  },

  SET_PRODUCTION_PARAMS(state, data) {
    state.productionParams = data;
    state.productionParamsDownload = true;
  },

  SET_BASE_DICTIONARY(state, { key, data, oldData }) {
    if (!data) {
      delete state.assortmentDictionary[key][oldData.id];
      return;
    }
    state.assortmentDictionary[key][data.id] = data || undefined;
  },

  SET_AVAILABLE_ASSORTMENT(state, { key, data }) {
    state.availableAssortment[key] = data;
  },
};
