<template>
  <div>
    <div class="media-wrapper">
      <AFileUpload
        v-model:files="otherFiles"
        :upload-files="uploadFilesHandler"
        :start-upload="startUploadImage"
        position="right"
      />
    </div>
    <div class="input-wrapper">
      <div class="input">
        <AInput v-model="message.text" textarea @keyup.alt.enter="sendMessageHandler" />
      </div>
      <div class="btn-group">
        <AButton
          small
          light
          title="Отправить сообщение: Alt+Enter"
          class="btn"
          @click="sendMessageHandler"
        >
          <div class="icon-wrapper">
            <Fa icon="long-arrow-alt-right" class="icon check" style="width: 25px; height: 25px" />
          </div>
        </AButton>
        <AButton
          v-if="isDesigner || isMainDesigner"
          small
          light
          title="Добавить дизайнерское решение"
          class="btn"
          @click="addSolution = true"
        >
          <div class="icon-wrapper">
            <Fa icon="pen-nib" class="icon check" style="width: 20px; height: 20px" />
          </div>
        </AButton>
      </div>
    </div>
  </div>
  <DesignSolutionSpecification :add-solution="addSolution" @close="addSolution = false" />
</template>

<script>
import accessMixin from '@/mixin/accessMixin.js';
import DesignSolutionSpecification from './DesignSolutionAsideBlock.vue';
import { cloneDeep } from '../../../utils/lodash';
import { mapActions, mapState } from 'vuex';

const EMPTY_MESSAGE = {
  text: null,
  content: null,
};

export default {
  name: 'InputMessageBlock',
  components: { DesignSolutionSpecification },
  mixins: [accessMixin],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: [],
  data() {
    return {
      otherFiles: [],
      uploadComponent: null,
      uploadedFiles: false,
      uploadedImages: [],
      startUploadImage: false,

      addSolution: false,

      sendMessageLoading: false,

      message: cloneDeep(EMPTY_MESSAGE),
    };
  },
  computed: {
    ...mapState({
      designSolution: (s) => s.design.selectedDesignSolution,
    }),
  },
  watch: {
    'uploadComponent.uploaded': {
      handler: function (value) {
        if (!value) return;
        this.uploadedFiles = true;
        this.startUploadImage = false;
        this.sendMessageHandler();
      },
      deep: true,
    },
  },
  created() {},
  methods: {
    ...mapActions({
      sendDesignSolutionMessage: 'design/sendDesignSolutionMessage',
      uploadImage: 'upload/uploadImage',
    }),
    async sendMessageHandler() {
      this.sendMessageLoading = true;
      if (this.otherFiles.length && !this.uploadedFiles) {
        this.startUploadImage = true;
        return;
      }
      try {
        if (this.message.text) {
          await this.sendDesignSolutionMessage({
            message: this.message,
            designSolutionId: this.designSolution.guid,
          });
          this.message = cloneDeep(EMPTY_MESSAGE);
        }
        for (const sha2 of this.uploadedImages) {
          this.message.content = sha2;
          await this.sendDesignSolutionMessage({
            message: this.message,
            designSolutionId: this.designSolution.guid,
          });
          this.message = cloneDeep(EMPTY_MESSAGE);
        }
      } catch (err) {
        console.log(err);
        this.$notifyError({ title: 'Ошибка' });
      } finally {
        this.otherFiles = [];
        this.uploadedImages = [];
        this.startUploadImage = false;
        this.sendMessageLoading = false;
        this.uploadComponent = null;
      }
    },
    async uploadFilesHandler(file, component) {
      this.uploadComponent = component;
      this.uploadedFiles = false;

      const { sha2 } = await this.uploadImage({
        entityId: this.designSolutionId,
        file: file.file,
        key: 'design-sulution-chat-img',
      });
      this.uploadedImages.push(sha2);
    },
  },
};
</script>
<style lang="scss" scoped>
.media-wrapper {
  width: calc(100% - 100px);
  margin: 10px;
}
.input-wrapper {
  display: flex;
  flex-direction: row;
  width: calc(100% - 40px);
  margin: 10px 10px;

  .input {
    width: 100%;
  }

  .btn-group {
    display: flex;
    flex-direction: column;
    .btn {
      margin-bottom: 20px;
    }

    margin-left: 20px;
    .icon-wrapper {
      display: flex;
    }
  }
}
</style>
