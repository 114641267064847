import dayjs from "dayjs";

export function format(date, format) {
  return dayjs(date).format(format);
}

export function formatRoubleCurrency(value, style) {
  return new Intl.NumberFormat("ru-RU", {
    style: style ? style : "currency",
    currency: "RUB",
    minimumFractionDigits: 0,
  }).format(Number(value));
}
