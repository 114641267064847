import injectVueUploadComponent from './injections/vue-upload-component';
import injectVueNotifications from './injections/vue-notifications';
import injectIconsComponents from './injections/icons-components';
import injectGitartVueDialog from './injections/gitart-vue-dialog';
// import injectClickOutside from './injections/click-outside';
import injectNotifyError from './injections/notify-error';
import injectquillEditor from './injections/quillEditor';
import injectClipboard from './injections/clipboard';
import injectUI from './injections/ui-components';
import draggable from './injections/draggable';
import injectMaska from './injections/maska';

export default function inject(vue) {
  injectVueUploadComponent(vue);
  injectVueNotifications(vue);
  injectIconsComponents(vue);
  injectGitartVueDialog(vue);
  // injectClickOutside(vue);
  injectNotifyError(vue);
  injectquillEditor(vue);
  injectClipboard(vue);
  injectMaska(vue);
  draggable(vue);
  injectUI(vue);
}
