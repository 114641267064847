<template>
  <div class="wrapper">
    <div class="form" @keypress.enter="reset ? backToStartPage() : loginHandler()">
      <div class="form__logo mb-15">
        <img class="img clover" src="../../assets/lg_logo.png" alt="photo" width="27" height="27" />
        <img
          class="img luckygrass"
          src="../../assets/luckygrass.png"
          alt="photo"
          width="130"
          height="23"
        />
      </div>
      <div class="form__access-fields mb-20">
        <AInput v-if="!codeSent" v-model="form.login" label="Логин" :error="getErrMess('login')" />
        <AInput v-if="codeSent" v-model="form.code" label="Код из смс" />
        <AInput
          v-if="!reset && !codeSent"
          v-model="form.password"
          type="password"
          label="Пароль"
          :error="getErrMess('password')"
        />
      </div>
      <div class="form__errors mb-10">
        <span v-if="error">{{ error }}</span>
      </div>

      <div class="form__reset">
        <span v-if="!reset" @click="reset = true">Восстановить пароль</span>
      </div>
      <div class="form__btn">
        <AButton class="mr-10" :disabled="loading" @click="loginHandler">
          {{ getBtnText.btnText }}
        </AButton>
        <AButton v-if="reset" :disabled="loading" @click="backToStartPage">Назад </AButton>
      </div>
    </div>
  </div>
</template>

<script>
import validateMixin from '@/mixin/validate.js';
import { loginSchema, loginSendCodeSchema } from '@/utils/validate/index.js';
import { parseJwt } from '@/utils/jwt.js';
import axios from 'axios';
import { TOKEN } from '../../constants/auth.js';
import { USER_TYPES } from '../../constants/index.js';
import errorsDictionary from '../../i18/ru/errors';

export default {
  name: 'Login',
  mixins: [validateMixin],
  data() {
    return {
      form: {
        login: null,
        password: null,
        code: null,
      },
      error: null,
      loading: false,

      reset: false,
      codeSent: false,
    };
  },
  computed: {
    getBtnText() {
      if (this.reset && this.codeSent) {
        return { btnText: 'Сбросить пароль', reset: true };
      }
      if (this.reset && !this.codeSent) {
        return { btnText: 'Отправить код', sendCode: true };
      }
      return { btnText: 'Войти' };
    },
  },
  methods: {
    async loginHandler() {
      this.error = null;

      if (this.getBtnText.sendCode) {
        const formValidation = this.$form(this.form, loginSendCodeSchema);
        if (!formValidation.isValid) return;
        await this.sendCodeHandler();
        return;
      }
      if (this.getBtnText.reset) {
        await this.resetPasswordHandler();
        return;
      }

      const formValidation = this.$form(this.form, loginSchema);
      if (!formValidation.isValid) return;
      this.loading = true;
      try {
        const res = await axios({
          method: 'post',
          url: process.env.VUE_APP_API_TARGET + 'api/auth/login',
          data: this.form,
        });
        if (res.status != 200) {
          this.error = 'Неверный логин или пароль';
          return;
        }
        const { token, refreshToken } = res.data.data;
        localStorage.setItem(TOKEN.ACCESS_TOKEN, token);
        localStorage.setItem(TOKEN.REFRESH_TOKEN, refreshToken);
        const data = parseJwt(token);
        localStorage.setItem('currentUserType', data.type);
        try {
          if ([USER_TYPES.DESIGN, USER_TYPES.MAIN_DESIGNER].includes(data.type)) {
            window.location.href = '/design';
            return;
          }
          if ([USER_TYPES.EMPLOYEE].includes(data.type)) {
            window.location.href = '/application';
            return;
          }
          if ([USER_TYPES.MAIN_EMPLOYEE].includes(data.type)) {
            window.location.href = '/monitoring';
            return;
          }
        } catch (e) {
          console.log(e);
        }
        window.location.href = '/orders';
      } catch (err) {
        this.error = 'Неверный логин или пароль';
        console.log(err);
        this.$notifyError({ title: 'Ошибка авторизации' });
      } finally {
        this.loading = false;
      }
    },
    async sendCodeHandler() {
      this.loading = true;
      try {
        const login = this.form.login;
        const res = await axios({
          method: 'post',
          url: process.env.VUE_APP_API_TARGET + 'api/auth/sendCode',
          data: this.form,
        });
        if (res.status != 200) {
          this.error = 'Ошибка восстановления';
          return;
        }
        this.codeSent = true;
        localStorage.setItem('resetLogin', login);
        this.$notify({ title: 'В течении двух минут, на телефон поступит код' });
      } catch (err) {
        err?.response?.data?.errors?.forEach((err) => {
          this.$notifyError({ title: errorsDictionary[err.code] || err.code });
        });
      } finally {
        this.loading = false;
      }
    },
    async resetPasswordHandler() {
      this.loading = true;
      try {
        const login = this.form.login || localStorage.getItem('resetLogin');
        const res = await axios({
          method: 'post',
          url: process.env.VUE_APP_API_TARGET + 'api/auth/resetPassword',
          data: {
            login,
            code: this.form.code,
          },
        });
        if (res.status != 200) {
          this.error = 'Ошибка восстановления';
          return;
        }
        this.backToStartPage();
        localStorage.removeItem('resetLogin');
        this.$notify({ title: 'Пароль сброшен, ожидайте смс с паролем' });
      } catch (err) {
        err?.response?.data?.errors?.forEach((err) => {
          this.$notifyError({ title: errorsDictionary[err.code] || err.code });
        });
      } finally {
        this.loading = false;
      }
    },
    backToStartPage() {
      this.reset = false;
      this.codeSent = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: row;

  width: 100%;
  height: 100%;

  justify-content: center;
  align-items: center;

  background-color: $smoke-blue-50-opacity;
}
.form {
  display: flex;
  flex-direction: column;
  width: 280px;
  height: 350px;
  background-color: $color-bg-light-blue;
  border-radius: 10px;

  margin: 0;
  padding: 20px 0px;

  justify-content: space-between;
  align-items: center;

  &__logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .clover {
      margin-right: 10px;
    }
  }

  &__access-fields {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
  }
  &__errors {
    font-size: 12px;
    color: $color-red;
  }

  &__reset {
    margin: 5px 15px 5px 0px;
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    span {
      font-size: 10px;
      color: $color-gray;
      @include InterBold;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  &__btn {
    display: flex;
  }
}
</style>
