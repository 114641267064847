<template>
  <Production />
</template>

<script>
import Production from '@/components/production';
export default {
  name: 'ProductionPage',
  components: { Production },
};
</script>
