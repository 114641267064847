<template>
  <div>
    <APageAside title="Фильтры" wide @close="closeHandler">
      <div v-if="showAsideFilters">
        <div>
          <div class="mb-40">
            <div class="employee-filter">
              <ASelect
                v-model="localEmployeeId"
                :options="filtredEmployees"
                select-label="name"
                track-key="guid"
                label="Сотрудник"
                :readonly="loading"
                disable-deleted
              />
            </div>
            <div class="deadline-filter mt-40">
              <ADatePicker
                v-model="localDeadline"
                format="YYYY-MM-DD"
                mode="date"
                without-icon
                range
                :readonly="loading"
                label="Дата отгрузки"
                placeholder="Фильтр по дате отгрузке"
              />
            </div>
            <ASpacer text="Теги" class="mt-20" />
            <div class="tags-filter mt-20">
              <ASelect
                v-model="localSelectedTagIds"
                :options="orderTags"
                select-label="name"
                track-key="id"
                label="Только выбранные теги"
                :readonly="loading"
                disable-deleted
                :disabled="!!localExcludeTagIds?.length"
                multiple
                class="mr-20"
              />
              <ASelect
                v-model="localExcludeTagIds"
                :options="orderTags"
                select-label="name"
                track-key="id"
                label="Исключить теги"
                :readonly="loading"
                :disabled="!!localSelectedTagIds?.length"
                disable-deleted
                multiple
              />
            </div>
            <ASpacer text="Категории" class="mt-20" />
            <div class="tags-filter mt-20">
              <ASelect
                v-model="localSelectedCategoryIds"
                :options="orderCategory"
                select-label="name"
                track-key="id"
                label="Только выбранные категории"
                :readonly="loading"
                disable-deleted
                :disabled="!!localExcludeCategoryIds?.length"
                multiple
                class="mr-20"
              />
              <ASelect
                v-model="localExcludeCategoryIds"
                :options="orderCategory"
                select-label="name"
                track-key="id"
                label="Исключить категории"
                :readonly="loading"
                :disabled="!!localSelectedCategoryIds?.length"
                disable-deleted
                multiple
              />
            </div>
            <div class="mt-40">
              <AButton :disabled="loading" @click="resetFilters"> Сбросить фильтры </AButton>
            </div>
          </div>
          <ASpacer text="Настройка отображения отделов" class="mb-40" />
          <div>
            <ASelect
              v-model="localSelectedDepartments"
              :options="departments"
              select-label="name"
              label="Отображаемые отделы"
              :disabled="loading"
              multiple
            />
          </div>
        </div>
      </div>
    </APageAside>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'MonitoringAsideFiltersPage',
  props: {
    showAsideFilters: {
      type: Boolean,
      default: null,
    },
    loading: {
      type: Boolean,
      default: null,
    },
    employeeId: {
      type: String,
      default: null,
    },
    deadline: {
      type: [String, Date, Array],
      default: null,
    },
    selectedDepartments: {
      type: Array,
      default: null,
    },
    selectedTagIds: {
      type: Array,
      default: () => [],
    },
    excludeTagIds: {
      type: Array,
      default: () => [],
    },
    selectedCategoryIds: {
      type: Array,
      default: () => [],
    },
    excludeCategoryIds: {
      type: Array,
      default: () => [],
    },
    departments: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    'close',
    'update:employeeId',
    'update:deadline',
    'update:selectedDepartments',
    'update:selectedTagIds',
    'update:excludeTagIds',
    'update:selectedCategoryIds',
    'update:excludeCategoryIds',
  ],
  computed: {
    ...mapState({
      employees: (s) => s.user.employees,
      orderTags: (s) => s.order.orderTags,
      orderCategory: (s) => s.order.orderCategory,
    }),
    filtredEmployees() {
      return this.employees.filter((e) => !e.deleted);
    },
    localEmployeeId: {
      get() {
        return this.employeeId;
      },
      set(val) {
        this.$emit('update:employeeId', val);
      },
    },
    localDeadline: {
      get() {
        return this.deadline;
      },
      set(val) {
        this.$emit('update:deadline', val);
      },
    },
    localSelectedDepartments: {
      get() {
        return this.selectedDepartments;
      },
      set(val) {
        if (val.length === this.departments.length) {
          this.$emit('update:selectedDepartments', []);
          return;
        }
        this.$emit('update:selectedDepartments', val);
      },
    },
    localSelectedTagIds: {
      get() {
        return this.selectedTagIds;
      },
      set(val) {
        this.$emit('update:selectedTagIds', val);
      },
    },
    localExcludeTagIds: {
      get() {
        return this.excludeTagIds;
      },
      set(val) {
        this.$emit('update:excludeTagIds', val);
      },
    },
    localSelectedCategoryIds: {
      get() {
        return this.selectedCategoryIds;
      },
      set(val) {
        this.$emit('update:selectedCategoryIds', val);
      },
    },
    localExcludeCategoryIds: {
      get() {
        return this.excludeCategoryIds;
      },
      set(val) {
        this.$emit('update:excludeCategoryIds', val);
      },
    },
  },
  methods: {
    closeHandler() {
      this.$emit('close');
    },
    resetFilters() {
      this.localEmployeeId = null;
      this.localDeadline = null;
      this.localSelectedTagIds = [];
      this.localExcludeTagIds = [];
      this.localSelectedCategoryIds = [];
      this.localExcludeCategoryIds = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  position: absolute;
  right: 0;
  top: 0;
  // z-index: 999;
  width: 500px;
  // height: 500px;
  background-color: red;
  height: 100%;
}
.tags-filter {
  display: flex;
  flex-direction: row;
}
</style>
