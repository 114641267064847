<template>
  <div ref="order-table" class="order-table-wrapper" @mousewheel="handleScrollTable">
    <div ref="left-side" class="left-side" />
    <div ref="top-side" class="top-side" />
    <div class="order-table">
      <table class="table" cellspacing="0" cellpadding="0" border="0">
        <tr id="order-table-first-tr">
          <OrderInfoBlock
            :is-selected-order="isSelectedOrder"
            :loading="orderloading"
            @changeTab="changeTabHandler"
          />
          <template
            v-for="(product, productInd) of pageOrder.products"
            :key="`order-product-col-${product.index}_${product.localIndex}`"
          >
            <th
              v-if="!product.localIndex || !!getProductColSpan(productInd)"
              class="item-block"
              :colspan="getProductColSpan(productInd) || 1"
            >
              <ProductBlock
                :index="product.index"
                :local-index="product.localIndex"
                :shadow-row="topBlockHidden"
                :class="{ 'products-compontnt': topBlockHidden }"
                :is-selected-order="isSelectedOrder"
                :selected-info-tab="selectedInfoTab"
              />
              <div :class="{ 'product-shadow': topBlockHidden }" />
            </th>
          </template>
          <th class="add-mockup">
            <div v-if="accessToChange" class="add-mockup-btn" @click="addProductHandler">
              <Fa name="icon" icon="plus" class="icon" style="width: 38px; height: 38px" />
            </div>
          </th>
        </tr>
        <tr v-show="pageOrder.players.length" class="headers-string" valign="bottom" align="center">
          <td class="fixed-index">
            <div>N</div>
          </td>
          <td class="fixed-row">
            <div class="fixed-row-content">Имя</div>
            <div :class="{ shadowRow: fioBlockHidden }" />
          </td>
          <td>Надпись на форме</td>
          <td>№ на поле</td>
          <td>Пол</td>
          <td>Сумма</td>
          <td
            v-for="colRazmHeaderInd of pageOrder.products.length"
            :key="`${colRazmHeaderInd}-headers`"
          >
            <div class="headers-string__col-razm">
              <div>Разм.</div>
              <div>Колич.</div>
            </div>
          </td>
          <td>Комментарий</td>
        </tr>
        <tr
          v-for="(player, playerInd) of pageOrder.players"
          :key="`order-player-row-${player.index}_${playerInd}`"
          class="player-row"
        >
          <td class="fixed-row-index" valign="center">
            <div class="fixed-row-index-content row-content index">
              <div class="index__label">
                {{ playerInd + 1 }}
              </div>
              <!-- v-if="pageOrder.players.length > 1" -->
              <div
                v-if="accessToChange"
                class="index__del-btn pointer"
                @click="deletePlayerHandler({ playerInd })"
              >
                <Fa name="icon" icon="times" class="icon times" style="width: 20px; height: 20px" />
              </div>
            </div>
          </td>
          <td class="fixed-row">
            <div class="fixed-row-content row-content fio">
              <input
                :value="player.fio"
                :readonly="!accessToChange"
                @input="
                  updatePlayerHandler({
                    playerInd,
                    selectedOrder: isSelectedOrder,
                    data: $event.target.value,
                    key: 'fio',
                  })
                "
              />
            </div>
            <div :class="{ shadowRow: fioBlockHidden }" />
          </td>
          <td>
            <div class="row-content lettering">
              <input
                :value="player.lettering"
                :readonly="!accessToChange"
                @input="
                  updatePlayerHandler({
                    playerInd,
                    selectedOrder: isSelectedOrder,
                    data: $event.target.value,
                    key: 'lettering',
                  })
                "
              />
            </div>
          </td>
          <td>
            <div class="row-content number">
              <input
                :value="player.number"
                :readonly="!accessToChange"
                @input="
                  updatePlayerHandler({
                    playerInd,
                    selectedOrder: isSelectedOrder,
                    data: $event.target.value,
                    key: 'number',
                  })
                "
              />
            </div>
          </td>
          <td>
            <div class="row-content sex">
              <ASelect
                :model-value="player.sexId"
                :options="allSexes"
                :read-only="!accessToChange"
                disable-deleted
                select-label="name"
                white
                :error-border="!!getOrderErrMess(`players[${playerInd}].sexId`)"
                @change="
                  updatePlayerHandler({
                    playerInd,
                    selectedOrder: isSelectedOrder,
                    data: $event,
                    key: 'sexId',
                  })
                "
              />
            </div>
          </td>
          <td>
            <div class="row-content sum">
              <ALabel :value="getPlayerSum(playerInd)" white borderless postfix="₽" />
            </div>
          </td>

          <td
            v-for="(qtSize, indx) of pageOrder.playerProducts[playerInd]"
            :key="`${indx}_${player.index}`"
          >
            <div v-if="qtSize.quantity" class="col-size">
              <ASimpleTableSelect
                white
                :model-value="qtSize.size"
                :read-only="!accessToChange"
                :options="
                  avaibleSexForProduct(pageOrder.products[indx], pageOrder.players[playerInd].sexId)
                "
                :error-border="!!getOrderErrMess(`playerProducts[${playerInd}][${indx}].size`)"
                @change="
                  updatePlayerProductsHandler({
                    productInd: indx,
                    playerInd,
                    selectedOrder: isSelectedOrder,
                    data: $event,
                    key: 'size',
                  })
                "
              />
              <div class="table-input">
                <input
                  type="number"
                  min="0"
                  :value="qtSize.quantity"
                  :readonly="!accessToChange"
                  @input="
                    updatePlayerProductsHandler({
                      productInd: indx,
                      playerInd,
                      selectedOrder: isSelectedOrder,
                      data: +$event.target.value,
                      key: 'quantity',
                    })
                  "
                />
                <div v-if="accessToChange" class="table-input__arrows">
                  <span
                    class="table-input__up"
                    @click="
                      updatePlayerProductsHandler({
                        productInd: indx,
                        playerInd,
                        selectedOrder: isSelectedOrder,
                        data: qtSize.quantity + 1,
                        key: 'quantity',
                      })
                    "
                  />
                  <span
                    v-show="qtSize.quantity > 0"
                    class="table-input__down"
                    @click="
                      updatePlayerProductsHandler({
                        productInd: indx,
                        playerInd,
                        selectedOrder: isSelectedOrder,
                        data: qtSize.quantity - 1,
                        key: 'quantity',
                      })
                    "
                  />
                </div>
              </div>
            </div>
            <div
              v-else
              class="col-size-empty"
              :class="{
                'plus': avaibleSexForProduct(
                  pageOrder.products[indx],
                  pageOrder.players[playerInd].sexId,
                  true
                ),
              }"
              @click="
                avaibleSexForProduct(
                  pageOrder.products[indx],
                  pageOrder.players[playerInd].sexId,
                  true
                ) &&
                  updatePlayerProductsHandler({
                    productInd: indx,
                    playerInd,
                    selectedOrder: isSelectedOrder,
                    data: 1,
                    key: 'quantity',
                  })
              "
            ></div>
          </td>

          <td>
            <div class="row-content comment">
              <input
                :class="{ 'errorBorder': !!getOrderErrMess(`players[${playerInd}].comment`) }"
                :value="player.comment"
                @input="
                  updatePlayerHandler({
                    playerInd,
                    selectedOrder: isSelectedOrder,
                    data: $event.target.value,
                    key: 'comment',
                  })
                "
              />
            </div>
          </td>
        </tr>
        <tr class="add-player">
          <td></td>
          <td colspan="5" class="add-player__td">
            <AButton v-if="accessToChange" light class="add-player__btn" @click="addPlayerHandler">
              Добавить игрока
            </AButton>
          </td>
        </tr>
      </table>
      <div class="spacer" />
    </div>
    <div class="import-error">
      <OrderXlImportErrors :is-selected-order="isSelectedOrder" />
    </div>
    <AsideAddProductPage :add-product="addProduct" @close="addProduct = null" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import OrderXlImportErrors from './components/OrderXlImportErrors.vue';
import OrderInfoBlock from './components/OrderInfoBlock.vue';
import ProductBlock from './components/ProductBlock.vue';
import { parseDictionary } from '@/utils/dictionaryUtils.js';
import AsideAddProductPage from './components/AsideAddProductPage.vue';
import { getOrderSum } from '@/utils/order.js';
import { ORDER_STATE } from '../../../constants';
import accessMixin from '@/mixin/accessMixin.js';

export default {
  name: 'OrderTable',
  components: {
    OrderXlImportErrors,
    AsideAddProductPage,
    OrderInfoBlock,
    ProductBlock,
  },
  mixins: [accessMixin],
  props: {
    isSelectedOrder: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      orderloading: false,

      fioBlockHidden: false,
      topBlockHidden: false,

      selectedInfoTab: null,
      addProduct: null,
      // addProduct: {
      //   currency: 'RUR',
      //   clientId: '0c5c3420-34bb-4d1d-b8f3-faeb46e6f012',
      //   isSelectedOrder: true,
      // },
    };
  },
  computed: {
    ...mapState({
      newOrder: (s) => s.order.newOrder,
      selectedOrder: (s) => s.order.selectedOrder,
      selectedOrderLoading: (s) => s.order.selectedOrderLoading,
      assortmentDictionary: (s) => s.dictionary.assortmentDictionary,
      availableAssortmentItemFabrics: (s) => s.dictionary.availableAssortment.itemFabrics,
      availableAssortmentitemSex: (s) => s.dictionary.availableAssortment.itemSex,
      selectedOrderValidateErrors: (s) => s.order.selectedOrderInfo.orderErrors,
      newOrderValidateErrors: (s) => s.order.newOrderInfo.orderErrors,
    }),
    allSexes() {
      const result = this.parseDictionary(this.assortmentDictionary.sex).filter((s) => !s.deleted);
      return result;
    },
    avaibleSexForProduct() {
      return (product, sexId, check = false) => {
        if (!check) {
          return (
            this.availableAssortmentitemSex
              .find((i) => i.itemId === product.itemId)
              ?.sexes?.find((s) => s.sexId === sexId)?.sizeChart || []
          );
        }
        const items = this.pageOrder.products.reduce((acc, curr) => {
          if (curr.index == product.index) {
            acc.push(curr.itemId);
          }
          return acc;
        }, []);
        return items.every((itemId) => {
          return this.availableAssortmentitemSex
            .find((i) => i.itemId === itemId)
            ?.sexes?.find((s) => s.sexId === sexId)?.sizeChart?.length;
        });
      };
    },
    accessToChange() {
      return (
        this.isCanChangeOrder &&
        ![ORDER_STATE.COMPLETED, ORDER_STATE.PRODUCTION, ORDER_STATE.TECH_DESIGN].includes(
          this.pageOrder._old.state
        )
      );
    },
    pageOrder() {
      return this.isSelectedOrder ? this.selectedOrder : this.newOrder;
    },
    globalLoading() {
      return this.orderloading;
    },
    orderId() {
      const orderId = this.$route.params.orderId;
      return orderId === 'new' ? null : orderId;
    },
    getProductsReverse() {
      return this.pageOrder.products.concat().reverse() || [];
    },
    getProductColSpan() {
      return (ind) => {
        const item = this.pageOrder.products[ind];
        return item?.localIndex === 1
          ? this.getProductsReverse.find((_) => _.index === item.index).localIndex
          : null;
      };
    },
  },
  created() {
    this.parseDictionary = parseDictionary;
  },
  mounted() {
    this.setPlayersObserver();
    this.orderTable = this.$refs['order-table'];
  },
  beforeUnmount() {
    this.fioHeaderObserver.disconnect();
    this.topHeaderObserver.disconnect();
  },
  methods: {
    ...mapActions({
      getOrder: 'order/getOrder',
    }),
    getPlayerSum(playerInd) {
      return getOrderSum(this.pageOrder, playerInd);
    },
    ...mapMutations({
      addPlayer: 'order/addPlayer',
      deletePlayer: 'order/deletePlayer',
      updatePlayer: 'order/updatePlayer',
      updatePlayerProducts: 'order/updatePlayerProducts',
    }),
    addProductHandler() {
      if (!this.pageOrder.currency) {
        this.$notifyError({
          title: `Необходимо выбрать валюту. (На вкладке "Заказ")`,
        });
        return;
      }
      this.addProduct = {
        currency: this.pageOrder.currency,
        clientId: this.pageOrder.clientId,
        isSelectedOrder: this.isSelectedOrder,
      };
    },
    getOrderErrMess(path) {
      const errors = this.isSelectedOrder
        ? this.selectedOrderValidateErrors
        : this.newOrderValidateErrors;
      return errors[path];
    },
    addPlayerHandler() {
      if (this.accessToChange) {
        this.addPlayer({ selectedOrder: this.isSelectedOrder });
      }
    },
    deletePlayerHandler({ playerInd }) {
      if (this.accessToChange) {
        this.deletePlayer({ selectedOrder: this.isSelectedOrder, playerInd });
      }
    },
    updatePlayerHandler(params) {
      if (this.accessToChange) {
        this.updatePlayer(params);
      }
    },
    updatePlayerProductsHandler(params) {
      if (this.accessToChange) {
        this.updatePlayerProducts(params);
      }
    },
    setPlayersObserver() {
      this.fioHeaderObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => (this.fioBlockHidden = !entry.isIntersecting));
      });
      this.topHeaderObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => (this.topBlockHidden = !entry.isIntersecting));
      });
      const fioHeader = this.$refs['left-side'];
      const topHeader = this.$refs['top-side'];
      this.fioHeaderObserver.observe(fioHeader);
      this.topHeaderObserver.observe(topHeader);
    },
    changeTabHandler(activeTab) {
      this.selectedInfoTab = activeTab;
    },
    handleScrollTable(event) {
      const path = event.composedPath ? event.composedPath() : event.path;
      if (!path) {
        return;
      }

      if (
        path.find((el) => el.classList?.contains('item-block')) &&
        !path.find((el) => el.classList?.contains('mousewheel-abort'))
      ) {
        this.orderTable.scrollLeft += event.deltaY;
        event.preventDefault();
        return;
      }

      // if (event.clientX > 775 && event.clientY < 430) {
      //   this.orderTable.scrollLeft += event.deltaY;
      //   event.preventDefault();
      //   return;
      // }
    },
  },
};
</script>
<style lang="scss" scoped>
.order-table-wrapper {
  display: grid;
  grid-template-areas:
    // 'a a a'
    // 'b c c'
    // 'b c c';

    'a a a'
    'b c c'
    'b c c'
    'b e e';

  // height: 100%;
  // width: 100%;
  // height: calc(100% - 300px);

  // height: fit-content;
  grid-gap: 0px;
  padding: 0px;
  margin: 0px;
  align-content: flex-start;
  justify-content: flex-start;

  .top-side {
    grid-area: a;
    background-color: transparent;
    height: 1px;
    z-index: 1;
  }
  .left-side {
    grid-area: b;
    width: 1px;
    background-color: transparent;
  }
  .order-table {
    grid-area: c;
    height: 100%;
  }
  .import-error {
    grid-area: e;
    height: fit-content;
  }

  // width: 92vw !important;
  // height: 92vh !important;
  width: calc(100vw - 110px) !important;
  height: calc(100vh - 110px) !important;
  @include scroll-wider;
  overflow: auto;
}

table,
tr,
td {
  padding: 0px;
  margin: 0;
  width: 100%;
  height: 100%;
}
tr {
  td:first-child {
    width: fit-content;
  }
}
table {
  position: relative;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
.headers-string {
  @include Inter;
  font-size: 9px;
  color: $color-gray;

  height: 18px;
  width: 100%;

  &__col-razm {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}

.item-block {
  position: sticky;
  top: 0px;
  background-color: white;
  border-top: 1px solid $color-white-smoke;

  z-index: 15;

  .product-shadow {
    height: 0px;
    width: 100%;
    box-shadow: $order-table-product-bottom-shadow;
    transition: box-shadow 0.3s;
  }
  width: fit-content;
}
th:nth-child(2) {
  .products-compontnt {
    height: 100%;
    box-shadow: $order-table-product-left-shadow;
    transition: box-shadow 0.3s;
  }
}
th:nth-last-child(2) {
  .products-compontnt {
    box-shadow: $order-table-product-right-shadow;
  }
}

th:after,
th:before {
  content: '';
  position: absolute;
}
th:before {
  right: 0;
  height: 100%;
  border-right: 1px solid $color-white-smoke;
}
th:after {
  left: 0;
  bottom: 0;

  width: 100%;
  border-bottom: 1px solid $color-white-smoke;
}

tr {
  height: 45px;
  padding: 0px;
  margin: 0px;
  // border-collapse: collapse;
}
td,
th {
  width: 100%;
  height: 100%;
  // border: 1px solid red;
  padding: 0;
  margin: 0;
  // border-collapse: collapse;
}

tr {
  .icon.times {
    color: $color-red;
  }
  .index__del-btn {
    display: none;
  }
  &:hover {
    .index__label {
      display: none;
    }
    .index__del-btn {
      display: block;
    }
  }
}

// ROW
.row-content {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;

  // height: 40px;

  &.index {
    display: flex;
    width: 30px;
    justify-content: center;
    align-items: center;
  }
  &.fio {
    width: max-content;
    background-color: rgb(14, 8, 8);
  }
  &.lettering {
    width: max-content;
  }
  &.number {
    width: 50px;
  }
  &.sex {
    width: 100px;
  }
  &.sum {
    width: 80px;
  }
  &.comment {
    width: 220px;
  }
}
td {
  width: fit-content;
  // padding: 0px 3px;
  border: 1px solid $color-white-smoke;
}
.player-row {
  padding: 0px;
  margin: 0px;

  input {
    color: $smoke-blue;
    border: none;
    height: 100%;
    width: 100%;
    outline: none;
    padding: 0px 10px;

    @include InterSemibold;
    font-size: 14px;

    &:focus {
      outline: 1px solid $color-bg-smoke-active;
    }
    &.errorBorder {
      outline: 1px solid $color-red;
    }
  }
}
.fixed-index {
  position: sticky;
  left: 30px;

  background-color: white;
  left: 0px;
}

.fixed-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;

  height: 100%;
  width: 100%;

  margin: 0px;

  position: sticky;
  left: 29px;
  &-index {
    left: -1px;

    height: 100%;

    position: sticky;
    background-color: white;
    z-index: 11;
  }
  &-content {
    width: 100%;
    margin: 0px;
  }

  z-index: 10;

  background-color: white;

  .shadowRow {
    z-index: 2;
    width: 0px;
    box-shadow: $order-table-row-shadow;
  }
}

.add-mockup {
  display: flex;
  flex-direction: row-reverse;
  // width: 100%;
  width: 220px;
  height: 100%;

  &-btn {
    display: flex;
    justify-content: center;
    justify-items: center;

    align-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    background-color: $color-bg-light-blue-active;

    cursor: pointer;
    &:active {
      transform: scale(0.99);
    }
  }
  .icon {
    color: $cornflower-blue;
  }
}

// player-row
.col-size {
  display: flex;
  flex-direction: row;

  flex-wrap: nowrap;

  width: 250px;
  height: 100%;
  gap: 1px;
  input[type='number'] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  .table-input {
    &__arrows {
      display: flex;
      position: relative;
    }
    &__up,
    &__down {
      position: absolute;
      cursor: pointer;

      border: solid $color-gray;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;

      right: 8px;

      &:active {
        border-width: 0 3px 3px 0;
      }
    }
    &__up {
      bottom: 25px;
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }
    &__down {
      bottom: 8px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }
}

.col-size-empty {
  display: flex;
  width: 100%;
  height: 100%;
  margin: auto;

  position: relative;

  &:not(.plus) {
    background-color: $color-bg-light-blue;
  }
  &.plus {
    --b: 4px;
    width: 44px;
    border: 10px solid;
    border-color: #fff;
    background: conic-gradient(from 90deg at var(--b) var(--b), #fff 90deg, $color-bg-smoke 0)
      calc(100% + var(--b) / 2) calc(100% + var(--b) / 2) / calc(50% + var(--b))
      calc(50% + var(--b));

    &:after {
      cursor: pointer;
      content: '';
      position: absolute;
      top: -9px;
      left: -114px;
      bottom: -9px;
      width: 250px;
    }
  }
}
</style>
