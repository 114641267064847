export default {
  install: function (app) {
    app.config.globalProperties.$copy = function (textToCopy) {
      if (navigator.clipboard && window.isSecureContext) {
        return navigator.clipboard.writeText(textToCopy);
      } else {
        // text area method
        const textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        textArea.textContent = textToCopy;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((resolve, reject) => {
          document.execCommand("copy")
            ? resolve(true)
            : reject(new Error("copyError"));
          textArea.remove();
        });
      }
    };
  },
};
