import BaseService from './services/BaseService.js';

class productionApi extends BaseService {
  async getAllDepartments() {
    const data = await this.api.get(`/production/department`);
    return data;
  }
  async changeDepartment({ department }) {
    const data = await this.api.post(`/production/department`, { department });
    return data;
  }
  async getProductionLine({ id, itemId }) {
    let options;
    if (itemId) {
      options = { params: { itemId: itemId } };
    }
    const data = await this.api.get(`/production/production-line/${id || ''}`, options);
    return data;
  }
  async getAllProductionLines() {
    const data = await this.api.get(`/production/production-line`);
    return data;
  }
  async getAllProductionLinesForItem({ itemId }) {
    const data = await this.api.get(`/production/production-line/get-all-by-item-id/${itemId}`);
    return data;
  }
  async getProductionLineCardsAndItems({ productionLineId }) {
    const data = await this.api.get(
      `/production/production-line-cards-and-items/${productionLineId}`
    );
    return data;
  }
  async changeOrderItemProductionLine({ productionLineIds, itemId, resolveChangedDepartments }) {
    const data = await this.api.post(`/production/item-production-line/change-order`, {
      productionLineIds,
      itemId,
      resolveChangedDepartments,
    });
    return data;
  }
  async changeProductionLine({
    productionLine,
    resolveChangedDepartments,
    fillAddedItemDepartments,
  }) {
    const data = await this.api.post(`/production/production-line`, {
      productionLine,
      resolveChangedDepartments,
      fillAddedItemDepartments,
    });
    return data;
  }
  async changeItemProductionLine({ productionLine, resolveChangedDepartments, itemId }) {
    const data = await this.api.post(`/production/item-production-line`, {
      productionLine,
      itemId,
      resolveChangedDepartments,
    });
    return data;
  }
  async saveOrderCardsProductionLineIds({ orderId, cards }) {
    const data = await this.api.post(`/production/change-order-cards-production-line`, {
      orderId,
      cards,
    });
    return data;
  }
  async getAllOrderItemProductionLinesIdsByOrderId({ orderId }) {
    const data = await this.api.get(
      `/production/production-line/get-all-order-item-production-lines-ids-by-order-id/${orderId}`
    );
    return data;
  }
}
export default new productionApi();
