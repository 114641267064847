import dictionaryApi from '../../../api/dictionaryApi';

export default {
  async getAssortmentDictionary({ commit, state }, { force } = { force: false }) {
    if (state.assortmentDictionaryDownload && !force) {
      return;
    }
    const { data } = await dictionaryApi.getAssortmentDictionary();
    commit('SET_ITEMS_DICTIONARY', data);
  },
  async getProductionParams({ commit, state }, { force = false } = { force: false }) {
    if (state.productionParamsDownload && !force) {
      return;
    }
    const { data } = await dictionaryApi.getProductionParams();
    commit('SET_PRODUCTION_PARAMS', data);
  },

  /**
   * @param {string} key ['itemFabrics','itemSex', 'itemSize']
   */
  async getAvailableAssortment({ commit }, { key }) {
    const { data } = await dictionaryApi.getAvailableAssortment({ key });
    commit('SET_AVAILABLE_ASSORTMENT', { key, data });
  },

  async changeArticle({ commit }, { article }) {
    const { data } = await dictionaryApi.changeArticle({ article });
    commit('SET_BASE_DICTIONARY', { key: 'articles', data, oldData: article });
    commit('assortment/SET_ARTICLE_IN_ITEMS', data, { root: true });
  },

  async changeFabric({ commit }, { fabric }) {
    const { data } = await dictionaryApi.changeFabric({ fabric });
    commit('SET_BASE_DICTIONARY', { key: 'fabrics', data, oldData: fabric });
  },

  async changeCategory({ commit }, { category }) {
    const { data } = await dictionaryApi.changeCategory({ category });
    commit('SET_BASE_DICTIONARY', { key: 'categories', data, oldData: category });
  },

  async changeGrowType({ commit }, { growType }) {
    const { data } = await dictionaryApi.changeGrowType({ growType });
    commit('SET_BASE_DICTIONARY', { key: 'growTypes', data, oldData: growType });
  },

  async changeProductType({ commit }, { productType }) {
    const { data } = await dictionaryApi.changeProductType({ productType });
    commit('SET_BASE_DICTIONARY', { key: 'productTypes', data, oldData: productType });
  },

  async changeSex({ commit }, { sex }) {
    const { data } = await dictionaryApi.changeSex({ sex });
    commit('SET_BASE_DICTIONARY', { key: 'sex', data, oldData: sex });
  },
};
