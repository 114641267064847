<template>
  <div>
    <APageAside
      :title="department ? 'Редактирование отдела' : 'Создание отдела'"
      wide
      @close="closeHandler"
    >
      <div v-if="department || creatingDepartment">
        <DepartmentBlock
          :department="department"
          :creating-department="creatingDepartment"
          @close="closeHandler"
          @update:user="$emit('update:department', $event)"
        />
      </div>
    </APageAside>
  </div>
</template>

<script>
import DepartmentBlock from './DepartmentBlock.vue';

export default {
  name: 'CreateDepartmentAsideBlock',
  components: {
    DepartmentBlock,
  },
  props: {
    department: {
      type: Object,
      default: null,
    },
    creatingDepartment: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'update:department'],
  methods: {
    closeHandler() {
      this.$emit('close');
    },
  },
};
</script>
