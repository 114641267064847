export default {
  props: {
    errorPropPath: {
      type: String,
      default: null,
    },
    formErrors: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    getErrMess(path) {
      const fullPath = this.errorPropPath ? `${this.errorPropPath}.${path}` : path;
      return this.formErrors[fullPath];
    },
  },
};
