<template>
  <div>
    <APageAside title="Настройка таблицы и фильтров" wide @close="closeHandler">
      <div v-if="openTableSettingAside">
        <ASpacer text="Столбцы в таблице заказов" class="mb-15 mt-15" bold />
        <div class="fields">
          <div v-for="field of filtredFields" :key="field.key" class="fields__item">
            <AToggle
              :model-value="selectedFields.includes(field.key)"
              :label="field.name"
              :disabled="PERMANENT_FIELDS.includes(field.key)"
              @change="changeSelectedFieldHandler($event, field.key)"
            />
            <ATooltip v-if="field.qtitle" :qtitle="field.qtitle" />
          </div>
        </div>
        <template v-if="isMainManager || isAdmin">
          <ASpacer text="Заказы" class="mb-15 mt-15" bold />
          <div class="a-row">
            <AToggle
              v-model="settings.showOrdersAllManager"
              label="Показывать заказы всех менеджеров"
            />
            <ATooltip
              qtitle="Одновременно с включением этой опции - будет осуществлятся поиск по всем заказам"
            />
          </div>
        </template>

        <ASpacer text="Поиск" class="mb-15 mt-15" bold />
        <div class="search">
          Поиск по id, имени, amoId заказа, а также дате отгрузке/дате создания, если они указаны
        </div>
        <AButton :disabled="!isTouched" class="mt-30" @click="saveBtnHandler"> Сохранить </AButton>
      </div>
    </APageAside>
  </div>
</template>

<script>
import { ADMIN_AND_MANAGERS_FIELDS, ALL_FIELDS, PERMANENT_FIELDS } from '../ordersDictionary.js';
import { STORAGE_KEY, STORAGE_PATH, storage } from '../../../utils/localStorage.js';
import { cloneDeep, xor } from '../../../utils/lodash.js';
import accessMixin from '@/mixin/accessMixin.js';
import { isEqual } from 'lodash';

export default {
  name: 'OrdersTableSettingAsideBlock',
  mixins: [accessMixin],
  props: {
    openTableSettingAside: {
      type: Boolean,
      default: null,
    },
  },
  emits: ['close', 'updateOrdersTableSetting'],
  data() {
    return {
      selectedFields: [],
      settings: {
        showOrdersAllManager: false,
      },

      settingsChache: null,
      selectedFieldsChache: [],
    };
  },
  computed: {
    isTouched() {
      return (
        xor(this.selectedFields, this.selectedFieldsChache).length != 0 ||
        !isEqual(this.settings, this.settingsChache)
      );
    },
    filtredFields() {
      if (this.isMainManager || this.isAdmin) {
        return ALL_FIELDS;
      }
      return ALL_FIELDS.filter((f) => !ADMIN_AND_MANAGERS_FIELDS.includes(f.key));
    },
  },
  watch: {
    openTableSettingAside(value) {
      if (this.isTouched) return;
      this.selectedFieldsChache = value ? cloneDeep(this.selectedFields) : [];
      this.settingsChache = value ? cloneDeep(this.settings) : null;
    },
  },
  created() {
    this.PERMANENT_FIELDS = PERMANENT_FIELDS;
    this.setInitialSetting();
  },
  methods: {
    changeSelectedFieldHandler(value, field) {
      if (value) {
        return this.selectedFields.push(field);
      }
      this.selectedFields = this.selectedFields.filter((f) => f != field);
    },
    saveBtnHandler() {
      storage(
        { key: STORAGE_KEY.SETTINGS, path: STORAGE_PATH.ORDERS_TABLE_SETTING },
        {
          selectedFields: this.selectedFields,
          settings: this.settings,
        }
      );
      this.selectedFieldsChache = cloneDeep(this.selectedFields);
      this.settingsChache = cloneDeep(this.settings);

      this.$emit('updateOrdersTableSetting', {
        selectedFields: this.selectedFields,
        settings: this.settings,
      });
      this.closeHandler();
    },
    setInitialSetting() {
      const data = storage({ key: STORAGE_KEY.SETTINGS, path: STORAGE_PATH.ORDERS_TABLE_SETTING });
      if (!data) {
        this.selectedFields = [
          ...PERMANENT_FIELDS,
          'clientName',
          'state',
          'createdAt',
          'deadline',
          'amoId',
        ];
        this.settings = {
          showOrdersAllManager: false,
        };
        this.saveBtnHandler();
        return;
      }
      this.selectedFields = data.selectedFields;
      this.settings = data.settings;
      this.saveBtnHandler();
    },
    closeHandler() {
      this.$emit('close');
      return;
    },
  },
};
</script>
<style lang="scss" scoped>
.fields {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 250px;

  &__item {
    display: flex;
    flex-direction: row;
    margin: 5px 0px;
  }
}
.search {
  font-size: 13px;
  color: $color-gray;
}
</style>
