<template>
  <div class="block-wrapper">
    <div v-if="localDepartment" class="department">
      <div v-if="localDepartment.deleted" class="department-alert-deleted">
        <p>
          Отдел не доступен для создания новых производственных линий, но все еще используется в уже
          созданных производственных линиях
        </p>
        <p>Чтобы отделом не могли пользоваться удалите его во всех производственных линиях</p>
      </div>
      <div v-if="department" class="department-title-block mb-5">
        <div class="department-title-block__title">
          Отдел:&nbsp;
          <span class="a-bold"> {{ localDepartment.id }}. </span> &emsp;{{ localDepartment.name }}
        </div>
      </div>
      <div v-else class="department-title-block">
        <div class="department-title-block__title">Новый отдел</div>
      </div>
      <div class="department-details">
        <div class="department-details_block">
          <AInput
            v-model="localDepartment.name"
            :read-only="!accessToChange"
            label="Название"
            :error="getErrMess('name')"
          />
        </div>
        <div class="department-details_block">
          <AInput
            v-model="localDepartment.shiftDuration"
            type="number"
            label="Доступно в день, минут"
            :disabled="localDepartment.deleted"
            :read-only="!accessToChange"
            :error="getErrMess(`shiftDuration`)"
            :min="0"
            :integer="true"
          />
          <AInput
            v-model="localDepartment.tableIndex"
            type="number"
            label="№п/п в таблице мониторинга"
            :disabled="localDepartment.deleted"
            :read-only="!accessToChange"
            :error="getErrMess(`tableIndex`)"
            :min="0"
            :integer="true"
          />
        </div>
        <div class="department-details_block mt-20">
          <AToggle
            v-model="localDepartment.multipleCardsInWork"
            :disabled="!accessToChange || localDepartment.deleted"
            label="Несколько карт в работе"
            class="toggle"
            :error="getErrMess(`multipleCardsInWork`)"
          />
          <AToggle
            v-model="localDepartment.changeAllCards"
            disabled
            label="Сканировать сразу весь заказ"
            class="toggle"
            :error="getErrMess(`changeAllCards`)"
          />
        </div>

        <ASpacer text="Подэтапы" class="mt-15" bold />
        <div class="department-details_block mt-20">
          <transition-group type="transition" name="flip-list">
            <Draggable
              v-if="localDepartment.subDepartments.length"
              v-model="localDepartment.subDepartments"
              item-key="id"
              class="draggable__prodLine"
              ghost-class="ghost"
              :scroll-sensitivity="20"
              :disabled="departmentLoading || !accessToChange"
              @start="drag = true"
              @end="drag = false"
              @change="updateSubDepartmentsIndex"
            >
              <template #item="{ element: subDep, index }">
                <div
                  :class="{
                    base: index === 0,
                  }"
                  class="draggable__item"
                >
                  <div class="index mt-20">{{ index + 1 }}.</div>

                  <div class="name">
                    <AInput
                      v-model="subDep.name"
                      :read-only="departmentLoading || !accessToChange"
                      :error="getErrMess(`subDepartments[${index}].name`)"
                    />
                    <div :class="{ 'mt-30': subDep.employeeIds.length }">
                      <ASelect
                        v-model="subDep.employeeIds"
                        :options="getAvailableEmployees"
                        :select-label-fn="getEmployeeName"
                        track-key="guid"
                        label="Сотрудники"
                        :readonly="departmentLoading"
                        disable-deleted
                        multiple
                      />
                    </div>
                  </div>

                  <div class="mt-20">
                    <AToggle
                      :disabled="!accessToChange"
                      class="ml-5"
                      title="Новые карты попадают в выбранный подэтап"
                      :model-value="subDep.isDefault"
                      @change="changeIsDefauleSubDepartment(index, $event)"
                    />
                  </div>
                  <div>
                    <AButton
                      v-if="accessToChange"
                      danger
                      class="ml-20"
                      @click="delSubDepartment(index)"
                    >
                      Удалить
                    </AButton>
                  </div>
                </div>
              </template>
            </Draggable>
          </transition-group>
        </div>
        <div class="department-details_block mt-10 mb-20">
          <AButton
            v-if="accessToChange && !localDepartment.deleted"
            class="20"
            @click="addSubDepartment"
          >
            Добавить подэтап
          </AButton>
        </div>
        <!-- <div class="department-details_block mt-20">
          <AToggle
            v-model="localDepartment.isActive"
            :disabled="localDepartment.deleted"
            label="Активен"
            class="toggle"
          />
        </div> -->
        <div class="department-details_block mt-30">
          <AButton
            v-if="accessToChange"
            success
            :disabled="!islocalDepartmentModified"
            @click="saveDepartment"
          >
            Сохранить
          </AButton>
          <AButton
            v-if="accessToChange"
            :disabled="!islocalDepartmentModified"
            @click="updateFromProp()"
          >
            Сбросить
          </AButton>
          <AButton
            v-if="isCanChangeProduction && !creatingDepartment && !departmentLoading"
            :disabled="islocalDepartmentModified"
            :danger="!localDepartment.deleted"
            :success="localDepartment.deleted"
            @click="deleteItemHandler(!localDepartment.deleted)"
          >
            {{ localDepartment.deleted ? 'Восстановить' : 'Удалить' }}
          </AButton>
        </div>
        <ALoader v-if="departmentLoading" centered green />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { cloneDeep, isEqual } from '../../../utils/lodash.js';
import accessMixin from '../../../mixin/accessMixin.js';
import { departmentBlockSchema } from '../../../utils/validate/index.js';
import validateMixin from '../../../mixin/validate.js';
import ConfirmDialog from '@@/components/Confirm/index.vue';
import { getUserName } from '@/utils/string';

const newDepartmentItem = {
  id: null,
  name: null,
  shiftDuration: 480,
  isActive: true,
  deleted: false,
  multipleCardsInWork: false,
  changeAllCards: false,
  isNewItem: true,
  subDepartments: [],
};

const newSubDepartment = {
  id: null,
  name: null,
  employeeIds: [],
  departmentId: null,
  index: 1,
  isDefault: false,
};

export default {
  name: 'DepartmentBlock',
  mixins: [accessMixin, validateMixin],
  props: {
    department: {
      type: Object,
      default: null,
    },
    creatingDepartment: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  data() {
    return {
      departmentLoading: false,

      localDepartment: null,

      drag: false,
    };
  },
  computed: {
    ...mapState({
      employees: (s) => s.user.employees,
      items: (s) => s.assortment.items,
      assortmentDictionary: (s) => s.dictionary.assortmentDictionary,
      assortmentDictionaryDownload: (s) => s.dictionary.assortmentDictionaryDownload,
    }),
    accessToChange() {
      return this.isCanChangeProduction && !this.localDepartment.deleted && !this.departmentLoading;
    },
    islocalDepartmentModified() {
      return !isEqual(this.department, this.localDepartment);
    },
    getAvailableEmployees() {
      return this.creatingDepartment
        ? []
        : this.employees.filter((e) => e.departmentIds.includes(this.department.id));
    },
  },
  watch: {
    department: function (department) {
      this.updateFromProp(department);
    },
    localDepartment: {
      handler: async function () {
        this.updateSubDepartmentsIndex();
      },
      deep: true,
    },
  },
  created() {
    this.updateFromProp();
  },
  methods: {
    ...mapActions({
      changeDepartment: 'production/changeDepartment',
    }),
    getEmployeeName(e) {
      return getUserName(e, null);
    },
    async saveDepartment() {
      const formValidation = this.$form(this.localDepartment, departmentBlockSchema);
      if (!formValidation.isValid) {
        this.$notifyError({ title: `Ошибка валидации формы` });
        return;
      }
      this.departmentLoading = true;
      try {
        await this.changeDepartment({ department: this.localDepartment });
        this.$emit('close');
        this.$notify({ title: 'Сохранено' });
      } catch (err) {
        console.log(err);
      } finally {
        this.departmentLoading = false;
      }
    },
    deleteItemHandler(needDeleted) {
      if (this.islocalDepartmentModified) {
        this.$notifyError({
          title: `Сбросте (или сохраните) все изменения в отделе, а затем удалите его`,
        });
        return;
      }
      this.$dialog.addDialog({
        component: ConfirmDialog,
        props: {
          title: needDeleted ? 'Удаление отдела' : 'Восстановление отдела',
          text: `Вы действительно хотите ${needDeleted ? 'удалить' : 'восстановить'} отдел?`,
          onConfirm: (confirm) => {
            confirm.showModal = false;
            this.localDepartment.deleted = needDeleted;
            this.saveDepartment(true);
          },
        },
      });
    },
    updateFromProp(department) {
      const value = this.creatingDepartment ? newDepartmentItem : department || this.department;
      this.localDepartment = cloneDeep(value);
    },
    updateSubDepartmentsIndex() {
      for (let i = 0; i < this.localDepartment.subDepartments.length; i++) {
        const subDepartment = this.localDepartment.subDepartments[i];
        subDepartment.index = i + 1;
        subDepartment.departmentId = this.localDepartment.id;
      }
    },
    addSubDepartment() {
      this.localDepartment.subDepartments.push(cloneDeep(newSubDepartment));
    },
    delSubDepartment(index) {
      this.localDepartment.subDepartments.splice(index, 1);
    },
    changeIsDefauleSubDepartment(index, value) {
      for (let i = 0; i < this.localDepartment.subDepartments.length; i++) {
        const subDep = this.localDepartment.subDepartments[i];
        if (i === index) {
          subDep.isDefault = value;
        } else if (value) {
          subDep.isDefault = false;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.draggable {
  &__item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 10px 0px 10px 0px;

    .index {
      width: 15px;
    }
    .name {
      width: 300px;
    }
    padding-bottom: 10px;
    border-bottom: 1px solid $color-bg-smoke-active;
  }
}

.flip-list-move {
  transition: transform 0.5s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.department {
  &-alert-deleted {
    background-color: $color-red;
    padding: 8px 0px;
    border-radius: 8px;
    text-align: center;
    margin-bottom: 10px;

    @include InterSemibold;
  }

  &-title-block {
    display: flex;
    flex-direction: column;

    &__title {
      @include InterSemibold;
      font-size: 16px;
    }
  }
  &-details {
    display: flex;
    flex-direction: column;
    .department-item-department {
      display: grid;
      grid-template-columns: 1fr 1fr 100px;
      grid-gap: 20px;
      margin-bottom: 10px;
      align-items: flex-end;
    }

    .department-details_block {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 36px;
      margin-bottom: 10px;
      align-items: flex-end;
    }
  }
}
.validation-error-items {
  color: $color-red;
  margin-left: 15px;
}
</style>
