import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      orderValidateErrors: (s) =>
        s.order[`${this?.isSelectedOrder ? 'selectedOrder' : 'newOrder'}Info`].orderErrors,
    }),
  },
  methods: {
    getOrderErrMess(path) {
      return this.orderValidateErrors[path];
    },
  },
};
