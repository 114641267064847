export default {
  install: function (app) {
    app.config.globalProperties.$notifyError = function ({
      duration,
      errors,
      title,
      text,
    }) {
      const errorMessage = errors?.message;
      const isErrorsArr = errorMessage && Array.isArray(errorMessage);

      if (isErrorsArr) {
        app.config.globalProperties.$notify({
          type: "error",
          title,
          text: text || errorMessage[0].message,
          duration,
        });
      } else {
        app.config.globalProperties.$notify({
          type: "error",
          title,
          text: text || errors?.message,
          duration,
        });
      }
    };
  },
};
