/**
 * Валюты
 * @enum {String}
 */
const CURRENCY = {
  RUR: 'RUR',
  EUR: 'EUR',
};

const CURRENCIES = [
  {
    name: 'Рубли',
    value: CURRENCY.RUR,
    sign: '₽',
  },
  {
    name: 'Евро',
    value: CURRENCY.EUR,
    sign: '€',
  },
];

/**
 * Тип пользователя
 * @enum {String}
 */
const USER_TYPES = {
  CUSTOMER: 'customer',
  EMPLOYEE: 'employee',
  DESIGN: 'designer',
  MAIN_DESIGNER: 'mainDesigner',
  MANAGER: 'manager',
  MAIN_MANAGER: 'mainManager',
  MAIN_EMPLOYEE: 'mainEmployee',
  ADMIN: 'admin',
};

/**
 * Тип пользователя
 * @enum {String}
 */
const USER_TYPES_DICTIONARY = {
  [USER_TYPES.CUSTOMER]: 'Клиент',
  [USER_TYPES.EMPLOYEE]: 'Сотрудник',
  [USER_TYPES.DESIGN]: 'Дизайнер',
  [USER_TYPES.MAIN_DESIGNER]: 'Глав. Дизайнер',
  [USER_TYPES.MANAGER]: 'Менеджер',
  [USER_TYPES.MAIN_MANAGER]: 'Глав. Менеджер',
  [USER_TYPES.MAIN_EMPLOYEE]: 'Нач. произв.',
  [USER_TYPES.ADMIN]: 'Админ',
};

/**
 * Статус заказа
 * @enum {String}
 */
const ORDER_STATE = {
  NEW: 'new',
  DESIGN: 'design',
  TECH_DESIGN: 'techDesign',
  PRODUCTION: 'production',
  COMPLETED: 'completed',
};

/**
 * Статус заказа
 * @enum {String}
 */
const ORDER_STATE_DICTIONARY = {
  [ORDER_STATE.NEW]: 'Новый',
  [ORDER_STATE.DESIGN]: 'Дизайн',
  [ORDER_STATE.TECH_DESIGN]: 'Тех. дизайн',
  [ORDER_STATE.PRODUCTION]: 'Производство',
  [ORDER_STATE.COMPLETED]: 'Завершен',
};

/**
 * Тип параметра заказа
 * @enum {String}
 */
const ORDER_PARAM_TYPE = {
  PACKING_DATE: 'packingDate',
  PAID_DATE: 'paidDate',
  CATEGORY: 'category',
};

/**
 * Типы значений параметра заказа
 * @enum {String}
 */
const ORDER_PARAM_VALUE_TYPE = {
  SIMPLE_VALUE: [ORDER_PARAM_TYPE.PACKING_DATE, ORDER_PARAM_TYPE.PAID_DATE],
  SINGLE_RELATION: [],
  MULTIPLE_RELATIONS: [ORDER_PARAM_TYPE.CATEGORY],
};

/**
 * Типы изменения сущности параметра заказа
 * @enum {String}
 */
const ORDER_PARAMS_CHANGED_EVENT = {
  UPDATED: 'updated',
  DELETED: 'deleted',
};

/**
 * Группировка заказов по типам для аналитики
 * @enum
 */
const ORDER_CATEGORY_GROUP = {
  PROJECT_MONEY: 'projectMoney',
  PROJECT: 'project',
  CLIENTS: 'clients',
  ALL_PACKAGED: 'allPackaged',
};

/**
 * Статус дизайна
 * @enum {String}
 */
const DESIGN_SOLUTION_STATUS = {
  QUEUE: 'queue',
  NEW: 'new',
  PROCESS: 'process',
  VALIDATE: 'validate',
  COMPLETE: 'complete',
};

const DESIGN_PRODUCT_STATUSES = [
  {
    value: DESIGN_SOLUTION_STATUS.NEW,
    name: 'Новый',
  },
  {
    value: DESIGN_SOLUTION_STATUS.QUEUE,
    name: 'Очередь',
  },
  {
    value: DESIGN_SOLUTION_STATUS.PROCESS,
    name: 'В работе',
  },
  {
    value: DESIGN_SOLUTION_STATUS.VALIDATE,
    name: 'Проверка',
  },
  {
    value: DESIGN_SOLUTION_STATUS.COMPLETE,
    name: 'Готово',
  },
];

/**
 * Вкладки на странице заказа
 * @enum {String}
 */
const ORDER_TAB = {
  CLIENT: 'client',
  TEAM: 'team',
  ORDER: 'order',
  PRODUCTION: 'production',
  DESIGN: 'design',
  IMPORT: 'import',
};

/**
 * store dictionary/SET_BASE_DICTIONARY
 * если нужно добавить поле - нужно внести правки везде, где изменяется item
 */
const ITEM_DICTIONARY_FIELDS = [
  'id',
  'name',
  'desc',
  'productTypeId',
  'categoryId',
  'growTypeId',
  'articleId',
  'deleted',
  'createdAt',
];
/**
 * поля которые апдейдим сокетами в событии patchOrder
 */
const FIELD_TO_PATCH_ORDER = [
  'state',
  'name',
  'deadline',
  'closeDate',
  'createdCardDate',
  'postponedDeadline',
  'urgencyType',
  'cardsQuantity',
];

const FIELD_TO_MONITORING_CARD = [
  'state',
  'generalState',
  'currentDepartmentId',
  'currentEmployeeId',
  'updatedAt',
  'productionLineId',
  'urgencyType',
  'subDepartmentId',
];

/**
 * Формат мокапов
 */
const MOCKUP_FORMATS = ['png', 'jpg', 'jpeg'];

const DEFAULT_PRODUCTION_LINE_ID = 1;

/**
 * @enum {String}
 */
const CARD_STATE = {
  QUEUE: 'queue',
  PROCESS: 'process',
  PAUSE: 'pause',
  DONE: 'done',
};

const CARD_STATE_DICTIONARY = {
  [CARD_STATE.QUEUE]: 'Очередь',
  [CARD_STATE.PROCESS]: 'В работе',
  [CARD_STATE.PAUSE]: 'Пауза',
  [CARD_STATE.DONE]: 'Готово',
};

/**
 * @enum {String}
 */
const CARD_GENERAL_STATE = {
  QUEUE: 'queue',
  PROCESS: 'process',
  DONE: 'done',
};

const MONITORING_SORTED_CARD_STATE = [
  CARD_STATE.PROCESS,
  CARD_STATE.PAUSE,
  CARD_STATE.QUEUE,
  CARD_STATE.DONE,
];

/**
 * @enum {String}
 */
const MONITORING_CARD_ACTIONS = {
  FULL_CARD: 'showFullCard',
  USET_CARD_DETAILS: 'userCardDetails',
};

const SYSTEM_USER_ID = '11111111-1111-1111-1111-111111111111';
const SYSTEM_USER_LG = {
  guid: SYSTEM_USER_ID,
  name: 'System LG',
};

/**
 * срочность заказа / карт
 * В ПОРЯДКЕ УБЫВАНИЯ
 * @enum {String}
 */
const URGENCY_TYPE = {
  L1: 'l1',
  L2: 'l2',
  L3: 'l3',
};

/**
 * сложность типа изделия
 * @enum
 */
const PRODUCT_TYPE_COMPLEXITY = {
  NO_COMPLEXITY: 'noComplexity',
  EASY: 'easy',
  MEDIUM: 'medium',
  HARD: 'hard',
  VERY_HARD: 'veryHard',
};

const PRODUCT_TYPE_COMPLEXITY_DICTIONARY = [
  {
    value: PRODUCT_TYPE_COMPLEXITY.NO_COMPLEXITY,
    label: '-',
  },
  {
    value: PRODUCT_TYPE_COMPLEXITY.EASY,
    label: 'Легко',
  },
  {
    value: PRODUCT_TYPE_COMPLEXITY.MEDIUM,
    label: 'Средне',
  },
  {
    value: PRODUCT_TYPE_COMPLEXITY.HARD,
    label: 'Сложно',
  },
  {
    value: PRODUCT_TYPE_COMPLEXITY.VERY_HARD,
    label: 'Очень сложно',
  },
];

export {
  CURRENCY,
  ORDER_TAB,
  CURRENCIES,
  CARD_STATE,
  USER_TYPES,
  ORDER_STATE,
  URGENCY_TYPE,
  SYSTEM_USER_ID,
  SYSTEM_USER_LG,
  MOCKUP_FORMATS,
  ORDER_PARAM_TYPE,
  CARD_GENERAL_STATE,
  ORDER_CATEGORY_GROUP,
  FIELD_TO_PATCH_ORDER,
  USER_TYPES_DICTIONARY,
  CARD_STATE_DICTIONARY,
  ORDER_PARAM_VALUE_TYPE,
  DESIGN_SOLUTION_STATUS,
  ORDER_STATE_DICTIONARY,
  ITEM_DICTIONARY_FIELDS,
  DESIGN_PRODUCT_STATUSES,
  PRODUCT_TYPE_COMPLEXITY,
  MONITORING_CARD_ACTIONS,
  FIELD_TO_MONITORING_CARD,
  DEFAULT_PRODUCTION_LINE_ID,
  ORDER_PARAMS_CHANGED_EVENT,
  MONITORING_SORTED_CARD_STATE,
  PRODUCT_TYPE_COMPLEXITY_DICTIONARY,
};
