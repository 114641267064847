/**
 * @param {Object|Array} o object | Array
 * @param {String} s string path
 */
export function getPropData(o, s) {
  // return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, ''); // strip a leading dot
  const a = s.split('.');
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}

export function nestedPropertyExists(obj, props) {
  const prop = props.shift();
  return prop === undefined
    ? true
    : Object.hasOwnProperty.call(obj, prop)
    ? nestedPropertyExists(obj[prop], props)
    : false;
}
