import { inject, reactive } from 'vue';
import { useStore } from 'vuex';
import { dialogInjectionKey } from 'gitart-vue-dialog';

import ConfirmDialog from '@@/components/Confirm/index.vue';
import { ORDER_PARAM_TYPE } from '../constants';

export function useOrderSetPaid() {
  const $dialog = inject(dialogInjectionKey);
  const store = useStore();
  const orderSetPaidLoading = reactive({});

  const orderSetPaidHandler = async ({ guid }) => {
    $dialog.addDialog({
      component: ConfirmDialog,
      props: {
        title: 'Изменение состояния заказа',
        text: `Заказ оплачен?`,
        onConfirm: async (confirm) => {
          orderSetPaidLoading[guid] = true;
          orderSetPaid(guid);
          confirm.showModal = false;
        },
      },
    });
  };
  const orderSetPaid = async (orderId) => {
    try {
      await store.dispatch('order/patchOrderParams', {
        orderId,
        orderParams: { [ORDER_PARAM_TYPE.PAID_DATE]: [{ data: { value: new Date() } }] },
      });
    } catch (e) {
      console.log(e);
    } finally {
      delete orderSetPaidLoading[orderId];
    }
  };
  return { orderSetPaidHandler, orderSetPaidLoading };
}
