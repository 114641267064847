import Axios from 'axios';
import qs from 'qs';
import { notify } from '@kyvg/vue3-notification';
import errorsDictionary from '../../i18/ru/errors';
import { TOKEN } from '../../constants/auth.js';
import store from '../../store';

const httpProvider = Axios.create({
  baseURL: process.env.VUE_APP_API_TARGET + 'api/',
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'Authorization': localStorage.getItem(TOKEN.ACCESS_TOKEN),
  },
});

let refreshRequest = null;
httpProvider.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== '/auth/login' && err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const refreshToken =
            store.state.auth.refreshToken || localStorage.getItem(TOKEN.REFRESH_TOKEN);
          if (!refreshRequest) {
            refreshRequest = store.dispatch('auth/refresh', { refreshToken });
          }
          await refreshRequest;
          refreshRequest = null;
          originalConfig.headers.Authorization = store.state.auth.token;
          store.dispatch('socket/connect');
          return httpProvider({ ...originalConfig });
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  }
);

class HttpService {
  constructor() {
    this.refreshTokenPromise = null;
    this.provider = httpProvider;
  }

  async get(url, options = {}) {
    try {
      const { data } = await this._processGetRequest(url, options);
      if (data?.errors) {
        throw data.errors;
      }
      return data;
    } catch (error) {
      if (Array.isArray(error.response?.data?.errors)) {
        this._handleError(error.response.data);
      }
      if (
        error.request.responseType === 'blob' &&
        error.response.data instanceof Blob &&
        error.response.data.type &&
        error.response.data.type.toLowerCase().indexOf('json') != -1
      ) {
        this._handleError(JSON.parse(await error.response.data.text()));
      }
      throw error;
    }
  }

  async post(url, options = {}) {
    try {
      const { data } = await this._processPostRequest(url, options);
      if (data?.errors) {
        throw new Error(data.errors);
      }
      return data;
    } catch (error) {
      if (Array.isArray(error.response?.data?.errors)) {
        this._handleError(error.response.data);
      }
      throw error;
    }
  }

  async patch(url, options = {}) {
    try {
      const { data } = await this._processPatchRequest(url, options);
      if (data?.errors) {
        throw Object({ errors: data.errors });
      }
      return data;
    } catch (error) {
      if (Array.isArray(error.response?.data?.errors)) {
        this._handleError(error.response.data);
      }
      throw error;
    }
  }
  async delete(url, options = {}) {
    try {
      const { data } = await this._processDeleteRequest(url, options);
      if (data?.errors) {
        throw Object({ errors: data.errors });
      }
      return data;
    } catch (error) {
      if (Array.isArray(error.response?.data?.errors)) {
        this._handleError(error.response.data);
      }
      throw error;
    }
  }

  _processGetRequest(url, options = {}) {
    return this.provider.get(url, {
      ...options,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
  }

  _processPostRequest(url, options) {
    return this.provider.post(url, options);
  }

  _processPatchRequest(url, options) {
    return this.provider.patch(url, options);
  }

  _processDeleteRequest(url, options) {
    return this.provider.delete(url, {
      data: options,
    });
  }

  _handleError(data) {
    if (Array.isArray(data.data?.data?.showAdditionalMessages)) {
      data.data.data.showAdditionalMessages.forEach((err) => {
        notify({ text: err.title, type: 'error' });
      });
    }
    data.errors.forEach((err) => {
      notify({ text: errorsDictionary[err.code] || err.code, type: 'error' });
    });
    console.log('API_ERRORS', data.errors);
    throw data.errors;
  }
}

export default HttpService;
