<template>
  <div class="nav-wrapper">
    <nav class="nav" :class="{ open: isNavOpen }">
      <div class="top-block">
        <div class="header">
          <div class="header__logo">
            <img
              class="img clover"
              src="../../assets/lg_logo.png"
              alt="photo"
              width="27"
              height="27"
            />
            <img
              class="img luckygrass"
              src="../../assets/luckygrass.png"
              alt="photo"
              width="130"
              height="23"
            />
          </div>
          <div class="header__burger">
            <navigation-burger-menu
              :type="isNavOpen"
              @click="changeNavigationOpen"
            />
          </div>
        </div>

        <div class="profile-block">
          <div class="profile-block__manager">
            <span
              class="profile-block__manager-name"
              @click="$router.push({ path: '/user' })"
              >{{ name }}</span
            >
            <span class="profile-block__manager-role">{{ type }}</span>
          </div>
          <div
            class="profile-block__icon-cover pointer"
            @click="$router.push({ path: '/user' })"
          >
            <img
              v-if="avatarId"
              class="icon"
              src="http://194.67.86.47:3001/img/Screenshot_31591436311784.jpeg"
              alt="avatar"
              width="40px"
              height="40px"
            />
            <Fa
              v-else
              @click="$router.push({ path: '/user' })"
              class="icon faicon"
              icon="user-circle"
              style="width: 40px; height: 40px"
            />
          </div>
        </div>
        <div class="separator"></div>
        <div class="sections">
          <NavigationSection :links="links" :isNavOpen="isNavOpen">
          </NavigationSection>
        </div>
      </div>
      <div class="bottom-block">
        <div class="exit-btn" @click="$emit('logout')">
          <div class="exit-icon">
            <Fa icon="sign-out-alt" class="active-icon fa-exit-icon" />
          </div>
          <div class="exit noSelect">
            <span> Выйти</span>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import NavigationBurgerMenu from "./components/navigation-burger-menu.vue";
import NavigationSection from "./components/navigation-section.vue";
export default {
  name: "Navigation",
  components: {
    NavigationBurgerMenu,
    NavigationSection,
  },
  emits: ["logout"],
  props: {
    links: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    avatarId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isNavOpen: false,
      currentPath: "/",
    };
  },
  methods: {
    changeNavigationOpen() {
      this.isNavOpen = !this.isNavOpen;
    },
    linkHandler(path) {
      console.log({ path });
      if (this.$route.path != path) this.$router.push({ path });
    },
  },
};
</script>

<style lang="scss" scoped>
$padding-left: 20px;

.nav-wrapper {
  height: 100%;
  min-height: 100%;
  background-color: $color-black-gray;
  color: $color-gray;
  font-size: 20px;

  // box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.8);

  .header {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 60px;

    @include noSelect;
    justify-content: space-between;

    &__logo {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-left: $padding-left;
      align-items: center;
      .clover {
        margin-right: 10px;
      }
      width: 0px;
      opacity: 0;
      transition: opacity 0.2s;
    }
    &__burger {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      align-items: center;

      margin-right: 20px;
    }
    margin-bottom: 15px;
  }

  .icon {
    color: $color-gray;

    &.active-icon {
      color: $cornflower-blue;
    }
    transition: color 0.3s;
  }

  .nav {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    width: 62px;

    &.open {
      width: 256px;
    }
    transition: all 0.3s;
    overflow-y: auto;
    overflow-x: hidden;
    @include scroll-wide;
    background-color: $color-black-gray;
  }

  .profile-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    transition: margin-left 0.3s;
    overflow: hidden;

    &__icon-cover {
      padding: 0px 10px 0px 0px;
      .faicon {
        &:hover {
          color: $cornflower-blue;
          transition: color 0.3s;
        }
      }
    }
    &__manager {
      display: flex;
      flex-direction: column;
      margin-left: 5px;
      width: 0px;
      opacity: 0;
      visibility: hidden;
      &:hover {
        cursor: pointer;
        color: $cornflower-blue;
        transition: color 0.3s;
      }
      &-role {
        padding-top: 2px;
        font-size: 10px;
        color: $color-gray;
      }
    }
  }

  .nav.open {
    .header {
      .header__logo {
        width: 100%;
        opacity: 1;
        transition-delay: 250ms;
        transition-property: opacity;
      }
    }
    .profile-block {
      margin-left: 22px;

      &__icon-cover {
        padding: 0px 20px;
      }

      &__manager {
        width: fit-content;
        transition: opacity 0.3s;
        opacity: 1;
        visibility: visible;
      }
    }

    .bottom-block {
      .exit {
        opacity: 1;
        transition: opacity 0.2s;
        width: fit-content;
        transition: width 0.7s;
      }
      .exit-icon {
        .fa-exit-icon {
          transform: rotateZ(0deg);
        }
      }
    }
  }

  .separator {
    height: 1px;
    border-bottom: 1px solid $color-gray;
    margin: 20px 15px 27px 15px;
  }

  .bottom-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    .exit-btn {
      display: flex;
      flex-direction: row;
      align-items: center;

      cursor: pointer;

      .exit-icon {
        .fa-exit-icon {
          transform: rotateZ(180deg);
          padding: 0px;
          margin: 0px;
        }
      }
      .exit {
        width: 0px;
        opacity: 0;
        transition: opacity 0.2s;
      }
      padding: 2px 60px;
      border: 1px solid transparent;
      transition: border-color 0.15s;
      &:hover {
        border: 1px solid $cornflower-blue;
        border-radius: 4px;
      }
    }
  }
}
</style>
