<template>
  <div
    v-click-away="close"
    class="a-select"
    :class="{
      open: isOpen,
      disabled,
      loading,
      greyish: !white,
      labeled: label,
      markered: !noMarkered,
      selected: !showPlaceholder || !noData,
      readOnly,
      error,
      prefix,
      errorBorder,
    }"
    :key="`a-select--${uuid}`"
  >
    <!-- {{ $attrs }} -->
    <div class="a-select__wrapper">
      <label :for="`a-select-${uuid}`">
        <div>
          <label class="a-select__label" :for="`a-select-${uuid}`">
            {{ label }}
          </label>
        </div>
        <div class="a-select__el-wrapper">
          <SelectTagsList
            v-if="isValidMultiple && data.length"
            :tags="data"
            :select-label="selectLabel"
            :select-label-fn="selectLabelFn"
            :options="options"
            :label-prefix="labelPrefix"
            :track-key="trackKey"
            :model-only-key="modelOnlyKey"
            @remove="removeHandler"
            @click="toggleOpened"
            :read-only="readOnly"
          />
          <input
            :id="`a-select-${uuid}`"
            v-show="showPlaceholder"
            :value="selectedLabel"
            :tabindex="disabled ? -1 : 0"
            autocomplete="off"
            type="text"
            v-bind="$attrs"
            class="a-select__el placeholder"
            :placeholder="$attrs.placeholder"
            @focus="toggleOpened"
            :readonly="readOnly"
          />
          <!--
            TODO: 
             v-bind="$attrs" 
             BUG in vue3.listeners in attrs
          -->
          <input
            v-show="isOpen"
            v-model="filter"
            :tabindex="disabled ? -1 : 0"
            autocomplete="off"
            type="text"
            class="a-select__el filter"
            placeholder=""
            :readonly="readOnly"
          />
        </div>
      </label>
    </div>
    <div class="a-select__options-wrapper">
      <transition-group name="list" tag="ul" class="a-select__options-list">
        <template v-if="filteredOptions.length">
          <li
            v-for="(item, idx) in filteredOptions"
            :key="idx"
            class="a-select__options-tab"
            :class="{
              'a-select__options-tab--active': isActiveItem(item),
              'a-select__options-tab--deleted': isDeletedItem(item),
            }"
            @click="selectItem(item)"
          >
            <span class="a-select__options-icon" v-if="!noMarkered" />
            <span v-if="prefix" class="a-select__prefix">
              {{ `${item[prefix]}` }}
            </span>
            <slot name="option" v-bind="{ item, idx }">
              <p>
                {{ getLabel(item) }}
              </p>
            </slot>
          </li>
        </template>
        <li v-else-if="isOpen" key="no-options" class="a-select__options-tab">
          <p class="no-options">
            {{ noMarkered ? "—" : "Не найдено" }}
          </p>
        </li>
      </transition-group>
    </div>
    <span class="a-select__error">
      <div class="a-select__error-icon" />
      {{ error }}
    </span>
  </div>
</template>
<script>
import { cloneDeep, isEqual } from "../../utils/lodash.js";
import uuid from "../../mixin/uuid.js";
import SelectTagsList from "./components/select-tags-list.vue";

export default {
  name: "ASelect",
  mixins: [uuid],
  components: {
    SelectTagsList,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    selectLabel: {
      type: String,
      default: null,
    },
    selectLabelFn: {
      type: Function,
      default: null,
    },
    trackKey: {
      type: String,
      default: "id",
    },
    modelOnlyKey: {
      type: Boolean,
      default: true,
    },
    modelValue: {
      type: [Array, Object, String, Number],
      default: null,
    },
    labelPrefix: {
      type: String,
      default: null,
    },
    prefix: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    noMarkered: {
      type: Boolean,
      default: false,
    },
    white: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: null,
    },
    disableDeleted: {
      type: Boolean,
      default: false,
    },
    errorBorder: {
      type: Boolean,
      default: false,
    },
    blockUnselect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: this.modelValue ? this.modelValue : this.multiple ? [] : null,
      filter: null,
      isOpen: false,
    };
  },
  computed: {
    getLabel() {
      return (item) => {
        let label = this.labelPrefix ? `${this.labelPrefix}` : "";
        if (this.selectLabelFn) {
          // if (this.modelOnlyKey) {
          //   // TODO проверить
          //   label += this.selectLabelFn(
          //     this.options.find((i) => i[this.trackKey] == item)
          //   );
          // } else {
          //   label += this.selectLabelFn(item);
          // }
          label += this.selectLabelFn(item);
        } else if (this.selectLabel) {
          if (this.modelOnlyKey) {
            label += this.options.find(
              (i) => i[this.trackKey] == item[this.trackKey]
            )?.[this.selectLabel];
          } else {
            label += item[this.selectLabel];
          }
        } else {
          label += item;
        }
        return label;
      };
    },

    isValidMultiple() {
      return this.multiple && Array.isArray(this.data);
    },
    filteredOptions() {
      let result = null;

      if (this.filter) {
        result = this.options.filter((item) => {
          // const fieldToSearch = this.selectLabel
          //   ? this.prefix
          //     ? String(item[this.selectLabel] + item[this.prefix]).toLowerCase()
          //     : String(item[this.selectLabel]).toLowerCase()
          //   : String(item).toLowerCase();

          return this.getLabel(item)
            .toLowerCase()
            .includes(this.filter.toLowerCase());
        });
      } else {
        result = this.options;
      }

      return result;
    },
    noData() {
      return this.multiple ? !this.data.length : !this.data;
    },
    deletedKey() {
      return "deleted";
    },
    selectedLabel() {
      // if (this.data && this.selectLabel) {
      //   if (!this.modelOnlyKey) {
      //     return this.data[this.selectLabel];
      //   }
      //   return this.options.find((i) => isEqual(i[this.trackKey], this.data))?.[
      //     this.selectLabel
      //   ];
      // }
      if (this.data) {
        if (this.selectLabelFn) {
          if (this.modelOnlyKey) {
            // TODO проверить
            return this.selectLabelFn(
              this.options.find((i) => isEqual(i[this.trackKey], this.data))
            );
          } else {
            return this.selectLabelFn(this.data);
          }
        } else if (this.selectLabel) {
          if (!this.modelOnlyKey) {
            return this.data[this.selectLabel];
          }
          return this.options.find((i) =>
            isEqual(i[this.trackKey], this.data)
          )?.[this.selectLabel];
        }
      }
      return this.data;
    },
    showPlaceholder() {
      const isDataArray = this.data
        ? Array.isArray(this.data) && this.data.length
        : false;
      const isDataEmpty = !this.data || !isDataArray;
      const isMultipleWithoutData = this.multiple && isDataEmpty;
      return (
        (!this.isOpen && !this.multiple && isDataEmpty) ||
        (!this.isOpen && isMultipleWithoutData)
      );
    },
  },
  watch: {
    modelValue: {
      handler() {
        this.updateFromProp();
      },
      deep: true,
    },
    data: {
      handler() {
        this.$emit("update:modelValue", this.data);
        this.$emit("change", this.data);
      },
      deep: true,
    },
  },
  methods: {
    close() {
      this.isOpen = false;
    },
    toggleOpened() {
      if (this.readOnly) return;
      this.isOpen = !this.isOpen;
    },
    updateFromProp() {
      if (isEqual(this.data, this.modelValue)) {
        return;
      }
      if (this.modelValue) {
        this.data = this.modelValue;
      } else {
        this.data = this.multiple ? [] : null;
      }
    },
    isDeletedItem(item) {
      if (!this.disableDeleted) return;
      return !!item[this.deletedKey];
    },
    isActiveItem(item) {
      if (this.multiple) {
        if (this.modelOnlyKey && typeof item === "object") {
          return this.data.includes(item[this.trackKey]);
        }
        return this.data.find((activeItem) => isEqual(activeItem, item));
      }
      if (this.modelOnlyKey && typeof item === "object") {
        return this.data == item[this.trackKey];
      }
      return isEqual(this.data, item);
    },
    selectItem(item) {
      if (
        (this.disableDeleted && !!item[this.deletedKey]) ||
        (this.blockUnselect && this.isActiveItem(item))
      )
        return;

      if (this.multiple) {
        if (
          this.data.find((activeItem) => {
            if (this.modelOnlyKey) {
              if (typeof activeItem === "object") {
                return activeItem[this.trackKey] == item[this.trackKey];
              } else {
                return isEqual(activeItem, item[this.trackKey]);
              }
            } else {
              return isEqual(activeItem, item);
            }
          })
        ) {
          this.data = this.data.filter((activeItem) => {
            if (this.modelOnlyKey) {
              if (typeof activeItem === "object") {
                return activeItem[this.trackKey] != item[this.trackKey];
              } else {
                return !isEqual(activeItem, item[this.trackKey]);
              }
            } else {
              return !isEqual(activeItem, item);
            }
          });
        } else {
          this.data.push(
            typeof item === "object" && this.modelOnlyKey
              ? item[this.trackKey]
              : item
          );
        }
      } else {
        const value =
          this.modelOnlyKey && typeof item === "object"
            ? item[this.trackKey]
            : item;

        isEqual(this.data, value)
          ? (this.data = null)
          : (this.data = cloneDeep(value));

        this.close();
      }
    },
    removeHandler(item) {
      this.$emit("remove", item);
      this.data = this.data.filter((option) => option !== item);
    },
  },
};
</script>
<style lang="scss" scoped>
.a-select {
  display: flex;
  flex-direction: column;
  position: relative;

  max-width: 100%;
  width: 100%;

  margin-bottom: 0;

  @include InterSemibold;

  font-size: 14px;
  .open {
    // .a-select__el-wrapper {
    //   position: absolute;
    //   top: 223.5px;
    //   left: 600.5px;
    //   z-index: 9999;
    //   height: 500px;
    //   width: 450px;
    //   background-color: red;
    // }
    // .a-select__options-list {
    //   position: absolute;
    //   top: 223.5px;
    //   left: 600.5px;
    //   z-index: 9999;
    //   height: 500px !important;
    //   width: 450px !important;
    //   background-color: red;
    //   z-index: 9999;
    // }
    // .a-select__options-wrapper {
    //   position: absolute;
    //   top: 223.5px;
    //   left: 600.5px;
    //   z-index: 9999;
    //   height: 500px !important;
    //   width: 450px !important;
    //   background-color: red;
    //   z-index: 9999;
    // }
  }
  &:not(.open):not(.readOnly) {
    .a-select {
      &__el-wrapper {
        &::after {
          position: absolute;
          top: 37%;
          right: 10px;

          height: 2px;
          width: 2px;

          content: "";
          border: solid $color-gray;
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 2px;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
        }
      }
      // &__options-list {
      //   overflow-y: auto;
      //   overflow-x: hidden;
      //   max-height: 0px;
      // }
    }
  }

  &.labeled {
    margin-top: 20px;
  }

  &.selected {
    .a-select {
      &__label {
        bottom: 110%;
        font-size: 12px;
      }
    }
  }
  &.greyish {
    .a-select {
      &__el {
        background-color: $color-gray-bg;
      }
      &__el:hover:not(.disabled) {
        background-color: $color-gray-bg-dark;
      }
    }
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.6;
    .a-select__label {
      color: $color-gray;
    }
  }
  &.errorBorder {
    .a-select__wrapper {
      outline: 1px solid $color-red;
    }
  }
  &__wrapper {
    position: relative;
  }

  &__el {
    position: relative;

    display: inline-block;

    color: $color-black;

    box-sizing: border-box;
    height: 40px;
    max-width: 100%;
    width: 100%;

    overflow: hidden;

    line-height: 40px;
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;

    border: none;
    border-color: transparent;
    border-radius: 6px;
    outline: none;

    padding: 13px 15px 13px 15px;

    transition: all 0.2s;

    -webkit-appearance: none;

    &.filter::placeholder {
      color: $color-gray;
    }
  }

  &__label {
    position: absolute;
    bottom: 30%;
    left: 10px;
    transition: bottom 0.2s, font-size 0.2s;
    color: $color-dark-gray;
    font-size: 14px;
    z-index: 1;
  }
  &__options-wrapper {
    position: relative;
    z-index: 10;
  }

  &__options-list {
    z-index: 10;
    @include InterSemibold;
    @include boxShadow;

    font-size: 14px;

    margin: 0;
    padding: 0;
    list-style: none;

    @include scroll;

    background-color: $color-white;

    overflow-y: auto;
    overflow-x: hidden;
    max-height: 0px;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transition: max-height 0.2s;

    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &__options-tab {
    position: relative;
    padding: 0;
    margin: 0;
    padding-left: 10px;
    width: 100%;
    box-sizing: border-box;
    // height: 40px;
    max-height: fit-content;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    cursor: pointer;
    transition: background-color 0.2s;
    &:hover {
      background-color: $color-bg-light-blue;
    }

    &--active {
      &:hover {
        color: $color-red;
      }
    }
    &--deleted {
      background-color: $color-light-gray;
      &:hover {
        background-color: $color-light-gray;
        cursor: default;
      }
    }
    .no-options {
      width: 100%;
      @include noSelect;
      cursor: auto;
    }
  }
  &.markered {
    .a-select {
      &__options-tab {
        &--active {
          &::before {
            content: "";

            position: absolute;
            left: 6px;
            top: 50%;

            transform: translate(0, -50%);

            width: 12px;
            height: 12px;

            background-image: url(../../assets/icon/statuses/check-mark.svg);
            background-repeat: no-repeat;
            background-size: contain;
          }

          &:hover::before {
            background-image: url(../../assets/icon/statuses/close-cross.svg);
          }
        }
      }
    }
  }
  &.readOnly {
    .a-select {
      &__el {
        border: dashed 1px $color-light-gray;
        background-color: $color-white;
      }
      &.greyish &__el &:hover {
        background-color: $color-white;
      }
    }
    &.greyish {
      .a-select {
        &__el:hover {
          background-color: $color-white;
        }
      }
    }
  }

  &__options-icon {
    width: 13px;
  }
  &__options-tab:not(:last-child) {
    border-bottom: 1px solid $color-bg-smoke-active;
  }
  &.open {
    .a-select {
      position: relative;
      &__options-list {
        max-height: 200px;
        margin-bottom: 100px;
      }
      &__el {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}
.a-select__prefix {
  display: inline-block;

  box-sizing: border-box;
  height: 16px;
  min-width: 16px;
  background-color: $color-bg-light-blue-active;
  border-radius: 4px;

  padding-left: 5px;
  padding-right: 5px;
  margin-right: 5px;
  margin-left: -2px;

  @include InterBold;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  vertical-align: text-top;
}

.a-select__error {
  @include input-error(".a-select");
}
</style>
