export default {
  // AUTH:
  'AUTH_ERROR_LOGIN': 'Ошибка авторизации',
  'AUTH_ERROR_REFRESH': 'Ошибка авторизации',
  'AUTH_ERROR_LOGOUT': 'Ошибка авторизации',
  'VALIDATION_INSUFFICIENT_ACCESS_LEVEL': 'Недостаточный уровень доступа',
  'AUTH_CAN_NOT_RESET_PASSWORD': 'Не удалось сбросить пароль',
  'AUTH_CODE_ALREADY_SEND': 'Код уже отправлен',
  'AUTH_WRONG_CODE': 'Неправильный код из смс',

  // CARD:
  'CARD_NOT_FOUND': 'Карта не найдены',
  'CARD_NOT_FOUNDS': 'Карты не найдены',
  'CARD_CAN_NOT_GET': 'Не удалось получить карты',
  'CARD_CAN_NOT_CREATE': 'Не удалось создать карты',
  'CARD_CAN_NOT_UPDATE': 'Не удалось обновить карты',

  //CARD_MOBILE
  'CARD_NEED_COMPLETE_IN_PREVIOUS_DEPARTMENT': 'Необходимо завершить в предыдущем отделе',
  'CARD_NEED_GET_IN_WORK': 'Сначала необходимо взять партию в работу',
  'CARD_WRONG_DEPARTMENT': 'Невалидный отдел',
  'CARD_CANT_RETURN_CARD_ON_PREVIOUS_DEPARTMENT': 'Нельзя возврашать карту в предыдущий отдел',
  'CARD_NO_ACCESS_TO_DEPARTMENT': 'Нет доступа к этапу',
  'CARD_CAN_NOT_CHANGE_DEPARTMENT': 'Невозможно изменить этап',
  'ALREADY_ON_THIS_DEPARTMENT': 'Партия уже находится на данном этапе',
  'CARD_PATH_CARD_IS_COMPLETE': 'Партия готова. Путь партии завершен',
  'CARD_NOT_FOUND_OR_INVALID': 'Карта удалена. Обратитесь к менеджеру заказа за новой картой',
  'CARD_ALREADY_ON_THIS_DEPARTMENT': 'Карта уже находится в данном отделе',

  //DEPARTMENT
  'DEPARTMENT_NOT_FOUND': 'Не удалось найти отдел',
  'DEPARTMENT_CAN_NOT_GET': 'Не удалось получить отдел',
  'DEPARTMENT_CAN_NOT_CREATE': 'Не удалось создать отдел',
  'DEPARTMENT_CAN_NOT_UPDATE': 'Не удалось обновить отдел',
  'DEPARTMENT_CAN_NOT_DELETE': 'Не удалось удалить отдел',
  'DEPARTMENT_TABLE_INDEX_ALREADY_EXIST': 'Уже существует отдел с таким порядковым номером',

  //PRODUCTION_LINE
  'PRODUCTION_LINE_NOT_FOUND': 'Не удалось найти производственную линию',
  'PRODUCTION_LINE_CAN_NOT_GET': 'Не удалось получить производственную линию',
  'PRODUCTION_LINE_CAN_NOT_CREATE': 'Не удалось создать производственную линию',
  'PRODUCTION_LINE_CAN_NOT_UPDATE': 'Не удалось обновить производственную линию',
  'PRODUCTION_LINE_CAN_NOT_DELETE': 'Не удалось удалить производственную линию',
  'PRODUCTION_LINE_NOT_UNIQUE_DEPARTMENT':
    'Присутствуют повторяющиеся отделы в производственной линии',
  'PRODUCTION_LINE_DEPARTMENT_NOT_FOUND_FOR_RESOLVE_CARD': 'Не указан отдел для переноса карт',
  'PRODUCTION_LINE_INTEGRITY_HAS_BEEN_VIOLATED': 'Нарушена целостность производственной линии',
  'PRODUCTION_LINE_HAVE_CARDS_OR_ITEMS': 'Есть активные карты или изделия',
  'PRODUCTION_LINE_CAN_NOT_CHANGE_ORDER': 'Не удалось изменить поряд производственных линий',

  // ORDER:
  'ORDER_NOT_FOUND': 'Заказ не найден',
  'ORDER_CAN_NOT_CREATE': 'Не удалось создать заказ',
  'ORDER_CAN_NOT_UPDATE': 'Не удалось обновить заказ',
  'ORDER_CAN_NOT_DELETE': 'Не удалось удалить заказ',
  'ORDER_CAN_NOT_CREATE_OR_UPDATE': 'Не удалось создать или обновить заказ',
  'ORDER_CAN_NOT_GET': 'Не удалось получить заказ',
  'ORDER_CAN_NOT_GET_ORDERS': 'Не удалось получить заказы',
  'ORDER_DUBLICATE_DISCOUNT': 'Неправильно установлена скидка',
  'ORDER_PLAYERS_INVALID': 'Невалидное значение у игрока',
  'ORDER_PRODUCTS_INVALID': 'Невалидное значение у продукта',
  'ORDER_PLAYER_PRODUCTS_INVALID': 'Невалидное значение у игрока (Разм/Количество)',
  'ORDER_KITS_INVALID': 'Невалидное значение у набора',
  'ORDER_COMPOSITION_VALIDATION': 'Невалидное значение у заказа',
  'ORDER_CHANGE_STATE_SEPARATE_COMMAND': 'Необходимо сменить состояние заказа отдельным запросом',
  'ORDER_HAVE_PRODUCT_WITHOUT_DESIGN': 'В заказе есть продукты с отсутствующим дизайном',
  'ORDER_HAVE_PRODUCT_WITHOUT_QUANTITY':
    'В заказе есть не использующиеся продукты. (С нулевым кол-вом)',
  'ORDER_HAVE_PLAYER_WITHOUT_QUANTITY':
    'В заказе есть не использущиеся игроки. (С нулевым кол-вом)',
  'ORDER_NO_PLAYERS_IN_ORDER': 'В заказе нет ни одного игрока',
  'ORDER_NO_PRODUCTS_IN_ORDER': 'В заказе нет ни одного продукта',
  'ORDER_CANT_LOVARING_STATE': 'Нельзя понижать статус заказа',
  'ORDER_CAN_NOT_GET_ORDER_PARAM': 'Не удалось получить параметры заказа',
  'ORDER_CAN_NOT_UPDATE_ORDER_PARAM': 'Не удалось обновить параметры заказа',

  // DESIGN:
  'DESIGN_CAN_NOT_GET_DESIGNS': 'Не удалось получить дизайны',
  'DESIGN_CAN_NOT_DELETE_DESIGNS': 'Не удалось удалить дизайн',
  'DESIGN_CAN_NOT_GET_DESIGNS_SOLUTIONS': 'Не удалось получить дизайнерские решения',
  'DESIGN_NOT_FOUND_DESIGNS_SOLUTIONS': 'Не удалось найти дизайнерские решения',
  'DESIGN_CAN_NOT_UPDATE_DESIGNS_SOLUTIONS': 'Не удалось обновить дизайнерские решения',
  'DESIGN_CAN_NOT_GET_DESIGNS_SOLUTIONS_MESSAGES': 'Не удалось получить сообщения',
  'DESIGN_CAN_NOT_CREATE_DESIGNS_SOLUTIONS_MESSAGES': 'Не удалось создать сообщение',
  'DESIGN_CAN_NOT_UPDATE_DESIGNS_SOLUTIONS_MESSAGES': 'Не удалось обновить сообщение',
  'DESIGN_DESIGN_SOLUTION_MESSAGE_INVALID': 'Невалидное сообщение',
  'DESIGN_DESIGN_SOLUTION_MESSAGE_NOT_FOUND': 'Не удалось найти сообщения',

  // USER:
  'USER_CAN_NOT_GET': 'Не удалось получить пользователя',
  'USER_NOT_FOUND': 'Не удалось найти пользователя',
  'USER_CAN_NOT_CREATE': 'Не удалось создать пользователя',
  'USER_CAN_NOT_UPDATE': 'Не удалось обновить пользователя',
  'USER_LOGIN_ALREADY_EXIST': 'Такой логин уже занят',
  'USER_EMAIL_ALREADY_EXIST': 'Такая почта уже занята',
  'USER_PHONE_ALREADY_EXIST': 'Такой телефон уже занят',
  'USER_ALREADY_EXIST': 'Такой пользователь уже существует',
  // USER_MOBILE
  'USER_CAN_NOT_GET_DEPARTMENTS': 'Не удалось загрузить доступные отделы',

  // TEAM:
  'TEAM_NOT_FOUND': 'Не удалось найти команду',
  'TEAM_CAN_NOT_GET': 'Не удалось получить команду',
  'TEAM_CAN_NOT_CREATE': 'Не удалось создать команду',
  'TEAM_CAN_NOT_DELETE': 'Не удалось удалить команду',

  // ITEM:
  'ITEM_CAN_NOT_GET': 'Не удалось получить изделие',
  'ITEM_CAN_NOT_UPDATE': 'Не удалось обновить изделие',
  'ITEM_NOT_FOUND': 'Не удалось найти изделие',
  'ITEM_CAN_NOT_CREATE': 'Не удалось создать изделие',
  'ITEM_CAN_NOT_GET_AVAILABLE_ASSORTMENT': 'Не удалось получит ассортимент',
  'ITEM_BASE_ENTITY_NOT_FOUND': 'Не удалось найти состовляющую ассортимента',
  'ITEM_BASE_ENTITY_ALREADY_EXIST': 'Такая запись уже существует.(Баз. сущность. асс.)',
  'ITEM_CATEGORY_CAN_NOT_UPDATE': 'Не удалось создать категория',
  'ITEM_CATEGORY_NOT_FOUD': 'Не удалось найти категория',
  'ITEM_FABRIC_CAN_NOT_UPDATE': 'Не удалось обновить связь изделие-материал',
  'ITEM_FABRIC_NOT_FOUND': 'Не удалось найти материал',
  'ITEM_ARTICLE_CAN_NOT_UPDATE': 'Не удалось обновить артикул',
  'ITEM_ARTICLE_NOT_FOUND': 'Не удалось найти артикул',
  'ITEM_GROW_TYPE_CAN_NOT_UPDATE': 'Не удалось обновить тип размера',
  'ITEM_GROW_TYPE_NOT_FOUND': 'Тип размера не найден',
  'ITEM_PRODUCT_TYPE_CAN_NOT_UPDATE': 'Не удалось обновить тип продукта',
  'ITEM_PRODUCT_TYPE_NOT_FOUND': 'Не удалось найти тип продукта',
  'ITEM_SEX_CAN_NOT_UPDATE': 'Не удалось обновить пол',
  'ITEM_SEX_NOT_FOUND': 'Не удалось найти пол',
  'ITEM_HAS_NO_PRODUCTION_LINE': 'У изделия не заполнены производственные параметры',

  // SET:
  'SET_NOT_FOUND': 'Не удалось найти комплект',
  'SET_CAN_NOT_GET': 'Не удалось получить комплект',
  'SET_CAN_NOT_CREATE': 'Не удалось создать комплект',
  'SET_CAN_NOT_UPDATE': 'Не удалось обновить комплект',
  'SET_ALREADY_EXIST': 'Такой комплект уже существует',

  // UPLOAD:
  'UPLOAD_FILE_REQUIRED': 'Необходим файл',
  'UPLOAD_CAN_NOT_UPLOAD': 'Не удалось загрузить файл',

  // PRODUCTION_PARAMS:
  'PRODUCTION_PARAMS_CAN_NOT_GET': 'Не удалось получить параметры для производства',

  // GLOBAL_SETTING:
  'GLOBAL_SETTING_CAN_NOT_SET_GLOBAL_SETTING': 'Не удалось установить общие настройки',

  // VALIDATION:
  'VALIDATION_ERROR': 'Ошибка валидации сервера',
  'VALIDATION_INCORRENCT_TIME_INTERVAL': 'Неверный временной интервал',

  // COMMON:
  'COMMON_NO_NEED_CHANGE': 'Нет изменений',
  'COMMON_NOT_ALLOVED': 'Нет доступа',

  // XLSX:
  'XLSX_WRONG_PRODUCT_COUNT': 'Неправильное кол-во изделий',
  'XLSX_WRONG_PLAYER_COUNT': 'Неправильное кол-во игроков',
  'XLSX_DOCUMENT_INTEGRITY_IS_COMPROMISED': 'Нарушена целостность документа',
  'XLSX_CAN_NOT_GET': 'Не удалось загрузить шаблон',

  // MOBILE:
  'MOBILE_CANT_SAVE_DATA': 'Невозможно сохранить данные',
  'MOBILE_CAN_NOT_GET_APP': 'Невозможно получить приложение',
  'MOBILE_CAN_NOT_GET_VERSION': 'Невозможно получить версию приложения',

  // ANALYTICS
  'ANALYTICS_CANT_GET_REPORT': 'Невозможно получить отчет',
  'ANALYTICS_NO_DATA_FOR_REPORT': 'Нет данных для отчета',
  'ANALYTICS_REPORT_IS_ALREADY_DOWNLOADED': 'Подождите, отчет уже загружается',

  // ORDER_TAG
  'ORDER_TAG_NOT_FOUND': 'Не удалось найти тег заказа',
  'ORDER_TAG_CAN_NOT_GET': 'Не удалось получить тег заказа',
  'ORDER_TAG_CAN_NOT_CREATE': 'Не удалось создать тег заказа',
  'ORDER_TAG_CAN_NOT_DELETE': 'Не удалось обновить тег заказа',

  // PRODUCTION_TABLE
  'PRODUCTION_TABLE_NOT_FOUND': 'Не удалось найти данные произв. таблицы',
  'PRODUCTION_TABLE_CAN_NOT_GET': 'Не удалось получить данные произв. таблицы',
};
