<template>
  <APageLayout>
    <template #header>
      <div class="page-header">
        <div class="page-header__container">
          <div class="title">Производственная таблица</div>
          <div class="a-row">
            <AInput v-model.trim="filters.text" placeholder="Фильтр" style="width: 200px" />
            <div v-if="currenctMonthStat" class="ml-40">
              <CurrentMonthStatBlock :currenct-month-stat="currenctMonthStat" />
            </div>
          </div>
          <div>
            <AButton :disabled="globalLoading" @click="downloadXlTable">
              <Fa
                name="icon"
                icon="file-download"
                class="icon faicon"
                style="width: 15px; height: 15px"
              />
            </AButton>
          </div>
          <div>
            <AButton
              :disabled="globalLoading"
              @click="showProductionSummaryTable = !showProductionSummaryTable"
            >
              <Fa
                v-if="!showProductionSummaryTable"
                name="icon"
                icon="calendar-day"
                class="icon faicon"
                style="width: 15px; height: 15px"
              />
              <Fa
                v-else
                name="icon"
                icon="th-list"
                class="icon faicon"
                style="width: 15px; height: 15px"
              />
            </AButton>
          </div>
          <div>
            <AButton :disabled="globalLoading" @click="showAsideFilters = !showAsideFilters">
              <div v-if="selectedAnyFilters" class="budge budge__filters" />
              <div v-if="showDisplayCustomizationBudge" class="budge budge__permanent" />
              &nbsp;
              <Fa name="icon" icon="filter" class="icon faicon" style="width: 15px; height: 15px" />
              &nbsp;
            </AButton>
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <div>
        <div
          v-if="!bootstrapLoading && productionTableOrders"
          class="content-wrapper production-table-page-content-wrapper"
        >
          <keep-alive>
            <component
              :is="getComponent"
              :production-table-orders="
                showProductionSummaryTable ? productionTableSummaryOrders : productionTableOrders
              "
              :summary-row="showProductionSummaryTable ? summaryRowForSummaryTable : summaryRow"
              :columns="showProductionSummaryTable ? summaryColumns : columns"
              :filters="filters"
              :display-customization="displayCustomization"
              :loading="globalLoading"
            />
          </keep-alive>
          <!-- <ProductionSummaryTableBlock
            v-else
            :production-table-orders="productionTableSummaryOrders"
            :summary-row="summaryRowForSummaryTable"
            :columns="summaryColumns"
            :filters="filters"
            :display-customization="displayCustomization"
            :loading="globalLoading"
          /> -->
          <ProductionTableAsideFilter
            v-model:display-customization="displayCustomization"
            v-model:filters="filters"
            v-model:deadline="filters.deadline"
            v-model:created-card-date="filters.createdCardDate"
            v-model:packing-date="filters.packingDate"
            v-model:tech-design-done-date="filters.techDesignDoneDate"
            v-model:tag="filters.tag"
            v-model:category="filters.category"
            v-model:orderState="filters.orderState"
            :order-tags="orderTags"
            :order-category="orderCategory"
            :show-aside-filters="showAsideFilters"
            :can-use-already-downloaded-orders="canUseAlreadyDownloadedOrders"
            :display-customization-dictionary="displayCustomizationDictionary"
            :loading="globalLoading"
            @close="showAsideFilters = false"
            @resetFilters="resetFiltersHandler"
            @searchOrders="searchOrdersHandler"
          />
        </div>
        <div v-if="bootstrapLoading" class="loaded-wrapper">
          <ALoader centered />
        </div>
      </div>
    </template>
  </APageLayout>
</template>

<script>
import accessMixin from '@/mixin/accessMixin.js';
import ProductionTableBlock from './components/ProductionTableBlock.vue';
import CurrentMonthStatBlock from './components/CurrentMonthStatBlock.vue';
import ProductionSummaryTableBlock from './components/ProductionSummaryTableBlock.vue';
import ProductionTableAsideFilter from './components/ProductionTableAsideFilter.vue';
import { ORDER_STATE, ORDER_STATE_DICTIONARY } from '@/constants';
import { INIT_FILTERS } from './productionTableDictionary';
import { prepareDictionary } from '@/utils/dictionaryUtils';
import { cloneDeep } from '@/utils/lodash.js';
import { getUserName } from '@/utils/string.js';
import { dayjs, formateDate } from '@/utils/date.js';

import { computed, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { isEqual } from '../../utils/lodash';
import { filterArrayByFields } from '../../utils/filters';
import { useProductionTableColumnController } from '@/use/productionTable/productionTableColumnController';
import { useDownloadXlProductionTable } from '@/use/productionTable/useDownloadXlProductionTable';
import { useOrderParamValue } from '@/use/order/useOrderParamValue';
import { ORDER_PARAM_TYPE } from '../../constants';

export default {
  name: 'ProductionTable',
  components: {
    ProductionTableBlock,
    CurrentMonthStatBlock,
    ProductionTableAsideFilter,
    ProductionSummaryTableBlock,
  },
  mixins: [accessMixin],
  setup() {
    const store = useStore();
    const loading = ref(false);
    const bootstrapLoading = ref(true);
    const globalLoading = computed(() => loading.value || bootstrapLoading.value);

    let orderCategoryDictionary;
    let orderTagDictionary;
    let managersDictionary;
    let employeesDictionary;
    let departmentsDictionary;

    async function bootstrap() {
      const getOrderCategories = store.dispatch('order/getOrderCategory');
      const getOrderTags = store.dispatch('order/getOrderTags');
      const getAllManagers = store.dispatch('user/getAllManagers');
      const getAllEmployees = store.dispatch('user/getAllEmployees');
      const getAllDepartments = store.dispatch('production/getAllDepartments');
      const getProductionTableOrdersData = store.dispatch(
        'productionTable/getProductionTableOrdersData',
        { filters }
      );
      const getProductionTableSummaryOrdersData = store.dispatch(
        'productionTable/getProductionTableSummaryOrdersData',
        { filters }
      );
      const getProductionTableSummaryMonthStat = store.dispatch(
        'productionTable/getProductionTableSummaryMonthStat'
      );
      const promises = [
        getOrderCategories,
        getOrderTags,
        getAllManagers,
        getAllEmployees,
        getAllDepartments,
        getProductionTableOrdersData,
        getProductionTableSummaryOrdersData,
        getProductionTableSummaryMonthStat,
      ];

      return Promise.all(promises).then(() => {
        orderCategoryDictionary = prepareDictionary(store.state.order.orderCategory, 'id');
        orderTagDictionary = prepareDictionary(store.state.order.orderTags, 'id');
        managersDictionary = prepareDictionary(store.state.user.managers, 'guid');
        employeesDictionary = prepareDictionary(store.state.user.employees, 'guid');
        departmentsDictionary = prepareDictionary(store.state.production.departments, 'id');
        bootstrapLoading.value = false;
      });
    }

    const {
      columns,
      summaryColumns,
      showDisplayCustomizationBudge,
      displayCustomization,
      displayCustomizationDictionary,
      selectedColumns,
    } = useProductionTableColumnController();

    const { getValueFromOrderParamByType } = useOrderParamValue();

    const getManagerNameById = ({ currentManagerId }) => {
      const manager = managersDictionary[currentManagerId];
      return getUserName(manager, null);
    };
    const getEmployeeNameById = ({ techDesignerId }) => {
      const employee = employeesDictionary[techDesignerId];
      return getUserName(employee, null);
    };
    const getOrderTagStyle = ({ tagId }) => {
      if (!tagId) {
        return;
      }
      return `#${orderTagDictionary[tagId].color}`;
    };
    const getOrderTagName = ({ tagId }) => {
      if (!tagId) {
        return '';
      }
      return orderTagDictionary[tagId].name;
    };
    const getOrderCategoryName = (id) => {
      if (!id) {
        return '';
      }
      return orderCategoryDictionary[id].name;
    };
    const getOrderStatus = (order) => {
      if (
        [
          ORDER_STATE.NEW,
          ORDER_STATE.DESIGN,
          ORDER_STATE.TECH_DESIGN,
          ORDER_STATE.COMPLETED,
        ].includes(order.state)
      ) {
        return ORDER_STATE_DICTIONARY[order.state];
      }
      return departmentsDictionary[order.minDepartment]?.name || '! ОШИБКА !';
    };
    const getOrderUrl = ({ guid }) =>
      `${
        (process.env.NODE_ENV === 'production'
          ? process.env.VUE_APP_API_TARGET
          : 'http://localhost:8080/') +
        'orders/' +
        guid
      }`;
    const getPackageDate = (orderParams) => {
      return getValueFromOrderParamByType({
        orderParamsType: ORDER_PARAM_TYPE.PACKING_DATE,
        orderParams: orderParams,
      });
    };
    const geOrderCategoryIds = (orderParams) => {
      return getValueFromOrderParamByType({
        orderParamsType: ORDER_PARAM_TYPE.CATEGORY,
        orderParams: orderParams,
      });
    };

    const appliedFilters = computed(() => store.state.productionTable.filters);
    const pTableOrders = computed(() => store.state.productionTable.orders);
    const productionTableSummaryOrders = computed(() =>
      store.state.productionTable.summaryTable.map((d) => ({
        ...d,
        deadline: d.deadline ? formateDate(d.deadline) : '-',
      }))
    );
    const currenctMonthStat = computed(() => store.state.productionTable.currenctMonthStat);
    const orderTags = computed(() => store.state.order.orderTags);
    const orderCategory = computed(() => store.state.order.orderCategory);

    const productionTableOrders = computed(() => {
      return (
        (!bootstrapLoading.value &&
          pTableOrders.value.map((o) => ({
            tagId: o.tagId,
            categoryIds: geOrderCategoryIds(o.orderParams),
            orderUrl: getOrderUrl(o),
            tagName: getOrderTagName(o),
            categoryNames: (geOrderCategoryIds(o.orderParams) || []).map(getOrderCategoryName),
            colorTag: getOrderTagStyle(o),
            guid: o.guid,
            name: o.name,
            amoId: o.amoId,
            cardsQuantity: o.cardsQuantity,
            allQuantity: o.allQuantity,
            noComplexityQuantity: o.noComplexityQuantity,
            easyQuantity: o.easyQuantity,
            mediumQuantity: o.mediumQuantity,
            hardQuantity: o.hardQuantity,
            veryHardQuantity: o.veryHardQuantity,
            currentManagerId: o.currentManagerId,
            managerName: getManagerNameById(o),
            timeToProduction: o.timeToProduction,
            deadline: formateDate(o.postponedDeadline || o.deadline),
            state: o.state,
            status: getOrderStatus(o),
            designer: o.designer,
            designerName: getEmployeeNameById(o),
            techDesignerId: o.techDesignerId,
            createdCardDate: formateDate(o.createdCardDate),
            techDesignDoneDate: formateDate(o.techDesignDoneDate),
            packageDate: formateDate(getPackageDate(o.orderParams)),

            isDeadlineExpired:
              o.postponedDeadline || dayjs(o.deadline).isSameOrBefore(new Date(), 'day'),
            isPackageDateExpired: dayjs(getPackageDate(o.orderParams)).isSameOrBefore(
              new Date(),
              'day'
            ),
          }))) ||
        []
      );
    });

    const defauleFilters = {
      ...cloneDeep(INIT_FILTERS),
      deadline: {
        ...cloneDeep(INIT_FILTERS.deadline),
        currentMonthWithDebt: true,
      },
    };
    const defauleFiltersWithExcludeCompliteOrders = {
      ...cloneDeep(INIT_FILTERS),
      deadline: {
        ...cloneDeep(INIT_FILTERS.deadline),
        currentMonthWithDebt: true,
      },
      orderState: {
        ...cloneDeep(INIT_FILTERS.orderState),
        exclude: [ORDER_STATE.COMPLETED],
      },
    };
    const filters = reactive(cloneDeep(defauleFiltersWithExcludeCompliteOrders));
    const resetFiltersHandler = () => {
      Object.assign(filters, cloneDeep(defauleFilters));
    };

    const showAsideFilters = ref(false);
    const showProductionSummaryTable = ref(false);

    const selectedAnyFilters = computed(() => !isEqual(defauleFilters, filters));
    const changedAppliedDeadline = computed(
      () => !isEqual(filters.deadline, cloneDeep(appliedFilters?.value?.deadline))
    );
    const canUseAlreadyDownloadedOrders = computed(
      () =>
        // true || // TODO DELETED
        (appliedFilters?.value?.deadline?.currentMonthWithDebt &&
          filters.deadline.currentMonthWithDebt) ||
        (!changedAppliedDeadline.value &&
          isEqual(
            {
              ...INIT_FILTERS,
              deadline: undefined,
              text: undefined,
            },
            {
              ...appliedFilters.value,
              deadline: undefined,
              text: undefined,
            }
          ))
    );

    const filtersOrders = computed(() => {
      let result = productionTableOrders.value;
      // simple filter
      if (filters.text) {
        result = filterArrayByFields(productionTableOrders.value, filters.text, ['name', 'guid']);
      }

      // return downloaded orders minus simple filter
      if (!canUseAlreadyDownloadedOrders.value) {
        if (filters.text) {
          return filterArrayByFields(productionTableOrders.value, filters.text, ['name', 'guid']);
        }
        return productionTableOrders.value;
      }

      // base filters
      if (filters.timeToProduction !== null) {
        result = result.filter((o) => o.timeToProduction <= filters.timeToProduction);
      }

      if (filters.currentManagerIds.length) {
        result = result.filter((o) => filters.currentManagerIds.includes(o.currentManagerId));
      }
      if (filters.techDesignerIds.length) {
        result = result.filter((o) => filters.techDesignerIds.includes(o.techDesignerId));
      }

      const createdCardDateFilter =
        filters.createdCardDate.dateRange || filters.createdCardDate.monthRange;
      if (createdCardDateFilter) {
        result = result.filter(
          (o) =>
            dayjs(o.createdCardDate).isSameOrAfter(createdCardDateFilter[0], 'day') &&
            dayjs(o.createdCardDate).isSameOrBefore(createdCardDateFilter[1], 'day')
        );
      }

      const packingDateDateFilter = filters.packingDate.dateRange || filters.packingDate.monthRange;
      if (packingDateDateFilter) {
        result = result.filter(
          (o) =>
            dayjs(o.packingDate).isSameOrAfter(packingDateDateFilter[0], 'day') &&
            dayjs(o.packingDate).isSameOrBefore(packingDateDateFilter[1], 'day')
        );
      }

      const techDesignDoneDateFilter =
        filters.techDesignDoneDate.dateRange || filters.techDesignDoneDate.monthRange;
      if (techDesignDoneDateFilter) {
        result = result.filter(
          (o) =>
            dayjs(o.techDesignDoneDate).isSameOrAfter(techDesignDoneDateFilter[0], 'day') &&
            dayjs(o.techDesignDoneDate).isSameOrBefore(techDesignDoneDateFilter[1], 'day')
        );
      }
      if (filters.category.include.length) {
        result = result.filter((o) =>
          o.categoryIds.some((oCatId) => filters.category.include.includes(oCatId))
        );
      }
      if (filters.category.exclude.length) {
        result = result.filter(
          (o) => !o.categoryIds.some((oCatId) => filters.category.exclude.includes(oCatId))
        );
      }
      if (filters.tag.include.length) {
        result = result.filter((o) => filters.tag.include.includes(o.tagId));
      }
      if (filters.tag.exclude.length) {
        result = result.filter((o) => !filters.tag.exclude.includes(o.tagId));
      }

      if (filters.orderState.include.length) {
        result = result.filter((o) => filters.orderState.include.includes(o.state));
      }
      if (filters.orderState.exclude.length) {
        result = result.filter((o) => !filters.orderState.exclude.includes(o.state));
      }

      return result;
    });
    const summaryRow = computed(
      () =>
        !bootstrapLoading.value &&
        filtersOrders.value.reduce(
          (acc, curr) => {
            acc.count++;
            acc.allQuantity += curr.allQuantity || 0;
            acc.noComplexityQuantity += curr.noComplexityQuantity || 0;
            acc.easyQuantity += curr.easyQuantity || 0;
            acc.mediumQuantity += curr.mediumQuantity || 0;
            acc.hardQuantity += curr.hardQuantity || 0;
            acc.veryHardQuantity += curr.veryHardQuantity || 0;
            return acc;
          },
          {
            guid: '_',
            count: 0,
            allQuantity: 0,
            noComplexityQuantity: 0,
            easyQuantity: 0,
            mediumQuantity: 0,
            hardQuantity: 0,
            veryHardQuantity: 0,
          }
        )
    );

    const summaryRowForSummaryTable = computed(
      () =>
        !bootstrapLoading.value &&
        productionTableSummaryOrders.value.reduce(
          (acc, curr) => {
            acc.count++;
            acc.allQuantityPlan += curr.allQuantityPlan || 0;
            acc.noComplexityQuantityPlan += curr.noComplexityQuantityPlan || 0;
            acc.easyQuantityPlan += curr.easyQuantityPlan || 0;
            acc.mediumQuantityPlan += curr.mediumQuantityPlan || 0;
            acc.hardQuantityPlan += curr.hardQuantityPlan || 0;
            acc.veryHardQuantityPlan += curr.veryHardQuantityPlan || 0;

            acc.allQuantityDebt += curr.allQuantityDebt || 0;
            acc.noComplexityQuantityDebt += curr.noComplexityQuantityDebt || 0;
            acc.easyQuantityDebt += curr.easyQuantityDebt || 0;
            acc.mediumQuantityDebt += curr.mediumQuantityDebt || 0;
            acc.hardQuantityDebt += curr.hardQuantityDebt || 0;
            acc.veryHardQuantityDebt += curr.veryHardQuantityDebt || 0;

            acc.allQuantityCompleted += curr.allQuantityCompleted || 0;
            return acc;
          },
          {
            guid: '__',
            count: 0,
            allQuantityPlan: 0,
            noComplexityQuantityPlan: 0,
            easyQuantityPlan: 0,
            mediumQuantityPlan: 0,
            hardQuantityPlan: 0,
            veryHardQuantityPlan: 0,

            allQuantityDebt: 0,
            noComplexityQuantityDebt: 0,
            easyQuantityDebt: 0,
            mediumQuantityDebt: 0,
            hardQuantityDebt: 0,
            veryHardQuantityDebt: 0,

            allQuantityCompleted: 0,
          }
        )
    );

    const searchOrdersHandler = async () => {
      try {
        loading.value = true;
        const promises = [
          store.dispatch('productionTable/getProductionTableOrdersData', { filters }),
        ];
        if (changedAppliedDeadline.value) {
          promises.push(
            store.dispatch('productionTable/getProductionTableSummaryOrdersData', { filters })
          );
        }
        await Promise.all(promises);
      } catch (error) {
        console.log(error);
      } finally {
        loading.value = false;
      }
    };

    const getComponent = computed(() =>
      showProductionSummaryTable.value ? ProductionSummaryTableBlock : ProductionTableBlock
    );

    const { downloadXlTable } = useDownloadXlProductionTable(columns, filtersOrders);

    bootstrap();
    return {
      canUseAlreadyDownloadedOrders,
      changedAppliedDeadline,

      globalLoading,

      orderTags,
      orderCategory,

      filters,
      selectedAnyFilters,
      showAsideFilters,

      showProductionSummaryTable,

      productionTableOrders: filtersOrders,
      summaryRow,
      columns,

      summaryColumns,
      productionTableSummaryOrders,
      summaryRowForSummaryTable,

      selectedColumns,

      displayCustomization,
      displayCustomizationDictionary,
      showDisplayCustomizationBudge,

      currenctMonthStat,

      bootstrapLoading,
      resetFiltersHandler,
      searchOrdersHandler,

      downloadXlTable,

      getComponent,
    };
  },
};
</script>
<style lang="scss" scoped>
.loaded-wrapper {
  min-width: 100%;
}
.page-header {
  @include InterBold;
  font-size: 18px;
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 36px;

  &__container {
    width: 100%;
    // display: flex;
    // flex-direction: row;
    // justify-content: space-between;
    display: grid;
    grid-template-columns: 290px 1fr 50px 100px 100px;

    & > div {
      display: flex;
      align-items: center;
    }
  }
}

.budge {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  &__filters {
    right: 3px;
    top: 3px;
    background-color: $color-red;
  }
  &__permanent {
    bottom: 3px;
    left: 3px;
    background-color: rgb(227, 231, 41);
  }
}

.aggregate-row-item {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  padding: 0px 10px;
  &:not(:last-of-type) {
    border-right: 1px solid $color-bg-smoke;
  }
}
.col-centred {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
