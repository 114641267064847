<template>
  <GDialog
    v-model="showModal"
    :persistent="persistent"
    content-class="a-modal-wrapper order-card-controller-modal"
  >
    <div class="modal__content">
      <div class="header">
        <div class="modal-title">
          {{ order.name }}
        </div>
        <div class="actions">
          <AButton small :disabled="loading" @click="showModal = false"> Закрыть </AButton>
        </div>
      </div>

      <div v-if="!loading" class="table">
        <div class="cards">
          <div
            v-for="(cards, productId) of formattedCards.groupCards"
            :key="productId"
            class="card-row mr-5 ml-5"
          >
            <div v-for="card of cards" :key="card.guid" class="cards-column mt-5 mb-5">
              <div :class="{ changed: card.productionLineId }" class="card mt-10 mb-10">
                <div class="item a-row">
                  <div class="a-row">
                    Партия&nbsp;
                    <div class="a-bold">{{ card.index }}</div>
                    .
                  </div>
                  &nbsp;&nbsp;
                  <div>Изделие {{ card.itemId }}</div>
                </div>
                <div class="production-line a-row">
                  <div class="name">Пр. лин.</div>
                  <div v-if="getProdLineOptionsByItemId(card.itemId).length" class="select">
                    <ASelect
                      :model-value="getCardProductionLine(card)"
                      :options="getProdLineOptionsByItemId(card.itemId)"
                      select-label="name"
                      disable-deleted
                      block-unselect
                      :disabled="card.generalState === CARD_GENERAL_STATE.DONE"
                      @change="changeCardProductionLine(card, $event)"
                    />
                  </div>
                  <div v-else>
                    <ASelect
                      :model-value="getBaseProductionLine.id"
                      :options="[getBaseProductionLine]"
                      select-label="name"
                      disable-deleted
                      read-only
                      @change="changeCardProductionLine(card, $event)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="loader">
        <ALoader centered green />
      </div>
      <div v-if="!loading" class="save">
        <AButton success @click="saveHandler"> Сохранить </AButton>
      </div>
    </div>
  </GDialog>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { GDialog } from 'gitart-vue-dialog';
import { cloneDeep } from '@/utils/lodash';
import { CARD_GENERAL_STATE, DEFAULT_PRODUCTION_LINE_ID } from '../../../constants';

export default {
  name: 'OrderCardControllerModal',
  components: { GDialog },
  props: {
    modelValue: {
      type: Boolean,
      default: null,
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: true,
    },
    onConfirm: {
      type: Function,
      default: null,
    },
    order: {
      type: Object,
      required: true,
    },
    departmentsDictionary: {
      type: Object,
      required: true,
    },
    productionLinesIdsByItemId: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      loading: false,
      localOrder: cloneDeep(this.order),
    };
  },
  computed: {
    ...mapState({
      productionLines: (s) => s.production.productionLines,
    }),
    getBaseProductionLine() {
      return this.productionLines.find((p) => p.id === DEFAULT_PRODUCTION_LINE_ID);
    },
    showModal: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
        if (!value && this.onClose) {
          return this.onClose(this);
        }
      },
    },
    formattedCards() {
      return this.localOrder.cards.reduce(
        (acc, curr) => {
          if (!acc.groupCards[curr.productId]) {
            acc.groupCards[curr.productId] = [];
          }
          acc.groupCards[curr.productId].push(curr);

          if (!acc.itemIds.includes(curr.itemId)) {
            acc.itemIds.push(curr.itemId);
          }

          return acc;
        },
        {
          groupCards: {},
          itemIds: [],
        }
      );
    },
  },
  created() {
    this.CARD_GENERAL_STATE = CARD_GENERAL_STATE;
  },
  methods: {
    ...mapActions({
      saveOrderCardsProductionLineIds: 'production/saveOrderCardsProductionLineIds',
    }),
    getCardProductionLine(card) {
      return card.productionLineId || this.productionLinesIdsByItemId[card.itemId][0];
    },
    changeCardProductionLine(card, productionLineId) {
      if (!productionLineId) {
        card.productionLineId = null;
        return;
      }
      if (productionLineId === this.productionLinesIdsByItemId[card.itemId][0]) {
        card.productionLineId = null;
        return;
      }
      card.productionLineId = productionLineId;
    },
    getProdLineOptionsByItemId(itemId) {
      const productionLinesForItem = this.productionLinesIdsByItemId[itemId];
      return [...this.productionLines.filter((p) => productionLinesForItem.includes(p.id))].sort(
        (a, b) => productionLinesForItem.indexOf(a.id) - productionLinesForItem.indexOf(b.id)
      );
    },
    async saveHandler() {
      try {
        this.loading = true;
        await this.saveOrderCardsProductionLineIds({
          orderId: this.order.guid,
          cards: this.localOrder.cards,
        });

        this.$notify({ title: 'Сохранено' });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.loader {
  min-width: 500px;
}
.modal-title {
  display: flex;
  flex-direction: row;
  @include InterSemibold;
  font-size: 18px;
  margin: 20px 0px 20px 0px;
  align-items: center;
  justify-content: center;
}
.modal__content {
  padding: 20px;

  position: relative;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.save {
  display: flex;
  justify-content: center;
}
.cards {
  display: flex;
  flex-direction: row;

  .cards-column {
    display: flex;
    flex-direction: column;
  }

  .card {
    &.changed {
      background-color: $color-info;
    }

    width: 200px;
    border: 1px solid $color-bg-smoke;
    border-radius: 6px;
    padding: 4px;
    .production-line {
      display: flex;
      flex-direction: row;

      align-items: center;
      .select {
        margin-left: 10px;
      }
    }
  }
}
</style>
