<template>
  <Assortment />
</template>

<script>
import Assortment from '@/components/assortment';
export default {
  name: 'AssortmentPage',
  components: { Assortment },
};
</script>
<style lang="scss"></style>
