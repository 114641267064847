<template>
  <GDialog
    v-model="showModal"
    :persistent="persistent"
    content-class="a-modal-wrapper aside-change-production-line-modal"
    max-width="1000"
  >
    <div class="modal__content" :style="cssVars">
      <CardBlock :card="fullProductionCard" :order="order" class="card" />
    </div>
  </GDialog>
</template>
<script>
import { GDialog } from 'gitart-vue-dialog';
import CardBlock from '../../cards/components/cardBlock.vue';

export default {
  name: 'MonitoringProductionCardModal',
  components: { GDialog, CardBlock },
  props: {
    modelValue: {
      type: Boolean,
      default: null,
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: true,
    },
    onConfirm: {
      type: Function,
      default: null,
    },
    fullProductionCard: {
      type: Object,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    showModal: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
        if (!value && this.onClose) {
          return this.onClose(this);
        }
      },
    },
    cssVars() {
      const cardHeight = 1594;
      const windowHeight = window.innerHeight - 100;
      let coeff = 0.1;
      if (cardHeight > windowHeight) {
        coeff = Number(((cardHeight - windowHeight) / cardHeight).toFixed(2));
      }
      return {
        '--scalec': 1 - coeff,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
$scaleCard: var(--scalec);
$cardHeight: 1594px;
$cardWidth: 1132px;
.modal {
  padding: 0px;
  margin: 0px;
  &__content {
    padding: 0px;
    margin: 0px;
  }
}

.card {
  transform: scale($scaleCard);
  margin-right: calc((($cardWidth - 50px) * ($scaleCard - 1)));
  transform-origin: top left;
}
</style>
