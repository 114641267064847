<template>
  <div v-if="designSolutionLoading" class="loader-wrapper">
    <ALoader centred />
  </div>
  <div v-else-if="designSolution" class="wrapper">
    <div class="solution">
      <div class="solution__title mb-10">
        <div>
          {{ designSolution.order.name }}
        </div>
        <div>
          <AButton
            v-if="isManager || isMainManager"
            small
            danger
            @click="openDeleteDublicateModalHandler"
          >
            Удалить
          </AButton>
        </div>
      </div>
      <div class="solution__product-info">
        <div class="solution__product">
          <ASpacer text="Изделие" class="mb-15" />
          Изделие {{ designSolution.product.itemId }}.
          <span v-if="designSolution.product.color">
            Цвет - {{ designSolution.product.color }}</span
          >
          <p>{{ designSolution.product.comment }}</p>
        </div>

        <div class="solution__article">
          <ASpacer text="Артикул" class="mb-15" />
          Id {{ designSolution.product.article.id }}.
          <span> {{ designSolution.product.article.name }} </span>
          <p>Code: {{ designSolution.product.article.code }}</p>
        </div>
      </div>
      <div>
        <DesignSolutionSpecification
          v-model:specification="designSolution.specification"
          v-model:files="designSolution.files"
          v-model:start-upload="startUpload"
          :design-solution-id="designSolution.guid"
          :loading="globalLoading"
        />
      </div>
      <div class="a-row mb-20 mt-10">
        <AButton v-if="isManager || isMainManager" class="mr-10" @click="prepareSaveHandler">
          Сохранить
        </AButton>
        <AButton
          v-if="
            (isManager || isMainManager) &&
            designSolution.state == DESIGN_SOLUTION_STATUS.QUEUE &&
            !isChangedDesignSolution
          "
          @click="changeDesignSolutionStateHandler"
        >
          Подтвердить описание ( отправить в работу дизайнерам)
        </AButton>
      </div>
    </div>
    <div class="chat">
      <ChatComponent :loading="globalLoading" />
    </div>
  </div>
  <div v-else class="empty-block">
    <div>Дизайнерское решение не найдено или завершено</div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import DesignSolutionSpecification from './components/DesignSolutionSpecification.vue';
import DeleteDublicateModal from './components/DeleteDublicateModal.vue';
import ChatComponent from './components/ChatComponent.vue';
import accessMixin from '@/mixin/accessMixin.js';
import { SOCKET_ROOM } from '../../constants/soket.js';
import { DESIGN_SOLUTION_STATUS } from '../../constants';
import { isEqual } from '../../utils/lodash';

const MOCKUP_FORMATS = ['png', 'jpg', 'jpeg'];

export default {
  name: 'DesignSolutionPage',
  components: {
    DesignSolutionSpecification,
    ChatComponent,
  },
  mixins: [accessMixin],
  emits: ['close'],
  data() {
    return {
      guidDesignSol: null,

      startUpload: false,
      designSolutionLoading: false,
      createMessageLoading: false,
    };
  },
  computed: {
    ...mapState({
      designSolution: (s) => s.design.selectedDesignSolution || null,
      selectedDesignSolutionCache: (s) => s.design.selectedDesignSolutionCache || null,
    }),
    globalLoading() {
      return this.designSolutionLoading || this.createMessageLoading;
    },
    designSolutionId() {
      return this.$route.params.designSolutionId;
    },
    isChangedDesignSolution() {
      // TODO убрать если нужно будет для всех состояний
      if (this.designSolution.state == DESIGN_SOLUTION_STATUS.QUEUE) {
        return;
      }
      return !isEqual(this.designSolution, this.selectedDesignSolutionCache);
    },
  },
  watch: {
    '$route.params.designSolutionId': async function (newValue) {
      if (!newValue) return this.resetDesignSolutionPage();
      this.getDesignSolutionHandler;
    },
    'startUpload': async function (newValue) {
      if (!newValue) return;
      if (newValue === 'uploaded') {
        await this.saveHandler();
        this.startUpload = false;
      }
    },
  },
  async created() {
    this.DESIGN_SOLUTION_STATUS = DESIGN_SOLUTION_STATUS;
    this.MOCKUP_FORMATS = MOCKUP_FORMATS;
    await this.initcomponent();
  },
  beforeUnmount() {
    this.disconnectFromRoom({
      id: this.designSolution?.guid || this.designSolutionId || this.guidDesignSol,
      type: SOCKET_ROOM.DESIGN_SOLUTION_MESSAGES,
    });
    this.resetDesignSolutionPage();
  },
  methods: {
    ...mapActions({
      getDesignSolution: 'design/getDesignSolution',
      getDesignMessages: 'design/getDesignMessages',
      deleteDesignSolution: 'design/deleteDesignSolution',
      createDesignMessage: 'design/createDesignMessage',
      updateDesignSolution: 'design/updateDesignSolution',
      changeStateAndDesignerInDesignSolution: 'design/changeStateAndDesignerInDesignSolution',
      connetctToRoom: 'socket/connetctToRoom',
      disconnectFromRoom: 'socket/disconnectFromRoom',
    }),
    ...mapMutations({
      resetDesignSolutionPage: 'design/RESET_DESIGN_SOLUTION_PAGE',
    }),
    async initcomponent() {
      await Promise.all([
        this.getDesignSolutionHandler(),
        this.connetctToRoom({
          id: this.designSolutionId,
          type: SOCKET_ROOM.DESIGN_SOLUTION_MESSAGES,
        }),
      ]);
    },
    async changeDesignSolutionStateHandler() {
      console.log(this.designSolution);
      await this.changeStateAndDesignerInDesignSolution({
        designSolutionId: this.designSolution.guid,
        newState: DESIGN_SOLUTION_STATUS.NEW,
      });
    },
    async getDesignSolutionHandler() {
      this.designSolutionLoading = true;
      try {
        await this.getDesignSolution({ designSolutionId: this.designSolutionId });
        this.guidDesignSol = this.designSolutionId;
      } catch (err) {
        console.log(err);
      } finally {
        this.designSolutionLoading = false;
      }
    },
    async prepareSaveHandler() {
      this.startUpload = true;
    },
    async saveHandler() {
      this.designSolutionLoading = true;
      try {
        await this.updateDesignSolution({ designSolution: this.designSolution });
      } catch (err) {
        console.log(err);
      } finally {
        this.designSolutionLoading = false;
      }
    },
    async deletedDublicateHandler(originalDesignSolutionId) {
      this.designSolutionLoading = true;
      try {
        await this.deleteDesignSolution({
          designSolutionId: this.designSolution.guid,
          originalDesignSolutionId,
        });
        this.$notify({ title: 'Дубликат удален' });
        this.$router.push({ path: '/design' });
      } catch (err) {
        console.log(err);
      } finally {
        this.designSolutionLoading = false;
      }
    },
    openDeleteDublicateModalHandler() {
      this.$dialog.addDialog({
        component: DeleteDublicateModal,
        props: {
          designSolution: this.designSolution,
          onConfirm: (confirm) => {
            this.deletedDublicateHandler(confirm.originalDesignSolution.guid);
            confirm.showModal = false;
          },
        },
      });
    },
    closeHandler() {
      this.$emit('close');
      return;
    },
  },
};
</script>
<style lang="scss" scoped>
.loader-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}
.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  @include scroll-wider;
  overflow-y: auto;
}
.solution {
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding-right: 10px;

  @include to(1700px) {
    width: 100%;
    // height: 900px;
    border-right: none;
    border-bottom: 1px solid $color-light-gray;
  }
  border-right: 1px solid $color-light-gray;
  flex-grow: 0.1;
  &__title {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    @include InterBold;
    font-size: 18px;
  }
  &__product-info {
    display: flex;
    flex-direction: row;
    > div {
      width: 50%;
    }
  }
}
.chat {
  display: flex;
  flex-direction: column;
  @include to(1700px) {
    width: 100%;
  }
  min-width: 900px;
  flex-grow: 1;
}

.empty-block {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  div {
    padding: 20px 10px;
    background-color: $color-bg-light-blue-active;
    font-size: 18px;
    margin-top: 50px;
    border-radius: 6px;
  }
}
</style>
