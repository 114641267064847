<template>
  <GDialog v-model="showModal" content-class="a-modal-wrapper" max-width="700">
    <div class="modal__content">
      <div class="modal__title">Невозможно удалить производственную линию</div>

      <div class="modal__block">
        <div>
          <div class="modal__subtitle">
            <p>Карты с измененными производственными линиями:</p>
          </div>
          <div class="modal__block-items">
            <div v-for="order of cards" :key="order.guid" class="modal__block-item">
              <div class="deleted">
                {{ order.name }}
              </div>

              <div class="cards">
                <div class="a-row">№№&nbsp;Партий&nbsp;:</div>
                <div v-for="card of order.cards" :key="card.guid" class="card-index">
                  &nbsp; {{ card.index }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="modal__subtitle">
            <p>Изделия, у которых данная пр. линия является основной:</p>
          </div>
          <div class="modal__block-items">
            <div v-for="item of items" :key="item.id" class="modal__block-item">
              <div class="deleted">id {{ item.id }}. {{ item.name }}</div>
            </div>
          </div>
        </div>

        <div class="modal__save-btn">
          <AButton small @click="confirmItemHandler"> Отмена </AButton>
        </div>
      </div>
    </div>
  </GDialog>
</template>
<script>
import { GDialog } from 'gitart-vue-dialog';

export default {
  name: 'DeleteProductionLineConflictModal',
  components: { GDialog },
  props: {
    modelValue: {
      type: Boolean,
      default: null,
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: true,
    },
    onConfirm: {
      type: Function,
      default: null,
    },
    onClose: {
      type: Function,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    cards: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      resolveChangedDepartments: {},
    };
  },
  computed: {
    showModal: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
        if (!value && this.onClose) {
          return this.onClose(this);
        }
      },
    },
  },
  created() {},
  methods: {
    confirmItemHandler() {
      return this.onConfirm(this);
    },
  },
};
</script>
<style lang="scss" scoped>
.modal {
  margin-bottom: 999px;
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px 30px;
  }
  &__title {
    @include InterSemibold;
    font-size: 16px;
    padding: 5px 15px 20px;
  }
  &__subtitle {
    @include InterSemibold;
    font-size: 14px;
    padding: 5px 15px 20px;
  }
  &__text {
    margin-top: 5px;
  }
  &__save-btn {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }
}

.a-modal-wrapper {
  width: fit-content;
}

// изделия
.modal__block {
  &-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &-item {
    width: 450px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 10px;
    div {
      width: 100%;
    }

    .cards {
      display: flex;
      flex-direction: row;
      div {
        width: fit-content;
      }

      .card-index {
        display: flex;
        flex-direction: row;
      }
    }
  }
}
</style>
