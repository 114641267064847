import BaseService from './services/BaseService.js';

class authApi extends BaseService {
  async refresh({ refreshToken }) {
    const data = await this.api.post('/auth/refresh', { refreshToken });
    return data;
  }
  async logout({ refreshToken }) {
    const data = await this.api.post('/auth/logout', { refreshToken });
    return data;
  }
}

export default new authApi();
