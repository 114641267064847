import { USER_TYPES } from '../constants/index.js';
export default {
  computed: {
    selfUser() {
      return this.$store.state.user.currentUser;
    },
    accessUserId() {
      return this.selfUser.guid;
    },
    isEmployee() {
      return this.selfUser.type != USER_TYPES.CUSTOMER;
    },
    isDesigner() {
      return this.selfUser.type === USER_TYPES.DESIGN;
    },
    isMainDesigner() {
      return this.selfUser.type === USER_TYPES.MAIN_DESIGNER;
    },
    isManager() {
      return this.selfUser.type === USER_TYPES.MANAGER;
    },
    isMainManager() {
      return this.selfUser.type === USER_TYPES.MAIN_MANAGER;
    },
    isMainEmployee() {
      return this.selfUser.type === USER_TYPES.MAIN_EMPLOYEE;
    },
    isAdmin() {
      return this.selfUser.type === USER_TYPES.ADMIN;
    },

    isCanChangeOrder() {
      return [USER_TYPES.MANAGER, USER_TYPES.MAIN_MANAGER].includes(this.selfUser.type);
    },
    isCanChangeProduction() {
      return [USER_TYPES.ADMIN, USER_TYPES.MAIN_EMPLOYEE].includes(this.selfUser.type);
    },
    isCanChangeAssortment() {
      return [USER_TYPES.ADMIN, USER_TYPES.MAIN_EMPLOYEE, USER_TYPES.MAIN_MANAGER].includes(
        this.selfUser.type
      );
    },
  },
};
