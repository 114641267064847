<template>
  <APageLayout>
    <template #header>
      <div class="page-header">
        <div class="page-header__container">
          <div>Ассортимент</div>
          <div class="mr-20">
            <AToggle
              v-model="showDeletedEntity"
              label="Показать удаленные сущности"
              class="toggle"
            />
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <div
        v-if="assortmentDictionaryDownload && !loading"
        class="content-wrapper assortment-page-content-wrapper"
      >
        <APageBlock class="mb-20" toggle>
          <template #header> Изделия </template>
          <div v-if="assortmentDictionaryDownload" class="content">
            <div class="table-filter">
              <AInput v-model.trim="tableFilters.items" placeholder="Фильтр по Id и названию" />
            </div>
            <ATable
              :items="itemPagination.chunkedItems"
              :fields="itemFields"
              class="assortment-item-table mb-20"
              :loading="!assortmentDictionaryDownload"
              :row-classes="itemTableRowCLasses"
              :row-click="(row) => (selectedItem = row)"
            >
              <template #itemCode="{ item, field }"> {{ item[field.key] }} </template>
              <template #articleCode="{ item }"> {{ item.article.code }} </template>
              <template #article="{ item }"> {{ item.article.name }} </template>
              <template #category="{ item }">
                {{ assortmentDictionary.categories[item.categoryId].name }}
              </template>
              <template #productType="{ item }">
                {{ assortmentDictionary.productTypes[item.productTypeId].name }}
              </template>
              <template #growType="{ item }">
                {{ assortmentDictionary.growTypes[item.growTypeId].name }}
              </template>
              <template #actions="{ item }">
                <AButton small @click.stop="itemProductionSetting = item">
                  <Fa
                    name="icon"
                    icon="cog"
                    class="icon faicon"
                    style="width: 15px; height: 15px"
                  />
                </AButton>
              </template>
            </ATable>
            <APagination
              v-model:currentPage="itemPagination.currentPage"
              v-model:currentOffset="itemPagination.offset"
              :items="filtredItems"
              :all="filtredItems.length"
              :loading="loading"
              :offsets="[10, 20, 50, 0]"
              mode="local"
              @changeOffset="itemPagination.offset = $event"
              @update:chunkedItems="itemPagination.chunkedItems = $event"
            />
            <div>
              <AButton v-if="isCanChangeAssortment" class="mb-10 mt-10" @click="creatingItem = true"
                >Создать изделие
              </AButton>
            </div>
          </div>
        </APageBlock>

        <APageBlock class="mb-20" toggle>
          <template #header> Комплекты </template>
          <div v-if="assortmentDictionaryDownload" class="content">
            <div class="table-filter">
              <AInput v-model.trim="tableFilters.sets" placeholder="Фильтр по Id и названию" />
            </div>
            <ATable
              :items="setPagination.chunkedItems"
              :fields="setFields"
              class="assortment-sets-table mb-20"
              :loading="!assortmentDictionaryDownload"
              :row-classes="setTableRowCLasses"
              :row-click="(row) => (selectedSet = row)"
            >
              <template #fabrics="{ item }">
                <ABadgeList :items="getSetsFibrics(item.itemSets)" />
              </template>

              <template #composition="{ item }">
                <ABadgeList :items="getSetsItems(item.itemSets)" />
              </template>
            </ATable>
            <APagination
              v-model:currentPage="setPagination.currentPage"
              v-model:currentOffset="setPagination.offset"
              :items="filtredSets"
              :all="filtredSets.length"
              :loading="loading"
              :offsets="[10, 20, 50, 0]"
              mode="local"
              @changeOffset="setPagination.offset = $event"
              @update:chunkedItems="setPagination.chunkedItems = $event"
            />
            <div>
              <AButton v-if="isCanChangeAssortment" class="mb-10 mt-10" @click="creatingSet = true"
                >Создать комплект
              </AButton>
            </div>
          </div>
        </APageBlock>
        <APageBlock class="mb-20" toggle>
          <template #header> Категории </template>
          <div class="content">
            <div class="table-filter">
              <AInput v-model.trim="tableFilters.categories" placeholder="Фильтр" />
            </div>
            <ATable
              :items="categoryPagination.chunkedItems"
              :fields="categoryFields"
              class="assortment-base-entity-table mb-20"
              :loading="loading"
              :row-classes="(item) => baseTableRowClasses(item, 'category')"
              :row-click="(row) => selectBaseEntity(row, 'category')"
            />
            <APagination
              v-model:currentPage="categoryPagination.currentPage"
              v-model:currentOffset="categoryPagination.offset"
              :items="filtredCategories"
              :all="filtredCategories.length"
              :loading="loading"
              :offsets="[10, 20, 50, 0]"
              mode="local"
              @changeOffset="categoryPagination.offset = $event"
              @update:chunkedItems="categoryPagination.chunkedItems = $event"
            />
            <div>
              <AButton
                v-if="isCanChangeAssortment"
                class="mb-10 mt-10"
                @click="selectBaseEntity(null, 'category', true)"
                >Создать категорию
              </AButton>
            </div>
          </div>
        </APageBlock>
        <APageBlock class="mb-20" toggle>
          <template #header> Материал(ткань) </template>
          <div class="content">
            <div class="table-filter">
              <AInput v-model.trim="tableFilters.fabrics" placeholder="Фильтр" />
            </div>
            <ATable
              :items="fabricPagination.chunkedItems"
              :fields="fabricFields"
              class="assortment-base-entity-table mb-20"
              :loading="loading"
              :row-classes="(item) => baseTableRowClasses(item, 'fabric')"
              :row-click="(row) => selectBaseEntity(row, 'fabric')"
            />
            <APagination
              v-model:currentPage="fabricPagination.currentPage"
              v-model:currentOffset="fabricPagination.offset"
              :items="filtredFabrics"
              :all="filtredFabrics.length"
              :loading="loading"
              :offsets="[10, 20, 50, 0]"
              mode="local"
              @changeOffset="fabricPagination.offset = $event"
              @update:chunkedItems="fabricPagination.chunkedItems = $event"
            />
            <div>
              <AButton
                v-if="isCanChangeAssortment"
                class="mb-10 mt-10"
                @click="selectBaseEntity(null, 'fabric', true)"
                >Создать материал
              </AButton>
            </div>
          </div>
        </APageBlock>
        <APageBlock class="mb-20" toggle>
          <template #header> Тип размера </template>
          <div class="content">
            <div class="table-filter">
              <AInput v-model.trim="tableFilters.growTypes" placeholder="Фильтр" />
            </div>
            <ATable
              :items="growTypePagination.chunkedItems"
              :fields="growTypeFields"
              class="assortment-base-entity-table mb-20"
              :loading="loading"
              :row-classes="(item) => baseTableRowClasses(item, 'growType')"
              :row-click="(row) => selectBaseEntity(row, 'growType')"
            />
            <APagination
              v-model:currentPage="growTypePagination.currentPage"
              v-model:currentOffset="growTypePagination.offset"
              :items="filtredGrowTypes"
              :all="filtredGrowTypes.length"
              :loading="loading"
              :offsets="[10, 20, 50, 0]"
              mode="local"
              @changeOffset="growTypePagination.offset = $event"
              @update:chunkedItems="growTypePagination.chunkedItems = $event"
            />
            <div>
              <AButton
                v-if="isCanChangeAssortment"
                class="mb-10 mt-10"
                @click="selectBaseEntity(null, 'growType', true)"
              >
                Создать тип размера
              </AButton>
            </div>
          </div>
        </APageBlock>
        <APageBlock class="mb-20" toggle>
          <template #header> Тип изделия </template>
          <div class="content">
            <div class="table-filter">
              <AInput v-model.trim="tableFilters.productTypes" placeholder="Фильтр" />
            </div>
            <ATable
              :items="productTypePagination.chunkedItems"
              :fields="productTypeFields"
              class="assortment-base-entity-table mb-20"
              :loading="loading"
              :row-classes="(item) => baseTableRowClasses(item, 'productType')"
              :row-click="(row) => selectBaseEntity(row, 'productType')"
            />
            <APagination
              v-model:currentPage="productTypePagination.currentPage"
              v-model:currentOffset="productTypePagination.offset"
              :items="filtredProductTypes"
              :all="filtredProductTypes.length"
              :loading="loading"
              :offsets="[10, 20, 50, 0]"
              mode="local"
              @changeOffset="productTypePagination.offset = $event"
              @update:chunkedItems="productTypePagination.chunkedItems = $event"
            />
            <div>
              <AButton
                v-if="isCanChangeAssortment"
                class="mb-10 mt-10"
                @click="selectBaseEntity(null, 'productType', true)"
                >Создать тип изделия
              </AButton>
            </div>
          </div>
        </APageBlock>
        <APageBlock class="mb-20" toggle>
          <template #header> Пол </template>
          <div class="content">
            <div class="table-filter">
              <AInput v-model.trim="tableFilters.sexDictionary" placeholder="Фильтр" />
            </div>
            <ATable
              :items="sexPagination.chunkedItems"
              :fields="sexFields"
              class="assortment-base-entity-table mb-20"
              :loading="loading"
              :row-classes="(item) => baseTableRowClasses(item, 'sex')"
              :row-click="(row) => selectBaseEntity(row, 'sex')"
            />
            <APagination
              v-model:currentPage="sexPagination.currentPage"
              v-model:currentOffset="sexPagination.offset"
              :items="filtredSexDictionary"
              :all="filtredSexDictionary.length"
              :loading="loading"
              :offsets="[10, 20, 50, 0]"
              mode="local"
              @changeOffset="sexPagination.offset = $event"
              @update:chunkedItems="sexPagination.chunkedItems = $event"
            />
            <div>
              <AButton
                v-if="isCanChangeAssortment"
                class="mb-10 mt-10"
                @click="selectBaseEntity(null, 'sex', true)"
                >Создать пол
              </AButton>
            </div>
          </div>
        </APageBlock>
        <APageBlock class="mb-20" toggle>
          <template #header> Артикул </template>
          <div class="content">
            <div class="table-filter">
              <AInput v-model.trim="tableFilters.articles" placeholder="Фильтр" />
            </div>
            <ATable
              :items="articlePagination.chunkedItems"
              :fields="articleFields"
              class="assortment-base-entity-table mb-20"
              :loading="loading"
              :row-classes="(item) => baseTableRowClasses(item, 'article')"
              :row-click="(row) => selectBaseEntity(row, 'article')"
            >
              <template #[`mockup.plain`]="{ item }">
                <div v-if="item.mockup.plain" class="mockup-indicator" />
              </template>
            </ATable>
            <APagination
              v-model:currentPage="articlePagination.currentPage"
              v-model:currentOffset="articlePagination.offset"
              :items="filtredArticles"
              :all="filtredArticles.length"
              :loading="loading"
              :offsets="[10, 20, 50, 0]"
              mode="local"
              @changeOffset="articlePagination.offset = $event"
              @update:chunkedItems="articlePagination.chunkedItems = $event"
            />
            <div>
              <AButton
                v-if="isCanChangeAssortment"
                class="mb-10 mt-10"
                @click="selectBaseEntity(null, 'article', true)"
                >Создать артикул
              </AButton>
            </div>
          </div>
        </APageBlock>
        <SetAsideBlock
          :selected-set="selectedSet"
          :creating-set="creatingSet"
          @close="closeSetAsideHandler"
        />
        <ItemAsideBlock
          :selected-item="selectedItem"
          :creating-item="creatingItem"
          :production-setting="itemProductionSetting"
          @close="closeItemAsideHandler"
        />
        <AssortmentDictionaryAsideBlock
          :selected-entity="baseEntity.selectedEntity"
          :creating-entity="baseEntity.creatingEntity"
          :type="baseEntity.type"
          @close="selectBaseEntity(null, null, false)"
        />
      </div>
      <div v-else>
        <ALoader centered />
      </div>
    </template>
  </APageLayout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { reactive } from 'vue';
import accessMixin from '@/mixin/accessMixin.js';
import { parseDictionary } from '../../utils/dictionaryUtils.js';
import { filterArrayByFields } from '../../utils/filters.js';
import SetAsideBlock from '@/components/assortment/setAsideBlock/index.vue';
import ItemAsideBlock from '@/components/assortment/itemAsideBlock/index.vue';
import AssortmentDictionaryAsideBlock from '@/components/assortment/assortmentDictionaryAsideBlock/index.vue';
import {
  setFields,
  itemFields,
  categoryFields,
  fabricFields,
  sexFields,
  growTypeFields,
  productTypeFields,
  articleFields,
  BASE_FIELD_DICTIONARY,
} from '../assortment/assortmentDictionary';

export default {
  name: 'Assortment',
  components: {
    SetAsideBlock,
    ItemAsideBlock,
    AssortmentDictionaryAsideBlock,
  },
  mixins: [accessMixin],
  setup() {
    const result = BASE_FIELD_DICTIONARY.reduce((acc, curr) => {
      acc[`${curr.value}Pagination`] = reactive({
        current: 1,
        offset: 10,
        chunkedItems: reactive([]),
      });
      return acc;
    }, {});
    return result;
  },
  data() {
    return {
      loading: true,

      selectedSet: null,
      creatingSet: false,
      setPagination: {
        current: 1,
        offset: 10,
        chunkedItems: [],
      },

      selectedItem: null,
      creatingItem: false,
      itemProductionSetting: null,
      itemPagination: {
        current: 1,
        offset: 10,
        chunkedItems: [],
      },

      baseEntity: {
        selectedEntity: null,
        creatingEntity: false,
        type: null,
      },

      tableFilters: {
        sets: null,
        items: null,

        categories: null,
        fabrics: null,
        growTypes: null,
        articles: null,
        productTypes: null,
        sexDictionary: null,
        fieldsForFilter: ['id', 'name'],
      },
      showDeletedEntity: true,
    };
  },
  computed: {
    ...mapState({
      sets: (s) => s.assortment.sets,
      items: (s) => s.assortment.items,
      assortmentDictionary: (s) => s.dictionary.assortmentDictionary,
      assortmentDictionaryDownload: (s) => s.dictionary.assortmentDictionaryDownload,
    }),
    filtredSets() {
      const sets = !this.showDeletedEntity ? this.sets.filter((i) => !i.deleted) : this.sets;
      return filterArrayByFields(sets, this.tableFilters.sets, this.tableFilters.fieldsForFilter);
    },
    filtredItems() {
      const items = !this.showDeletedEntity ? this.items.filter((i) => !i.deleted) : this.items;
      return filterArrayByFields(items, this.tableFilters.items, [
        ...this.tableFilters.fieldsForFilter,
        ['article', (a) => a.code],
      ]);
    },
    filtredCategories() {
      const categories = parseDictionary(this.assortmentDictionary.categories);
      const filtredCategories = !this.showDeletedEntity
        ? categories.filter((i) => !i.deleted)
        : categories;
      return filterArrayByFields(
        filtredCategories,
        this.tableFilters.categories,
        this.tableFilters.fieldsForFilter
      );
    },
    filtredFabrics() {
      const fabrics = parseDictionary(this.assortmentDictionary.fabrics);
      const filtredFabrics = !this.showDeletedEntity ? fabrics.filter((i) => !i.deleted) : fabrics;
      return filterArrayByFields(
        filtredFabrics,
        this.tableFilters.fabrics,
        this.tableFilters.fieldsForFilter
      );
    },
    filtredGrowTypes() {
      const growTypes = parseDictionary(this.assortmentDictionary.growTypes);
      const filtredGrowTypes = !this.showDeletedEntity
        ? growTypes.filter((i) => !i.deleted)
        : growTypes;
      return filterArrayByFields(
        filtredGrowTypes,
        this.tableFilters.growTypes,
        this.tableFilters.fieldsForFilter
      );
    },
    filtredArticles() {
      const articles = parseDictionary(this.assortmentDictionary.articles);
      const filtredArticles = !this.showDeletedEntity
        ? articles.filter((i) => !i.deleted)
        : articles;
      return filterArrayByFields(filtredArticles, this.tableFilters.articles, [
        ...this.tableFilters.fieldsForFilter,
        'code',
        'desc',
        'techComment',
      ]);
    },
    filtredProductTypes() {
      const productTypes = parseDictionary(this.assortmentDictionary.productTypes);
      const filtredProductTypes = !this.showDeletedEntity
        ? productTypes.filter((i) => !i.deleted)
        : productTypes;
      return filterArrayByFields(
        filtredProductTypes,
        this.tableFilters.productTypes,
        this.tableFilters.fieldsForFilter
      );
    },
    filtredSexDictionary() {
      const sex = parseDictionary(this.assortmentDictionary.sex);
      const filtredSex = !this.showDeletedEntity ? sex.filter((i) => !i.deleted) : sex;
      return filterArrayByFields(
        filtredSex,
        this.tableFilters.sexDictionary,
        this.tableFilters.fieldsForFilter
      );
    },
    getSetsFibrics() {
      return (itemFabrics) =>
        itemFabrics.reduce((acc, curr) => {
          const fabric =
            this.assortmentDictionary.fabrics[curr.fabricId]?.name || `fabricId=${curr.fabricId}`;
          if (!acc.includes(fabric)) acc.push(fabric);
          return acc;
        }, []);
    },
    getSetsItems() {
      return (itemFabrics) =>
        itemFabrics.reduce((acc, curr) => {
          const item = this.assortmentDictionary.items[curr.itemId]?.name || `itemId=${curr.id}`;
          if (!acc.includes(item)) acc.push(item);
          return acc;
        }, []);
    },
  },
  async created() {
    this.setFields = setFields;
    this.itemFields = itemFields;
    this.categoryFields = categoryFields;
    this.fabricFields = fabricFields;
    this.sexFields = sexFields;
    this.growTypeFields = growTypeFields;
    this.productTypeFields = productTypeFields;
    this.articleFields = articleFields;

    this.loading = true;
    await Promise.all([
      this.getItems(),
      this.getSets(),
      this.getAssortmentDictionary(),
      this.getAllDepartments(),
      this.getAllProductionLines(),
    ]).then(() => (this.loading = false));
  },
  methods: {
    ...mapActions({
      getSets: 'assortment/getSets',
      getItems: 'assortment/getItems',
      getAllDepartments: 'production/getAllDepartments',
      getAssortmentDictionary: 'dictionary/getAssortmentDictionary',
      getAllProductionLines: 'production/getAllProductionLines',
    }),
    selectBaseEntity(row, type, creating) {
      this.baseEntity = {
        selectedEntity: row,
        creatingEntity: creating,
        type,
      };
    },
    closeSetAsideHandler() {
      this.selectedSet = null;
      this.creatingSet = null;
    },
    closeItemAsideHandler() {
      this.selectedItem = null;
      this.creatingItem = null;
      this.itemProductionSetting = null;
    },
    closeCategoryAsideHandler() {
      this.selectedCategory = null;
      this.creatingCategory = null;
    },
    clickHandler(item) {
      if (this.selectedItem?.id == item.id) this.selectedItem = null;
      else this.selectedItem = item;
    },
    setTableRowCLasses(row) {
      if (row.id === this.selectedSet?.id) return 'active-table-row';
      if (row.deleted) return 'row-deleted';
    },
    itemTableRowCLasses(row) {
      if (row.id === this.selectedItem?.id) return 'active-table-row';
      if (row.deleted) return 'row-deleted';
    },
    baseTableRowClasses(row, entity) {
      if (entity === this.baseEntity.type && this.baseEntity.selectedEntity?.id === row.id)
        return 'active-table-row';
      if (row.deleted) return 'row-deleted';
    },
  },
};
</script>
<style lang="scss" scoped>
.page-header {
  @include InterBold;
  font-size: 18px;
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 36px;

  &__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
.content-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}
.table-filter {
  max-width: 350px;
  margin: 5px 0px 0px 0px;
}
</style>
<style lang="scss">
.assortment-page-content-wrapper {
  .active-table-row {
    background-color: $color-active-item;
  }
  .row-deleted {
    background-color: $color-light-gray;
  }
}
.assortment-sets-table {
  .id {
    width: 60px;
  }
  .fabrics {
    width: 190px;
  }
}
.assortment-item-table {
  .id {
    width: 60px;
  }
  .article {
    width: 200px;
  }
  .equal-column {
    width: 100px;
  }
}
.assortment-base-entity-table {
  .id {
    width: 60px;
  }
  .plainMockup {
    width: 45px;
  }
  .mockup-indicator {
    width: 15px;
    height: 15px;
    border-radius: 4px;
    background-color: $cornflower-blue-60-opacity;
  }
}
</style>
