<template>
  <div class="bt-menu-trigger" :class="{ left: type, right: !type }">
    <span></span>
  </div>
</template>
<script>
export default {
  name: "NavigationBurgerMenu",
  props: {
    type: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.bt-menu-trigger {
  font-size: 12px;
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;

  cursor: pointer;
}

.bt-menu-trigger span {
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  width: 100%;
  height: 0.2em;
  margin-top: -0.1em;
  background-color: $color-white;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.bt-menu-trigger span:after,
.bt-menu-trigger span:before {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  content: "";
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}

.bt-menu-trigger span:before {
  -webkit-transform: translateY(-0.5em);
  transform: translateY(-0.5em);
}

.bt-menu-trigger span:after {
  -webkit-transform: translateY(0.5em);
  transform: translateY(0.5em);
}

.bt-menu-trigger.left:hover span:before {
  -webkit-transform: translateY(-0.36em) translateX(-0.65em) rotate(-45deg)
    scaleX(0.6);
  transform: translateY(-0.35em) translateX(-0.65em) rotate(-45deg) scaleX(0.6);
}

.bt-menu-trigger.left:hover span:after {
  -webkit-transform: translateY(0.36em) translateX(-0.65em) rotate(45deg)
    scaleX(0.6);
  transform: translateY(0.35em) translateX(-0.65em) rotate(45deg) scaleX(0.6);
}
.bt-menu-trigger.right:hover span:before {
  -webkit-transform: translateY(-0.36em) translateX(0.65em) rotate(45deg)
    scaleX(0.6);
  transform: translateY(-0.35em) translateX(0.65em) rotate(45deg) scaleX(0.6);
}

.bt-menu-trigger.right:hover span:after {
  -webkit-transform: translateY(0.36em) translateX(0.65em) rotate(-45deg)
    scaleX(0.6);
  transform: translateY(0.35em) translateX(0.65em) rotate(-45deg) scaleX(0.6);
}
</style>
