export default {
  SET_CURRENT_USER(state, data) {
    state.currentUser = data;
    localStorage.setItem('currentUserType', data.type);
  },

  SET_EMPLOYEE(state, { employee, oldEmployee }) {
    if (!employee) {
      const index = state.employees.findIndex((e) => e.guid === oldEmployee.guid);
      state.employees.splice(index, 1);
      return;
    }
    const index = state.employees.findIndex((e) => e.guid === employee.guid);
    if (index === -1) {
      state.employees = [employee, ...state.employees];
    } else {
      state.employees.splice(index, 1, employee);
    }
  },

  SET_ALL_EMPLOYEES(state, data) {
    state.employees = data;
  },

  SET_ALL_MANAGERS(state, data) {
    state.managers = data;
  },
};
