<template>
  <div class="block-wrapper">
    <div v-if="localSex">
      <div v-if="localSex.deleted" class="entity-alert-deleted">Пол удален !</div>
      <div v-if="localSex" class="entity-title-block">
        <div class="entity-title-block__title">
          Пол:&nbsp;
          <span v-if="localSex.id" class="a-bold"> {{ localSex.id }}. </span>
          &emsp;{{ localSex.name }}
        </div>
      </div>
      <div v-else class="entity-title-block">
        <div class="entity-title-block__title">Новый пол</div>
      </div>
      <div class="entity-details my-20">
        <div class="entity-details_block">
          <AInput
            v-model="localSex.name"
            :read-only="!accessToChange"
            class="entity-details__desc"
            label="Название пол"
            :error="getErrMess('name')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import accessMixin from '@/mixin/accessMixin.js';
import validateMixin from '@/mixin/validateChild.js';

export default {
  name: 'SexBlock',
  mixins: [accessMixin, validateMixin],
  props: {
    selectedEntity: {
      type: Object,
      default: null,
    },
    creatingEntity: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:selectedEntity'],
  data() {
    return {};
  },
  computed: {
    localSex: {
      get() {
        return this.selectedEntity;
      },
      set(val) {
        this.$emit('update:selectedEntity', val);
      },
    },
    accessToChange() {
      return this.isCanChangeAssortment && !this.selectedEntity?.deleted && !this.loading;
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@include assortment-base-entity;
</style>
