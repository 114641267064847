<template>
  <APageLayout>
    <template #header>
      <div class="header">
        <div class="header__row">
          <AButton v-if="cards.length" small @click="printCards">Распечатать карты </AButton>
          <AButton
            v-if="canChangeState"
            small
            class="ml-40"
            :disabled="cardsLoading"
            @click="patchOrderStateHandlerConfirm"
          >
            Передать заказ в тех.дизайн
          </AButton>
          <AToggle
            v-model="withDefectTemplateCard"
            class="ml-40"
            label="Добавить шаблон для брака"
          />
        </div>
      </div>
    </template>
    <template #content>
      <div class="wrapper">
        <div v-if="cardsLoading" class="a-row a-centred">
          <ALoader centred />
        </div>
        <div v-else-if="cards.length && cardOrder">
          <div class="preview">
            <PreviewCards :summary="summary" :cards="cards" :order="cardOrder" />
          </div>
          <div v-show="showCards" ref="cards" class="hidden-cards">
            <div class="cards-wrapper">
              <div v-for="card of cards" :key="card.guid">
                <CardBlock :card="card" :order="cardOrder" />
                <DefectTemplateCard v-if="withDefectTemplateCard" :card="card" :order="cardOrder" />
              </div>
              <div>
                <SummaryCard :summary="summary" :cards="cards" :order="cardOrder" />
              </div>
            </div>
          </div>
        </div>
        <div v-else class="no-found">
          <div class="no-found--title">Заказ не найден. Или карты еще не подготовлены</div>
        </div>
      </div>
    </template>
  </APageLayout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ConfirmDialog from '@@/components/Confirm/index.vue';
import CardBlock from './components/cardBlock.vue';
import SummaryCard from './components/summaryCard.vue';
import PreviewCards from './components/previewCards.vue';
import DefectTemplateCard from './components/defectTemplateCard.vue';
import { cardStyles } from './styleForIframe.js';
import { ORDER_STATE } from '../../constants';

export default {
  name: 'Cards',
  components: {
    CardBlock,
    SummaryCard,
    PreviewCards,
    DefectTemplateCard,
  },
  data() {
    return {
      cardsLoading: true,
      withDefectTemplateCard: true,
      showCards: false,
      summary: [],
    };
  },
  computed: {
    ...mapState({
      cards: (s) => s.card.cards,
      cardOrder: (s) => s.card.cardOrder,
      assortmentDictionary: (s) => s.dictionary.assortmentDictionary,
    }),
    orderId() {
      return this.$route.params.orderId;
    },
    canChangeState() {
      return this.cardOrder && [ORDER_STATE.NEW, ORDER_STATE.DESIGN].includes(this.cardOrder.state);
    },
  },
  async created() {
    await this.getCardsHandler();
  },
  methods: {
    ...mapActions({
      patchOrder: 'order/patchOrder',
      getCardsWithCardOrder: 'card/getCardsWithCardOrder',
      getProductionParams: 'dictionary/getProductionParams',
      getAssortmentDictionary: 'dictionary/getAssortmentDictionary',
    }),
    getSummary(card, blocks = {}) {
      for (const player of card.players) {
        if (!blocks[player.sexId]) blocks[player.sexId] = {};
        blocks[player.sexId][player.size] = ++blocks[player.sexId][player.size] || 1;
      }
      return blocks;
    },
    getAllCardsSummary() {
      const result = [];
      for (const card of this.cards) {
        const lastItem = result[result.length - 1];
        const isNewItem = lastItem?.productId != card.productId;
        const item = {
          name: `${
            this.assortmentDictionary.productTypes[card.product.item.productTypeId]?.name
          }. ${this.assortmentDictionary.categories[card.product.item.categoryId]?.name}`,
          fabric: this.assortmentDictionary.fabrics[card.product.fabricId]?.name,
          parts: isNewItem ? [card.index] : lastItem.parts.concat(card.index),
          color: card.product.color,
          summary: this.getSummary(card, isNewItem ? {} : lastItem?.summary),
          sumQuantity: (isNewItem ? 0 : lastItem?.sumQuantity) + card.players.length,
          productId: card.productId,
        };
        isNewItem ? result.push(item) : result.splice(result.length - 1, 1, item);
      }
      return result;
    },
    async getCardsHandler() {
      this.cardsLoading = true;
      if (!this.orderId) return;
      try {
        await Promise.all([
          this.getAssortmentDictionary(),
          this.getProductionParams(),
          this.getCardsWithCardOrder({ orderId: this.orderId }),
        ]);
        this.summary = this.getAllCardsSummary();
      } catch (err) {
        console.log(err);
      } finally {
        this.cardsLoading = false;
      }
    },
    async patchOrderStateHandlerConfirm() {
      this.$dialog.addDialog({
        component: ConfirmDialog,
        props: {
          title: 'Изменение статуса заказа',
          text: 'Вы действительно хотите изменить статус заказа на "Технический дизайн"? Заказ станет недоступным для редактирования',
          onConfirm: async (confirm) => {
            confirm.showModal = false;
            this.patchOrderStateHandler();
          },
        },
      });
    },
    async patchOrderStateHandler() {
      try {
        this.cardsLoading = true;
        await this.patchOrder({
          order: {
            guid: this.orderId,
            state: ORDER_STATE.TECH_DESIGN,
          },
        });
        await this.getCardsHandler();
      } catch (error) {
        console.log(error);
      } finally {
        this.cardsLoading = false;
      }
    },
    async printCards() {
      this.showCards = true;
      await this.$nextTick();
      const cardsWrapper = this.$refs.cards;
      const windowPrint = window.open(
        null,
        null,
        'left=50,top=50,width=800,height=640,toolbar=0,scrollbars=1,status=0'
      );
      windowPrint.document.write(`
      <html><head><title>${this.cardOrder.name}</title>
      <style>${cardStyles}</style></head>
      <body>${cardsWrapper.innerHTML}</body></html>
      `);

      await new Promise((resolve) => {
        setTimeout(resolve, 3000);
      });
      await this.$nextTick();

      windowPrint.document.close();
      windowPrint.focus();
      windowPrint.print();
      windowPrint.close();
      this.showCards = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  padding: 20px;
  &__row {
    display: flex;
    justify-content: flex-start;
    align-content: flex-end;
    align-items: center;
  }
}
.hidden-cards {
  display: none;
}
.no-found {
  display: flex;
  flex-direction: row;
  justify-content: center;
  &--title {
    padding: 20px;
    border-radius: 6px;
    background-color: $color-bg-light-blue;
  }
}
</style>
