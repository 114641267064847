<template>
  <!-- 


TODO: ДОДЕЛАТЬ


 -->
  <div id="loadingProgressG" :class="{ centered, yellow, green }">
    <div id="loadingProgressG_1" class="loadingProgressG"></div>
  </div>
</template>
<script>
export default {
  name: "LineLoader",
  props: {
    centered: {
      type: Boolean,
      default: false,
    },
    yellow: {
      type: Boolean,
      default: false,
    },
    green: {
      type: Boolean,
      default: false,
    },
    big: {
      type: Boolean,
      default: null,
    },
  },
};
</script>
<style lang="scss" scoped>
$bolb-color: $color-apple;
$loader-width: 350px;
$loader-height: 40px;

.yellow {
  $bolb-color: $color-success !global;
}
.green {
  $bolb-color: $color-apple !global;
}
.big {
  $loader-width: 100%;
}
#loadingProgressG {
  width: $loader-width;
  height: 19px;
  overflow: hidden;
  background-color: rgb(0, 0, 0);
  margin: auto;
  border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
}

.loadingProgressG {
  background-color: rgb(255, 255, 255);
  margin-top: 0;
  margin-left: -#{$loader-width};
  animation-name: bounce_loadingProgressG;
  -o-animation-name: bounce_loadingProgressG;
  -ms-animation-name: bounce_loadingProgressG;
  -webkit-animation-name: bounce_loadingProgressG;
  -moz-animation-name: bounce_loadingProgressG;
  animation-duration: 2.08s;
  -o-animation-duration: 2.08s;
  -ms-animation-duration: 2.08s;
  -webkit-animation-duration: 2.08s;
  -moz-animation-duration: 2.08s;
  animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-timing-function: linear;
  -o-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  width: 250px;
  height: 19px;
}

@keyframes bounce_loadingProgressG {
  0% {
    margin-left: -250px;
  }

  100% {
    margin-left: 250px;
  }
}

@-o-keyframes bounce_loadingProgressG {
  0% {
    margin-left: -250px;
  }

  100% {
    margin-left: 250px;
  }
}

@-ms-keyframes bounce_loadingProgressG {
  0% {
    margin-left: -250px;
  }

  100% {
    margin-left: 250px;
  }
}

@-webkit-keyframes bounce_loadingProgressG {
  0% {
    margin-left: -250px;
  }

  100% {
    margin-left: 250px;
  }
}

@-moz-keyframes bounce_loadingProgressG {
  0% {
    margin-left: -250px;
  }

  100% {
    margin-left: 250px;
  }
}
</style>
