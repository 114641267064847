<template>
  <APageLayout
    max-height
    without-margins
    :class="{ isDragActive }"
    @mousewheel="handleScrollTable"
    @mousedown="mouseDownHandler"
    @mousemove="mouseMoveHandler"
  >
    <template #header>
      <div class="page-header">
        <ALinePageLoader v-if="cardDetailsLoading" />
        <div class="page-header__container">
          <div class="row">
            <div class="mr-40">Мониторинг</div>
            <div class="filters">
              <div class="content">
                <div class="name-filter">
                  <AInput v-model.trim="filters.name" placeholder="Поиск по названию заказа" />
                </div>
              </div>
              <div class="mr-10 a-row">
                <AButton :disabled="loading" @click="showAsideFilters = !showAsideFilters">
                  <div v-if="countAsideFilters" class="budge budge__filters" />
                  <div v-if="filters.selectedDepartments.length" class="budge budge__permanent" />
                  &nbsp;
                  <Fa
                    name="icon"
                    icon="filter"
                    class="icon faicon"
                    style="width: 15px; height: 15px"
                  />
                  &nbsp;
                </AButton>
                <div class="a-row ml-20">
                  <IconCover
                    active
                    @click="columnState.depRowDirection = !columnState.depRowDirection"
                  >
                    <Fa
                      :class="{ rotate90: !columnState.depRowDirection }"
                      icon="arrows-alt-v"
                      class="icon check"
                      style="width: 15px; height: 15px"
                    />
                  </IconCover>
                  <IconCover
                    v-if="columnState.depRowDirection"
                    active
                    class="ml-5"
                    @click="columnState.subDepartmentsHide = !columnState.subDepartmentsHide"
                  >
                    <Fa
                      v-if="columnState.subDepartmentsHide"
                      icon="eye"
                      class="icon check"
                      style="width: 15px; height: 15px"
                    />
                    <Fa
                      v-else
                      icon="eye-slash"
                      class="icon check"
                      style="width: 15px; height: 15px"
                    />
                  </IconCover>
                </div>
              </div>
            </div>
          </div>

          <div class="mr-20"></div>
        </div>
      </div>
    </template>
    <template #content>
      <div ref="column-wrapper" class="content-wrapper" :style="cssVar" :class="{ topBlockHidden }">
        <div ref="top-side" class="top-side" />
        <div ref="left-side" class="left-side" />

        <div
          v-if="!loading"
          class="mcolumn-wrapper"
          :class="{
            depRowDirection: columnState.depRowDirection,
            subDepartmentsHide: columnState.subDepartmentsHide,
          }"
        >
          <div class="order-column">
            <div class="order-column-cover">
              <div class="order-column-header">
                <div class="cover">
                  Общее
                  <div class="sum-card">
                    {{ filtredOrders.length }}
                  </div>
                </div>
              </div>
              <div class="order-column-body">
                <div
                  v-for="(ordersGroup, key) in orderByState"
                  :key="`${key}`"
                  class="block-wrapper"
                  :class="{ [key]: key, [`${key}OrderBlock`]: getBindedClass[`${key}OrderBlock`] }"
                >
                  <div class="block">
                    <div class="stage-name-row">
                      <div class="row-content">
                        <div>{{ ORDER_GROUP[key].name }}</div>
                        <div class="stage-sum">
                          {{ ordersGroup.length }}
                        </div>
                      </div>
                      <div>
                        <IconCover
                          :class="{ [`${key}OrderBlock`]: getBindedClass[`${key}OrderBlock`] }"
                          active
                          class="icon"
                          @click="changeShowOrderBlockHandler(key)"
                        >
                          <Fa
                            icon="chevron-down"
                            class="iconOrderBlock"
                            style="width: 14px; height: 14px"
                          />
                        </IconCover>
                      </div>
                    </div>
                    <div class="block-body">
                      <MonitoringOrderCard
                        v-for="order of ordersGroup"
                        :key="order.guid"
                        :order="order"
                        :additional-info-show-order-card-id="additionalInfoShowOrderCardId"
                        :departments-dictionary="departmentsDictionary"
                        :loading="
                          globalLoading ||
                          sendToClientOrdersLoading[order.guid] ||
                          orderSetPaidLoading[order.guid]
                        "
                        :access-to-change-sub-department="accessToChangeSubDepartment"
                        @showOrderCardAdditionalInfo="additionalInfoShowOrderCardId = $event"
                        @orderProductionLineController="orderProductionLineControllerHandler"
                        @doneOrderSendToClient="ordersendedToClientHandler"
                        @orderSetPaid="orderSetPaidHandler"
                        @changeOrderUrgencyType="changeOrderUrgencyTypeHandler"
                      />
                    </div>
                  </div>
                </div>
                <div class="expand"></div>
              </div>
            </div>
          </div>

          <div
            v-for="depData of getCardsByDepartment"
            :key="`department_${depData.department.id}`"
            class="mcolumn"
          >
            <div class="mcolumn-cover">
              <div class="mcolumn-header">
                <div class="cover">
                  <div class="content">
                    <div class="a-row content-header">
                      <div class="title">
                        {{ depData.department.name }}
                      </div>
                      <div class="sum-card">
                        {{ depData.cardsQuantity }}
                        {{ depData.playersQuantity ? '| ' + depData.playersQuantity : '' }}
                      </div>
                    </div>
                    <div v-if="columnState.depRowDirection" class="sub-header-container">
                      <div
                        v-for="subDepData of depData.data"
                        :key="`subDepartment_name_${depData.department.id}_${subDepData.subDepartment.id}`"
                        class="sub-header"
                      >
                        <div v-if="depData.data.length > 1" class="sub-header-desc">
                          <div>
                            {{ subDepData.subDepartment.name }}
                          </div>
                          <div class="ml-10 sum-card-square">
                            {{ subDepData.cardsQuantity }}
                            {{
                              subDepData.playersQuantity ? '| ' + subDepData.playersQuantity : ''
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mcolumn-subdepartments-wrapper">
                <transition-group
                  v-for="subDepData of depData.data"
                  :key="`subDepartment_${depData.department.id}_${subDepData.subDepartment.id}`"
                  name="flip-list"
                  tag="div"
                  class="mcolumn-body"
                  @drop="onDrop($event, depData.department.id, subDepData.subDepartment.id)"
                  @dragover.prevent
                  @dragenter.prevent
                >
                  <div
                    :key="`11sub_dep_name_${depData.department.id}_${subDepData.subDepartment.id}`"
                    class="sub-departments-wrapper"
                  >
                    <div
                      v-if="
                        !columnState.depRowDirection &&
                        subDepData.cards.length &&
                        depData.data.length > 1
                      "
                      :key="`sub_dep_name_${depData.department.id}_${subDepData.subDepartment.id}`"
                      class="sub-department-column-header"
                    >
                      <div class="title">{{ subDepData.subDepartment.name }}</div>

                      <div class="sum-card">
                        {{ subDepData.cardsQuantity }}
                        {{ subDepData.playersQuantity ? '| ' + subDepData.playersQuantity : '' }}
                      </div>
                    </div>
                    <div
                      v-for="card of subDepData.cards"
                      :key="`card_wrapper_${card.guid}`"
                      class="card-wrapper"
                    >
                      <MonitoringCard
                        :card="card"
                        :additional-info-show-card-id="additionalInfoShowCardId"
                        :order="orderDictionary[card.orderId]"
                        :loading="globalLoading || card.drag"
                        :employees-dictionary="employeesDictionary"
                        :draggable="accessToChangeSubDepartment ? `true` : `false`"
                        :class="{ drag: card.drag }"
                        :is-foreign-card="
                          isForeignCard(
                            depData.department.id,
                            subDepData.subDepartment.id,
                            card.currentEmployeeId,
                            card.state
                          )
                        "
                        :access-to-change-sub-department="accessToChangeSubDepartment"
                        class="monitoring-card"
                        @dragstart="startDrag($event, card)"
                        @showCardAdditionalInfo="additionalInfoShowCardId = $event"
                        @showCardDetails="showCardDetailsHandler"
                        @changeCardUrgencyType="changeCardUrgencyTypeHandler"
                      />
                    </div>
                  </div>
                  <div
                    :key="`${depData.department.id}_${subDepData.subDepartment.id}_expand`"
                    class="expand"
                    style="min-width: 320px"
                  />
                </transition-group>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="loaded-wrapper">
          <ALoader centered />
        </div>
      </div>
      <MonitoringAsideFiltersPage
        v-model:employee-id="filters.employeeId"
        v-model:deadline="filters.deadline"
        v-model:selected-departments="filters.selectedDepartments"
        v-model:selected-tag-ids="filters.selectedTagIds"
        v-model:exclude-tag-ids="filters.excludeTagIds"
        v-model:selected-category-ids="filters.selectedCategoryIds"
        v-model:exclude-category-ids="filters.excludeCategoryIds"
        :show-aside-filters="showAsideFilters"
        :departments="departments"
        :loading="globalLoading"
        @close="showAsideFilters = false"
      />
    </template>
  </APageLayout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import MonitoringCard from './components/MonitoringCard.vue';
import MonitoringOrderCard from './components/MonitoringOrderCard.vue';
import { prepareDictionary } from '@/utils/dictionaryUtils';
import { sortBy } from '@/utils/lodash';
import {
  CARD_GENERAL_STATE,
  CARD_STATE,
  MONITORING_CARD_ACTIONS,
  MONITORING_SORTED_CARD_STATE,
  ORDER_PARAM_TYPE,
  ORDER_STATE,
  SYSTEM_USER_LG,
  URGENCY_TYPE,
} from '../../constants';
import accessMixin from '@/mixin/accessMixin.js';
import IconCover from '@/components/base/IconCover.vue';
import MonitoringProductionCardModal from './components/MonitoringProductionCardModal.vue';
import MonitoringUserCardsDetailsModal from './components/MonitoringUserCardsDetailsModal.vue';
import MonitoringAsideFiltersPage from './components/MonitoringAsideFiltersPage.vue';
import OrderCardControllerModal from './components/OrderCardControllerModal.vue';
import { debounce } from '@/utils/lodash.js';
import { dayjs } from '@/utils/date';
import { filterArrayByFields } from '../../utils/filters';
import { useOrderSendToClient } from '../../use/orderSendToClient';
import { useOrderSetPaid } from '../../use/orderSetPaid';
import { STORAGE_KEY, STORAGE_PATH, storage } from '../../utils/localStorage';
import { cloneDeep } from '../../utils/lodash';
import { useOrderParamValue } from '../../use/order/useOrderParamValue';

const ORDER_GROUP = {
  'done': {
    name: 'Готово',
    states: [ORDER_STATE.COMPLETED],
  },
  'process': {
    name: 'В работе',
    states: [ORDER_STATE.PRODUCTION],
  },
  'queue': {
    name: 'Запланировано',
    states: [ORDER_STATE.TECH_DESIGN],
  },
};

const FILTERS_ON_ASIDE_PAGE = [
  'employeeId',
  'deadline',
  'selectedTagIds',
  'excludeTagIds',
  'selectedCategoryIds',
  'excludeCategoryIds',
];
export default {
  name: 'Monitoring',
  components: {
    IconCover,
    MonitoringCard,
    MonitoringOrderCard,
    MonitoringAsideFiltersPage,
  },
  mixins: [accessMixin],
  setup() {
    const { getValueFromOrderParamByType } = useOrderParamValue();

    const { ordersendedToClientHandler, sendToClientOrdersLoading } = useOrderSendToClient();
    const { orderSetPaidHandler, orderSetPaidLoading } = useOrderSetPaid();

    // const { getCardsByDepartmentFn } = useMonitoringOrdersAndCards();

    return {
      getValueFromOrderParamByType,

      ordersendedToClientHandler,
      sendToClientOrdersLoading,

      orderSetPaidHandler,
      orderSetPaidLoading,
    };
  },

  data() {
    return {
      grid: {},
      columnState: {
        depRowDirection: true,
        subDepartmentsHide: false,
        // [depId]: {}
      },
      draggedCard: null,

      loading: true, // TODO true
      cardDetailsLoading: false,
      urgencyTypeLoading: false,

      columnWrapper: null, // ref
      isDragActive: false,
      lastDragEvent: 0,

      topBlockHidden: false,

      additionalInfoShowCardId: null,
      additionalInfoShowOrderCardId: null,

      showAsideFilters: false, // TODO false
      filters: {
        name: null,

        employeeId: null,
        deadline: null,
        selectedTagIds: [],
        excludeTagIds: [],
        selectedCategoryIds: [],
        excludeCategoryIds: [],

        selectedDepartments:
          storage({
            key: STORAGE_KEY.MONITORING,
            path: STORAGE_PATH.MONITORING_SETTING_SELECTED_DEP,
          }) || [],
      },

      doneOrderBlock: true,
      processOrderBlock: true,
      queueOrderBlock: true,
    };
  },
  computed: {
    ...mapState({
      orders: (s) => s.monitoring.orders,
      employees: (s) => s.user.employees,
      departments: (s) => s.production.departments,
      cardDetails: (s) => s.monitoring.cardDetails,
    }),
    globalLoading() {
      return this.loading || this.cardDetailsLoading || this.urgencyTypeLoading;
    },
    accessToChangeSubDepartment() {
      return this.isAdmin || this.isMainEmployee;
    },
    departmentsDictionary() {
      const depDectionary = prepareDictionary(this.departments);
      for (const departmentId in depDectionary) {
        const department = depDectionary[departmentId];
        department.subDepartmentsDictionary = prepareDictionary(department.subDepartments);
      }
      return depDectionary;
    },
    employeesDictionary() {
      return {
        ...prepareDictionary(this.employees, 'guid'),
        [SYSTEM_USER_LG.guid]: SYSTEM_USER_LG,
      };
    },
    getBindedClass() {
      return {
        doneOrderBlock: this.doneOrderBlock,
        processOrderBlock: this.processOrderBlock,
        queueOrderBlock: this.queueOrderBlock,
      };
    },
    filterDebounced: {
      get() {
        return this.filter || '';
      },
      set: debounce(function (newValue) {
        this.filter = newValue;
      }, 70),
    },
    getThisValueByKey() {
      return (field) => this[field];
    },
    countAsideFilters() {
      let result = 0;
      for (const key in this.filters) {
        if (FILTERS_ON_ASIDE_PAGE.includes(key)) {
          const value = this.filters[key];
          if (Array.isArray(value) ? value.length : !!value) {
            result++;
          }
        }
      }
      return result;
    },
    sortedOrders() {
      return sortBy(this.orders, [
        (o) => -this.URGENCY_TYPE_SORT_ARRAY.indexOf(o.urgencyType),
        (o) =>
          this.getValueFromOrderParamByType({
            orderParamsType: ORDER_PARAM_TYPE.PACKING_DATE,
            orderParams: o.orderParams,
          }) ||
          o.postponedDeadline ||
          o.deadline,
      ]);
    },
    filtredOrders() {
      let result = this.sortedOrders.filter(
        (o) =>
          !(
            o.closeDate &&
            this.getValueFromOrderParamByType({
              orderParamsType: ORDER_PARAM_TYPE.PAID_DATE,
              orderParams: o.orderParams,
            })
          ) && ![ORDER_STATE.NEW, ORDER_STATE.DESIGN].includes(o.state)
      );
      if (this.filters.selectedDepartments.length) {
        result = result.filter((o) => {
          return o.cards.some((c) =>
            this.filters.selectedDepartments.includes(c.currentDepartmentId)
          );
        });
      }
      if (this.filters.selectedTagIds.length) {
        result = result.filter((o) => this.filters.selectedTagIds.includes(o.tagId));
      }
      if (this.filters.excludeTagIds.length) {
        result = result.filter((o) => !o.tagId || !this.filters.excludeTagIds.includes(o.tagId));
      }
      if (this.filters.selectedCategoryIds.length) {
        result = result.filter((o) =>
          this.getValueFromOrderParamByType({
            orderParamsType: ORDER_PARAM_TYPE.CATEGORY,
            orderParams: o.orderParams,
          }).some((oCatId) => this.filters.selectedCategoryIds.includes(oCatId))
        );
      }
      if (this.filters.excludeCategoryIds.length) {
        result = result.filter(
          (o) =>
            !this.getValueFromOrderParamByType({
              orderParamsType: ORDER_PARAM_TYPE.CATEGORY,
              orderParams: o.orderParams,
            }).some((oCatId) => this.filters.excludeCategoryIds.includes(oCatId))
        );
      }
      if (this.filters.deadline) {
        result = result.filter(
          (o) =>
            o.deadline &&
            dayjs(o.deadline).isBetween(
              this.filters.deadline[0],
              this.filters.deadline[1],
              'day',
              '[]'
            )
        );
      }
      if (this.filters.employeeId) {
        result = result.filter((o) => {
          return o.cards.some((c) => c.currentEmployeeId === this.filters.employeeId);
        });
      }
      if (this.filters.name) {
        result = filterArrayByFields(result, this.filters.name.toLowerCase(), ['name', 'guid']);
      }
      return result;
    },
    orderDictionary() {
      return prepareDictionary(this.orders, 'guid');
    },
    sortedAndFiltredDepartments() {
      return [
        ...(this.filters.selectedDepartments.length
          ? this.departments.filter((d) => this.filters.selectedDepartments.includes(d.id))
          : this.departments),
      ].sort((a, b) => a.tableIndex < b.tableIndex);
    },
    cardDepatmentsDictionaryTemplate() {
      const selectedDepartmentsIds = this.sortedAndFiltredDepartments.map((d) => d.id);
      return this.departments.reduce(
        (acc, curr) => {
          if (!selectedDepartmentsIds.includes(curr.id)) {
            return acc;
          }

          acc.dictionary[curr.id] = {
            index: acc.departments.length,
            subDepartments: {},
          };
          acc.departments.push({
            department: { ...curr, subDepartments: undefined },
            cardsQuantity: 0,
            playersQuantity: 0,
            data: [
              {
                subDepartment: {
                  id: 0,
                  name: 'Неразобранное',
                  cards: [],
                },
                cardsQuantity: 0,
                playersQuantity: 0,
                cards: [],
              },
              ...curr.subDepartments.map((subDep, ind) => {
                acc.dictionary[curr.id].subDepartments[subDep.id] = {
                  index: ind + 1,
                };
                return {
                  subDepartment: subDep,
                  cardsQuantity: 0,
                  playersQuantity: 0,
                  cards: [],
                };
              }),
            ],
          });
          return acc;
        },
        {
          departments: [],
          dictionary: {},
        }
      );
    },
    getCardsByDepartment() {
      const selectedDepartmentsIds = this.sortedAndFiltredDepartments.map((d) => d.id);

      const getDepInd = (departmentId, subDepartmentId) => {
        const dep = this.cardDepatmentsDictionaryTemplate.dictionary[departmentId];
        if (subDepartmentId) {
          return dep.subDepartments[subDepartmentId]?.index || 0;
        }
        return dep.index;
      };
      const getSubDepInd = (departmentId, subDepartmentId, currentEmployeeId) => {
        const dep = this.cardDepatmentsDictionaryTemplate.dictionary[departmentId];
        const subDepartments = this.departmentsDictionary[departmentId].subDepartments;
        if (subDepartmentId) {
          return dep.subDepartments[subDepartmentId]?.index || 0;
        }
        if (!subDepartmentId && subDepartments.length && currentEmployeeId) {
          const subDep = subDepartments.find((sd) => sd.employeeIds.includes(currentEmployeeId));
          return subDep ? dep.subDepartments[subDep.id]?.index || 0 : 0;
        }
        return 0;
      };

      const cardsByDepartment = cloneDeep(this.cardDepatmentsDictionaryTemplate.departments);
      for (const order of this.filtredOrders) {
        if (order.state === ORDER_STATE.COMPLETED) {
          continue;
        }
        for (const card of order.cards) {
          if (!selectedDepartmentsIds.includes(card.currentDepartmentId)) {
            continue;
          }
          if (card.generalState !== CARD_GENERAL_STATE.DONE && this.checkCardInFilter(card)) {
            const dep = cardsByDepartment[getDepInd(card.currentDepartmentId, null)];
            const subDep =
              dep.data[
                getSubDepInd(card.currentDepartmentId, card.subDepartmentId, card.currentEmployeeId)
              ];

            subDep.cards.push(card);

            dep.cardsQuantity += 1;
            dep.playersQuantity += card.playersLength;
            subDep.cardsQuantity += 1;
            subDep.playersQuantity += card.playersLength;
          }
        }
      }

      for (let i = 0; i < cardsByDepartment.length; i++) {
        const departments = cardsByDepartment[i].data;
        if (this.columnState.subDepartmentsHide) {
          cardsByDepartment[i].data = departments.filter((d) => d.cardsQuantity);
        }
        for (let k = 0; k < departments.length; k++) {
          departments[k].cards = this.sortDepartments(departments[k].cards);
        }
      }

      return cardsByDepartment;
    },
    orderByState() {
      const result = cloneDeep(this.GROUPED_ORDER_STATE);

      for (const order of this.filtredOrders) {
        for (const groupKey in ORDER_GROUP) {
          const groupData = ORDER_GROUP[groupKey];
          if (groupData.states.includes(order.state)) {
            result[groupKey].push(order);
          }
        }
      }
      return result;
    },
    cssVar() {
      return {
        '--department-count': this.departments.length,
        ...(this.columnState.depRowDirection
          ? {
              '--department-col-width': '1fr',
              '--department-col-direction': 'row',
            }
          : {
              '--department-col-width': '360px',
              '--department-col-direction': 'column',
            }),
      };
    },
    getColumnState() {
      return (departmentId) => {
        const data = this.columnState[departmentId];

        return (
          data || {
            depRowDirection: true,
            subDepartmentsHide: false,
          }
        );
      };
    },
  },
  watch: {
    'filters.selectedDepartments': {
      handler: function (newValue) {
        storage(
          { key: STORAGE_KEY.MONITORING, path: STORAGE_PATH.MONITORING_SETTING_SELECTED_DEP },
          newValue || []
        );
      },
      deep: true,
    },
  },
  async created() {
    this.ORDER_GROUP = ORDER_GROUP;
    this.URGENCY_TYPE = URGENCY_TYPE;
    this.URGENCY_TYPE_SORT_ARRAY = [...Object.values(URGENCY_TYPE)].reverse();
    this.GROUPED_ORDER_STATE = Object.keys(ORDER_GROUP).reduce((acc, curr) => {
      acc[curr] = [];
      return acc;
    }, {});
    await this.bootstrap();
  },
  mounted() {
    this.columnWrapper = this.$refs['column-wrapper'];

    window.addEventListener('mouseleave', this.mouseLeaveHandler);
    window.addEventListener('mouseup', this.mouseUpHandler);
    this.setObserver();
  },
  beforeUnmount() {
    window.removeEventListener('mouseleave', this.mouseLeaveHandler);
    window.removeEventListener('mouseup', this.mouseUpHandler);
  },
  methods: {
    ...mapActions({
      getProductionParams: 'dictionary/getProductionParams',
      getAssortmentDictionary: 'dictionary/getAssortmentDictionary',
      getAllEmployees: 'user/getAllEmployees',
      getAllMonitoringOrders: 'monitoring/getAllMonitoringOrders',
      getAllDepartments: 'production/getAllDepartments',
      getAllMonitoringCardDetails: 'monitoring/getAllMonitoringCardDetails',
      getAllProductionLines: 'production/getAllProductionLines',
      getAllOrderItemProductionLinesIdsByOrderId:
        'production/getAllOrderItemProductionLinesIdsByOrderId',
      changeOrder: 'order/patchOrder',
      getOrderTags: 'order/getOrderTags',
      getOrderCategory: 'order/getOrderCategory',
      changeCard: 'monitoring/patchCard',
    }),
    isForeignCard(departmentId, subDepartmentId, currentEmployeeId, state) {
      if (state === CARD_STATE.QUEUE) {
        return false;
      }
      return subDepartmentId
        ? !this.departmentsDictionary[departmentId].subDepartmentsDictionary[
            subDepartmentId
          ].employeeIds.includes(currentEmployeeId)
        : false;
    },
    changeColumnState(departmentId, action) {
      let dep = this.columnState[departmentId];
      if (!dep) {
        this.columnState[departmentId] = {
          depRowDirection: true,
          subDepartmentsHide: false,
        };
        dep = this.columnState[departmentId];
      }
      dep[action] = !dep[action];
    },

    sortDepartments(departments) {
      return sortBy(departments, [
        (c) => MONITORING_SORTED_CARD_STATE.indexOf(c.state),
        // (c) => {
        //   //l3 l2 l1
        //   //card (l2)->>1
        //   //order (l1)->>3
        //   const cardInd = this.URGENCY_TYPE_SORT_ARRAY.indexOf(c.urgencyType);
        //   const orderInd = this.URGENCY_TYPE_SORT_ARRAY.indexOf(
        //     this.orderDictionary[c.orderId].urgencyType
        //   );
        //   return -this.URGENCY_TYPE_SORT_ARRAY.indexOf(Math.max(cardInd, orderInd));
        // },
        (c) =>
          -this.URGENCY_TYPE_SORT_ARRAY.indexOf(
            c.urgencyType || this.orderDictionary[c.orderId].urgencyType
          ),
        // (c) => -this.URGENCY_TYPE_SORT_ARRAY.indexOf(c.urgencyType),
        (c) =>
          this.getValueFromOrderParamByType({
            orderParamsType: ORDER_PARAM_TYPE.PACKING_DATE,
            orderParams: this.orderDictionary[c.orderId].orderParams,
          }) ||
          this.orderDictionary[c.orderId].postponedDeadline ||
          this.orderDictionary[c.orderId].deadline,
      ]);
    },
    startDrag(e, card) {
      if (this.draggedCard) {
        e.preventDefault();
        return;
      }
      this.draggedCard = true;
      e.dataTransfer.dropEffect = 'move';
      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.setData('cardId', card.guid);
      e.dataTransfer.setData('departmentId', card.currentDepartmentId);
      e.dataTransfer.setData('subDepartmentId', card.subDepartmentId);
      card.drag = true;
    },
    async onDrop(e, departmentId, subDepartmentId) {
      this.draggedCard = false;
      const cardId = e.dataTransfer.getData('cardId');
      const currDepartmentId = parseInt(e.dataTransfer.getData('departmentId'), 10);
      const currSubDepartmentId = parseInt(e.dataTransfer.getData('subDepartmentId'), 10) || 0;
      const deps = this.getCardsByDepartment.find((dep) => dep.department.id === currDepartmentId);
      if (!deps || !currDepartmentId) {
        this.$notifyError({ title: `Ошибка, отдел не найден` });
        return;
      }
      const card = deps.data.reduce((acc, subDep) => {
        if (acc) {
          return acc;
        }
        const card = subDep.cards.find((c) => c.guid === cardId);
        if (card) {
          return card;
        }
        return acc;
      }, null);

      if (!card) {
        this.$notifyError({ title: `Ошибка` });
        return;
      }
      if (card.currentDepartmentId !== departmentId) {
        this.$notifyError({ title: `Перемещение допустимо только внутри одного отдела` });
        card.drag = false;
        return;
      }
      card.subDepartmentId = subDepartmentId;

      try {
        await this.changeCard({ card: { guid: card.guid, subDepartmentId } });
      } catch (error) {
        card.subDepartmentId = currSubDepartmentId;
      } finally {
        card.drag = false;
      }
    },
    async showCardDetailsHandler({ card, action }) {
      if (
        this.cardDetails?.card?.guid !== card.guid ||
        this.cardDetails?.card?.updatedAt !== card.updatedAt
      ) {
        this.cardDetailsLoading = true;
        try {
          await this.getAllMonitoringCardDetails({ card: card });
        } catch (error) {
          console.log(error);
        } finally {
          this.cardDetailsLoading = false;
        }
      }
      if (action === MONITORING_CARD_ACTIONS.FULL_CARD) {
        this.$dialog.addDialog({
          component: MonitoringProductionCardModal,
          props: {
            order: this.orderDictionary[card.orderId],
            fullProductionCard: this.cardDetails.fullCard,
            persistent: false,
            onConfirm: (modal) => {
              modal.showModal = false;
            },
          },
        });
      } else if (action === MONITORING_CARD_ACTIONS.USET_CARD_DETAILS) {
        this.$dialog.addDialog({
          component: MonitoringUserCardsDetailsModal,
          props: {
            employeesDictionary: this.employeesDictionary,
            departmentsDictionary: this.departmentsDictionary,
            cardEmployeeDetails: this.cardDetails.cardEmployeeDetails,
            title: `${this.orderDictionary[card.orderId].name}. Партия ${
              this.cardDetails.card.index
            }/${this.orderDictionary[card.orderId].cardsQuantity}`,
            persistent: false,
            onConfirm: (modal) => {
              modal.showModal = false;
            },
          },
        });
      }
    },
    async changeCardUrgencyTypeHandler({ card, urgencyType }) {
      try {
        this.urgencyTypeLoading = true;
        await this.changeCard({ card: { guid: card.guid, urgencyType } });
      } catch (error) {
        console.log(error);
      } finally {
        this.urgencyTypeLoading = false;
      }
    },
    async changeOrderUrgencyTypeHandler({ order, urgencyType }) {
      try {
        this.urgencyTypeLoading = true;
        await this.changeOrder({ order: { guid: order.guid, urgencyType } });
      } catch (error) {
        console.log(error);
      } finally {
        this.urgencyTypeLoading = false;
      }
    },
    checkCardInFilter(card) {
      if (this.filters.employeeId) {
        return card.currentEmployeeId == this.filters.employeeId;
      }
      return true;
    },
    setObserver() {
      const topHeader = this.$refs['top-side'];
      this.topHeaderObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => (this.topBlockHidden = !entry.isIntersecting));
      });
      this.topHeaderObserver.observe(topHeader);
    },
    async bootstrap() {
      this.loading = true;
      try {
        const promises = [
          this.getOrderTags(),
          this.getOrderCategory(),
          this.getAllEmployees(),
          this.getProductionParams(),
          this.getAssortmentDictionary(),
          this.getAllMonitoringOrders({ force: true }),
          this.getAllDepartments(),
          this.getAllProductionLines(),
        ];
        await Promise.all(promises);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    handleScrollTable(event) {
      const path = event.composedPath ? event.composedPath() : event.path;
      if (!path) {
        return;
      }

      if (
        path.find(
          (el) => el.classList?.contains('mcolumn-header') || el.classList?.contains('page-header')
        )
      ) {
        this.columnWrapper.scrollLeft += event.deltaY * 2.5;
        return;
      }
    },
    async orderProductionLineControllerHandler(order) {
      this.cardDetailsLoading = true;
      const productionLinesIdsByItemId = await this.getAllOrderItemProductionLinesIdsByOrderId({
        orderId: order.guid,
      });
      this.$dialog.addDialog({
        component: OrderCardControllerModal,
        props: {
          order,
          departmentsDictionary: this.departmentsDictionary,
          productionLinesIdsByItemId,
          persistent: true,
          onConfirm: (modal) => {
            modal.showModal = false;
          },
        },
      });
      this.cardDetailsLoading = false;
    },
    mouseMoveHandler(event) {
      if (this.isDragActive) {
        event.preventDefault();
        this.columnWrapper.scrollLeft -= event.movementX;
        this.columnWrapper.scrollTop -= event.movementY;
      }
    },
    mouseDownHandler(event) {
      if (event.which === 2) {
        this.isDragActive = true;
        event.preventDefault();
      }
    },
    mouseUpHandler(event) {
      if (event.which === 2) {
        this.isDragActive = false;
        event.preventDefault();
      }
    },
    mouseLeaveHandler() {
      this.isDragActive = false;
    },
    changeShowOrderBlockHandler(field) {
      this[`${field}OrderBlock`] = !this[`${field}OrderBlock`];
    },
  },
};
</script>
<style lang="scss" scoped>
.rotate90 {
  transform: rotate(90deg);
}
.drag {
  opacity: 0.3;
}
.monitoring-card {
  background-color: white;
}
.isDragActive {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;

  // position: absolute;
  // background-color: red;
}
.page-header {
  @include InterBold;
  font-size: 18px;
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 36px;
  // position: relative;

  &__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;

    .filters {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: fit-content;

        .name-filter {
          width: 300px;
          margin-right: 10px;
        }
        .deadline-filter {
          width: 300px;
          margin-right: 10px;
        }
        .employee-filter {
          width: 300px;
          margin-right: 10px;
        }
      }

      .budge {
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        &__filters {
          right: 3px;
          top: 3px;
          background-color: $color-red;
        }
        &__permanent {
          bottom: 3px;
          left: 3px;
          background-color: rgb(227, 231, 41);
        }
      }
    }
  }
}

.content {
  &-wrapper {
    // position: relative;
    display: grid;
    grid-template-areas:
      'a a a'
      'b c c'
      'b c c'
      'b c c';
    overflow: scroll;
    @include scroll-wider;
  }
}
.top-side {
  grid-area: a;
}
.left-side {
  grid-area: b;
}
$order-col-width: 440px;
$department-col-width: var(--department-col-width);
$department-col-direction: var(--department-col-direction);

$header-height: 46px;
$border-radius: 6px;

.topBlockHidden {
  .mcolumn-header .content {
    //TODO
    box-shadow: 0px 3px 2px 2px rgba(133, 139, 200, 0.3);
    transition: box-shadow 0.3s;
  }
  .order-column .cover {
    box-shadow: 0px 3px 2px 2px rgba(41, 86, 190, 0.4);
    transition: box-shadow 0.3s;
  }
}

.mcolumn {
  // position: relative;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  background-color: $color-column-bg;
  border-radius: $border-radius;

  &-wrapper {
    min-height: calc(100vh - 100px);
    grid-area: c;
    margin-top: 20px;
    margin-left: 28px;

    display: grid;
    grid-template-columns: $order-col-width repeat(var(--department-count), $department-col-width);
    min-width: $department-col-width;
    gap: 8px;
    @include scroll-wider;

    &.depRowDirection {
      .mcolumn-subdepartments-wrapper {
        padding: 0px 20px;
        margin-top: 20px;
      }
      .mcolumn-header .content {
        grid-template-rows: 35px 35px;
      }
    }
  }
  &-cover {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 46px 1fr;

    min-width: $department-col-width;
  }
  &-subdepartments-wrapper {
    display: flex;
    // flex-direction: column;
    flex-direction: $department-col-direction;
    grid-gap: 15px;
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  // position: relative;

  &-header {
    // height: $header-height;
    display: flex;
    flex-direction: row;
    justify-content: center;
    // align-items: center;

    z-index: 2;
    position: sticky;
    top: -0px;
    .title {
      display: flex;
      flex-direction: row;

      justify-content: center;
      align-items: center;
    }
    .cover {
      background-color: $color-column-bg-solid;
      min-width: 100%;

      .content {
        @include InterBold;
        background-color: $color-column-header;
        border-radius: $border-radius $border-radius 0px 0px;
        // height: $header-height;
        text-transform: capitalize;
        min-width: 320px;

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding-left: 20px;

        display: grid;
        grid-template-rows: 35px;
        // grid-template-rows: 35px 35px;

        .title {
          font-size: 18px;
        }
        .sum-card {
          font-size: 14px;
          margin-left: 10px;

          background-color: $color-white;
          padding: 4px 6px;
          border-radius: $border-radius;
        }
      }
    }
  }
  &-body {
    display: flex;
    flex-direction: column;

    .card-wrapper {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      // height: 40px;
      // background-color: red;
    }
  }
  .expand {
    flex-grow: 1;
    display: flex;
    overflow: hidden;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    // background-color: rgb(160, 132, 20);
  }
}
.sum-card-square {
  font-size: 14px;
  margin-left: 10px;

  background-color: $color-white;
  padding: 2px 2px;
  border-radius: $border-radius;
}
.order-column {
  // transition: all 0.3s;
  // position: relative;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  &-header {
    height: $header-height;

    z-index: 3;
    position: sticky;
    top: -0px;

    margin-bottom: 12px;
    .cover {
      @include InterBold;
      color: $color-white;
      background-color: $order-header-color;
      border-radius: $border-radius;
      height: $header-height;

      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding-left: 20px;
      .title {
        font-size: 18px;
      }
      .sum-card {
        font-size: 14px;
        margin-left: 10px;

        background-color: $color-white;
        padding: 4px 6px;
        border-radius: $border-radius;
        color: $color-black;
      }
    }
  }
  &-body {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .block {
    margin-bottom: 12px;
    &-wrapper {
      display: flex;
      flex-direction: column;
      border-radius: $border-radius;

      &:not(.queueOrderBlock, .processOrderBlock, .doneOrderBlock) {
        .block-body {
          height: 0px;
          max-height: 0px;
          overflow: hidden;
          margin-top: 0px;
        }
      }
      .block-body {
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      &.done {
        background-color: $order-done-block-color;
        .stage-sum {
          background-color: $order-done-block-color-solid;
        }
      }
      &.process {
        background-color: $order-process-block-color;
        .stage-sum {
          background-color: $order-process-block-color-solid;
        }
      }
      &.queue {
        background-color: $color-column-bg;
        .stage-sum {
          background-color: $order-header-color;
        }
      }
    }

    .icon {
      transition: transform 0.15s;
      &.queueOrderBlock,
      &.doneOrderBlock,
      &.processOrderBlock {
        transform: rotate(-180deg);
        transition: transform 0.15s;
      }
    }

    margin: 20px;

    .stage-name-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      // margin-bottom: 12px;
      @include InterBold;
      font-size: 16px;

      .row-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        .stage-sum {
          font-size: 14px;
          margin-left: 10px;

          padding: 4px 6px;
          border-radius: $border-radius;
          color: $color-white;
        }
      }
    }
  }
}
.sub-header {
  &-container {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    // width: 100%;
  }
  &-desc {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  width: 320px;
  display: flex;
  flex-direction: row;
  // justify-content: center;
  margin-right: 15px;
  // width: 3px;
}

.sub-department-column-header {
  display: flex;
  padding: 5px 0px 5px 20px;

  @include InterBold;
  .title {
    margin-right: 10px;
  }

  background-color: $color-column-header;
}
.loaded-wrapper {
  padding-top: 200px;
  width: 100%;
  height: 100%;
}

.flip-list-move {
  transition: transform 0.7s;
}
</style>
