<template>
  <Orders />
</template>

<script>
import Orders from '@/components/orders';
export default {
  name: 'OrdersPage',
  components: { Orders },
};
</script>
