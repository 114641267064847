<template>
  <APageLayout>
    <template #header>
      <div class="page-header">
        <div class="page-header__container">
          <div class="row">
            <div class="mr-40">Мобильное приложение</div>
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <div class="content-wrapper">
        <div class="qr-codes-wrapper">
          <div class="qrcode-col ml-20">
            <div class="title">Android</div>
            <div class="qr-code">
              <img :src="qrCodeAndroidLink" width="300" height="300" />
            </div>
            <div class="ml-40 a-row">
              или скачайте по ссылке

              <div class="ml-10 qrcodelink" @click="downloadApp(getAppLink)">{{ getAppLink }}</div>
            </div>
          </div>
          <div class="qrcode-col ml-20">
            <div class="title">iOS</div>
            <div class="qr-code">
              <img :src="qrCodeIosLink" width="300" height="300" />
            </div>
            <div class="ml-40 a-row">
              или скачайте по ссылке

              <div class="ml-10 qrcodelink" @click="downloadApp(iosLink)">{{ iosLink }}</div>
            </div>
          </div>
        </div>

        <div class="mt-30">
          <div class="a-row">Последняя версия приложения:</div>
          <div class="a-row version">
            Ver:&nbsp;
            <div class="number">{{ lastAppVersion?.ver }}</div>
          </div>
          <div class="a-row version">
            R_Ver:&nbsp;
            <div class="number">{{ lastAppVersion?.rVer }}</div>
            &nbsp;
            <ATooltip>
              <div>
                Если не совпадает "R_Ver" версия приложения, приложение считается сильно устаревшим.
              </div>
              <div>
                Не гарантирована его работоспособность и приложение больше нельзя обновлять
                автоматически "по воздуху".
              </div>
              <div>
                Пользователю нужно обязательно скачать новую версию приложения по ссылке выше, через
                QR-код или внутри приложения:
              </div>
              <div>
                'Профиль' -> 'Проверить наличение обновлений' -> и перейти по ссылке для скачивания
              </div>
            </ATooltip>
          </div>
        </div>
      </div>
    </template>
  </APageLayout>
</template>

<script>
import QRCode from 'qrcode';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Application',
  components: {},
  data() {
    return {
      iosLink: 'https://apps.apple.com/ru/app/lucky-erp/id6462783090',
      loading: true,

      qrCodeAndroidLink: null,
      qrCodeIosLink: null,
    };
  },
  computed: {
    ...mapState({
      lastAppVersion: (s) => s.mobile.lastAppVersion,
    }),
    getAppLink() {
      return `${process.env.VUE_APP_API_TARGET + 'api/mobile/application'}`;
    },
  },
  async created() {
    await this.bootstrap();
  },
  methods: {
    ...mapActions({
      getLastAppVersion: 'mobile/getLastAppVersion',
    }),
    async bootstrap() {
      try {
        this.qrCodeAndroidLink = await QRCode.toDataURL(this.getAppLink, {
          width: 300,
          errorCorrectionLevel: 'M',
        });
        this.qrCodeIosLink = await QRCode.toDataURL(this.iosLink, {
          width: 300,
          errorCorrectionLevel: 'M',
        });
        await this.getLastAppVersion();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    downloadApp(link) {
      return window.open(link, '_blank');
    },
  },
};
</script>
<style lang="scss" scoped>
.page-header {
  @include InterBold;
  font-size: 18px;
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 36px;
  position: relative;

  &__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
.qrcode-col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .qrcodelink {
    text-decoration: underline;
    cursor: pointer;
  }

  .title {
    @include InterBold;
    font-size: 20px;
    margin: 10px 0px;
  }
}
.content-wrapper {
  display: flex;
  flex-direction: column;

  .qr-codes-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }

  .version {
    font-size: 14px;
    @include InterBold;
    margin-left: 5px;
    .number {
      font-size: 16px;
      letter-spacing: 0.5px;
    }
  }
}
</style>
