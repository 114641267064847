<template>
  <div v-if="!loading" class="page-header-wrapper">
    <div class="page-header__name">
      <div class="page-header__name-title" :class="getOrderTitleClass">{{ pageOrder?.name }}</div>
      <div class="page-header__name-actions">
        <ADropdown position="left" class="ml-10 pointer">
          <template #activator>
            <div class="icon">
              <Fa icon="ellipsis-v" style="width: 25px; height: 25px" />
            </div>
          </template>
          <div class="a-column dropdown">
            <AButton
              v-if="!pageOrder?.details?.cardQuantity"
              small
              class="my-10"
              :disabled="!accessToChange"
              @click="createCardsHandler"
            >
              Создать карты
            </AButton>
            <router-link :to="{ path: `/cards/${pageOrder.guid}` }" class="no-link">
              <AButton small :disabled="!pageOrder?.cardsQuantity">
                Перейти на страницу карт
              </AButton>
            </router-link>
          </div>
        </ADropdown>
        <div
          class="header-btn icon"
          :class="{ disabled: !accessToChangeOrder }"
          @click="saveOrderHandler"
        >
          <Fa icon="save" style="width: 25px; height: 25px" />
        </div>
        <div
          class="header-btn icon mr-40"
          :class="{ disabled: !canResetOrder }"
          @click="canResetOrder ? confirmResetOrDeleteOrder() : null"
        >
          <Fa icon="redo" style="width: 25px; height: 25px" />
        </div>
        <div
          class="header-btn icon mr-15"
          :class="{ disabled: !canDeleteOrder }"
          @click="canDeleteOrder ? confirmResetOrDeleteOrder(true) : null"
        >
          <Fa icon="trash-alt" style="width: 25px; height: 25px" />
        </div>
      </div>
    </div>
    <div class="page-header__info-wrapper">
      <ALabel v-if="getManagerName" label="Менеджер" :value="getManagerName" white small />
      <ALabel
        v-if="pageOrder.createdAt"
        label="Дата создания"
        :value="formatedDate(pageOrder.createdAt)"
        white
        small
      />
      <ALabel
        v-if="pageOrder.deadline"
        label="Дата отгрузки"
        :value="formatedDate(pageOrder.deadline)"
        white
        small
      />
      <ALabel :label="`Бюджет ${getCommaAndCurrency}`" :value="getFullOrderPrice" white small />
      <ALabel
        v-if="pageOrder.paid"
        :label="`Оплачено ${getCommaAndCurrency}`"
        :value="formatSum(pageOrder.paid)"
        white
        small
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import ConfirmDialog from '@@/components/Confirm/index.vue';
import { dayjs } from '@/utils/date.js';
import { formatSum } from '@/utils/money.js';
import { CURRENCIES } from '@/constants/index';
import { Form } from '@/utils/validate/index.js';
import { cloneDeep } from '@/utils/lodash.js';
import { orderSchema } from '@/utils/validate/schema.js';
import { getOrderSum } from '@/utils/order.js';
import { getClassNameByTextLength } from '@/utils/text.js';
import accessMixin from '@/mixin/accessMixin.js';
import { ORDER_STATE } from '../../../constants';

export default {
  name: 'OrderHeader',
  mixins: [accessMixin],
  props: {
    isSelectedOrder: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      orderloading: true,
    };
  },
  computed: {
    ...mapState({
      newOrder: (s) => s.order.newOrder,
      selectedOrder: (s) => s.order.selectedOrder,
      selectedOrderLoading: (s) => s.order.selectedOrderLoading,
    }),
    pageOrder() {
      return this.isSelectedOrder ? this.selectedOrder : this.newOrder;
    },
    getFullOrderPrice() {
      return formatSum(getOrderSum(this.pageOrder));
    },
    getCommaAndCurrency() {
      if (this.pageOrder?.currency) {
        return `, ${CURRENCIES.find((el) => el.value == this.pageOrder.currency)?.sign}`;
      }
      return '';
    },
    getManagerName() {
      if (!this.pageOrder?.manager?.name) return;
      let surname = this.pageOrder.manager.surname?.slice(0, 1) || '';
      if (surname) {
        surname = surname + '.';
      }
      return `${this.pageOrder.manager.name} ${surname}`;
    },
    accessToChange() {
      return (
        this.isCanChangeOrder &&
        ![ORDER_STATE.TECH_DESIGN, ORDER_STATE.COMPLETED, ORDER_STATE.PRODUCTION].includes(
          this.pageOrder._old.state
        )
      );
    },
    accessToChangeOrder() {
      return this.isCanChangeOrder && this.pageOrder._old.state !== ORDER_STATE.COMPLETED;
    },
    canResetOrder() {
      return (
        this.isCanChangeOrder &&
        [ORDER_STATE.TECH_DESIGN, ORDER_STATE.PRODUCTION].includes(this.pageOrder._old.state)
      );
    },
    canDeleteOrder() {
      return (this.isAdmin || this.isMainManager) && this.pageOrder._old.state === ORDER_STATE.NEW;
    },
    globalLoading() {
      return this.orderloading || this.selectedOrderLoading;
    },
    getOrderTitleClass() {
      const text = this.pageOrder?.name;
      return getClassNameByTextLength(text);
    },
  },
  created() {
    this.formatSum = formatSum;
    this.ORDER_STATE = ORDER_STATE;
  },
  methods: {
    ...mapActions({
      saveOrder: 'order/saveOrder',
      patchOrder: 'order/patchOrder',
      patchOrderParams: 'order/patchOrderParams',
      createCards: 'card/createCards',
      resetOrDeleteOrder: 'order/resetOrDeleteOrder',
    }),
    ...mapMutations({
      setValidateErrors: 'order/SET_VALIDATE_ERRORS',
    }),
    async saveOrderHandler() {
      if (!this.accessToChangeOrder) return;
      const order = cloneDeep(this.pageOrder);
      const formValidation = new Form(order, orderSchema).validate();
      if (Object.values(formValidation.errors).length) {
        this.setValidateErrors({ selectedOrder: this.isSelectedOrder, ...formValidation.errors });
        console.log('Order form errorrs', formValidation.errors);
        this.$notifyError({ title: `Ошибка валидации` });
        return;
      }
      if ([ORDER_STATE.TECH_DESIGN, ORDER_STATE.PRODUCTION].includes(this.pageOrder._old.state)) {
        return this.updateOrderHandler();
      }
      try {
        await this.saveOrder({ order, selectedOrder: this.isSelectedOrder });
        this.setValidateErrors({ selectedOrder: this.isSelectedOrder });
        this.$notify({ title: `Сохранено` });
      } catch (err) {
        console.log(err);
      }
    },
    async updateOrderHandler() {
      try {
        const order = cloneDeep(this.pageOrder);
        await Promise.all([
          this.patchOrder({ order }),
          this.patchOrderParams({ orderId: order.guid, orderParams: order.orderParams }),
        ]);
        this.setValidateErrors({ selectedOrder: this.isSelectedOrder });
        this.$notify({ title: `Сохранено` });
      } catch (err) {
        console.log(err);
      }
    },
    async confirmResetOrDeleteOrder(needDelete = false) {
      this.$dialog.addDialog({
        component: ConfirmDialog,
        props: {
          title: needDelete ? `Удаление заказа` : `Сброс заказа до состояния "Новый"`,
          text: needDelete
            ? `Вы действительно хотите УДАЛИТЬ заказ БЕЗ ВОЗМОЖНОСТИ ВОССТАНОВЛЕНИЯ?`
            : `Это приведет к удалению всех карт и статистики сотрудников. Необходимо заново распечатать карты`,
          onConfirm: async (confirm) => {
            confirm.showModal = false;
            await this.resetOrDeleteOrder({ orderId: this.pageOrder.guid, needDelete });
            if (needDelete) {
              this.$router.push({ name: 'ordersPage' });
            }
          },
        },
      });
    },
    async createCardsHandler() {
      await this.createCards({ orderId: this.pageOrder.guid });
      this.$notify({ title: 'Карты созданы' });
      this.$router.push('/cards/' + this.pageOrder.guid);
    },
    formatedDate(date) {
      if (!date) return;
      return dayjs(date).format('DD.MM.YYYY HH:mm');
    },
  },
};
</script>
<style lang="scss" scoped>
.page-header-wrapper {
  display: flex;
  height: 100%;
  align-items: center;
  background-color: $color-white;

  z-index: 10;
  overflow-x: auto;
  @include scroll;
}
.page-header {
  @include InterBold;
  font-size: 18px;
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 36px;
}
.page-header__name {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 710px;
  border-right: 1px solid $color-bg-smoke;

  &-title {
    @include InterBold;
    font-size: 18px;
    height: 100%;
    margin-left: 36px;

    max-width: 560px;
    word-wrap: break-word;
    &.text-xl {
      font-size: 16px;
    }
    &.text-xxl {
      font-size: 14px;
    }
  }

  &-actions {
    display: flex;
    flex-direction: row-reverse;
    margin-right: 10px;

    .icon {
      &.disabled {
        opacity: 0.5;
      }
    }
  }
}
.header-btn:not(.disabled) {
  &:hover {
    cursor: pointer;
  }
  &:active {
    transform: scale(0.96);
  }
}
.page-header__info-wrapper {
  display: flex;
  margin-left: 10px;

  & > * {
    width: 140px;
    min-width: fit-content;
    margin-right: 15px;
  }
}

.dropdown {
  background-color: $color-bg-light-blue; //$smoke-light-blue;
  border-radius: 6px;
  padding: 10px;
}
</style>
