<template>
  <div v-if="currenctMonthStat" class="aggregate-row-wrapper">
    <div class="a-row">
      <div v-if="currenctMonthStat.allQuantityMonth" class="aggregate-row-item">
        <div class="col-centred">
          <div class="main-col">
            {{ currenctMonthStat.allQuantityMonth }}
          </div>
          <span class="secondary bold">{{ CURRENT_MONTH_LABEL }}</span>
        </div>
      </div>
      <div v-if="currenctMonthStat.noComplexityQuantityMonth" class="aggregate-row-item">
        <div class="col-centred">
          <div>
            {{ currenctMonthStat.noComplexityQuantityMonth }}
          </div>
          <span class="secondary">Без пош.</span>
        </div>
      </div>
      <div v-if="currenctMonthStat.easyQuantityMonth" class="aggregate-row-item">
        <div class="col-centred">
          <div>
            {{ currenctMonthStat.easyQuantityMonth }}
          </div>
          <span class="secondary bold">Лег.</span>
        </div>
      </div>
      <div v-if="currenctMonthStat.mediumQuantityMonth" class="aggregate-row-item">
        <div class="col-centred">
          <div>
            {{ currenctMonthStat.mediumQuantityMonth }}
          </div>
          <span class="secondary bold">Ср.</span>
        </div>
      </div>
      <div v-if="currenctMonthStat.hardQuantityMonth" class="aggregate-row-item">
        <div class="col-centred">
          <div>
            {{ currenctMonthStat.hardQuantityMonth }}
          </div>
          <span class="secondary bold">Сл.</span>
        </div>
      </div>
      <div v-if="currenctMonthStat.veryHardQuantityMonth" class="aggregate-row-item">
        <div class="col-centred">
          <div>
            {{ currenctMonthStat.veryHardQuantityMonth }}
          </div>
          <span class="secondary bold">Оч. сл.</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dayjs } from '@/utils/date';
import { capitalizeFirstLetter } from '@/utils/capitalizeFirstLetter';
export default {
  name: 'CurrentMonthStatBlock',
  props: {
    currenctMonthStat: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.CURRENT_MONTH_LABEL = capitalizeFirstLetter(dayjs().format('MMMM'));
  },
};
</script>
<style lang="scss" scoped>
.bold {
  @include InterSemibold;
}
.secondary {
  font-size: 11px;
  color: $color-gray;
}
.aggregate-row {
  &-wrapper {
    min-height: 50px;

    padding: 10px;
    border-radius: 4px;
  }
}
.aggregate-row-item {
  @include InterMedium;
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  padding: 0px 10px;
  &:not(:last-of-type) {
    border-right: 1px solid $color-bg-smoke;
  }
  .main-col {
    @include InterBold;
  }
}
.words-col {
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  width: min-content;
}
.col-centred {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.main-cell {
  @include InterBold;
}
</style>
