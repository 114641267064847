<template>
  <div>
    <APageAside
      :title="userId ? 'Редактирование сотрудника' : 'Добавление сотрудника'"
      wide
      @close="closeHandler"
    >
      <div v-if="userId || creatingUser">
        <UserBlock
          :user-id="userId"
          :creating-user="creatingUser"
          :default-phone="phone"
          :default-email="email"
          :creating-user-types="creatingUserTypes"
          @close="closeHandler"
          @update:user="$emit('update:user', $event)"
        />
      </div>
    </APageAside>
  </div>
</template>

<script>
import UserBlock from '@/components/userBlock/index.vue';
import { USER_TYPES } from '../../../constants';

export default {
  name: 'CreateEmployeeAsideBlock',
  components: {
    UserBlock,
  },
  props: {
    userId: {
      type: String,
      default: null,
    },
    creatingUser: {
      type: Boolean,
      default: false,
    },
    phone: {
      type: String,
      default: null,
    },
    email: {
      type: String,
      default: null,
    },
  },
  emits: ['close', 'update:user'],
  data() {
    return {
      creatingUserTypes: [USER_TYPES.EMPLOYEE, USER_TYPES.MAIN_EMPLOYEE],
    };
  },
  methods: {
    closeHandler() {
      this.$emit('close');
    },
  },
};
</script>
