import userApi from '@/api/userApi';

export default {
  async getCurrentUser({ commit }) {
    const { data } = await userApi.getCurrentUser();
    commit('SET_CURRENT_USER', data);
    return data;
  },

  async createUserToComponent(_, { user }) {
    const { data } = await userApi.createUser({ user });
    return data;
  },

  async changeUserToComponent(_, { user }) {
    const { data } = await userApi.changeUser({ user });
    return data;
  },

  async getUserToComponent(_, { userId }) {
    const { data } = await userApi.getUserById({ userId });
    return data;
  },

  async getAllManagers({ commit, state }, { force = false } = {}) {
    if (state.managers.length && !force) {
      return;
    }
    const { data } = await userApi.getAllManagers();
    commit('SET_ALL_MANAGERS', data);
    return data;
  },

  async getAllEmployeeToComponent(_, { userId }) {
    const { data } = await userApi.getEmployee({ userId });
    return data;
  },
  async getAllEmployees({ commit, state }, { force = false } = {}) {
    if (state.employees.length && !force) {
      return;
    }
    const { data } = await userApi.getAllEmployees();
    commit('SET_ALL_EMPLOYEES', data);
    return data;
  },

  async changeEmployee({ commit }, { user }) {
    const { data } = await userApi.changeEmployee({ user });
    commit('SET_EMPLOYEE', { employee: data, oldEmployee: user });
    return data;
  },
};
