<template>
  <div>
    <div v-if="!localLoading" class="selector" :class="{ show, disabled, row }">
      <IconCover class="icon-wrapper" :shadow="orderValue">
        <i class="fa fa-star icon" style="font-size: 14px" :class="btnIconClasses"></i>
      </IconCover>
      <div class="selector-content">
        <div v-for="item of items" :key="item || 'null'" @click="selectHandler(item)">
          <IconCover class="my-10 icon-wrapper" :shadow="btnWrapperClasses(item)">
            <i
              class="fa fa-star icon"
              style="font-size: 14px"
              :class="contentWrapperClasses(item)"
            ></i>
          </IconCover>
        </div>
      </div>
    </div>
    <div v-else class="loader">
      <ARingLoader centered />
    </div>
  </div>
</template>

<script>
const MODE = {
  CARD: 'card',
  ORDER: 'order',
};

import IconCover from '@/components/base/IconCover.vue';
import { URGENCY_TYPE } from '../../../constants';

export default {
  name: 'OrderUrgencyTypeSelector',
  components: {
    IconCover,
  },
  props: {
    cardValue: {
      type: [String, Number],
      default: null,
    },
    orderValue: {
      type: [String, Number],
      default: null,
    },
    position: {
      type: String,
      default: 'left',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: MODE.CARD,
    },
    row: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:cardValue', 'change', 'update:orderValue'],

  data() {
    return {
      data: this.updateFromProp(this.mode === MODE.CARD ? this.cardValue : this.orderValue),
      items: [...Object.values(URGENCY_TYPE), null],
      show: false,
      localLoading: false,
    };
  },
  computed: {
    btnIconClasses() {
      if (this.mode === MODE.CARD) {
        return { [this.cardValue]: this.cardValue };
      }
      return {};
    },
  },
  watch: {
    cardValue(newValue) {
      this.updateFromProp(newValue);
    },
    orderValue(newValue) {
      this.updateFromProp(newValue);
    },
    data() {
      if (this.mode === MODE.CARD) {
        this.$emit('update:cardValue', this.data);
        return;
      }
      this.$emit('update:orderValue', this.data);
    },
  },
  created() {
    this.MODE = MODE;
    this.updateFromProp(this.mode === MODE.CARD ? this.cardValue : this.orderValue);
  },

  methods: {
    btnWrapperClasses(item) {
      if (this.mode === MODE.CARD) {
        return null;
      }
      return item;
    },
    contentWrapperClasses(item) {
      if (this.mode === MODE.CARD) {
        return { [item]: item };
      }
      return {};
    },
    showMenu() {
      this.show = !this.show;
    },
    updateFromProp(value) {
      this.data = value;
      this.localLoading = false;
    },
    selectHandler(value) {
      if (this.data === value) {
        return;
      }
      this.data = value || null;
      this.show = false;
      this.localLoading = true;
      this.$emit('change', this.data);
    },
  },
};
</script>

<style lang="scss" scoped>
// .dropdown-btn:not(.disabled) {
//   .icon-wrapper {
//     cursor: pointer;
//   }
// }
// .dropdown-body {
//   // background-color: white;
//   background-color: $color-white-smoke;
//   padding: 1px 3px;
//   border-radius: 5px;

//   .icon-wrapper:active {
//     transform: scale(0.99);
//   }
// }
// .icon {
//   color: $color-light-gray;
// }
// .l1 {
//   color: $color-red;
// }
// .l2 {
//   color: $color-wanring;
// }
// .l3 {
//   color: $color-dodger-blue;
// }

// .loader {
//   width: 100%;
//   height: 100%;
//   background-color: red;
// }

.selector {
  position: relative;
  display: inline-block;
}
.selector-content {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 50;
  right: 22px;
  top: -55px;
  .selector.row & {
    right: -50px;
    top: -51px;
    gap: 5px;
  }
}
.selector:not(.disabled) {
  .icon-wrapper {
    cursor: pointer;
  }
}
.selector:not(.disabled):not(.row):hover .selector-content {
  display: block;
}
.selector.row:not(.disabled):hover .selector-content {
  display: flex;
  flex-direction: row;
}

.icon {
  color: $color-light-gray;
}
.l1 {
  color: $color-red;
}
.l2 {
  color: $color-wanring;
}
.l3 {
  color: $color-dodger-blue;
}
</style>
