<template>
  <div class="a-radio-buttons-group" :class="{ error, borderless, nowraptext }">
    <div class="a-radio-buttons-group-items-wrapper">
      <div
        v-for="(button, ind) in options"
        :key="`${ind}_${button[propToBind]}`"
        class="a-radio-buttons-group-item"
        :class="{ active: button[propToBind] == data }"
        @click="clickHandler(button)"
      >
        <div class="button">
          {{ button[selectLabel] }}
        </div>
      </div>
    </div>

    <span class="a-radio-buttons-group__error">
      <div class="a-radio-buttons-group__error-icon" />
      {{ error }}
    </span>
  </div>
</template>

<script>
export default {
  name: "ARadioButtonsGroup",
  props: {
    modelValue: {
      type: [String, Number],
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    selectLabel: {
      type: String,
      default: "name",
    },
    propToBind: {
      type: String,
      default: "value",
    },
    borderless: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: null,
    },
    nowraptext: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["input", "change", "update:modelValue"],
  data() {
    return {
      data: null,
    };
  },
  watch: {
    modelValue() {
      this.updateFromProp();
    },
    data: {
      handler: function () {
        this.$emit("input", this.data);
        this.$emit("change", this.data);
        this.$emit("update:modelValue", this.data);
      },
    },
  },
  beforeMount() {
    this.updateFromProp();
  },
  methods: {
    updateFromProp() {
      this.data = this.modelValue;
    },
    clickHandler(radio) {
      if (this.disabled) return;
      if (this.data == radio[this.propToBind]) {
        this.data = null;
        return;
      }
      this.data = radio[this.propToBind];
    },
  },
};
</script>

<style lang="scss" scoped>
.a-radio-buttons-group {
  display: flex;
  width: 100%;

  &-items-wrapper {
    display: flex;
    flex-direction: column;

    min-width: 100%;
  }
  &-item {
    display: flex;
    min-width: 100%;

    cursor: pointer;

    padding: 8px 10px 8px 12px;

    // :not(.a-radio-buttons-group.borderless) & > {
    //   border: 1px solid $color-bg-smoke;
    // }
    // transition: background-color 0.3s;

    &.active {
      background-color: $cornflower-blue;
      color: white;

      animation-duration: 0.25s;
      animation-name: slidein;
      // transition: background-color 0.3s;
    }

    &:first-child {
      border-radius: 8px 8px 0px 0px;
    }
    &:last-child {
      border-radius: 0px 0px 8px 8px;
    }

    .a-radio-buttons-group.borderless & {
      border-radius: 8px;
    }

    .button {
      min-width: 100%;
    }
  }
  &.nowraptext {
    .button {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

@keyframes slidein {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1);
  }

  30% {
    transform: scale(0.99);
  }
}

.a-radio-buttons-group__error {
  @include input-error(".a-radio-buttons-group");
}
</style>
