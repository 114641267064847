<template>
  <div class="page-aside__el" :class="{ half }">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'PageAsideCell',
  props: {
    half: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.page-aside__el {
  display: flex;
  align-items: flex-start;

  box-sizing: border-box;
  width: 100%;
  margin-bottom: 20px;

  @include items-row__el;

  &.half {
    width: 50%;
    max-width: 250px;
  }
}
</style>
