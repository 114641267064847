import cardApi from '../../../api/cardApi';
import orderApi from '../../../api/orderApi';

export default {
  async createCards(_, { orderId }) {
    await cardApi.createCards({ orderId });
  },

  async getCardsWithCardOrder({ commit }, { orderId }) {
    const [{ data: cards }, { data: cardOrder }] = await Promise.all([
      cardApi.getCards({ orderId }),
      orderApi.getOrderForCards({ orderId }),
    ]);
    commit('SET_ALL_CARDS', cards);
    commit('SET_CARD_ORDER', cardOrder);
  },
};
