<template>
  <ul class="a-badge-list" ref="scroll-container">
    <li
      v-for="(item, key) in items"
      :key="key"
      class="a-badge-list__label"
      @mousewheel.prevent="scrollX"
    >
      <ABadge
        :class="{ pointer: pointer }"
        :fontbase="fontbase"
        :uppercase="uppercase"
        :lowercase="lowercase"
      >
        {{ propToShow ? getPropData(item, propToShow) : item }}
      </ABadge>
    </li>
  </ul>
</template>

<script>
import ABadge from "../Badge/index.vue";

export default {
  name: "ABadgeList",
  components: {
    ABadge,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    propToShow: {
      type: String,
      default: null,
    },
    pointer: {
      type: Boolean,
      default: false,
    },
    fontbase: {
      type: Boolean,
      default: true,
    },
    uppercase: {
      type: Boolean,
      default: false,
    },
    lowercase: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getPropData(obj, key) {
      let data = obj;
      const keyArray = key.split(".");

      if (keyArray.length > 1) {
        for (let i = 0; i < keyArray.length; i++) {
          const propKey = keyArray[i];

          data = data[propKey];
        }
      } else {
        data = obj[key];
      }

      return data;
    },
    scrollX(e) {
      this.$refs["scroll-container"].scrollLeft += e.deltaY / 5;
    },
  },
};
</script>

<style lang="scss" scoped>
.a-badge-list {
  display: flex;

  max-width: 100%;

  overflow-y: hidden;
  overflow-x: auto;

  @include scroll;
  padding-bottom: 4px;

  padding: 0;
  margin: 0;

  list-style-type: none;

  &__counter {
    display: none;
  }

  &__label {
    display: inline-block;
    flex-shrink: 0;
    margin-right: 2px;
    margin-bottom: 2px;
  }
}
</style>
