<template>
  <div class="block-wrapper">
    <div v-if="localSet" class="set">
      <div v-if="localSet.deleted" class="set-alert-deleted">Комплект удален !</div>
      <div v-if="set" class="set-title-block mb-5">
        <div class="set-title-block__title">
          Комплект:&nbsp;
          <span class="a-bold"> {{ localSet.id }}. </span> &emsp;{{ localSet.name }}
        </div>
      </div>
      <div v-else class="set-title-block">
        <div class="set-title-block__title">Новый комплект</div>
      </div>
      <div class="set-details">
        <div class="set-details_block">
          <AInput
            v-model="localSet.name"
            :read-only="!accessToChange"
            label="Название"
            :error="getErrMess('name')"
          />
        </div>
        <ASpacer text="Состав комплекта" class="mb-15 mt-15" bold />
        <div>
          <div v-for="(itemSet, ind) of localSet.itemSets" :key="`${ind}_`" class="set-item-set">
            <div>
              <ASelect
                v-model="itemSet.itemId"
                :options="parseDictionary(assortmentDictionary.items)"
                disable-deleted
                select-label="name"
                prefix="id"
                :label="`Изделие${` ${itemSet.itemId || ''}`}`"
                :disabled="localSet.deleted"
                :read-only="isReadOnlyItemSet(itemSet)"
                :error="getErrMess(`itemSets[${ind}].itemId`)"
              />
            </div>
            <ASelect
              v-model="itemSet.fabricId"
              :options="
                creatingSet
                  ? allAvailableFabricsForSet(itemSet.itemId)
                  : parseDictionary(assortmentDictionary.fabrics)
              "
              disable-deleted
              select-label="name"
              label="Материал"
              prefix="id"
              :read-only="isReadOnlyItemSet(itemSet)"
              :disabled="localSet.deleted || !localSet.itemSets[ind].itemId"
              :error="getErrMess(`itemSets[${ind}].fabricId`)"
            />
            <AButton
              v-if="accessToChange && creatingSet"
              danger
              @click="deleteItemSetHandler({ ind })"
            >
              Удалить
            </AButton>
          </div>
          <div v-if="localSet.itemSets.length < 2" class="validation-error-items">
            Комплект не может включать меньше двух изделий
          </div>
          <AButton v-if="accessToChange && creatingSet" class="my-10" @click="addItemSet">
            {{ 'Добавить' }}
          </AButton>
          <div v-if="creatingSet" class="validation-error-items my-10">
            При создании комплекта важен порядок изделий в нем. В будущем невозможно будет изменить
            изделия, материалы или их расположение!
          </div>
        </div>
        <ASpacer text="Цена" class="mb-15 mt-15" bold />
        <div class="set-details_block">
          <AInput
            v-model="localSet.prices.RUR"
            type="number"
            label="RUR"
            :disabled="localSet.deleted"
            :read-only="!accessToChange"
            :error="getErrMess(`prices.RUR`)"
            :min="0"
          />
          <AInput
            v-model="localSet.prices.EUR"
            type="number"
            label="EUR"
            :disabled="localSet.deleted"
            :read-only="!accessToChange"
            :error="getErrMess(`prices.EUR`)"
            :min="0"
          />
        </div>
        <div class="set-details_block mt-15">
          <AButton
            v-if="accessToChange"
            success
            :disabled="!islocalSetModified"
            @click="saveSetHandler"
          >
            Сохранить
          </AButton>
          <AButton v-if="accessToChange" :disabled="!islocalSetModified" @click="updateFromProp()">
            Сбросить
          </AButton>
          <AButton
            v-if="isCanChangeAssortment && !creatingSet && !setLoading"
            :disabled="islocalSetModified"
            :danger="!localSet.deleted"
            :success="localSet.deleted"
            @click="deleteItemHandler(!localSet.deleted)"
          >
            {{ localSet.deleted ? 'Восстановить' : 'Удалить' }}
          </AButton>
        </div>
        <ALoader v-if="setLoading" centered green />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { parseDictionary } from '../../../../utils/dictionaryUtils.js';
import { cloneDeep, isEqual } from '../../../../utils/lodash.js';
import accessMixin from '../../../../mixin/accessMixin.js';
import { setBlockSchema } from '../../../../utils/validate/index.js';
import validateMixin from '../../../../mixin/validate.js';
import ConfirmDialog from '@@/components/Confirm/index.vue';

const newItemSetItem = {
  id: null,
  itemId: null,
  setId: null,
  fabricId: null,
  index: null,
  deleted: null,
  isNewItem: true,
};

const newSetItem = {
  id: null,
  name: null,
  desc: null,
  itemSets: [cloneDeep(newItemSetItem)],
  prices: {
    RUR: null,
    EUR: null,
  },
  deleted: null,
  isNewItem: true,
};

export default {
  name: 'SetBlock',
  mixins: [accessMixin, validateMixin],
  props: {
    set: {
      type: Object,
      default: null,
    },
    creatingSet: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  data() {
    return {
      setLoading: true,

      localSet: null,
    };
  },
  computed: {
    ...mapState({
      assortmentDictionary: (s) => s.dictionary.assortmentDictionary,
      availableAssortmentItemFabrics: (s) => s.dictionary.availableAssortment.itemFabrics,
    }),
    accessToChange() {
      return this.isCanChangeAssortment && !this.localSet.deleted && !this.setLoading; // TODO !this.set.deleted;
    },
    islocalSetModified() {
      return !isEqual(this.set, this.localSet);
    },
    allAvailableFabricsForSet() {
      return (itemId) => {
        const avaibleFabricsForItem =
          this.availableAssortmentItemFabrics.find((item) => item.itemId === itemId)?.fabrics || [];
        return this.parseDictionary(this.assortmentDictionary.fabrics).filter((fabric) =>
          avaibleFabricsForItem.includes(fabric.id)
        );
      };
    },
    isReadOnlyItemSet() {
      return (itemSet) => (itemSet.itemId && !itemSet.isNewItem) || !this.accessToChange;
    },
  },
  watch: {
    set: function (set) {
      this.updateFromProp(set);
    },
  },
  created() {
    this.updateFromProp();
    this.parseDictionary = parseDictionary;
  },
  beforeMount() {
    Promise.all([this.getAvailableAssortment({ key: 'itemFabrics' })]).then(
      () => (this.setLoading = false)
    );
  },
  methods: {
    ...mapActions({
      getAvailableAssortment: 'dictionary/getAvailableAssortment',
      changeSet: 'assortment/changeSet',
      createSet: 'assortment/createSet',
    }),

    async saveSet(deleteOrRestore) {
      const formValidation = this.$form(this.localSet, setBlockSchema);
      if (!formValidation.isValid) {
        this.$notifyError({ title: `Ошибка валидации формы` });
        return;
      }
      this.setLoading = true;
      try {
        const handler = this.creatingSet ? this.createSet : this.changeSet;
        await handler({ set: this.localSet });
        this.$emit('close');
        this.$notify({ title: 'Сохранено' });
      } catch (err) {
        console.log(err);
        if (deleteOrRestore) {
          this.localSet.deleted = !this.localSet.deleted;
        }
      } finally {
        this.setLoading = false;
      }
    },
    deleteItemHandler(needDeleted) {
      if (this.islocalSetModified) {
        this.$notifyError({
          title: `Сбросте (или сохраните) все изменения в комплекте, а затем удалите его`,
        });
        return;
      }
      this.$dialog.addDialog({
        component: ConfirmDialog,
        props: {
          title: needDeleted ? 'Удаление комплекта' : 'Восстановление комплекта',
          text: `Вы действительно хотите ${needDeleted ? 'удалить' : 'восстановить'} комплект?`,
          onConfirm: (confirm) => {
            confirm.showModal = false;
            this.localSet.deleted = needDeleted;
            this.saveSet(true);
          },
        },
      });
    },
    saveSetHandler() {
      this.$dialog.addDialog({
        component: ConfirmDialog,
        props: {
          title: this.creatingSet ? 'Создание комплекта' : 'Сохранение изменений',
          text: this.creatingSet
            ? 'Сохранить комплект? В будущем невозможно будет поменять состав изделий, материалов или их расположение!'
            : 'Применить выбранные изменения?',
          onConfirm: (confirm) => {
            confirm.showModal = false;
            this.saveSet();
          },
        },
      });
    },
    addItemSet() {
      this.localSet.itemSets.push(cloneDeep(newItemSetItem));
    },
    deleteItemSetHandler({ ind }) {
      this.localSet.itemSets.splice(ind, 1);
    },
    updateFromProp(set) {
      const value = this.creatingSet ? newSetItem : set || this.set;
      this.localSet = cloneDeep(value);
    },
  },
};
</script>
<style lang="scss" scoped>
.set {
  &-alert-deleted {
    background-color: $color-red;
    padding: 8px 0px;
    border-radius: 8px;
    text-align: center;
    margin-bottom: 10px;

    @include InterSemibold;
  }

  &-title-block {
    display: flex;
    flex-direction: column;

    &__title {
      @include InterSemibold;
      font-size: 16px;
    }
  }
  &-details {
    display: flex;
    flex-direction: column;
    .set-item-set {
      display: grid;
      grid-template-columns: 1fr 1fr 100px;
      grid-gap: 20px;
      margin-bottom: 10px;
      align-items: flex-end;
    }

    .set-details_block {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 36px;
      margin-bottom: 10px;
      align-items: flex-end;
    }
  }
}
.validation-error-items {
  color: $color-red;
  margin-left: 15px;
}
</style>
