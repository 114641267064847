<template>
  <div>
    <APageAside title="Добавление дизайнерского решения" wider @close="closeHandler">
      <div v-if="addSolution">
        <AddSolutionBlock @close="closeHandler" />
      </div>
    </APageAside>
  </div>
</template>

<script>
import AddSolutionBlock from './AddSolutionBlock.vue';
export default {
  name: 'DesignSolutionAsideBlock',
  components: { AddSolutionBlock },
  props: {
    addSolution: {
      type: Boolean,
      default: null,
    },
  },
  emits: ['close'],
  methods: {
    closeHandler() {
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped></style>
