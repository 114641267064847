import BaseService from './services/BaseService.js';

const BASE_PATH = '/analytics';

class AnalyticsApi extends BaseService {
  async getMonitorngDepartmentReport({ startDate, endDate }) {
    const data = await this.api.get(`${BASE_PATH}/get-monitoring-department-report`, {
      headers: { 'content-type': 'multipart/form-data' },
      responseType: 'blob',
      params: { startDate, endDate },
    });
    return data;
  }

  async getMonitoringPlanFactPackagingData({ startDate, endDate }) {
    const data = await this.api.get(`${BASE_PATH}/get-monitoring-plan-fact-packaging-data`, {
      params: { startDate, endDate },
    });
    return data;
  }
}
export default new AnalyticsApi();
