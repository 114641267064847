/**
 * Типы комнат сокетов
 * @enum {String}
 */
const SOCKET_ROOM = {
  DESIGN_SOLUTION_MESSAGES: 'designSolutionMessages',
  DESIGN_SOLUTION: 'designSolution',
};

export { SOCKET_ROOM };
