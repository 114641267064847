import { createStore } from 'vuex';

const files = require.context('./modules', true, /\.js$/);

const modules = files.keys().reduce((acc, folder) => {
  const module = folder.split('/')[1];
  const type = folder.split('/')[2].split('.')[0];
  acc[module] = Object.assign(acc[module] || {}, { [type]: files(folder).default });
  acc[module].namespaced = true;
  return acc;
}, {});

export default createStore({
  namespaced: true,
  modules,
});
