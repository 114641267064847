<template>
  <GDialog v-model="showModal" persistent content-class="a-modal-wrapper" max-width="1000">
    <div class="modal__content">
      <div class="modal__title">Удаление дизайнерского решения</div>
      <div class="modal__text">
        Удалять дизайнерское решение можно только в случае, если оно является дубликатом. Укажите
        основное диз.решение. В последствии у этой партии станет такой же дизайн.
      </div>
      <div v-if="!loading && data.length" class="my-10">
        <div>
          <div class="search-by-link a-row my-10">
            <div
              class="input-wrapper mr-10"
              :class="{
                error: !findLinkProduct && link,
                success: findLinkProduct && link,
              }"
            >
              <AInput v-model="link" />
            </div>
            <span> Найти по ссылке </span>
            <ATooltip
              qtitle="Нужно ввести ссылку страницы, того дизайнерского решения, чей дизайн нужно скопировать"
            />
          </div>
          <div class="row header">
            <div class="t-secondary">Изд.</div>
            <div class="t-secondary">Code</div>
            <div class="t-secondary">Цвет</div>
            <div class="t-secondary">Коммент</div>
            <div></div>
          </div>
          <div
            v-for="pr of data"
            :key="pr.guid"
            class="row"
            :class="{
              preselect: findLinkProduct?.guid === pr.guid,
              selected: originalDesignSolution?.guid === pr.guid,
            }"
          >
            <div>{{ pr.product.itemId }}</div>
            <div>{{ pr.product.article.code }}</div>
            <div>{{ pr.product.color }}</div>
            <div>{{ pr.product.comment }}</div>
            <div><AButton small @click="originalDesignSolution = pr">Выбрать</AButton></div>
          </div>
        </div>
      </div>
      <div v-else-if="!loading && !data.length" class="my-10 danger">
        Для данного продукта не найдено предпологаемых дубликатов. ( У продуктов должны быть
        одинаковые изделия и артиклов)
      </div>
      <div v-else-if="loading" class="a-row a-centred">
        <ALoader centred />
      </div>
      <div class="modal__actions">
        <AButton @click="showModal = false"> Закрыть </AButton>
        <AButton
          v-if="onConfirm"
          success
          :disabled="
            loading ||
            !data.length ||
            !originalDesignSolution ||
            (findLinkProduct && findLinkProduct.guid != originalDesignSolution.guid)
          "
          @click="confirmHandler"
        >
          Подтвердить
        </AButton>
      </div>
    </div>
  </GDialog>
</template>
<script>
import { GDialog } from 'gitart-vue-dialog';
import { mapActions, mapState } from 'vuex';
import { cloneDeep } from '../../../utils/lodash';

export default {
  name: 'DeleteDublicateModal',
  components: { GDialog },
  props: {
    modelValue: {
      type: Boolean,
      default: null,
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
    onConfirm: {
      type: Function,
      default: null,
    },
    designSolution: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      link: '',
      loading: true,
      data: [],
      originalDesignSolution: null,
    };
  },
  computed: {
    ...mapState({
      designSolutions: (s) => s.design.designSolutions,
    }),
    findLinkProduct() {
      const matches = this.link.match(
        /([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/gm
      );
      if (!matches?.length) return false;
      const guid = matches[0];
      return this.data.find((el) => el.guid === guid);
    },
    showModal: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
        if (!value && this.onClose) {
          return this.onClose(this);
        }
      },
    },
  },
  created() {
    this.initComponent();
  },
  methods: {
    ...mapActions({
      getDesignSolutions: 'design/getDesignSolutions',
    }),
    async initComponent() {
      this.loading = true;
      let qty = 0;
      for (const field in this.designSolutions) {
        qty += this.designSolutions[field].length;
      }
      if (!qty) {
        try {
          await this.getDesignSolutions();
        } catch (err) {
          console.log(err);
        } finally {
          this.loading = false;
        }
      }
      for (const field in this.designSolutions) {
        const block = this.designSolutions[field];
        const order = block.find((o) => o.guid === this.designSolution.orderId);
        if (!order) continue;
        order.products.forEach((product) => {
          if (
            product.guid != this.designSolution.guid &&
            product.product.itemId === this.designSolution.product.itemId &&
            product.product.article.id === this.designSolution.product.article.id
          ) {
            this.data.push(cloneDeep(product));
          }
        });
      }
      this.loading = false;
    },
    confirmHandler() {
      return this.onConfirm(this);
    },
  },
};
</script>
<style lang="scss" scoped>
.search-by-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .input-wrapper {
    border: 1px solid transparent;
    border-radius: 6px;
    &.error {
      border-color: $color-red;
    }
    &.success {
      border-color: $color-apple;
    }
  }
}
.row {
  display: flex;
  flex-direction: row;
  margin: 0px 5px 5px 0px;
  &.preselect {
    border-radius: 6px;
    border: 1px solid $color-apple;
  }
  &.selected {
    background-color: $color-apple;
  }
  border-radius: 6px;
  padding: 5px 0px;
  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    margin: 0px 10px;
  }
  &.header {
    justify-content: space-between;
  }
}

.modal {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px 30px;
  }
  &__title {
    @include InterSemibold;
    font-size: 16px;
    padding: 5px 15px 20px;
  }
  &__text {
    margin-top: 5px;
  }
  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    width: 100%;
    margin-top: 15px;
    // background-color: red;
  }
}
.danger {
  color: $color-red;
}
</style>
<style lang="scss">
.a-modal-wrapper {
  width: fit-content;
}
</style>
