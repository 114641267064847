export default {
  SET_ALL_DEPARTMENTS(state, data) {
    state.departments = data;
  },

  SET_DEPARTMENT(state, { department, oldDepartment }) {
    if (!department) {
      const index = state.departments.findIndex((e) => e.id === oldDepartment.id);
      state.departments.splice(index, 1);
      return;
    }
    const index = state.departments.findIndex((e) => e.id === department.id);
    if (index === -1) {
      state.departments = [department, ...state.departments];
    } else {
      state.departments.splice(index, 1, department);
    }
  },

  SET_ALL_PRODUCTION__LINES(state, data) {
    state.productionLines = data;
  },

  SET_PRODUCTION_LINE(state, { productionLine, oldProductionLine }) {
    if (!productionLine) {
      const index = state.productionLines.findIndex((e) => e.id === oldProductionLine.id);
      state.productionLines.splice(index, 1);
      return;
    }
    const index = state.productionLines.findIndex((e) => e.id === productionLine.id);
    if (index === -1) {
      state.productionLines = [productionLine, ...state.productionLines];
    } else {
      state.productionLines.splice(index, 1, productionLine);
    }
  },
};
