<template>
  <div>
    <APageAside :title="getTitle" wide @close="closeHandler">
      <div v-if="selectedItem || creatingItem || itemProductionSetting">
        <ItemBlock
          v-if="(selectedItem || creatingItem) && !itemProductionSetting"
          :item="selectedItem"
          :creating-item="creatingItem"
          @close="closeHandler"
          @itemProductionSetting="itemProductionSetting = $event"
        />
        <ItemProductionControllerBlock
          v-if="itemProductionSetting"
          :item="itemProductionSetting"
          @close="closeHandler"
        />
      </div>
    </APageAside>
  </div>
</template>

<script>
import ItemBlock from '@/components/assortment/itemAsideBlock/components/ItemBlock.vue';
import ItemProductionControllerBlock from './components/ItemProductionControllerBlock.vue';

export default {
  name: 'ItemAsideBlock',
  components: {
    ItemBlock,
    ItemProductionControllerBlock,
  },
  props: {
    selectedItem: {
      type: Object,
      default: null,
    },
    creatingItem: {
      type: Boolean,
      default: false,
    },
    productionSetting: {
      type: Object,
      default: null,
    },
  },
  emits: ['close'],
  data() {
    return {
      showModal: false,
      itemProductionSetting: null,
    };
  },
  computed: {
    getTitle() {
      if (this.itemProductionSetting) {
        return 'Редактирование параметров производства';
      }
      return this.selectedItem ? 'Просмотр изделия' : 'Создание изделия';
    },
  },
  watch: {
    productionSetting() {
      this.itemProductionSetting = this.productionSetting;
    },
  },
  methods: {
    closeHandler() {
      if (this.itemProductionSetting) {
        if (this.selectedItem || this.creatingItem) {
          this.itemProductionSetting = null;
          return;
        }
        this.itemProductionSetting = null;
      }
      this.$emit('close');
      return;
    },
  },
};
</script>
<style lang="scss" scoped>
.confirm-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
</style>
