import { cloneDeep } from '@/utils/lodash';
import { FIELD_TO_MONITORING_CARD } from '../../../constants';
import { patchOrderParams, pathOrder } from '../../../utils/order';

export default {
  SET_ALL_CARDS(state, data) {
    state.cards = data;
  },

  SET_ALL_ORDERS(state, data) {
    state.orders = data;
  },

  DELETE_MONITORING_ORDER(state, data) {
    for (let i = 0; i < state.orders.length; i++) {
      const order = state.orders[i];
      if (order.guid === data.guid) {
        state.orders.splice(i, 1);
      }
    }
  },

  PATCH_MONITORING_ORDER(state, data) {
    for (const order of state.orders) {
      if (order.guid === data.guid) {
        pathOrder(order, data);
        return;
      }
    }
  },

  PATCH_MONITORING_ORDER_PARAMS(state, data) {
    for (const order of state.orders) {
      if (order.guid === data.orderId) {
        patchOrderParams(order.orderParams, data.changedOrderParams);
        return;
      }
    }
  },

  ADD_OR_REPLACE_MONITORING_ORDER(state, data) {
    const index = state.orders.findIndex((c) => c.guid === data.guid);
    if (index > -1) {
      state.orders.splice(index, 1, data);
      return;
    }
    state.orders.push(data);
  },

  PATCH_MONITORING_CARD(state, data) {
    for (const order of state.orders) {
      if (order.guid === data.orderId) {
        if (data.deleted) {
          const index = order.cards.findIndex((c) => c.guid === data.guid);
          order.cards.splice(index, 1);
          return;
        }
        for (const card of order.cards) {
          if (card.guid === data.guid) {
            for (const field of FIELD_TO_MONITORING_CARD) {
              if (Object.hasOwnProperty.call(data, field)) {
                card[field] = data[field];
              }
            }
            return;
          }
        }
        return;
      }
    }
  },

  SET_MONITORING_CARD_DETAILS(state, { data, card }) {
    state.cardDetails = cloneDeep({
      card,
      ...data,
    });
  },
};
