const setFields = [
  { key: 'id', label: 'Id', sortable: true, class: 'id' },
  { key: 'name', label: 'Название комплекта', sortable: true, class: 'name' },
  { key: 'fabrics', label: 'Материалы', class: 'fabrics' },
  { key: 'composition', label: 'Состав комплекта', class: 'composition' },
];

const itemFields = [
  { key: 'id', label: 'Id', sortable: true, class: 'id' },
  { key: 'name', label: 'Название Изд', sortable: true },
  { key: 'articleCode', label: 'Код артикула', class: 'article' },
  { key: 'article', label: 'Артикул' },
  { key: 'category', label: 'Категория', class: 'equal-column' },
  { key: 'productType', label: 'Тип', sortable: true, class: 'equal-column' },
  { key: 'growType', label: 'Вид(детс/Взр)', sortable: true, class: 'equal-column' },
  { key: 'actions', label: '', class: 'equal-column' },
];

const categoryFields = [
  { key: 'id', label: 'Id', sortable: true, class: 'id' },
  { key: 'name', label: 'Название категории', sortable: true },
];

const fabricFields = [
  { key: 'id', label: 'Id', sortable: true, class: 'id' },
  { key: 'name', label: 'Название материала', sortable: true },
];

const sexFields = [
  { key: 'id', label: 'Id', sortable: true, class: 'id' },
  { key: 'name', label: 'Название пола', sortable: true },
];

const growTypeFields = [
  { key: 'id', label: 'Id', sortable: true, class: 'id' },
  { key: 'name', label: 'Название типа размера', sortable: true },
];

const productTypeFields = [
  { key: 'id', label: 'Id', sortable: true, class: 'id' },
  { key: 'name', label: 'Название типа продукта', sortable: true },
];

const articleFields = [
  { key: 'id', label: 'Id', sortable: true, class: 'id' },
  { key: 'mockup.plain', label: 'Раск.', sortable: true, class: 'plainMockup' },
  { key: 'name', label: 'Название артикула', sortable: true },
  { key: 'code', label: 'Код', sortable: true },
  { key: 'desc', label: 'Описание', sortable: true },
  { key: 'techComment', label: 'Техн. комментарий', sortable: true },
];

const BASE_FIELD_DICTIONARY = [
  {
    value: 'fabric',
    component: 'Fabric',
    title: 'материала (ткани)',
    name: 'материал',
  },
  {
    value: 'article',
    component: 'Article',
    title: 'артикула',
    name: 'артикула',
  },
  {
    value: 'category',
    component: 'Category',
    title: 'категории',
    name: 'категорию',
  },
  {
    value: 'growType',
    component: 'GrowType',
    title: 'типа размера',
    name: 'тип размера',
  },
  {
    value: 'productType',
    component: 'ProductType',
    title: 'типа',
    name: 'тип',
  },
  {
    value: 'sex',
    component: 'Sex',
    title: 'пола',
    name: 'пол',
  },
];

export {
  setFields,
  itemFields,
  categoryFields,
  fabricFields,
  sexFields,
  growTypeFields,
  productTypeFields,
  articleFields,
  BASE_FIELD_DICTIONARY,
};
