<template>
  <div class="container">
    <div class="wrapper">
      <QuillEditor
        v-model:content="specificationModel"
        :modules="modules"
        :toolbar="modules.toolbar"
        :options="modules.options"
      />
      <div class="images__list my-10">
        <div v-for="(sha2, ind) of files" :key="`${sha2}_${ind}`" class="images__wrapper">
          <img :src="getLink(sha2)" alt="imageUrl" />
          <div
            v-if="isManager || isMainManager || isAdmin"
            class="remove"
            @click="loading ? null : removeImage(sha2, ind)"
          >
            <fa class="icon" style="width: 25px; height: 25px" icon="plus-circle" />
          </div>
        </div>
      </div>
      <AFileUpload
        v-if="isManager || isMainManager || isAdmin"
        v-model:files="otherFiles"
        :upload-files="uploadFilesHandler"
        :start-upload="uploadOtherImages"
        placeholder="Перетащите файлы для загрузки"
        position="left"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import accessMixin from '@/mixin/accessMixin.js';
import BlotFormatter from 'quill-blot-formatter/dist/BlotFormatter';
import { getLink } from '@/utils/getStorageLink.js';
import { dataUrlToFile } from '@/utils/dataUrlToFile.js';

export default {
  name: 'DesignSolutionSpecification',
  mixins: [accessMixin],
  props: {
    specification: {
      type: Object,
      default: () => ({}),
    },
    files: {
      type: Array,
      default: () => [],
    },
    designSolutionId: {
      type: String,
      default: null,
    },
    startUpload: {
      type: [Boolean, String],
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:specification', 'update:files', 'update:startUpload'],
  data() {
    return {
      uploadComponent: null,
      uploadOtherImages: false,
      otherFiles: [],
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote'],
          [{ header: 1 }, { header: 2 }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ indent: '-1' }, { indent: '+1' }],
          [{ header: [false, 1, 2, 3, 4, 5, 6] }],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          ['clean'],
          ['link', 'image'],
        ],
        BlotFormatter,
        name: 'blotFormatter',
        module: BlotFormatter,
        options: {
          placeholder: 'Техническое задание...',
          readOnly: false,
        },
      },
    };
  },
  computed: {
    specificationModel: {
      get() {
        return this.specification;
      },
      set(val) {
        this.$emit('update:specification', val);
      },
    },
    filesModel: {
      get() {
        return this.files;
      },
      set(val) {
        this.$emit('update:files', val);
      },
    },
  },
  watch: {
    showAllOrders() {
      this.debounceSearchOrders();
    },
    'startUpload': {
      handler: async function (value) {
        if (!value || value === 'uploaded') return;
        await this.downloadImagesInText(this.specificationModel);
      },
      deep: true,
    },
    'uploadComponent.uploaded': {
      handler: function (value) {
        if (!value) return;
        this.$emit('update:startUpload', 'uploaded');
      },
      deep: true,
    },
  },
  created() {
    this.getLink = getLink;
    this.modules.options.readOnly = !this.isManager && !this.isMainManager && !this.isAdmin;
  },
  methods: {
    ...mapActions({
      uploadImage: 'upload/uploadImage',
    }),
    removeImage(sha2, ind) {
      if (sha2 === this.filesModel[ind]) {
        this.filesModel.splice(ind, 1);
      }
    },
    async downloadImagesInText(specification) {
      // if (!specification?.ops?.length) return;
      for (const item of specification?.ops || []) {
        if (!item.insert?.image) continue;
        if (!String(item.insert.image).startsWith('data:')) continue;
        const file = await dataUrlToFile(item.insert.image);
        const { sha2 } = await this.uploadImage({
          entityId: this.designSolutionId,
          file: file,
          key: 'design-sulution-specification-text-img',
        });
        item.insert.image = getLink(sha2);
      }
      if (this.otherFiles.length) {
        this.uploadOtherImages = true;
      } else {
        this.$emit('update:startUpload', 'uploaded');
      }
    },
    async uploadFilesHandler(file, component) {
      this.uploadComponent = component;
      const { sha2 } = await this.uploadImage({
        entityId: this.designSolutionId,
        file: file.file,
        key: 'design-sulution-specification-img',
      });
      this.filesModel = [...this.filesModel, sha2];
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  max-width: 850px;
  max-height: 100vh;
}
.wrapper {
  max-height: calc(100vh - 250px);
  @include scroll-wider;
  overflow-y: auto;
}
.images {
  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  &__wrapper {
    position: relative;
    display: flex;
    margin: 0px 5px;

    img {
      display: block;
      width: 100%;
      max-height: 120px;
      width: auto;
      height: auto;
      object-fit: cover;
    }
    .remove {
      opacity: 0;
      position: absolute;
      top: calc(50% - 20px);
      right: calc(50% - 12px);
      color: $color-red;
      transform: rotate(45deg);
      cursor: pointer;
    }
    &:hover {
      .remove {
        opacity: 1;
      }
    }
  }
}
</style>
<style lang="scss">
.ql-container,
.ql-toolbar,
.ql-editor {
  max-width: 800px;
}
</style>
