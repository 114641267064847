<template>
  <div>
    <div class="upload-images">
      <div class="mockup mb-20">
        <div>
          <AInputFile v-model="fileToUpload.front" max="5" greyish :formats="mockupFormats">
            <div class="mockup-content ml-10 pt-5">Загрузить мокап - перед</div>
            <template #icon>
              <Fa
                name="icon"
                icon="file-image"
                class="icon faicon"
                style="width: 30px; height: 30px"
              />
            </template>
            <img
              v-if="fileToUpload.front"
              class="image-file"
              width="90px"
              height="110px"
              :src="fileToUpload.front.url"
              alt="мокап перед"
            />
          </AInputFile>
          <div v-if="formErrors[`mockup.front`]" class="validation-error-items">
            {{ formErrors[`mockup.front`] }}
          </div>
        </div>

        <div>
          <AInputFile v-model="fileToUpload.back" max="5" greyish :formats="mockupFormats">
            <div class="mockup-content ml-10 pt-5">Загрузить мокап - спинку (Не обязательно)</div>
            <template #icon>
              <Fa
                name="icon"
                icon="file-image"
                class="icon faicon"
                style="width: 30px; height: 30px"
              />
            </template>
            <img
              v-if="fileToUpload.back"
              class="image-file"
              width="90px"
              height="110px"
              :src="fileToUpload.back.url"
              alt="мокап спинка"
            />
          </AInputFile>
          <div v-if="formErrors[`mockup.back`]" class="validation-error-items">
            {{ formErrors[`mockup.back`] }}
          </div>
        </div>
      </div>
      <div class="mockup">
        <div>
          <AInputFile v-model="fileToUpload.plain" max="5" greyish :formats="mockupFormats">
            <div class="mockup-content ml-10 pt-5">Загрузить раскладку (Не обязательно)</div>
            <template #icon>
              <Fa
                name="icon"
                icon="file-image"
                class="icon faicon"
                style="width: 30px; height: 30px"
              />
            </template>
            <img
              v-if="fileToUpload.plain"
              class="image-file"
              width="90px"
              height="110px"
              :src="fileToUpload.plain.url"
              alt="мокап раскладка"
            />
          </AInputFile>
          <div v-if="formErrors[`mockup.plain`]" class="validation-error-items">
            {{ formErrors[`mockup.plain`] }}
          </div>
        </div>
      </div>
    </div>
    <div class="mt-20">
      <AButton :disabled="createSolutionLoading" @click="createSolution">
        Создать дизайнерское решение
      </AButton>
    </div>
    <div v-if="createSolutionLoading" class="a-row center">
      <ALoader centred />
    </div>
  </div>
</template>

<script>
import accessMixin from '@/mixin/accessMixin.js';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'AddSolutionBlock',
  mixins: [accessMixin],
  emits: ['close'],
  data() {
    return {
      mockupFormats: ['png', 'jpg', 'jpeg'],
      formErrors: {},
      fileToUpload: {
        back: null,
        front: null,
        plain: null,
      },
      createSolutionLoading: false,
      message: {
        mockup: {
          back: null,
          front: null,
          plain: null,
        },
      },
    };
  },
  computed: {
    ...mapState({
      designSolution: (s) => s.design.selectedDesignSolution,
    }),
  },
  methods: {
    ...mapActions({
      sendDesignSolutionMessage: 'design/sendDesignSolutionMessage',
      uploadArticle: 'upload/uploadArticle',
    }),
    closeHandler() {
      this.$emit('close');
    },
    async createSolution() {
      if (!this.fileToUpload.front) {
        this.formErrors[`mockup.front`] = 'Обязательно';
        return;
      }
      this.createSolutionLoading = true;

      try {
        for (const key in this.fileToUpload) {
          const file = this.fileToUpload[key];
          if (!file) continue;
          const { sha2 } = await this.uploadArticle({
            file,
            entityId: this.designSolution.guid,
            key: `design-sulution-mockup-${file}`,
          });
          this.message.mockup[key] = sha2;
        }

        await this.sendDesignSolutionMessage({
          message: this.message,
          designSolutionId: this.designSolution.guid,
        });
        this.$emit('close');
      } catch (err) {
        this.$notifyError({ title: 'Ошибка при создании решения' });
        console.log(err);
      } finally {
        this.createSolutionLoading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.center {
  justify-content: center;
}
.mockup {
  display: flex;
  flex-direction: row;
  grid-gap: 20px;

  img {
    width: 100%;
    height: 100%;
    max-width: 250px;
    max-height: 400px;
  }

  div {
    width: 100%;
  }
}

.validation-error-items {
  color: $color-red;
  margin-left: 15px;
}
</style>
