<template>
  <APageBlock class="mb-20" toggle>
    <template #header>План / Факт (производственная и мониторинг упаковка)</template>
    <div class="content">
      <div class="select-interval-cover">
        <div class="interval-picker">
          <ADatePicker
            mode="date"
            range
            format="YYYY-MM-DD"
            label="Интервал"
            fixed-popover
            :model-value="[startDate, endDate]"
            :readonly="globalLoading"
            @change="setReportRange"
          />
        </div>
        <AButton
          v-if="!globalLoading"
          :disabled="!startDate || !endDate"
          class="mb-10 mt-20 ml-20"
          @click="getMonitoringPlanFactPackagingDataHandler"
        >
          Показать
        </AButton>
      </div>

      <div>
        <div class="monitoring-plan-fact-chart">
          <AStackedBarChart
            v-if="accPlan"
            title="План"
            :labels="xAxis"
            :datasets="accPlan.datasets"
            :line-dataset="accPlan.lineDataset"
          />
        </div>

        <div class="monitoring-plan-fact-chart">
          <AStackedBarChart
            v-if="planAbs"
            title="Абсолютные значения План"
            :labels="xAxis"
            :datasets="planAbs.datasets"
            :line-dataset="planAbs.lineDataset"
          />
        </div>
        <div class="monitoring-plan-fact-chart">
          <AStackedBarChart
            v-if="planAbsWithKoef"
            title="С коэф. сложности План"
            :labels="xAxis"
            :datasets="planAbsWithKoef.datasets"
            :line-dataset="planAbsWithKoef.lineDataset"
          />
        </div>
      </div>
      <div>
        <div v-if="globalLoading">
          <ALoader centered />
        </div>
      </div>
    </div>
  </APageBlock>
</template>

<script>
import { mapActions } from 'vuex';
import { getDateOffset } from '../../../utils/date';
import { useMonitoringPlanFactPackaging } from '@/use/analytics/useMonitoringPlanFactPackaging';

export default {
  name: 'MonitoringPlanFactPackagingBlock',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { formatDataForCharts } = useMonitoringPlanFactPackaging();

    return {
      formatDataForCharts,
    };
  },
  data() {
    return {
      localLoading: false,

      startDate: getDateOffset(new Date(), -1, 'YYYY-MM-DD'),
      endDate: getDateOffset(new Date(), 15, 'YYYY-MM-DD'),

      xAxis: null,
      planAbs: null,
      planAbsWithKoef: null,
      accPlan: null,
    };
  },
  computed: {
    globalLoading() {
      return this.loading || this.localLoading;
    },
  },
  async created() {
    await this.bootstrap();
  },
  methods: {
    ...mapActions({
      getMonitoringPlanFactPackagingData: 'analytics/getMonitoringPlanFactPackagingData',
    }),
    setReportRange(data) {
      [this.startDate, this.endDate] = data || [null, null];
    },
    async getMonitoringPlanFactPackagingDataHandler() {
      this.localLoading = true;
      try {
        const data = await this.getMonitoringPlanFactPackagingData({
          startDate: this.startDate,
          endDate: this.endDate,
        });
        const result = this.formatDataForCharts(data);
        this.xAxis = result.xAxis;
        this.planAbs = result.planAbs;
        this.planAbsWithKoef = result.planAbsWithKoef;
        this.accPlan = result.accPlan;
      } catch (error) {
        console.log(error);
      } finally {
        this.localLoading = false;
      }
    },
    async bootstrap() {
      try {
        await this.getMonitoringPlanFactPackagingDataHandler();
      } catch (error) {
        console.log(error);
      } finally {
        this.localLoading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.interval-picker {
  max-width: 250px;
}
.monitoring-plan-fact-chart {
  position: relative;
  margin: auto;
  height: 40vh;
  width: 90vw;
  margin-bottom: 40px;
}
.select-interval-cover {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
</style>
