export function parseDictionary(dictionary = {}) {
  return Object.values(dictionary);
}

export function prepareDictionary(arr = [], key = 'id') {
  return arr.reduce((acc, curr) => {
    acc[curr[key]] = curr;
    return acc;
  }, {});
}
