import { computed } from 'vue';
import { useStore } from 'vuex';
import { ORDER_PARAM_VALUE_TYPE } from '../../constants';

export function useOrderParamValue(isSelectedOrder) {
  const store = useStore();
  const orderParams = computed(
    () => store.state.order[isSelectedOrder ? 'selectedOrder' : 'newOrder'].orderParams
  );

  const parseEntityId = (entityId) =>
    entityId && !Number.isNaN(parseInt(entityId)) ? parseInt(entityId) : entityId;

  const getValueFromOrderParamByType = ({ orderParamsType, orderParams }) => {
    if (ORDER_PARAM_VALUE_TYPE.SIMPLE_VALUE.includes(orderParamsType)) {
      return orderParams[orderParamsType]?.[0]?.data?.value || null;
    }
    if (ORDER_PARAM_VALUE_TYPE.SINGLE_RELATION.includes(orderParamsType)) {
      const entityId = orderParams[orderParamsType]?.[0]?.entityId;
      return parseEntityId(entityId) || null;
    }
    if (ORDER_PARAM_VALUE_TYPE.MULTIPLE_RELATIONS.includes(orderParamsType)) {
      const values = orderParams[orderParamsType];
      if (!values?.length) {
        return [];
      }
      return values.map((p) => parseEntityId(p.entityId));
    }
    return orderParams[orderParamsType]?.[0]?.data?.value || null;
  };

  const orderPramModelFn = ({ orderParamsType }) => {
    return computed({
      get: () => {
        return getValueFromOrderParamByType({ orderParamsType, orderParams: orderParams.value });
      },
      set: (value) => {
        store.commit('order/SET_ORDER_PARAMS', {
          value,
          orderParamsType,
          selectedOrder: isSelectedOrder,
        });
      },
    });
  };

  return { getValueFromOrderParamByType, orderPramModelFn };
}
