<template>
  <div class="client-tab">
    <div v-if="isSelectedOrder">
      <div class="a-row mb-10">
        <AInput
          v-maska="'+7 (###) ###-##-##'"
          :model-value="selectedOrder.user.contacts.phone"
          label="Телефон"
          class="mr-20"
          read-only
        />
        <AInput :model-value="selectedOrder.user.contacts.email" label="Почта" read-only />
      </div>
      <div class="a-row mb-10">
        <AInput label="Фамилия" class="mr-10" :model-value="selectedOrder.user.surname" read-only />
        <AInput label="Имя" class="mr-10" :model-value="selectedOrder.user.name" read-only />
        <AInput label="Отчество" :model-value="selectedOrder.user.patronymic" read-only />
      </div>
      <div class="a-row currency">
        <ASelect
          :model-value="selectedOrder.user.currency"
          :options="CURRENCIES"
          label="Валюта клиента"
          track-key="value"
          select-label="name"
          read-only
        />
      </div>
      <div v-if="pageOrder?.user?.guid && accessToChangeUser" class="a-row mt-20 right">
        <AButton @click="changeUserId = pageOrder?.user?.guid"> Изменить пользователя </AButton>
      </div>
    </div>

    <div v-else>
      <ASpacer text="Поиск клиента" text-default class="mb-10" />
      <div class="a-row mb-10">
        <AInput
          v-if="phone || (!phone && !email)"
          v-model="phone"
          v-maska="'+7 (###) ###-##-##'"
          label="Телефон"
          class="mr-20"
          :read-only="!!email || !accessToChange"
          :error="getOrderErrMess('clientId')"
        />
        <AInput
          v-else
          v-maska="'+7 (###) ###-##-##'"
          :model-value="orderInfo.user?.contacts?.phone"
          label="Телефон"
          class="mr-20"
          read-only
        />

        <AInput
          v-if="email || (!phone && !email)"
          v-model="email"
          label="Почта"
          placeholder="Поиск по почте"
          :read-only="!!phone || !accessToChange"
        />
        <AInput v-else :model-value="orderInfo.user?.contacts?.email" label="Почта" read-only />
      </div>

      <div v-if="orderInfo.user" class="a-row mb-10">
        <AInput label="Фамилия" class="mr-10" :model-value="orderInfo.user.surname" read-only />
        <AInput label="Имя" class="mr-10" :model-value="orderInfo.user.name" read-only />
        <AInput label="Отчество" :model-value="orderInfo.user.patronymic" read-only />
      </div>
      <div v-if="orderInfo.user" class="a-row currency">
        <ASelect
          :model-value="orderInfo.user.currency"
          :options="CURRENCIES"
          label="Валюта клиента"
          track-key="value"
          select-label="name"
          read-only
        />
      </div>
      <div v-if="!orderInfo.user && accessToChange" class="a-row mt-20">
        <AButton @click="creatingUser = true"> Создать пользователя </AButton>
      </div>
      <div v-if="orderInfo?.user?.guid && accessToChangeUser" class="a-row right">
        <AButton @click="changeUserId = orderInfo?.user?.guid"> Изменить пользователя </AButton>
      </div>
    </div>

    <div v-if="globalLoading" class="a-row">
      <ALoader centered />
    </div>
  </div>
  <CreateUserAsideBlock
    :creating-user="creatingUser"
    :phone="phone"
    :email="email"
    :user-id="changeUserId"
    @close="closeAsidePageHandler"
    @update:user="updateUserHandler"
  />
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { debounce } from '@/utils/lodash';
import { CURRENCIES } from '@/constants/index';
import CreateUserAsideBlock from './CreateUserAsideBlock.vue';
import accessMixin from '@/mixin/accessMixin.js';
import validateOrder from '@/mixin/validateOrder.js';

export default {
  name: 'OrderInfoClientBlock',
  components: {
    CreateUserAsideBlock,
  },
  mixins: [accessMixin, validateOrder],
  props: {
    isSelectedOrder: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      phone: null,
      email: null,
      findClientLoading: false,

      creatingUser: false,
      changeUserId: null,
    };
  },
  computed: {
    ...mapState({
      selectedOrder: (s) => s.order.selectedOrder,
      selectedOrderInfo: (s) => s.order.selectedOrderInfo,
      newOrderInfo: (s) => s.order.newOrderInfo,
      selectedOrderLoading: (s) => s.order.selectedOrderLoading,
    }),
    accessToChange() {
      return this.isCanChangeOrder && !this.globalLoading && !this.isSelectedOrder;
    },
    accessToChangeUser() {
      return this.isCanChangeOrder && !this.globalLoading;
    },
    globalLoading() {
      return this.loading || this.findClientLoading || this.selectedOrderLoading;
    },
    orderInfo() {
      return this.isSelectedOrder ? this.selectedOrderInfo : this.newOrderInfo;
    },
    pageOrder() {
      return this.isSelectedOrder ? this.selectedOrder : this.newOrder;
    },
  },
  watch: {
    phone: function (value) {
      this.debounceInput(value, 'phone');
    },
    email: function (value) {
      this.debounceInput(value, 'email');
    },
  },
  created() {
    this.CURRENCIES = CURRENCIES;
  },
  beforeMount() {
    if (
      this.isSelectedOrder &&
      this.selectedOrder?.user?.guid &&
      this.selectedOrderInfo?.user?.guid != this.selectedOrder?.clientId
    ) {
      this.phone = this.selectedOrder.user.contacts?.phone || null;
      this.email = this.selectedOrder.user.contacts?.email || null;
    }
  },
  methods: {
    ...mapActions({
      getClientUserAndTeamsInfo: 'order/getClientUserAndTeamsInfo',
    }),
    ...mapMutations({
      setOrderInfo: 'order/setOrderInfo',
      setClientBlockUser: 'order/setClientBlockUser',
    }),
    updateUserHandler(user) {
      this.setClientBlockUser({ user, isSelectedOrder: this.isSelectedOrder });
      if (!this.isSelectedOrder) {
        ['phone', 'email'].map((field) => {
          this[field] = field === 'phone' ? '7' + user.contacts[field] : user.contacts[field];
        });
      }
    },
    debounceInput: debounce(async function (value, field) {
      try {
        const clearValue =
          field === 'phone'
            ? String(value)
                .replace(/[^0-9.]/gi, '')
                .slice(1)
            : value;
        const field2 = field === 'phone' ? this.email : this.phone;
        if (field == 'phone' && clearValue.length != 10) {
          return this.setOrderInfo({ selectedOrder: this.isSelectedOrder, data: {} });
        }

        if (field == 'email' && clearValue) {
          const emailRegexp = /\S+@\S+\.\S+/;
          if (!emailRegexp.test(clearValue))
            return this.setOrderInfo({ selectedOrder: this.isSelectedOrder, data: {} });
        }

        if (!clearValue && !field2) {
          return this.setOrderInfo({ selectedOrder: this.isSelectedOrder, data: {} });
        }

        this.findClientLoading = true;

        await this.getClientUserAndTeamsInfo({
          [field]: clearValue,
          selectedOrder: this.isSelectedOrder,
        });
      } catch (err) {
        this.setOrderInfo({ selectedOrder: this.isSelectedOrder, data: {} });
        this.$notifyError({ title: 'Клиент не найден' });
      } finally {
        this.findClientLoading = false;
      }
    }, 800),
    closeAsidePageHandler() {
      this.changeUserId = null;
      this.creatingUser = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.client-tab {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 275px;

  overflow: hidden;
  @include scroll;

  .currency {
    width: 130px;
  }
  .right {
    flex-direction: row-reverse;
  }
}
</style>
