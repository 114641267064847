<template>
  <span
    class="a-badge"
    :class="{
      fontbase,
      lowercase,
      uppercase,
      blue,
      red,
      small,
      big,
      gray,
    }"
  >
    <slot />
  </span>
</template>

<script>
export default {
  name: "ABudge",
  props: [
    "uppercase",
    "lowercase",
    "small",
    "blue",
    "gray",
    "red",
    "big",
    "fontbase",
  ].reduce((acc, item) => {
    acc[item] = {
      type: Boolean,
      default: null,
    };
    return acc;
  }, {}),
};
</script>

<style lang="scss" scoped>
.a-badge {
  display: inline-block;

  min-width: 46px;
  min-height: 20px;

  @include InterBold;
  color: $color-white;
  font-size: 11px;
  text-align: center;

  background-color: $color-bg-light-blue-active;
  color: $color-blue;
  border-radius: 3px;

  padding: 5px 8px 5px 8px;

  box-sizing: border-box;
  &.red {
    background-color: $color-red;
    color: $color-white;
  }
  &.gray {
    background-color: $color-white-smoke;
    color: $color-gray;
  }
  &.small {
    font-size: 8px;
    min-height: 0;
    letter-spacing: 0;
    padding: 3px 4px 1px 4px;
  }
  &.big {
    font-size: 14px;
    min-height: 0;
    letter-spacing: 0;
    padding: 10px 16px 10px 16px;
  }

  &.lowercase {
    text-transform: lowercase;
    letter-spacing: 1px;
  }
  &.uppercase {
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  &.fontbase {
    text-transform: none;
    // letter-spacing: 1px;
    font-size: 12px;
    display: flex;
    align-items: center;
  }

  // &.red {
  //   background-color: $color-red;
  // }

  // &.orange {
  //   background-color: $color-wanring;
  // }

  // &.dark {
  //   background-color: red;
  // }

  // &.gray {
  //   background-color: $color-bg-smoke;
  // }

  // &.light {
  //   background-color: $color-bg-light-blue;
  //   color: $color-gray;
  // }
}
</style>
