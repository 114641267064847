<template>
  <div class="a-item-title-subtitle-group">
    <div class="a-item-title-subtitle-group__title" :class="{ titleBold }">
      <slot name="title" />
    </div>
    <div class="a-item-title-subtitle-group__subtitle">
      <slot name="subtitle" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Item-group",
  props: {
    titleBold: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.a-item-title-subtitle-group {
  display: flex;
  flex-direction: column;

  font-size: 12px;

  &__title {
    &.titleBold {
      @include InterSemibold;
    }
    font-size: 14px;
  }
  &__subtitle {
    color: $color-gray;
  }
}
@include to(500px) {
  .a-item-title-subtitle-group {
    flex-direction: row;
    align-items: center;
    margin: 0;
    padding: 0;
    &__title {
      margin-right: 10px;
    }
  }
}
</style>
