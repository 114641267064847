<template>
  <div>
    <div v-if="cards.length && order" class="card-summary">
      <div class="card-summary__info">
        <div>
          <div>Менеджер</div>
          <div class="body">
            <span>{{ getManagerName }}</span>
            <span class="seconary">{{ order.manager.contacts.phone }}</span>
          </div>
        </div>
        <div>
          <div>Название заказа</div>
          <div class="body">
            <span class="name" :class="getOrderNameClass">
              {{ order.name }}
            </span>
          </div>
        </div>
        <div>
          <div>Дата сдачи</div>
          <div class="body">
            <span v-if="order.postponedDeadline || order.deadline">
              {{ dayjs(order.postponedDeadline || order.deadline).format('DD MMM') }}
            </span>
          </div>
        </div>
        <div>
          <div>Кол-во партий</div>
          <div class="body">{{ cards.length }}</div>
        </div>
      </div>
      <div class="card-summary__summary">
        <table>
          <tbody>
            <tr>
              <td>№</td>
              <td>Название изделия</td>
              <td>Материал</td>
              <td>Партии</td>
              <td>Цвет</td>
              <td>Кол-во</td>
            </tr>
            <tr v-for="(item, index) of summary" :key="item.productId" class="row">
              <td>{{ index + 1 }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.fabric }}</td>
              <td>{{ item.parts }}</td>
              <td>{{ item.color }}</td>
              <td>{{ item.sumQuantity }}</td>
            </tr>
          </tbody>
        </table>
        <div class="tables">
          <div
            v-for="(item, index) of summary"
            :key="`summary-${item.productId}`"
            class="summary-block"
          >
            <div class="header">
              <span>{{ index + 1 }}. {{ item.name }} </span>
              <br />
              <span>
                {{ item.fabric }}
              </span>
            </div>
            <div class="body">
              <div class="card-summary__summary-table">
                <div v-for="(values, sexId) of item.summary" :key="sexId" class="summary-column">
                  <div class="header">{{ getSex(sexId) }}</div>
                  <div v-for="(quantity, size) of values" :key="size" class="row">
                    <div>{{ size }}</div>
                    <div>{{ quantity }}</div>
                  </div>
                  <div class="wrapper">
                    <div />
                    <div class="sum">{{ getSumInSex(values) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-summary__footer">
        <div>Менеджер: {{ order.manager.name }}</div>
        <div>Тел: {{ order.manager.contacts.phone }}</div>
        <div>
          Карта составлена: {{ dayjs().format('DD.MM.YYYY HH:mm:ss') }} (V.
          {{ dayjs(card.createdAt).format('DD.MM HH:mm') }})
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { dayjs } from '../../../utils/date';
import { getClassNameByTextLength } from '@/utils/text.js';
import { getUserName } from '@/utils/string.js';

export default {
  name: 'SummaryCard',
  props: {
    cards: {
      type: Object,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
    summary: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      card: this.cards[0],
    };
  },

  computed: {
    ...mapState({
      assortmentDictionary: (s) => s.dictionary.assortmentDictionary,
    }),
    getSumInSex() {
      return (values) => Object.values(values).reduce((s, i) => s + i, 0);
    },
    getSex() {
      return (sexId) => {
        const sex = this.assortmentDictionary.sex[sexId]?.name || '-';
        if (sex?.length > 6) {
          return `${sex.slice(0, 6)}.`;
        }
        return sex;
      };
    },
    getMockup() {
      return this.card.product.design.mockup;
    },
    getOrderNameClass() {
      const text = this.order?.name || '';
      return getClassNameByTextLength(text);
    },
    getManagerName() {
      return getUserName(this.order.manager);
    },
  },
  created() {
    this.dayjs = dayjs;
  },
};
</script>
<style scoped lang="scss">
$cardWidth: 1132px;
$cardHeight: 1594px;
$scaleCard: var(--scalec);
$border-color: rgb(158, 158, 158);

.card-summary {
  display: flex;
  flex-direction: column;
  border: 1px dashed $border-color;
  width: $cardWidth;
  height: $cardHeight;

  // transform: scale($scaleCard) translateX(calc(calc($cardWidth * $scaleCard) * (-1)))
  //   translateY(calc(calc($cardHeight * $scaleCard) * (-1)));

  &__info {
    flex-grow: 0.11;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid $border-color;

    .seconary {
      margin-top: 10px;
      font-size: 12px;
    }

    div {
      display: grid;
      grid-template-rows: 20px 1fr;

      font-size: 14px;
      flex-grow: 1;
      border-right: 1px solid $border-color;

      text-align: center;
      .body {
        border-top: 1px dashed $border-color;
        font-size: 20px;
        font-weight: 600;
      }
      .name {
        max-width: 600px;
        &.text-xl {
          font-size: 16px;
        }
        &.text-xxl {
          font-size: 16px;
        }
      }
    }
  }

  &__summary {
    flex-grow: 3;

    display: flex;
    flex-direction: column;

    margin-top: 20px;

    table {
      .row {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
  .tables {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 20px;
    // background-color: aliceblue;

    .summary-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0px 10px 10px 0px;
      border: 1px solid $border-color;
      .header {
        padding-top: 10px;
        font-weight: 600;
      }
    }
  }
  &__summary-table {
    display: flex;
    flex-direction: row;
    border-top: 1px dashed $border-color;
    width: fit-content;
    margin: 10px 0px;
    flex-grow: 3;

    .summary-column {
      display: flex;
      flex-direction: column;

      &:not(:last-child) {
        border-right: 1px solid $border-color;
      }
    }

    .header {
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      border-bottom: 1px dashed $border-color;
      padding: 5px 0px;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 3;

      .sum {
        text-align: center;
        font-weight: 600;
        font-size: 16px;
        border-top: 1px dashed $border-color;
      }
    }
    .row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      div {
        text-align: center;
        padding: 1px 10px;
        &:first-child {
          border-right: 1px solid $border-color;
        }
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid $border-color;
  }
}
</style>
