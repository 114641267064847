export default () => ({
  assortmentDictionary: {
    items: {},
    sets: {},

    itemGrowtypes: {},
    categories: {},
    fabrics: {},
    growTypes: {},
    articles: {},
    productTypes: {},
    sex: {},
    sizes: [],
  },
  availableAssortment: {
    itemFabrics: [],
    itemSex: [],
  },
  productionParams: {},
  productionParamsDownload: false,
  assortmentDictionaryDownload: false,
});
